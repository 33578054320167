<template>
  <div
    class="body-back bg-s w3-container"
    style="display: block; margin-left: auto; margin-right: auto; display: table-cell; vertical-align: middle"
    v-loading="load_pagina"
    element-loading-text="Cargando página..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <img :src="img_header" class="img-banner responsive hidden-xs-only" :style="styleBanner" fit="contain" alt="Logo Empresa" />
    <img :src="img_header" class="img-banner responsive_2 hidden-md-only hidden-lg-only hidden-xl-only hidden-sm-only" fit="contain" alt="Logo Empresa" />
    <!-- <img
      :src="img_header"
      class="img-banner resposive"
      fit="contain"
      alt="Logo Empresa"
      style="width: 320px; height: auto; margin: auto" /> -->
    <div v-if="!Noshow_principal">
      <el-row v-if="Noshow">
        <h1 style="color: white"><center>Página en mantenimiento</center></h1>
      </el-row>
      <el-row :gutter="10" v-if="!Noshow" style="margin-top: 0% !important">
        <el-col :span="10" :offset="7" class="hidden-xs-only">
          <el-card class="box-card" style="/* centrar vertical y horizontalmente */ position: absolute; width: 100%; height: 100%; margin: auto; /* margin-top: 100px; */">
            <el-col :span="24" class="login-form">
              <h2>INGRESO AL SISTEMA</h2>
              <!-- <div class="avatar">
                <img :src="img_header" alt="" />
              </div> -->

              <!-- <h4 style="font-size: 24px; margin-top: 0px">

              </h4> -->
            </el-col>
            <el-form :model="formIngresar" :rules="rules" ref="formIngresar" class="demo-dynamic" autocomplete="on">
              <el-form-item prop="usuario">
                <el-input v-model="formIngresar.usuario" placeholder="Ingrese Usuario"
                  ><template slot="prepend"><i class="el-icon-user-solid"></i></template>
                </el-input>
              </el-form-item>
              <el-form-item prop="pass">
                <el-input placeholder="Ingrese contraseña" v-model="formIngresar.pass" :type="passwordType" @keypress.native.enter="submitForm('formIngresar')">
                  <el-button slot="append" type="password" icon="el-icon-view" @click="mostrarPass" style="background-color: #e8f0fe"></el-button>
                  <template slot="prepend">
                    <i class="el-icon-lock"></i>
                  </template>
                </el-input>
              </el-form-item>
              <div class="foot-lnk">
                <!-- <el-form-item v-if="formIngresar.empresa == 'lo'"> -->
                <el-form-item>
                  <el-button type="text" style="float: right; margin-top: -15px; margin-bottom: -10px" @click="forgetPass()">Olvidé mi contraseña</el-button>
                </el-form-item>
              </div>
              <el-form-item>
                <center>
                  <el-button
                    :type="showbtnC"
                    class="btn-ingresar"
                    :style="showStyle"
                    @click="submitForm('formIngresar')"
                    size="mini"
                    round
                    title="Ingresa al Sistema"
                    icon="el-icon-thumb"
                    :loading="loadnBtn">
                    Ingresar
                  </el-button>
                </center>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
        <el-col :span="18" :offset="2" class="hidden-md-only hidden-lg-only hidden-xl-only hidden-sm-only">
          <el-card class="box-card" style="/* centrar vertical y horizontalmente */ position: absolute; margin: auto; padding: 0px 20px !important">
            <el-col :xs="24">
              <h4 style="font-size: 24px; margin-top: 0px">Ingreso al Sistema</h4>
            </el-col>
            <el-form :model="formIngresar" :rules="rules" ref="formIngresar" :closable="false" class="demo-dynamic" autocomplete="off">
              <el-form-item prop="usuario">
                <el-input v-model="formIngresar.usuario" placeholder="Ingrese Usuario"
                  ><template slot="prepend"><i class="el-icon-user-solid"></i></template
                ></el-input>
              </el-form-item>
              <el-form-item prop="pass">
                <el-input placeholder="Ingrese contraseña" v-model="formIngresar.pass" type="password" @keypress.native.enter="submitForm('formIngresar')">
                  <template slot="prepend">
                    <i class="el-icon-lock"></i>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="text" style="float: right; margin-top: -15px; margin-bottom: -10px" @click="forgetPass()">Olvidé mi contraseña</el-button>
              </el-form-item>
              <el-form-item>
                <center>
                  <el-button
                    :type="showbtnC"
                    class="btn-ingresar-movil"
                    :style="showStyle"
                    @click="submitForm('formIngresar')"
                    size="mini"
                    round
                    title="Ingresa al Sistema"
                    icon="el-icon-thumb"
                    :loading="loadnBtn">
                    Ingresar
                  </el-button>
                </center>
              </el-form-item>
              <el-alert v-if="showMsj" :title="msj_error" type="error" center show-icon> </el-alert>
            </el-form>
          </el-card>
        </el-col>
      </el-row>

      <el-footer :style="styleFooter">
        <div>
          {{ altEmpresa }}
        </div>
      </el-footer>
    </div>
    <!-- <el-row> </el-row> -->
  </div>
</template>

<script>
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'
import jwt_decode from 'jwt-decode'

export default {
  data() {
    return {
      loading: false,
      password: null,
      email: '',
      msj: '',
      show_error: false,
      formulario: false,
      form_recupe: true,
      nombre: '',
      class_error: '',
      show_error2: false,
      base_path: '',
      regresar: 'usuarios/recuperar',
      loading_recup: false,
      msj_error: '',
      img_header: '',
      formIngresar: {
        usuario: '',
        pass: '',
        empresa: '',
      },
      showMsj: false,
      disabledBtn: true,
      rules: {
        usuario: [
          {
            required: true,
            message: 'Ingrese usuario / email',
            trigger: 'blur',
          },
        ],
        pass: [
          { required: true, message: 'Ingrese contraseña', trigger: 'blur' },
          {
            min: 6,
            message: 'Su contraseña debe tener como mínimo 6 caracteres',
            trigger: 'blur',
          },
        ],
      },
      token: '',
      imgFavicon: '',
      showbtnC: '',
      titulo: '',
      Noshow_principal: true,
      Noshow: true,
      loadnBtn: false,
      result: '',
      passwordType: 'password',
      load_pagina: true,
      showStyle: {},
      styleFooter: {},
      altEmpresa: '',
      styleBanner: {},
    }
  },
  metaInfo() {
    return {
      title: this.titulo,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        {
          name: 'description',
          content: 'An example Vue application with vue-meta.',
        },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css?family=Roboto:300,300i,500,700,900i&display=swap',
        },
        { rel: 'shortcut icon', type: 'image/png', href: this.imgFavicon },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Varela+Round&display=swap',
        },
      ],
    }
  },
  created() {
    this.checkServer()
    if (this.$route.query.tokenExterno) {
      this.procesarToken(this.$route.query.tokenExterno)
    }
    /* Imagen segu url y btn */
    if (process.env.NODE_ENV !== 'development') {
      let urlpp = window.location.host,
        array_url = urlpp.split('/'),
        empresa_nombre = array_url[0].split('.')

      if (empresa_nombre[1] == 'suizoargentina') {
        this.img_header = require('@/assets/SuizoBlanco.svg')
        this.titulo = 'Suizo Argentina S.A.'
        this.imgFavicon = 'favicon_suizo.ico'
        this.showbtnC = 'primary'
        this.showStyle['fontSize'] = '20px'
        this.showStyle['backgroundColor'] = '#37597bcc'
        this.showStyle['borderColor'] = '#324a62'
        // this.styleBanner['width'] = '30%'

        this.formIngresar.empresa = 'u'
      } else if (empresa_nombre[1] == 'log-in') {
        this.titulo = 'LOG-IN'
        this.img_header = require('@/assets/loginfarma.png')
        this.imgFavicon = 'favicon_log.png'
        this.showbtnC = 'warning'
        this.formIngresar.empresa = 'lo'
        this.showStyle['fontSize'] = '20px'
        this.styleBanner['width'] = '27% !important'
      } else {
        this.$router.push({ name: 'PageNotFound' })
      }
    } else {
      if (process.env.VUE_APP_EMPRESA === 'suizo') {
        this.titulo = 'Suizo Argentina S.A.'
        this.img_header = require('@/assets/SuizoBlanco.svg')
        this.imgFavicon = 'favicon_suizo.ico'
        this.showbtnC = 'primary'
        this.formIngresar.empresa = 'u'
        this.showStyle['fontSize'] = '20px'
        this.showStyle['backgroundColor'] = '#324a62'
        this.showStyle['borderColor'] = '#324a62'
        this.styleBanner['width'] = '25%'
      } else {
        this.titulo = 'LOG-IN'
        this.img_header = require('@/assets/loginfarma.png')
        this.imgFavicon = 'favicon_log.png'
        this.showbtnC = 'warning'
        this.formIngresar.empresa = 'lo'
        this.showStyle['fontSize'] = '20px'
        this.styleBanner['width'] = '20%'
      }
    }
    this.permisos_footer()
  },
  methods: {
    checkServer() {
      setTimeout(() => {
        HTTP.get('/loginguias')
          .then((res) => {
            if (res.data) {
              this.Noshow = true
            } else {
              this.Noshow = false
            }
            setTimeout(() => {
              this.load_pagina = false
              this.Noshow_principal = false
            }, 1000)
          })
          .catch((error) => {
            this.load_pagina = false
            this.Noshow = true
            this.Noshow_principal = false
            console.log(error)
          })
      }, 1000)
    },
    forgetPass() {
      this.$router.push({ name: 'recuperar' })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadnBtn = true
          HTTP.post('/loginguias/logearVue', this.formIngresar)
            // HTTP_DESARROLLO.post('/loginguias/logearVue_desarrollo', this.formIngresar)
            .then((res) => {
              this.loadnBtn = false
              // console.log(res.data)
              if (res.data.empresaL === 'extra') {
                // const dataInfo={id:res.data}
                // console.log(typeof res.data.token != 'undefined')
                if (typeof res.data.token != 'undefined') {
                  this.$router.push({ name: 'expirar', params: { dataInfo: res.data.token } })
                } else {
                  if (res.data.intentos === 3) {
                    this.$swal('Ingreso', 'Usted lleva 3 intentos fallidos. Recuerde que al quinto intento su usuario quedara bloqueado.', 'error')
                  } else {
                    this.$message.error('Usuario o contraseña incorrectos.')
                  }
                }
              } else {
                if (typeof res.data.empresaL != 'undefined') {
                  if (res.data.usuario.user == 'bd_test') {
                    window.open('https://seguimiento.log-in.com.ar/seguimiento/empresa_test/#/' + res.data.empresaL + '/guias?token=' + res.data.token, '_self')
                    // window.open('http://localhost:8080/#/' + res.data.empresaL + '/guias?token=' + res.data.token, '_self')
                  } else {
                    if (res.data.url == 'localhost') {
                      window.open('http://localhost:8080/#/' + res.data.empresaL + '/guias?token=' + res.data.token, '_self')
                      // window.open('https://seguimiento.log-in.com.ar/seguimiento/empresa/#/' + res.data.empresaL + '/guias?token=' + res.data.token, '_self')
                    } else {
                      window.open('https://seguimiento.log-in.com.ar/seguimiento/empresa/#/' + res.data.empresaL + '/guias?token=' + res.data.token, '_self')
                      // window.open('http://localhost:8080/#/' + res.data.empresaL + '/guias?token=' + res.data.token, '_self')
                    }
                  }
                  // window.open('https://seguimiento.log-in.com.ar/#/' + res.data.empresaL + '/guias?token='+res.data.token , "_self");
                  // window.open('http://localhost:8080/#/' + res.data.empresaL + '/guias?token='+res.data.token , "_self");
                } else {
                  var valores = [0, 2, 3, 4, 5, 6] /* Valores de errores */
                  if (valores.find((dato) => dato === res.data)) {
                    switch (res.data) {
                      case 4:
                        this.msj_error = 'Usuario o contraseña incorrectos.'
                        break
                      case 2:
                        this.msj_error = 'Usuario inactivo.'
                        break
                      case 3:
                        this.msj_error = 'Usuario inactivo.'
                        break
                      case 0:
                        this.msj_error = 'Intente nuevamente.'
                        break
                      case 5:
                        this.msj_error = 'No tiene permisos de acceso.'
                        break
                      case 6:
                        this.msj_error = 'Intente nuevamente.'
                        break
                      default:
                        break
                    }
                    if (this.msj_error != '') this.$message.error(this.msj_error)
                  } else {
                    // LLamo funcion
                    this.procesarToken(res.data)
                  }
                }
              }
            })
            .catch((error) => {
              this.loadnBtn = false
              if (error) {
                this.$alert('Error de conexión. Intente nuevamente por favor.')
              }
            })
        } else {
          return false
        }
      })
    },
    procesarToken(token) {
      localStorage.setItem('token', token)
      //this.token = this.$route.query.token ? this.$route.query.token : window.localStorage.getItem("token");
      this.token = token

      if (this.token) {
        /*if (this.token !== "undefined" && this.$route.query.token) {
                    window.localStorage.setItem("token",this.$route.query.token);
            }*/
        var decoded = jwt_decode(this.token)
        if (decoded.data) {
          if (typeof decoded.data.convol != 'undefined') window.localStorage.setItem('convol', JSON.stringify(decoded.data.convol))
          else window.localStorage.setItem('convol', null)

          if (decoded.data.empresa == 'lo' || decoded.data.empresa == 'u') {
            window.localStorage.setItem('permisos', JSON.stringify(decoded.data.session['permisos']))
            window.localStorage.setItem('empresa', decoded.data.empresa)
            window.localStorage.setItem('datos', JSON.stringify(decoded.data.session))
            window.localStorage.setItem('fantasia', decoded.data.fantasia)
            window.localStorage.setItem('convenio', decoded.data.convenio)
            window.localStorage.setItem('clave_ex', decoded.data.clave)
            window.localStorage.setItem('permiso_pass', decoded.data.permiso_pass)
            if (this.$route.query.tokenExterno) window.localStorage.setItem('externo', true)
            else window.localStorage.setItem('externo', false)
          }
        } else {
          this.$alert('Es necesario ingresar nuevamente con su usuario y contraseña', 'Alerta de Seguridad', {
            confirmButtonText: 'Aceptar',
            type: 'error',
            callback: () => {
              this.$router.push({ path: '/' })
            },
          })
        }
        //VUEX
        const datosTok = decoded.data.session
        const empresaTok = decoded.data.empresa
        this.$store
          .dispatch('setDatos', { datosTok, empresaTok })
          .then(() => {
            console.log('VUEX success')
          })
          .catch(() => {
            console.log('VUEX error')
          })
        this.$store
          .dispatch('login', token)
          .then(() => {
            console.log('Login success')
            this.$router.push({ name: 'inicio', query: { token: token } })
          })
          .catch(() => {
            console.log('Login error')
          })
        //FIN VUEX
      }
    },
    getConvol() {
      var permiso_stock = JSON.parse(window.localStorage.getItem('permisos')).includes('STOCK') ? true : false
      var convenio = JSON.parse(window.localStorage.getItem('datos')).convenio
      if (permiso_stock) {
        HTTP.post('/guiasnew/getConvol1', { codigo: convenio })
          .then((res) => {
            window.localStorage.setItem('convol', JSON.stringify(res.data))
          })
          .catch((error) => console.log(error))
      }
    },
    mostrarPass() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    permisos_footer() {
      if (this.formIngresar.empresa == 'lo') {
        this.altEmpresa = 'LOG-IN FARMA S.R.L © 2024 '
        this.styleFooter['color'] = 'white'
        this.styleFooter['fontSize'] = '10px'
      } else if (this.formIngresar.empresa == 'u') {
        this.altEmpresa = 'Suizo Argentina S.A. - © 2005-2024'
        this.styleFooter['color'] = 'white'
        this.styleFooter['fontSize'] = '10px'
      }
    },
    encrypt_data(string) {
      string = unescape(encodeURIComponent(string))
      var newString = '',
        char,
        nextChar,
        combinedCharCode
      for (var i = 0; i < string.length; i += 2) {
        char = string.charCodeAt(i)

        if (i + 1 < string.length) {
          nextChar = string.charCodeAt(i + 1) - 31

          combinedCharCode =
            char +
            '' +
            nextChar.toLocaleString('en', {
              minimumIntegerDigits: 2,
            })

          newString += String.fromCharCode(parseInt(combinedCharCode, 10))
        } else {
          newString += string.charAt(i)
        }
      }
      return newString.split('').reduce((hex, c) => (hex += c.charCodeAt(0).toString(16).padStart(4, '0')), '')
    },
  },
}
</script>
<style scoped>
.bg-s {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #66b1ff !important;
  background-image: linear-gradient(315deg, #3a638d 0%, #2a2b2d 74%);
  z-index: -1;
  transition: 850ms !important;
  /* margin-top: 20%; */
}
.body-back {
  /* background: linear-gradient(96deg, #FFFAFA 0, #E5EBF6 64%); */
  overflow: hidden;
  height: 100%;
  text-align: center;
  font-family: 'Roboto';
}
.img-banner {
  padding-top: 3%;
  padding-bottom: 1rem;
}
.responsive {
  width: 30%;
  height: auto;
  margin: auto;
}
.responsive_2 {
  width: 55%;
  height: auto;
  margin: auto;
  padding-top: 5% !important;
}
.vform {
  background-color: #ffffff;
  padding: 15px;
  width: 30%;
}
.el-header {
  color: #333;
  text-align: center;
  line-height: 200px;
  height: 250px !important;
}
.login-wrap {
  width: 35%;
  margin: auto;
  max-width: 525px;
  position: relative;
  box-shadow:
    0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.btn-ingresar {
  width: 100%;
  height: 55px;
  padding: auto !important;
  position: relative;
  margin-bottom: 25px;
  border-radius: 32px;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.btn-ingresar-movil {
  width: 100%;
  height: 55px;
  padding: auto !important;
  position: relative;
  margin-bottom: 25px;
  border-radius: 32px;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.el-form-item {
  margin-bottom: 20px;
}
.element {
  width: 100%;
  height: 100%;
  animation: pulse 5s infinite;
}
@media (max-width: 768px) {
  .vform {
    width: 80%;
    margin-top: 100px;
  }
  footer {
    position: flex;
    bottom: 0;
    width: 100% !important;
    height: 40px;
    color: white;
    text-align: center;
    margin-top: auto !important;
    padding: 20px 20px;
    font-size: 13px;
  }
}
/* .w3-responsive {
  display: block;
  overflow-x: auto;
}
.w3-container:after,
.w3-container:before {
  content: '';
  display: table;
  clear: both;
} */

.foot-lnk {
  text-align: center;
  color: black;
}
footer {
  position: flex;
  bottom: 0;
  width: 100% !important;
  height: 40px;
  color: white;
  text-align: center;
  margin-top: auto !important;
  padding: 20px 20px;
  font-size: 15px;
}
</style>
