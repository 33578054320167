export default {
  namespaced: true,
  state: {
    importadora: {
      convol: null,
      convenio: null,
      descrip: 'IMPORTADORA SUIZO',
      nombre: 'IMPORTADORA SUIZO - SUC 16',
    },
    turnero: {
      //convenio: {},
      convenios_all: [],
      agenda: [],
    },
  },
  mutations: {
    SET_TURNERO_CONFIG(state, { convenios_all, agenda }) {
      //state.turnero.convenio = convenio
      state.turnero.convenios_all = convenios_all
      state.turnero.agenda = agenda
    },
    CLEAR_TURNERO(state) {
      state.turnero = {
        convenios: [],
        convenios_full: [],
        convenios_all: [],
        agenda: [],
      }
    },
  },
  actions: {
    setTurneroConfig({ commit }, config) {
      commit('SET_TURNERO_CONFIG', config)
    },
  },
  getters: {
    getImportadora: (state) => state.importadora,
    getTurneroConfig: (state) => state.turnero,
  },
}
