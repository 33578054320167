<template>
  <div>
    <el-card v-if="showForm" class="box-card" style="border-color: #80808073">
      <el-form :model="formCrear" :rules="rules" ref="formCrear" label-position="top" label-width="100%" class="demo-ruleForm">
        <div v-if="showForm">
          <h4 v-if="showMje" style="text-decoration: underline; margin: 0px; text-decoration-style: dotted">
            <center>Número de Comprobante correcto g: {{ formCrear.comprob }}</center>
          </h4>
          <el-row :gutter="10" class="vguia-row">
            <el-col :md="6" :sm="12" :xs="24">
              <label for><span style="color: red">*</span>Terminal de guía:</label>
              <el-input v-model="formCrear.terminal" size="mini" placeholder="Terminal" ref="user" disabled prefix-icon="el-icon-check"></el-input>
            </el-col>
            <el-col :md="6" :sm="12" :xs="24">
              <label for><span style="color: red">*</span>Número de guía:</label>
              <el-input v-model="formCrear.numero" size="mini" placeholder="Número" disabled prefix-icon="el-icon-check"></el-input>
            </el-col>
            <el-col :md="12" :sm="12" :xs="24">
              <label for> <span style="color: red">*</span> Comprobante:</label>
              <el-input v-model.number="formCrear.comprob" size="mini" placeholder="Comprobante" clearable disabled prefix-icon="el-icon-check"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="vguia-row">
            <el-col :md="12" :sm="12" :xs="24">
              <label for><span style="color: red">*</span> Sucursal de escaneo:</label>
              <el-select v-model="formCrear.sucursal" placeholder="Seleccione sucursal" class="vpim-select" size="mini" clearable filterable :disabled="disabled_sucursal">
                <el-option v-for="item in sucursales" :key="item.codigo" :label="item.codigo + ' - ' + item.nombre" :value="item.codigo"> </el-option>
              </el-select>
            </el-col>
            <el-col :md="12" :sm="12" :xs="24">
              <label for>Cuit:</label>
              <el-input v-model.number="formCrear.cuit" size="mini" placeholder="Cuit" clearable prefix-icon="el-icon-check"></el-input>
              <!-- <div class="el-form-item__error" style="position: relative;" v-if="error_cuit">{{error_cuit}}</div> -->
            </el-col>
          </el-row>
          <el-row :gutter="10" class="vguia-row">
            <el-col :md="12" :sm="12" :xs="24">
              <label for>Fecha de escaneo:</label>
              <el-date-picker
                :picker-options="datePickerOptions2"
                v-model="formCrear.fecha_escaneo"
                type="date"
                size="mini"
                value-format="yyyyMMdd"
                format="dd-MM-yyyy"
                style="width: 100%"
                placeholder="Fecha escaneo"
                clearable
                :disabled="disabled_escaneo">
              </el-date-picker>
            </el-col>
            <el-col :md="12" :sm="12" :xs="24">
              <label for>Fecha de comprobante:</label>
              <el-date-picker
                v-model="formCrear.fecha_comprob"
                type="date"
                size="mini"
                value-format="yyyyMMdd"
                format="dd-MM-yyyy"
                style="width: 100%"
                placeholder="Fecha comprobante"
                :disabled="disabled_fecha">
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="12" :sm="10" :xs="24">
              <label for="" class="vpim-label">Convenios:</label>
              <el-select v-model="formCrear.guia_olconv" placeholder="Seleccione convenio" size="mini" class="vpim-select" filterable clearable :disabled="disabled_olconv">
                <el-option v-for="item in convenios" :key="item.olconv" :label="item.nombre" :value="item.olconv"> </el-option>
              </el-select>
            </el-col>
          </el-row>
          <div v-if="!disabled_sucursal">
            <el-row :gutter="10" v-if="showMje" class="vguia-row">
              <el-col :span="24">
                <el-alert :title="mensaje" type="info" show-icon :closable="false"> </el-alert>
              </el-col>
            </el-row>
          </div>
          <el-row :gutter="10" class="vguia-row">
            <center>
              <el-col :span="24">
                <el-form-item label="" style="margin-bottom: 0px !important">
                  <el-button type="primary" @click="enviarForm('formCrear')" icon="el-icon-check" :loading="loadingGuardar" size="mini" :disabled="disabled_guardar">Guardar</el-button>
                  <el-button v-if="tipo_busqueda != 'fecha'" @click="limpiarTodo()" icon="el-icon-close" size="mini" :disabled="disabled_cancelar">Cancelar</el-button>
                </el-form-item>
              </el-col>
            </center>
          </el-row>
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
// const moment = require("moment");
// import { EventBus } from "@/event-bus";
// import pdf from "vue-pdf";

export default {
  props: ['datos', 'comproba', 'sucursales', 'comprob_extra', 'tipo_busqueda', 'convenios'],

  data() {
    return {
      formCrear: {
        terminal: '',
        numero: '',
        sucursal: '',
        comprob: '',
        fecha_escaneo: null,
        fecha_comprobante: '',
        cuit: '',
        guia_suc: null,
        presentacion_suc: null,
        canalizador_suc: null,
        comprobante_error: '',
        id: null,
        guia_olconv: null,
        ruta: '',
        convol: '',
      },
      rules: {
        sucursal: [
          {
            required: true,
            message: 'Por favor elija una sucursal',
            trigger: 'change',
          },
        ],
        comprob: [
          {
            required: true,
            message: 'No se cargó el comprobante',
            trigger: 'change',
          },
        ],
      },
      showMje: false,
      // mensaje: 'Los datos previamente cargados se encuentran asociados al comprobante, pero no están como datos del comprobante escaneado. Por lo tanto si los datos son correctos debe guardar los mismos.',
      mensaje: 'Algunos de los datos previamente cargados se encuentran asociados al comprobante, pero no así al comprobante escaneado. Si los datos son correctos guarde los mismos.',
      datosComprob: '',
      empresaUrl: window.localStorage.getItem('empresa'),
      operador: JSON.parse(window.localStorage.getItem('datos')).uid + '-' + JSON.parse(window.localStorage.getItem('datos')).user,
      showForm: false,
      loadingGuardar: false,
      disabled_fecha: false,
      disabled_escaneo: false,
      error_cuit: '',
      disabled_olconv: false,
      disabled_sucursal: false,
      datePickerOptions2: {
        disabledDate(date) {
          return date > new Date()
        },
      },
      disabled_guardar: true,
      disabled_cancelar: false,
    }
  },
  mounted() {
    // this.getConvenios()
    if (this.datos) {
      this.showForm = true
      this.getDatosOlcomprob(this.datos)
    }
  },
  beforeDestroy() {
    this.$events.off('datos_comprobante')
  },
  methods: {
    // getConvenios() {
    //   HTTP.post('/guiasnew/getConvenios', {}).then((res) => {
    //     this.convenios = res.data
    //   })
    // },
    getDatosOlcomprob(datos) {
      this.showMje = false
      this.error_cuit = ''
      if (datos != null && datos != false) {
        this.showMje = true

        this.formCrear.terminal = datos.guia_terminal != null || datos.guia_terminal != '' ? datos.guia_terminal : ''

        this.formCrear.numero = datos.guia_numero != null || datos.guia_numero != '' ? datos.guia_numero : ''
        // alert(this.comprob_extra.fecha_escaneo!=null)
        this.formCrear.fecha_escaneo =
          this.comprob_extra.fecha_escaneo != null && this.comprob_extra.fecha_escaneo != '' ? this.$moment(this.comprob_extra.fecha_escaneo.date).format('YYYYMMDD') : null
        this.disabled_escaneo = this.formCrear.fecha_escaneo != null ? true : false

        this.formCrear.fecha_comprobante =
          this.comprob_extra.fecha_comprobante != null || this.comprob_extra.fecha_comprobante != ''
            ? this.$moment(this.comprob_extra.fecha_comprobante).format('YYYYMMDD')
            : datos.fecha_comprob != null || datos.fecha_comprob != ''
            ? this.$moment(datos.fecha_comprob.date).format('YYYYMMDD')
            : ''
        this.disabled_fecha = this.formCrear.fecha_comprobante != '' ? true : false

        this.formCrear.cuit = this.comprob_extra.cuit != null || this.comprob_extra.cuit != '' ? this.comprob_extra.cuit : ''

        this.formCrear.comprobante_error = this.comprob_extra.comprob != null || this.comprob_extra.comprob != '' ? this.comprob_extra.comprob : ''

        this.formCrear.id = this.comprob_extra.id != null || this.comprob_extra.id != '' ? this.comprob_extra.id : null

        this.formCrear.guia_olconv = datos.olconv != null || datos.olconv != '' ? datos.olconv : ''
        this.disabled_olconv = this.formCrear.guia_olconv != '' ? true : false
        if (this.comproba == datos.comprobante_guardar) {
          this.formCrear.comprob = datos.comprobante_guardar != null && datos.comprobante_guardar != '' ? (this.comproba == datos.comprobante_guardar ? datos.comprobante_guardar : '') : ''
          this.formCrear.comprob1 = datos.comprob != null && datos.comprob != '' ? datos.comprob : null
        } else if (datos.comprobante_guardar != null && datos.comprobante_guardar != '') {
          this.formCrear.comprob = datos.comprobante_guardar
          this.formCrear.comprob1 = datos.comprob != null && datos.comprob != '' ? datos.comprob : null
        }
        /* DATOS EXTRAS GUIA_SUC, PRESENTACION Y CANALIZADOR SUC */
        /*  Asigno sucursal */
        if (datos.presentacion_suc != null && datos.presentacion_suc != '') {
          this.formCrear.sucursal = datos.presentacion_suc
          this.formCrear.presentacion_suc = datos.presentacion_suc != null || datos.presentacion_suc != '' ? datos.presentacion_suc : null
        } else if (datos.guia_suc != null && datos.guia_suc != '') {
          this.formCrear.sucursal = datos.guia_suc
          this.formCrear.guia_suc = datos.guia_suc != null || datos.guia_suc != '' ? datos.guia_suc : null
        } else if (datos.canalizador_suc != null && datos.canalizador_suc != '') {
          this.formCrear.sucursal = datos.canalizador_suc
          this.formCrear.canalizador_suc = datos.canalizador_suc != null || datos.canalizador_suc != '' ? datos.canalizador_suc : null
        } else {
          this.formCrear.sucursal = ''
        }

        if (datos.fecha_comprob != null || datos.fecha_comprob != '') {
          this.formCrear.fecha_comprob = this.$moment(datos.fecha_comprob.date).format('YYYYMMDD H:mm:ss')
        }
        this.disabled_sucursal = this.formCrear.sucursal != '' && this.formCrear.sucursal != null && this.tipo_busqueda == 'fecha' ? true : false
        if (this.formCrear.comprob && this.formCrear.guia_olconv && this.formCrear.terminal && this.formCrear.numero) {
          this.disabled_guardar = false
        } else {
          this.disabled_guardar = true
        }
      } else {
        this.showMje = false
        this.showForm = false
        this.$alert('No se encontró el comprobante')
      }
    },
    enviarForm() {
      // control
      this.loadingGuardar = true
      this.disabled_cancelar = true
      if (this.formCrear.comprob != '' && this.formCrear.sucursal != '' && this.formCrear.terminal != '' && this.formCrear.numero != '' && this.formCrear.comprobante_error != '') {
        /* Controles extras si envian los datos no obligatorios */
        this.$events.fire('bloquear_btn', 1)
        this.functionGuardar()
        // }
      } else {
        this.loadingGuardar = false
        this.$confirm('Controle que los campos obligatorios esten completos', 'Campos incompletos', {
          confirmButtonText: 'OK',
          type: 'warning',
          center: true,
        })
      }
    },
    functionGuardar() {
      // alert('llego')
      HTTP.post(
        '/escaner/actualizarEscaneo_prueba',
        // HTTP.post('/escaner/actualizarEscaneo_prueba_31_08_2022',
        {
          formulario: this.formCrear,
          tipo: this.tipo_busqueda,
          operador: this.operador,
        }
      )
        .then((res) => {
          if (res.data) {
            /* Actualizar la tabla, */
            /* Comunicar a buscadorComprobantes */
            this.$events.fire('loadTabla-set', res.data)
            this.$message({
              message: 'Comprobante editado satisfactoriamente.',
              type: 'success',
            })
          } else if (res.data == -1) {
            this.$message.warning('El comprobante ya fue editado en otro momento.')
          } else {
            this.$message.error('No se pudo editar el comprobante.Intente nuevamente')
          }
          this.disabled_cancelar = true
          this.loadingGuardar = false
        })
        .catch((error) => {
          console.log(error)
          this.disabled_cancelar = true
        })
    },
    resetForm1(formName) {
      this.$refs[formName].resetFields()
    },
    limpiarTodo() {
      // this.resetForm1('formCrear')
      this.$events.fire('quitar_formulario', 1)
    },
  },
  events: {
    datos_comprobante(filterText) {
      if (filterText) {
        this.showForm = true
        // this.getConvenios()
        this.getDatosOlcomprob(filterText)
      }
    },
  },
}
</script>
