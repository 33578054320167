export default {
  namespaced: true,
  state: {
    integraciones: {
      convenios: [],
    },
  },
  mutations: {
    SET_INTEGRACIONES_CONFIG(state, { convenios }) {
      state.integraciones.convenios = convenios
    },
    CLEAR_INTEGRACIONES(state) {
      state.integraciones = {
        convenios: [],
      }
    },
  },
  actions: {
    setIntegraciones({ commit }, config) {
      commit('SET_INTEGRACIONES_CONFIG', config)
    },
  },
  getters: {
    getIntegraciones: (state) => state.integraciones,
  },
}
