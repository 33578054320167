<template>
    <div>
        <h1><center>Manual de Uso</center></h1>
<!-- <editor
      autofocus
      ref="editor"
      holder-id="codex-editor"
      save-button-id="save-button"
      :init-data="initData"
      @save="Onsave()"
   
    /> -->
    <Editor ref="editor" :config="config" />

    <button @click="invokeSave">Save</button>
    
    </div>
</template>

<script>

    export default {
        data() {
        return {
            config: {
                image: {
                // Like in https://github.com/editor-js/image#config-params
                endpoints: {
                                },
                field: 'image',
                types: 'image/*',
                },
            },
            initData:null,
            data:{

            }
        }     
        },
        methods: {
            onSave(response)
            {
                console.log('response on save',response)
            },
            save()
            {
                console.log(this.$refs.editor.$attrs)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>