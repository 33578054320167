<template>
  <div>
    <!-- ================ MODAL PARA VER ACCIONES Y DETALLES DEL USUARIO ==============================  -->
    <el-dialog width="60%" :visible.sync="loadingModal" top="5vh" @close="closeDetalles('itemDetalle')">
      <el-row>
        <el-col v-if="itemDetalle" :md="24">
          <h3 style="margin-top: -20px">Detalles del chofer</h3>
          <h3 style="margin-top: -20px">
            App versión: <strong>{{ itemDetalle.appversion || '-' }}</strong> | Plataforma: <strong>{{ itemDetalle.plataforma || '-' }}</strong>
          </h3>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-form ref="itemDetalle" :model="itemDetalle" :label-position="labelPosition" style="border-style: groove; padding: 5px" :rules="rules" class="demo-ruleForm">
          <h3 style="margin: 0px 0px 10px 0px"><center>Datos personales</center></h3>
          <el-row :gutter="10">
            <el-col :md="{ span: 12 }">
              <el-form-item label="Apellido y nombre:" prop="nombre">
                <el-input v-model="itemDetalle.nombre" placeholder="Ingrese apellido y nombre" size="mini" prefix-icon="el-icon-user-solid" clearable></el-input>
              </el-form-item>
            </el-col>

            <el-col :md="{ span: 12 }">
              <el-form-item label="DNI:" prop="dni">
                <el-input prefix-icon="el-icon-edit-outline" v-model.number="itemDetalle.dni" size="mini" placeholder="Ingrese DNI" clearable></el-input
              ></el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item label="Email:" prop="email">
                <el-input prefix-icon="el-icon-message" v-model="itemDetalle.email" type="email" size="mini" placeholder="Ingrese Email" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item label="Teléfono:" prop="telefono">
                <el-input prefix-icon="el-icon-phone" v-model="itemDetalle.telefono" size="mini" placeholder="Ingrese teléfono" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item label="Sucursal:" prop="sucursal_nombre">
                <el-select v-model="itemDetalle.sucursal_nombre" placeholder="Sucursal" size="mini" class="vpim-select" clearable filterable>
                  <el-option v-for="item in sucursales" :key="item.codigo" :label="item.codigo + ' - ' + item.nombre" :value="item.codigo"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 12 }">
              <el-form-item label="App " prop="app_permitidas">
                <el-select v-model="itemDetalle.app_permitidas" placeholder="Elija una opción" size="small" style="width: 100%" clearable filterable>
                  <el-option v-for="item in apps" :key="item.value" :label="item.nombre" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :xs="12">
              <el-form-item label="Límite de Hoja de Ruta:" prop="limite">
                <el-input v-model.number="itemDetalle.limite" prefix-icon="el-icon-d-caret" :min="0" size="mini" class="vpim-select" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :xs="12">
              <el-form-item label="Código chofer Suizo:" prop="codigo_chofer">
                <el-input
                  v-model.number="itemDetalle.codigo_chofer"
                  prefix-icon="el-icon-truck"
                  size="mini"
                  class="vpim-select"
                  :min="1"
                  maxlength="5"
                  placeholder="Ingrese código de chofer Suizo"
                  clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :xs="12">
              <el-form-item label="Código chofer Login:" prop="codigo_chofer_log">
                <el-input
                  v-model.number="itemDetalle.codigo_chofer_log"
                  maxlength="5"
                  prefix-icon="el-icon-truck"
                  size="mini"
                  :min="1"
                  class="vpim-select"
                  placeholder="Ingrese código de chofer Login"
                  clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row> </el-row>
          <el-row>
            <el-button type="primary" size="mini" style="width: 100%" @click="setData('itemDetalle')"> Guardar </el-button>
          </el-row>
        </el-form>

        <el-form v-if="formEstado.estado == 1 || formEstado.estado == 0" ref="formEstado" :model="formEstado" :inline="true" style="border-style: groove; padding: 10px; margin-top: 10px">
          <h3 style="margin: 0px">Estado</h3>
          <p style="margin: 0px 0px 25px 0px">para habilitar/deshabilitar el usuario presione el botón correspondiente.</p>
          <el-radio-group v-if="formEstado.estado >= 0" v-model="formEstado.estado" :fill="rb_color" @change="setEstado(formEstado.estado)" size="mini" style="width: 100%">
            <el-radio-button :label="1">Habilitado</el-radio-button>
            <el-radio-button :label="0">Deshabilitado</el-radio-button>
          </el-radio-group>
        </el-form>

        <el-form ref="formPassword" :model="formPassword" :inline="true" style="border-style: groove; padding: 10px; margin-top: 10px">
          <h3 style="margin: 0px 0px 0px 0px">Generar nueva contraseña</h3>
          <p>Para generar una nueva contraseña presione el siguiente botón.</p>

          <el-button type="warning" size="mini" @click="setPassword" :disabled="!itemDetalle.dni"> GENERAR NUEVA CONTRASEÑA </el-button>
          <p v-if="!itemDetalle.dni" style="color: red">Para resetear la contraseña debe contar con DNI.</p>
        </el-form>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import { HTTP } from '@/axios'
const moment = require('moment')
import estadosJson from './_estados.json'

export default {
  mounted() {
    EventBus.$on('detalleRow', (item) => {
      this.loadingModal = true
      this.itemDetalle = item
      this.itemDetalle.sucursal_nombre = this.sucursales.filter((elemento) => elemento.nombre == item.sucursal_nombre)[0].codigo

      this.formEstado.estado = item.habilitado ? item.habilitado : 0
      this.rb_color = parseInt(this.formEstado.estado) ? 'green' : 'red'
    })
    this.getSucursal()
  },
  data() {
    var validarDNI = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Por favor, ingrese su DNI'))
      }
      if (value.toString().length > 8) {
        callback(new Error('Por favor, ingrese un dni correcto'))
      } else {
        callback()
      }
    }
    // var validarCodigo = (rule, value, callback) => {
    //     if (!Number.isInteger(value)) {
    //     callback(new Error('Por favor, ingrese sólo números'));
    //     }else {
    //      callback();
    //     }
    // };
    return {
      itemDetalle: {
        codigo_chofer: '',
        codigo_chofer_log: '',
        dni: '',
        email: '',
        id_geo: '',
        limite: '',
        nombre: '',
        sucursal_nombre: '',
        telefono: '',
        uuid: '',
        habilitado: 0,
        app_permitidas: '',
      },
      loadingDetalles: false,
      tableDataDetalles: [],
      loadingModal: false,
      labelPosition: 'top',
      formEstado: { estado: '' },
      formPassword: { password: '' },
      rb_color: '',
      cambio: false,
      operador: JSON.parse(window.localStorage.getItem('datos'))['uid'] + ' - ' + JSON.parse(window.localStorage.getItem('datos'))['user'],
      sucursales: [],
      apps: estadosJson,
      rules: {
        dni: [{ validator: validarDNI, trigger: ['blur', 'change'] }],
        // codigo_chofer: [
        // { validator: validarCodigo, trigger: ['blur', 'change'] }
        // ],
        // codigo_chofer_log: [
        // { validator: validarCodigo, trigger: ['blur', 'change'] }
        // ],
        nombre: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        sucursal_nombre: [{ required: true, message: 'Seleccione una sucursal', trigger: 'blur' }],
        email: [{ type: 'email', message: 'Ingrese un email correcto', trigger: ['blur'] }],
        app_permitidas: [{ required: true, message: 'Seleccione una opción', trigger: 'blur' }],
      },
    }
  },
  beforeDestroy() {
    EventBus.$off('detalleRow', false)
  },
  methods: {
    // getMasDetalles() {
    //     this.loadingDetalles = true;
    //     HTTP.post('/guiasNew/getMasDetalles', { uuid : this.itemDetalle.uuid, dni : this.itemDetalle.dni })
    //         .then( res => {
    //             this.loadingDetalles = false;
    //             this.tableDataDetalles = res.data;
    //         })

    // },
    controlChofer() {
      if (this.itemDetalle.codigo_chofer != '' && this.itemDetalle.codigo_chofer > 32767) {
        return true
      }
      if (this.itemDetalle.codigo_chofer_log != '' && this.itemDetalle.codigo_chofer_log > 32767) {
        return true
      }
      return false
    },
    resetForm1(formName) {
      this.$refs[formName].resetFields()
    },
    closeDetalles() {
      this.resetForm1('itemDetalle')
      setTimeout(() => {
        EventBus.$emit('closeDetalles', true)
      }, 300)
    },
    setData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let $chofer_ = false
          if (this.itemDetalle.codigo_chofer || this.itemDetalle.codigo_chofer_log) {
            if (this.controlChofer()) {
              $chofer_ = true
            }
          }
          console.log($chofer_)
          if (!$chofer_) {
            this.$alert(`Está seguro que desea actualizar los datos de <strong>${this.itemDetalle.nombre}</strong>?`, 'Datos personales', {
              confirmButtonText: 'SI',
              cancelButtonText: 'SI',
              dangerouslyUseHTMLString: true,
              callback: (action) => {
                if (action === 'confirm') {
                  HTTP.post('/guiasNew/updateDataNuevo', {
                    id_geo: this.itemDetalle.id_geo,
                    dni: this.itemDetalle.dni,
                    nombre: this.itemDetalle.nombre,
                    email: this.itemDetalle.email,
                    telefono: this.itemDetalle.telefono,
                    limite: this.itemDetalle.limite,
                    sucursal: this.itemDetalle.sucursal_nombre,
                    codigo_suizo: this.itemDetalle.codigo_chofer,
                    codigo_login: this.itemDetalle.codigo_chofer_log,
                    operador: this.operador,
                  }).then((res) => {
                    // console.log(res.data)
                    this.cambio = true
                    this.$message({
                      type: 'info',
                      message: res.data.mensaje,
                      duration: 3000,
                    })
                    if (res.data.estado === 1) {
                      this.itemDetalle = res.data.valorUsuario
                      this.itemDetalle.sucursal_nombre = res.data.valorUsuario.sucursal_nombre
                      this.itemDetalle.limite = res.data.valorUsuario.limite_hr
                    }
                  })
                } else {
                  this.cambio = false
                }
              },
            })
          } else {
            this.$message({
              message: 'Controle los códigos ingresados.',
              type: 'warning',
            })
            return false
          }
        } else {
          this.$message({
            message: 'Controle los campos requeridos',
            type: 'warning',
          })
          return false
        }
      })
    },
    // setEstado OK!
    setEstado(val) {
      this.$alert('¿Esta seguro que desea cambiar el estado de <strong>' + this.itemDetalle.nombre + '</strong>?', 'Estado del usuario', {
        confirmButtonText: 'SI',
        cancelButtonText: 'NO',
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          if (action === 'confirm') {
            this.rb_color = parseInt(val) ? 'green' : 'red'
            HTTP.post('/guiasNew/habilitar', { id_geo: this.itemDetalle.id_geo, estado: this.formEstado.estado, operador: this.operador }).then((res) => {
              this.cambio = true
              this.$message({
                type: 'info',
                message: res.data.mensaje,
                duration: 1000,
              })
            })
          } else {
            this.formEstado.estado = this.itemDetalle.habilitado ? 0 : 1
            this.rb_color = parseInt(this.formEstado.estado) ? 'green' : 'red'
            this.cambio = false
          }
        },
      })
    },
    // setPassword OK!
    setPassword() {
      // console.log(this.operador)
      this.$confirm('¿Esta seguro que desea resetear la contraseña?', 'Generar contraseña', {
        confirmButtonText: 'SI',
        cancelButtonText: 'Cancelar',
        type: 'warning',
      })
        .then(() => {
          HTTP.post('/guiasNew/resetPass', { dni: this.itemDetalle.dni, operador: this.operador }).then((res) => {
            this.$alert(
              `Nombre: <strong>${this.itemDetalle.nombre}</strong><br>Usuario: <strong>${this.itemDetalle.dni}</strong><br>Contraseña: <strong>${res.data.pass}</strong>`,
              `Sus nuevos datos son: `,
              {
                confirmButtonText: 'He copiado la contraseña',
                dangerouslyUseHTMLString: true,
                callback: (action) => {
                  if (action) {
                    this.cambio = true
                    this.$message({
                      type: 'info',
                      message: 'Gracias por copiar su nueva contraseña',
                      duration: 1000,
                    })
                  }
                },
              }
            )
          })
        })
        .catch(() => {
          this.cambio = false
          this.$message({
            type: 'info',
            message: 'No se realizó acción',
          })
        })
    },
    getSucursal() {
      HTTP.get('/remitos/getSucursales')
        .then((res) => {
          this.sucursales = res.data
        })
        .catch((error) => console.log(error))
    },
  },
  filters: {
    fecha: function (date) {
      return moment(date).format('DD-MM-YYYY')
    },
  },
}
</script>
<style scoped>
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #ffffff;
  background-color: #409eff !important;
  border-color: #409eff !important;
  box-shadow: -1px 0 0 0 #409eff !important;
}
</style>
