<template>
  <div>
    <el-row style="text-align: center" :gutter="10">
      <center>
        <el-col :md="24">
          <p class="bold title-stock">
            Total de elementos encontrados:{{ cantidad_elementos }}
          </p>
        </el-col>
      </center>
    </el-row>
    <el-table
      :data="tableData"
      width="80"
      border
      stripe
      fit
      v-loading="cargando"
      size="small"
      height="40vh">
      <el-table-column prop="Descripcion" label="Nombre"> </el-table-column>
      <el-table-column prop="Sucursal" label="Sucursal"> </el-table-column>
      <el-table-column prop="Deposito" label="Deposito"> </el-table-column>
      <el-table-column prop="cod_proveedor" label="Cod. Proveedor" width="80">
      </el-table-column>
      <el-table-column prop="cod_suizo" label="Cod. Suizo" width="80">
      </el-table-column>
      <el-table-column prop="Stock" label="Stock" width="90" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.Stock != null && scope.row.Stock != 0"
            >{{ scope.row.Stock }}
          </span>
          <span v-else style="color: #dd6161"><strong>No</strong></span>
        </template>
      </el-table-column>
      <el-table-column prop="En preparacion" label="Preparación" width="90">
      </el-table-column>
      <el-table-column prop="Reservas" label="Reservas" width="80">
      </el-table-column>
      <el-table-column prop="Disponible" label="Disponible" width="90">
      </el-table-column>
      <el-table-column align="center" width="80">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.Stock"
            size="mini"
            @click="showDetalle(scope.row)"
            type="danger"
            >Ver</el-button
          >
          <el-button v-else disabled size="mini" type="danger">Ver</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="cantidad_elementos"
      background
      layout="total,prev, pager, next"
      :page-size="20"
      style="margin: 20px 0px; float: right"
      :total="cantidad_elementos"
      @current-change="nextPage"
      :current-page.sync="pagina">
    </el-pagination>

    <!-- TABLA DE DETALLES -->

    <el-dialog :visible.sync="dialogDetalle" width="95%" :top="'0vh'">
      <el-row :gutter="20"
        ><el-col>
          <center>
            <h4
              class="title-tabla-h4"
              style="margin-top: 0px; margin-bottom: 0px">
              {{ titleModal }}
            </h4>
          </center>
        </el-col>
      </el-row>
      <el-table
        :data="tablaDetalles"
        :height="altDetalle"
        border
        v-loading="cargandoVer">
        <el-table-column prop="Descripcion" label="Nombre"> </el-table-column>
        <el-table-column prop="Sucursal" label="Sucursal" width="150">
        </el-table-column>
        <el-table-column prop="Deposito" label="Deposito" width="150">
        </el-table-column>
        <el-table-column
          prop="cod_proveedor"
          label="Cod. Proveedor"
          width="110">
        </el-table-column>
        <el-table-column prop="cod_suizo" label="Cod. Suizo" width="80">
        </el-table-column>
        <el-table-column prop="Vencimiento" label="Vencimiento" width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.Vencimiento"
              >{{ formatFecha(scope.row.Vencimiento.date) }}
            </span>
            <span v-else><strong>-</strong></span>
          </template>
        </el-table-column>
        <el-table-column prop="Lote" label="Lote" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.Lote != null">{{ scope.row.Lote }} </span>
            <span v-else><strong>-</strong></span>
          </template>
        </el-table-column>
        <el-table-column prop="Estado" label="Estado" width="130">
        </el-table-column>
        <el-table-column prop="Stock" label="Stock" sortable width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.Stock != null && scope.row.Stock != 0"
              >{{ scope.row.Stock }}
            </span>
            <span v-else style="color: #dd6161"><strong>No</strong></span>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row :gutter="20"
        ><el-col>
          <center>
            <h4
              class="title-tabla-h4"
              style="margin-top: 0px; margin-bottom: 0px">
              Tabla de Reservas
            </h4>
          </center></el-col
        ></el-row
      >

      <el-table
        :data="tablaReservas"
        :height="altReserva"
        border
        v-loading="cargandoVer">
        <el-table-column prop="lote" label="Lote" width="100">
        </el-table-column>
        <el-table-column prop="cantidad" label="Reservas" width="100">
        </el-table-column>
        <el-table-column prop="Fecha" label="Fecha de Alta" width="150"
          ><template slot-scope="scope">
            <span v-if="scope.row.alta"
              >{{ formatFechaHr(scope.row.alta.date) }}
            </span>
            <span v-else><strong>-</strong></span>
          </template>
        </el-table-column>

        <el-table-column prop="motivo" label="Motivo"> </el-table-column>
        <el-table-column prop="operador" label="Operador"> </el-table-column>
        <el-table-column prop="vtolote" label="Venc. Lote" width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.vtolote"
              >{{ formatFecha(scope.row.vtolote.date) }}
            </span>
            <span v-else><strong>-</strong></span>
          </template>
        </el-table-column>
        <el-table-column prop="observac" label="Observaciones">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
const moment = require('moment')
import { HTTP } from '@/axios'

export default {
  data() {
    return {
      tableData: [],
      tablaDetalles: [],
      cargando: false,
      cantidad_elementos: 0,
      laborat: JSON.parse(window.localStorage.getItem('datos')).laborat,
      cargarTabla: false,
      pagina: 1,
      dialogDetalle: false,
      cargandoVer: false,
      titleModal: '',
      tablaReservas: [],
      altDetalle: 150,
      altReserva: 150,
    }
  },
  mounted() {
    EventBus.$on('cargandoStockDSA', () => {
      this.cargando = true
      this.cantidad_elementos = 0
    })

    EventBus.$on('cargarTablaStockDSA', (g) => {
      this.cargando = false
      this.tableData = g

      if (this.tableData.length == 0) {
        this.$message({
          message: 'No se encontraron artículos que concuerden con su búsqueda',
          type: 'warning',
        })
        this.cantidad_elementos = 0
      } else {
        window.scrollTo(0, 0)
        this.cantidad_elementos = this.tableData[0].TotalRows
      }
    })

    setTimeout(() => {
      EventBus.$on('limpiarStockDSA', () => {
        this.tableData = []
        this.cargando = false
        this.cantidad_elementos = 0
      })
    }, 700)
  },
  beforeDestroy() {
    EventBus.$off('cargarTablaStockDSA', false)
    EventBus.$off('cargandoStockDSA', false)
    EventBus.$off('limpiarStockDSA', false)
    EventBus.$off('cargarCarrito2', false)
  },
  methods: {
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    formatFechaHr(fecha) {
      return moment(fecha).format('DD/MM/YYYY hh:mm:ss')
    },
    nextPage(page) {
      //    alert(page)
      EventBus.$emit('cambiarPagina', page)
    },
    showDetalle(row) {
      // alert(row.id)
      // console.log(row)
      this.tablaDetalles = []
      this.tablaReservas = []
      this.titleModal = ''
      this.dialogDetalle = true
      this.cargandoVer = true
      HTTP.post('/vlogisticanew/stockDSA_detalle', {
        // HTTP.post('/vlogisticanew/stockDSA_detalle_probar', {
        codigo: row.suc_codigo,
        laborat: this.laborat,
        articulo: row.cod_suizo,
        deposito: row.Deposito,
      })
        .then((res) => {
          console.log(res.data)
          this.titleModal = 'Detalle de artículo ' + row.Descripcion
          if (res.data.length != 0) {
            this.tablaDetalles = res.data
            res.data.forEach((element) => {
              if (typeof element.reservas != 'undefined') {
                console.log(element.reservas)
                element.reservas.forEach((elemento) => {
                  this.tablaReservas.push(elemento)
                })
              }
            })
          }

          this.altDetalle = res.data.length > 3 ? 210 : 130
          this.altReserva = this.tablaReservas.length > 3 ? 210 : 130
          this.cargandoVer = false
        })
        .catch((error) => {
          this.dialogDetalle = false
          this.cargandoVer = false
          console.log(error)
        })
    },
  },
}
</script>
<style scoped>
.title-stock {
  margin: 0px !important;
  font-size: 15px;
  background-color: #36404e;
  color: #ffff;
  font-size: 13px;
}
.el-button--mini.is-circle {
  padding: 1px;
}
.title-tabla-h4 {
  background-color: #6969696b;
  padding: 5px;
}
</style>
