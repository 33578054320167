<template>
  <el-row :gutter="10">
    <el-col :xl="12" :lg="12" :md="24">
      <h4 style="font-size: 20px; margin: 2px; padding-top: 5px">Gestor de Docks</h4>
      <el-divider></el-divider>
      <el-table
        :data="tabla"
        size="medium"
        element-loading-text="Cargando..."
        ref="tablaTurnos"
        :show-header="false"
        :highlight-current-row="false"
        element-loading-spinner="el-icon-loading"
        v-loading="sistema.loadingDocks">
        <el-table-column label="NAVES" prop="nave" width="120" align="center">
          <template slot-scope="scope">
            <i class="fas fa-warehouse"></i><span style="margin-left: 10px">{{ scope.row.nave }}</span>
          </template>
        </el-table-column>
        <el-table-column label="DOCKS" align="center" prop="docks">
          <template slot-scope="scope">
            <el-row>
              <el-col v-for="dock in scope.row.docks" :key="dock.id" :span="8">
                <el-button
                  @click="changeDock(dock)"
                  :class="{
                    'dock-libre': dock.estado == 1,
                    'dock-ocupado': dock.estado == 0,
                    'dock-suspendido': dock.estado == 99,
                  }">
                  <div>DOCK Nº {{ dock.numero }}</div>
                </el-button>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :xl="12" :lg="12" :md="24">
      <h4 style="font-size: 20px; margin: 2px; padding-top: 5px">Gestor de feriados</h4>
      <el-divider></el-divider>
      <FullCalendar ref="fullCalendar" :options="calendarOptions" v-loading="sistema.loadingFechas">
        <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </el-col>
  </el-row>
</template>
<script>
import { API } from '@/plugins/api'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import multiMonthPlugin from '@fullcalendar/multimonth'
import esLocale from '@fullcalendar/core/locales/es'
import moment from 'moment'

export default {
  name: 'DocksTable',
  components: {
    FullCalendar,
  },
  async created() {
    await this.getDocks()
  },
  data() {
    return {
      tabla: [],
      sistema: {
        loadingFechas: true,
        loadingDocks: true,
        boton: 'btnCross',
        datos: false,
      },
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, multiMonthPlugin],
        headerToolbar: {
          right: 'prev,next',
          left: 'title',
          center: 'dayGridMonth,multiMonthYear',
        },
        locale: esLocale,
        timeZone: 'America/Argentina/Tucuman',
        initialView: 'dayGridMonth',
        eventDisplay: 'block',
        events: this.fetchEvents,
        // events: {
        //   url: process.env.VUE_APP_CROSS_BASE_URL + '/turnero/fechas',
        //   method: 'get',
        //   //failure: function () { alert('ERROR!') }
        // },
        weekends: true, //mostrar finde
        //editable: false, //editable
        //selectable: false, //seleccionar fechas
        lazyFetching: true,
        eventClick: this.borrarFecha,
        dateClick: this.setFecha,
      },
    }
  },
  methods: {
    changeDock(row) {
      if (row.estado == 0) return this.$swal('Atencion!', 'Para inhabilitar un dock, el mismo no debe estar ocupado', 'info')
      else {
        this.$swal({
          title: 'Cambiar estado de Dock ' + row.numero + ' - Nave ' + row.tipo,
          text: 'Esta acción provocara que no pueda seleccionarlo para asignarlo a un turno. Desa continuar?',
          icon: 'warning',
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: '#4caf50',
          cancelButtonText: 'No, Lo pensare mejor',
          confirmButtonText: 'Si, Continuar',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            API.put('turnero/docks/' + row.id, {})
              .then(() => {
                this.getDocks()
                return this.$swal('Dock actualizado!', 'Los cambios fueron guardados correctamente.', 'success')
              })
              .catch((error) => {
                return this.$swal('Error!', error.response.data.message, 'error')
              })
          },
        })
      }
    },
    setFecha(info) {
      const fechaDada = info.dateStr
      const fechaActual = moment().format('YYYY-MM-DD')
      const calendar = info.view.calendar
      const fechaSeleccionada = info.date
      const eventosEnFecha = calendar.getEvents().filter((event) => {
        return event.start.toISOString().split('T')[0] === fechaSeleccionada.toISOString().split('T')[0]
      })

      if (moment(fechaDada).isSameOrAfter(fechaActual, 'day') && eventosEnFecha.length <= 0) {
        this.$swal({
          title: '¿Desea bloquear la fecha ' + moment(info.dateStr, 'YYYY-MM-DD').format('DD/MM/YYYY') + '?',
          text: 'Debe agregar los motivos a continuacion',
          icon: 'warning',
          input: 'textarea',
          inputPlaceholder: 'Agregar nombre del feriado o motivo de bloqueo de fecha',
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonColor: '#999999',
          confirmButtonColor: '#f86c6b',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Bloquear',
          preConfirm: (result) => {
            API.post('turnero/fechas', { fecha: fechaDada, detalles: result })
              .then(() => {
                this.$events.fire('refrescarCalendario')
                return this.$swal('Fecha Agregada', 'Los cambios fueron guardados correctamente.', 'success')
              })
              .catch((error) => {
                return this.$swal('Error!', error.response.data.message, 'error')
              })
          },
        })
      }
    },
    async borrarFecha(info) {
      const fechaDada = info.event.startStr
      const fechaActual = moment().format('YYYY-MM-DD')

      if (moment(fechaDada).isSameOrAfter(fechaActual, 'day')) {
        this.$swal({
          title: 'Desea quitar la fecha: ' + moment(fechaDada, 'YYYY-MM-DD').format('DD/MM/YYYY') + '?',
          text: 'Luego de esta accion quedara libre para usar en una asignacion de turno',
          icon: 'warning',
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: '#4caf50',
          cancelButtonText: 'No, Lo pensare mejor',
          confirmButtonText: 'Si, Continuar',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            API.delete('turnero/fechas/' + info.event.id)
              .then(async () => {
                await this.$events.fire('refrescarCalendario')

                return this.$swal('Dock actualizado!', 'Los cambios fueron guardados correctamente.', 'success')
              })
              .catch((error) => {
                return this.$swal('Error!', error.response.data.message, 'error')
              })
          },
        })
      } else this.$swal('No puede quitar una fecha anterior a la actual', 'No se realizo ninguna accion', 'info')
    },
    async getDocks() {
      //this.sistema.loadingDocks = true
      await API.get('turnero/docks')
        .then((response) => {
          this.tabla = response.data.data
          this.sistema.loadingDocks = false
          //return this.$swal('Fecha Agregada', 'Los cambios fueron guardados correctamente.', 'success')
        })
        .catch((error) => {
          return this.$message({
            message: error.response.data.message,
            type: 'error',
          })
          //return this.$swal("Error!", error.response.data.message, "error")
        })
    },
    async fetchEvents(info, successCallback, failureCallback) {
      await API.get(process.env.VUE_APP_CROSS_BASE_URL + '/turnero/fechas', {
        params: {
          start: info.startStr,
          end: info.endStr,
        },
      })
        .then((response) => {
          this.sistema.loadingFechas = false
          successCallback(response.data.data)
        })
        .catch((error) => {
          failureCallback(error)
          return this.$swal('Error!', error.response.data.message, 'error')
        })
    },
  },
  events: {
    async refrescarCalendario() {
      let calendar = this.$refs.fullCalendar.getApi()
      await calendar.refetchEvents()
    },
  },
}
</script>
<style>
.fc-event {
  cursor: pointer;
}
.dock-libre {
  margin-bottom: 10px !important;
  background-color: #27ae60 !important;
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  color: white !important;
}
.dock-libre:hover {
  background-color: #2ecc71 !important; /* Cambia el color de fondo del hover según tu necesidad */
}
.dock-ocupado {
  margin-bottom: 10px !important;
  background-color: #f39c12 !important;
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  color: white !important;
}
.dock-ocupado:hover {
  background-color: #e67e22 !important; /* Cambia el color de fondo del hover según tu necesidad */
}
.dock-suspendido {
  margin-bottom: 10px !important;
  background-color: #c0392b !important;
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  color: white !important;
}
.dock-suspendido:hover {
  background-color: #e74c3c !important; /* Cambia el color de fondo del hover según tu necesidad */
}
</style>
