import axios from 'axios'
import store from '@/store'
import router from '@/router'

// En produccion `https://apicd.suizoargentina.com.ar/api`
// En desarrollo `http://api-seguimiento.test/api`
// Modificar .env para cambiar
const baseUrl = process.env.VUE_APP_CROSS_BASE_URL

const API = axios.create({
  baseURL: baseUrl,
  //timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  crossDomain: true,
})

API.interceptors.request.use(
  (config) => {
    const tokenFake = store.getters.getTokenFake
    //const token = store.getters.getToken
    if (tokenFake) {
      //console.log(token)
      config.headers.Authorization = `Bearer ${tokenFake}`
    } else {
      //console.log('no hay token')
      delete API.defaults.headers.common.Authorization
    }
    //config.params = { ...config.params, tokenFake }
    return config
  },
  (error) => Promise.reject(error)
)
API.interceptors.response.use(
  (response) => {
    //console.log("response ok");
    return response
  },
  (error) => {
    if (error.response.data.status == '401') {
      console.log('error 401 - saliendo...')
      //VUEX
      store.dispatch('logout').then(() => {
        if (window.localStorage.getItem('externo') === 'true') {
          window.localStorage.clear()
          window.sessionStorage.clear()
          window.location.href = '//web1.suizoargentina.com/login/salir'
        } else {
          window.localStorage.clear()
          window.sessionStorage.clear()
          router.push({ name: 'login' })
          window.location.reload()
        }
        console.log('Gracias vuelva pronto...')
      })
      //FIN VUEX
    }
    return Promise.reject(error)
  }
)

export { API }
