<template>
  <div>
    <div class="vguias_buscar">
      <el-row>
        <el-col :md="12" :sm="12" :xs="24">
          <h4 style="font-size: 24px; margin: -3px; margin-top: -10px; margin-bottom: 10px; float: left">
            <img v-if="empresaBuscar == 'u'" class="img-fluid" src="@/assets/001-usuario.png" style="height: 31px; margin-bottom: -4px" />
            <img v-if="empresaBuscar == 'lo'" class="img-fluid" src="@/assets/002-usuario-1.png" style="height: 31px; margin-bottom: -4px" />
            Usuarios
          </h4>
        </el-col>
        <el-col :md="12" :sm="12" :xs="24">
          <el-button v-if="esAdmin" type="default" size="small" style="float: right" @click="abrirUsuarios()" round><i class="el-icon-thumb"> </i> Usuarios {{ nombreEmpresa }} </el-button>
        </el-col>
      </el-row>
      <el-row> </el-row>
      <el-row :gutter="10">
        <el-form :label-position="'top'">
          <el-col :md="4" :sm="6" :xs="24">
            <label for="" class="vpim-label">Seleccionar Opción: </label>
            <el-select v-model="clave" :disabled="loading" placeholder="Buscar por..." size="mini" style="width: 100%; line-height: 0px !important" @change="cleanSelect">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-col>
          <el-col :md="8" :sm="8" :xs="24">
            <div v-if="clave == 'nombre'">
              <label for="" class="vpim-label">Ingrese Búsqueda:</label>
              <el-input
                id="caja_txt"
                :placeholder="'Ingrese ' + clave"
                v-model="valor"
                clearable
                @clear="eliminarFiltro(1)"
                :disabled="loading || !clave.length | conversion"
                size="mini"
                @input="habilitarBTN()"
                @keypress.native.enter="busquedaPor()"></el-input>
            </div>
            <div v-if="clave == 'select'">
              <label for="" class="vpim-label">Seleccione convenio:</label>
              <el-select v-model="valor" placeholder="Seleccione convenios" class="vpim-select" size="mini" clearable filterable @change="habilitarBTN()">
                <el-option v-for="conv in convenios" :key="conv.olconv" :label="conv.olconv + ' - ' + conv.descrip" :value="conv.olconv"> </el-option>
              </el-select>
            </div>
            <div v-if="clave == 'selectLog' && empresaBuscar == 'lo'">
              <label for="" class="vpim-label">Seleccione convenio Logística:</label>
              <el-select v-model="valor" placeholder="Seleccione convenios" class="vpim-select" size="mini" clearable filterable @change="habilitarBTN()">
                <el-option v-for="conv in convols" :key="conv.convol" :label="conv.convol + ' - ' + conv.descrip" :value="conv.convol"> </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :md="2" :sm="4" :xs="24" v-if="clave != ''">
            <el-button icon="el-icon-search" rounded style="margin-top: 17px" @click="busquedaPor()" :size="'small'" :loading="loadingBus" :disabled="deshabilitarBtn">Buscar </el-button>
          </el-col>
          <el-col :md="6" :sm="12" :xs="24">
            <el-button-group>
              <el-button
                type="info"
                size="mini"
                :style="styleEliminar"
                style="color: white; margin-top: 19px; border: 2px solid #b3b6bc"
                @click="eliminarFiltro(null)"
                :disabled="!filtro"
                :loading="loadingFil"
                ><i class="el-icon-delete"></i> Eliminar filtro
              </el-button>
              <el-button type="primary" size="mini" style="margin-top: 19px" @click="abrirModalCrearUsuario()" :style="styleBtn"
                ><i class="el-icon-circle-plus-outline"> </i> Crear usuario
              </el-button>
            </el-button-group>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <!-- ----------------------------------CREAR USUARIO------------------------------------------------ -->
    <el-dialog title="Crear Usuario" :visible.sync="dialogCrearUser" width="50%" center>
      <el-row :gutter="10">
        <el-form :label-position="'top'" :inline="true" size="small" label-width="100px" :model="formCrear">
          <el-col :md="12">
            <el-form-item label="Email" class="item_label">
              <el-input v-model="formCrear.email" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Activity zone">
              <el-input v-model="formCrear.region" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Activity form" class="item_label">
              <el-input v-model="formCrear.type" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Activity form">
              <el-input v-model="formCrear.type" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Activity form">
              <el-input v-model="formCrear.type" size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCrearUser = false" size="mini">Cancelar</el-button>
        <el-button type="primary" @click="dialogCrearUser = false" size="mini">Editar</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'

const moment = require('moment')
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      mostrar: false,
      loadPrincipal: true,
      activeIndex: '1',
      mensaje: '',
      pagina: 1,
      clave: 'nombre',
      valor: '',
      filtro: false,
      loading: false,
      loadingFil: false,
      loadingBus: false,
      loadingExcel: false,
      sucursales: [],
      sucursalUser: '',
      options: [
        {
          value: 'nombre',
          label: 'Nombre',
        },
        {
          value: 'select',
          label: 'Convenios',
        },
      ],
      exportar: false,
      formCrear: {
        email: '',
        region: '',
        type: '',
      },
      dialogCrearUser: false,
      permisos_usuario: JSON.parse(window.localStorage.getItem('datos')).permisos,
      permisos: { app_rfo: false, app_user: false },
      btnDisabled: false,
      styleBtn: { borderColor: '#02172e00', color: 'white' },
      styleEliminar: { borderColor: '#02172e00', color: 'white' },
      empresa: window.localStorage.getItem('empresa'),
      convenios: [],
      convol: [],
      deshabilitarBtn: true,
      esAdmin: window.localStorage.getItem('permisos').includes('OL_ABM_USUARIOS'),
      nombreEmpresa: '',
      empresaBuscar: window.localStorage.getItem('empresa'),
    }
  },
  created() {
    setTimeout(() => {
      this.mostrar = true
    }, 500)
  },
  mounted() {
    /* CAMBIAR NOMBRE DE EVENTO SI SELECCIONA USUARIOS LOGIN/SUIZO*/
    if (typeof this.$route.query.e != 'undefined') {
      this.empresaBuscar = this.$route.query.e
      this.formCrear.empresa = this.$route.query.e
    }
    /* ---------------PRIMERO QUE SE CARGA---------------------- */
    /* Si el usuario logueado es Admin puede ver todos los usuarios (suizo y login) */
    setTimeout(() => {
      if (this.esAdmin) {
        this.nombreEmpresa = this.empresaBuscar == 'lo' ? 'Suizo' : 'Log-in'
      }
    }, 100)

    setTimeout(() => {
      this.permisos_app()
    }, 50)

    setTimeout(() => {
      this.getUsers(1, null, null)
    }, 300)

    EventBus.$on('nuevaPagUser', (p) => {
      // setTimeout(() => {
      this.btnDisabled = true
      this.getUsers(p, this.clave, this.valor)
      return false
      // }, 100)
    })
    EventBus.$on('crearUsuario', () => {
      this.btnDisabled = true
      this.getUsers(1, null, null)
    })
    setTimeout(() => {
      if (this.empresaBuscar) this.getConvenios()
      this.getConvol() //SOLO PARA LOGIN
    }, 200)
  },
  beforeDestroy() {
    EventBus.$off('closeDetalles')
    EventBus.$off('nuevaPaginaAdmin')
    EventBus.$off('crearUsuario')
  },
  methods: {
    permisos_app() {
      this.permisos.app_rfo = this.permisos_usuario.indexOf('APP_RFO') != -1 ? true : false
      this.permisos.app_user = this.permisos_usuario.indexOf('APP_USUARIOS') != -1 ? true : false
      if (this.empresaBuscar == 'lo') {
        this.styleBtn['backgroundColor'] = '#e5590a'
        this.styleBtn['border'] = '2px solid #e5590a'
        this.options.push({ value: 'selectLog', label: 'Convenios Logística' })
      } else {
        this.styleBtn['backgroundColor'] = '#0b406a'
        this.styleBtn['border'] = '2px solid #0b406a'
      }
    },
    generarExcel() {
      setTimeout(() => {
        this.loadingExcel = true
        HTTP.get('/guiasNew/excelDispositivos')
          .then((res) => {
            this.loadingExcel = false
            window.location.href = res.data
          })
          .catch((error) => console.log(error))
      }, 100)
    },
    eliminarFiltro(tipo = null) {
      if (tipo == null) {
        this.valor = ''
        // this.clave = ''
        this.loadingFil = true
        this.loading = true
        this.filtro = false
        this.deshabilitarBtn = true
      }
      setTimeout(() => {
        this.loading = false
        this.loadingFil = false
        EventBus.$emit('limpiarTabla', true)
      }, 300)
      setTimeout(() => {
        this.btnDisabled = true
        this.getUsers(1, null, null)
      }, 350)
    },
    abrirModalCrearUsuario() {
      EventBus.$emit('abrirModalUser', {
        estado: 'crear',
        info: {},
        empresaB: this.empresaBuscar,
      })
    },
    getUsers(pagina, clave, valor) {
      this.pagina = pagina
      this.btnDisabled = true
      if (valor != '' && clave == 'nombre') {
        valor = valor.trim()
      }
      valor = valor != '' ? valor : null
      clave = clave == '' ? null : clave

      EventBus.$emit('cargandoUser', true)
      HTTP.post('/guiasNew/getUsers', {
        // HTTP_DESARROLLO.post('/guiasNew/getUsers_PRUEBA', {
        clave: clave,
        valor: valor,
        pagina: this.pagina,
        empresa: this.empresaBuscar,
      })
        .then((res) => {
          this.loading = false
          this.loadingBus = false
          this.loadingFil = false
          this.btnDisabled = false
          if (res.data.length == 0) {
            EventBus.$emit('cargarUsuarios', {
              tabla: [],
              pagina: 0,
              empresa: this.empresaBuscar,
            })
          } else {
            EventBus.$emit('cargarUsuarios', {
              tabla: res.data,
              pagina: this.pagina,
              empresa: this.empresaBuscar,
            })
            this.exportar = true
          }
        })
        .catch((error) => console.log(error))
    },
    busquedaPor() {
      this.loadingBus = true
      this.loading = true
      setTimeout(() => {
        this.filtro = true
      }, 300)
      this.getUsers(1, this.clave, this.valor)
    },
    getConvenios() {
      this.convenios = []
      HTTP.post('/guiasnew/getConvenios', {
        ol_lista: false,
        guia_convenios: [],
      })
        .then((res) => {
          this.convenios = res.data
          this.cargandoConv = false
          EventBus.$emit('conveniosGet', res.data)
        })
        .catch((error) => console.log(error))
    },
    getConvol() {
      HTTP.get('/guiasnew/getConvols')
        .then((res) => {
          this.convols = res.data
          EventBus.$emit('convolsGet', res.data)
        })
        .catch((error) => console.log(error))
    },
    habilitarBTN() {
      this.deshabilitarBtn = this.valor != '' ? false : true
    },
    cleanSelect() {
      this.valor = ''
    },
    abrirUsuarios() {
      let urlpp = window.location.host,
        array_url = urlpp.split('/'),
        empresa_nombre = array_url[0].split('.')
      // console.log(empresa_nombre)
      // empresa_nombre[1] = this.empresaBuscar == 'u' ? "suizoargentina" : 'log-in'
      if (empresa_nombre[1] == 'suizoargentina') {
        if (this.empresaBuscar == 'u') {
          window.open('https://seguimiento.suizoargentina.com.ar/#/usuarios?e=lo', '_blank')
          // window.open('http://localhost:8080/#/usuarios?e=lo', "_blank");
        } else {
          window.open('https://seguimiento.suizoargentina.com.ar/#/usuarios?e=u', '_blank')
          // window.open('http://localhost:8080/#/usuarios?e=lo', "_blank");
        }
      } else if (empresa_nombre[1] == 'log-in') {
        if (this.empresaBuscar == 'u') {
          window.open('https://seguimiento.log-in.com.ar/#/usuarios?e=lo', '_blank')
          //  window.open('http://localhost:8080/#/usuarios?e=lo', "_blank");
        } else {
          window.open('https://seguimiento.log-in.com.ar/#/usuarios?e=u', '_blank')
          //  window.open('http://localhost:8080/#/usuarios?e=u', "_blank");
        }
      } else {
        window.open('http://localhost:8080/#/usuarios?e=u', '_blank')
        this.empresaBuscar = 'u'
      }
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD-MM-YYYY H:mm:ss')
    },
    fecha: function (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    desde: function (date) {
      return moment(date).fromNow()
    },
    conversion: function (date) {
      return date ? true : false
    },
  },
}
</script>
<style scoped>
.r_button {
  width: 100%;
}
.r_button:last-child {
  width: 100%;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  /* padding: 0; */
}

a {
  color: #42b983;
}
.right-sep {
  /* padding: 1em 2em; */
  border-right: 3px solid rgb(224, 222, 222);
}
.el-form-item--small .el-form-item__content,
.el-form-item--small .el-form-item__label {
  line-height: 32px;
  /* padding-right: 10px; */
}
.loading {
  min-height: 100vh;
  background: white;
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
* Generated by Animista on 2020-12-21 15:22:19
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation slide-in-bottom
* ----------------------------------------
*/
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:hover,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:active {
  background-color: #c0c4cc !important;
}
.el-item_label {
  line-height: 0px !important;
}
</style>
