<template>
  <div>
    <el-row :gutter="20">
      <center>
        <el-col :md="24">
          <p class="font-title" style="background-color: rgb(11 64 106)">Total de Facturas:{{ cantFacturas }}</p>
        </el-col>
      </center>
    </el-row>
    <el-row :gutter="20">
      <el-col :md="24">
        <el-table border fixed size="mini" :data="tableData" v-loading="loadingTabla" height="400" style="width: 100%">
          <el-table-column sortable prop="fecha" label="Fecha">
            <template slot-scope="scope">
              <span v-if="scope.row.fecha">{{ scope.row.fecha.date | fechaE }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column sortable prop="comprob" label="Tipo" with="50"> </el-table-column>
          <el-table-column sortable prop="letra" label="Letra" with="10"> </el-table-column>
          <el-table-column prop="terminal" label="Comprobante">
            <template slot-scope="scope">
              <span>{{ scope.row.terminal }}-{{ scope.row.numero }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="sucursal" label="Sucursal"
            ><template slot-scope="scope">
              <span>{{ scope.row.sucursal }}-{{ scope.row.nombre }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Operaciones" width="100" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" @click="generarPdf(scope.row)" :loading="scope.row.id == loadingBtn">Ver Pdf</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length"
          background
          layout="total,prev, pager, next"
          :total="cantFacturas"
          :page-size="20"
          style="margin-top: 20px; float: right; padding-bottom: 1rem"
          @current-change="nextFactura"
          :current-page.sync="pagina_actual">
        </el-pagination>
      </el-col>
    </el-row>
    <el-dialog :modal="false" :visible.sync="modalPDF" width="500px" top="2vh" class="soporteModal">
      <el-carousel :autoplay="false" arrow="always" height="80vh" :indicator-position="'none'">
        <el-carousel-item class="comprob" @click.native="openPdf(archivo)">
          <pdf :src="archivo"></pdf>
        </el-carousel-item>
      </el-carousel>
      <el-col>
        <center>
          <el-button size="mini" class="block" type="danger" style="margin-bottom: 5px" @click="openPdf(archivo)">DESCARGAR FACTURA {{ archivoNumero }} </el-button>
        </center>
      </el-col>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="modalPDF = false">Cerrar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const moment = require('moment')
import { HTTP } from '@/axios'
import pdf from 'vue-pdf'

export default {
  components: {
    pdf,
  },
  data() {
    return {
      tableData: [],
      loadingBtn: false,
      loadingTabla: false,
      cantFacturas: 0,
      pagina_actual: 1,
      form: {},
      modalPDF: false,
      archivo: null,
      archivoNumero: null,
    }
  },

  mounted() {
    this.$events.listen('loading_facturas', (eventData) => {
      this.loadingTabla = eventData
    })
    this.$events.listen('set_facturas', (eventData) => {
      console.log(eventData)
      this.loadingTabla = false
      this.tableData = eventData.datos_tabla
      this.cantFacturas = eventData.datos_tabla.length ? eventData.datos_tabla[0].TotalRows : 0
      this.form = eventData.formulario
    })
  },
  beforeDestroy() {
    this.$events.$off('set_facturas')
    this.$events.$off('loading_facturas')
  },
  methods: {
    generarPdf(datos) {
      this.loadingBtn = datos.id
      // let sucursal = datos.sucursal.toString.length < 2 ? '0' + datos.sucursal : datos.sucursal
      let urlCompleta = 'FAC' + datos.comprob + datos.letra + datos.terminal + datos.numero + datos.sucursal + '10100000032'
      HTTP.post('/escaner/downloadPDFFacturas', { ruta: urlCompleta })
        .then((res) => {
          console.log(res.data.status)
          if (res.data.status == 1) {
            this.archivo = 'https://seguimiento.suizoargentina.com.ar/seguimiento' + res.data.archivo
            this.loadingBtn = false
            if (this.archivo !== null) {
              this.archivoNumero = datos.comprob + datos.letra + datos.terminal + datos.numero
              this.modalPDF = true
            }
          } else {
            this.loadingBtn = false
            alert('Error al descargar la factura. Intente nuevamente.')
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    openPdf() {
      window.open(this.archivo, '_blank')
    },
    nextFactura() {
      this.loadingTabla = true
      this.form.pagina = this.pagina_actual
      HTTP.post('/escaner/getFacturasSuizo', this.form)
        .then((res) => {
          this.loadingTabla = false
          this.tableData = res.data
          this.cantFacturas = res.data.length ? res.data[0].TotalRows : 0
        })
        .catch((e) => {
          this.$alert('Error al descargar el archivo ', e)
        })
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD-MM-YYYY H:mm:ss')
    },
    fecha: function (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    fechaE: function (date) {
      return moment(date).format('DD-MM-YYYY H:mm:ss')
    },
    desde: function (date) {
      return moment(date).fromNow()
    },
  },
}
</script>
<style scoped>
.el-table th {
  background: #f0f2f5 !important;
  border: 1px solid #f0f2f5 !important;
  font-weight: normal;
  color: #000;
}
.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #04aa6d;
  /* padding: 14px 28px; */
  /* font-size: 12px; */
  cursor: pointer;
  text-align: center;
}
</style>
