<template>
  <div v-loading="sistema.loading">
    <el-row style="padding-bottom: 8px" :gutter="10">
      <el-col :span="12" :xs="24">
        <p></p>
      </el-col>
      <el-col :span="12" :xs="24">
        <div style="float: right">
          <el-button size="small" @click="$events.fire('open-form-guias', 'ALTA')" icon="el-icon-edit" :class="sistema.boton">Carga Manual</el-button>
          <el-button size="small" @click="sistema.modalImportar.visible = true" type="primary" :class="sistema.boton"> <i class="fas fa-file-import"></i> Importar archivo</el-button>
        </div>
      </el-col>
    </el-row>
    <br />
    <guias-create-form v-if="this.sistema.modalGuia.visible" :modal="this.sistema.modalGuia" />
    <guias-importar v-if="this.sistema.modalImportar.visible" :modal="this.sistema.modalImportar" />
    <form-bultos v-if="this.sistema.modalAnexo.bultos" :modal="this.sistema.modalAnexo" />
    <form-comprobantes v-if="this.sistema.modalAnexo.comprobantes" :modal="this.sistema.modalAnexo" />
    <div v-show="this.guias.length > 0">
      <el-row :gutter="10">
        <el-col :span="24">
          <vuetable
            ref="vuetable"
            v-loading="sistema.loaderTable"
            :api-mode="false"
            :data="guias"
            :fields="fields"
            :css="css.table"
            :row-class="onRowClass"
            @vuetable:cell-clicked="onCellClicked"
            detail-row-component="guias-details"
            noDataTemplate="Sin Datos">
            <template slot="guia" slot-scope="props">
              <div @click="$events.fire('mostrar-detalles', props.rowData.id)">
                <div v-if="props.rowData.origen == 'ARCHIVO'">{{ props.rowData.terminal.toString().padStart(5, '0') }}-{{ props.rowData.numero.toString().padStart(8, '0') }}</div>
                <div v-else>
                  <p>Carga Manual</p>
                </div>
              </div>
            </template>
            <template slot="comprobantes" slot-scope="props">
              <div @click="$events.fire('mostrar-detalles', props.rowData.id)">
                <span class="el-tag el-tag--success">{{ props.rowData.comprobantes.length }}</span>
              </div>
            </template>
            <template slot="bultos" slot-scope="props">
              <div @click="$events.fire('mostrar-detalles', props.rowData.id)">
                <span class="el-tag el-tag--success">{{ props.rowData.bultos.length }}</span>
              </div>
            </template>
            <template slot="total_peso" slot-scope="props">
              <div @click="$events.fire('mostrar-detalles', props.rowData.id)">
                <span>{{ calcularBultos(props.rowData).peso_declarado }}</span>
              </div>
            </template>
            <template slot="total_volumen" slot-scope="props">
              <div @click="$events.fire('mostrar-detalles', props.rowData.id)">
                <span>{{ calcularBultos(props.rowData).volumen_declarado }}</span>
              </div>
            </template>
            <template slot="total_valor_declarado" slot-scope="props">
              <div @click="$events.fire('mostrar-detalles', props.rowData.id)">
                <span>${{ calcularBultos(props.rowData).valor_declarado }}</span>
              </div>
            </template>
          </vuetable>
        </el-col>
      </el-row>
      <br />
      <el-row :gutter="10">
        <el-col :span="24">
          <div style="float: right">
            <el-button @click="saveGuias()" icon="el-icon-check" type="warning"> Confirmar</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-show="this.guias.length <= 0">
      <el-alert type="info" show-icon :closable="false" center>
        <span slot="title">
          <p>Para iniciar la carga de guias, puede usar la <b>Carga Manual</b> o <b>Importar</b> desde un archivo</p>
        </span>
      </el-alert>
    </div>
    <br />
  </div>
</template>
<script>
import { API } from '@/plugins/api'
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import GuiasDetails from './GuiasDetails'
import GuiasActions from './GuiasActions'
import GuiasCreateForm from './GuiasCreateForm'
import GuiasImportar from './GuiasImportar'
import FormComprobantes from './FormComprobantes'
import FormBultos from './FormBultos'
import { css } from '@/components/cross/_guias'
import { mapGetters, mapActions } from 'vuex'

Vue.component('guias-details', GuiasDetails)
Vue.component('guias-actions', GuiasActions)

export default {
  name: 'GuiasCreate',
  components: {
    Vuetable,
    GuiasCreateForm,
    FormComprobantes,
    FormBultos,
    GuiasImportar,
  },
  data() {
    return {
      guias: [],
      fields: [
        {
          name: 'id',
          title: '#',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          visible: true,
        },
        {
          name: 'origen',
          visible: false,
        },
        {
          name: '__slot:guia',
          title: 'Guia',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '100px',
        },
        {
          name: 'convenio',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'razon_social',
          title: 'Razon social',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '200px',
        },
        {
          name: 'cuit',
          title: 'CUIT',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '200px',
          visible: false,
        },
        {
          title: 'Codigo Postal',
          name: 'codigo_postal',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
        },
        {
          name: 'provincia',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
        },
        {
          name: 'localidad',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
        },
        {
          name: 'direccion',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
        },
        {
          name: 'cobranza',
          callback: this.callCobranza,
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          title: 'Valor',
          name: 'valor_cobranza',
          callback: this.callValorCobranza,
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },

        {
          name: '__slot:comprobantes',
          title: 'Comprobantes',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          width: '80px',
        },
        {
          name: '__slot:bultos',
          title: 'Bultos',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          width: '30px',
        },
        {
          name: '__slot:total_peso',
          title: 'Total Peso',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
        },
        {
          name: '__slot:total_volumen',
          title: 'Total Volumen',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
        },
        {
          name: '__slot:total_valor_declarado',
          title: 'Total Valor Declarado',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
        },
        {
          name: '__component:guias-actions',
          title: 'Acciones',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          width: '125px',
        },
      ],
      css,
      sistema: {
        boton: 'btnCross',
        loading: true,
        loaderTable: false,
        modalGuia: {
          visible: false,
          tipo: null,
          row: null,
          tipoPorte: [],
          convenios: [],
          convenios_simple: [],
        },
        modalAnexo: {
          comprobantes: false,
          bultos: false,
          rowGuia: null,
          tiposBultos: [],
        },
        modalImportar: {
          visible: false,
          convenios: [],
        },
      },
    }
  },
  created() {
    this.sistema.modalGuia.convenios = this.getGuiasConfig.convenios_full
    this.sistema.modalGuia.convenios_simple = this.getGuiasConfig.convenios
    this.sistema.modalAnexo.tiposBultos = this.getGuiasConfig.bultos
    this.sistema.modalGuia.tipoPorte = this.getGuiasConfig.porte
    this.sistema.modalImportar.convenios = this.getGuiasConfig.convenios_full
  },
  mounted() {
    this.guias = this.getGuiasCross
    this.sistema.loading = false
    //console.log(this.guias)
  },
  methods: {
    ...mapActions('Crossdocking', ['setCrossFetch']),
    calcularBultos(guia) {
      var bultM = {}
      bultM['peso_declarado'] = 0
      bultM['volumen_declarado'] = 0
      bultM['valor_declarado'] = 0
      //bultos
      guia.bultos.forEach((obj) => {
        bultM['peso_declarado'] = bultM['peso_declarado'] + obj.peso_declarado
        bultM['volumen_declarado'] = bultM['volumen_declarado'] + obj.volumen_declarado
        bultM['valor_declarado'] = bultM['valor_declarado'] + obj.valor_declarado
      })

      return bultM
    },
    callCobranza(value) {
      if (value == 1) return '<p>SI</p>' //
      else return '<p>NO</p>'
    },
    callValorCobranza(value) {
      if (value == null) return '-'
      else return '$' + value
    },
    onCellClicked(data) {
      if (this.$refs.vuetable.isVisibleDetailRow(data.id)) {
        this.$refs.vuetable.hideDetailRow(data.id)
      } else {
        this.$refs.vuetable.visibleDetailRows = []
        this.$refs.vuetable.showDetailRow(data.id)
      }
    },
    onRowClass(dataItem) {
      if (this.$refs.vuetable.isVisibleDetailRow(dataItem.id)) return 'selected-row'
      if (dataItem.comprobantes.length <= 0 || dataItem.bultos.length <= 0) return 'selected-row-warning'
    },
    saveGuias() {
      //Validacion Comprobantes y Bultos
      var contadorComprobante = 0
      var contadorBultos = 0
      this.guias.forEach((element) => {
        if (element.comprobantes.length <= 0) {
          contadorComprobante++
        }
        if (element.bultos.length <= 0) {
          contadorBultos++
        }
      })
      if (contadorComprobante >= 1) return this.$swal('Atencion!', 'Debe cargar al menos un comprobante en cada guia.', 'warning')
      if (contadorBultos >= 1) return this.$swal('Atencion!', 'Debe cargar al menos un bulto en cada guia.', 'warning')
      //Busqueda de guias duplicadas
      const busqueda = this.guias.reduce((acc, guia) => {
        if (guia.origen === 'ARCHIVO') {
          const clave = JSON.stringify(guia.terminal + guia.numero)
          acc[clave] = ++acc[clave] || 0
        }
        return acc
      }, {})

      const duplicados = this.guias.filter((guia) => {
        return busqueda[JSON.stringify(guia.terminal + guia.numero)]
        //return busqueda[guia.numero];
      })
      if (duplicados.length > 0) return this.$swal('Atencion!', 'Existe ' + duplicados.length / 2 + ' guias duplicadas en la tabla.', 'warning')

      //Busqueda de Comprobantes duplicados en guias
      //Agrupar las guías por convenio
      const guiasPorConvenio = {}
      this.guias.forEach((guia) => {
        if (!guiasPorConvenio[guia.convenio]) guiasPorConvenio[guia.convenio] = [guia]
        else guiasPorConvenio[guia.convenio].push(guia)
      })
      //Verifico por convenio
      for (const convenio in guiasPorConvenio) {
        const guias = guiasPorConvenio[convenio]
        const compDuplicados = guias.reduce((acum, guia) => {
          guia.comprobantes.forEach((comprobante) => {
            if (acum[comprobante.codigo]) acum[comprobante.codigo]++
            else acum[comprobante.codigo] = 1
          })
          return acum
        }, {})
        for (let codigo in compDuplicados) {
          if (compDuplicados[codigo] > 1) {
            return this.$swal('Atencion!', `El comprobante "${codigo}" está duplicado en guias.`, 'warning')
          }
        }
      }
      //Mandar Array de guias
      this.$swal.fire({
        title: '¿Desea procesar las guias cargadas?',
        text: 'Recuerde que no podrá revertir esta acción',
        icon: 'question',
        showCancelButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return API.post('/cross/guias', {
            guias: this.guias,
          })
            .then((response) => {
              this.guias = []
              this.setCrossFetch([...this.guias])
              this.$refs.vuetable.setData([])
              Vue.nextTick(() => this.$refs.vuetable.setData(this.guias))

              return this.$swal('Guardar', response.data.message, 'success')
            })
            .catch((error) => {
              return this.$swal('Error!', error.response.data.message, 'error')
            })
        },
      })
    },
  },
  events: {
    refrescarTabla() {
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'close-modal'() {
      this.sistema.modalImportar.visible = false
      this.sistema.modalGuia.visible = false
      this.sistema.modalAnexo.comprobantes = false
      this.sistema.modalAnexo.bultos = false
    },
    'mostrar-detalles'(id) {
      if (this.$refs.vuetable.isVisibleDetailRow(id)) {
        this.$refs.vuetable.hideDetailRow(id)
      } else {
        this.$refs.vuetable.visibleDetailRows = []
        this.$refs.vuetable.showDetailRow(id)
      }
    },
    'open-form-guias'(tipo, row) {
      if (tipo == 'ALTA') {
        this.sistema.modalGuia.row = null
        this.sistema.modalGuia.tipo = 'ALTA'
      }
      if (tipo == 'EDITAR') {
        this.sistema.modalGuia.row = row
        this.sistema.modalGuia.tipo = 'EDITAR'
        this.$refs.vuetable.hideDetailRow(row.id)
      }

      this.sistema.modalGuia.visible = true
    },
    'open-form-anexo'(rowGuia, tipo) {
      this.sistema.modalAnexo.rowGuia = rowGuia
      if (tipo == 'C') this.sistema.modalAnexo.comprobantes = true
      if (tipo == 'B') this.sistema.modalAnexo.bultos = true
    },
    'add-guia'(guia, tipo) {
      this.sistema.modalGuia.visible = false
      this.sistema.loaderTable = true
      var mensaje = null
      switch (tipo) {
        case 'ALTA':
          this.guias.push(guia)
          mensaje = 'Se agregó una nueva Guia en la tabla de precarga'
          break
        case 'EDITAR':
          var objIndex = this.guias.findIndex((obj) => obj.id == guia.id)
          this.guias[objIndex] = guia
          mensaje = 'Se actualizo correctamente la Guia en la tabla de precarga'
          break
      }
      //reorganizo ID
      var i = 0
      while (i < this.guias.length) {
        this.guias[i]['id'] = i + 1
        i++
      }
      //Copio en Store
      this.setCrossFetch([...this.guias])
      this.sistema.loaderTable = false
      this.$refs.vuetable.setData([])
      Vue.nextTick(() => this.$refs.vuetable.setData(this.guias))
      return this.$swal('Guardar', mensaje, 'success')
    },
    'add-comprobante'(rowGuia, comprobantes) {
      this.sistema.modalAnexo.comprobantes = false
      this.sistema.loaderTable = true
      var objIndex = this.guias.findIndex((obj) => obj.id == rowGuia.id)
      this.guias[objIndex].comprobantes = comprobantes
      //Controlo si tengo comprobantes con contra entrega
      const acumContraentrega = comprobantes.reduce((acum, comprobante) => {
        if (comprobante.contraentrega === '1') {
          return acum + 1
        } else {
          return acum
        }
      }, 0)
      if (acumContraentrega > 0) this.guias[objIndex].contraentrega = 1
      else this.guias[objIndex].contraentrega = 0
      //Fin control
      setTimeout(() => {
        this.setCrossFetch([...this.guias])
        this.sistema.loaderTable = false
        this.$refs.vuetable.setData([])
        Vue.nextTick(() => this.$refs.vuetable.setData(this.guias))
      }, 500)
    },
    'add-bultos'(rowGuia, bultos) {
      this.sistema.modalAnexo.bultos = false
      this.sistema.loaderTable = true
      var objIndex = this.guias.findIndex((obj) => obj.id == rowGuia.id)
      this.guias[objIndex].bultos = bultos
      setTimeout(() => {
        this.setCrossFetch([...this.guias])
        this.sistema.loaderTable = false
        this.$refs.vuetable.setData([])
        Vue.nextTick(() => this.$refs.vuetable.setData(this.guias))
      }, 500)
    },
    'importar-guia'(guias, mensajeServer) {
      this.sistema.modalImportar.visible = false
      this.sistema.loaderTable = true
      guias.forEach((guia) => {
        this.guias.push(guia)
      })
      var i = 0
      while (i < this.guias.length) {
        this.guias[i]['id'] = i + 1
        i++
      }
      this.setCrossFetch([...this.guias])
      this.sistema.loaderTable = false
      this.$message({ message: mensajeServer, type: 'success' })
    },
    'remove-guia'(rowIndex) {
      //var objIndex = this.guias.findIndex((obj => obj.id == rowData.id))
      this.guias.splice(rowIndex, 1)
      this.setCrossFetch([...this.guias])
      this.$refs.vuetable.setData([])
      //reorganizo ID
      var i = 0
      while (i < this.guias.length) {
        this.guias[i]['id'] = i + 1
        i++
      }
      Vue.nextTick(() => this.$refs.vuetable.setData(this.guias))
    },
  },
  computed: {
    ...mapGetters('Crossdocking', ['getGuiasConfig', 'getGuiasCross']),
  },
}
</script>
