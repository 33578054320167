<template>
  <el-button-group>
    <el-tooltip class="item" effect="dark" content="Ver detalles" placement="left">
      <el-button size="mini" round @click="detalles(rowData.id)" icon="el-icon-search" type="primary"></el-button>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Editar" placement="bottom-end">
      <el-button size="mini" round @click="editarGuia(rowData)" icon="el-icon-edit" type="success"></el-button>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Eliminar" placement="top-start">
      <el-button size="mini" round @click="eliminarGuia(rowData, rowIndex)" icon="el-icon-delete" type="danger"></el-button>
    </el-tooltip>
  </el-button-group>
</template>
<script>
export default {
  name: 'guiasActions',
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },
  methods: {
    editarGuia(rowData) {
      this.$events.fire('open-form-guias', 'EDITAR', rowData)
    },
    eliminarGuia(rowData, rowIndex) {
      this.$swal
        .fire({
          title: '¿Desea borrar la Guia #: ' + rowData.id,
          text: 'Recuerde que no podrá revertir esta acción',
          icon: 'warning',
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) this.$events.fire('remove-guia', rowIndex)
        })
    },
    detalles(value) {
      this.$events.fire('mostrar-detalles', value)
    },
  },
}
</script>
