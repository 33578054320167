<template>
    <div >
       <h2> Modificar dirección de correo electrónico (e-mail) </h2>
       <el-row :gutter="10" >
        <el-col :md="14" :sm='14' :xs='24'>
            <el-form class="hidden-xs-only" :label-position="labelPosition" label-width="300px" :model="formEmail" :disabled="permisos_usuario.clave_ex!=null" >
                <el-form-item label="Dirección de correo electrónico (e-mail)" prop="email"  :loading="loadingDatos"
                    :rules="[
                    { required: true, message: 'Ingrese email', trigger: 'blur' },
                    { type: 'email', message: 'Ingrese un email correcto', trigger: ['blur'] }
                    ]">
                    <el-input v-model="formEmail.email" :disabled='disabledText' ref="user_email" 
                    
                    ></el-input>
                </el-form-item>
            </el-form>
            <el-form class="hidden-sm-and-up" :label-position="labelPosition" :model="formEmail" :disabled="permisos_usuario.clave_ex!=null">
                <el-form-item label="Dirección de correo electrónico (e-mail)" prop="email"  
                    :rules="[
                    { required: true, message: 'Ingrese email', trigger: 'blur' },
                    { type: 'email', message: 'Ingrese un email correcto', trigger: ['blur', 'change'] }
                    ]">
                    <el-input v-model="formEmail.email" :disabled='disabledText' ref="user_email" 
                    
                    ></el-input>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :md="10" :sm='10' :xs='24'>
            <el-button :type="tipoBtn" @click="modificarEmail"  :icon="iconBtn" :disabled="disabledMod" :style="styleBtnBuscar">{{titleBtn}}</el-button>
            <el-button type="primary" icon="el-icon-check" :disabled="disabledEmail" @click="guardarEmail" :loading="loadingBuscar">Guardar</el-button>
        </el-col>
       </el-row>  
       <br>
        <el-row>
            <el-col :md="14" :sm='24' :xs='24'>
            <el-alert  title="Atención"   type="info"   :description="mensaje" :closable="false" show-icon></el-alert>
            </el-col> 
        </el-row>  
    </div>
</template>
<script>
import {HTTP} from '@/axios'

export default {
   data() {
        
        return {
            activeName      : 'first',
            labelPosition   : 'right',
            formEmail       : {
                email: '',
            },
            mensaje         :'Esta dirección de e-mail será la principal para su usuario y le permitirá recuperar su usuario y/o contraseña en caso de ser necesario.',
            disabledEmail   :true,        
            showErrores     :false,
            errorContra     :'',
            alertContra     :'',
            permisos_usuario:window.localStorage.getItem("permisos"),
            empresa         : window.localStorage.getItem('empresa'),
            datos           :JSON.parse(window.localStorage.getItem("datos")),
            clave_ex        :window.localStorage.getItem("clave_ex"),
            uid             :'',
            loadingBuscar   :false,
            disabledMod     :true,
            disabledText    :true,
            styleBtnBuscar  :{borderColor: '#02172e00',color:'white'},
            titleBtn        :'Modificar',
            loadingDatos    :true,
            tipoBtn         :'warning',
            iconBtn         :'el-icon-edit',
        };      
    },
    mounted() {   
        
        if(this.empresa == 'lo')
        {
            // Consultar los datos del usuarios
            HTTP.post('/guiasNew/consultarDatos',{'usuario':this.datos['user']})
            .then( res=>{
                this.formEmail.email    = res.data['email'];
                this.uid                = res.data['id'];
                this.loadingDatos       = this.formEmail.email !=''? false: true
            }).catch(error=>{
                this.formEmail.email    = '';
                this.uid                = '';
                this.loadingDatos       = false
                console.log(error)
                this.$alert('No se pudo cargar los datos. Intente nuevamente.');
            });
        }
        else if(this.empresa == 'u')
        {
            // Dejar los datos de session
            this.formEmail.email    = this.datos.email;
            this.uid                = this.datos['uid']
            this.loadingDatos       = this.formEmail.email !=''? false: true            
        }
        this.disabledMod    = false  
       
    }, 
    methods: {
        modificarEmail()
        {
            if(!this.disabledEmail)
            {
                this.disabledEmail  = true;
                this.disabledText   = true;
                 this.titleBtn      = 'Modificar';
                this.tipoBtn        = 'warning'
                this.iconBtn        = 'el-icon-edit'
            }else
            {
                this.disabledEmail  = false;
                this.$refs.user_email.focus();
                this.disabledText   = false;
                this.titleBtn       = 'Cancelar';
                this.tipoBtn        = 'danger';
                this.iconBtn        = 'el-icon-close'
            }          
        },
        guardarEmail()
        {
           this.loadingBuscar   = true;
           this.disabledMod     = true;
           this.disabledText    = true;
          
            HTTP.post('/guiasNew/editarEmail',{email:this.formEmail.email.trim(),tipo:this.empresa,uid:this.uid})
            .then( res=>{
                
                switch (res.data) {
                    case 0:
                            this.$message({
                                showClose: true,
                                message: 'Error, no se pudo realizar la acción.',
                                type: 'error'
                            });
                            this.loadingBuscar = false;
                            this.disabledEmail = false;
                            this.titleBtn      = 'Cancelar'
                            this.disabledEmail = false;
                            this.$refs.user_email.focus();
                            this.disabledText   = false;
                            this.tipoBtn        = 'danger';
                            this.iconBtn        = 'el-icon-close'
                        break;
                    case 1:
                            this.$message({
                                showClose: true,
                                message: 'El cambio se realizó con éxito.',
                                type: 'success'
                            }); 
                            this.disabledEmail  = true;
                            this.titleBtn       = 'Modificar'
                            this.tipoBtn        = 'warning';
                            this.iconBtn        = 'el-icon-edit'
                        break;
                    case 2:
                        this.$message({
                            showClose: true,
                            message: 'Error. Email en uso o incorrecto.',
                            type: 'error'
                            });
                            this.titleBtn       = 'Cancelar'
                            this.disabledEmail  = false;
                            this.$refs.user_email.focus()
                            this.disabledText   = false;
                            this.tipoBtn        = 'danger'
                            this.iconBtn        = 'el-icon-close'
                        break;
                    default:
                        this.$message({
                            showClose: true,
                            message: 'Hubo un error, intente nuevamente',
                            type: 'warning'
                            });
                             this.titleBtn          = 'Modificar'
                            this.disabledEmail      = false;
                            this.$refs.user_email.focus();
                            this.disabledText       = false;
                            this.tipoBtn            = 'warning';
                            this.iconBtn            = 'el-icon-edit'
                        break;
                }
                this.disabledMod     = false;
                this.loadingBuscar   = false;
            }).catch(error=>{
                    this.$alert('Error del servidor '+error);
            });
        },
    },
}
</script>