<template>
  <div>
    <el-row>
      <el-col :md="24">
        <el-alert title="Atención" type="info" description="Sólo los comprobantes que fueron procesados a partir de Mayo 2022 pueden consultarse." show-icon :closable="false"> </el-alert>
      </el-col>
    </el-row>
    <br />
    <el-row :gutter="10">
      <el-col :md="10">
        <el-card shadow="never" :body-style="{ padding: '0px', minHeight: '350px' }">
          <div slot="header">
            <el-row :gutter="10"
              ><span><i class="el-icon-search"></i> Buscador de Índices</span>
              <hr
            /></el-row>
            <!-- <el-row :gutter="10"><hr></el-row> -->
            <el-form ref="form" :model="formIndice" label-width="80px" size="mini" :inline="true" style="padding-top: 10px; padding-bottom: 0px" label-position="left">
              <el-row :gutter="10">
                <el-col :md="17">
                  <label for>Fecha</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="formIndice.fecha_buscar"
                    type="month"
                    value-format="yyyyMM"
                    format="MM-yyyy"
                    id="hola"
                    placeholder="Seleccione mes"
                    size="small"
                    :disabled="disTipo">
                  </el-date-picker>
                </el-col>
                <el-col :md="7">
                  <label for>Escaneo</label>
                  <el-select v-model="formIndice.empresa" placeholder="Seleccione" @change="getIndicesMostrar(1)" style="width: 100%" size="small" clearable>
                    <el-option v-for="item in opcion_empresas" :key="item.id" :label="item.nombre" :value="item.id"> </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <br />
              <el-row :gutter="20">
                <el-col :md="17">
                  <label for>Índice</label>
                  <el-input v-model="formIndice.search" size="small" placeholder="Buscar índice" clearable @keypress.native.prevent.enter="getIndicesMostrar"></el-input>
                </el-col>
                <!-- </el-row>
                    <el-row :gutter="20">              -->
                <el-col :md="7">
                  <el-form-item size="large">
                    <!-- <el-button @click="getIndicesMostrar()" style="float:right;margin-bottom: -15px;margin-top: 20px;"
                                size="small" type="primary" icon="el-icon-search" :loading="buscando_load"> Buscar</el-button> -->
                    <el-button-group style="margin-bottom: -15px; margin-top: 15px">
                      <el-button type="info" icon="el-icon-search" size="small" @click="getIndicesMostrar()" style="padding-left: 5px; padding-right: 5px">Buscar</el-button>
                      <el-tooltip class="item" effect="dark" content="Eliminar búsqueda" placement="top-start">
                        <el-button type="danger" size="small" @click="deleteDatos('indices')" style="padding-left: 5px; padding-right: 5px"
                          ><i class="el-icon-close el-icon-right"></i></el-button
                      ></el-tooltip>
                    </el-button-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <br />
            </el-form>
          </div>
          <div>
            <el-table
              :data="indicesxmes.filter((data) => !search || data.estado.toLowerCase().includes(search.toLowerCase()))"
              height="300"
              size="mini"
              border
              stripe
              fit
              style="width: 100%"
              v-loading="buscando_load">
              <el-table-column prop="fecha" label="Fecha" width="130">
                <template slot-scope="scope">
                  <span v-if="scope.row.fecha">
                    {{ scope.row.fecha.date | moment }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="indice" label="Índice">
                <template slot-scope="scope">
                  <span @click="getComprobantesIndice(scope.row.indice)">{{ scope.row.indice }}</span>
                </template>
              </el-table-column>
              <el-table-column width="70" align="center" prop="empresa" label="Escaneo">
                <template slot-scope="scope">
                  <span v-if="scope.row.empresa == 'LOG'">Log-in</span>
                  <span v-if="scope.row.empresa == 'DSA'">Suizo</span>
                </template>
              </el-table-column>
              <el-table-column prop="estado" label="Estado" width="100" align="center">
                <template slot-scope="scope">
                  <span style="text-transform: uppercase">{{ scope.row.estado }}</span>
                </template>
              </el-table-column>
              <el-table-column width="70" align="center"
                >|
                <!-- <template slot="header" slot-scope="{}">
                                <el-input||
                                v-model="search"
                                size="mini" clearable
                                placeholder="Filtrar Estado"/>
                            </template> -->
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.estado != 'procesados'"
                    style="padding-left: 5px; padding-right: 5px"
                    size="mini"
                    type="danger"
                    :loading="cargandoVer == scope.row.indice"
                    @click="buscarIndice(scope.row)"
                    >Ver</el-button
                  >
                  <el-button v-else size="mini" type="success" :loading="cargandoVer == scope.row.indice" style="padding-left: 5px; padding-right: 5px" @click="buscarIndice(scope.row)"
                    >Ver</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              v-if="cant_datos > 0"
              background
              layout="total,prev, pager, next"
              :page-size="20"
              style="margin: 20px 0px; float: right"
              :total="cant_datos"
              @current-change="nextPage"
              :current-page.sync="pagina">
            </el-pagination>
          </div>
        </el-card>
      </el-col>
      <el-col :md="14">
        <el-card shadow="never" :body-style="{ padding: '0px', minHeight: '350px' }">
          <div slot="header">
            <el-row :gutter="10"
              ><span><i class="el-icon-search"></i> Buscar Comprobantes</span>
              <hr
            /></el-row>
            <el-form ref="form" :model="formComprob" label-width="100px" size="mini" :inline="true" style="padding-top: 10px; padding-bottom: 0px" label-position="left">
              <el-row :gutter="10">
                <el-col :md="10">
                  <label for>Índice</label>
                  <el-input
                    v-model="formComprob.indice"
                    id="chau"
                    size="small"
                    placeholder="Buscar por índice"
                    clearable
                    width="100"
                    :disabled="disabled_buscando"
                    @keypress.native.prevent.enter="getComprobMostrar(1)"></el-input>
                  <div class="el-form-item__error" style="position: relative" v-if="error_indice">{{ error_indice }}</div>
                </el-col>
                <el-col :md="8">
                  <label for>Comprobante</label>
                  <el-input
                    v-model="formComprob.search"
                    minlength="4"
                    id="gi"
                    size="small"
                    placeholder="Buscar por comprobante"
                    clearable
                    @keypress.native.prevent.enter="getComprobMostrar(1)"
                    :disabled="disabled_buscando"></el-input>
                  <div class="el-form-item__error" style="position: relative" v-if="error_comprob">{{ error_comprob }}</div>
                </el-col>
                <el-col :md="6">
                  <label for>Escaneo</label>
                  <el-select v-model="formComprob.empresa" placeholder="Seleccione" style="width: 100%" size="small" clearable :disabled="disabled_buscando">
                    <el-option v-for="item in opcion_empresas" :key="item.id" :label="item.nombre" :value="item.id"> </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <br />
              <el-row :gutter="10">
                <el-col :md="5">
                  <label for>Estado</label>
                  <el-select v-model="formComprob.estado" placeholder="Seleccione" :disabled="disabled_buscando" style="width: 100%" size="small">
                    <el-option v-for="item in opciones_estado" :key="item.id" :label="item.id" :value="item.id"> </el-option>
                  </el-select>
                </el-col>
                <el-col :md="15">
                  <label for>Fecha de procesamiento</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="formComprob.fecha_procesamiento"
                    :disabled="disabled_buscando"
                    type="daterange"
                    align="right"
                    size="small"
                    unlink-panels
                    value-format="yyyyMMdd"
                    format="dd-MM-yyyy"
                    range-separator="a"
                    start-placeholder="desde"
                    end-placeholder="hasta"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </el-col>
                <el-col :md="4" style="float: right !important">
                  <el-form-item size="large">
                    <el-button-group style="margin-bottom: -15px; margin-top: 15px">
                      <el-button type="info" icon="el-icon-search" size="small" @click="getComprobMostrar()" style="padding-left: 5px; padding-right: 5px" :disabled="disabled_buscando"
                        >Buscar</el-button
                      >
                      <el-tooltip class="item" effect="dark" content="Eliminar búsqueda comprobantes" placement="top-start">
                        <el-button type="danger" size="small" @click="deleteDatos('comprobantes')" style="padding-left: 5px; padding-right: 5px">
                          <i class="el-icon-close el-icon-right"></i>
                        </el-button>
                      </el-tooltip>
                    </el-button-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <br />
              <el-row :gutter="10">
                <div v-if="formComprob.estado == 'ERROR'">
                  <el-col :md="6" :sm="8" :xs="10">
                    <label for>Filtrar comprobantes:</label>
                    <el-switch style="margin-top: 10px" v-model="valorswitch" active-text="Editables" :disabled="disabled_buscando" inactive-text="Con errores" @change="getComprobMostrar()">
                    </el-switch>
                  </el-col>
                </div>
              </el-row>
              <!-- <br>
                        <el-row :gutter="20">
                            <el-col :md="10">
                                <label for>Fecha</label>
                                <el-date-picker
                                    width="100%"
                                    v-model="formComprob.fecha"
                                    type="daterange"
                                    start-placeholder="Desde"
                                    end-placeholder="Hasta" size ="small" format="dd/MM/yyyy" :picker-options="pickerOptions">
                                    </el-date-picker>
                            </el-col>
                            <el-col :md="4" style="">
                                <el-form-item size="large">
                                <el-button-group style="margin-bottom:-15px;margin-top:15px;">
                                <el-button type="info" icon="el-icon-search" size="small" @click="getComprobMostrar()"
                                style="padding-left: 5px; padding-right: 5px;" :disabled="disabled_buscando">Buscar</el-button>
                                <el-tooltip class="item" effect="dark" content="Eliminar búsqueda comprobantes"
                                placement="top-start">
                                    <el-button type="danger" size="small" @click="deleteDatos('comprobantes')"
                                    style="padding-left: 5px; padding-right: 5px;">
                                    <i class="el-icon-close el-icon-right" ></i>
                                    </el-button>order by fchcomp desc
                                </el-tooltip>
                                </el-button-group>
                                </el-form-item>
                            </el-col>

                        </el-row> -->
            </el-form>
          </div>
          <div>
            <el-table
              :data="comprobantes.filter((data) => !search || data.detalle.toLowerCase().includes(search.toLowerCase()))"
              height="300"
              size="small"
              border
              fit
              style="width: 100%"
              v-loading="buscando_comprob"
              empty-text="Sin comprobantes">
              <el-table-column prop="fecha" label="Fecha procesamiento" width="150">
                <template slot-scope="scope">
                  <span v-if="scope.row.fecha_indice">
                    {{ scope.row.fecha_indice.date | moment }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="nombre" label="Índice">
                <template slot-scope="scope">
                  <span @click="getTodo(scope.row.nombre)">{{ scope.row.nombre }}</span>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="comprobante" label="Comprobante"> </el-table-column>
              <el-table-column width="70" prop="empresa" label="Empresa">
                <template slot-scope="scope">
                  <span v-if="scope.row.empresa == null"><center>Log-in</center></span>
                  <span v-else><center>Suizo</center></span>
                </template>
              </el-table-column>
              <el-table-column prop="detalle" label="Estado" width="150" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.btn_show && !scope.row.borrado">
                    <!-- <el-tag v-if="scope.row.btn_show=='btn_ok'" effect="dark"  size="small"
                                        type="success">{{scope.row.detalle}}</el-tag> -->
                    <div v-if="scope.row.btn_show == 'btn_ok'">
                      <el-button type="success" size="mini" @click="showPdf(scope.row)"> {{ scope.row.detalle }}</el-button>
                      <!-- <el-button-group>
                                            <el-button type="success" size="mini"
                                            @click="showPdf(scope.row)">
                                            {{scope.row.detalle}}</el-button>
                                            <el-popover
                                            placement="top-start"
                                            width="20"
                                            trigger="hover"
                                            content="Subir comprobante">
                                            <el-button type="primary" slot="reference" size="mini"
                                            @click="showSubir(scope.row)"
                                            >
                                            SUBIR</el-button></el-popover>
                                            </el-button-group> -->
                    </div>
                    <div v-if="scope.row.btn_show == 'btn_error'">
                      <el-button type="warning" size="mini" @click="showModal(scope.row)"> {{ scope.row.detalle }}</el-button>
                    </div>
                    <div v-if="scope.row.btn_show == 'btn_corregido'">
                      <el-button type="info" plain size="mini" v-if="scope.row.detalle == 'CORREGIDO'" @click="showModal(scope.row)"> {{ scope.row.detalle }}</el-button>
                    </div>
                    <div v-if="scope.row.btn_show == 'btn_fecha'">
                      <el-button type="info" plain size="mini" style="padding: 5px" @click="getDatosComprobante(scope.row, 'fecha')"> EDITAR FECHA</el-button>
                    </div>
                    <div v-if="scope.row.btn_show == 'btn_editar' && scope.row.comprobante != ''">
                      <el-button type="danger" size="mini" @click="getDatosComprobante(scope.row, 'todo')" style="margin: 0px -10px"> EDITAR</el-button>
                    </div>
                    <div v-if="scope.row.btn_show == 'btn_editar' && scope.row.comprobante == ''">-</div>

                    <!-- <div v-if="scope.row.btn_show != 'btn_editar'">
                                            <el-button type="danger"  size="mini"
                                            @click="getDatosComprobante(scope.row,'todo')">
                                            SUBIR</el-button>
                                        </div> -->
                  </div>
                  <div v-else-if="!scope.row.btn_show && !scope.row.borrado">
                    <el-tag effect="dark" size="small" type="danger">No existe</el-tag>
                  </div>
                  <div v-else-if="scope.row.borrado">
                    <el-tag effect="dark" size="small" type="danger" style="background-color: #c9182b !important; border-color: #c9182b">ELIMINADO</el-tag>
                  </div>
                  <!--<div v-if="scope.row.btn_show=='btn_error'">
                                                <el-button type="danger" size="mini"
                                                @click="showModal(scope.row.item,scope.row.nombre,scope.row.comprobante,scope.row.fecha_cambio)">
                                                {{scope.row.detalle}}</el-button>
                                            </div>
                                            <div v-else-if="scope.row.fecha_escaneo == null">
                                                <el-button type="info" plain size="mini" style="padding:5px;"
                                                @click="getDatosComprobante(scope.row,'fecha')">
                                                EDITAR FECHA</el-button>
                                            </div>

                                        </div>

                                    </div> -->
                  <!-- <div  v-if="scope.row.detalle == 'ERROR' && scope.row.guia_terminal==-1">
                                            <el-tooltip class="item" effect="dark" content="Editar comprobante" placement="right-end">
                                                <el-button type="warning"  size="mini"
                                                @click="getDatosComprobante(scope.row,'todo')">
                                                EDITAR</el-button>
                                            </el-tooltip>
                                    </div> -->
                  <!-- <div v-if="scope.row.detalle == 'CORREGIDO'">
                                        <el-button type="info" plain  size="mini"
                                        v-if="scope.row.detalle == 'CORREGIDO'"
                                        @click="showModal(scope.row.item,scope.row.nombre,scope.row.comprobante,scope.row.fecha_cambio)">
                                        {{scope.row.detalle}}</el-button>
                                    </div> -->
                  <!-- {{scope.row.guia_terminal}} -->
                  <!-- <div  v-if="scope.row.detalle == 'ERROR' && scope.row.guia_terminal==null">
                                            <el-button type="info" size="mini"
                                                @click="showModal(scope.row.item,scope.row.nombre,scope.row.comprobante,scope.row.fecha_cambio)">
                                                {{scope.row.detalle}}</el-button>
                                    </div> -->
                  <!-- <div v-else-if="scope.row.fecha_escaneo==null && scope.row.guia_terminal">
                                        <el-row :gutter="10" v-if="scope.row.detalle == 'ERROR'">
                                            <el-tooltip class="item" effect="dark" content="Editar fecha de escaneo" placement="right-end">
                                                <el-button type="info" plain size="mini" style="padding:5px;"
                                                @click="getDatosComprobante(scope.row,'fecha')">
                                                EDITAR FECHA</el-button>
                                            </el-tooltip>
                                        </el-row>
                                    </div> -->
                  <!-- <div v-if="scope.row.fecha_cambio">
                                        <el-tag  v-if="scope.row.detalle == 'ERROR'" effect="dark" type="info"  size="small">EDITADO</el-tag>
                                    </div>                                    -->
                </template>
              </el-table-column>
              <el-table-column prop="borrado" label="Acción" width="70" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.borrado == 0">
                    <center>
                      <el-tooltip class="item" effect="dark" content="Eliminar comprobante" placement="left-end">
                        <el-button
                          type="info"
                          icon="el-icon-delete"
                          @click="deleteComprob(scope.row, 1)"
                          :disabled="eliminando != false"
                          :loading="scope.row.id == eliminando"
                          size="mini"
                          style="background-color: #c9182b !important; border-color: #c9182b"
                          circle>
                          <!-- style="background-color: tomato !important;
                                                border-color: #f44336;" circle> -->
                        </el-button>
                      </el-tooltip>
                    </center>
                  </span>
                  <span v-else>
                    <center>
                      <el-tooltip class="item" effect="dark" content="Activar comprobante" placement="left-end">
                        <el-button
                          type="success"
                          icon="el-icon-check"
                          @click="deleteComprob(scope.row, 0)"
                          :disabled="eliminando != false"
                          :loading="scope.row.id == eliminando"
                          size="mini"
                          circle>
                        </el-button>
                      </el-tooltip>
                    </center>
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              v-if="total_comprobantes > 0"
              background
              layout="total,prev, pager, next"
              :page-size="20"
              style="margin: 20px 0px; float: right"
              :total="total_comprobantes"
              @current-change="nextPageComprob"
              :current-page.sync="pagina_comprob">
            </el-pagination>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- MODALES -->
    <el-dialog title="Datos de Índice" :visible.sync="dialogDatos" width="55%" center top="0vh">
      <hr style="margin-top: -25px" />
      <h3 style="text-align: center">{{ indiceB }}</h3>
      <h3 style="color: #f56c6c">Ubicación actual : {{ carpetaB }}</h3>
      <h4>Total de comprobantes : {{ datosIndices.length }}</h4>

      <el-table :data="datosIndices" style="width: 100%" height="300">
        <el-table-column prop="comprob" label="Comprobantes" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.comprob && scope.row.errores.length == 0">{{ scope.row.comprob }}</span>
            <span v-else-if="scope.row.comprob" style="color: #f56c6c">{{ scope.row.comprob }}</span>
            <span v-else-if="scope.row.comprob == ''">-</span>
          </template>
        </el-table-column>
        <el-table-column prop="fecha_escaneo_2" label="Fecha escaneo" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.archivo.length">
              <span v-if="scope.row.fecha_escaneo_2 && scope.row.archivo[0].fecha_arch == scope.row.fecha_comparar">{{ scope.row.fecha_escaneo_2 }}</span>
              <span v-else-if="scope.row.fecha_escaneo_2 && scope.row.archivo[0].fecha_arch != scope.row.fecha_comparar" style="color: firebrick">
                {{ scope.row.fecha_escaneo_2 }} - (Ver fecha archivo)
              </span>
              <span v-else-if="scope.row.fecha_escaneo_2 == ''">-</span>
              <span v-else style="text-align: center">-</span>
            </div>
            <div v-else>
              {{ scope.row.fecha_escaneo_2 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="archivo" label="PDFs asociados al Índice">
          <template slot-scope="scope">
            <div v-if="scope.row.archivo.length">
              <div v-for="arch in scope.row.archivo" :key="arch.fecha_arch + 1">
                <i class="el-icon-caret-right"></i> <span v-if="arch.fecha_arch">{{ arch.archivo }}</span>
              </div>
            </div>
            <div v-else><span>-</span></div>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-alert title="Los comprobantes marcados con rojo tienen algún error." type="info" show-icon :closable="false"> </el-alert>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDatos = false" type="primary" icon="el-icon-close">Cerrar</el-button>
        <!-- <el-button @click="procesarNuevo" v-if="comparacion && estadoIndice!='procesados'" type="warning"
                    :loading="procesar" icon="el-icon-refresh-right">
                        Volver a procesar
                    </el-button> -->
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisibleComprobante" width="60%" top="1vh">
      <el-row style="border-bottom: 1px dashed #ddd; margin-top: -25px; margin-bottom: 10px" :gutter="10">
        <el-col :md="24">
          <span style="float: right; font-size: 18px; text-aling: center; margin-right: 50px"
            ><strong> <i class="el-icon-document-checked"></i> {{ tituloError }}</strong>
          </span>
          <!-- <span style="float:left; font-size:18px;text-aling:center;margin-right: 50px;">
                    <strong>{{tituloGuiaError}}</strong>
                </span> -->
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="24">
          <h3 style="text-align: center; background-color: #f9e8af; padding: 5px; margin-left: 5px; margin-top: 0px; margin-bottom: 5px">{{ tituloGuiaError }}</h3>
          <el-collapse accordion style="margin-bottom: 5px; margin-left: 5px">
            <el-collapse-item :title="'ERRORES EN EL COMPROBANTE AL PROCESAR EL INDICE: ' + indice_error.toUpperCase()" name="1">
              <!-- <span style="color:#F56C6C">{{indice_error.toUpperCase()}}</span> -->
              <el-alert
                style="font-size: 12px; margin-top: 5px"
                title="Los siguientes errores se encontraron en el comprobante al procesar el índice pero no interrumpieron su asociación con una guía por lo tanto no es necesaria su correción."
                type="info"
                :closable="false">
              </el-alert>
              <ul v-for="(d, index) in datos_item" :key="index">
                <li>{{ d.toUpperCase() }}</li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="24">
          <div v-loading="cargando_imagen">
            <el-col :md="24" v-if="pdfs.length">
              <iframe :src="pdfs" width="100%" height="500" allow="autoplay"></iframe>
            </el-col>
            <el-col :md="24" v-else>
              <div class="demo-image__error" style="height: 160px">
                <div class="block">
                  <p class="demonstration"><center>Imagen con error</center></p>
                  <el-image style="height: 100px; width: 100px"></el-image>
                </div>
              </div>
            </el-col>
          </div>
        </el-col>
        <!-- <blockquote style="white-space: break-spaces;">
                    <i class="el-icon-d-arrow-right"></i><i class="el-icon-d-arrow-left"></i>
                </blockquote> -->
      </el-row>

      <div v-if="fecha_cambio != null">
        <hr />
        <div style="background-color: #6e6e6e; padding: 10px; color: white">
          <p>ATENCIÓN</p>
          <span>{{ descripcion_fecha_cambio }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleComprobante = false">Cerrar</el-button>
      </span>
    </el-dialog>
    <!-- MODAL EDITAR -->
    <el-dialog title="" :fullscreen="false" :visible.sync="dialog_editar_comprobante" :close-on-click-modal="false" width="100%" top="1vh" height="30%" @close="quitarElementos()">
      <el-row style="border-bottom: 1px dashed #ddd; margin-top: -25px; margin-bottom: 10px; padding-top: 5px" :gutter="10">
        <el-col :md="24">
          <span style="float: left; font-size: 20px; text-aling: center"
            ><strong> <i class="el-icon-edit"></i>{{ titleModal }}</strong></span
          >
          <span style="float: right; font-size: 20px; text-aling: center; padding-right: 5px; margin-top: -10px"><strong> </strong></span>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="10">
          <el-form :inline="true" :model="formBuscar" :rules="regla1" ref="formBuscar" class="demo-ruleForm">
            <!-- Formulario para los otros -->
            <el-form-item label="Comprobante" prop="comprobante">
              <el-input
                v-model="formBuscar.comprobante"
                ref="inputComprob"
                size="mini"
                minlength="12"
                placeholder="Ingrese comprobante"
                clearable
                :disabled="buscando"
                @keypress.native.prevent.enter="buscarEscaneo('formBuscar')"
                @clear="show_msj = false" />
              <!-- <div class="el-form-item__error" style="white-space: pre;" v-if="errorBuscar!='' && formBuscar.comprobante==null"></div> -->
              <div class="el-form-item__error" style="white-space: pre" v-if="show_msj">Comprobante previamente cargado</div>
            </el-form-item>
            <!-- ------------------------ -->
            <el-form-item>
              <el-button-group v-if="tipo_busqueda != 'fecha'">
                <el-button type="primary" size="mini" title="Buscar comprobante" @click="buscarEscaneo('formBuscar')">Buscar</el-button>
                <el-button size="mini" @click="eliminarBusqueda()">Limpiar</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <div v-loading="cargando_imagen">
            <el-col :md="24" v-if="pdfs.length">
              <iframe :src="pdfs" width="100%" height="500" allow="autoplay"></iframe>
            </el-col>
            <el-col :md="24" v-else>
              <div class="demo-image__error" style="height: 140px">
                <div class="block">
                  <p class="demonstration"><center>Imagen con error</center></p>
                  <el-image style="height: 100px; width: 100px"></el-image>
                </div>
              </div>
            </el-col>
          </div>
        </el-col>
        <el-col :md="14">
          <el-row :gutter="10">
            <el-col :md="24">
              <el-collapse style="background-color: red; margin-bottom: 11px; font-size: 15px">
                <el-collapse-item :title="'ERRORES EN EL COMPROBANTE AL PROCESAR EL INDICE: ' + indice_error.toUpperCase()" name="1">
                  <!-- <span style="color:#F56C6C">{{indice_error.toUpperCase()}}</span> -->
                  <ul v-for="(d, index) in datos_item" :key="index">
                    <li>{{ d.toUpperCase() }}</li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </el-col>
          </el-row>
          <el-row v-if="showTablaComprobantes" style="margin-bottom: 10px">
            <p style="margin-bottom: 1px">
              Seleccionar la guía correcta que desea asociarse al comprobante <span style="font-weight: 800; color: black">{{ this.formBuscar.comprobante }}</span>
            </p>
            <el-table :data="tabla_comprobantes" style="width: 100%" size="mini">
              <el-table-column fixed="right" label="" width="40">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="Elegir comprobante" placement="right-end">
                    <el-button type="success" icon="el-icon-check" plain size="mini" @click="cargarFormulario(scope.row)" circle></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="Comprobante">
                <template slot-scope="scope">{{ scope.row.comprob }}</template>
              </el-table-column>
              <el-table-column label="N° Guía">
                <template slot-scope="scope">{{ scope.row.guia_terminal }}-{{ scope.row.guia_numero }}</template>
              </el-table-column>
              <el-table-column label="Sucursal" align="center">
                <el-table-column label="Pres." width="65">
                  <template slot-scope="scope">
                    <span v-if="scope.row.presentacion_suc != null">{{ scope.row.presentacion_suc }}</span>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
                <el-table-column label="Guia" width="65%">
                  <template slot-scope="scope">
                    <span v-if="scope.row.guia_suc != null">{{ scope.row.guia_suc }}</span>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
                <el-table-column label="Canal." width="65%">
                  <template slot-scope="scope">
                    <span v-if="scope.row.canalizador_suc != null">{{ scope.row.canalizador_suc }}</span>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="Conv." width="50%" prop="olconv">
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="right-start">
                    <p style="font-size: 11px">{{ scope.row.olconv }}-{{ scope.row.descrip }}</p>
                    <div slot="reference" class="name-wrapper">
                      {{ scope.row.olconv }}
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="Fecha comp.">
                <template slot-scope="scope">
                  <span v-if="scope.row.fecha_comprob"> {{ scope.row.fecha_comprob.date | moment }}</span>
                  <span v-else> -</span>
                </template>
              </el-table-column>
            </el-table>
          </el-row>

          <div v-loading="loadingModal">
            <div v-if="show_formulario">
              <modal-editar-comprob
                :datos="datos_props"
                :comproba="formBuscar.comprobante"
                :sucursales="sucProps"
                :comprob_extra="comprob_extra"
                :tipo_busqueda="tipo_busqueda"
                :convenios="convenios">
              </modal-editar-comprob>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- MODAL PDF -->
    <el-dialog title="" :visible.sync="dialog_pdfs" width="70%" top="1vh" height="30%">
      <el-row style="border-bottom: 1px dashed #ddd; margin-top: -25px; margin-bottom: 10px" :gutter="10">
        <el-col :md="24">
          <span style="float: right; font-size: 18px; text-aling: center; margin-right: 102px"
            ><strong> <i class="el-icon-document-checked"></i> {{ title_pdfs }}</strong></span
          >
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="24">
          <h3 style="text-align: center; background-color: #f9e8af; padding: 5px; margin-left: 5px; margin-top: 0px; margin-bottom: 5px">{{ title_pdfs_guia }}</h3>
          <div v-loading="cargando_imagen">
            <el-col :md="24" v-if="pdfs.length">
              <iframe :src="pdfs" width="100%" height="500" allow="autoplay"></iframe>
            </el-col>
            <el-col :md="24" v-else>
              <div class="demo-image__error" style="height: 160px">
                <div class="block">
                  <p class="demonstration"><center>Imagen con error</center></p>
                  <el-image style="height: 100px; width: 100px"></el-image>
                </div>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- <subir-comprobante-new></subir-comprobante-new> -->
  </div>
</template>

<script>
import { HTTP } from '@/axios'
const moment = require('moment')
import ModalEditarComprob from './modalEditarComprob.vue'
// import subirComprobanteNew from './subirComprobanteNew.vue';
export default {
  components: {
    ModalEditarComprob,
    //   subirComprobanteNew
    // pdf,
  },
  props: ['indice', 'sucProps'],
  data() {
    let checkTamanio = (rule, value, callback) => {
      if (value == null) {
        return callback(new Error('Ingrese número de comprobante'))
      } else {
        return callback()
        // if (value.length < 11) {
        //     return callback(new Error('Debe tener al menos 12 caracteres'));
        // } else {
        //     // return callback(new Error('Comprobante cargado'));
        //     return callback();
        // }
      }
    }
    return {
      buscando: undefined,
      loading: true,
      indices: [],
      carpeta: '/',
      empresa: window.localStorage.getItem('empresa') == 'u' ? 'DSA' : 'LOG',

      indicesxmes: [],
      buscando_load: false,
      cant_datos: 0,
      pagina: 1,
      usuario: JSON.parse(window.localStorage.getItem('datos'))['user'],
      formIndice: {
        search: '',
        fecha_buscar: '',
        selectEstado: '',
        empresa: '',
      },
      dialogDatos: false,
      options: [
        { key: 0, label: 'TODOS', value: null },
        { key: 1, label: 'INCOMPLETOS', value: 'incompletos' },
        { key: 2, label: 'ERRORES', value: 'error' },
        { key: 3, label: 'PROCESADOS', value: 'procesados' },
      ],
      datosIndices: [],
      indiceB: '',
      carpetaB: '',
      archivosPdfs: '',
      cargandoVer: false,
      comparacion: false,
      search: '',
      disTipo: false,
      procesar: false,
      estadoIndice: '',
      /* Nueva parte */
      formComprob: {
        search: '',
        indice: '',
        estado: '',
        fecha_procesamiento: [],
        empresa: '',
      },
      buscando_comprob: false,
      pagina_comprob: 1,
      comprobantes: [],
      total_comprobantes: 0,
      dialogVisibleComprobante: false,
      fecha_cambio: null,
      datos_item: [],
      indice_error: '',
      tituloError: '',
      nombre_comprobante: '',
      tituloGuiaError: '',
      opciones_estado: [
        { estado: 'OK', id: 'OK' },
        { estado: 'ERROR', id: 'ERROR' },
        { estado: 'CORREGIDO', id: 'CORREGIDO' },
        { estado: 'ELIMINADO', id: 'ELIMINADO' },
      ],
      opcion_empresas: [
        { nombre: 'Suizo', id: 'DSA' },
        { nombre: 'Log-in', id: 'LOG' },
      ],
      cantidad_original: 0,
      cantidad_busqueda: 0,
      error_comprob: '',
      error_indice: '',
      dialog_editar_comprobante: false,
      pdfs: '',
      empresaUrl: window.localStorage.getItem('empresa'),
      formBuscar: {
        comprobante: null,
      },
      regla1: {
        comprobante: [
          { validator: checkTamanio, trigger: ['change', 'blur'] },
          { required: true, message: 'Campo obligatorio', trigger: 'blur' },
        ],
      },
      titleModal: '',
      errorBuscar: '',
      datos_props: '',
      loadingModal: false,
      comprob_extra: '',
      tipo_busqueda: '',
      valorswitch: true,
      disabled_buscando: false,
      descripcion_fecha_cambio: '',
      cargando_imagen: false,
      dialog_pdfs: false,
      title_pdfs: '',
      title_pdfs_guia: '',
      comprob_subir: '',
      show_datos: false,
      tipo_modal_titulo: '',
      pickerOptions: {
        disabledDate(date) {
          return date > new Date() || date < new Date('2022-06-07T00:00:00')
        },
      },
      show_msj: true,
      comprobante_auxiliar: '',
      tabla_comprobantes: [],
      multipleSelection: [],
      showTablaComprobantes: false,
      show_formulario: false,
      eliminando: false,
      operador: JSON.parse(window.localStorage.getItem('datos')).uid + '-' + JSON.parse(window.localStorage.getItem('datos')).user,
      convenios: [],
    }
  },
  created() {
    this.getConvenios()
  },
  mounted() {
    //    console.log(new Date())
    this.$events.listen('quitar_formulario', (eventData) => {
      // alert('jajaja')
      if (eventData) {
        this.show_formulario = false
      }
    })
    if (window.localStorage.getItem('comprob') != null && window.localStorage.getItem('comprob') != '') {
      this.formComprob.search = window.localStorage.getItem('comprob')
      this.getComprobMostrar(1)
      window.localStorage.removeItem('comprob')
    }
  },
  beforeDestroy() {
    this.$events.off('quitar_formulario')
  },
  methods: {
    buscarIndice(row) {
      setTimeout(() => {
        this.getComprobantesIndice(row.indice)
      }, 300)
      this.cargandoVer = row.indice
      this.comparacion = false
      this.estadoIndice = row.estado
      this.indiceB = ''
      let url = row.empresa == 'DSA' ? '/robotescaner/buscarIndice' : '/robotescanerlogin/buscarIndice'
      HTTP.post(url, { indice: row.indice, estado: row.estado, empresa: row.empresa })
        .then((res) => {
          //    console.log(res.data)
          if (!res.data) {
            //this.$message.error('El archivo no contiene datos '+row.indice);
            this.dialogDatos = true
            this.cargandoVer = false
            this.datosIndices = []
            this.indiceB = row.indice
            this.carpetaB = 'CARPETA ' + row.estado.toUpperCase()
          } else if (res.data != 2) {
            this.dialogDatos = true
            this.cargandoVer = false
            if (res.data != 3) {
              this.archivosPdfs = res.data.archivos != 0 ? res.data.archivos : null
              let anio = '',
                mes = '',
                dia = '',
                hora
              let archivos_completo = []
              /* Separar los datos del archivo */

              if (res.data.archivos != null) {
                let fecha_arch = ''
                res.data.archivos.forEach((element2) => {
                  // alert('entro')
                  let separar = []
                  separar = element2.split('-')

                  if (row.empresa == 'DSA') {
                    fecha_arch = separar[separar.length - 1].substring(0, separar[separar.length - 1].length - 4)
                    archivos_completo.push({ comprobante: separar[1], archivo: element2, fecha_arch: fecha_arch })
                  } else {
                    // let maskedNumber = separar[1].padStart(8, '0');

                    fecha_arch = separar[separar.length - 1].substring(0, separar[separar.length - 1].length - 4)
                    archivos_completo.push({ comprobante: separar[1], archivo: element2, fecha_arch: fecha_arch })
                  }
                })
              }
              res.data.indice.forEach((element) => {
                if (element.comprob != '') {
                  if (typeof element.fecha_escaneo != 'undefined') {
                    if (element.fecha_escaneo) {
                      anio = element.fecha_escaneo.substring(0, 4)
                      mes = element.fecha_escaneo.substring(4, 6)
                      dia = element.fecha_escaneo.substring(6, 8)
                      hora = element.fecha_escaneo.substring(8, element.fecha_escaneo.length)

                      // console.log(anio,mes,dia,hora)
                      element.fecha_escaneo_2 = dia + '-' + mes + '-' + anio + hora
                      element.fecha_comparar = element.fecha_escaneo.substring(6, 8) + element.fecha_escaneo.substring(4, 6) + element.fecha_escaneo.substring(2, 4)
                    } else {
                      element.fecha_escaneo_2 = ''
                    }
                  }
                }

                element.existError = Array.isArray(element.errores) ? false : true

                // /* acomodar los archivos */
                if (archivos_completo.length) {
                  if (row.empresa == 'DSA') {
                    element.archivo = archivos_completo.filter((elemento) => elemento.comprobante == element.comprob || elemento.comprobante == element.comprob1)
                  } else {
                    element.archivo = archivos_completo.filter((elemento) => elemento.comprobante == element.numero_extra)
                  }
                } else {
                  element.archivo = []
                }
                // // element.comparacion     = archivos_completo.filter(elemento => elemento.fecha_arch != element.fecha_comparar).length ? true : false
                element.indice = row.indice
                element.estado = row.estado
              })

              this.datosIndices = res.data.indice
              this.indiceB = row.indice
              this.carpetaB = 'CARPETA ' + res.data.carpeta.toUpperCase()
              /* Busco si alguno no cumple con la condicion deque la fecha y la fecha que contiene el nombre del pdf
                                sean iguales, si es así habilito para procesar nuevamente*/
              // this.comparacion     = res.data.indice.filter(indice => indice.comparacion == true).length ? true : false;
              if (row.empresa == 'DSA') {
                if (res.data.indice.length && archivos_completo.length && typeof res.data.indice[0].fecha_comparar != 'undefined') {
                  this.comparacion = archivos_completo[0].fecha_arch != res.data.indice[0].fecha_comparar ? true : false
                }
              }
            } else {
              this.datosIndices = []
              this.indiceB = row.indice
              this.carpetaB = 'No se encontró en  CARPETA ' + row.estado.toUpperCase()
            }
            this.cargandoVer = false
          } else {
            this.$message.success('El archivo ya fue procesado ' + row.indice)
            this.cargandoVer = false
          }
        })
        .catch(() => {
          this.cargandoVer = false
        })
    },
    procesarByIndice(indice) {
      this.subiendo = indice
      HTTP.get('/robotescaner/forzarSincroByIndex?index=' + indice)
        .then((res) => {
          console.log(res)
          //  window.sessionStorage.removeItem('indices')
          //  this.indices = []
          //  setTimeout(() => {
          //       this.getIndices()
          //  this.subiendo = undefined
          //  }, 150);
        })
        .catch(() => {
          this.subiendo = undefined
        })
    },
    getIndicesMostrar(page) {
      this.buscando_load = true
      let comienzo = '',
        final = '',
        fecha_b = []
      this.disTipo = false
      if (page == '' || page == null) {
        page = 1
      }
      this.pagina = page
      if (this.formIndice.fecha_buscar == null || this.formIndice.fecha_buscar == '') {
        comienzo = this.$moment().startOf('month').format('YYYYMMDD')
        final = this.$moment().format('YYYYMMDD')
      } else {
        comienzo = this.$moment(this.formIndice.fecha_buscar).startOf('month').format('YYYYMMDD')
        final = this.$moment(this.formIndice.fecha_buscar).endOf('month').format('YYYYMMDD')
      }
      fecha_b = [comienzo, final]
      if (this.formIndice.search != '') {
        this.disTipo = true
        HTTP.post('/robotescaner/buscarunIndice', { empresa: this.formIndice.empresa, indice: this.formIndice.search.trim() }).then((res) => {
          window.scrollTo(0, 0)
          this.indicesxmes = []
          let buscar = []
          res.data.forEach((element) => {
            let existe = ''
            existe = this.indicesxmes.find((ab) => ab.indice == element.indice)
            if (existe == undefined) {
              buscar = res.data.filter((busca) => busca.indice == element.indice)
              if (buscar.length > 1) {
                this.indicesxmes.push(buscar[0])
              } else {
                this.indicesxmes.push(buscar[0])
              }
            }
          })
          this.cant_datos = res.data.length ? res.data[0].TotalRows : 0
          this.buscando_load = false
        })
      } else {
        HTTP.post('/robotescaner/mostrarIndiceMes_2', {
          empresa: this.formIndice.empresa,
          fecha: fecha_b,
          pagina: page,
          indice: this.formIndice.search.trim(),
          estado: this.formIndice.selectEstado,
        })
          .then((res) => {
            window.scrollTo(0, 0)
            this.indicesxmes = []
            let buscar = []
            res.data.forEach((element) => {
              let existe = ''
              existe = this.indicesxmes.find((ab) => ab.indice == element.indice)
              if (existe == undefined) {
                buscar = res.data.filter((busca) => busca.indice == element.indice)
                this.indicesxmes.push(buscar[0])
              }
            })
            this.cant_datos = res.data.length ? res.data[0].TotalRows : 0
            this.buscando_load = false
          })
          .catch(() => {
            this.buscando_load = false
          })
      }
    },
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    },
    fecha: function (date) {
      return this.$moment(date).format('DD-MM-YYYY')
    },
    nextPage(page) {
      this.getIndicesMostrar(page)
    },
    procesarNuevo() {
      this.procesar = true
      HTTP.post('/robotescaner/actualizarItemFTP', { empresa: this.empresa, indices: this.datosIndices })
        .then((res) => {
          this.procesar = false
          if (res.data.estado == 'procesados') {
            this.$message({
              message: 'Se realizó la actualización',
              type: 'success',
            })
            this.dialogDatos = false
            setTimeout(() => {
              this.getIndicesMostrar(1)
            }, 300)
          } else if (res.data.estado == 'incompletos') {
            this.$message({
              message: res.data.msj,
              type: 'danger',
            })
          } else {
            this.$message({
              message: 'Error. No se pudo procesar los comprobantes. Verifique que esten correctos.',
              type: 'danger',
            })
          }
        })
        .catch((error) => {
          this.procesar = false
          console.log(error)
        })
    },
    nextPageComprob(page) {
      this.getComprobMostrar(page)
    },
    getComprobMostrar(page) {
      this.disabled_buscando = true
      this.error_comprob = ''
      this.error_indice = ''
      this.buscando_comprob = true
      if (page == '' || page == null) {
        page = 1
      }
      /* Controlo que si busca por comprobante cumpla con una regla > 4 */
      let buscar = true
      if (typeof this.formComprob.search != 'undefined' && this.formComprob.search != '') {
        if (this.formComprob.search.length < 4) {
          this.error_comprob = 'Para buscar por comprobantes deben ser 4 caracteres mínimos'
          buscar = false
          this.buscando_comprob = false
        } else {
          this.formComprob.indice = ''
        }
      }
      if (typeof this.formComprob.indice != 'undefined' && this.formComprob.indice != '') {
        if (this.formComprob.indice.length < 8) {
          this.error_indice = 'Para buscar deben ser 8 caracteres mínimos'
          buscar = false
          this.buscando_comprob = false
        }
      }
      this.pagina_comprob = page
      this.comprobantes = []
      this.total_comprobantes = 0
      this.indice_error = ''
      this.valorswitch = this.formComprob.estado != 'ERROR' ? null : this.valorswitch == null ? true : this.valorswitch

      if (buscar) {
        HTTP.post(
          // '/robotescaner/buscarComprobIndice_2_DESARROLLO',
          // '/robotescaner/buscarComprobIndice_2_DESARROLLO_05082022',
          '/robotescaner/buscarComprobIndice_2_DESARROLLO_06102022',
          // '/robotescaner/buscarComprobIndice_2_DESARROLLO_06102022_DESARROLLO',
          {
            empresa: this.formComprob.empresa,
            indice: this.formComprob.indice,
            pagina: page,
            comprobante: this.formComprob.search,
            estado: this.formComprob.estado,
            switch_estado: this.valorswitch,
            fecha_procesamiento: this.formComprob.fecha_procesamiento,
          }
        )
          .then((res) => {
            window.scrollTo(0, 0)
            this.comprobantes = res.data
            /* procesar los datos para saber que boton mostrar */
            this.total_comprobantes = res.data.length ? res.data[0].TotalRows : 0
            this.buscando_comprob = false
            this.disabled_buscando = false
          })
          .catch(() => {
            this.comprobantes = []
            this.total_comprobantes = 0
            this.buscando_comprob = false
            this.disabled_buscando = false
          })
      } else {
        this.comprobantes = []
        this.total_comprobantes = 0
        this.buscando_comprob = false
        this.disabled_buscando = false
      }
    },
    showModal(datos) {
      // console.log(datos)
      this.dialogVisibleComprobante = true
      this.datos_item = datos.item != '' ? JSON.parse(datos.item) : ''
      this.indice_error = datos.nombre
      /* Separo el nombre del comprobante */
      let comprobante_nombre = datos.comprob != '' ? this.nombreComprobante(datos) : 'SIN COMPROBANTE'
      this.tituloError = 'COMPROBANTE: ' + comprobante_nombre
      this.nombre_comprobante = comprobante_nombre
      if (datos.guia_terminal != -1 && datos.guia_numero != null) {
        this.tituloGuiaError = 'GUÍA ASOCIADA: ' + datos.guia_terminal + '-' + datos.guia_numero
      }
      this.fecha_cambio = datos.fecha_cambio
      if (this.fecha_cambio != null) {
        this.descripcion_fecha_cambio = 'El comprobante ya fue corregido. Fecha de corrección: ' + this.$moment(datos.fecha_cambio.date).format('DD-MM-YYYY HH:mm:SS')
      }
      if (datos.ruta) {
        this.cargando_imagen = true
        if (datos.empresa == 'DSA' && this.empresaUrl != 'lo') {
          this.url = 'https://seguimiento.suizoargentina.com.ar/seguimiento'
        } else {
          this.url = this.empresaUrl != 'lo' ? 'https://seguimiento.suizoargentina.com.ar/seguimiento' : 'https://seguimiento.log-in.com.ar/seguimiento'
        }
        let url = 'robotescanerlogin/downloadPdfs'
        let empresa_comprobante = datos.empresa == 'DSA' ? 'DSA' : 'LOGIN'
        HTTP.post(url, { ruta: datos.ruta, id: datos.id, hojas: datos.hojas, empresa: empresa_comprobante })
          .then((res) => {
            // console.log(res.data)
            this.pdfs = ''
            if (datos.hojas == 1 || datos.hojas == null) {
              if (res.data.status === 1) {
                this.pdfs = this.url + res.data.archivo
              } else if (res.data.status === 2) {
                // console.log(res.data.status)
                this.$alert('El archivo no existe')
              } else {
                this.$alert('No se pudo recuperar el archivo ')
              }
            } else {
              if (res.data[0].status === 1) {
                this.pdfs = this.url + res.data[0].archivo
              } else if (res.data.status === 2) {
                // console.log(res.data.status)
                this.$alert('El archivo no existe')
              } else {
                this.$alert('No se pudo recuperar el archivo ')
              }
            }
            this.cargando_imagen = false
          })
          .catch((e) => {
            this.descargando = false
            this.cargando_imagen = false
            this.$alert('Error al descargar el archivo ', e)
          })
      } else {
        this.descargando = false
        this.$alert('No hay ruta asociada al comprobante.')
      }
    },
    deleteDatos(formulario) {
      if (formulario == 'comprobantes') {
        this.formComprob = {}
        this.comprobantes = []
        this.total_comprobantes = 0
      } else {
        this.cant_datos = 0
        this.indicesxmes = []
        this.formIndice.search = ''
        this.formIndice.fecha_buscar = ''
        this.formIndice.empresa = ''
      }
    },
    getComprobantesIndice(indice) {
      this.buscando_comprob = true
      let page = 1
      this.pagina_comprob = page
      this.comprobantes = []
      this.total_comprobantes = 0
      this.indice_error = ''
      this.formComprob.indice = indice
      this.disabled_buscando = true
      this.valorswitch = this.formComprob.estado != 'ERROR' ? null : this.valorswitch
      HTTP.post(
        // '/robotescaner/buscarComprobIndice_2',
        // 'robotescaner/buscarComprobIndice_2_DESARROLLO',
        // 'robotescaner/buscarComprobIndice_2_DESARROLLO_05082022',
        'robotescaner/buscarComprobIndice_2_DESARROLLO_06102022',
        {
          empresa: this.formComprob.empresa,
          indice: this.formComprob.indice,
          pagina: page,
          comprobante: this.formComprob.search,
          estado: this.formComprob.estado,
          switch_estado: this.valorswitch,
          fecha_procesamiento: this.formComprob.fecha_procesamiento,
          tipo: 'indice',
        }
      )
        .then((res) => {
          window.scrollTo(0, 0)
          this.comprobantes = res.data
          this.total_comprobantes = res.data.length ? res.data[0].TotalRows : 0
          this.buscando_comprob = false
          this.cantidad_original = this.total_comprobantes
          this.disabled_buscando = false
        })
        .catch(() => {
          this.comprobantes = []
          this.total_comprobantes = 0
          this.buscando_comprob = false
          this.disabled_buscando = false
        })
    },
    getTodo(indice) {
      this.getComprobantesIndice(indice)
      this.formIndice.search = indice
      this.getIndicesMostrar(1)
    },
    getDatosComprobante(datos, tipo) {
      // console.log(datos,tipo)
      this.comprob_extra = []
      this.dialog_editar_comprobante = true
      this.show_msj = true
      this.pdfs = ''
      // ARMAR TITULO
      let comprob_titulo = ''
      this.show_formulario = false
      if (datos.comprobante.length > 8) {
        // if(datos.comprobante.length == 13 )
        // {
        //      comprob_titulo = datos.comprobante.substring(0,5)+'-'+ datos.comprobante.substr(-8)
        // }else if(datos.comprobante.length == 12 ){
        //     comprob_titulo = datos.comprobante.substring(0,4)+'-'+ datos.comprobante.substr(-8)
        // }else{
        let resultado = datos.comprobante.substr(-8)
        let descontar = datos.comprobante.length - resultado.length
        comprob_titulo = datos.comprobante.substring(0, descontar) + '-' + resultado
        // }
        // comprob_titulo = (datos.comprobante.length == 13
        //                     ? datos.comprobante.substring(0,5)+'-'+ datos.comprobante.substring(5)
        //                     : (datos.comprobante.length == 12
        //                         ? datos.comprobante.substring(0,4)+'-'+ datos.comprobante.substring(4)
        //                         :datos.comprobante))
      } else {
        comprob_titulo = datos.comprobante
      }
      this.titleModal = 'Editar comprobante sin guía asociada: ' + comprob_titulo
      this.formBuscar.comprobante = datos.comprobante
      this.comprobante_auxiliar = datos.comprobante
      // this.tipo_modal_titulo     = ' sin guía asociada'
      this.datos_props = ''

      this.comprob_extra = datos ? datos : []
      this.tipo_busqueda = tipo
      this.datos_item = datos.item != '' ? JSON.parse(datos.item) : ''
      this.indice_error = datos.nombre
      /* AGREGO UN ERROR EXTRA QUE NO ESTÁ EN WEB_COMPROBANTES_INDICES */
      if (datos) {
        // console.log(this.datos_item)
        if (datos.guia_terminal == -1) {
          if (this.datos_item != '' && this.datos_item != null) {
            this.datos_item.guia = 'EL COMPROBANTE NO ESTA ASOCIADO A UNA GUÍA'
          } else {
            // var obj   = {};
            // obj.foo   = "bar";
            // alert(obj)
            this.datos_item = new Object()
            this.datos_item.guia = 'EL COMPROBANTE NO ESTA ASOCIADO A UNA GUÍA'
          }
        }
      }
      this.tipo_modal = ''
      if (tipo == 'fecha' && this.dialog_editar_comprobante) {
        if (datos) {
          this.titleModal = 'Comprobante sin fecha de escaneo: ' + comprob_titulo
          // this.tipo_modal_titulo             = ' sin fecha asociada'
          this.formBuscar.comprobante = datos.comprobante
          this.buscarFecha()
          this.tipo_modal = 'fecha'
          this.show_formulario = true
        }
      }
      // console.log(this.sucProps)

      if (this.sucProps.length) {
        if (datos.nombre) {
          let c = datos
          if (c.ruta) {
            this.cargando_imagen = true
            if (c.empresa == 'DSA' && this.empresaUrl != 'lo') {
              this.url = 'https://seguimiento.suizoargentina.com.ar/seguimiento'
            } else {
              this.url = this.empresaUrl != 'lo' ? 'https://seguimiento.suizoargentina.com.ar/seguimiento' : 'https://seguimiento.log-in.com.ar/seguimiento'
            }
            let url = 'robotescanerlogin/downloadPdfs'
            let empresa_comprobante = c.empresa == 'DSA' ? 'DSA' : 'LOGIN'
            HTTP.post(url, { ruta: c.ruta, id: c.id, hojas: c.hojas, empresa: empresa_comprobante })
              .then((res) => {
                // console.log(res.data)
                this.pdfs = ''
                if (c.hojas == 1 || c.hojas == null) {
                  if (res.data.status === 1) {
                    this.pdfs = this.url + res.data.archivo
                  } else if (res.data.status === 2) {
                    // console.log(res.data.status)
                    this.$alert('El archivo no existe 3')
                  } else {
                    // this.$alert("No se pudo recuperar el archivo ");
                    this.pdfs = ''
                  }
                } else {
                  if (res.data[0].status === 1) {
                    this.pdfs = this.url + res.data[0].archivo
                  } else if (res.data.status === 2) {
                    // console.log(res.data.status)
                    this.$alert('El archivo no existe')
                  } else {
                    this.$alert('No se pudo recuperar el archivo ')
                  }
                }
                this.cargando_imagen = false
              })
              .catch((e) => {
                this.descargando = false
                this.cargando_imagen = false
                this.$alert('Error al descargar el archivo ', e)
              })
          } else {
            this.descargando = false
            this.$alert('No hay ruta asociada al comprobante.')
          }
        } else {
          this.descargando = false
          this.$alert('Error en los datos del comprobante. Contacte  a sitemas.')
        }
      } else {
        alert('recarge la pagina')
      }
    },
    buscarEscaneo(formName) {
      // alert('cargando')
      this.errorBuscar = ''
      this.showTablaComprobantes = false
      this.$refs[formName].validate((valid) => {
        // alert(valid)
        if (valid) {
          if (this.formBuscar.comprobante.length < 11) {
            this.$message.error('La longitud de búsqueda debe tener al menos 12 caracteres')
            return 0
          }
          // this.form1 = true
          this.buscando = true
          /* Primero tomos los casos especiales */
          this.loadingModal = true

          HTTP.post('/escaner/getEscaneado_DESARROLLO_NUEVO', { comprob: this.formBuscar.comprobante })
            .then((res) => {
              // console.log(res.data)
              this.show_formulario = false
              if (!res.data) {
                this.$message.error('No se encontró comprobante')
                this.form1 = false
                this.buscando = false
                this.loadingModal = false
              } else {
                if (res.data.length > 1) {
                  this.showTablaComprobantes = true
                  this.tabla_comprobantes = res.data
                  this.loadingModal = false
                  return 0
                } else {
                  this.show_formulario = true
                  this.datos_props = res.data
                  // this.datos_props.push({'extra':})
                  this.$events.fire('datos_props', res.data)
                  this.loadingModal = false
                }

                // console.log(this.datos_props)
              }
            })
            .catch(() => {
              this.form1 = false
              this.buscando = false
              this.loadingModal = false
            })
        }
      })
    },
    buscarFecha() {
      this.buscando = true
      /* Primero tomos los casos especiales */
      this.loadingModal = true
      HTTP.post('/escaner/getEscaneado', { comprob: this.formBuscar.comprobante })
        .then((res) => {
          // console.log(res.data)
          if (!res.data) {
            this.$message.error('No se encontró comprobante')
            this.form1 = false
            this.buscando = false
            this.loadingModal = false
          } else {
            this.datos_props = res.data
            this.$events.fire('datos_comprobante', res.data)
            this.loadingModal = false
            // console.log(this.datos_props)
          }
        })
        .catch(() => {
          this.form1 = false
          this.buscando = false
          this.loadingModal = false
        })
    },
    eliminarBusqueda() {
      this.formBuscar.comprobante = null
      this.datos_props = ''
      this.buscando = false
      this.disabled_buscando = false
      this.show_msj = false
      this.showTablaComprobantes = false
      this.show_formulario = false
    },
    quitarElementos() {
      this.formBuscar.comprobante = null
      this.datos_props = ''
      this.buscando = false
      this.pdfs = ''
      this.show_msj = false
      this.showTablaComprobantes = false
    },
    showPdf(c) {
      this.dialog_pdfs = true
      /* SEPARAR EL TÍTULO */
      let comprob_titulo = ''
      if (c.comprobante.length > 8) {
        let resultado = c.comprobante.substr(-8)
        let descontar = c.comprobante.length - resultado.length
        comprob_titulo = c.comprobante.substring(0, descontar) + '-' + resultado
      } else {
        comprob_titulo = c.comprobante
      }
      this.title_pdfs = 'Pdf del Comprobante ' + comprob_titulo
      this.title_pdfs_guia = ''
      // console.log(c.guia_numero)
      if (c.guia_terminal != -1 && c.guia_numero != null) {
        this.title_pdfs_guia = ' Comprobante asociado a la Guía: ' + c.guia_terminal + '-' + c.guia_numero
      }

      // console.log(c)
      this.pdfs = ''
      if (c.ruta) {
        this.cargando_imagen = true
        if (c.empresa == 'DSA' && this.empresaUrl != 'lo') {
          this.url = 'https://seguimiento.suizoargentina.com.ar/seguimiento'
        } else {
          this.url = this.empresaUrl != 'lo' ? 'https://seguimiento.suizoargentina.com.ar/seguimiento' : 'https://seguimiento.log-in.com.ar/seguimiento'
        }
        let url = 'robotescanerlogin/downloadPdfs'
        let empresa_comprobante = c.empresa == 'DSA' ? 'DSA' : 'LOGIN'
        HTTP.post(url, { ruta: c.ruta, id: c.id, hojas: c.hojas, empresa: empresa_comprobante })
          .then((res) => {
            // console.log(res.data)
            this.pdfs = ''
            if (c.hojas == 1 || c.hojas == null) {
              if (res.data.status === 1) {
                this.pdfs = this.url + res.data.archivo
              } else if (res.data.status === 2) {
                // console.log(res.data.status)
                this.$alert('El archivo no existe')
              } else {
                this.$alert('No se pudo recuperar el archivo ')
              }
            } else {
              if (res.data[0].status === 1) {
                this.pdfs = this.url + res.data[0].archivo
              } else if (res.data.status === 2) {
                // console.log(res.data.status)
                this.$alert('El archivo no existe')
              } else {
                this.$alert('No se pudo recuperar el archivo ')
              }
            }
            this.cargando_imagen = false
          })
          .catch((e) => {
            this.descargando = false
            this.cargando_imagen = false
            this.$alert('Error al descargar el archivo ', e)
          })
      } else {
        this.descargando = false
        this.$alert('No hay ruta asociada al comprobante.')
      }
    },
    showSubir(datos) {
      this.$events.fire('event_subir', datos)
    },
    cargarFormulario(datos_comprobante) {
      this.loadingModal = true
      setTimeout(() => {
        // console.log(datos_comprobante)
        this.show_formulario = true
        this.datos_props = datos_comprobante
        this.$events.fire('datos_comprobante', datos_comprobante)
        this.loadingModal = false
      }, 700)
    },
    nombreComprobante(comp) {
      let comprob_titulo = ''
      // console.log(comp,comp.comprobante)
      if (comp.comprob.length > 8) {
        let resultado = comp.comprob.substr(-8)
        let descontar = comp.comprob.length - resultado.length
        comprob_titulo = comp.comprob.substring(0, descontar) + '-' + resultado
      } else {
        comprob_titulo = comp.comprob
      }
      return comprob_titulo
    },
    deleteComprob(datos, accion) {
      // console.log(datos)
      this.eliminando = datos.id
      let titulo = '',
        texto = ''
      if (accion == 1) {
        titulo = '¿Está seguro de eliminar comprobante ' + datos.comprobante + '?'
        accion = 'eliminar'
      } else {
        titulo = '¿Está seguro de activar el comprobante ' + datos.comprobante + '?'
        accion = 'activar'
      }
      this.$swal
        .fire({
          title: titulo,
          text: texto,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6d6',
          cancelButtonColor: '#dd3333ba',
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
        })
        .then((result) => {
          // console.log(result)
          if (result.value) {
            this.borrarComprobDigital(datos, accion)
          }
          this.eliminando = false
        })
    },
    borrarComprobDigital(comprob, accion) {
      HTTP.post('/escaner/borrarComprobDigital', { datos: comprob, operador: this.operador, accion: accion })
        .then((res) => {
          // console.log(res.data)
          let titulo = accion == 'eliminar' ? 'Eliminado' : 'Activado'

          let texto = accion == 'eliminar' ? 'El comprobante fue eliminado correctamente.' : 'El comprobante fue activado correctamente.'
          if (res.data) {
            this.$swal
              .fire({
                icon: 'info',
                title: titulo,
                text: texto,
              })
              .then((result) => {
                // console.log(result)
                if (result.value) {
                  this.getComprobMostrar(this.pagina_comprob)
                }
              })
          } else {
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'La acción no pudo ser procesada correctamente. Intente nuevamente',
            })
          }
        })
        .catch(() => {})
    },
    getConvenios() {
      HTTP.post('/guiasnew/getConvenios', {}).then((res) => {
        this.convenios = res.data
      })
    },
  },
  watch: {
    formIndice: {
      handler(val) {
        // do stuff
        if (val.search == '') {
          this.disTipo = false
        } else {
          this.disTipo = true
        }
      },
      deep: true,
    },
    formComprob: {
      handler(val) {
        // do stuff
        if (val.comprobante == null) {
          this.show_msj = false
        } else {
          this.show_msj = true
        }
      },
      deep: true,
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD-MM-YYYY H:mm:ss')
    },
  },
  events: {
    'loadTabla-set'(filterText) {
      if (filterText) {
        this.dialog_editar_comprobante = false
        this.getComprobMostrar(this.pagina_comprob)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.indice-estado {
  list-style: none;
  padding: 0;
  font-size: 14px;
}
.indice-estado > li {
  border-bottom: 1px solid #f1f1f1;
  padding: 4px;
}
.centrarLoader {
  margin-top: 30px;
}
.el-collapse-item__header {
  background-color: #cf923666 !important;
  padding-left: 5px !important;
}
.right .el-tooltip__popper {
  font-size: 8px;
}
</style>
