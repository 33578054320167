<template>
  <div>
    <!-- <footer class="footer text-right" :style="styleFooter">
  {{altEmpresa}}
  </footer> -->
    <el-footer class="text-right;padding-top: 10px !important;" :style="styleFooter">
      <div v-if="url_privacidad == ''">
        {{ altEmpresa }}
      </div>
      <div v-else>
        <el-link :href="url_privacidad" :disabled="url_privacidad == ''" target="_blank" style="color: #fafcff; font-size: 13px !important">
          {{ altEmpresa }}
        </el-link>
      </div>
    </el-footer>
  </div>
</template>

<script>
const moment = require('moment')
export default {
  data() {
    return {
      styleFooter: {
        // color: 'white',
        // fontSize: '15px',
        // padding: '10px',
        // fontWeight: 'bolder',
        // textAlign:'center',
        // height:'50px',
      },
      altEmpresa: '',
      empresa: window.localStorage.getItem('empresa'),
      anio: moment().format('YYYY'),
      url_privacidad: '',
    }
  },

  created() {
    this.permisos_footer()
  },
  methods: {
    permisos_footer() {
      if (this.empresa == 'lo') {
        this.altEmpresa = 'LOG-IN FARMA S.R.L © 2024 '
        this.styleFooter['backgroundColor'] = '#36404e'
        this.url_privacidad = ''
      } else if (this.empresa == 'u') {
        this.altEmpresa = 'Suizo Argentina S.A. - © 2005-2024 | Aviso de privacidad'
        this.styleFooter['backgroundColor'] = '#0B406A'
        this.url_privacidad = 'https://suizoargentina.com/aviso-de-privacidad/'
      }
    },
  },
}
</script>

<style lang="css" scoped>
/* html {
  min-height: 100%;
  position: relative;
} */
/* body {
  margin: 0;
  margin-bottom: 40px;
  position: relative;

  display: grid;
  flex-direction: column;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
} */
footer {
  background-color: black;
  position: flex;
  bottom: 0;
  width: 100% !important;
  height: 40px;
  color: white;
  text-align: center;
  margin-top: auto !important;
  padding: 20px 20px;
  font-size: 13px;
}
</style>
