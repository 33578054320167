export default {
  namespaced: true,
  state: {
    filtro_hoja_ruta: {
      //convenio: {},
      destino: null,
      fechas: null,
      form: [],
      pagina: null,
    },
    select_hj: {
      pagina: null,
      hr: null,
      hj: null,
    },
  },
  mutations: {
    SET_FILTRO(state, { pagina, destino, fechas, form }) {
      state.filtro_hoja_ruta.form = form
      state.filtro_hoja_ruta.destino = destino
      state.filtro_hoja_ruta.fechas = fechas
      state.filtro_hoja_ruta.pagina = pagina
    },
    SET_SELECT_HJ(state, { pagina, hr, hj }) {
      state.select_hj.pagina = pagina
      state.select_hj.hr = hr
      state.select_hj.hj = hj
    },
    CLEAR_FILTROS(state) {
      state.filtro_hoja_ruta = {
        destino: null,
        fechas: null,
        form: [],
        pagina: null,
      }
      state.select_hj = {
        pagina: null,
        hr: null,
        hj: null,
      }
    },
  },
  actions: {
    setFiltro({ commit }, config) {
      commit('SET_FILTRO', config)
    },
    setSelectHj({ commit }, config) {
      commit('SET_SELECT_HJ', config)
    },
    clearFiltros({ commit }) {
      commit('CLEAR_FILTROS')
    },
  },
  getters: {
    getFiltro: (state) => state.filtro_hoja_ruta,
    getSelectHj: (state) => state.select_hj,
  },
}
