var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticStyle:{"padding-top":"0px !important"},attrs:{"visible":_vm.modal.comprobantes,"width":"80%","close-on-click-modal":false,"destroy-on-close":true,"show-close":false,"close-on-press-escape":true,"top":"5vh"},on:{"update:visible":function($event){return _vm.$set(_vm.modal, "comprobantes", $event)},"closed":function($event){return _vm.limpiarForm()}}},[_c('el-row',{staticStyle:{"margin-top":"-37px"}},[_c('h2',{staticStyle:{"text-align":"center","padding":"0px"}},[_vm._v("Carga de Comprobantes")]),_c('hr')]),_c('el-form',{attrs:{"label-position":"right","label-width":"130px"}},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":{ span: 4 }}},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus","round":"","plain":""},on:{"click":function($event){return _vm.addComprobante()}}},[_vm._v(" Agregar Comprobante")])],1)],1),_c('br'),_c('el-row',[_c('el-col',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"size":"mini","border":"","stripe":"","data":_vm.comprobantes,"empty-text":"Realice una busqueda para ver la información"}},[_c('el-table-column',{attrs:{"label":"#","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"Codigo","min-width":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('editable-cell',{attrs:{"show-input":row.editMode,"editable-component":"el-select","close-event":"change"},on:{"change":function($event){return _vm.tipoLetra(row)}},model:{value:(row.bulto_tipo),callback:function ($$v) {_vm.$set(row, "bulto_tipo", $$v)},expression:"row.bulto_tipo"}},[_c('span',{attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(row.bulto_tipo))]),_c('template',{slot:"edit-component-slot"},_vm._l((row.selectBultoTipo),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.nombre,"value":item.nombre}})}),1)],2)}}])}),_c('el-table-column',{attrs:{"label":"","min-width":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('editable-cell',{attrs:{"show-input":row.editMode,"editable-component":"el-select","close-event":"change"},model:{value:(row.bulto_l),callback:function ($$v) {_vm.$set(row, "bulto_l", $$v)},expression:"row.bulto_l"}},[_c('span',{attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(row.bulto_l))]),_c('template',{slot:"edit-component-slot"},_vm._l((row.selectBultoLetra),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.nombre,"value":item.nombre}})}),1)],2)}}])}),_c('el-table-column',{attrs:{"label":"","min-width":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('editable-cell',{attrs:{"show-input":row.editMode,"clearable":"","editable-component":"el-input","maxlength":5,"placeholder":"Terminal","type":"text"},model:{value:(row.terminal),callback:function ($$v) {_vm.$set(row, "terminal", $$v)},expression:"row.terminal"}},[_c('span',{attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(row.terminal))])])}}])}),_c('el-table-column',{attrs:{"label":"","min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('editable-cell',{attrs:{"show-input":row.editMode,"clearable":"","editable-component":"el-input","maxlength":8,"placeholder":"Numero","type":"text"},model:{value:(row.numero),callback:function ($$v) {_vm.$set(row, "numero", $$v)},expression:"row.numero"}},[_c('span',{attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(row.numero))])])}}])}),_c('el-table-column',{attrs:{"min-width":"60","label":"Contra entrega de mercadería"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('editable-cell',{attrs:{"show-input":row.editMode,"editable-component":"el-select","close-event":"change"},model:{value:(row.contraentrega),callback:function ($$v) {_vm.$set(row, "contraentrega", $$v)},expression:"row.contraentrega"}},[_c('el-tag',{attrs:{"slot":"content","size":"medium","type":row.contraentrega === '0' ? 'danger' : 'success'},slot:"content"},[_vm._v(" "+_vm._s(row.contraentrega === '0' ? 'NO' : 'SI')+" ")]),_c('template',{slot:"edit-component-slot"},[_c('el-option',{attrs:{"value":'0',"label":"NO"}}),_c('el-option',{attrs:{"value":'1',"label":"SI"}})],1)],2)}}])}),_c('el-table-column',{attrs:{"label":"Acciones"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.editMode == false)?_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary","icon":"el-icon-edit"},on:{"click":function($event){return _vm.setEditMode(scope.row, scope.$index)}}}):_vm._e(),(scope.row.editMode == true)?_c('el-button',{attrs:{"plain":"","size":"mini","type":"success","icon":"el-icon-check"},on:{"click":function($event){return _vm.saveRow(scope.row, scope.$index)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveRow(scope.row, scope.$index)}}}):_vm._e(),_c('el-button',{attrs:{"plain":"","size":"mini","icon":"el-icon-delete","type":"danger"},on:{"click":function($event){return _vm.deleteRow(scope.$index)}}})]}}])})],1)],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-row',{attrs:{"type":"flex","justify":"end"}},[_c('el-button',{attrs:{"type":"success","size":"medium","icon":"el-icon-check"},on:{"click":function($event){return _vm.agregarComprobantes()}}},[_vm._v("Guardar")]),_c('el-button',{attrs:{"type":"danger","size":"medium","icon":"el-icon-close"},on:{"click":function($event){return _vm.limpiarForm()}}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }