<template>
    <div>
        <recuperar-pass></recuperar-pass>
    </div>
</template>
<script>
import RecuperarPass from '@/components/recuperar/recuperarPass.vue'

export default {
    
    components:{
        RecuperarPass
    },
    data() {
        return {
            showSolo: false
        }
    },
    created() {
        /* Imagen segu url y btn */
        // var url = 'seguimiento.suizoargentina.com.ar/#/login';
        let urlpp = window.location.host, 
            array_url       = urlpp.split("/"),
            empresa_nombre  = array_url[0].split('.');
            
        if(empresa_nombre[0] != 'localhost:8080')
        {
            this.showSolo = false
        }
        else if(empresa_nombre[0] == 'localhost:8080')
        {
             this.showSolo = true   
        }
  },
}
</script>
