<template>
  <div>
		<detalle-guia></detalle-guia>
  </div>
</template>
<script>
import DetalleGuia from '@/components/guias/detalleGuia.vue'
export default {
	components:{
		DetalleGuia
	}
}
</script>