<template>
    <div>
        <presentacion></presentacion>
        <tabla-presentacion></tabla-presentacion>
    </div>
</template>
<script>
import Presentacion from '@/components/presentacion/presentacion.vue'
import TablaPresentacion from '@/components/presentacion/TablaPresentacion.vue'
export default {
    components:{
        TablaPresentacion,
        Presentacion
    }
}
</script>