<template>
  <div>
    <h4 class="titulo-pedido" style="background: #36404e !important">
      <center>{{ titulo_pedidos_realizados }}</center>
    </h4>
    <!-- ---------BTN DESCARGAR TODOS LOS PEDIDOS--------- -->
    <el-row :gutter="20">
      <el-col :span="24" :offset="0">
        <el-tooltip
          class="item"
          effect="dark"
          content="Descargar todos los pedidos"
          placement="left-start">
          <el-button
            type="warning"
            size="mini"
            style="float: right; margin-top: -25px; margin-bottom: 10px"
            icon="el-icon-download"
            @click="abrirModal()"
            plain
            :loading="loading_todo">
            Descargar Pedidos</el-button
          >
        </el-tooltip>
      </el-col>
    </el-row>
    <!-- ----------FORMULARIO PARA FILTRAR PEDIDOS POR FECHA-------- -->
    <el-row :gutter="20">
      <el-col :span="12" :offset="0">
        <el-form ref="filtrarP" :model="filtrarP" label-width="120px">
          <el-form-item label="Filtrar por fecha">
            <el-date-picker
              v-model="filtrarP.fecha"
              type="daterange"
              align="right"
              size="mini"
              unlink-panels
              value-format="yyyyMMdd"
              format="dd-MM-yyyy"
              range-separator="a"
              start-placeholder="desde"
              end-placeholder="hasta"
              :picker-options="pickerOptions"
              style="width: 100%">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-button-group style="margin-top: 5px">
          <el-button
            @click="filtrar(1)"
            type="info"
            icon="el-icon-search"
            size="mini"
            rounded
            style="margin-left: 10px"
            :loading="cargando_procesos"
            >Buscar</el-button
          >
          <el-button
            @click="limpiarFiltro()"
            icon="el-icon-refresh"
            size="mini"
            rounded
            style="margin-left: 10px"
            >Limpiar</el-button
          >
        </el-button-group>
      </el-col>
    </el-row>
    <div v-if="procesos.length && !cargando_procesos">
      <!-- --------------------------FIN DEL FORMULARIO DE BUSQUEDA--------------- -->
      <div v-loading="cargando_procesos">
        <div
          style="width: 100 !important"
          v-for="groupName in procesos"
          :key="groupName.receta">
          <!-- -----------------------------------CABECERA-------------- -->
          <el-button
            type="warning"
            class="block-btn"
            plain
            :disabled="loading_info"
            @click.stop.prevent="showPedido(groupName.receta)">
            <i class="fas fa-angle-down arrow" style="float: right"></i>
            <span
              style="
                float: left;
                font-weight: bold;
                font-size: 15px;
                color: #00000094;
              ">
              N° DE PEDIDO: #{{ groupName.receta }}
            </span>
            <!-- <span style="float:left;margin-left:7px;font-weight:bold" v-if="groupName.fecha_proceso!=null">
                   <i class="el-icon-d-arrow-right"></i> FECHA DE PROCESAMIENTO: {{groupName.fecha_proceso}}
                  </span> -->
            <span
              style="float: left; margin-left: 7px; font-weight: bold"
              v-if="groupName.fecha_guardado != null">
              <i class="el-icon-d-arrow-right"></i> FECHA DE COMPRA:
              {{ groupName.fecha_guardado }}
            </span>
            <span
              style="float: right; margin-right: 7px"
              v-if="groupName.fecha_proceso == null">
              <el-tag :type="groupName.estado_tipo" effect="dark">{{
                groupName.estado_nombre.toUpperCase()
              }}</el-tag>
            </span>
            <span
              style="float: right; margin-right: 7px"
              v-if="groupName.fecha_proceso != null">
              <el-tooltip
                class="item"
                effect="dark"
                :content="'Fecha de proc. ' + groupName.fecha_proceso"
                placement="left-start">
                <el-tag :type="groupName.estado_tipo" effect="dark">{{
                  groupName.estado_nombre.toUpperCase()
                }}</el-tag>
              </el-tooltip>
            </span>
          </el-button>
          <!-- -----------------------FIN DE CABECERA------------------------------------ -->
          <!-- -----------------------TABLA DE ARTICULOS CONFIRMADOS--------------------- -->
          <div v-if="show_data == groupName.receta" id="accordion-1">
            <el-table
              :header-cell-style="{ background: 'blue' }"
              :height="altTablaP"
              :data="tablaDetalles"
              v-loading="loading_proceso"
              border
              size="mini"
              show-summary
              :summary-method="getSummaries">
              <el-table-column prop="descrip" label="Descripción">
              </el-table-column>
              <el-table-column prop="lote" label="N° Lote" sortable width="150">
                <template slot-scope="scope">
                  <div v-if="scope.row.lote != null">{{ scope.row.lote }}</div>
                  <div v-else>-</div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="!no_genericos"
                prop="cod_generico"
                label="Genérico"
                width="150">
              </el-table-column>
              <el-table-column prop="articulo" label="Cod. Login" width="100">
              </el-table-column>
              <el-table-column
                prop="cantidad"
                label="Cantidad"
                width="100"
                center
                sortable>
              </el-table-column>
            </el-table>
            <center>
              <el-row style="width: 90%; margin-left: 5%">
                <el-button
                  type="warning"
                  size="mini"
                  style="margin: 10px 0px 10px 0px; text-aling: center"
                  round
                  icon="el-icon-download"
                  @click="descargarPedido(groupName.receta)"
                  :loading="loading_descargar">
                  Descargar Excel N° Pedido {{ groupName.receta }}</el-button
                >
              </el-row>
            </center>
          </div>
          <!-- -----------------------FIN DE TABLA------------------------------------ -->
        </div>
      </div>
      <!-- -----PAGINACIÓN DE PEDIDOS 4 POR PÁGINA---------- -->
      <el-row :gutter="20">
        <el-col :span="24" :offset="0">
          <el-pagination
            v-if="procesos.length"
            background
            layout="total,prev, pager, next"
            :total="procesos[0].TotalRows"
            :page-size="4"
            small
            style="margin-top: 20px; float: right"
            :current-page.sync="pagina_actual"
            @current-change="filtrar(pagina_actual)"></el-pagination>
        </el-col>
      </el-row>
    </div>
    <div
      v-if="!procesos.length && !cargando_procesos"
      style="margin-top: 50px; margin-bottom: 50px">
      <el-row :gutter="20">
        <el-col :span="24" :offset="0"
          ><h4><center>No se encontraron pedidos</center></h4></el-col
        >
      </el-row>
    </div>
    <!--------- MODAL PARA DESCARGAR ARCHIVOS --------->
    <el-dialog
      title="Descargar Excel de Pedidos"
      :visible.sync="show_dialog"
      width="40%"
      center>
      <el-form ref="buscar" :model="buscar" label-width="120px">
        <el-form-item label="Seleccione fecha">
          <el-date-picker
            v-model="buscar.fecha"
            type="daterange"
            align="right"
            size="small"
            unlink-panels
            value-format="yyyyMMdd"
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="desde"
            end-placeholder="hasta"
            :picker-options="pickerOptions"
            style="width: 100%">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-row :gutter="20" v-if="cantidad_download > 0">
        <el-col :span="24" :offset="0">
          <p style="word-break: break-word">
            La descarga de resultados está limitada a 10.000 registros, su
            actual consulta arrojó un total de
            <strong style="color: #f76868">{{ cantidad_download }}</strong>
            resultados.
          </p>
          <p style="word-break: break-word">
            Puede ajustar los resultados reduciendo el rango de fechas
            seleccionadas.
          </p></el-col
        >
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
        style="margin-top: -40px !important">
        <el-button
          type="primary"
          @click="descargarPedido(0)"
          size="small"
          icon="el-icon-download"
          :loading="loading_btn_descarga"
          >Descargar</el-button
        >
        <el-button
          @click="show_dialog = false"
          size="small"
          icon="el-icon-close"
          >Cancelar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      procesos: [],
      datos: JSON.parse(window.localStorage.getItem('datos')),
      loading_proceso: false,
      activeNames: ['1'],
      a: 1,
      cantidad_pedidos_total: 0,
      tablaDetalles: [],
      pedido_actual: '',
      loading_descargar: false,
      loading_todo: false,
      show_dialog: false,
      pickerOptions: {
        shortcuts: [
          {
            text: 'Últ. Semana',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Últ. Mes',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '3 Últ. meses',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        disabledDate(date) {
          return date > new Date()
        },
      },
      buscar: {
        fecha: [],
      },
      filtrarP: {
        fecha: [],
      },
      pagina_actual: 1,
      cargando_procesos: false,
      titulo_pedidos_realizados: '',
      cantidad_download: 0,
      show_data: false,
      tipo_estado: '',
      TotalRows: 0,
      cantidad_restar: 0,
      altTablaP: 0,
      styleAccordion: { height: '0vh' },
      loading_procesar: false,
      mostrar: false,
      array_pedidos: [],
      detalles: [],
      loading_info: true,
      buscando_pedido: false,
      loading_btn_descarga: false,
      permiso_todos: false,
      no_genericos: false,
    }
  },
  created() {
    /* NUEVO PERMISO NO GENERICOS. NO MOSTRAR GENERICOS PARA QUIENES TIENEN ESTE PERMISO */
    this.no_genericos = this.datos.permisos.includes('NO_GENERICOS')
      ? true
      : false
    // ------------------------------------------------------------------------------------
    this.permiso_todos =
      this.datos.permisos.indexOf('PEDIDOS_TODOS') !== -1 ? true : false
    this.filtrar(1)
    //  this.updateGenerico() //funcion que se uso para actulizar los cod. genericos de los procesados
  },
  mounted() {
    /* CUANDO HACE CLICK EN TAB PEDIDOS EMITE PEDIDO PARA CONSULTAR LA BD Y ACTUALIZAR LOS ESTADOS */
    EventBus.$on('actualizar_pedidos', (f) => {
      if (f) {
        this.filtrar(1)
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('actualizar_pedidos', false)
  },
  methods: {
    /*--------TOMO TODOS LOS PEDIDOS EN UNA SOLA CONSULTA------------*/
    getDetallesCompletos() {
      this.detalles = []
      HTTP.post('/vlogisticaLogin/getDetallesCompletos', {
        // HTTP.post('/vlogisticaLogin/getDetallesCompletosDesarrollo', {
        cliente: this.datos['cliente'],
        idu: this.datos['uid'],
        pedidos: this.array_pedidos,
      }).then((res) => {
        this.detalles = res.data
        setTimeout(() => {
          this.loading_info = false
        }, 500)
      })
    },
    /* AL HACER CLIC DESPLIEGA EL DETALLE DEL PEDIDO */
    showPedido(receta) {
      /* MUESTRA AL HACER CLICK EL PEDIDO */
      this.show_data = receta
      if (receta != this.pedido_actual) {
        this.pedido_actual = receta
        this.tablaDetalles = this.detalles.length
          ? this.detalles.filter((elementos) => elementos.receta == receta)
          : []

        /* MODIFICAR EL TAMAÑO DE LA TABLA SEGUN LA CANTIDAD DE ELEMENTOS */
        let tamanio = this.tablaDetalles.length

        if (tamanio == 1) {
          this.altTablaP = '20vh'
        } else if (tamanio <= 3) {
          this.altTablaP = '25vh'
        } else {
          if (tamanio >= 15) {
            this.altTablaP = '50vh'
          } else {
            this.altTablaP =
              tamanio > 5 ? 5 * tamanio + 'vh' : 9 * tamanio + 'vh'
          }
        }
      }
    },
    formatFecha(fecha) {
      return this.$moment(fecha).format('DD/MM/YYYY h:mm:ss')
    },
    formatFechaSinH(fecha) {
      return this.$moment(fecha).format('DD/MM/YYYY')
    },
    /* FUNCION QUE NO SE UTILIZAR. CONSULTABA POR CADA PEDIDO */
    mostrarDatos(row) {
      if (row.receta == this.pedido_actual) {
        this.tablaDetalles = []
      } else {
        this.loading_proceso = true
        HTTP.post('/vlogisticaLogin/getDetallePedido', {
          // HTTP.post('/vlogisticaLogin/getDetallePedidoDesarrollo', {
          idu: this.datos['uid'],
          pedido: row.receta,
        }).then((res) => {
          this.tablaDetalles = res.data
          this.pedido_actual = row.receta
          this.loading_proceso = false
        })
      }
    },
    descargarPedido(pedido) {
      let fecha_pedido = null
      fecha_pedido = pedido === 0 ? this.buscar.fecha : []
      this.cantidad_download = 0
      this.loading_descargar = pedido === 0 ? false : true
      this.loading_todo = pedido === 0 ? true : false
      this.loading_btn_descarga = true
      HTTP.post('/vlogisticaLogin/downloadPedidos', {
        cliente: this.datos['cliente'],
        idu: this.datos['uid'],
        npedido: pedido,
        fecha: fecha_pedido,
      }).then((res) => {
        this.loading_descargar = false
        this.loading_todo = false

        if (isNaN(res.data)) {
          window.location.href = res.data
        } else {
          if (res.data > 10000) {
            this.cantidad_download = res.data
          } else {
            this.$message({
              message: 'No se encontraron datos en ese rango de fecha.',
              type: 'warning',
            })
          }
        }
        this.loading_btn_descarga = false
      })
    },
    abrirModal() {
      this.buscar.fecha = this.filtrarP.fecha
      this.show_dialog = true
      this.cantidad_download = 0
    },
    /* ---- FUNCION PARA MOSTRAR LOS DATOS DE BUSQUEDA ---- */
    filtrar($pagina) {
      this.procesos = []
      this.cargando_procesos = true
      this.pagina_actual = $pagina
      if (this.filtrarP.fecha == null) {
        this.filtrarP.fecha = [
          new Date(this.$moment().subtract(7, 'days')),
          new Date(),
        ]
      } else {
        if (this.filtrarP.fecha.length === 0) {
          this.filtrarP.fecha = [
            new Date(this.$moment().subtract(7, 'days')),
            new Date(),
          ]
        }
      }

      if ($pagina == 1) {
        this.TotalRows = 0
      }

      /* EL PRIMER PASO PARA CARGAR LOS DATOS EN LAS TABLAS ES TOMAR LOS PEDIDOS POR USUARIO Y CLIENTE */
      HTTP.post('/vlogisticaLogin/getPedidosNuevo', {
        idu: this.datos['uid'],
        fecha: this.filtrarP.fecha,
        permiso: this.permiso_todos,
        pagina: $pagina,
      })
        // HTTP_DESARROLLO.post('/vlogisticaLogin/getPedidosNuevoDesarrollo', {
        //   idu: this.datos['uid'],
        //   fecha: this.filtrarP.fecha,
        //   pagina: $pagina,
        // })
        .then((res) => {
          this.procesos = res.data
          this.cantidad_pedidos_total = res.data.length
          this.array_pedidos = res.data.length
            ? res.data.map((element) => element.receta)
            : [] //array de pedidos
          if (this.cantidad_pedidos_total > 0) {
            this.titulo_pedidos_realizados =
              'Cantidad de pedidos realizados: ' +
              res.data[0].TotalRows +
              ' desde el ' +
              this.formatFechaSinH(this.filtrarP.fecha[0]) +
              '-' +
              this.formatFechaSinH(this.filtrarP.fecha[1])
            this.procesos.forEach((element) => {
              element.fecha_proceso =
                element.fecha_proceso != null
                  ? this.formatFecha(element.fecha_proceso.date)
                  : null
              element.fecha_guardado =
                element.fecha_guardado != null
                  ? this.formatFecha(element.fecha_guardado.date)
                  : null
              switch (element.estado) {
                case 1:
                  element.estado_tipo = 'success'
                  element.estado_nombre = 'Procesado'

                  break
                case 9:
                  element.estado_tipo = 'warning'
                  element.estado_nombre = 'Pendiente'
                  break
                case 2:
                  element.estado_tipo = 'danger'
                  element.estado_nombre = 'Error'
                  break
                default:
                  element.estado_tipo = ''
                  element.estado_nombre = ''

                  break
              }
            })
          } else {
            this.titulo_pedidos_realizados =
              'Cantidad de pedidos realizados: 0 desde el ' +
              this.formatFechaSinH(this.filtrarP.fecha[0]) +
              '-' +
              this.formatFechaSinH(this.filtrarP.fecha[1])
          }

          this.procesos.sort(function (a, b) {
            if (a.receta < b.receta) {
              return 1
            } else if (a.receta > b.receta) {
              return -1
            }
            return 0
          })

          /* AL FINAL CARGO LOS DATOS DE CADA PEDIDO PARA NO REALIZAR CONSULTAS */
          setTimeout(() => {
            this.cargando_procesos = false
            if (this.array_pedidos.length) {
              this.getDetallesCompletos()
            }
          }, 500)
        })
        .catch((error) => {
          console.log(error)
          this.cargando_procesos = false
          this.$alert('No se pudo realizar la acción. Refresque la página')
          this.titulo_pedidos_realizados =
            'Total de pedidos realizados: ' +
            0 +
            ' del ' +
            this.formatFechaSinH(this.filtrarP.fecha[0]) +
            '-' +
            this.formatFechaSinH(this.filtrarP.fecha[1])
        })
    },
    limpiarFiltro() {
      this.filtrarP.fecha = []
    },
    /* FUNCION PARA MOSTRARA EL TOTAL DE ARTICULOS EN LA TABLA */
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Suma prod.'
          return
        }
        if (index === 4) {
          const values = data
            .map((element) => element.cantidad)
            .reduce((a, b) => a + b, 0)
          sums[index] = values
          return
        }
      })
      return sums
    },
    updateGenerico() {
      HTTP.post('/vlogisticaLogin/updateCodGenerico', {
        sesion: this.datos,
      }).then((res) => {
        console.log(res.data)
      })
    },
  },
}
</script>
<style>
.el-table th {
  background: #3d639b !important;
  border: 1px solid #f0f2f5 !important;
  font-weight: normal;
  color: #000;
}
.titulo-pedido {
  background: #e6a23c;
  color: white;
  padding: 15px;
  margin-bottom: 35px;
}

.card {
  background: white;
  padding: 38px 36px;
  margin-bottom: 40px;
  border-radius: 4px;
  font-size: 15px !important;
  width: 100%;
}
.card details {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  background: #edb263;
  border-left: 15px solid gray;
  padding: 15px;
}
.card details {
  margin-top: 15px;
}
.card details.warning {
  --highlight: var(--color-summary-1-highlight);
  background: var(--color-summary-1);
  border-left-color: var(--color-summary-1-highlight);
  color: #36404e;
}
.card details.warning p {
  list-style-type: corona-warning;
}
.card details summary,
.card details p {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  font-family: var(--font-ibm-plex-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: var(--color-title);
  padding: 5px;
  cursor: pointer;
}
.el-alert--warning.is-light {
  background-color: #fdf6ec;
  color: #e57552 !important;
  font-weight: 700;
}
.card details summary:focus,
.card details p:focus {
  outline: solid 3px var(--highlight);
}
*/ .card details summary::-moz-selection,
.card details p::-moz-selection {
  background-color: var(--highlight);
}
.card details summary::selection,
.card details p::selection {
  background-color: var(--highlight);
}
.card details p {
  display: list-item;
  cursor: default;
  margin-left: 3rem;
  list-style-type: corona;
}
.el-table--border {
  border-right: #9e9e9e;
  border-bottom: #9e9e9e;
}
@counter-style corona-warning {
  system: cyclic;
  /* symbols: 🧼 🩺 👩🏻‍⚕️ 🚑; */
  symbols: '\26AB';
  suffix: ' ';
}
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}
.titulo-fecha {
  background: #e6a23cde;
  color: white;
  /* padding: 15px; */
  /* margin-bottom: 35px; */
  margin-top: 0px;
}
.block-btn {
  display: block;
  width: 100%;
  border: none;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px !important;
}
#accordion-1 {
  position: relative;
  box-shadow: 0px 1px 7px #dbdbdb;
  /* height: 25vh; */
  margin-top: 10px;
}

#accordion-1 .head {
  background-color: #ffffff;
  color: #563e6e;
  padding: 20px 30px;
  cursor: pointer;
  transition: 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#accordion-1 .arrow {
  color: #563e6e;
  font-size: 60px;
  transition: 0.25s ease;
  opacity: 0.3;
  transform: rotate(-90deg);
}

#accordion-1 .head:hover .arrow {
  opacity: 1;
}

#accordion-1 .head:hover,
#accordion-1 .active {
  background-color: #ffe77aff;
}

#accordion-1 .arrow-animate {
  transform: rotate(0deg);
  opacity: 1;
}

#accordion-1 .content {
  background-color: #ffffff;
  display: none;
  padding: 20px 30px;
  color: #333333;
}
</style>
