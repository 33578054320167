<template>
  <div class="vguias_buscar">
    <el-dialog
      :visible.sync="dialogCrearUser"
      width="70%"
      @closed="resetForm('formCrear')"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="false"
      :top="'0vh'">
      <el-row style="border-bottom: 1px dashed #ddd; margin-top: -25px; margin-bottom: 10px" :gutter="10">
        <el-col :md="14">
          <span style="float: left; font-size: 20px; text-aling: center">
            <strong>
              <i :class="iconTitle" :style="styleIcon"></i>
              {{ titleModal }}
            </strong>
          </span>
        </el-col>
      </el-row>
      <el-form :model="formCrear" :rules="rules" ref="formCrear" label-position="top" label-width="120px" class="demo-ruleForm" v-loading="cargandoDatos">
        <el-row :gutter="10">
          <el-col :md="12" :xs="24">
            <el-form-item label="Nombre de Usuario:" prop="usuario" style="padding-bottom: 5px">
              <el-input
                v-model="formCrear.usuario"
                size="mini"
                placeholder="Ingrese usuario"
                ref="user"
                clearable
                prefix-icon="el-icon-user"
                maxlength="20"
                aut00ocomplete="off"
                :disabled="loadingGuardar"></el-input>
            </el-form-item>
          </el-col>
          <div v-if="tipoModal == 1">
            <el-col :md="12" :xs="24">
              <!-- Parte de crear -->
              <!-- <div v-if="tipoModal == 1"> -->
              <el-form-item label="Contraseña:" prop="password">
                <el-input
                  v-model="formCrear.password"
                  id="passW"
                  maxlength="25"
                  size="mini"
                  placeholder="Contraseña"
                  prefix-icon="el-icon-lock"
                  clearable
                  :disabled="loadingGuardar"
                  style="margin-top: 6px"
                  autocomplete="off"
                  ref="pass">
                  <el-button slot="append" icon="el-icon-thumb" size="mini" @click="generarPass('pass')">Generar</el-button>
                </el-input>
                <!-- <div class="el-form-item__error" v-if="errorPass" style="margin-top: -10px">
                  {{ passRule }}
                </div> -->
              </el-form-item>
            </el-col>
          </div>
          <div v-else>
            <el-col :md="12" :xs="24" v-if="tipoModal == 2">
              <!-- Parte de editar -->
              <el-form-item label="Resetear Contraseña (opcional):">
                <el-input
                  v-model="formCrear.reset_password"
                  size="mini"
                  placeholder="Resetear Contraseña"
                  autocomplete="off"
                  prefix-icon="el-icon-lock"
                  clearable
                  maxlength="25"
                  minlength="8"
                  ref="reset"
                  style="margin-top: 6px">
                  <el-button slot="append" icon="el-icon-thumb" size="mini" @click="generarPass('reset')">Generar</el-button>
                </el-input>
                <!-- <div class="el-form-item__error" v-if="showError" style="color: #f56c6c; font-size: 12px; line-height: 0px">La contraseña debe ser más extensa</div> -->
              </el-form-item>
            </el-col>
          </div>
        </el-row>
        <el-row :gutter="10">
          <el-col :md="12" :xs="24">
            <el-form-item label="Expirar contraseña:" style="margin-top: 6px">
              <el-date-picker
                v-model="formCrear.pass_expire"
                type="date"
                size="mini"
                value-format="yyyyMMdd"
                format="dd-MM-yyyy"
                style="width: 100%"
                placeholder="Fecha (opcional)"
                :picker-options="pickerOptions"
                :disabled="loadingGuardar">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :md="12" :xs="24">
            <el-form-item label="Email:" style="padding-bottom: 5px">
              <el-input
                type="email"
                v-model="formCrear.email"
                size="mini"
                placeholder="Ingrese email"
                prefix-icon="el-icon-postcard"
                @change="validarEmail(formCrear.email)"
                clearable
                :disabled="loadingGuardar"></el-input>
            </el-form-item>
            <div v-if="error_email != ''">
              <div class="el-form-item__error">{{ error_email }}</div>
            </div>
          </el-col>
          <el-col :md="12" :xs="24">
            <el-form-item label="Sucursal:" prop="sucursal">
              <el-select v-model="formCrear.sucursal" placeholder="Seleccione sucursal" class="vpim-select" size="mini" clearable filterable :disabled="loadingGuardar">
                <el-option v-for="item in sucursales" :key="item.codigo" :label="item.codigo + ' - ' + item.nombre" :value="item.codigo"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ----------------------------------------------LOGIN------------------------------------------------ -->
        <div v-if="empresa == 'lo'">
          <el-row :gutter="10" class="vguia-row">
            <el-col :md="12" :sm="12" :xs="24">
              <el-form-item label="Base de Datos a consultar:" prop="bd">
                <el-radio-group
                  v-model="formCrear.bd"
                  size="small"
                  v-for="item in bdSelect"
                  :key="item.value"
                  style="margin-bottom: 5px; margin-top: 5px; display: block"
                  :disabled="loadingGuardar">
                  <el-radio :label="item.value">{{ item.nombre }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :md="12" :sm="12" :xs="24" v-if="showCod" style="margin-top: 5px">
              <el-form-item label="Código cliente:" prop="codCliente">
                <el-select
                  v-model="formCrear.codCliente"
                  :disabled="loadingGuardar"
                  class="vpim-select"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  placeholder="Ingrese código"
                  :remote-method="remoteMethod"
                  :loading="loadingCod"
                  empty="No hay coincidencias"
                  size="mini"
                  no-data-text="No existe el cliente en la base de datos">
                  <el-option v-for="item in clientes" :key="item.codigo" :label="item.codigo + ' - ' + item.fantasia" :value="item.codigo"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="vguia-row">
            <el-col :md="12" :sm="12" :xs="24" style="padding-bottom: 15px">
              <el-form-item label="Permisos:" prop="permisos">
                <el-checkbox-group
                  :disabled="loadingGuardar"
                  v-model="formCrear.permisos"
                  size="small"
                  v-for="permiso in nuevospermisosLog"
                  :key="permiso.nombre"
                  style="margin-bottom: -15px; display: block"
                  @change="fnPermisoSelect(permiso.id)">
                  <el-checkbox :label="permiso.id" :value="permiso.nombre">
                    {{ permiso.nombre }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <!-- NUEVA PARTE -->
            <div v-if="formCrear.permisos.length">
              <el-col :md="12" :sm="12" :xs="24" style="padding-bottom: 5px">
                <el-form-item v-if="permisos_asignados.length" label="Permisos adicionales:">
                  <el-checkbox-group
                    :disabled="loadingGuardar"
                    v-model="formCrear.permisos_extras"
                    size="small"
                    v-for="permiso in permisos_asignados"
                    :key="permiso.nombre"
                    style="margin-bottom: -15px; display: block"
                    @change="asignarPermisoExtra(permiso.id)">
                    <div v-if="permiso.nombre != 'TURNOS CLIENTE' && permiso.nombre != 'NO GENÉRICOS'">
                      <el-checkbox :label="permiso.id" :value="permiso.nombre">
                        {{ permiso.nombre }}
                      </el-checkbox>
                    </div>
                    <div v-else>
                      <el-checkbox v-if="permiso.nombre === 'NO GENÉRICOS'" :label="permiso.id" :value="permiso.nombre" :disabled="disabledGenerico">
                        {{ permiso.nombre }}
                      </el-checkbox>
                      <el-checkbox v-else :label="permiso.id" :value="permiso.nombre" disabled>
                        {{ permiso.nombre }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                  <div class="el-form-item__error">
                    {{ rulesTurnos }}
                  </div>
                </el-form-item>
              </el-col>
            </div>
          </el-row>
          <el-row :gutter="10" class="vguia-row" v-if="showPedidoPerm">
            <el-col :md="24" :xs="24">
              <el-form-item label="Permisos Stock Pedidos:" prop="permisos">
                <el-select
                  v-model="formCrear.permisos_stock"
                  placeholder="Seleccione el/los permisos para stock"
                  class="vpim-select"
                  size="mini"
                  clearable
                  filterable
                  multiple
                  :disabled="loadingGuardar">
                  <el-option v-for="perm in stockPermisos" :key="perm" :label="perm" :value="perm"> </el-option>
                </el-select>
                <div class="el-form-item__error" v-if="rulePedido != ''">
                  {{ rulePedido }}
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="vguia-row" v-if="showMulti && !addDENVER && !addBD">
            <el-col :md="24" :xs="24" style="margin-top: 15px">
              <el-checkbox :md="12" :xs="24" v-model="olmulti" @change="deshabilitarSelect" :disabled="loadingGuardar"> Seleccionar todos los convenios </el-checkbox>
            </el-col>
          </el-row>
          <div v-if="formCrear.permisos.length">
            <el-row v-if="showConvStock && !addDENVER && !addBD" :gutter="10" class="vguia-row">
              <el-col :md="24" :xs="24">
                <el-form-item label="Convenios Logística:" prop="convol">
                  <el-select
                    v-model="formCrear.convol"
                    placeholder="Seleccione convenios Logística"
                    class="vpim-select"
                    size="mini"
                    clearable
                    filterable
                    @change="asignarOlconv_nuevo('second')"
                    :disabled="loadingGuardar">
                    <el-option v-for="conv in convols" :key="conv.convol" :label="conv.convol + ' - ' + conv.descrip" :value="conv.convol"> </el-option>
                  </el-select>
                  <div class="el-form-item__error" v-if="ruleConvol != ''">
                    {{ ruleConvol }}
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="vguia-row" v-if="showConv && !addDENVER && !addBD">
              <el-col :md="24" :xs="24" style="line-height: 0px">
                <el-form-item label="Convenios Guías:" prop="guias_convenios">
                  <el-select
                    v-model="formCrear.guias_convenios"
                    placeholder="Seleccione convenios"
                    class="vpim-select"
                    size="mini"
                    clearable
                    multiple
                    filterable
                    :disabled="disabledSelectConv || loadingGuardar"
                    allow-create
                    default-first-option
                    @visible-change="controlarConvLog">
                    <el-option v-for="conv in convenios" :key="conv.olconv" :label="conv.olconv + ' - ' + conv.descrip" :value="conv.olconv"> </el-option>
                  </el-select>
                  <div v-if="showConv && !addDENVER && !addBD">
                    <div class="el-form-item__error" v-if="rulesConv != ''">
                      {{ rulesConv }}
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- ----------------------------------------------SUIZO------------------------------------------------ -->
        <div v-if="empresa == 'u'">
          <el-row :gutter="10" class="vguia-row">
            <el-col :md="12" :sm="24" :xs="24" v-if="showCod" style="margin-top: 5px">
              <el-form-item label="Código cliente:" prop="codCliente">
                <el-select
                  v-model="formCrear.codCliente"
                  class="vpim-select"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  placeholder="Ingrese código"
                  :remote-method="remoteMethod"
                  :loading="loadingCod"
                  empty="No hay coincidencias"
                  size="mini"
                  no-data-text="No existe el cliente en la base de datos">
                  <el-option v-for="item in clientes" :key="item.codigo" :label="item.codigo + ' - ' + item.fantasia" :value="item.codigo"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="vguia-row">
            <el-col :md="12" :xs="24" style="margin-bottom: 10px">
              <el-form-item label="Permisos:" prop="permisos">
                <el-checkbox-group
                  v-model="formCrear.permisos"
                  size="small"
                  v-for="permiso in permisosSuizo"
                  :key="permiso.id"
                  @change="fnHabilitarSelect()"
                  style="margin-bottom: -15px; display: block">
                  <el-checkbox :label="permiso.id" :value="permiso.nombre">
                    {{ permiso.nombre }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24" style="margin-bottom: 10px">
              <el-form-item v-if="formCrear.permisos.length" label="Permisos adicionales:">
                <el-checkbox-group
                  v-model="formCrear.permisos_extras"
                  size="small"
                  v-for="permiso in permisos_guias_suizo"
                  :key="permiso.nombre"
                  style="margin-bottom: -15px; display: block">
                  <el-checkbox :label="permiso.id" :value="permiso.nombre">
                    {{ permiso.nombre }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12" class="vguia-row" v-if="showConvSuizo">
            <el-col :md="12" :xs="24" v-if="showMultiSuizo">
              <el-checkbox :md="12" :xs="24" v-model="olmulti" @change="deshabilitarSelect"> Seleccionar todos los convenios </el-checkbox>
            </el-col>
            <el-col :md="24" :xs="24">
              <el-form-item label="Convenios Guías:" prop="guias_convenios">
                <el-select
                  v-model="formCrear.guias_convenios"
                  placeholder="Seleccione convenios"
                  class="vpim-select"
                  size="mini"
                  clearable
                  multiple
                  filterable
                  :disabled="disabledMultiSuizo">
                  <el-option v-for="conv in convenios" :key="conv.olconv" :label="conv.olconv + ' - ' + conv.descrip" :value="conv.olconv"> </el-option>
                </el-select>
                <div class="el-form-item__error" v-if="rulesConvSuizo != ''">
                  {{ rulesConvSuizo }}
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <br />
        <el-row :gutter="10">
          <el-col :span="24">
            <center>
              <el-form-item style="padding-bottom: 0px">
                <el-button v-if="estadoModal == 0" size="mini" type="primary" @click="guardarUsuario('formCrear')" icon="el-icon-check" :loading="loadingGuardar">Guardar</el-button>
                <el-button v-if="estadoModal == 1" size="mini" type="primary" @click="editarUserNuevo('formCrear')" icon="el-icon-edit" :loading="loadingEdit">Guardar</el-button>
                <el-button @click="resetForm('formCrear')" size="mini" icon="el-icon-close">Cancelar</el-button>
              </el-form-item>
            </center>
          </el-col>
        </el-row>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogCrearUser = false" size="mini">Cancelar</el-button>
                <el-button type="primary" @click="guardarUsuario" size="mini">Editar</el-button>
            </span> -->
    </el-dialog>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'
const moment = require('moment')

export default {
  data() {
    var validatePassOld = (rule, value, callback) => {
      // console.log(rule, 'ooo')
      if (this.formCrear.reset_password === null && this.tipoModal === 2) {
        callback()
      } else {
        if (this.formCrear.password === '' || this.formCrear.password === null) {
          callback(new Error('Por favor ingrese una contraseña'))
        } else {
          if (this.formCrear.password.length < 8 || this.formCrear.password.length > 25) {
            callback(new Error('El tamaño de contraseña es incorrecto.'))
          }
          callback()
        }
      }
    }
    return {
      titleModal: '',
      iconTitle: '',
      formCrear: {
        usuario: null,
        password: null,
        email: null,
        permisos: [],
        permisosArray: [],
        sucursal: null,
        bd: null,
        ol_convenio: null,
        guias_convenios: [],
        convol: 0,
        empresa: window.localStorage.getItem('empresa'),
        operador: JSON.parse(window.localStorage.getItem('datos')).uid + '-' + JSON.parse(window.localStorage.getItem('datos')).user,
        idOperador: JSON.parse(window.localStorage.getItem('datos')).uid,
        codCliente: null,
        permisos_stock: [],
        permisos_extras: [],
        permiso_general: null,
        pass_expire: null,
        reset_password: null,
      },
      empresa: window.localStorage.getItem('empresa'),
      dialogCrearUser: false,
      permisosSuizo: [
        { id: 1, nombre: 'Consultar Guías', permisos: 'OL' },
        // {id:3,nombre:'Consultar Guías y SAC',permisos:'OL,SAC,'},
      ],
      nuevospermisosLog: [
        { id: 1, nombre: 'Consultar Guías', permisos: 'OL', tipo: 'guia' },
        { id: 2, nombre: 'Consultar Stock', permisos: 'STOCK', tipo: 'stock' },
        {
          id: 3,
          nombre: 'Consultar Turnos',
          permisos: ['TURNOS'],
          tipo: 'turno',
        },
        {
          id: 4,
          nombre: 'Consultar Crossdock',
          permisos: 'CROSSDOCK',
          tipo: 'cross',
        },
        {
          id: 5,
          nombre: 'Consultar Archivos',
          permisos: 'FM',
          tipo: 'fm',
        },
      ],
      bdSelect: [
        { nombre: 'PiedraBuena', value: 'servdestino_8.oplogistico' },
        { nombre: 'Tom', value: 'servdestino_51.tom' },
      ],
      disabledSelectConv: false,
      loadingGuardar: false,
      loadingEdit: false,
      sucursales: [],
      convenios: [],
      convols: [],
      showConv: false,
      showConvSuizo: false,
      showConvStock: false,
      showCod: false,
      showMulti: false,
      showMultiSuizo: false,
      disabledMultiSuizo: false,
      rules: {
        usuario: [
          {
            required: true,
            message: 'Por favor ingresar Usuario',
            trigger: 'change',
          },
        ],
        sucursal: [
          {
            required: true,
            message: 'Por favor elija una sucursal',
            trigger: 'change',
          },
        ],
        permisosArray: [
          {
            required: true,
            message: 'Por favor elija un permiso',
            trigger: 'change',
          },
        ],
        permisos: [
          {
            required: true,
            message: 'Por favor elija un permiso',
            trigger: 'change',
          },
        ],
        bd: [
          {
            required: true,
            message: 'Por favor elija un Base de Datos',
            trigger: 'change',
          },
        ],
        codCliente: [
          {
            required: true,
            message: 'Por favor ingrese un Cód. Cliente',
            trigger: 'change',
          },
        ],
        email: [
          {
            type: 'email',
            message: 'Ingrese una direccion de email correcta',
            trigger: ['blur'],
          },
        ],
        password: [
          {
            required: true,
            message: 'Por favor ingrese una contraseña',
            trigger: 'change',
          },
          { validator: validatePassOld, trigger: 'blur' },
        ],
      },
      styleIcon: {},
      tipoModal: 0,
      estadoModal: 0, //0 modal crear , 1 modal editar
      showError: false,
      Iduser: '',
      guia_convenios_precargados: [],
      convolPre: '',
      aux_convol: '',
      errorPass: false,
      classError: 'border-color: #f56c6c !important',
      loadingCod: false,
      clientes: [],
      disabledGuia: false,
      olmulti: false,
      addSubir: false,
      esAdmin: window.localStorage.getItem('permisos').includes('OL_ABM_USUARIOS'),
      stockPermisos: ['TODOS', '.', 'D', 'V', 'Z'],
      addBD: false,
      addDENVER: false,
      /* NUEVA PARTE */
      permisos_guias: [
        { id: 'BD', nombre: 'GUÍAS BD' },
        { id: 'BD_GRAFICOS', nombre: 'GUÍAS BD + GRÁFICOS' },
        { id: 'DENVER', nombre: 'GUÍAS DENVER' },
        { id: 'SACL', nombre: 'SAC' },
        { id: 'SUBIR_COMPROB', nombre: 'SUBIR COMPROBANTE' },
        { id: 'COMPROB_ZIP', nombre: 'DESCARGAR ZIP DE COMPROB.' },
      ],
      permisos_stock: [
        { id: 'PEDIDOS', nombre: 'PEDIDOS POR USUARIO' },
        { id: 'PEDIDOS_TODOS', nombre: 'PEDIDOS POR CLIENTE' },
        { id: 'NO_GENERICOS', nombre: 'NO GENÉRICOS' },
        { id: 'STOCK_EXCEL_UM', nombre: 'EXCEL UM' },
      ],
      permisos_turnos: [
        { id: 'TURNOS_ADMIN', nombre: 'TURNOS ADMIN' },
        { id: 'TURNOS_CONSULTA', nombre: 'CONSULTAR TURNOS' },
        { id: 'TURNOS_OPERARIO', nombre: 'OPERARIO' },
        { id: 'TURNOS_VIGILANCIA', nombre: 'VIGILANCIA' },
        { id: 'TURNOS_CLIENTE', nombre: 'TURNOS CLIENTE' },
      ],
      permisos_cross: [{ id: 'CD_DESTINATARIO', nombre: 'ABM DESTINATARIO' }],
      permisos_guias_suizo: [
        { id: 'SACL', nombre: 'SAC' },
        { id: 'SUBIR_COMPROB', nombre: 'SUBIR COMPROBANTE' },
        { id: 'COMPROB_ZIP', nombre: 'DESCARGAR ZIP DE COMPROB.' },
        { id: 'WEB_FAC_ABBVIE', nombre: 'VER FACTURAS DE COMPROB.' },
      ],
      permisos_asignados: [],
      showPedidoPerm: false,
      error_email: '',
      aux_convenio: [],
      aux_conv_log: [],
      cargandoDatos: false,
      disabledGenerico: true,
      pickerOptions: {
        shortcuts: [
          {
            text: 'Un Mes',
            onClick(picker) {
              // const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
              picker.$emit('pick', start)
            },
          },
          {
            text: '3 meses',
            onClick(picker) {
              // const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() + 3600 * 1000 * 24 * 90)
              picker.$emit('pick', start)
            },
          },
          {
            text: '6 meses',
            onClick(picker) {
              const start = new Date()
              start.setTime(start.getTime() + 3600 * 1000 * 24 * 180)
              picker.$emit('pick', start)
            },
          },
          {
            text: 'Un año',
            onClick(picker) {
              const start = new Date()
              start.setTime(start.getTime() + 3600 * 1000 * 24 * 365)
              picker.$emit('pick', start)
            },
          },
        ],
        disabledDate(date) {
          // return date
          return date < new Date()
        },
      },
    }
  },
  created() {
    this.cargandoDatos = true
    if (typeof this.$route.query.e != 'undefined') {
      this.empresa = this.$route.query.e
      this.formCrear.empresa = this.$route.query.e
    }
    this.getSucursales()
    /*--------- SOLO EN EL CASO DE SER ADMIN PUEDE ASIGNAR EL PERMISO DE USUARIOS --------*/
    if (this.esAdmin) {
      this.permisos_guias.push({ id: 'APP_USUARIOS', nombre: 'VER USUARIOS' })
      this.permisos_guias_suizo.push({
        id: 'APP_USUARIOS',
        nombre: 'VER USUARIOS',
      })
    }
    /* ---------------------------------------------------------------------------------- */
  },
  mounted() {
    /* CAMBIAR NOMBRE DE EVENTO */
    this.cargandoDatos = true
    EventBus.$on('abrirModalUser', (datos) => {
      this.getConvenios()
      if (this.empresa == 'lo') {
        this.getConvol()
      }
      setTimeout(() => {
        this.formCrear.empresa = datos.empresaB
        if (datos.estado == 'crear') {
          this.titleModal = ''
          this.limpiarForm()
          this.titleModal = 'Crear Usuario'
          this.iconTitle = 'el-icon-circle-plus'
          this.tipoModal = 1
          this.estadoModal = 0
          this.showCod = true
          this.olmulti = false
          this.showMulti = false
          this.showMultiSuizo = false
          this.disabledMultiSuizo = false
          this.showConvSuizo = false
          // SACL
          this.addBD = false
          this.addDENVER = false
          this.showPedidoPerm = false
          this.cargandoDatos = false
        } else {
          this.tipoModal = 2
          this.estadoModal = 1
          this.showOpcion = false
          this.titleModal = ''
          this.titleModal = 'Editar Usuario: ' + datos.info.usuario
          this.iconTitle = 'el-icon-edit'
          this.limpiarForm()
          this.cargarFormGral(datos.info)
        }
      }, 500)

      setTimeout(() => {
        this.dialogCrearUser = true
        this.fnStyleIcon()
      }, 1000)
    })
  },
  beforeDestroy() {
    EventBus.$off('abrirModalUser', null)
  },
  methods: {
    fnStyleIcon() {
      this.styleIcon['color'] = this.formCrear.empresa == 'lo' ? '#e5590a' : '#0b406a'
    },
    resetForm(formName) {
      this.dialogCrearUser = false
      this.$refs[formName].resetFields()
    },
    limpiarForm() {
      this.clientes = []
      this.formCrear.bd = null
      this.formCrear.email = null
      this.formCrear.convol = 0
      this.formCrear.usuario = null
      this.formCrear.sucursal = null
      this.formCrear.password = null
      this.formCrear.permisos = []
      this.formCrear.codCliente = null
      this.formCrear.ol_convenio = null
      this.formCrear.permiso_general = null
      this.formCrear.permisosArray = []
      this.formCrear.permisos_stock = []
      this.formCrear.guias_convenios = []
      this.formCrear.permisos_extras = []
      this.formCrear.pass_expire = null
      this.formCrear.reset_password = null
      this.formCrear.empresa = window.localStorage.getItem('empresa')
      this.convolPre = ''

      this.addBD = false
      this.olmulti = false
      this.showConv = false
      this.showConvSuizo = false
      this.showMulti = false
      this.showMultiSuizo = false
      this.disabledMultiSuizo = false
      this.addDENVER = false
      this.showConvStock = false
      this.showPedidoPerm = false
    },

    /* ----------------------------------PARA LOGIN Y SUIZO----------------------------------------------------------- */
    getSucursales() {
      HTTP.post('/guiasNew/getSucursales', { codigo: this.formCrear.sucursal })
        .then((res) => {
          this.sucursales = res.data
        })
        .catch((error) => console.log(error))
    },
    getConvenios() {
      this.convenios = []
      HTTP.post('/guiasnew/getConvenios', {
        ol_lista: false,
        guia_convenios: [],
      })
        .then((res) => {
          this.convenios = res.data
          EventBus.$emit('conveniosGet', res.data)
        })
        .catch((error) => console.log(error))
    },
    getConvol() {
      HTTP.get('/guiasnew/getConvols')
        .then((res) => {
          this.convols = res.data
          EventBus.$emit('convolsGet', res.data)
        })
        .catch((error) => console.log(error))
    },
    remoteMethod(queryString) {
      this.clientes = []

      HTTP.post('/guiasnew/getCliente', {
        codigo: queryString,
        empresa: this.empresa,
      })
        .then((res) => {
          if (res.data) {
            this.clientes.push(res.data)
          } else {
            this.clientes = []
          }
        })
        .catch((error) => console.log(error))
    },
    cargarCod(cod) {
      this.showCod = true
      HTTP.post('/guiasnew/getCliente', { codigo: cod, empresa: this.empresa })
        .then((res) => {
          if (res.data) {
            this.clientes.push(res.data)
            this.formCrear.codCliente = cod
          }
        })
        .catch((error) => console.log(error))
    },
    deshabilitarSelect(valor) {
      if (this.formCrear.empresa == 'u') {
        this.disabledMultiSuizo = valor ? true : false
        this.formCrear.guias_convenios = this.disabledMultiSuizo ? [] : this.formCrear.guias_convenios
        if (this.formCrear.permisosArray.includes('OL_MULTI') && valor == false) {
          let index = this.formCrear.permisosArray.indexOf('OL_MULTI')
          if (index > -1) {
            this.formCrear.permisosArray.splice(index, 1)
          }
        }
      } else {
        if (valor && !this.formCrear.permisos.includes(2)) {
          this.formCrear.guias_convenios = []
          this.formCrear.ol_convenio = ''
        }
        // if (
        //   valor &&
        //   this.formCrear.permisosArray.includes('CROSSDOCK') == false
        // ) {
        //   this.formCrear.guias_convenios = []
        //   this.formCrear.ol_convenio = null
        // }
        if (this.formCrear.permisos.length == 1 && valor && this.formCrear.permisos.includes(1)) {
          this.disabledSelectConv = true
        } else {
          this.disabledSelectConv = false
        }
      }
    },
    generarPass(tipo) {
      var max = 100
      var min = 10
      var prefix = ['GU18', '6UIA', '6U14', 'GU14', '6UI4']
      let index = Math.floor(Math.random() * (5 - 0)) + 0
      let r = (Math.random() + 1).toString(36).substring(10)
      let numeros = Math.floor(Math.random() * (max - min)) + min
      if (tipo === 'pass') {
        this.formCrear.password = prefix[index] + numeros.toString() + r.toUpperCase()
        this.formCrear.reset_password = null
      } else {
        this.formCrear.reset_password = prefix[index] + numeros.toString() + r.toUpperCase()
        this.formCrear.password = null
      }
    },
    cargarFormGral(info) {
      /*--------- CARGAMOS TODOS LOS DATOS ------------*/
      this.empresa = info.empresa == 'LOG' && this.empresa == 'lo' ? 'lo' : 'u' // TERMINAR

      this.formCrear.empresa = this.empresa
      this.formCrear.usuario = info.usuario
      this.formCrear.email = info.email
      this.formCrear.bd = info.db
      this.formCrear.sucursal = info.sucursal
      this.formCrear.ol_convenio = info.ol_convenio
      this.formCrear.codCliente = info.cod_cliente
      this.Iduser = info.id
      this.formCrear.permisos_extras = []
      this.formCrear.pass_expire = info.pass_expire !== null ? moment(info.pass_expire.date).format('YYYYMMDD') : null
      /* GUIAS CONVENIOS */
      this.guia_convenios_precargados = info.guia_convenios == null || info.guia_convenios == 0 ? null : info.guia_convenios

      this.formCrear.guias_convenios = info.guia_convenios == null || info.guia_convenios == 0 ? [] : info.guia_convenios
      if (this.formCrear.guias_convenios.length) {
        this.separarGuias(this.formCrear.guias_convenios)
      }
      /* ------------------------- */
      setTimeout(() => {
        this.cargarCod(info.cod_cliente)
      }, 100) //Cargar el cod_cliente

      /*-------- PERMISOS -----------*/
      let array_permisos = info.permisos.split(',')
      array_permisos.splice(array_permisos.length - 1, 1) // 1ero ELIMINAR ESPACIO EN BLANCO
      /* PARTE DE LOGIN */
      if (this.empresa == 'lo') {
        this.formCrear.convol = info.convol
        this.convolPre = info.convol
        this.asignarPermisosEditarLogin(array_permisos, info) //ENVIO PERMISOS Y CONVENIO POR EL CASO DE BD Y DENVER
        /* TENER EN CUENTA LO DE BD Y DENVER */
        this.addBD = array_permisos.includes('GUIAS_EMPRESA')
        this.addDENVER = array_permisos.includes('GUIAS_EMPRESA')
      } else {
        this.asignarPermisosEditarSuizo(array_permisos, info) //ENVIO PERMISOS Y CONVENIO POR EL CASO DE BD Y DENVER
      }
      this.cargandoDatos = false
    },
    validarEmail(elemento) {
      this.error_email = ''
      if (elemento != '') {
        let texto = elemento
        let regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
        this.error_email = !regex.test(texto) ? 'Formato de email incorrecto' : ''
      }
    },
    /* ------------------------------------FIN------------------------------------------------------ */
    /* -----------------------------------LOGIN----------------------------------------------------- */
    separarGuias(guia_convenios) {
      if (guia_convenios) {
        // let separado = guia_convenios.split(',')
        this.formCrear.guias_convenios = guia_convenios.map(function (x) {
          return parseInt(x)
        })
      }
    },
    controlExtraLogin() {
      /*--- ASIGNO A OL_CONVENIO EL PRIMERO CONVENIO DE GUIAS_CONVENIO  ---*/
      /* PERMISOS */

      if (!this.olmulti) {
        /* CASO ESPECIAL */
        let estado = this.formCrear.permisosArray.includes('TURNOS') && this.formCrear.permisos.length == 1 && this.formCrear.permisos.includes(3) ? true : false
        if (estado) {
          this.formCrear.guias_convenios = []
          this.formCrear.ol_convenio = ''
          /* CONTROLO QUE SI SELECCIONA TURNO DEBE SELECCIONAR AL MENOS ALGUNO DE SUS ELEMENTOS */
        } else {
          this.formCrear.ol_convenio =
            this.formCrear.ol_convenio == '' || this.formCrear.ol_convenio == null
              ? this.formCrear.guias_convenios.length > 0
                ? this.formCrear.guias_convenios[0]
                : null
              : this.formCrear.ol_convenio
        }
        let permisos_fm = this.formCrear.permisosArray.includes('FM') ? true : false
        if (permisos_fm && this.formCrear.permisos.length === 1) {
          this.formCrear.guias_convenios = []
          this.formCrear.ol_convenio = null
        }
      } else {
        let permisos_extras = this.formCrear.permisosArray.includes('CROSSDOCK') ? true : false
        if (permisos_extras) {
          // this.formCrear.guias_convenios  = []
          this.formCrear.ol_convenio = this.formCrear.guias_convenios[0]
        }
        let permisos_fm = this.formCrear.permisosArray.includes('FM') ? true : false
        if (permisos_fm && this.formCrear.permisos.length === 1) {
          // this.formCrear.guias_convenios  = []
          this.formCrear.guias_convenios = []
          this.formCrear.ol_convenio = null
          // this.formCrear.permisosArray = []
          // this.formCrear.permisosArray.push('FM')
        }
      }
      /* Asignar permisos */
      this.asignarPermisoLog() // ASIGNA LOS PERMISOS ESPECIALES
      this.controlPermisosStock() //CONTROL CUANDO SELECCION A LOS PERMISOS PEDIDOS
    },
    asignarPermisoLog() {
      if (this.addDENVER || this.addBD) {
        // this.formCrear.permisosArray = []
        this.formCrear.permisosArray = ['OL', 'OL_LISTACONV', 'SACL', 'GUIAS_EMPRESA']
        if (this.formCrear.permisos_extras.includes('BD_GRAFICOS')) {
          this.formCrear.permisosArray.push('BD_GRAFICOS')
        }
        if (this.addBD) {
          this.formCrear.ol_convenio = 412
          this.formCrear.guias_convenios = [412, 395]
          this.formCrear.convol = 32
        } else {
          this.formCrear.ol_convenio = 51
          this.formCrear.guias_convenios = [51]
          this.formCrear.convol = 15
        }
      } else {
        if (this.olmulti) {
          /* caso especial si es OL MULTI PERO POR LOS DEMÁS PERMISOS NECESITA OL_CONVENIO */
          if (this.formCrear.permisosArray.indexOf('OL_MULTI') == -1) {
            this.formCrear.permisosArray.push('OL_MULTI')
            this.formCrear.permisosArray.push('OL_LISTACONV')
          }
        } else if (this.formCrear.guias_convenios.length >= 1) {
          if (this.formCrear.permisosArray.indexOf('OL_LISTACONV') == -1) {
            this.formCrear.permisosArray.push('OL_LISTACONV')
          }
        }
      }
      // this.formCrear.permisosArray = this.formCrear.permisosArray.join(',')
    },
    controlPermisosStock() {
      /* SI SELECCIONA TODOS, ENVIAR VACIO, NO SE GUARDA EL DATO */
      /* SI SELECCIONA TODOS LO ELEMENTOS PERO NO "TODOS", ENVIAR VACIO */
      /* SI NO GUARDA "TODOS", NI TOMA TODOS LOS ELEMENTOS DEL ARRAY, ENVIA EL ARRAY SELECCIONADO */
      let stock = this.formCrear.permisos.find((ab) => ab == 2) ? true : false
      if (stock) {
        /* DEBE INCLUIR PEDIDOS */
        if (this.formCrear.permisos_extras.includes('PEDIDOS')) {
          if (this.formCrear.permisos_stock.length == this.stockPermisos.length) {
            this.formCrear.permisos_stock.push('TODOS')
          }
        }
        /* SI ES EL ÚNICO PERMISO */
        if (stock && this.formCrear.permisos.length === 1) {
          this.formCrear.guias_convenios = []
          this.formCrear.ol_convenio = null
        }
      } else {
        let index_pedido = this.formCrear.permisos_extras.indexOf('PEDIDOS')
        let index_ver = this.formCrear.permisos_extras.indexOf('PEDIDOS_TODOS')
        if (index_pedido > -1) {
          this.formCrear.permisos_extras.splice(index_pedido, 1)
        }
        if (index_ver > -1) {
          this.formCrear.permisos_extras.splice(index_ver, 1)
        }
        this.formCrear.permisos_stock = []
      }
    },
    elementosPermisosLog() {
      this.showConvStock = false
      this.showConv = false
      this.disabledSelectConv = false
      /* QUITAR CONVENIOS */
      if (this.formCrear.guias_convenios.includes(395) || this.formCrear.guias_convenios.includes(412)) {
        let conv_395 = this.formCrear.guias_convenios.indexOf(395)
        let conv_412 = this.formCrear.guias_convenios.indexOf(412)
        if (conv_395 > -1) {
          this.formCrear.guias_convenios.splice(conv_395, 1)
        }
        if (conv_412 > -1) {
          this.formCrear.guias_convenios.splice(conv_412, 1)
        }
      }
      /* CONTROLA CONTINUAMENTE EL ARRAY permisos */
      /* GUIAS Y CROSS */
      if (this.formCrear.permisos.includes(1) || this.formCrear.permisos.includes(4)) {
        this.showConv = true
        this.showConvStock = false //OK
        /* Y SHOWMULTI SI ES USUARIO SUIZO O LOGIN */
        this.showMulti = (this.formCrear.codCliente === 8000 || this.formCrear.codCliente === 7717) && this.formCrear.permisos.includes(1) ? true : false
        /* SI ESTA SELECCIONADO ALGUNO DE ESTOS PERMISOS EXTRAS, PEDIR QUITAR */
        if (this.formCrear.permisos_extras.includes('BD') || this.formCrear.permisos_extras.includes('DENVER') || this.formCrear.permisos_extras.includes('BD_GRAFICOS')) {
          let nombre_mostrar = this.formCrear.permisos_extras[0] == 'BD' ? 'GUÍAS BD' : this.formCrear.permisos_extras[0] === 'DENVER' ? 'GUÍAS DENVER' : 'GUÍAS BD + GRÁFICOS'
          this.$message.error('Por favor primero quite el permiso adicional ' + nombre_mostrar)
        }
        if (this.guia_convenios_precargados != '' && this.tipoModal == 2) {
          this.separarGuias(this.guia_convenios_precargados)
        }
        /* NUEVO SI ES TURNO TOMA LOGISTICA */
        if (this.formCrear.permisos_extras.includes('TURNOS_CLIENTE')) {
          this.showConvStock = true //OK
          this.formCrear.convol = this.convolPre
        }
      } else if (this.formCrear.permisos.includes(3) && this.formCrear.permisos_extras.includes('TURNOS_CLIENTE')) {
        this.showConv = false //OK
        this.showConvStock = true //OK
        this.formCrear.convol = this.convolPre
        if (this.formCrear.permisos.includes(4)) {
          this.showConv = true
          this.disabledSelectConv = false
        }
      }

      /* COMBINACION CON STOCK */
      if (this.formCrear.permisos.includes(2)) {
        this.showConvStock = true
        this.formCrear.convol = this.convolPre
        if (this.formCrear.permisos.includes(1) || this.formCrear.permisos.includes(4)) {
          this.showConv = true
        }
      }
    },
    controlar_permisos_extras_array(campo) {
      let permisos_arrays =
        campo == 'guia' ? this.permisos_guias : campo == 'stock' ? this.permisos_stock : campo == 'turno' ? this.permisos_turnos : campo == 'cross' ? this.permisos_cross : []
      if (campo == 'stock') {
        this.showPedidoPerm = false
      }
      if (permisos_arrays.length) {
        permisos_arrays.forEach((element) => {
          let existe = this.formCrear.permisos_extras.find((extra) => extra == element.id)
          if (typeof existe != 'undefined') {
            let index = this.formCrear.permisos_extras.indexOf(existe)
            if (index > -1) {
              this.formCrear.permisos_extras.splice(index, 1)
            }
          }
        })
      }
    },
    /* ESTA FUNCIÓN SE USA PARA ASIGNAR LOS CONVENIOS ASOCIADOS A CONVOL PARA LOGIN */
    asignarOlconv_nuevo(value) {
      this.convolPre = this.formCrear.convol
      //Nueva funcion que se usa || ab == 3
      let guias = this.formCrear.permisos.find((ab) => ab == 1 || ab == 4) ? true : false
      this.disabledSelectConv = false
      if (this.formCrear.convol != '') {
        let aux_convenios = [] //guardo para concatenar
        aux_convenios = this.formCrear.guias_convenios //guardo para concatenar
        if (guias) {
          let convol_filtrado = this.convols.filter((element) => element.convol == this.formCrear.convol)
          if (convol_filtrado.length > 0) {
            let separado = convol_filtrado[0].cadolconv.split(',')
            if (separado[0] != '') {
              this.formCrear.guias_convenios = separado.map(function (x) {
                return parseInt(x)
              })
              this.formCrear.ol_convenio = this.formCrear.guias_convenios[0]
              // if (this.formCrear.permisosArray.includes('CROSSDOCK') ) { //este es en la caso de croossdock
              if (guias) {
                this.formCrear.guias_convenios = this.formCrear.guias_convenios.concat(aux_convenios) //SE CONCATENA PARA EL CASO DE CROSDOCK O TURNOS
                /* QUITO LOS REPETIDOS */
                this.formCrear.guias_convenios = this.formCrear.guias_convenios.filter((value, index, array) => array.indexOf(value) === index)
              }
            }
            /* EN EDITAR SE VAN CONCATENANDO LOS ELEMENTOS */
            // PERO SI SE CAMBIA DE CONVOL SE QUITAN LOS CONVENIOS ASOCIADOS AL MISMO
            if (this.aux_convol != this.formCrear.convol && this.aux_convol != '' && this.aux_convol != null && value == 'second') {
              let convol_quitado = this.convols.filter((element) => element.convol == this.aux_convol)
              if (convol_quitado.length > 0) {
                let convenio_quitado = convol_quitado[0].cadolconv.split(',').map(function (x) {
                  return parseInt(x)
                })
                /* hago una diferencia entre ambos para sacar los repetidos */
                this.formCrear.guias_convenios = this.formCrear.guias_convenios.filter((x) => !convenio_quitado.includes(x))
              }
            }
            this.aux_convol = this.formCrear.convol
          }
        }
      }
    },
    fnPermisoSelect(permiso) {
      this.permisos_asignados = []
      /* PRIMER CONTROL ES SI TOMA TODOS LOS PERMISOS */
      this.disabledSelectConv = false
      this.disabledGuia = false

      this.showMulti = false
      this.formCrear.permisosArray = []

      /* CONTROLO INTERACCIÓN CON GUIAS BD Y DENVER */
      if (this.addBD || this.addDENVER) {
        this.formCrear.permisos = []
        this.formCrear.permisos.push(1)
        this.permisos_asignados = this.permisos_asignados.concat(this.permisos_guias) /* PERMISOS ADICIONALES */
      } else {
        if (this.formCrear.permisos.length == this.nuevospermisosLog.length) {
          this.formCrear.permisosArray = []
          /* EN EL CASO DE QUE SEAN ARRAY */
          this.nuevospermisosLog.forEach((element) => {
            if (Array.isArray(element.permisos)) {
              this.formCrear.permisosArray = this.formCrear.permisosArray.concat(element.permisos)
            } else {
              this.formCrear.permisosArray.push(element.permisos)
            }
          })
          this.disabledSelectConv = true
          this.showGuias = true
          /* concatenar permisos */
          this.permisos_asignados = this.permisos_asignados.concat(this.permisos_guias)
          this.permisos_asignados = this.permisos_asignados.concat(this.permisos_stock)
          this.permisos_asignados = this.permisos_asignados.concat(this.permisos_turnos)
          this.permisos_asignados = this.permisos_asignados.concat(this.permisos_cross)
        } else {
          let guias = this.formCrear.permisos.find((ab) => ab == 1) ? true : false
          let stock = this.formCrear.permisos.find((ab) => ab == 2) ? true : false
          let turno = this.formCrear.permisos.find((ab) => ab == 3) ? true : false
          let cross = this.formCrear.permisos.find((ab) => ab == 4) ? true : false
          let fm = this.formCrear.permisos.find((ab) => ab == 5) ? true : false
          /* tomar el caso de guias */
          this.formCrear.permisosArray = []
          if (guias) {
            this.permisos_asignados = this.permisos_asignados.concat(this.permisos_guias) /* PERMISOS ADICIONALES */
            this.formCrear.permisosArray.push('OL')
            if (stock) {
              this.permisos_asignados = this.permisos_asignados.concat(this.permisos_stock) //sumamos a los permisos adicionales de guias los de stock
              this.formCrear.permisosArray.push(this.nuevospermisosLog[1].permisos)
              if (this.formCrear.convol != '') {
                this.asignarOlconv_nuevo()
              }
            }

            if (turno) {
              //si selecciona turno
              this.permisos_asignados = this.permisos_asignados.concat(this.permisos_turnos)

              this.formCrear.permisosArray = this.formCrear.permisosArray.concat(this.nuevospermisosLog[2].permisos)
            }
            if (cross) {
              //si selecciona turno
              this.permisos_asignados = this.permisos_asignados.concat(this.permisos_cross)

              this.formCrear.permisosArray.push(this.nuevospermisosLog[3].permisos)
            }
            if (fm) {
              this.formCrear.permisosArray.push(this.nuevospermisosLog[4].permisos)
            }
          } else if (stock) {
            //no selecciona guias, pero si stock
            /* DESTILO LOS PERMISOS EXTRAS */
            this.formCrear.permisosArray = []
            this.permisos_asignados = []
            this.permisos_asignados = this.permisos_asignados.concat(this.permisos_stock) //sumamos a los permisos adicionales de guias los de stock

            this.formCrear.permisosArray.push(this.nuevospermisosLog[1].permisos)
            if (turno) {
              this.permisos_asignados = this.permisos_asignados.concat(this.permisos_turnos)
              this.formCrear.permisosArray = this.formCrear.permisosArray.concat(this.nuevospermisosLog[2].permisos)
            }

            if (cross) {
              this.permisos_asignados = this.permisos_asignados.concat(this.permisos_cross)

              this.formCrear.permisosArray.push(this.nuevospermisosLog[3].permisos)
            }
            if (fm) {
              this.formCrear.permisosArray.push(this.nuevospermisosLog[4].permisos)
            }
            /* CASO ESPECIAL EDITAR SI NO TIENE GUIAS QUITAR OL_MULTI */
          } else if (turno) {
            this.formCrear.permisosArray = []
            this.permisos_asignados = []
            /* NO TOMA GUIAS NI STOCK */
            this.permisos_asignados = this.permisos_asignados.concat(this.permisos_turnos)
            /* hay turno pero no toma stock ni guias */
            this.formCrear.permisosArray = this.formCrear.permisosArray.concat(this.nuevospermisosLog[2].permisos)

            if (cross) {
              this.permisos_asignados = this.permisos_asignados.concat(this.permisos_cross)
              this.formCrear.permisosArray.push(this.nuevospermisosLog[3].permisos)
            }
            if (fm) {
              this.formCrear.permisosArray.push(this.nuevospermisosLog[4].permisos)
            }
          } else if (cross) {
            this.formCrear.permisosArray = []
            this.permisos_asignados = []
            this.permisos_asignados = this.permisos_asignados.concat(this.permisos_cross)
            this.formCrear.permisosArray.push(this.nuevospermisosLog[3].permisos)
            if (fm) {
              this.formCrear.permisosArray.push(this.nuevospermisosLog[4].permisos)
            }
          } else if (fm) {
            this.formCrear.permisosArray.push(this.nuevospermisosLog[4].permisos)
          }

          if (this.tipoModal === 2) {
            /* CASO SOLO PARA EDITAR */
            if (guias == false && (this.formCrear.permisosArray.includes('OL_MULTI') || this.formCrear.permisos_extras.includes('OL_LISTACONV'))) {
              if (this.formCrear.permisosArray.includes('OL_MULTI')) {
                let index = this.formCrear.permisosArray.indexOf('OL_MULTI')
                if (index > -1) {
                  this.formCrear.permisosArray.splice(index, 1)
                }
              }
              if (this.formCrear.permisos_extras.includes('OL_LISTACONV')) {
                let index = this.formCrear.permisos_extras.indexOf('OL_LISTACONV')
                if (index > -1) {
                  this.formCrear.permisos_extras.splice(index, 1)
                }
              }
            }
          }
          if (!guias && !cross) {
            this.formCrear.guias_convenios = []
            this.formCrear.ol_convenio = null
          }
        }
        /* SI EL PERMISO QUE VINO NO ESTÁ EN permisosArray limpio lo campos de permisos extras */
        let permiso_eliminado = this.formCrear.permisos.find((ab) => ab == permiso)
        if (typeof permiso_eliminado == 'undefined') {
          let busco = this.nuevospermisosLog.find((pop) => pop.id === permiso)
          if (typeof busco != 'undefined') {
            this.controlar_permisos_extras_array(busco.tipo)
          }
        }

        /*  */
      }
      this.elementosPermisosLog()
    },
    asignarPermisoExtra(estado) {
      /* PRIMERO BUSCO QUE NO HAYA SELECCIONADO DENVER O BD ANTES */
      let BD_ = this.formCrear.permisos_extras.find((elemet) => elemet == 'BD')
      let DENVER_ = this.formCrear.permisos_extras.find((elemet) => elemet == 'DENVER')
      let BD_GRAFICOS_ = this.formCrear.permisos_extras.find((elemet) => elemet == 'BD_GRAFICOS')
      let index = ''
      /* SI INCLUTE DENVER O BD*/
      if ((estado == 'BD' || estado == 'DENVER' || estado == 'BD_GRAFICOS') && (BD_ == 'BD' || DENVER_ == 'DENVER' || BD_GRAFICOS_ == 'BD_GRAFICOS')) {
        this.showPedidoPerm = false
        this.formCrear.permisos = []
        this.formCrear.permisos_extras = []
        this.formCrear.permisos_extras.push(estado)
        this.addBD = estado == 'BD' || estado == 'BD_GRAFICOS' ? true : false
        this.addDENVER = estado == 'DENVER' ? true : false
        // this.formCrear.permisosArray = ['OL']
        this.formCrear.permisos.push(1)
        this.permisos_asignados = []
        this.permisos_asignados = this.permisos_guias
      } else {
        // console.log([
        //   this.formCrear.permisosArray.includes('OL'),
        //   this.formCrear.permisosArray.includes('TURNOS'),
        // ])
        this.formCrear.guias_convenios =
          this.formCrear.guias_convenios.length && this.tipoModal == 2
            ? this.formCrear.guias_convenios
            : this.formCrear.guias_convenios.length && this.tipoModal == 1 && (this.formCrear.permisos.includes('OL') || this.formCrear.permisos.includes('TURNOS'))
            ? this.formCrear.guias_convenios
            : []
        /* CUANDO DESTILDO BD O DENVER LIMPIO LOS DATOS DE CONVENIOS */
        if (estado == 'BD' || estado == 'DENVER' || estado == 'BD_GRAFICOS') {
          this.aux_convenio = []
          this.formCrear.ol_convenio = ''

          this.guia_convenios_precargados = []
        }
        this.addBD = false
        this.addDENVER = false

        // this.formCrear.permisosArray        = []
        if (typeof BD_ != 'undefined' || typeof DENVER_ != 'undefined' || typeof BD_GRAFICOS_ != 'undefined') {
          this.formCrear.permisos_extras = []

          // this.formCrear.convol = ''
          index = this.formCrear.permisos_extras.indexOf(estado)

          if (index > -1) {
            this.formCrear.permisos_extras.splice(index, 1)
          } else {
            this.formCrear.permisos_extras.push(estado)
          }
        } else {
          if (estado == 'PEDIDOS' || estado == 'PEDIDOS_TODOS') {
            if (this.formCrear.permisos_extras.includes('PEDIDOS') || this.formCrear.permisos_extras.includes('PEDIDOS_TODOS')) {
              this.disabledGenerico = false
              this.showPedidoPerm = true
              this.formCrear.permisos_stock = this.formCrear.permisos_stock.length && this.tipoModal == 2 ? this.formCrear.permisos_stock : []
            } else {
              this.showPedidoPerm = false
              this.disabledGenerico = true
              let existeGen = this.formCrear.permisos_extras.indexOf('NO_GENERICOS')
              if (existeGen > -1) {
                this.formCrear.permisos_extras.splice(existeGen, 1)
              }
            }

            // index = this.formCrear.permisos_extras.indexOf(estado)
            // this.showPedidoPerm = index != -1 ? true : false
          }
          if (estado === 'TURNOS_CLIENTE') {
            this.showConvStock = true
            this.addDENVER = false
            this.addBD = false
            // index = this.formCrear.permisos_extras.indexOf(estado)
            // let perm = this.formCrear.permisosArray.indexOf('OL')
            // // let stock = this.formCrear.permisosArray.indexOf('STOCK')
            // let cross = this.formCrear.permisosArray.indexOf('CROSSDOCK')
            // this.showConv =
            //   index != -1 || perm != -1 || cross != -1 ? true : false
          }
        }

        /* PERMISOS AL DESTILDAR BD O DENVER */
        if (this.tipoModal === 2) {
          this.elementosPermisosLog()
        }
      }
    },
    controlarConvLog() {
      if (this.formCrear.permisos_extras.includes('TURNOS_CLIENTE') && this.olmulti) {
        if (this.formCrear.guias_convenios.length > 1) {
          let aux = this.formCrear.guias_convenios
          this.formCrear.guias_convenios = []
          this.formCrear.guias_convenios.push(aux[0])
        }
        /* solo permite tomar un elemento vamos a limpiar el resto y dar una alerta */
      } else {
        var numero = ''
        var arrayNumero = this.formCrear.guias_convenios
        this.formCrear.guias_convenios = []
        arrayNumero.forEach((element) => {
          numero = parseInt(element)
          if (Number(numero)) {
            if (!this.formCrear.guias_convenios.includes(numero)) {
              this.formCrear.guias_convenios.push(numero)
            }
          }
        })
      }
    },
    /* VIENDOOOO */
    asignarPermisosEditarLogin(array_permisos, info) {
      let permisos_total = array_permisos
      let eliminar = []
      let index = -2
      this.permisos_asignados = []
      let include_stock = array_permisos.includes('STOCK')
      let include_OL = array_permisos.includes('OL'),
        include_turno = array_permisos.includes('TURNOS'),
        include_cross = array_permisos.includes('CROSSDOCK'),
        include_fm = array_permisos.includes('FM')

      if (include_OL) {
        this.permisos_asignados = this.permisos_asignados.concat(this.permisos_guias) /* PERMISOS ADICIONALES */
        this.formCrear.permisos.push(1)
        eliminar.push('OL')
        if (array_permisos.includes('OL_MULTI') && (info.cod_cliente == 8000 || info.cod_cliente == 7717)) {
          //ES OL, OL_MULTI

          eliminar.push('OL_MULTI')
          this.showMulti = true
          this.olmulti = true
          this.deshabilitarSelect(true)
        }
      }

      /* VEMOS SI INCLUYE STOCK Y NO FUE CONTEMPLADO ANTES */
      if (this.formCrear.permisos.includes(2) == false && include_stock) {
        this.formCrear.permisos.push(2) //agrego el permiso
        eliminar.push('STOCK')
        this.permisos_asignados = this.permisos_asignados.concat(this.permisos_stock) /* PERMISOS ADICIONALES */

        if (array_permisos.includes('PEDIDOS') || array_permisos.includes('PEDIDOS_TODOS')) {
          this.showPedidoPerm = true
          if (typeof info.rubros !== 'undefined') {
            if (info.rubros.length) {
              this.formCrear.permisos_stock = info.rubros
            } else {
              this.formCrear.permisos_stock.push('TODOS')
            }
          }
          this.disabledGenerico = false
        } else {
          this.disabledGenerico = true
        }
        // this.asignarOlconv_nuevo('first') // Hacer una función exclusiva para editar primero
      }

      if (this.formCrear.permisos.includes(3) == false && include_turno) {
        this.formCrear.permisos.push(3)
        this.permisos_asignados = this.permisos_asignados.concat(this.permisos_turnos) /* PERMISOS ADICIONALES */
        eliminar.push('TURNOS')
        // eliminar.push('TURNOS_ADMIN')
      }

      if (this.formCrear.permisos.includes(4) == false && include_cross) {
        this.formCrear.permisos.push(4)
        this.permisos_asignados = this.permisos_asignados.concat(this.permisos_cross) /* PERMISOS ADICIONALES */
        eliminar.push('CROSSDOCK')
      }
      if (this.formCrear.permisos.includes(5) == false && include_fm) {
        this.formCrear.permisos.push(5)

        eliminar.push('FM')
      }
      // /* CASOS ESPECIALES DENVER Y BD y BD_GRAFICOS */
      if (permisos_total.includes('GUIAS_EMPRESA')) {
        if (info.ol_convenio === 51) {
          this.formCrear.permisos_extras.push('DENVER')
        } else if (info.ol_convenio === 412) {
          /* NUEVO PERMISO PARA BD */
          if (permisos_total.includes('BD_GRAFICOS')) {
            this.formCrear.permisos_extras.push('BD_GRAFICOS')
          } else {
            this.formCrear.permisos_extras.push('BD')
          }
        } else {
          eliminar.push('GUIAS_EMPRESA')
          eliminar.push('OL_LISTACONV')
          eliminar.push('OL')
          eliminar.push('SACL')
          eliminar.push('BD_GRAFICOS')
        }
      } else {
        /* ELIMINAR LOS PERMISOS GENERALES PARA TOMAR LOS EXTRAS Y PODER MSTRAR LOS PERMISOS ADICIONALES*/
        eliminar.forEach((element) => {
          index = permisos_total.indexOf(element)
          if (index > -1) {
            permisos_total.splice(index, 1)
          }
        })

        this.formCrear.permisos_extras = []

        if (permisos_total.length) {
          this.formCrear.permisos_extras = permisos_total //PERMISOS EXTRAS
        }
        this.elementosPermisosLog() //ELEMENTOS QUE VAN A MOSTRARSE
      }
      this.formCrear.permisosArray = eliminar // lo que se elimina es la base de permisosArray
    },

    /* ------------------------------------FIN LOGIN------------------------------------------------ */

    /* -----------------------------------SUIZO----------------------------------------------------- */
    fnHabilitarSelect() {
      this.permisos_asignados = []
      if (this.tipoModal == 1) {
        this.formCrear.ol_convenio = ''
        this.formCrear.guias_convenios = []
      }
      this.formCrear.permisos_extras = this.formCrear.permisosArray.length ? this.formCrear.permisos_extras : []
      this.showConvSuizo = this.formCrear.permisos.includes(1) ? true : false
      this.showMultiSuizo = this.formCrear.permisos.includes(1) && (this.formCrear.codCliente == 8000 || this.formCrear.codCliente == 7717) ? true : false
      /* PERMISOS ADICIONALES */
      let guias = this.formCrear.permisos.includes(1) ? true : false
      if (guias) {
        this.permisos_asignados = this.permisos_guias_suizo
      }
      if (this.guia_convenios_precargados != null && this.tipoModal == 2 && this.showConvSuizo) {
        this.separarGuias(this.guia_convenios_precargados)
      }
    },
    controlExtraSuizo() {
      /*--- ASIGNO A OL_CONVENIO EL PRIMERO CONVENIO DE GUIAS_CONVENIO  ---*/
      if (!this.olmulti) {
        this.formCrear.ol_convenio =
          this.formCrear.ol_convenio == '' || this.formCrear.ol_convenio == null
            ? this.formCrear.guias_convenios.length > 0
              ? this.formCrear.guias_convenios[0]
              : null
            : this.formCrear.ol_convenio
      } else {
        this.formCrear.ol_convenio = 20
      }
      /* Asignar permisos */
      this.asignarPermisoSuizo() // ASIGNA LOS PERMISOS ESPECIALES
    },
    asignarPermisoSuizo() {
      if (this.formCrear.permisos.includes(1)) {
        this.formCrear.permisosArray.push('OL')
      }
      if (this.olmulti) {
        /* caso especial si es OL MULTI PERO POR LOS DEMÁS PERMISOS NECESITA OL_CONVENIO */
        if (this.formCrear.permisosArray.indexOf('OL_MULTI') == -1) {
          this.formCrear.permisosArray.push('OL_MULTI')
          this.formCrear.permisosArray.push('OL_LISTACONV')
        }
      } else if (this.formCrear.guias_convenios.length >= 1) {
        if (this.formCrear.permisosArray.indexOf('OL_LISTACONV') == -1) {
          this.formCrear.permisosArray.push('OL_LISTACONV')
        }
      }
    },
    elementosPermisosSuizo() {
      this.showConvSuizo = false
      this.disabledSelectConv = false
      /* CONTROLA CONTINUAMENTE EL ARRAY permisos */
      /* GUIAS Y CROSS */
      if (this.formCrear.permisos.includes(1)) {
        /* SI INCLUYE 1 Y/O 4  */
        /* MUESTRO SHOWCONV Y CARGO LOS DATOS SI CORRESPONDE */
        this.showConvSuizo = true //OK
        /* Y SHOWMULTI SI ES USUARIO SUIZO O LOGIN */
        this.showMultiSuizo = (this.formCrear.codCliente === 8000 || this.formCrear.codCliente === 7717) && this.formCrear.permisos.includes(1) ? true : false
      }
    },
    asignarPermisosEditarSuizo(array_permisos, info) {
      let permisos_total = array_permisos
      let eliminar = []
      let index = -2
      this.permisos_asignados = []
      let include_OL = array_permisos.includes('OL')

      if (include_OL) {
        this.permisos_asignados = this.permisos_asignados.concat(this.permisos_guias_suizo) /* PERMISOS ADICIONALES */
        this.formCrear.permisos.push(1)
        eliminar.push('OL')
        if (array_permisos.includes('OL_MULTI') && (info.cod_cliente == 8000 || info.cod_cliente == 7717)) {
          //ES OL, OL_MULTI
          eliminar.push('OL_MULTI')
          this.showMultiSuizo = true
          this.olmulti = true
          this.disabledMultiSuizo = true
        }
      }
      /* ELIMINAR LOS PERMISOS GENERALES PARA TOMAR LOS EXTRAS Y PODER MSTRAR LOS PERMISOS ADICIONALES*/
      eliminar.forEach((element) => {
        index = permisos_total.indexOf(element)
        if (index > -1) {
          permisos_total.splice(index, 1)
        }
      })

      this.formCrear.permisos_extras = []
      if (permisos_total.length) {
        this.formCrear.permisos_extras = permisos_total //PERMISOS EXTRAS
      }
      this.elementosPermisosSuizo() //ELEMENTOS QUE VAN A MOSTRARSE

      this.formCrear.permisosArray = eliminar // lo que se elimina es la base de permisosArray
    },
    /* ------------------------------------FIN SUIZO------------------------------------------------- */
    controlEmail() {
      return this.error_email != '' && this.formCrear.email != '' ? true : false
    },
    /* -------------------------------FUNCIONES GUARDAR Y EDITAR------------------------------------- */
    guardarUsuario(formName) {
      /* Controlo los demás campos que depende de otros */
      /* En el caso de Suizo con permisos SACL no es obligatorio Convenio */
      this.$refs[formName].validate((valid) => {
        if (valid) {
          /* reglas afuera del rules */
          let rulesExtras = false
          if (this.formCrear.empresa == 'lo') {
            rulesExtras = this.rulesConv != '' || this.ruleConvol != '' || this.rulePedido != '' || this.rulesTurnos != '' ? true : false
          } else {
            rulesExtras = this.rulesConvSuizo != '' ? true : false
          }
          // let controlPassword = this.formCrear.password == '' || this.formCrear.password.length < 8 || this.formCrear.password.length > 25 ? true : false

          // if (rulesExtras || controlPassword || this.controlEmail()) {
          if (rulesExtras || this.controlEmail()) {
            // this.errorPass = controlPassword
            this.$message({
              message: 'Por favor. Controle los campos obligatorios.',
              type: 'warning',
            })
          } else {
            if (this.empresa == 'lo') {
              this.controlExtraLogin() //INCLUYE ASIGNAR PERMISOS Y CONTROL DE PERMISOS PEDIDOS
            } else {
              this.controlExtraSuizo()
            }

            this.loadingGuardar = true
            HTTP.post('/guiasnew/altaUsersNuevaFuncion', this.formCrear)
              // HTTP_DESARROLLO.post('/guiasnew/altaUsers_git push PRUEBA_PERMISO', this.formCrear)
              .then((res) => {
                if (typeof res.data.resultado === 'undefined') {
                  switch (res.data) {
                    case 2:
                      this.$message({
                        message: 'El usuario ya existe.',
                        type: 'error',
                      })
                      this.$refs.user.focus()
                      break
                    case 99:
                      this.$message({
                        message: 'La contraseña no cumple con los requisitos.',
                        type: 'error',
                      })
                      this.$refs.pass.focus()
                      break
                    case 3:
                      this.$message({
                        message: 'Usuario eliminado. Elija un nuevo nombre de usuario',
                        type: 'error',
                      })
                      this.$refs.user.focus()
                      break
                  }
                } else if (typeof res.data.resultado != 'undefined') {
                  if (res.data.resultado === 1) {
                    this.$message({
                      message: 'Usuario creado exitosamente.',
                      type: 'success',
                    })
                    this.dialogCrearUser = false
                    // Actualizar tabla de usuario y limpiar filtro
                    EventBus.$emit('crearUsuario', true)
                  } else {
                    this.$message({
                      message: 'No se pudo crear el usuario. Controle los datos e intente nuevamente.',
                      type: 'error',
                    })
                  }
                } else {
                  this.$message({
                    message: 'No se pudo crear el usuario. Controle los datos e intente nuevamente.',
                    type: 'error',
                  })
                }

                this.loadingGuardar = false
              })
              .catch((error) => {
                console.log(error)
                this.loadingGuardar = false
              })
          }
        } else {
          this.$message({
            message: 'Por favor. Controle los campos obligatorios.',
            type: 'warning',
          })
          this.errorPass = this.formCrear.password == '' ? true : false
          this.loadingGuardar = false
          return false
        }
      })
    },
    editarUserNuevo(formName) {
      /* Controlo los demás campos que depende de otros */
      this.showError = false
      this.loadingEdit = true

      this.$refs[formName].validate((valid) => {
        if (valid) {
          /* HACER UN SOLO CAMBIO */
          // console.log(this.formCrear.password)
          if (this.formCrear.reset_password != '' && this.formCrear.reset_password !== null) {
            if (this.formCrear.reset_password.length < 8 || this.formCrear.reset_password.length > 25) {
              this.showError = true
              this.$refs.reset.focus()
              this.loadingEdit = false
            }
          }

          let rulesExtras = false
          if (this.formCrear.empresa == 'lo') {
            rulesExtras = this.rulesConv != '' || this.ruleConvol != '' || this.rulePedido != '' || this.rulesTurnos != '' ? true : false
          } else {
            rulesExtras = this.rulesConvSuizo != '' ? true : false
          }
          if (rulesExtras === true || this.showError) {
            this.$message({
              message: 'Por favor. Controle los campos obligatorios.',
              type: 'warning',
            })
          } else {
            // this.loadingEdit = true
            if (this.empresa == 'lo') {
              this.controlExtraLogin() //INCLUYE ASIGNAR PERMISOS Y CONTROL DE PERMISOS PEDIDOS
            } else {
              this.controlExtraSuizo()
            }

            /* GUARDAR LOS CAMBIOS */
            // HTTP_DESARROLLO.post('/guiasnew/editUsers_prueba', {
            // HTTP.post('/guiasnew/editUsersNuevaFuncion', {
            HTTP.post('/guiasnew/editUsersNuevaFuncion', {
              datos: this.formCrear,
              id: this.Iduser,
            })
              .then((res) => {
                if (typeof res.data.resultado != 'undefined') {
                  if (res.data.resultado) {
                    this.$message({
                      message: 'Se guardaron los cambios exitosamente',
                      type: 'success',
                    })
                    this.dialogCrearUser = false
                    // Actualizar tabla de usuario y limpiar filtro
                    EventBus.$emit('crearUsuario', true)
                    /* Si el usuario donde se realizó el cambio es el mimso
                                        que esta logueado, pedir deslogueo */
                    if (this.Iduser == JSON.parse(window.localStorage.getItem('datos')).uid) {
                      HTTP.post('/guiasnew/getDatosLogueo', {
                        empresa: this.empresa,
                        id: this.Iduser,
                      }).then((res) => {
                        if (res.data) {
                          window.localStorage.removeItem('permisos')
                          window.localStorage.setItem('permisos', JSON.stringify(res.data.permisos))
                          window.localStorage.removeItem('datos')
                          window.localStorage.setItem('datos', JSON.stringify(res.data))
                        }
                      })
                    }
                  } else {
                    this.$message({
                      message: 'Ocurrió un error. No se guardaron los cambios. Intente nuevamente.',
                      type: 'error',
                    })
                  }
                } else if (res.data == 4) {
                  this.$message({
                    message: 'El usuario ya existe.',
                    type: 'error',
                  })
                  this.$refs.user.focus()
                } else {
                  this.$message({
                    message: 'Ocurrió un error. Intente nuevamente',
                    type: 'error',
                  })
                }
                this.loadingEdit = false
              })
              .catch((error) => console.log(error))
            /* DESCOMENTAR LUEGO */
          }
          this.loadingEdit = false
        } else {
          // this.$message.error('Complete los campos requeridos')
          this.loadingEdit = false
          return false
        }
      })
    },
  },
  watch: {
    formCrear: {
      handler: function (val) {
        if (val.empresa == 'lo') {
          this.showMulti =
            val.empresa == 'lo' &&
            val.permisos.includes(1) &&
            // val.permisos.includes(2) == false &&
            (val.codCliente == 8000 || val.codCliente == 7717)
              ? true
              : false
          this.olmulti = this.showMulti == false ? false : this.olmulti
          if (this.tipoModal == 2) {
            // let valor =
            //   val.empresa == 'lo' &&
            //   val.permisos.includes(1) &&
            //   val.permisos.length == 1
            // this.disabledSelectConv =
            //   this.showMulti == false && valor == false ? false : true
          } else {
            this.disabledSelectConv = this.showMulti == false ? false : this.disabledSelectConv
          }

          if (val.guias_convenios.length && ((val.permisos.includes(1) && val.permisos) || val.permisos.includes(4))) {
            // console.log(val.guias_convenios)
            // if (val.permisos.includes(2)) {
            //   this.aux_convenio = []
            // } else {
            this.disabledSelectConv = false
            val.guias_convenios.forEach((element) => {
              this.aux_convenio.push(element)
            })
            // this.aux_convenio=val.guias_convenios
            // }
          } else {
            this.aux_convenio = []
          }
        } else {
          this.showMultiSuizo = val.empresa == 'u' && val.permisos.includes(1) && (val.codCliente == 8000 || val.codCliente == 7717) ? true : false
        }
      },
      deep: true,
    },
  },
  computed: {
    // get only
    passRule: function () {
      if (this.tipoModal == 1) {
        var resul = '151'
        resul =
          this.formCrear.password == ''
            ? 'Por favor ingresar contraseña'
            : this.formCrear.password.length < 8
            ? 'Debe tener al menos 8 caraceteres'
            : this.formCrear.password.length > 25
            ? 'No puede tener más de 25 caracteres'
            : ''
      } else {
        if (this.formCrear.password != '') {
          resul = this.formCrear.password.length < 8 ? 'Debe tener al menos 8 caraceteres' : this.formCrear.password.length > 25 ? 'No puede tener más de 25 caracteres' : ''
        }
      }

      return resul
    },
    rulesConv: function () {
      let guias_conv = this.formCrear.guias_convenios != null ? this.formCrear.guias_convenios.length == 0 : false
      let resul = ''
      let perm_cross = this.formCrear.permisosArray.includes('CROSSDOCK')
      // let perm_cliente =
      //   this.formCrear.permisos_extras.includes('TURNOS_CLIENTE')

      if (guias_conv) {
        resul =
          this.showConv && !this.addDENVER && !this.addBD && guias_conv && !this.olmulti
            ? 'Debe seleccionar al menos un Convenio'
            : this.showConv && this.showConvStock && this.formCrear.guias_convenios.length == 0
            ? 'Debe seleccionar al menos un Convenio'
            : this.showConv && !this.addDENVER && !this.addBD && perm_cross
            ? 'Debe seleccionar un Convenio para los demás permisos'
            : this.showConv && !this.addDENVER && !this.addBD && perm_cross && this.formCrear.guias_convenios.length > 1
            ? 'No puede seleccionar más de un convenio'
            : ''
      }

      return resul
    },
    rulesConvLog: function () {
      let guias_conv = this.formCrear.guias_convenios != null ? this.formCrear.guias_convenios.length == 0 : false
      let resul = ''
      if (guias_conv) {
        resul =
          this.showConv && guias_conv && !this.olmulti
            ? 'Debe seleccionar al menos un Convenio'
            : this.showConv && this.showConvStock && this.formCrear.guias_convenios.length == 0
            ? 'Debe seleccionar al menos un Convenio'
            : ''
      }
      return resul
    },
    ruleConvol: function () {
      var resul = this.showConvStock && (this.formCrear.convol == null || this.formCrear.convol === '') && this.empresa == 'lo' ? 'Debe seleccionar un Convenio Log.' : ''
      return resul
    },
    /* Si el permiso asignado es sólo SAC los convenios son opcionales */
    rulesConvSuizo: function () {
      var resul =
        this.showConvSuizo && this.formCrear.guias_convenios.length == 0 && this.disabledMultiSuizo == false && this.formCrear.permisos.includes(1)
          ? 'Debe seleccionar al menos un Convenio'
          : ''
      return resul
    },
    rulePedido: function () {
      let perm_gral = this.formCrear.permisos.includes(2)
      let perm_extra = this.formCrear.permisos_extras.includes('PEDIDOS') || this.formCrear.permisos_extras.includes('PEDIDOS_TODOS')
      let cant_perm = this.formCrear.permisos_stock.length
      let resul = ''
      resul = perm_gral && perm_extra && this.empresa == 'lo' && cant_perm == 0 ? 'Debe seleccionar al menos permiso' : ''
      return resul
    },
    rulesTurnos: function () {
      let consulta = this.formCrear.permisos_extras.includes('TURNOS_CONSULTA')
      let operario = this.formCrear.permisos_extras.includes('TURNOS_OPERARIO')
      let vigilancia = this.formCrear.permisos_extras.includes('TURNOS_VIGILANCIA')
      let cliente = this.formCrear.permisos_extras.includes('TURNOS_CLIENTE')
      let admin = this.formCrear.permisos_extras.includes('TURNOS_ADMIN')

      let valor = this.formCrear.permisosArray.includes('TURNOS') && this.formCrear.permisos.includes(3) && !consulta && !operario && !vigilancia && !admin && !cliente ? true : false
      let resultado = ''
      resultado = valor && this.empresa == 'lo' ? 'Debe seleccionar al menos un permiso adicional para TURNOS' : ''
      return resultado
    },
  },
}
</script>
<style scoped>
.el-dialog__header {
  border-bottom: 1px dashed rgb(189, 39, 39) !important;
}
.padding-input {
  padding-bottom: 5px;
}
.el-form-item {
  margin-bottom: 0px !important;
}
</style>
