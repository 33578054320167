<template>
  <div>
    <el-row :gutter="10" class="hidden-xs-only">
      <el-col
        :lg="{ span: 12, offset: 6 }"
        :md="{ span: 12, offset: 6 }"
        :sm="{ span: 8, offset: 7 }"
        :xs="{ span: 22, offset: 1 }">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-size: 20px"
              ><i class="el-icon-edit"></i>Formulario para recuperar datos</span
            >
            <p v-if="show_form_user" style="margin-bottom: -5px !important">
              Para recuperar su contraseña complete el siguiente formulario
            </p>
          </div>
          <el-form
            :model="recup"
            :rules="rules"
            ref="recup"
            status-icon
            class="demo-dynamic"
            v-if="show_form_user">
            <el-row :gutter="10">
              <el-col :span="17" :xs="24">
                <el-form-item label="Nombre Completo" prop="nombre">
                  <el-input
                    type="text"
                    v-model="recup.nombre"
                    autocomplete="off"
                    ref="nombre"
                    placeholder="Ingrese nombre completo"
                    clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7" :xs="24">
                <el-form-item label="DNI" prop="dni">
                  <el-input
                    v-model.number="recup.dni"
                    autocomplete="off"
                    placeholder="Ingrese dni"
                    clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12" :xs="24">
                <el-form-item label="Email de contacto" prop="email">
                  <el-input
                    type="email"
                    v-model="recup.email"
                    autocomplete="off"
                    placeholder="Ingrese email"
                    ref="email"
                    clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" :xs="24">
                <el-form-item label="Teléfono/Celular" prop="tel">
                  <el-input
                    v-model.number="recup.tel"
                    autocomplete="off"
                    ref="nombre"
                    placeholder="Ingrese Teléfono/Celular"
                    clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item class="el-form-button">
              <!-- <el-popconfirm
                            confirm-button-text='Si'
                            cancel-button-text='No'
                            icon="el-icon-info"
                            icon-color="red"
                            title="Está seguro que desea enviar el formulario?"
                            @confirm="enviarForm('recup')" v-if="disabled_enviar"
                            > -->
              <el-button
                :type="showbtnC"
                style="margin-right: 5px"
                @click="enviarForm('recup', 'web')">
                <i class="el-icon-s-promotion"></i> Enviar
              </el-button>
              <!-- </el-popconfirm> -->
              <el-button @click="cambiarForm"
                ><i class="el-icon-back"></i> Regresar</el-button
              >
            </el-form-item>
          </el-form>
          <div v-else v-loading="cargarinfo">
            <img :src="imgResultado" alt="" />
            <p style="text-aling: justify">{{ msj_email_form }}</p>
            <div v-if="!show_form_user">
              <el-tooltip
                class="item"
                effect="dark"
                content="Regresar"
                placement="bottom">
                <el-button @click="recargar" type="warning"
                  ><i class="el-icon-back"></i> Regresar</el-button
                >
              </el-tooltip>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- Para movil  -->
    <el-row class="hidden-sm-and-up">
      <el-col :xs="20" :offset="2">
        <el-card class="box-card" style="overflow-y: scroll; height: 400px">
          <div slot="header" class="clearfix">
            <span style="font-size: 20px"
              ><i class="el-icon-edit"></i>Formulario para recuperar datos</span
            >
            <p style="margin-bottom: -5px !important">
              Para recuperar su contraseña complete el siguiente formulario
            </p>
          </div>
          <div v-if="show_form_user">
            <el-form :model="recup" :rules="rules" ref="recup" status-icon>
              <el-row :gutter="10">
                <el-col :span="17" :xs="24">
                  <el-form-item label="Nombre Completo" prop="nombre">
                    <el-input
                      type="text"
                      v-model="recup.nombre"
                      autocomplete="off"
                      ref="nombre"
                      placeholder="Ingrese nombre completo"
                      clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7" :xs="24">
                  <el-form-item label="DNI" prop="dni">
                    <el-input
                      v-model.number="recup.dni"
                      autocomplete="off"
                      placeholder="Ingrese dni"
                      clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12" :xs="24">
                  <el-form-item label="Email de contacto" prop="email">
                    <el-input
                      type="email"
                      v-model="recup.email"
                      autocomplete="off"
                      placeholder="Ingrese email"
                      ref="email"
                      clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" :xs="24">
                  <el-form-item label="Teléfono/Celular" prop="tel">
                    <el-input
                      v-model.number="recup.tel"
                      autocomplete="off"
                      ref="nombre"
                      placeholder="Ingrese Teléfono/Celular"
                      clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item class="el-form-button">
                <el-popconfirm
                  confirm-button-text="Si"
                  cancel-button-text="No"
                  icon="el-icon-info"
                  icon-color="red"
                  title="Está seguro que desea enviar el formulario?"
                  @confirm="enviarForm('recup', 'celular')">
                  <el-button
                    :type="showbtnC"
                    slot="reference"
                    style="margin-right: 5px">
                    <i class="el-icon-s-promotion"></i> Enviar
                  </el-button>
                </el-popconfirm>
                <el-button @click="cambiarForm"
                  ><i class="el-icon-back"></i> Regresar</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div v-else v-loading="cargarinfo">
            <img :src="imgResultado" alt="" />
            <p style="text-aling: justify">{{ msj_email_form }}</p>
            <div v-if="!show_form_user">
              <el-tooltip
                class="item"
                effect="dark"
                content="Regresar"
                placement="bottom">
                <el-button @click="recargar" type="warning"
                  ><i class="el-icon-back"></i> Regresar</el-button
                >
              </el-tooltip>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { HTTP } from '@/axios'

export default {
  props: ['empresa'],
  data() {
    var validarDNI = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Por favor, ingrese su DNI'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('Por favor, ingrese sólo números'))
      } else {
        if (value.toString().length > 9) {
          callback(new Error('Por favor, ingrese un dni correcto'))
        } else {
          callback()
        }
      }
    }
    return {
      recup: {
        nombre: '',
        tel: '',
        dni: '',
        email: '',
        empresa: '',
      },
      showbtnC: '',
      show_form_user: true,
      msj_email_form: '',
      imgResultado: '',
      cargarinfo: false,
      rules: {
        dni: [
          { required: true, message: 'Por ingrese un dni', trigger: 'blur' },
          { validator: validarDNI, trigger: ['blur', 'change'] },
        ],
        email: [
          { required: true, message: 'Por ingrese su email', trigger: 'blur' },
          { type: 'email', message: 'Formato incorrecto', trigger: ['blur'] },
        ],
        tel: [
          {
            required: true,
            message: 'Por ingrese un teléfono',
            trigger: 'blur',
          },
          {
            type: 'number',
            message: 'Teléfono sólo puede ser numérico',
            trigger: 'blur',
          },
        ],
        nombre: [
          { required: true, message: 'Por ingrese su nombre', trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {
    if (this.empresa == 'u') {
      this.showbtnC = 'primary'
    } else if (this.empresa == 'lo') {
      this.showbtnC = 'warning'
    }
    this.recup.empresa = this.empresa
  },
  methods: {
    enviarForm(formName, tipo) {
      // Controlar que los datos requeridos esten completos
      /* Si los datos están completos enviar el formulario */

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form_recupe = false
          this.show_form_user = true
          if (tipo == 'web') {
            this.$confirm('Está seguro de enviar el formulario?', 'Warning', {
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'OK',

              type: 'warning',
            })
              .then(() => {
                this.cargarinfo = true
                this.show_form_user = false
                HTTP.post('/loginguias/recuperarDatos', this.recup)
                  .then((res) => {
                    switch (res.data) {
                      case 0:
                        this.msj_email_form =
                          'No se pudo procesar su petición, por favor intente más tarde.'
                        this.show_form_user = false
                        this.cargarinfo = false
                        this.imgResultado = require('@/assets/003-cancelar.png')
                        break

                      case 1:
                        this.show_form_user = false
                        this.cargarinfo = false
                        this.imgResultado = require('@/assets/002-comprobado-1.png')
                        this.msj_email_form =
                          'Los datos se enviaron correctamente, pronto nos comunicaremos con usted. Muchas gracias.'

                        break
                    }
                  })
                  .catch(() => {
                    this.msj_email_form =
                      'No se pudo procesar su petición, por favor intente más tarde.'
                    this.show_form_user = false
                    this.cargarinfo = false
                    this.imgResultado = require('@/assets/003-cancelar.png')
                  })
              })
              .catch(() => {
                this.cargarinfo = false
              })
          } else {
            this.cargarinfo = true
            this.show_form_user = false
            HTTP.post('/loginguias/recuperarDatos', this.recup)
              .then((res) => {
                switch (res.data) {
                  case 0:
                    this.msj_email_form =
                      'No se pudo procesar su petición, por favor intente más tarde.'
                    this.show_form_user = false
                    this.cargarinfo = false
                    this.imgResultado = require('@/assets/003-cancelar.png')
                    break

                  case 1:
                    this.show_form_user = false
                    this.cargarinfo = false
                    this.imgResultado = require('@/assets/004-email.png')
                    this.msj_email_form =
                      'Los datos se enviaron correctamente, pronto nos comunicaremos con usted. Muchas gracias.'

                    break
                }
              })
              .catch(() => {
                this.msj_email_form =
                  'No se pudo procesar su petición, por favor intente más tarde.'
                this.show_form_user = false
                this.cargarinfo = false
                this.imgResultado = require('@/assets/003-cancelar.png')
              })
          }
        } else {
          // this.$message.error('Debe completar todos los campos.');
          this.$notify.error({
            title: 'Error',
            message: 'Debe completar todos los campos.',
            offset: 160,
          })
          return false
        }
      })
    },
    cambiarForm() {
      this.$router.push({ name: 'login' })
    },
    recargar() {
      location.reload()
    },
  },
}
</script>
