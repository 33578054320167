<template>
  <div>
    <el-row :gutter="20">
      <el-col :md="24">
        <!-- <el-button-group style="float:right;"> -->
        <el-button style="float: right" type="danger" size="mini" :loading="exportar_nodigital" plain icon="el-icon-download" @click.native="abrirModal()" :disabled="disabled_exportar"
          >Exportar Comprob. no digitalizados</el-button
        >
        <el-button style="float: right" type="success" size="mini" :loading="exportar" plain icon="el-icon-download" @click.native="downloadExportar()" :disabled="disabled_exportar"
          >Exportar comprobantes
        </el-button>
        <!-- </el-button-group> -->
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row :gutter="10">
      <!-- <el-col :md="24"> -->
      <el-form :labe-position="'top'" size="small" style="margin: 0px 5px">
        <el-col :md="4" :sm="8">
          <el-form-item label="N° de comprobante">
            <el-input v-model="buscar.comprob" clearable size="mini" @keypress.native.enter="getComprobantes(1)" placeholder="Ingrese Nº" />
          </el-form-item>
        </el-col>
        <div v-if="showConvenios">
          <el-col :md="6" :sm="8">
            <el-form-item label="Convenio">
              <el-select size="mini" v-model="buscar.convenio" filterable placeholder="Buscar por Convenio" style="width: 100%" clearable>
                <el-option v-for="item in convenios" :key="item.codigo" :label="item.codigo + ' - ' + item.descrip" :value="item.codigo"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </div>
        <el-col :md="4" :sm="8">
          <el-form-item label="Sucursal">
            <el-select v-model="buscar.sucursal" filterable placeholder="Buscar por sucursal" style="width: 100%" size="mini" clearable>
              <el-option v-for="item in sucursales" :key="item.codigo" :label="item.codigo + ' - ' + item.nombre" :value="item.codigo"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <div v-if="this.escaner || this.admin || this.ol_multi">
          <el-col :md="4" :sm="8">
            <el-form-item label="Empresa">
              <el-select v-model="buscar.empresa_buscar" filterable placeholder="Buscar por empresa" style="width: 100%" clearable size="mini">
                <el-option v-for="item in empresas" :key="item.codigo" :label="item.nombre" :value="item.codigo"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </div>
        <el-col :md="6" :sm="8">
          <el-form-item label="Cuit">
            <el-input v-model="buscar.cuit" clearable size="mini" @keypress.native.enter="getComprobantes(1)" placeholder="Buscar por Cuit" />
          </el-form-item>
        </el-col>
        <!-- <el-row :gutter="20">           -->
        <el-col :md="4" :sm="8">
          <el-form-item label="Afinidad">
            <el-select v-model="buscar.afinidad" filterable placeholder="Buscar afinidad" style="width: 100%" clearable size="mini">
              <el-option v-for="item in afinidades" :key="item.codigo" :label="item.descrip" :value="item.codigo"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8" :sm="10">
          <el-form-item label="Fecha:">
            <el-radio-group v-model="buscar.tipo_fecha">
              <el-radio :label="'comprobante'">Comprobante</el-radio>
              <el-radio :label="'escaneo'">Escaneo</el-radio>
            </el-radio-group>
            <el-date-picker
              size="mini"
              style="width: 100%"
              clearable
              v-model="buscar.fecha"
              format="dd-MM-yyyy"
              value-format="yyyyMMdd"
              type="daterange"
              start-placeholder="Desde"
              end-placeholder="Hasta"
              :picker-options="datePickerOptions2">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="4" :sm="14">
          <el-button-group style="margin-top: 34px">
            <el-button type="success" size="mini" :loading="buscando" @click.native="getComprobantes(1)">BUSCAR</el-button>
            <el-button size="mini" @click.native="limpiar(buscar.tipo)">LIMPIAR</el-button>
          </el-button-group>
        </el-col>
        <!-- </el-row> -->
      </el-form>
      <!-- </el-col> -->
    </el-row>
    <el-divider></el-divider>
    <tabla-comprobantes v-if="convenios" />

    <el-dialog :visible.sync="dialogExcel" width="50%" :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="false" :show-close="false" :top="'2vh'">
      <el-row style="border-bottom: 1px dashed #ddd; margin-top: -25px; margin-bottom: 10px" :gutter="10">
        <el-col :md="24">
          <span style="float: left; font-size: 20px; text-aling: center"
            ><strong> <i class="el-icon-download"></i> Exportar datos de comprobantes </strong></span
          >
        </el-col>
      </el-row>
      <p style="word-break: break-word">
        La descarga de resultados está limitada a 10.000 registros, su actual consulta arrojó un total de
        <strong style="color: #f76868">{{ numberTitle }}</strong> resultados.
      </p>
      <p style="word-break: break-word">Puede ajustar los resultados agregando nuevos filtros o reduciendo el rango de fechas seleccionadas.</p>
      <el-row style="padding: 5px !important">
        <center>
          <el-button type="success" icon="el-icon-download" @click="downloadSame" size="small" :loading="aceptar_load">Descargar 10.000 registros</el-button>
          <el-button @click="cancelarDownload" icon="el-icon-close" size="small" :disabled="blockCancelar">Cancelar</el-button>
        </center>
      </el-row>
    </el-dialog>
    <!-- MODAL PARA EXPORTAR COMPROBANTES NO DIGITALIZADOS -->
    <modal-exportar :convenios="convenios" :sucursales="sucursales" :afinidades="afinidades"> </modal-exportar>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
import { EventBus } from '@/event-bus'

import tablaComprobantes from '@/components/escaner/tablaComprobantes.vue'
import ModalExportar from './modalExportar.vue'

export default {
  components: {
    tablaComprobantes,
    ModalExportar,
  },
  data() {
    return {
      buscar: {
        comprob: null,
        convenio: null,
        sucursal: null,
        empresa_buscar: null,
        cuit: null,
        afinidad: 'x',
        tipo_convenio: 'receta',
        tipo_fecha: 'comprobante',
        fecha: [],
        convol: null,
        askDescargar: 0,
      },
      buscando: false,
      convenios: [],
      sesion: JSON.parse(window.localStorage.getItem('datos')),
      comprobantes: '',
      visible: false,
      bg: false,
      uid: JSON.parse(window.localStorage.getItem('datos')).uid,
      permisos_usuario: JSON.parse(window.localStorage.getItem('datos')).permisos,
      url: '//caba33.suizoargentina.com.ar/escaner/',
      pagina: 1,
      showConvenios: false,
      convSesion: '',
      ol_multi: false,
      admin: false,
      escaner: false,
      ol_lista: false,
      ol: false,
      cliente: JSON.parse(window.localStorage.getItem('datos')).cliente,
      sucursales: [],
      exportar: false,
      exportar_nodigital: false,
      disabled_exportar: true,
      dialogExcel: false,
      dialogExcelNoDig: false,
      numberTitle: false,
      aceptar_load: false,
      blockCancelar: false,
      empresas: [
        { codigo: null, nombre: 'Todas' },
        { codigo: 'DSA', nombre: 'Suizo' },
        { codigo: 'LOG', nombre: 'Log-in' },
      ],
      afinidades: [],
      datePickerOptions2: {
        disabledDate(date) {
          return date > new Date()
        },
      },
    }
  },
  created() {
    this.getConvenios(this.buscar.tipo_convenio)
    setTimeout(() => {
      this.getComprobantes(this.pagina)
    }, 500)

    EventBus.$on('actualizarComprobantes', (p) => {
      this.pagina = p
      this.getComprobantes(p)
    })
    this.permisosBuscar()
    this.getAfinidad()
  },
  beforeDestroy() {
    EventBus.$off('actualizarComprobantes', false)
    // EventBus.$off('sucursales',false)
  },
  methods: {
    permisosBuscar() {
      ;(this.ol_multi = this.permisos_usuario.includes('OL_MULTI')),
        (this.admin = this.permisos_usuario.includes('ADMIN')),
        (this.escaner = this.permisos_usuario.includes('ESCANER')),
        (this.ol_lista = this.permisos_usuario.includes('OL_LISTACONV'))
      this.ol = this.permisos_usuario.includes('OL')

      /* Mostrar select Convenio*/
      this.showConvenios = this.escaner || this.admin || this.ol_multi || this.ol_lista ? true : false
      this.asignarConvenios()
      this.getSucursales()
    },
    getConvenios(tipo) {
      HTTP.post('/remitos/getConveniosComprob', {
        tipo_convenio: tipo,
      }).then((res) => {
        // console.log(res.data)
        this.convenios = res.data
        this.convenios.push({ codigo: 999, descrip: 'COMPROBANTES LOGIN' })
        this.convenios.push({ codigo: 0, descrip: 'COMPROBANTES SUIZO' })
        if (this.convenios.length) {
          this.filtrarConvenios()
        }
      })
    },
    filtrarConvenios() {
      let lista = this.ol_lista && !this.escaner && !this.admin && !this.ol_multi ? true : false //si sólo es ol_lista

      if (this.convSesion != '' && lista) {
        let convenios = []
        let conv = ''
        let arr = this.convSesion.map(function (x) {
          return parseInt(x)
        })
        arr.forEach((convenio_unico) => {
          conv = this.convenios.filter((elemento) => elemento.codigo == convenio_unico)
          //  console.log('array c', conv)
          if (conv) {
            convenios.push(conv[0])
          }
        })

        if (convenios.length) {
          this.convenios = convenios
        }
      }
    },
    buscarGuia(comprobante, data) {
      this.bg = true
      HTTP.post('/escaner/buscarComprobante', {
        comprobante: comprobante,
        convol: data.convol,
      }).then((res) => {
        // this.comprobantes = res.data
        if (!res.data || res.data.length == 0) {
          this.$alert('No hay guias asociadas', '', {
            confirmButtonText: 'OK',
          })
        } else {
          this.selectComprobante = data
          this.modalGuia = true
          this.selectGuia = res.data
          this.tituloDialog = 'Guía Asociada: ' + this.selectComprobante.tipo + this.selectComprobante.terminal + this.selectComprobante.numero
        }
        this.bg = false
      })
    },
    getComprobantes(p) {
      this.pagina = p

      this.buscando = p == 1 ? true : false
      let tipo_permiso = null
      if (this.ol_multi || this.admin || this.escaner) {
        tipo_permiso = 'multi'
      } else if (this.ol_lista) {
        tipo_permiso = 'lista'
      } else {
        tipo_permiso = 'ol'
      }
      EventBus.$emit('cargarComprob', true)

      HTTP.post('/escaner/getComprobantes_08_05_2024', {
        pagina: this.pagina, //1
        uid: this.uid, // 22202
        buscar: this.buscar, // {tipo: 1}
        tipo_permiso: tipo_permiso,
        cliente: this.cliente,
      })
        .then((res) => {
          this.buscando = false
          this.loadingTabla = false
          EventBus.$emit('getComprobantes', {
            datos: res.data,
            convenios: this.convenios,
            pagina: this.pagina,
          })
          setTimeout(() => {
            this.disabled_exportar = false
          }, 2000)
        })
        .catch((e) => {
          this.buscando = false
          this.loadingTabla = false
          this.$alert('Error al recuperar los comprobantes ', e)
        })
    },

    limpiar() {
      this.buscar = {
        tipo_convenio: 'receta',
        tipo_fecha: 'comprobante',
        convol: null,
        convenio: null,
        comprob: null,
        cuit: null,
        fecha: [],
        empresa_buscar: null,
      }
      this.asignarConvenios()
      this.getComprobantes()
    },
    openPdf(ruta) {
      window.open(this.url + ruta, '_blank')
    },
    asignarConvenios() {
      /* Si el usuario tiene permiso OL_LISTA mostrar sólo Convenio de guia_convenios sesion */
      if (this.ol_lista && !this.escaner && !this.admin && !this.ol_multi) {
        this.convSesion = JSON.parse(window.localStorage.getItem('datos')).guia_convenios != null ? JSON.parse(window.localStorage.getItem('datos')).guia_convenios : 0

        if (!Array.isArray(this.convSesion)) {
          this.convSesion = this.convSesion.split(',')
        }
        this.buscar.convol =
          this.convSesion != 0
            ? this.convSesion.map(function (x) {
                return parseInt(x)
              })
            : null
      } else if (this.ol && !this.ol_lista && !this.escaner && !this.admin && !this.ol_multi) {
        // Si el usuario sólo tiene permiso OL, no mostrar select convenios y buscar sólo convenio ol_convenio

        this.convSesion = JSON.parse(window.localStorage.getItem('datos')).ol_convenio != null ? JSON.parse(window.localStorage.getItem('datos')).ol_convenio : 0
        this.buscar.convol = this.convSesion != 0 ? this.convSesion : null
        this.buscar.convenio = this.convSesion != 0 ? this.convSesion : null
      }
    },
    actualizarLOG() {
      HTTP.get('/escaner/actualizarComprobLOG')
        .then((res) => {
          console.log('comprobantes LOG', res.data)
        })
        .catch((e) => this.$alert('Error al recuperar los comprobantes ', e))
    },
    actualizarComprobGeneral() {
      HTTP.get('/escaner/actualizarComprobGeneral')
        .then((res) => {
          console.log('comprobantes LOG', res.data)
        })
        .catch((e) => this.$alert('Error al recuperar los comprobantes ', e))
    },
    getSucursales() {
      this.sucursales = []
      let showTodo = this.ol_multi || this.admin || this.escaner
      let showLista = this.ol_lista ? (this.sesion.guia_convenios != null ? this.sesion.guia_convenios : [this.sesion.ol_convenio]) : false
      let showOl = this.ol ? [this.sesion.ol_convenio] : false
      /* Variable que se envía en la función */
      let convenios = showTodo ? null : showLista != false ? showLista : showOl != false ? showOl : false
      let suc = { codigo: 'S/S', nombre: 'SIN SUCURSAL' }
      HTTP.post('/remitos/getSucComprobante2', {
        convenios,
      })
        .then((res) => {
          if (res.data == -99) {
            this.sucursales = []
          } else {
            this.sucursales = res.data
            if (this.sucursales.length > 1) {
              this.sucursales.push(suc)
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.$alert('No se pudo realizar la acción. Intente nuevamente')
        })
    },
    exportarComprobantes() {
      this.exportar = true
      let permiso_enviar = this.escaner || this.admin || this.ol_multi || this.ol || this.ol_lista ? true : false
      let tipo_permiso = ''
      if (this.ol_multi || this.admin || this.escaner) {
        tipo_permiso = 'multi'
      } else if (this.ol_lista) {
        tipo_permiso = 'lista'
      } else {
        tipo_permiso = 'ol'
      }
      HTTP.post('/escaner/exportarComprobantes', {
        uid: this.uid, // 22202
        buscar: this.buscar, // {tipo: 1}
        permisos: permiso_enviar,
        tipo_permiso: tipo_permiso,
        cliente: this.cliente,
        convenios: this.convenios,
        sucursales: this.sucursales,
      })
        .then((res) => {
          this.exportar = false
          // console.log(res.data)
          if (res.data == 0) {
            this.$message({
              message: 'No hay registros para descargar',
              type: 'warning',
            })
          } else if (Number.isInteger(res.data)) {
            this.dialogExcel = true
            this.numberTitle = res.data
          } else {
            window.location.href = res.data
            setTimeout(() => {
              this.aceptar_load = false
              this.blockCancelar = false
              this.dialogExcel = false
            }, 600)
          }
        })
        .catch((error) => {
          console.log(error)
          this.$alert('No se pudo realizar la acción. Intente nuevamente')
          this.guia_loading = false
          this.aceptar_load = false
          this.blockCancelar = false
        })
    },
    downloadSame() {
      this.buscar.askDescargar = 1
      this.aceptar_load = true
      this.blockCancelar = true
      this.exportarComprobantes()
    },
    downloadExportar() {
      this.buscar.askDescargar = 0
      this.exportarComprobantes()
    },
    cancelarDownload() {
      this.dialogExcel = false
      this.buscar.askDescargar = 0
    },
    abrirModal() {
      EventBus.$emit('dialog', { abrirModal: true, datos: this.buscar })
    },
    getAfinidad() {
      HTTP.get('/guiasnew/getAfinidad')
        .then((res) => {
          this.afinidades = res.data
          // this.afinidadInicio = res.data;
        })
        .catch((error) => {
          console.log(error)
          this.$alert('No se pudo realizar la acción. Intente nuevamente')
        })
    },
  },
}
</script>
<style scoped>
.el-dialog__body {
  padding: 10px 20px !important;
}
.msj-error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
</style>
