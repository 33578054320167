<template>
  <div>
    <div v-if="!showTablaEscaneo">
      <el-row :gutter="20" style="padding-bottom: 5px">
        <el-col :md="12" :xs="12" :sm="12" :offset="12"
          ><el-button round style="float: right" :style="styleTitleTabla" @click="bloquearVistas" size="mini" icon="el-icon-back"> Vista Principal de Resumen</el-button></el-col
        >
      </el-row>

      <el-row :gutter="20">
        <el-col :md="24">
          <el-card shadow="always" class="instalaciones" style="padding-bottom: 0px; padding-top: 0px; padding: 0px !important">
            <el-row :gutter="20">
              <el-row :gutter="20">
                <center>
                  <h4 :style="styleTitleTabla" style="margin-top: -20px" class="titleConv">Resumen de Escaneo {{ titulo }}</h4>
                </center>
              </el-row>
            </el-row>
            <el-row :gutter="20">
              <el-col :md="4" :sm="8" :xs="12">
                <label for="" class="vpim-label">Fecha escaneo:</label>
                <el-select v-model="buscar.fecha" placeholder="Seleccione fecha" clearable size="small" class="vpim-select">
                  <el-option v-for="fcha in fecha_Select" :key="fcha.id" :label="fcha.nombre" :value="fcha.id"></el-option>
                </el-select>
              </el-col>
              <!-- <el-col :md="6" :sm='8' :xs='12'>
                                <label for="" class="vpim-label">{{busqueda}}:</label>
                                <el-select v-model="buscar.conv" placeholder="Seleccionar Convenios" class="vpim-select" size="small" multiple  collapse-tags clearable filterable >
                                    <el-option
                                    v-for="item in array_convenios"
                                    :key="item.convol"
                                    :label="item.convol+'-'+item.nombre "
                                    :value="item.convol">
                                    </el-option>
                                </el-select>
                            </el-col> -->
              <el-col :md="6">
                <el-button-group style="margin-top: 18px">
                  <el-button type="primary" size="small" icon="el-icon-search" @click="filtrarDatos(buscar)" :loading="cargarTabla"> Filtrar </el-button>
                  <el-button type="default" size="small" icon="el-icon-close" @click="limpiarB()"> Limpiar </el-button>
                </el-button-group>
              </el-col>
              <!-- <el-col :md="8">
                                <el-button type="success" plain @click="descargarExcel(formInline)" icon="el-icon-download"  style="margin-top: 18px;float:right" size="small"  :loading="loadDesc">Descargar</el-button>
                            </el-col> -->
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <p class="font-title" :style="styleTitleTabla">Búsqueda realizada entre: {{ f_busqueda }}-Total de comprobantes en el período de búsqueda:{{ cantComprob }}</p>
      </el-row>
      <!-- :data="array_convenios.filter(data => !search || data.convenio.toLowerCase().includes(search.toLowerCase()) ||  data.convol == search)" -->
      <el-row :gutter="20" style="padding-bottom: 20px">
        <el-col :md="24">
          <el-table
            border
            stripe
            fit
            size="mini"
            v-loading="loadingC"
            element-loading-text="Cargando..."
            element-loading-spinner="el-icon-loading"
            show-summary
            :data="array_convenios.filter((data) => !search || data.convenio.toLowerCase().includes(search.toLowerCase()) || data.convol == search)"
            style="width: 100%:padding-top:2px !important;padding-bottom:2px !important;">
            <el-table-column label="Convenio" prop="convenio">
              <template slot-scope="scope">
                <span>{{ scope.row.convol }} - {{ scope.row.convenio }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Cantidad de comprobantes" prop="cantidad" width="300"> </el-table-column>
            <el-table-column align="right" width="250">
              <template slot="header" slot-scope="{}">
                <el-input v-model="search" size="mini" placeholder="Filtrar Convenio" />
              </template>
              <template slot-scope="scope">
                <center>
                  <el-button-group>
                    <el-tooltip class="item" effect="dark" content="Ver" placement="left-start">
                      <el-button
                        v-if="!controlarAccion(scope.row.convol) || scope.row.convol == 412 || scope.row.convol == 389"
                        size="mini"
                        @click="editarComprobantes(scope.row.convol)"
                        type="danger"
                        icon="el-icon-zoom-in"
                        :disabled="scope.row.cantidad == 0"
                        >Ver datos
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Actualizar" placement="top">
                      <el-button
                        v-if="controlarAccion(scope.row.convol)"
                        size="mini"
                        @click="actualizarComprobBD(scope.row.convol)"
                        type="warning"
                        icon="el-icon-refresh"
                        :loading="accionAct == scope.row.convol"
                        :disabled="scope.row.cantidad == 0"
                        >Actualizar</el-button
                      >
                    </el-tooltip>
                  </el-button-group>
                </center>
              </template>
            </el-table-column>
            <!-- <el-table-column width="200"
                    label="">
                    <template slot-scope="scope">
                            <center><el-button size="mini" @click="handleEdit(scope.$index, scope.row)">Editar datos</el-button></center>
                        </template>
                    </el-table-column> -->
          </el-table>
        </el-col>
      </el-row>
    </div>
    <div v-if="showTablaEscaneo">
      <!-- tIPO TABLA COMPROBANTES SIN GUIAS -->
      <tabla-escaneo :datosTabla="datosTabla" :styleTitleTabla="styleTitleTabla" :tipo="'SG'" :conv="ver_convenio" :mes="mes_tabla"></tabla-escaneo>
    </div>
    <!-- <el-row :gutter="20"  style="padding-top:10px" :loading="cargarTabla">
            <div v-for="group in arraySinGuias" :key="group.convol">
                <el-col :md="6" :sm="8" :xs="12" style="padding-bottom:10px;" >
                    <el-card
                        shadow="always"
                        class="instalaciones"
                        :body-style="{ padding: '0px',textAlign:'center' }"
                        style="border: 1px solid #DCDFE6;"
                        >
                        <el-button plain style="border: 0px solid #DCDFE6;">
                        <h1 style="word-break: break-all;">
                        <small st>{{group.convol}}-{{group.convenio}}</small>
                        <br>
                        {{group.cantidad}}
                        </h1>
                        </el-button>
                    </el-card>
                </el-col>
            </div>
        </el-row> -->
  </div>
</template>
<script>
// import tablaEscaneo from './tablaEscaneo.vue';
import { HTTP } from '@/axios'
import tablaEscaneo from './tablaEscaneo.vue'
import { EventBus } from '@/event-bus'

export default {
  components: { tablaEscaneo },
  props: ['datos'],
  data() {
    return {
      titulo: '',
      cantComprob: 0,
      buscar: {
        fecha: '',
      },
      array_convenios: [],
      loadDesc: false,
      cargarTabla: false,
      f_busqueda: '',
      f_aux: '',
      actual: this.$moment().format('MM'),
      loadingC: false,
      search: '',
      styleTitleTabla: {},
      showTablaEscaneo: false,
      fecha_Select: [],
      datosTabla: {},
      ver_convenio: '',
      conv_especial: '',
      accionAct: false,
      mes_tabla: '',
      fecha_actualizar: '',
      empresa: window.localStorage.getItem('empresa'),
    }
  },
  // created() {
  //      HTTP.post('/escaner/actualizarComprobBD',{})
  //         .then(res => {
  //             console.log('agregar php',res.data)
  //         })
  // },
  mounted() {
    EventBus.$on('pagConvenios', (p) => {
      if (p) {
        this.showTablaEscaneo = false
        this.refrescarTabla()
      }
    })
    this.loadingC = true
    setTimeout(() => {
      this.buscar.fecha = this.datos.mes
      this.cantComprob = this.datos.cantSguias
      this.f_busqueda = this.datos.fecha_b
      this.f_aux = this.datos.mes
      this.styleTitleTabla = this.datos.styleTitleTabla
      this.titulo = this.datos.busqueda ? 'por ' + this.datos.busqueda : ''
      this.fecha_Select = this.datos.fecha_Select
      // this.array_convenios = this.datos.arraySinGuias
      this.getComprobGuias(this.datos.mes)
      /* Pregunto si en el array de convenio viene  el convenio 418 */
      if (this.fecha_actualizar == '') {
        let resultados = this.generarDatos(this.datos.mes)
        this.fecha_actualizar = resultados.fecha_busq
      }
    }, 150)
  },
  beforeDestroy() {
    EventBus.$off('pagConvenios')
  },
  methods: {
    filtrarDatos(filtros) {
      this.cargarTabla = true
      let fecha = filtros.fecha == '' ? this.$moment().format('MM') : filtros.fecha
      let resultados = this.generarDatos(fecha)
      this.fecha_actualizar = resultados.fecha_busq
      /* En caso de que busque todas las fechas */
      if (this.f_aux != filtros.fecha) {
        this.getCantSguias(resultados.fecha_busq)
      } else {
        this.loadingC = true
        setTimeout(() => {
          this.loadingC = false
        }, 300)
        this.cargarTabla = false
      }
      this.f_aux = filtros.fecha
    },
    generarDatos(filtros) {
      let comienzo = '',
        final = '',
        fecha_busq = '',
        anio = this.$moment().years()
      if (filtros != '') {
        /* Si el mes de búsqueda es la actual */
        if (filtros == '0') {
          comienzo = this.$moment().startOf('year').format('YYYYMMDD')
          final = this.$moment().endOf('year').format('YYYYMMDD')
          this.f_busqueda = this.$moment().startOf('year').format('DD/MM/YYYY') + '-' + this.$moment().format('DD/MM/YYYY')
        } else if (this.actual == filtros) {
          comienzo = this.$moment().startOf('month').format('YYYYMMDD')
          final = this.$moment().format('YYYYMMDD')
          this.f_busqueda = this.$moment().startOf('month').format('DD/MM/YYYY') + '-' + this.$moment().format('DD/MM/YYYY')
        } else {
          let month = this.$moment(anio + '-' + filtros, 'YYYY-MM')
          comienzo = this.$moment(month).startOf('month').format('YYYYMMDD')
          final = this.$moment(month).endOf('month').format('YYYYMMDD')
          this.f_busqueda = this.$moment(month).startOf('month').format('DD/MM/YYYY') + ' - ' + this.$moment(month).endOf('month').format('DD/MM/YYYY')
        }
        fecha_busq = [comienzo, final]
      }

      return { fecha_busq: fecha_busq }
    },
    limpiarB() {
      this.buscar.fecha = this.actual
      this.cargarTabla = true
      this.array_aux = this.arraySinGuias
      this.f_aux = this.actual
      let comienzo = this.$moment().startOf('month').format('YYYYMMDD')
      let final = this.$moment().format('YYYYMMDD')
      this.fecha_busqueda = this.$moment().startOf('month').format('DD/MM/YYYY') + '-' + this.$moment().format('DD/MM/YYYY')
      let fecha_ba = [comienzo, final]
      this.getCantSguias(fecha_ba)
    },
    getCantSguias(fecha_buscar) {
      this.loadingC = true
      this.cargarTabla = true
      if (fecha_buscar == '') {
        fecha_buscar = [this.$moment().startOf('month').format('YYYYMMDD'), this.$moment().format('YYYYMMDD')]
      }

      HTTP.post('/escaner/contarComprobGuias', { fecha: fecha_buscar })
        .then((res) => {
          if (res.data.length > 0) {
            this.cantComprob = res.data.reduce((a, b) => {
              return a + b.cantidad
            }, 0)
            this.array_convenios = res.data
            this.loadingC = false
          } else {
            this.cantComprob = 0
            this.loadingC = false
            this.array_convenios = []
          }
          this.cargarTabla = false
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
          this.loadingC = false
          this.cargarTabla = false
        })
    },
    editarComprobantes(convol) {
      // mostrar tabla con escaneo
      if (convol != null) {
        /* Muestra los comprobantes relacionados a los comprobantes */
        let fecha_filtro = this.generarDatos(this.buscar.fecha).fecha_busq
        this.mes_tabla = this.fecha_Select.filter((elemento) => elemento.id == this.buscar.fecha)[0].nombre
        this.showTablaEscaneo = true
        this.datosTabla = { convol: convol, fecha: fecha_filtro }
        this.ver_convenio = convol
      }
    },
    bloquearVistas() {
      EventBus.$emit('pagPrincipal', true)
    },
    controlarAccion(convenio) {
      // `this` points to the vm instance
      let array_conv = [412, 389]
      let resultado = array_conv.find((element) => element == convenio)

      if (typeof resultado != 'undefined') {
        return true
      } else {
        return false
      }
    },
    actualizarComprobBD(convol) {
      this.accionAct = true
      this.accionAct = convol

      if (convol == 412) {
        HTTP.post('/escaner/actualizarComprobBD', { fecha: this.fecha_actualizar })
          .then((res) => {
            if (res.data.norealizados.length) {
              this.$alert('<strong>Cantidad de comprobantes que no fueron actualizadas </strong>' + res.data.norealizados.length, 'Comprobantes', {
                dangerouslyUseHTMLString: true,
              })
            }

            setTimeout(() => {
              this.refrescarTabla()
              this.accionAct = false
            }, 1500)
          })
          .catch((error) => {
            this.$alert('Error del servidor ' + error)
            this.accionAct = false
          })
      } else {
        HTTP.post('/escaner/actualizarComprobFastTrack', { fecha: this.fecha_actualizar })
          .then((res) => {
            // console.log('fast',res.data)
            this.accionAct = false
            if (res.data.norealizados.length) {
              this.$alert('<strong>Cantidad de comprobantes que no fueron actualizados </strong>' + res.data.norealizados.length, 'Comprobantes', {
                dangerouslyUseHTMLString: true,
              })
            }

            setTimeout(() => {
              this.refrescarTabla()
              this.accionAct = false
            }, 1500)
          })
          .catch((error) => {
            this.$alert('Error del servidor ' + error)
            this.accionAct = false
          })
      }
    },

    refrescarTabla() {
      this.loadingC = true
      const fecha_buscar = this.generarDatos(this.f_aux).fecha_busq
      this.getCantSguias(fecha_buscar)
    },
    getComprobGuias(mes) {
      this.loadingC = true
      let fecha_buscar = []
      if (mes === this.$moment().format('MM')) {
        fecha_buscar = [this.$moment().startOf('month').format('YYYYMMDD'), this.$moment().format('YYYYMMDD')]
      } else {
        let comienzo = this.$moment(mes).startOf('month').format('YYYYMMDD')
        let final = this.$moment(mes).endOf('month').format('YYYYMMDD')
        fecha_buscar = [comienzo, final]
      }
      this.cantComprob = true
      HTTP.post('/escaner/contarComprobGuias', { empresa: this.empresa, fecha: fecha_buscar })
        .then((res) => {
          if (res.data.length > 0) {
            this.cantSguias = res.data.reduce((a, b) => {
              return a + b.cantidad
            }, 0)
            this.array_convenios = res.data
            // this.arraySinGuias = res.data
            this.convenios = this.array_convenios.map(function (obj) {
              let rObj = {}
              rObj['convol'] = obj.convol
              rObj['nombre'] = obj.convenio
              return rObj
            })
          } else {
            this.cantComprob = 0
            this.array_cantSguiasconvenios = []
            this.convenios = []
          }
          this.loadingC = false
          this.cantComprob = false
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
          this.cantComprob = false
          this.loadingC = false
        })
    },
  },
  computed: {
    // sincronizar(convenio)
    // {
    //     let array_conv = [389,412,13]
    //     let resultado =array_conv.find(element => element == convenio)
    //     console.log('resultado', resultado)
    //     if (typeof resultado != 'undefined') {
    //         return true;
    //     }else
    //     {
    //         return false
    //     }
    // },
  },
}
</script>
<style scoped>
.instalaciones {
  padding: 10px;
}
.instalaciones h1 {
  text-align: center;
  margin: 0;
  margin-bottom: 6px;
  color: #0b406a;
}
.instalaciones h1 > small {
  /* color: #f44336db;
  font-size: 12px; */
  color: #198ca5;
  font-size: 13px;
  font-weight: bold;
}
.titleConv {
  text-transform: uppercase;
  margin: 0px;
}
</style>
