<template>
  <div class="container-fluid">
    <el-row :gutter="10">
      <center>
        <el-col :md="24">
          <p class="font-title" :style="styleTitleTabla">Total de Usuarios:{{ cantidad_elementos }}</p>
        </el-col>
      </center>
    </el-row>

    <el-table
      :data="tablaUsers"
      size="mini"
      :row-class-name="tableRowClassName"
      border
      fit
      element-loading-text="cargando resultados..."
      element-loading-spinner="el-icon-loading"
      style="width: 100%"
      v-loading="loadingTable">
      <el-table-column label="ID" prop="id" width="80" :rows="6" :columns="5"></el-table-column>
      <el-table-column label="Usuario" prop="usuario" @mouseover="dialogVisible = true">
        <template slot-scope="scope">
          <div v-if="scope.row.pass_attempts === 5">
            <el-popover placement="right" trigger="hover">
              Usuario bloqueado por la cantidad de intentos de ingreso.
              <div slot="reference" class="name-wrapper">{{ scope.row.usuario }}</div></el-popover
            >
          </div>
          <div v-if="scope.row.pass_attempts !== 5">
            {{ scope.row.usuario }}
          </div>
        </template></el-table-column
      >

      <el-table-column label="Email" prop="email" width="270">
        <template slot-scope="scope">
          <span v-if="scope.row.email"> {{ scope.row.email }} </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha Alta" prop="alta_fecha" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.alta_fecha">
            {{ formatFecha(scope.row.alta_fecha.date) }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="Convenio" prop="convenioDescrip">
        <template slot-scope="scope">
          <span v-if="scope.row.convenioDescrip != ''">
            {{ scope.row.convenioDescrip }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="Operaciones" width="150" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button-group>
            <el-tooltip class="item" effect="dark" content="Editar Usuario" placement="left">
              <el-button v-if="scope.row.borrado === 0" size="mini" @click="handleEdit(scope.row)" icon="el-icon-edit"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Desactivar Usuario" placement="top-start">
              <el-button v-if="scope.row.borrado == 0" size="mini" type="danger" @click="cambiarEstadoUser(scope.row, 1)" icon="el-icon-close"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Activar Usuario" placement="top-start">
              <el-button v-if="scope.row.borrado == 1 && scope.row.pass_attempts !== 5" size="mini" type="success" @click="cambiarEstadoUser(scope.row, 0)" icon="el-icon-check"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Desbloquear usuario" placement="top-start">
              <el-button v-if="scope.row.borrado == 1 && scope.row.pass_attempts === 5" size="mini" type="warning" @click="cambiarEstadoUser(scope.row, 0)" icon="el-icon-check"></el-button>
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-row :gutter="10">
      <el-pagination
        v-if="tablaUsers.length"
        background
        layout="total,prev, pager, next"
        :total="cantidad_elementos"
        :page-size="20"
        style="margin-top: 20px; float: right; padding-bottom: 1rem"
        @current-change="nextPage"
        :current-page.sync="pagina_actual">
      </el-pagination>
    </el-row>
    <!-- Modal ELIMINAR -->
    <el-dialog title="Eliminar Usuario" :visible.sync="dialogEliminar" width="30%">
      <span><i class="el-icon-warning" style="font-size: 20px"></i> Seguro desea eliminar al usuario {{ userName }} ?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEliminar = false" size="mini">Cancelar</el-button>
        <el-button type="primary" @click="deleteUser()" size="mini">Confirmar</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style scoped>
.el-form-item__label {
  line-height: 0px !important;
}
</style>
<script>
import { EventBus } from '@/event-bus'
const moment = require('moment')
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'

export default {
  data() {
    return {
      tablaUsers: [],
      empresa: window.localStorage.getItem('empresa'),
      styleTitleTabla: {},
      cantidad_elementos: 0,
      pagina: 1,
      loadingTable: false,
      pagina_actual: '',
      search: '',
      formLabelAlign: {
        email: '',
        region: '',
        type: '',
      },
      dialogEliminar: false,
      userName: '',
      operador: JSON.parse(window.localStorage.getItem('datos')).uid + '-' + JSON.parse(window.localStorage.getItem('datos')).user,
    }
  },
  created() {},
  mounted() {
    this.styleTitleTabla['backgroundColor'] = this.empresa == 'u' ? '#0b406a' : '#0b406a'
    this.loadingTable = true
    EventBus.$on('cargarUsuarios', (g) => {
      this.loadingTable = true
      this.tablaUsers = g.tabla
      this.pagina_actual = g.pagina
      this.cantidad_elementos = this.tablaUsers.length > 0 ? g.tabla[0].TotalRows : 0
      setTimeout(() => {
        this.loadingTable = false
      }, 1000)
      this.empresa = g.empresa
    })
  },
  beforeDestroy() {
    EventBus.$off('nuevaPagUser', 0)
    EventBus.$off('cargarUsuarios', 0)
    EventBus.$off('cargandoUser', 0)
  },
  methods: {
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    nextPage(page) {
      this.loadingTable = true
      EventBus.$emit('nuevaPagUser', page)
      this.tablaUsers = []
      this.pagina_actual = page
    },
    handleEdit(row) {
      EventBus.$emit('abrirModalUser', {
        estado: 'editar',
        info: row,
        empresa: this.empresa,
      })
    },
    cambiarEstadoUser(row, estado) {
      //    this.userName = row.usuario;
      //    this.dialogEliminar = true;
      let titulo = estado
        ? row.pass_attempts === 5
          ? 'Está seguro que desea Desbloquear al usuario ' + row.usuario + '?'
          : 'Está seguro que desea Desactivar al usuario ' + row.usuario + '?'
        : 'Está seguro que desea Activar al usuario ' + row.usuario + '?'
      let titulo_2 = estado ? (row.pass_attempts === 5 ? 'Desbloquear Usuario' : 'Desactivar Usuario') : 'Activar Usuario'
      let btnConfirm = estado ? (row.pass_attempts === 5 ? 'Desbloquear' : 'Desactivar') : 'Activar'
      this.$confirm(titulo, titulo_2, {
        distinguishCancelAndClose: true,
        confirmButtonText: btnConfirm,
        cancelButtonText: 'Cancelar',
      })
        .then(() => {
          this.deleteUser(row, estado)
        })
        .catch((action) => {
          this.$message({
            type: 'info',
            message: action === 'cancel' ? 'No se realizó ningún cambio' : 'Stay in the current route',
          })
        })
    },
    deleteUser(row, estado) {
      HTTP.post('/guiasNew/deleteUser', {
        // HTTP_DESARROLLO.post('/guiasNew/deleteUser', {
        usuario: row.usuario,
        id: row.id,
        empresa: this.empresa,
        operador: this.operador,
        estado: estado,
      })
        .then((res) => {
          // console.log(res.data)
          if (res.data) {
            this.$message({
              message: 'Se cambió estado del usuario correctamente.',
              type: 'success',
            })
            this.dialogEliminar = false
            // Actualizar tabla de usuario y limpiar filtro
            EventBus.$emit('crearUsuario', true)
          } else {
            this.$message({
              message: 'Ocurrió un error. Intente nuevamente',
              type: 'error',
            })
          }
        })
        .catch((error) => console.log(error))
    },
    tableRowClassName({ row }) {
      if (row.borrado === 1 && row.pass_attempts !== 5) {
        return 'warning-row'
      } else if (row.borrado === 1 && row.pass_attempts === 5) {
        return 'disabled-row'
      }
      return ''
    },
  },
}
</script>
<style>
.el-table .disabled-row {
  background: #36404e21;
}
</style>
