<template>
  <el-row>
    <el-form label-position="top" size="small">
      <el-row :gutter="10">
        <el-col :md="6" :sm="8" :xs="24">
          <el-form-item label="Busqueda:" for="filter">
            <el-input @keypress.native.enter="filtrar()" id="filter" name="filter" placeholder="Puede buscar por Razon social" v-model="filterText" size="mini" ref="filterInput" />
            <el-input type="text" v-show="false" /><!-- Previene reload -->
          </el-form-item>
        </el-col>
        <el-col :md="6" :sm="8" :xs="24">
          <el-form-item label="Convenio:" for="convenio_select">
            <el-select
              size="mini"
              v-model="convenio_select"
              id="convenio_select"
              name="convenio_select"
              placeholder="Seleccione convenio"
              multiple
              clereable
              filterable
              style="width: 100%"
              clearable>
              <el-option v-for="item in convenios" :key="item.convenio" :label="item.nombre" :value="item.convenio">
                <span style="float: left"
                  >{{ item.descrip }} - <span style="color: #8492a6; font-size: 13px"> {{ item.convenio }}</span></span
                >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="4" :xs="24">
          <el-form-item label="Fechas de carga:">
            <el-date-picker
              id="fecha"
              name="fecha"
              size="mini"
              style="width: 100%"
              clearable
              v-model="fecha"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="Desde"
              end-placeholder="Hasta"
              :picker-options="datePickerOptions" />
          </el-form-item>
        </el-col>
        <el-col :md="6" :sm="8" :xs="24">
          <el-form-item label=".">
            <el-button-group>
              <el-button @click="filtrar()" icon="el-icon-search" size="mini" rounded style="margin-left: 10px">Buscar</el-button>
              <el-button @click="limpiarFiltro()" icon="el-icon-refresh" size="mini" rounded style="margin-left: 10px">Limpiar</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-row>
</template>
<script>
export default {
  name: 'guias-filter',
  props: {
    convenios: { required: true },
  },
  created() {
    this.convenio_select = this.convenios.map((convenio) => convenio.convenio)
    //console.log(convenios)
  },
  mounted() {
    this.$nextTick(() => this.$refs.filterInput.focus())
  },
  data() {
    return {
      importar: false,
      filterText: '',
      convenio_select: [],
      fecha: null,
      datePickerOptions: null, // { 			disabledDate (date) { return date > new Date();} 		},
    }
  },
  methods: {
    filtrar() {
      var filtros = {}
      filtros.filter = this.filterText
      //Check convenios
      if (this.convenio_select.length > 0) filtros.convenio_select = this.convenio_select
      else filtros.convenio_select = [0]
      //Check fechas
      if (this.fecha != null) {
        filtros.filter_date = this.fecha[0] + '|' + this.fecha[1]
      }
      //filtros.checkSuper = true
      this.$events.fire('filter-set', filtros)
    },
    limpiarFiltro() {
      this.filterText = ''
      //this.convenio_select = [this.convenios[0].convenio]
      this.convenio_select = this.convenios.map((convenio) => convenio.convenio)
      this.fecha = null
      var filtros = {}
      filtros.convenio_select = this.convenio_select
      //filtros.checkSuper = true
      this.$events.fire('filter-set', filtros)
      //this.$events.fire('filter-reset')
    },
    importGuias() {
      this.$events.fire('open-form-importar')
    },
  },
}
</script>
