<template>
  <div>
    <el-dialog width="60%" :visible.sync="loadingModalUser" top="0vh" style="padding-top: 0px !important" :close-on-press-escape="false" @close="closeModalUser('form1')" :show-close="false">
      <el-row style="margin-top: -50px; border-bottom: 1px dashed #ddd">
        <el-col :span="8" :offset="8"><h2 style="text-align: center; padding: 0px">Crear usuario</h2></el-col>
      </el-row>
      <el-form ref="form1" :model="form1" :rules="rules" :label-position="labelPosition" class="demo-ruleForm" style="border-style: groove; padding: 10px">
        <h3><center>Datos personales</center></h3>
        <el-row :gutter="10">
          <el-col :md="{ span: 12 }">
            <el-form-item label="Apellido y nombre" prop="nombre">
              <el-input prefix-icon="el-icon-user-solid" size="small" v-model="form1.nombre" placeholder="Ingrese Apellido y Nombre"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 12 }">
            <el-form-item label="DNI" prop="dni">
              <el-input prefix-icon="el-icon-edit-outline" size="small" v-model.number="form1.dni" placeholder="Ingrese Dni" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :md="{ span: 12 }">
            <el-form-item label="Sucursal" prop="suc">
              <el-select v-model="form1.suc" placeholder="Elija una sucursal" size="small" style="width: 100%" clearable filterable>
                <el-option v-for="item in sucursales" :key="item.codigo" :label="item.codigo + ' - ' + item.nombre" :value="item.codigo"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 12 }">
            <el-form-item label="App " prop="app_permitidas">
              <el-select v-model="form1.app_permitidas" placeholder="Elija una opción" size="small" style="width: 100%" clearable filterable>
                <el-option v-for="item in apps" :key="item.value" :label="item.nombre" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :md="{ span: 12 }">
            <el-form-item label="Suizo - Código del chofer" prop="codigo_chofer_suizo">
              <el-input
                prefix-icon="el-icon-setting"
                size="small"
                v-model.number="form1.codigo_chofer_suizo"
                placeholder="Ingrese código del chofer"
                clearable
                ref="cod_suizo"
                :min="1"
                maxlength="5"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 12 }">
            <el-form-item label="Login - Código del chofer" prop="codigo_chofer_login">
              <el-input
                prefix-icon="el-icon-setting"
                size="small"
                v-model.number="form1.codigo_chofer_login"
                placeholder="Ingrese código del chofer"
                clearable
                ref="cod_log"
                :min="1"
                maxlength="5"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :md="{ span: 12 }" :xs="24">
            <el-form-item label="Email" prop="email_chofer">
              <el-input type="email" placeholder="Ingrese Email" size="small" prefix-icon="el-icon-message" v-model="form1.email_chofer" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 12 }" :xs="24">
            <el-form-item label="Contraseña" prop="password_chofer">
              <el-input
                :type="passwordType"
                v-model="form1.password_chofer"
                size="small"
                ref="primera"
                autocomplete="off"
                clearable
                placeholder="Ingrese contraseña"
                prefix-icon="el-icon-lock"
                style="margin-top: 3px">
                <el-button slot="append" type="password" icon="el-icon-view" @click="mostrarPass('passwordType2')"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :md="12" :sm="12" :xs="12">
            <el-button type="danger" size="small" style="width: 100%" @click="closeModalUser('form1')" icon="el-icon-close"> Cerrar </el-button>
          </el-col>
          <el-col :md="12" :sm="12" :xs="12">
            <el-button type="success" size="small" style="width: 100%" @click="createUserDB('form1')" icon="el-icon-check"> Guardar </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import { HTTP } from '@/axios'
import estadosJson from './_estados.json'

export default {
  data() {
    var validarDNI = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Por favor, ingrese su DNI'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('Por favor, ingrese sólo números'))
      } else {
        if (value.toString().length < 7 || value.toString().length > 8) {
          callback(new Error('Por favor, ingrese un dni correcto'))
        } else {
          callback()
        }
      }
    }
    // var validarCodigo = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error('Por favor, ingrese el código'))
    //   }
    //   // console.log(value)
    //   if (!Number.isInteger(value)) {
    //     callback(new Error('Por favor, ingrese sólo números'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      form1: {
        nombre: '',
        codigo_chofer_suizo: '',
        codigo_chofer_login: '',
        dni: '',
        email_chofer: '',
        password_chofer: '',
        suc: '',
        app_permitidas: '',
      },
      loadingModalUser: false,
      apps: estadosJson,
      labelPosition: 'top',
      passwordType: 'text',
      sucursales: [],
      rules: {
        dni: [{ validator: validarDNI, trigger: ['blur', 'change'] }],

        nombre: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        suc: [{ required: true, message: 'Seleccione una sucursal', trigger: 'blur' }],
        app_permitidas: [{ required: true, message: 'Seleccione una opción', trigger: 'blur' }],
        email_chofer: [
          { required: true, message: 'Campo obligatorio', trigger: 'blur' },
          { type: 'email', message: 'Ingrese un email correcto', trigger: ['blur'] },
        ],
        password_chofer: [
          { required: true, message: 'Campo obligatorio', trigger: 'blur' },
          { min: 6, message: 'Mínimo 6 caracteres', trigger: 'change' },
        ],
      },
      // codigo_chofer_suizo: [
      //     { validator: validarCodigo, trigger: ['blur', 'change'] }
      //     ],
      //     codigo_chofer_login: [
      //     { validator: validarCodigo, trigger: ['blur', 'change'] }
      //     ],
    }
  },
  mounted() {
    EventBus.$on('abrirModal', (valor) => {
      this.loadingModalUser = valor ? true : false
      this.getSucursales()
    })
  },
  beforeDestroy() {
    EventBus.$off('abrirModal')
  },
  methods: {
    controlChofer() {
      if (this.form1.codigo_chofer_login != '' && this.form1.codigo_chofer_login > 32767) {
        return true
      }
      if (this.form1.codigo_chofer_suizo != '' && this.form1.codigo_chofer_suizo > 32767) {
        return true
      }
      return false
    },
    createUserDB(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          /* Primero agrego */
          let $chofer_ = false
          if (this.form1.codigo_chofer_login || this.form1.codigo_chofer_suizo) {
            if (this.controlChofer()) {
              $chofer_ = true
            }
          }
          if (!$chofer_) {
            this.$alert('Esta por crear el usuario: <strong>' + this.form1.nombre + '.</strong>', 'Crear usuario', {
              confirmButtonText: 'Aceptar',
              dangerouslyUseHTMLString: true,
              callback: (action) => {
                if (action === 'confirm') {
                  HTTP.post('/guiasNew/updateDataNuevo', {
                    dni: this.form1.dni,
                    nombre: this.form1.nombre,
                    email: this.form1.email_chofer,
                    password: this.form1.password_chofer,
                    sucursal: this.form1.suc,
                    codigo_suizo: this.form1.codigo_chofer_suizo,
                    codigo_login: this.form1.codigo_chofer_login,
                    app_permitidas: this.form1.app_permitidas,
                  }).then((res) => {
                    // console.log('resultado',res.data)

                    if (res.data.estado == -1) {
                      this.$message.error(res.data.mensaje)
                    } else {
                      if (res.data.estado == 2) {
                        this.resetForm(formName)
                      }
                      this.loadingModalUser = res.data.estado == 2 ? false : true
                      let mensaje = res.data.estado == 2 ? res.data.mensaje : 'Error: No se pudo realizar la acción'
                      let tipo = res.data.estado == 2 ? 'success' : 'error'
                      this.$message({
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        duration: 2500,
                        message: '<strong><i>' + mensaje + '</i></strong>',
                        type: tipo,
                      })
                    }
                  })
                }
              },
            })
          } else {
            this.$alert('<strong>Controle el/los códigos de chofer ingresado.</strong>', 'Crear usuario', {
              confirmButtonText: 'Aceptar',
              dangerouslyUseHTMLString: true,
              callback: (action) => {
                if (action) {
                  this.$refs.form1.cod_log.focus()

                  // this.form1.nombre = '';
                  // this.form1.codigo = '';
                  // this.form1.suc = '';
                  // this.form1.dni = '';
                  // this.form1.email_chofer = '';
                  // this.form1.password_chofer = '';
                }
              },
            })
          }
        } else {
          this.$alert('<strong>Formulario no válido, ingrese todos los datos.</strong>', 'Crear usuario', {
            confirmButtonText: 'Aceptar',
            dangerouslyUseHTMLString: true,
            callback: (action) => {
              if (action) {
                // this.form1.nombre = '';
                // this.form1.codigo = '';
                // this.form1.suc = '';
                // this.form1.dni = '';
                // this.form1.email_chofer = '';
                // this.form1.password_chofer = '';
              }
            },
          })
        }
      })
    },
    closeModalUser(FormName) {
      this.loadingModalUser = false
      this.resetForm(FormName)
    },
    getSucursales() {
      HTTP.post('/guiasNew/getSucursales').then((res) => {
        this.sucursales = res.data
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    mostrarPass() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
  },
}
</script>
