<template>
  <div>
    <buscar-user></buscar-user>
    <tabla-user></tabla-user>
    <modal-usuario></modal-usuario>
  </div>
</template>
<script>
import BuscarUser from '@/components/usuarios/buscarUser.vue'
import TablaUser from '@/components/usuarios/tablaUser.vue'
// import ModalUsuario from '@/components/usuarios/modalUsuario.vue'
import ModalUsuario from '@/components/usuarios/modalUsuarioNuevo.vue'
export default {
  components:
  {    
    BuscarUser,
    TablaUser,
    ModalUsuario,
  }
}
</script>