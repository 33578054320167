<template>
    <div>
    <div style="background: #FFF;  min-height:90vh; padding:  10px;padding-top:0px"  v-loading="general">
      <el-card
        shadow="always"
        class="instalaciones"
        :body-style="{ padding: '0px' }"
      >
        <el-row :gutter="10">
          <div v-if="registros.length && Object.keys(this.form).length">
            <el-col :md="12">
                <div  v-if="registros.length">
                  <div slot="header" style="font-size:14px;padding: 5px;paddin-bottom:0px">
                    <span> Registro de actividad</span>  
                    <span v-if="form.hrsuc != '' && form.hojaruta!= ''">   
                      - Hoja de ruta: 
                      <span style="background-color: #014a82;color: white;padding: 0px 5px 0px 5px;">
                        {{zeroPad(suc, 2)}}{{zeroPad(hr, 8)}}</span>
                    </span>            
                  </div>
                </div>
                <div class="wrapper"                 
                v-loading="filtrando"
                element-loading-text="cargando..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255,255,255, 0.8)" style="padding: 5px;"
                >
                <table class="table table-remitos table-bordered">
                  <thead>
                    <tr>
                      <td style="width:90px">Fecha</td>
                      <td style="width:270px">Versión | DNI | nombre</td>
                      <!-- <td>Acción</td> -->
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="r in registros" class="el-table__body-wrapper" :key="r.key">
                      <td>{{ r.fecha.date | moment("DD-MM-YY H:mm") }}</td>
                      <td>{{ r.app_version || '5.0.9' }} - {{ r.dni }} - {{ r.nombre }} 
                      <span v-if="r.estado_app" style="color: darkred;"> ({{ r.estado_app }})</span>
                      </td>
                      
                    </tr>                  
                  </tbody>                
                </table>
                </div>        	            
            </el-col>
            </div>
            <div v-if="!registros.length && datos_HR.length && Object.keys(this.form).length" style="text-align:center;">
              <el-col :md="12">            
                  <center><el-card v-loading="load_pendiente" shadow="always" 
                  class="pendientes_style"  style="background-color: #014A82 !important;">
                  La hoja de ruta no fue registrada
                </el-card></center>
              </el-col>
            </div>
            <div v-if="!registros.length  && !datos_HR.length && Object.keys(this.form).length" style="text-align:center;">
              <el-col :md="12">            
                  <center><el-card v-loading="load_pendiente" shadow="always" 
                  class="pendientes_style"  style="background-color: #014A82 !important;">
                    -
                </el-card></center>
              </el-col>
            </div>
            <div v-if="Object.keys(this.form).length ">
            <el-col :md="6">                       
                <center><el-card v-loading="load_pendiente" shadow="always" class="pendientes_style">
                  <span v-if="resumenHR.length"> PENDIENTES ({{totalPendientes}})
                    <el-tooltip class="item" effect="dark" content="Refrezcar datos" placement="top-start">
                      <el-button type="danger" icon="el-icon-refresh"  style="padding: 1px !important;margin: 1px;font-size: 11px;"
                      circle size="mini" @click="refrescarDatos()" :disabled="!totalPendientes"></el-button>
                    </el-tooltip>  
                  </span> 
                  <span v-else> - </span>  
                  
                </el-card></center>                            
            </el-col>
            </div>  
            <div v-if="Object.keys(this.form).length">     
            <el-col :md="6">                
                <center><el-card v-loading="filtrando" shadow="always" style="margin-right: 5px;" :style="back_app">              
                  <!-- <div  v-for="vapp in version_app" :key="vapp.id"> -->
                      Versión de Aplicación {{version_actual}}
                      <!-- <span v-if="vapp.app_version=='4.8.1'"><i class="el-icon-check"></i></span>
                      <span v-else style="color: darkred;">(DESACTUALIZADA)</span> -->
                  <!-- </div> -->               
                </el-card></center>
            </el-col>
            </div>
            <!-- <el-col :md="8">
              <div  v-if="registros.length"> 
                <div slot="header" style="font-size:14px">
                  <span> Registro de actividad</span>  
                  <span v-if="form.hrsuc != '' && form.hojaruta!= ''">   
                    - Hoja de ruta : {{form.hojaruta}} 
                  </span>            
                </div>
                <div class="wrapper"                 
                v-loading="filtrando"
                element-loading-text="cargando..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255,255,255, 0.8)" style="padding: 5px;"
                >
                    <el-card shadow="always">
                      PENDIENTES 
                    </el-card>
                </div>
              </div>        
            </el-col> -->
          <div v-if="!Object.keys(this.form).length && !noshow_COMPLETAR" style="text-align:center;padding:10px !important;">
              <h4>Para realizar una búsqueda presione aa</h4>
              <div v-loading="loadb_hr"></div>
          </div>
          <div v-if="!Object.keys(this.form).length && noshow_COMPLETAR" style="text-align:center;padding:10px !important;">
             <el-row :gutter="10">
               <el-col :md="24">
                  <h4 style="float:left">Buscar una hoja de ruta</h4>
               </el-col>
             </el-row>
              <el-row :gutter="10">
               <el-col :md="24">
                <el-form :inline="true" :model="formInline" class="demo-ruleForm" style="float:left">
                <el-form-item label="Ingrese HR formato SSNNNNNNNN">
                  <el-input v-model="formInline.hojaruta" ref="input" name="hojaruta"
                  autocomplete="off" placeholder="Ingrese HR"  autofocus 
                  :rules="[{ required: true, message: 'Obligatorio', trigger: 'blur' }]"
                  @keypress.enter.native.prevent="buscarCampo(formInline.hojaruta)" clearable size="small"> 
                  </el-input>
                </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="buscarCampo(formInline.hojaruta)" size="small">Buscar</el-button>
              </el-form-item>
            </el-form>
               </el-col>
              </el-row>
          </div>
        </el-row>
        <el-row :gutter="10" v-if="resumenHR.length ">
          <el-col :md="24" >
            <el-table :data="totales" border fit size="mini" 
              v-loading="load_pendiente" style="width: 100%;padding:5px">
                    <el-table-column prop="total_pendientes" label="Pendientes">
                    </el-table-column>
                    <el-table-column prop="total_entregadas" label="Entregadas">
                    </el-table-column>
                    <el-table-column prop="total_rechazadas" label="Rechazadas">
                    </el-table-column>
                    <el-table-column prop="total_reprogramadas" label="Reprogramadas">
                    </el-table-column>
                    <el-table-column prop="total_retiradas" label="Retiro">
                    </el-table-column>
                    <el-table-column prop="total_anuladas" label="Anulada">
                    </el-table-column>
                </el-table>
          </el-col>
        </el-row>
      </el-card>
      <div v-if="Object.keys(this.form).length">
        <el-card
          shadow="always"
          class="instalaciones"
        >  
          <el-row :gutter="20">
            <center><h3 style="margin-top: -5px;">DESTINOS - GUÍAS - HISTORIAL</h3></center>
              <!------------------ FORMULARIO COMENTADO     --------------->
              <!-- <el-col :md="6" :xs="24" style="margin-bottom:15px">
                <el-row :gutter="10" style="background: #f0f2f5;padding:5px;padding-top:0px">
                  <el-form :model="form" ref="hr" style="margin-bottom:10px;" :rules="rules_hr"
                  :label-position="'top'" :inline="false" na size="mini" >
                    <h5><center>VERIFICAR HOJA DE RUTA</center></h5>
                      <el-col :md="15" :sm="12" :xs="24">
                        <el-form-item label="Sucursal" prop="hrsuc" 
                        >
                          <el-select v-model="form.hrsuc" placeholder="Sucursal" clearable 
                          style="width:100%; padding:0px; margin: 0px;"   :filterable="true" :disabled="desahbilitar_suc">
                            <el-option v-for="item in sucursales"  :key="item.codigo" :label="item.codigo +' - '+ item.nombre" :value="item.codigo">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :md="9" :sm="12" :xs="24">
                        <el-form-item label="HR" prop="hojaruta" >
                          <el-input v-model.number="form.hojaruta" placeholder="HR" 
                          size="mini" clearable @keypress.native.enter="ProcesarHR('hr')" :disabled="desahbilitar_suc"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :md="24" :sm="24" :xs="24">
                        <el-form-item >
                          <el-button size="mini" type="primary" 
                          @click="ProcesarHR('hr')" :loading="loadb_hr">Buscar</el-button>
                          <el-button type="danger"  size="mini"
                          @click="limpiarCampos('hr')">Limpiar</el-button>
                        </el-form-item>
                      </el-col>
                  </el-form>
                </el-row>
                <el-row :gutter="10" style="background: #f0f2f5;padding:5px;padding-top:0px">
                <h5><center>VERIFICAR GUÍA</center></h5>
                  <el-form :model="guia" ref="guia" :label-position="'top'" :inline="false" size="mini">
                      <el-col :md="10" :sm="12" :xs="24">
                        <el-form-item label="Terminal" prop="terminal" 
                        :rules="[{ required: true, message: 'Obligatorio', trigger: 'blur' },
                        { type: 'number', message: 'Sólo puede ser numérico',  trigger: ['blur','focus']  }]">
                          <el-input v-model.number="guia.terminal" placeholder="Terminal" size="mini" clearable 
                          @keypress.native.enter="ProcesarGuia('guia')" :disabled="deshabilitar_guia"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :md="14" :sm="12" :xs="24">
                        <el-form-item label="Número" prop="numero" 
                        :rules="[{ required: true, message: 'Obligatorio', trigger: 'blur' },
                        { type: 'number', message: 'Sólo puede ser numérico',  trigger: ['blur','focus']  },]">
                          <el-input v-model.number="guia.numero" placeholder="Número" size="mini" clearable 
                          @keypress.native.enter="ProcesarGuia('guia')" :disabled="deshabilitar_guia"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :md="24" :xs="24" style="margin-top:10px">
                        <el-form-item>
                          <el-button type="warning"  size="mini"
                          @click="ProcesarGuia('guia')" :loading="loadb_guia">Buscar</el-button>
                          <el-button type="danger"  size="mini"
                          @click="limpiarCampos('guia')">Limpiar</el-button>
                        </el-form-item>
                      </el-col>
                  </el-form>
                </el-row>
              </el-col> -->
              <!------------------ FIN FORMULARIO COMENTADO --------------->
              <!-- MENSAJE DE ERROR CENTRADO -->
              <div v-loading="cargar_resultado">
                <div v-if="!buscado_destino && msj_destino!=''">            
                  <h4><center>{{msj_destino}}</center></h4> 
                  <h4 style="color:#f85346"><center>{{msj_fecha}}</center></h4>
                </div>            
              </div>
              <!-- FIN DE MENSAJE DE ERROR -->
              <!-- PRIMER COLUMNA -->
              <div v-loading="cargar_resultado">
                <div v-if="buscado_destino">
                  <!-- <div v-loading ="(loadb_hr)"> -->
                    <el-col :md="8" :xs="24" style="margin-top:-15px;">
                      <div v-loading ="(loadb_guia || loadb_hr)">
                        <div v-if="buscado_destino">          
                            <el-row :gutter="10" >
                              <el-col :md="24">
                                <center> <p v-if="suc && hr"><span class="titulo_class">HR</span> 
                                <span class="suc_class">{{zeroPad(suc, 2)}}</span>
                                <span class="hr_class">{{zeroPad(hr, 8)}}</span></p> </center>
                              </el-col>
                            </el-row>  
                            <div v-if="cant_destinos>5">                  
                              <!-- BUSCADOR DE DESTINOS  -->
                              <el-row  :gutter="10">
                                <span style="font-size:12px;"><center>Cantidad de destinos: {{cant_destinos}} 
                                <span v-if="fechaHR!=''">- Fecha HR : {{fechaHR | moment('DD-MM-YY')}}</span></center></span>
                                <el-col :md="24">
                                  <center>
                                    <el-form :model="form" ref="hr" :label-position="'top'" :inline="false" size="mini" >
                                      <el-form-item>
                                        <el-input v-model="destino_filter" placeholder="Buscar destino" 
                                        size="mini" clearable @keyup.native="filtrar_destino(destino_filter)" @clear="recargar()"></el-input>
                                      </el-form-item>
                                    </el-form>                     
                                  </center>
                                </el-col>
                              </el-row>
                            </div>
                            <div v-if="cant_destinos<=5">                  
                              <!-- BUSCADOR DE DESTINOS  -->
                              <el-row  :gutter="10">
                                <span style="font-size:12px;"><center>Cantidad de destinos: {{cant_destinos}} 
                                <span v-if="fechaHR!=''">- Fecha HR : {{fechaHR | moment('DD-MM-YY')}}</span></center></span>
                              </el-row>
                            </div>
                            <div v-else-if="cant_destinos<5"><span style="font-size:12px;"><center> 
                              Fecha HR : <span v-if="fechaHR!=''">{{fechaHR | moment('DD-MM-YY')}}</span></center></span></div>
                            <div v-if="cant_guia>=7 && cant_destinos<=5" style="margin-top:10px;"></div>                              
                            <el-row :gutter="10" v-if="suc && hr">
                              <div v-loading ="(loadb_guia || loadb_hr)">
                                <div v-if="cant_destinos"> 
                                  <div :id="global">
                                    <div id="mensajes">
                                    <el-row  v-for="item in datos_HR" :key="item.key">            
                                      <el-card shadow="hover" 
                                      style="padding-bottom: 0px;padding-top: 0px;padding:0px !important">                 
                                      <!-- :style="[select_card == item.key ? {backgroundColor : '#dbdbdb9c !important'} : '']"> -->
                                      
                                      <center><el-button style="padding: 3px 0;white-space: break-spaces !important;
                                        color: #606266 !important;" type="text">
                                          <!-- @click="showGuias(item)"> -->
                                        <h4 style="color:#16456F;
                                        margin-bottom: -10px;font-weight: bolder;margin-top: 0px;" > 
                                        {{item.razsoc}}</h4>
                                        <p style="margin-bottom: -10px;font-size: 12px;">
                                          <span v-if="item.direc!=null">{{item.direc}}</span>
                                          <span v-else>S/DIRECCIÓN</span>
                                        </p>
                                        <p style="margin-bottom: -10px;font-size: 12px;">CP: {{item.codpos}} - {{item.localid}}</p>
                                        <p style="font-size: 12px;margin-bottom: -10px;">
                                          <span v-if="item.provincia!=null">{{item.provincia}}</span>
                                          <span v-else>S/PROVINCIA</span>
                                        </p>
                                        <p style="font-size: 12px;margin-bottom: -10px;">GUÍAS: {{item.count_guias}}</p>                         
                                      </el-button></center>
                                      </el-card>
                                  </el-row>
                                    </div>
                                  </div>
                                </div>
                                <div v-if="suc && hr && !cant_destinos && msj_destino ==''">
                                    <span><small><center>No se encontraron resultados</center></small></span>
                                </div>                     
                              </div>
                            </el-row>
                        </div>  
                          
                      </div>        
                    </el-col>
                    <!-- SEGUNDA COLUMNA -->
                    <div v-loading="load_pendiente">
                    <el-col :md="8" :xs="24" v-if="showTabla_2">                 
                          <!-- <el-row :gutter="10">
                            <p class="p_datos" v-if="!select_card"> 
                              Seleccione un destino. 
                            </p>                  
                          </el-row> -->
                            <el-row :gutter="10">
                              <!-- v-if="select_card"> -->
                              
                              <!-- <div v-if="razon_social!=''">  -->                  
                                  <!-- <el-row :gutter="10" >
                                    <p style=" white-space: nowrap;overflow: hidden; text-overflow: ellipsis;">
                                      <center>
                                      <span class="titulo_class" style="font-size: 12px;"  v-if="razon_social !=''">
                                        {{razon_social}}
                                      </span>
                                      </center>                 
                                    </p>
                                  </el-row> -->
                                  <!-- BUSCADOR DE DESTINOS  -->                     
                                  <!-- <el-row  :gutter="10" v-if="cant_destinos>5 && cant_guia<10" style="padding-bottom:28px;">
                                    <el-form :model="form" ref="hr" :label-position="'top'" :inline="false" size="mini" >
                                    <el-form-item>
                                    </el-form-item>
                                    </el-form>
                                  </el-row>    -->
                                  <div v-if="(cant_destinos>5 && cant_guia>=7) || (cant_destinos<=5 && cant_guia<7)" 
                                  style="margin-top: 36px !important;"></div>
                                  <div v-if="(cant_destinos<=5 && cant_guia<7)" 
                                  style="margin-top: 50px !important;"></div>
                                   <div v-if="(cant_destinos>5 && cant_guia<7)" 
                                  style="margin-top: 96px !important;"></div>
                                  <el-row  :gutter="10" v-if="cant_guia>=7">
                                    <el-col :md="24">
                                      <span style="font-size:12px;"><center>Cantidad de Guías: {{cant_guia}}</center></span>
                                      <center>
                                          <el-form :model="form" ref="hr" :label-position="'top'" :inline="false" size="mini" >
                                            <el-form-item>
                                              <el-input v-model="guia_filter" placeholder="Buscar guía por número o destino" 
                                              size="mini" clearable @keyup.native.prevent="filtrar_guia(guia_filter)" 
                                              @clear="filtrar_guia(guia_filter)"></el-input>
                                            </el-form-item>
                                          </el-form>
                                      </center>
                                    </el-col>                         
                                  </el-row>
                                  
                                    <!-- <li v-for="subregion in groups" :key ="subregion.estado_id">
                                      {{ subregion[0].guia }}
                                      {{ subregion[0].razsoc }}
                                      <ul>
                                        <li v-for="country of subregion" :key ="country.estado_id">
                                            {{ country.estado_app }}
                                        </li>
                                      </ul>
                                    </li> -->
                                  <el-row :gutter="5">  
                                    <!-- v-if="!resultado_busq_1" -->
                                      <div  v-if="datos_guias_hr">                  
                                      <!-- TABLA DE GUÍAS RELACIONADAS A SUCURSAL Y HOJA DE RUTA -->
                                        <el-table border
                                        :data="datos_guias_hr" v-loading ="load_guias"
                                        align="center" size="mini"
                                        style="width: 100%"  height="300" :row-style="tableRowClassName">             
                                        <el-table-column  prop="terminal" label="Guía" width="105">
                                          <template slot-scope="scope">
                                            <el-button  type="text" size="mini"
                                            @click="getDatosExtra(scope.row)">
                                            <span v-if="scope.row.guia!=''">{{scope.row.guia}}</span>
                                            <span v-else>-</span>
                                            </el-button>
                                          </template>
                                        </el-table-column>
                                        <el-table-column  prop="estado_app" label="Estado App" width="100">  
                                          <!-- <template slot-scope="scope">
                                            <div v-if="scope.row.estado_app != null && scope.row.estado_app!=''">
                                            <span v-if="scope.row.estado_app.toUpperCase()=='ENTREGADO'" style="color: #85c88a;">{{scope.row.estado_app.toUpperCase()}}</span>
                                            <span v-else-if="scope.row.estado_app.toUpperCase()=='RECHAZADO'"  style="color: #e86156;">{{scope.row.estado_app.toUpperCase()}}</span>
                                            <span v-else>{{scope.row.estado_app.toUpperCase()}}</span>

                                            </div>
                                            <div v-else>
                                              -
                                            </div>
                                          </template> -->
                                          <template slot-scope="scope">
                                            <div v-if="scope.row.estados != null && scope.row.estados!=''">
                                              <span v-if="scope.row.estados.toUpperCase()=='ENTREGADO'" style="color: #85c88a;">{{scope.row.estados.toUpperCase()}}</span>
                                              <span v-else-if="scope.row.estados.toUpperCase()=='RECHAZADO'"  style="color: #e86156;">{{scope.row.estados.toUpperCase()}}</span>
                                              <span v-else>{{scope.row.estados.toUpperCase()}}</span>
                                            </div>
                                            <div v-else-if="scope.row.estado_app != null && scope.row.estado_app!=''">
                                              <span v-if="scope.row.estado_app.toUpperCase()=='ENTREGADO'" style="color: #85c88a;">{{scope.row.estado_app.toUpperCase()}}</span>
                                              <span v-else-if="scope.row.estado_app.toUpperCase()=='RECHAZADO'"  style="color: #e86156;">{{scope.row.estado_app.toUpperCase()}}</span>
                                              <span v-else>{{scope.row.estado_app.toUpperCase()}}</span>
                                            </div>
                                            <div v-else>
                                              -
                                            </div>
                                          </template>
                                        </el-table-column> 
                                        <el-table-column  prop="razsoc" label="Destino" >  
                                          <template slot-scope="scope">
                                            <span v-if="scope.row.razsoc!='' && scope.row.razsoc!=null">{{scope.row.razsoc.toUpperCase()}}</span>
                                            <span v-else>-</span>
                                          </template>
                                        </el-table-column> 
                                        </el-table>
                                      </div>
                                    
                                  </el-row>
                                  <el-row v-if="resultado_busq_1 && !datos_guias_hr.length">
                                    <span><small><center>No se encontraron guías asociadas</center></small></span>
                                  </el-row>   

                              <!-- </div>      -->
                            </el-row>
                    </el-col>
                    </div>
                    <!-- TERCER COLUMNA -->
                    <el-col :md="8" :xs="24" style="margin-top:-15px" v-if="showTabla_3">
                      <el-row :gutter="5"  v-if="!select_guia && showTabla_2">
                        <p class="p_datos"> Seleccione una guía. </p>             
                      </el-row>
                      <el-row :gutter="5" v-if="select_guia">            
                        <div v-loading="load_guias_buscada">
                          <el-row :gutter="5" >
                            <p><center>
                              <span class="titulo_class" v-if="guia_buscada !=''">
                                {{guia_buscada}}
                              </span></center>                 
                            </p>
                          </el-row> 
                          <!-- Espacios -->
                          <div v-if="(cant_guia>=7)" style="margin-top:24px;"></div>
                          <div v-if="(cant_destinos>=7)" style="margin-top:60px;"></div>
                          <div v-if="(cant_destinos<7 && cant_guia<7)" style="margin-top:13px;"></div>
                          <div v-if="(cant_destinos>5 && cant_guia<=7)" style="margin-top:60px;"></div>
                          <!-- <div v-if="cant_destinos<5 && cant_guia<7" style="margin-top: 29px;"></div> -->
                          <!-- <br v-if="cant_guia>=7">
                          <el-row  :gutter="10" v-if="(cant_destinos>5 && cant_guia<10 )||(cant_guia>=10)" style="padding-bottom:28px;">
                            <el-form :model="form" ref="hr" :label-position="'top'" :inline="false" size="mini" >
                              <el-form-item>
                              </el-form-item>
                            </el-form>
                          </el-row>    -->
                          <el-row :gutter="5">
                            <!-- TABLA DE GUÍAS y chofer -->
                            <el-table size="mini" v-if="guia_buscada!=''" 
                            :data="datos_guia_buscada" align="center" border
                            style="width: 100%"  height="300">             
                              <el-table-column  prop="fecha_escaneo" label="F. Escaneo" >
                                <template slot-scope="scope">
                                  <span v-if="scope.row.fecha_escaneo != null">
                                    {{ scope.row.fecha_escaneo.date | moment("DD-MM-YYYY HH:mm:ss") }}
                                  </span>
                                  <span v-else> - </span>
                                </template>
                              </el-table-column>
                              <el-table-column  prop="chofer" label="Chofer">  
                                <template slot-scope="scope">
                                  <span v-if="scope.row.nombre_chofer !='' && scope.row.nombre_chofer!=null">
                                    <i class="el-icon-user-solid"></i> 
                                    {{scope.row.nombre_chofer.toUpperCase()}}
                                  </span>
                                  <span v-else> - </span>
                                </template>                  
                              </el-table-column> 
                              <el-table-column  prop="estado" label="Estado" width="90">  
                                <template slot-scope="scope">
                                  <span v-if="scope.row.estado!=''">{{scope.row.estado.toUpperCase()}}</span>
                                  <span v-else> - </span>
                                </template>
                              </el-table-column> 
                              <el-table-column 
                                align="right"  width="50">
                                <template slot-scope="scope">
                                  <div  v-if="scope.row.chofer">                            
                                    <center><el-button icon="el-icon-search" circle
                                      size="mini" @click="showDialog((scope.row))"
                                    ></el-button></center>
                                  </div>
                                </template>
                                </el-table-column>
                            </el-table>
                          </el-row>
                        </div>
                      </el-row>
                    </el-col>
                  <!-- </div> -->
                </div>
              </div>

          </el-row>
        </el-card>
      </div>
      <div v-if="Object.keys(this.form).length">
        <el-row :gutter="10">
          <el-col :md="24">
            <p style="text-align:center;font-weight: 300;"> Para realizar nuevas búsquedas presione F3</p>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- Datos de chofer -->
    <el-dialog title="Datos de Chofer"  width="40%" :visible.sync="dialog_chofer" top="5vh">
      <ul  v-if="datos_chofer !=''" style="padding-top:0px;">
        <li>Nombre: 
          <span v-if="datos_chofer.nombre_chofer !='' && datos_chofer.nombre_chofer!=null">
            <i class="el-icon-user-solid"></i> 
            {{datos_chofer.nombre_chofer.toUpperCase()}}
          </span>
          <span v-else> - </span>
        <li>DNI: 
          <span v-if="datos_chofer.dni_chofer !='' && datos_chofer.dni_chofer!=null">
            {{datos_chofer.dni_chofer}}
          </span>
          <span v-else> - </span>
        <li>App Versión: 
          <span v-if="datos_chofer.chofer.appversion !='' && datos_chofer.chofer.appversion!=null">
            {{datos_chofer.chofer.appversion}}
          </span>
          <span v-else> - </span>
        <li>Última conexión: 
          <span v-if="datos_chofer.chofer.ultima_fecha != null" style="color:red">
            {{datos_chofer.chofer.ultima_fecha.date | moment("DD-MM-YYYY HH:mm:ss")}}
          </span>
          <span v-else> - </span>
        </li>
        <li>Dispositivo:  
          <span v-if="datos_chofer.chofer.dispositivo !='' && datos_chofer.chofer.dispositivo!=null">
            <i class="el-icon-mobile-phone"></i> 
            {{datos_chofer.chofer.dispositivo}}
          </span>
          <span v-else> - </span>
        </li>
      </ul>
    </el-dialog>
    </div>
</template>
<script>
import {HTTP} from '@/axios'
import { EventBus } from "@/event-bus";
import Vue from 'vue'
import VueHotkey from 'v-hotkey'

Vue.use(VueHotkey)
export default {
    data() {
        return {
          form        : {},
          guia        : {},
          sucursales  : [],
          // Datos HR
          hr                        :'',
          suc                       :'',
          datos_HR                  :[],
          datos_HR_guardado         :[],
          cant_destinos             : 0,
          loadb_guia                :false,
          loadb_hr                  :false,
          cant_guia                 :0,
          datos_guias_hr            :[],
          datos_guias_hr_guardados  :[],
          razon_social              :'',
          showTabla_2               :undefined,
          load_guias                :true,
          guia_buscada              :'',
          datos_guia_buscada        :[],
          showTabla_3               :false,
          load_guias_buscada        :false,
          select_guia               :false,
          datos_select              :{},
          back_tabla                :{background:'#dbdbdb9c !important'},
          resultado_busq_1          :undefined,
          buscado_destino           :false,
          dialog_chofer             :false,
          datos_chofer              :'',
          global                    :'global',
          // autocomplete
          destino_filter            : '',
          guia_filter               : '',
          msj_destino               :'',
          msj_fecha                 : '',
          //prueba
          size                      : true,
          
          // Registro de actividades
          registros                 : [],
          totalEventos              : 0,
          filtrando                 : true,
          resumenHR                 : [],
          fechaHR                   :'',
          totalPendientes           : 0,
          load_pendiente            : true,
          version_app               :[],
          estado_app                :false,
          back_app                  :{color: 'white',marginTop: '30px',backgroundColor: "#85c88a !important"},
          version_actual            : '',
          totales:[{
                total_pendientes:0,
                total_entregadas:0,   
                total_rechazadas :0, 
                total_reprogramadas:0,
                total_anuladas   :0,   
                total_retiradas :0,   
          }],
          hr_completa               : '',
          cargar_resultado          :false,
          general                   : false,
          noshow_COMPLETAR          :true,
          formInline:{
            hojaruta:''
          }
        }
    },
    mounted() {
      
      EventBus.$on("sucursalesHR", (valor) => {
        this.sucursales = valor.sucursales
      });
      this.$events.listen('limpiar_verificar', eventData => {
        if (eventData)
        {
          
          this.limpiar_datos()
          this.form             = {}
          this.registros        = []
          this.buscado_destino  = false
          this.registros        = []
          this.totalEventos     = 0
          this.filtrando        = false
          this.resumenHR        = []
          this.hr_completa      = ''
          this.totales=[{
              total_pendientes    :0,
              total_entregadas    :0,   
              total_rechazadas    :0, 
              total_reprogramadas :0,
              total_anuladas      :0,   
              total_retiradas     :0,   
            }]
          this.noshow_COMPLETAR = true
          this.formInline.hojaruta = ''
        }
      }),

      this.$events.listen('event_hr', eventData => {
        // Separamos datos para buscar 
        // console.log(eventData)
        this.noshow_COMPLETAR = eventData.noshow_input
        if(eventData.hojaruta.length== 10)
        {

          this.cargar_resultado = true
          let hr                = eventData.hojaruta
          this.hr_completa      = eventData.hojaruta
          this.form.hojaruta    = parseInt(hr.substr(2,10))
          let suc               = hr.substr(0,2)
          let buscar            = this.sucursales.find(function filtrarElemento(elemento) {
              return elemento.codigo == parseInt(suc);});
          if(typeof buscar != 'undefined')
          {
            this.form.hrsuc = buscar.codigo
            this.ProcesarHR_F3()                 
                       
          }else
          {

            this.$message.error('La sucursal no existe.')
            this.cargar_resultado = false
            this.load_pendiente   = false
            this.msj_destino      = 'La hoja de ruta ('+ eventData.hojaruta +') no existe, verifique los datos ingresados.'
            this.msj_fecha        = 'Fecha y hora de consulta : '+this.$moment().format('DD-MM-YYYY HH:mm')
            this.limpiar_campos()
          }
        }
        
      });
      /* Tomo el valor actual de la aplicacion */
      HTTP.get("/remitos/getVersionApp")
        .then((res) => {
          this.version_actual = res.data
        });
      
    },
    beforeDestroy(){
        EventBus.$off('sucursalesHR')
        this.$events.off('event_hr')
        this.$events.off('limpiar_verificar')
    },
    methods: {
      /* --------- AGREGADO REGISTRO DE ACT. HOJA DE RUTA ----------- */
      RegistroAct()
      {
        let index = 1
        this.totalEventos     = 0
        this.filtrando        = true
        this.version_app      = []
        
        HTTP.post("/remitos/getEventosV4", {hojaruta:this.form.hojaruta,hrsuc:this.form.hrsuc})
        .then((res) => {
          if (res.data.length) {
            this.registros = res.data
            let nombre_empleado = this.registros[0].nombre
            // Se toma la versión de aplicación, convertir en array de enteros y comparar con la versión de los choferes que actuan en la HR
            let separar_version = this.version_actual.split('.')
            separar_version     = separar_version.map(x => parseInt(x))
            let separar_app_0   = this.registros[0].app_version.split('.')
            separar_app_0       = separar_app_0.map(x => parseInt(x))

            if(separar_app_0[0] == separar_version[0] && separar_app_0[1] == separar_version[1])
            {
              if(separar_app_0[2] < separar_version[2])
              {
                this.registros[0]['estado_app']='DESACTUALIZADA'
              }
            }else
            {
              this.registros[0]['estado_app']='DESACTUALIZADA'
            }        
            this.version_app.push({id:this.registros[0].id,app_version:this.registros[0].app_version.split('.')})
          
          if(this.registros.length>1)
          {
              this.registros.forEach(element => {
                index++;
                element['key'] = element.id+element.nombre+index
                separar_app_0 = []
                if(nombre_empleado != element.nombre && index > 1)
                {
                  separar_app_0 = element.app_version.split('.')
                  separar_app_0 = separar_app_0.map(x => parseInt(x))

                  if(separar_app_0[0] == separar_version[0]
                  && separar_app_0[1] == separar_version[1])
                  {
                    if(separar_app_0[2] < separar_version[2])
                    {
                        element['estado_app'] = 'DESACTUALIZADA'
                    }
                  }else
                  {
                    element['estado_app'] = 'DESACTUALIZADA'
                  }       
                  // this.version_app.push({id:element.id+element.nombre+index,
                  // app_version:element.app_version.split('.')})
                  nombre_empleado = element.nombre
                }
            });
          }
          
            this.totalEventos = res.data[0].TotalRows
            this.filtrando = false            
          } else {
            this.registros    = []
            this.totalEventos = 0
            this.filtrando    = false
            this.version_app  = []
          }
        }).catch((e) => {
          console.log(e)
          this.version_app  = []
          this.filtrando = false
        })        
      },
      getApp()
      {
        // Uso para conocer si la versión de la app está desactualizada
        

        
        // let separar_version = this.version_actual.split('.')
        // if(this.version_app.length == 1)
        // {          
        //   if(this.version_app[0]['app_version'][0] == separar_version[0] && 
        //   this.version_app[0]['app_version'][1] == separar_version[1] && 
        //   this.version_app[0]['app_version'][2] >= separar_version[2])
        //   {
        //     this.back_app["backgroundColor"] = "#85c88a !important"
        //     this.estado_app = true
        //   }else
        //   {
        //     this.back_app["backgroundColor"] = "#FF9B93 !important"
        //     this.estado_app = false
        //   }
        // }else
        // {
        //   if(this.version_app[this.version_app.length-1]['app_version'] == '4.8.1')
        //   {
        //     this.back_app["backgroundColor"] = "#85c88a !important"
        //     this.estado_app = true
        //   }else
        //   {
        //     this.back_app["backgroundColor"] = "#FF9B93 !important"
        //     this.estado_app = false
        //   }
        // }
        
     
      },  
      /* --------- RESUMEN DE HOJA DE RUTA ---------- */
      getHojaRuta()
      {
        
        this.resumenHR        = []
        this.load_pendiente   = true
        this.totalPendientes  = 0
        this.fechaHR          = ''
        this.cant_guia        = 0
        if(this.form.hrsuc!='' && this.form.hojaruta != '')
        {
          HTTP.post('/guiasNew/getResumenHR2', {
            destino: this.form.hrsuc,
            hr: this.form.hojaruta
          }).then(res => {
            this.load_pendiente = false
            this.mostrarTable   = true
            if(typeof res.data.resultado != 'undefined')
            {
              
              this.resumenHR = res.data.resultado
              this.fechaHR   = res.data.resultado.length ? res.data.resultado[0].fchhojaruta.date : ''
              this.totales=[{
                total_pendientes:0,
                total_entregadas:0,   
                total_rechazadas :0, 
                total_reprogramadas:0,
                total_anuladas   :0,   
                total_retiradas :0,   
              }]
              res.data.resultado.forEach(element => {
                this.totalPendientes +=element['Pendiente'] 
                this.totales[0]['total_pendientes']   += element['Pendiente'] 
                this.totales[0]['total_entregadas']   += element['Entregada'] 
                this.totales[0]['total_rechazadas']   += element['Rechazado'] 
                this.totales[0]['total_reprogramadas']+= element['Reprogramada'] 
                this.totales[0]['total_anuladas']     += element['Anulada'] 
                this.totales[0]['total_retiradas']    += element['Retiro']
                this.cant_guia  += element['Pendiente']+ element['Entregada']+element['Rechazado']+element['Reprogramada']+element['Anulada']+element['Retiro']       
                this.showTabla_2    = this.cant_guia ? true : false    
              });
            }
          }).catch((e) => {
            console.log(e)
            this.fechaHR   = ''
            this.mostrarTable = false
            this.load_pendiente = false
          })
        }else
        {
          this.mostrarTable = false
          this.load_pendiente = false
        }
        
      },
      /* --------- BUSCADOR DE HOJA DE RUTA --------- */
      ProcesarHR_F3()
      {
        this.rules = {}
        this.desahbilitar_suc   = true
        this.limpiar_datos()
        this.loadb_hr     = true
        this.guia         = {}
        this.global       = 'global'
        this.general = true
        HTTP.post('/verificarHR/getDestinos', 
              {origen: 'hr',
              hojaruta:this.form.hojaruta,
              hrsuc:this.form.hrsuc,
              }).then(async (res) => {
                this.general = false
                if(res.data.destinos != false && res.data.destinos != null)
                {          
                      
                  if(typeof res.data.destinos.fecha == 'undefined' )
                  {
                    this.RegistroAct()
                    this.getHojaRuta()
                    this.showAllGuias(this.form.hrsuc,this.form.hojaruta)  
                    this.buscado_destino = true
                    this.datos_HR       = res.data.destinos
                    this.datos_HR_guardado = res.data.destinos
                    this.cant_destinos  = res.data.count_destinos
                    this.hr             = res.data.hojaruta != null ? res.data.hojaruta.toString() :false
                    this.suc            = res.data.hrsuc != null ? res.data.hrsuc.toString() :false 
                    this.loadb_hr       = false
                    this.global         = res.data.count_destinos > 1 ? 'global' : ''   
                  
                            
                  }else if(typeof res.data.destinos.fecha != 'undefined' )
                  {
                     this.RegistroAct()
                    this.getHojaRuta()
                    this.showAllGuias(this.form.hrsuc,this.form.hojaruta)  
                    this.buscado_destino    = false
                    this.datos_HR           = []
                    this.datos_HR_guardado  = []
                    this.cant_destinos      = 0
                    this.hr                 = res.data.hojaruta != null ? res.data.hojaruta.toString()  :false
                    this.suc                = res.data.hrsuc != null    ? res.data.hrsuc.toString()     :false 
                    // res.data.destinos.fecha.date = this.$moment().format('DD-MM-YYYY HH:mm')
                    
                    //   console.log(comienzo,final,totalMinutos)
                    // if(this.$moment().isSame(this.$moment(res.data.destinos.fecha.date)))
                    // {        
                      let comienzo            = this.$moment(res.data.destinos.fecha.date).format('YYYY-MM-DD HH:mm')
                      let final               = this.$moment().format('YYYY-MM-DD HH:mm');
                      if(this.$moment(final).diff(comienzo,'year')==0)
                      {                      
                        let totalMinutos        = this.$moment(final).diff(comienzo,'minutes')
                        if(totalMinutos > 30)
                        {
                          this.msj_fecha   = 'Fecha y hora de consulta : '+this.$moment().format('DD-MM-YYYY HH:mm')
                          this.msj_destino = 'Hubo una falla al momento de sincronizar la Hoja de ruta ('+ this.hr_completa +'), vamos a verificar el problema.'
                        }else
                        {
                          this.msj_fecha   = 'Fecha y hora de consulta : '+this.$moment().format('DD-MM-YYYY HH:mm')
                          this.msj_destino = 'La hoja de ruta ('+ this.hr_completa +') aún no fue sincronizada, reintente nuevamente en 10 minutos.'
                        }
                    }else
                    {
                      this.msj_fecha   = 'Fecha y hora de consulta : '+this.$moment().format('DD-MM-YYYY HH:mm')
                      this.msj_destino = 'Hubo una falla al momento de sincronizar la Hoja de ruta ('+ this.hr_completa +'), vamos a verificar el problema.'
                    }
                    this.loadb_hr       = false 
                  }
                }else{
                  this.filtrando          = true
                  this.load_pendiente     = true
                  this.loadb_hr           = false 
                  this.buscado_destino    = false
                  this.registros          = []
                  this.totalPendientes    = 0
                  this.cant_destinos      = 0
                  this.cant_guia          = 0
                  this.datos_HR           = []
                  this.datos_HR_guardado  = []
                  this.datos_guias_hr     = [] 
                  this.datos_guias_hr_guardados = []
                  this.guia_buscada       = ''
                  this.datos_guia_buscada = []
                  this.cant_destinos      = 0
                  this.msj_destino        = 'La hoja de ruta ('+ this.hr_completa +') no existe, verifique los datos ingresados.'
                  this.msj_fecha          = 'Fecha y hora de consulta : '+this.$moment().format('DD-MM-YYYY HH:mm')
                  setTimeout(() => {
                    this.filtrando        = false
                    this.load_pendiente   = false
                  }, 600);
                }
                this.cargar_resultado = false
              })
              .catch(error => {
                console.log(error)
                this.buscado_destino    = false
                this.loadb_hr           = false
                this.cargar_resultado   = false
                this.limpiar_campos()
              });   
      },
      limpiar_campos()
      {
        this.buscado_destino  = false
        this.registros        = []
        this.totalEventos     = 0
        this.filtrando        = false
        this.resumenHR        = []
        this.totalPendientes    = 0
        this.cant_destinos      = 0
        this.cant_guia          = 0
        this.datos_HR           = []
        this.datos_HR_guardado  = []
        this.datos_guias_hr     = [] 
        this.datos_guias_hr_guardados = []
        this.guia_buscada       = ''
        this.datos_guia_buscada = []
        this.cant_destinos      = 0
        this.totales=[]
      },
      buscarCampo(hojaruta)
      {
        this.noshow_COMPLETAR = false
        if(hojaruta.length== 10)
        {

          this.cargar_resultado = true
          let hr                = hojaruta
          this.hr_completa      = hojaruta
          this.form.hojaruta    = parseInt(hr.substr(2,10))
          let suc               = hr.substr(0,2)
          let buscar            = this.sucursales.find(function filtrarElemento(elemento) {
            return elemento.codigo == parseInt(suc);});
          if(typeof buscar != 'undefined')
          {
            this.form.hrsuc = buscar.codigo
            this.ProcesarHR_F3()                 
                       
          }else
          {

            this.$message.error('La sucursal no existe.')
            this.cargar_resultado = false
            this.load_pendiente = false
            this.msj_destino      = 'La hoja de ruta ('+ hojaruta +') no existe, verifique los datos ingresados.'
            this.msj_fecha        = 'Fecha y hora de consulta : '+this.$moment().format('DD-MM-YYYY HH:mm')
            this.limpiar_campos()
          }
        }else if(hojaruta.length== 0){
          this.noshow_COMPLETAR = true
          this.$message.error('Por favor ingrese una hoja de ruta válida');
        }else{
          this.noshow_COMPLETAR = true
          this.$message.error('La hoja de ruta ingresada es incorrecta');
        }
      },
      /* -------- NUEVA FUNCION PARA MOSTRAR TODAS LAS GUÍAS INDEPENDIENTE DEL DESTINO --------------------*/
      showAllGuias(hrsuc,hojaruta)
      {
        // Limpio las tablas ante una nueva busqueda
        // this.select_card        = true;
        this.load_guias = true
        
        this.datos_guias_hr     = [] 
        this.datos_guias_hr_guardados = []
        // Datos tabla 3
        this.guia_buscada       = ''
        this.datos_guia_buscada = []        
        this.showTabla_3  = false
        if(hrsuc != '' && hojaruta != '')
          {
              HTTP.post('/verificarHR/getGuias', {
              hojaruta    : hojaruta,
              hrsuc       : hrsuc
            })
            .then(res => {
              this.datos_guias_hr_guardados   = res.data.count_guias > 0 ? res.data.guias : [] 
              // Sumar guias
              
              this.resultado_busq_1 = (!res.data.count_guias) ? true : false;
              // mostrar el mensaje

              this.datos_guias_hr_guardados.forEach(element => {              
                if(element.letra == '')
                {
                  element.guia = 'G-'+element.terminal.toString()+'-'+element.numero.toString()
                }else
                {
                  element.guia = element.letra.toString()+'-'+element.terminal.toString()+'-'+element.numero.toString()
                }
              });            
              this.datos_guias_hr   = this.datos_guias_hr_guardados      
              this.load_guias       = false
              this.showTabla_3      = (res.data.count_guias) ? true : false; //Si hay guias para seleccionar

            })
            .catch(error => console.log(error),this.load_guias = false); 
              
        }
      },  
      groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },  
      refrescarDatos()
      {
        this.getHojaRuta()
        this.showAllGuias(this.form.hrsuc,this.form.hojaruta)
      },  
      /* -----------------------------FUNCIONES ANTERIORES --------------------- */
      // showGuias(item)
      // {
      //   this.select_card        = item.key;
      //   // Limpio las tablas ante una nueva busqueda
      //   this.razon_social       = item.razsoc.length > 20 ? item.razsoc.slice(0, 25 - 3) + '...' : item.razsoc;
      //   this.datos_guias_hr     = [] 
      //   this.datos_guias_hr_guardados = []
      //   // Datos tabla 3
      //   this.guia_buscada       = ''
      //   this.datos_guia_buscada = []
        

      //   if(Object.keys(this.form).length)
      //   {
          
      //       HTTP.post('/verificarHR/getGuias', {
      //       // codpos      : item.codpos,
      //       // count_guias : item.count_guias,
      //       // direc       : item.direc,
      //       // localid     : item.localid,
      //       // ocliente    : item.ocliente,
      //       // provin      : item.provin,
      //       // provincia   : item.provincia,
      //       // razsoc      : item.razsoc,
      //       hojaruta    : this.hr,
      //       hrsuc       : this.suc
      //     })
      //     .then(res => {           
             
      //       this.datos_guias_hr_guardados   = res.data.count_guias > 0 ? res.data.guias : [] 
      //       this.cant_guia        = res.data.count_guias           
      //       this.resultado_busq_1 = (!res.data.count_guias) ? true : false;
      //       this.showTabla_3      = (res.data.count_guias) ? true : false; //Si hay guias para seleccionar
      //       // mostrar el mensaje
      //       this.datos_guias_hr_guardados.forEach(element => {
      //         if(element.letra == '')
      //         {
      //           element.guia = 'G-'+element.terminal.toString()+'-'+element.numero.toString()
      //         }else
      //         {
      //           element.guia = element.letra.toString()+'-'+element.terminal.toString()+'-'+element.numero.toString()
      //         }
      //       });            
      //       this.datos_guias_hr   = this.datos_guias_hr_guardados      
      //       this.load_guias       = false

      //     })
      //     .catch(error => console.log(error),this.load_guias = false);         
      //   }else if(Object.keys(this.guia).length)
      //   {
      //       HTTP.post('/verificarHR/getGuias', {
      //       codpos      : item.codpos,
      //       count_guias : item.count_guias,
      //       direc       : item.direc,
      //       localid     : item.localid,
      //       ocliente    : item.ocliente,
      //       provin      : item.provin,
      //       provincia   : item.provincia,
      //       razsoc      : item.razsoc,
      //       terminal    : this.guia.terminal,
      //       numero      : this.guia.numero
      //     })
      //     .then(res => {
      //       this.datos_guias_hr   = res.data.count_guias > 0 ? res.data.guias : []
      //       this.resultado_busq_1 = (!res.data.count_guias) ? true : false;
      //       this.showTabla_3      = (res.data.count_guias) ? true : false; //Si hay guias para seleccionar
      //       this.load_guias       = false
      //     })
      //     .catch(error => console.log(error),this.load_guias = false);         
      //   }
       
      // },
      limpiar_todo()
      {
          // Datos HR
        this.datos_HR = []
        this.datos_HR_guardado= []
        this.cant_destinos=  0
        this.loadb_guia= false
        this.loadb_hr= false
        this.cant_guia= 0
        this.datos_guias_hr= []
        this.datos_guias_hr_guardados= []
        this.razon_social= ''
        this.showTabla_2= undefined
        this.guia_buscada= ''
        this.datos_guia_buscada= []
        this.showTabla_3= false
        this.load_guias_buscada= false
        this.select_guia=  false
        this.datos_select= {}
        this.resultado_busq_1= undefined
        this.buscado_destino= false
        this.dialog_chofer= false
        this.datos_chofer= ''
        // autocomplete
        this.destino_filter=  ''
        // Registro de actividades
        this.registros=  []
        this.totalEventos=  0
        this.filtrando=  false
        this.resumenHR=  []
        this.totalPendientes=  0
        this.load_pendiente=  false
        this.version_app = []
        this.estado_app= false
        this.totales=[{
          total_pendientes:0,
          total_entregadas:0,   
          total_rechazadas :0, 
          total_reprogramadas:0,
          total_anuladas   :0,   
          total_retiradas :0,   
        }]
      },
      limpiar_datos()
      {
        this.resultado_busq_1 = false
        this.msj_destino      = ''
        this.msj_fecha        = ''
        this.buscado_destino  = false
        // this.select_card  = false
        this.select_guia      = false
        this.showTabla_2      = false
        this.showTabla_3      = false
        this.suc = ''
        this.hr  = ''
        // tabla 1
        this.datos_HR       = []
        this.cant_destinos  = 0
        this.cant_guia      = 0
        // Tabla 2
        this.razon_social   = ''
        this.datos_guias_hr = []  
        // Tabla 3
        this.guia_buscada       = ''
        this.datos_guia_buscada = []
        this.guia_filter = ''
        this.datos_select = ''
        this.datos_guias_hr_guardados = []
      },
      tableRowClassName({row}) {
        if (row.terminal === this.datos_select.terminal && row.numero === this.datos_select.numero) 
        {
          return this.back_tabla;
        } else  {
          return '';
        }        
      },
      getDatosExtra(datos){   
        this.select_guia        = true
        this.load_guias_buscada = true
        this.guia_buscada       = datos.guia;
        this.datos_guia_buscada = []
        this.datos_select       = {terminal:datos.terminal,numero:datos.numero}
        HTTP.post('/verificarHR/getHistorialGuia', {
           terminal : datos.terminal,
           numero   : datos.numero,
           hrsuc : datos.hrsuc,
           hojaruta   : datos.hojaruta
          })
          .then(res => {
            this.datos_guia_buscada= res.data
            this.load_guias_buscada = false
          })
          .catch(error => console.log(error));        
      },
      limpiarCampos(formulario)
      {
        if(formulario == 'hr')
        {
          this.form = {}
          this.desahbilitar_suc   = false
          if(Object.keys(this.guia).length == 0)
          {
            this.limpiar_datos()
          }
        }else {
          this.guia = {}
          // this.deshabilitar_guia  = false
          if(Object.keys(this.form).length == 0)
          {
            this.limpiar_datos()
          }
        }

      },
      showDialog(row)
      {
        this.dialog_chofer = true
        this.datos_chofer = row
      },      
      filtrar_destino(valor)
      {
        if(valor !='')
        {
          this.datos_HR = this.datos_HR_guardado.filter(item => {
            return item.razsoc.toLowerCase().includes(valor.toLowerCase()) == true
          });  
          // this.select_card    = false
          // this.showTabla_2    = true
          // this.showTabla_3    = false
          // Tabla 2
          // this.razon_social   = ''
          // this.datos_guias_hr = []
          // this.valor_guia     = ''  
          // Tabla 3
          // this.guia_buscada       = ''
          // this.datos_guia_buscada = []
        }else
        {
          this.datos_HR = this.datos_HR_guardado
          // this.select_card  = false
          this.showTabla_2  = true
          // this.showTabla_3  = false
          // Tabla 2
          // this.razon_social   = ''
          // this.datos_guias_hr = []  
          // Tabla 3
          // this.guia_buscada       = ''
          // this.datos_guia_buscada = []
        }
      },   
      recargar()
      {
          this.datos_HR = this.datos_HR_guardado
          // this.select_card  = false
          // this.showTabla_2  = true
          // this.showTabla_3  = false
          // Tabla 2
          // this.razon_social   = ''
          // this.datos_guias_hr = []  
          // Tabla 3
          // this.guia_buscada       = ''
          // this.datos_guia_buscada = []
      },

      filtrar_guia(valor_guia)
      {
        // alert(valor_guia)
        if(valor_guia !='')
        { 
          // if (typeof valor_guia === 'number') {
          //   this.datos_guias_hr = this.datos_guias_hr_guardados.filter(item => {
          //     return item.guia.toLowerCase().includes(valor_guia) == true
          //   }); 
          // }else if(valor_guia.length > 3)
          // {            
            this.datos_guias_hr = this.datos_guias_hr_guardados.filter(item => {
              if(item.razsoc.toLowerCase().includes(valor_guia.toLowerCase()) == true){
                  return item.razsoc.toLowerCase().includes(valor_guia.toLowerCase()) == true
              }else if(item.guia.toLowerCase().includes(valor_guia) == true)
              {
                return item.guia.toLowerCase().includes(valor_guia) == true
              }
              
            });
          // }
          
           
          // if(!this.datos_guias_hr.length && valor_guia.length > 3)
          // {
          //   this.datos_guias_hr = this.datos_guias_hr_guardados.filter(item => {
          //     return item.razsoc.toString().includes(valor_guia.toLowerCase()) == true
          //   });
          // }
        }else
        {
          this.datos_guias_hr = this.datos_guias_hr_guardados
        }
      },   
      zeroPad(num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
      },
      toggle () {
      this.size = !this.size
      },
      /* ------ CÓDIGO QUE SE USABA ANTERIORMENTE ------- */
      /* ----------------- CÓDIGO QUE ACTUALMENTE NO SE USA -------------------- */
      // Busco por terminal y número
      // ProcesarGuia(formName) {
      //   this.loadb_guia   = true
      //   this.limpiar_datos()        
      //   this.form         = {}
      //   this.global       = ''
      //   this.deshabilitar_guia  = true
      //   this.desahbilitar_suc   = true

      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {   
      //       setTimeout(() => {
      //         HTTP.post('/verificarHR/getDestinos', 
      //         {origen: 'm',
      //         terminal:this.guia.terminal,
      //         numero:this.guia.numero,
      //         })
      //           .then((res) => {
      //             if(res.data.destinos != false && res.data.destinos != null)
      //             {
      //               this.buscado_destino = true
      //               this.datos_HR       = res.data.destinos
      //               this.datos_HR_guardado = res.data.destinos
      //               this.cant_destinos  = res.data.count_destinos
      //               this.hr             = res.data.hojaruta != null ? res.data.hojaruta.toString() :false
      //               this.suc            = res.data.hrsuc != null ? res.data.hrsuc.toString() :false 
      //               this.loadb_guia     = false
      //               this.showTabla_2    = res.data.count_destinos ? true : false
      //               this.showGuias(res.data.destinos[0])
      //             }else{
      //               this.loadb_guia         = false 
      //               this.buscado_destino    = true
      //               this.datos_HR           = []
      //               this.datos_HR_guardado  = []
      //               this.cant_destinos      = 0
      //               this.showTabla_2        = false
      //               this.hr                 = false
      //               this.suc                = false 
      //               this.msj_destino        = 'La guía ingresada no existe, verifique los datos ingresados.'
      //             }
                
      //           })
      //           .catch(error =>{ 
      //             console.log(error)
      //             this.showTabla_2      = false
      //             this.loadb_guia       = false
      //             this.buscado_destino  = false
      //           });
      //       }, 1000);                                 
            
          
      //     } else {
      //       this.loadb_guia = false
      //       return false;
      //     }
      //   });
      // },      
      // ProcesarHR(formName) {
        
      //   this.limpiar_datos()
      //   this.loadb_hr  = true
      //   this.guia = {}
      //   this.global       = 'global'
      //   // this.rules_hr = {
      //   //   hrsuc: [{ required: true, message: 'Obligatorio', trigger: 'blur' },],
      //   //       hojaruta: [{ required: true, message: 'Obligatorio', trigger: 'blur' },
      //   //           { type: 'number', message: 'Sólo puede ser numérico',  trigger: ['blur','focus']  }]   
      //   // }
      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //         setTimeout(() => {                      
      //         HTTP.post('/verificarHR/getDestinos', 
      //         {origen: 'hr',
      //         hojaruta:this.form.hojaruta,
      //         hrsuc:this.form.hrsuc,
      //         })
      //           .then(async (res) => {
      //             if(res.data.destinos != false && res.data.destinos != null)
      //             {
      //               if(typeof res.data.destinos.fecha == 'undefined' )
      //               {

      //                 this.buscado_destino = true
      //                 this.datos_HR       = res.data.destinos
      //                 this.datos_HR_guardado = res.data.destinos
      //                 this.cant_destinos  = res.data.count_destinos
      //                 this.hr             = res.data.hojaruta != null ? res.data.hojaruta.toString() :false
      //                 this.suc            = res.data.hrsuc != null ? res.data.hrsuc.toString() :false 
      //                 this.loadb_hr       = false
      //                 this.showTabla_2    = res.data.count_destinos ? true : false  
      //               }else if(typeof res.data.destinos.fecha != 'undefined' )
      //               {
      //                 this.buscado_destino    = true
      //                 this.datos_HR           = []
      //                 this.datos_HR_guardado  = []
      //                 this.cant_destinos      = 0
      //                 this.hr                 = res.data.hojaruta != null ? res.data.hojaruta.toString()  :false
      //                 this.suc                = res.data.hrsuc != null    ? res.data.hrsuc.toString()     :false 
      //                 let final               = this.$moment().format('DD-MM-YYYY HH:mm')
      //                 let comienzo            = this.$moment(res.data.destinos.fecha.date).format('DD-MM-YYYY HH:mm')
      //                 let totalMinutos        = this.$moment(final).diff(comienzo) / (1000 * 60)
      //                 if(totalMinutos > 30)
      //                 {
      //                   this.msj_destino = 'Hubo una falla al momento de sincronizar la Hoja de ruta, vamos a verificar el problema.'
      //                 }else
      //                 {
      //                   this.msj_destino = 'La hoja de ruta aún no fue sincronizada, reintente nuevamente en 10 minutos.'
      //                 }
      //                 this.loadb_hr       = false 
      //               }                   
      //             }else{
      //               this.loadb_hr           = false 
      //               this.buscado_destino    = true
      //               this.datos_HR           = []
      //               this.datos_HR_guardado  = []
      //               this.cant_destinos      = 0
      //               this.msj_destino        = 'La hoja de ruta ingresada no existe, verifique los datos ingresados.'
      //             }
      //           })
      //           .catch(error => {
      //             console.log(error)
      //             this.buscado_destino = false
      //             this.loadb_hr = false});
      //       }, 1000);    
      //     } else {
      //       this.loadb_hr         = false
      //       this.buscado_destino = false
      //       return false;
      //     }
      //   });
      //   // }
      // }, 
      /* ----------------- FIN CÓDIGO QUE NO SE USA ACTUALMENTE */ 
    },
    filters: {
      // Pipe para pransforma 1 en true y 0 en false.
      conversion: function(date) {
        return date ? true : false;
      },

         
    },
    
}
</script>
<style scoped>
.el-dialog__body {
    padding: 5px 10px  !important;
 
}
.titulo_class{
    padding: 5px;
    background-color: #01BCD5;
    color: white;
}
.hr_class{
    padding: 5px;
    background-color: #E91F63;
    color: white;
}
.suc_class{
    padding: 5px;
    background-color: #F44236;
    color: white;
}
#global {
	height: 300px;
	border: 1px solid #ddd;
	background: #f1f1f1;
	overflow-y: scroll;
}
#mensajes {
	height: auto;
}
.p_datos
{
    text-align: center;
    color: steelblue;
    background-color: aliceblue;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
kbd {
    display: inline-block;
    padding: 3px 5px;
    font: 11px SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
    line-height: 10px;
    color: #444d56;
    vertical-align: middle;
    background-color: #fcfcfc;
    border: 1px solid #c6cbd1;
    border-bottom-color: #959da5;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #959da5;
}
table.table-remitos tr td {
  padding: 5px !important;
  color: #333;
  font-size: 12px;
}
table.table-remitos > thead > tr > td {
  text-align: left;
  background: rgb(1, 74, 130) !important;
  color: white;
  position: sticky;
  top: -1px;  
}
.pendientes_style{
  background-color: #FF9B93;
  color: white;
  margin-top: 30px;
}
</style>