<template>
  <div>
    <el-row
      style="border-top: 1px solid #ddd; margin-top: 10px; text-align: center">
      <el-col :md="24">
        <p class="font-title" :style="styleTitleTabla">
          Total de Datos:{{ totalRows }}
        </p>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-table
          fit
          size="mini"
          :data="tableData"
          border
          style="width: 100%"
          height="450"
          v-loading="loading"
          empty-text="Realice una busqueda para ver la información">
          <el-table-column prop="id_geo" label="ID" width="50px">
          </el-table-column>
          <el-table-column prop="uuid" label="UUID" width="110">
            <template slot-scope="scope">
              <span v-if="scope.row.uuid">{{ scope.row.uuid }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="nombre" label="Nombre" width="140px;">
            <template slot-scope="scope">
              <span v-if="scope.row.nombre">{{ scope.row.nombre }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="sucursal_nombre"
            label="Sucursal"
            width="110px;">
            <template slot-scope="scope">
              <span v-if="scope.row.sucursal_nombre">{{
                scope.row.sucursal_nombre
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="dni" label="DNI" width="80px;">
            <template slot-scope="scope">
              <span v-if="scope.row.dni">{{ scope.row.dni }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column prop="appversion" label="App Vers." width="70">
            <template slot-scope="scope">
              <span v-if="scope.row.appversion">{{
                scope.row.appversion
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column label="Ult. Escaneo">
            <template slot-scope="scope">
              <span v-if="scope.row.ultima_fecha">
                {{ scope.row.ultima_fecha.date | moment }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="Dispositivo">
            <template slot-scope="scope">
              <span v-if="scope.row.dispositivo">
                {{ scope.row.dispositivo }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="codigo_chofer" label="Cod. Suizo" width="70">
            <template slot-scope="scope">
              <span v-if="scope.row.codigo_chofer">{{
                scope.row.codigo_chofer
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="codigo_chofer_log"
            label="Cod. Login"
            width="70">
            <template slot-scope="scope">
              <span v-if="scope.row.codigo_chofer_log">{{
                scope.row.codigo_chofer_log
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="email" label="Email" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.email">{{ scope.row.email }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="" align="center" width="120">
            <!-- ========================= BOTON PARA VER LOS DETALLES DEL USUARIO ============================  -->
            <template slot-scope="scope">
              <el-button
                @click="getDetalles(scope.row)"
                type="success"
                v-if="scope.row.habilitado"
                icon="el-icon-view"
                size="mini">
                Detalle
              </el-button>

              <el-button
                @click="getDetalles(scope.row)"
                type="danger"
                v-if="!scope.row.habilitado"
                icon="el-icon-view"
                size="mini">
                Detalle
              </el-button>
            </template>
            <!-- ===================== END BOTON PARA VER LOS DETALLES DEL USUARIO ============================  -->
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length"
          :page-size="10"
          layout="prev, pager, next"
          :total="totalRows"
          @current-change="siguiente"
          :current-page.sync="pagina_actual"
          style="margin-top: 20px; float: right; padding-bottom: 1rem">
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'
const moment = require('moment')

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      totalRows: 0,
      itemSelected: undefined,
      loadingModal: false,
      pagina: 1,
      estado: undefined,
      styleTitleTabla: {},
      empresa: window.localStorage.getItem('empresa'),
      pagina_actual: '',
    }
  },
  created() {
    this.styleTitleTabla['backgroundColor'] =
      this.empresa == 'u' ? '#0b406a' : '#36404e'
  },
  mounted() {
    EventBus.$on('cargando', () => {
      this.loading = true
    })
    EventBus.$on('limpiarTabla', (valor) => {
      if (valor == true) {
        this.tableData = []
      }
    })
    EventBus.$on('cargarUsuarios', (g) => {
      // console.log(g)
      if (this.$refs.tableViewBox && this.$refs.tableViewBox.bodyWrapper) {
        this.$refs.tableViewBox.bodyWrapper.scrollTop = 0
      }
      window.scrollTo(0, 0)
      if (g.tabla == 0) {
        this.tableData = []
        this.loading = false
        this.totalRows = 0
        this.pagina = 1
        this.pagina_actual = 1
      } else {
        this.tableData = g.tabla
        this.loading = false
        this.totalRows = g.tabla[0].TotalRows
        this.pagina = g.pagina
        this.pagina_actual = g.pagina
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('limpiarTabla')
    EventBus.$off('cargarUsuarios')
    EventBus.$off('cargando')
  },
  methods: {
    /* Renombrar evento */
    siguiente(page) {
      EventBus.$emit('nuevaPaginaAdmin', page)
      this.loading = true
      this.pagina = page
      this.pagina_actual = page
      return false
    },
    getDetalles(row) {
      // this.loadingModal = true;
      this.rb_color = row.habilitado ? 'green' : 'red'
      setTimeout(() => {
        // console.log('row detalle',row)
        EventBus.$emit('detalleRow', row)
        this.estado = row.habilitado
      }, 10)
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD-MM-YYYY H:mm:ss')
    },
    fecha: function (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    desde: function (date) {
      return moment(date).fromNow()
    },
    conversion: function (date) {
      return date ? true : false
    },
  },
}
</script>
