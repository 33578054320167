<template>
  <div style="padding: 10px">
    <el-tabs type="border-card" @tab-click="handleClick" v-model="activeName">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-button type="success" style="float: right; margin-bottom: 5px" @click="descargarStock" plain size="mini" icon="el-icon-download" :loading="loadBtn">
            Descargar Excel Stock
          </el-button>
        </el-col>
      </el-row>
      <!-- ---------------------------BUSCAR POR ARTICULO------------------------------------ -->
      <el-tab-pane label="BUSCAR POR ARTÍCULO" name="first">
        <stock-log></stock-log>
      </el-tab-pane>
      <!-- --------------------------- FIN BUSCAR POR ARTICULO------------------------------------ -->

      <!-- ---------------------------BUSCAR POR LOTE------------------------------------ -->
      <el-tab-pane label="BUSCAR POR LOTE" name="config">
        <stock-cod></stock-cod>
      </el-tab-pane>
      <tabla-stock></tabla-stock>
      <!-- --------------------------- FIN BUSCAR POR LOTE------------------------------------ -->
    </el-tabs>
    <!-- Modal descargar -->
    <el-dialog :visible.sync="dialogStock" width="50%" :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="false" :show-close="false" :top="'2vh'">
      <el-row style="border-bottom: 1px dashed #ddd; margin-top: -25px; margin-bottom: 10px" :gutter="10">
        <el-col :md="14">
          <span style="float: left; font-size: 20px; text-aling: center"
            ><strong> <i class="el-icon-download"></i> Descargar Stock </strong></span
          >
        </el-col>
      </el-row>
      <p style="word-break: break-word">
        La descarga de resultados está limitada a 10.000 registros, su actual consulta arrojó un total de
        <strong style="color: #f76868">{{ numberTitle }}</strong> resultados.
      </p>
      <el-row style="padding: 5px !important">
        <center>
          <el-button type="success" icon="el-icon-download" @click="downloadSame" size="small" :loading="aceptar_load">Descargar 10.000 registros</el-button>
          <el-button @click="cancelarDownload" icon="el-icon-close" size="small" :disabled="blockCancelar">Cancelar</el-button>
        </center>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import StockLog from '@/components/stocks/login/stockLogBuscar.vue'
import TablaStock from '@/components/stocks/login/TablaStockComun.vue'
import StockCod from '@/components/stocks/login/stockCod.vue'
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'
import { EventBus } from '@/event-bus'

export default {
  components: {
    StockLog,
    TablaStock,
    StockCod,
  },
  data() {
    return {
      activeName: 'first',
      datos: JSON.parse(window.localStorage.getItem('datos')),
      convenio: '',
      loadBtn: false,
      numberTitle: 0,
      dialogStock: false,
      aceptar_load: false,
      blockCancelar: false,
    }
  },
  methods: {
    handleClick() {
      EventBus.$emit('limpiarStock')
    },
    descargarStock() {
      let permiso_excel = this.datos.permisos.includes('STOCK_EXCEL_UM') ? true : false
      if (this.datos['convenio']) {
        this.convenio = this.datos['convenio']
      } else if (this.datos['ol_convenio']) {
        this.convenio = this.datos['ol_convenio']
      }
      this.loadBtn = true
      let urlShow = permiso_excel ? '/vlogisticaLogin/stockConsultaUM' : '/vlogisticaLogin/stockConsulta'
      HTTP.post(urlShow, {
        // HTTP_DESARROLLO.post(urlShow, {
        convenio: this.convenio,
        session: this.datos,
      })
        .then((res) => {
          this.loadBtn = false
          // console.log(res.data)
          if (Number.isInteger(res.data)) {
            this.dialogStock = true
            this.numberTitle = res.data
          } else if (res.data != '') {
            window.location.href = res.data
          } else {
            this.$message({
              message: 'No hay datos para descargar.',
              type: 'warning',
            })
          }
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
          this.loadBtn = false
        })
    },
    downloadSame() {
      this.aceptar_load = true
      this.blockCancelar = true
      let permiso_excel = this.datos.permisos.includes('STOCK_EXCEL_UM') ? true : false
      let urlShow = permiso_excel ? '/vlogisticaLogin/stockConsultaUM' : '/vlogisticaLogin/stockConsulta'

      HTTP.post(urlShow, {
        convenio: this.convenio,
        session: this.datos,
        ask_descargar: 1,
      })
        .then((res) => {
          window.location.href = res.data
          setTimeout(() => {
            ;(this.aceptar_load = false), (this.blockCancelar = false)
            this.dialogStock = false
          }, 200)
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
        })
    },
    cancelarDownload() {
      this.dialogStock = false
    },
  },
}
</script>
