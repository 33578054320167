<template>
    <div>
 <el-card shadow="never" :body-style="{ padding: '10px' }">
                 <div slot="header">
                     <span>{{texto}} <strong style="color:red" >{{indices.length}}</strong></span>
                    <el-input
          v-model="search"
          size="mini"
          placeholder="buscar indice"
          @keypress.native.enter="buscarIndice"
          >
             <el-button slot="append"  icon="el-icon-search" @click="buscarIndice"></el-button> 
             <el-button slot="append" icon="el-icon-close" @click="search = ''; buscarIndice()"></el-button>
             <!-- <el-button slot="append" icon="el-icon-refresh" @click="getIndices"></el-button> -->
                    </el-input>
                      
                 </div>
                  <el-table :data="indices" border stripe size="mini" fit height="500px" v-loading="loading">
                        <el-table-column
      type="index"
      width="50">
    </el-table-column>
                      <el-table-column prop="indice" label="índice">
                         
                      </el-table-column>
                        <!-- <el-table-column label=""   width="50">
                          <template slot-scope="scope">
                              <el-button type="danger" :loading="subiendo == scope.row.indice" :disabled="disableBtnSubir || (subiendo && subiendo != scope.row.indice)" size="mini" @click="procesarByIndice(scope.row.indice)" icon="el-icon-upload"></el-button>
                              
                  
                             
                          </template>
                      </el-table-column> -->
                  </el-table>
                  
               
 </el-card>
    </div>
</template>

<script>
import {HTTP} from "@/axios"
import {EventBus} from "@/event-bus"

    export default {
        props:['indice'],
        data() {
            return {
                subiendo:undefined,
                loading:true,
                disableBtnSubir:false,
                indices: [],
                search:'',
                texto:'',
                carpeta:'/'
            }
        },
        created() {

            switch (parseInt(this.indice)) {
                case 1:
                    this.texto = "Índices sin procesar"
                    this.carpeta = '/'
                    break;
                      case 2:
                    this.texto = "Índices procesados"
                     this.carpeta = '/_procesados_/'
                     
                    break;
                     case 3:
                    this.texto = "Índices Incompletos"
                     this.carpeta = '/_incompletos_/'
                    break;
                       case 4:
                    this.texto = "Índices con ERRORES"
                     this.carpeta = '/_errores_/'
                    break;

            
                
            }
            this.getIndices()
              EventBus.$on('disableBtnSubir', (e) => {
                  this.disableBtnSubir = e
                  if(!e)
                  {
                      this.getIndices()
                  }
              })
        },
        methods: {
            getIndices() {
                this.loading = true
                 this.indices = []
                 window.sessionStorage.removeItem('indices')
                HTTP.get('/robotescaner/getIndices?carpeta=' + this.carpeta)
                    .then(res => {
                        if(res.data && res.data.length > 0)
                        {
                            res.data.forEach((e,i) => {
                                                        let elemento = {
                                                            id:i,
                                                            indice:e
                                                        }
                                                        this.indices.push(elemento)
                                                    });

                            setTimeout(() => {
                                window.sessionStorage.setItem('indices', JSON.stringify(this.indices))
                                this.loading = false
                            }, 150);
                        }else{
                            this.loading = false
                            this.indices = []
                        }
                       
                    })
                    .catch((e) => {
                        this.$alert('No se pudo recuperar los índices, refresque la pagina y reintente ' + e)
                    })
            },
            buscarIndice()
            {
                let aux = JSON.parse(window.sessionStorage.getItem('indices'))
                
                this.indices = aux.filter(data => !this.search || data.indice.toLowerCase().includes(this.search.toLowerCase()))
            },
            procesarByIndice(indice)
            {
            
                this.subiendo = indice
                HTTP.get('/robotescaner/forzarSincroByIndex?index=' + indice)
                    .then(res => {
                      

                        this.subiendo = undefined
                       this.indices = this.indices.filter(i => i.indice !== indice )
                        if(res.data.msj)
                        {
                            this.$alert(res.data.msj)
                        }
                        console.log('this.indicesauxI ',this.indices );
                      
                         
                    })
                    .catch(() => {
                         this.subiendo = undefined
                    })
            },
           
        },
    }
</script>

<style lang="scss" scoped>

</style>