<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="80%" :top="'0vh'">
      <el-row class="css_codigo" style="margin-top: -20px">
        <el-col :span="24">
          <h2
            style="
              text-align: center;
              margin-top: 5px;
              margin-bottom: 0px;
              font-size: 16px;
              margin-bottom: 10px;
            ">
            <u>{{ titleDialog }}</u>
          </h2>
          <h5 style="margin: 5px" v-if="!no_genericos">
            Genérico : <span>{{ codgenerico }}</span> | {{ descgenerico }}
          </h5>
        </el-col>
      </el-row>
      <div v-loading="fullscreenLoading">
        <el-row style="background-color: #c0c4cc">
          <h3 style="text-align: center !important; margin: 5px">
            Resumen de Stock
          </h3>
        </el-row>
        <el-row v-if="tablaResumen.length > 0">
          <el-table
            :data="tablaResumen"
            border
            fit
            style="width: 100%"
            v-loading="cargarTabla"
            :height="altRes"
            size="mini">
            <el-table-column property="deposito" label="Dep."></el-table-column>
            <el-table-column property="fchulting" label="Últ.Ingreso">
              <template slot-scope="scope">
                <span v-if="scope.row.fchulting">
                  {{ formatFecha(scope.row.fchulting.date) }}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column property="stock" label="Stock"></el-table-column>
            <el-table-column property="ventas" label="Ventas"></el-table-column>
            <el-table-column
              property="reserva"
              label="Reserva"></el-table-column>
            <el-table-column property="disponible" label="Disponible">
              <template slot-scope="scope">
                <span v-if="scope.row.disponible > 0" style="color: green">
                  {{ scope.row.disponible }}
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Agregar al carro"
                    placement="right-start">
                    <!-- <el-button type="success" icon="el-icon-plus" circle size="mini" disabled></el-button>  -->
                  </el-tooltip>
                </span>
                <span v-else style="color: red"
                  >{{ scope.row.disponible }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row v-else style="background-color: #f2f6fc; text-align: center">
          <p style="text-align: center">No hay datos de Resumen</p>
        </el-row>
        <br />
        <!-- fin Tabla -->
        <!-- Tabla Lotes -->
        <el-row style="background-color: #c0c4cc">
          <h3 style="text-align: center; margin: 5px">Lotes</h3>
        </el-row>
        <el-row v-if="tablaLotes.length > 0">
          <el-table
            :data="tablaLotes"
            border
            v-loading="cargarTabla"
            :height="altTabla"
            size="mini">
            <el-table-column
              prop="cantidad"
              label="#"
              width="170"
              v-if="permisos_user">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.cantidad"
                  style="width: 75% !important"
                  :min="0"
                  :max="scope.row.disponible"
                  size="mini"
                  :disabled="scope.row.disponible <= 0"
                  @change="sumar(scope.row)">
                </el-input-number>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Agregar al carro"
                  placement="right-start"
                  v-if="scope.row.disponible">
                  <el-button
                    type="success"
                    icon="el-icon-check"
                    circle
                    size="mini"
                    :disabled="scope.row.cantidad <= 0"
                    style="margin-left: 6px; font-size: 12px">
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- @click="agregarCarrito(scope.row)" -->
            <el-table-column property="lote" label="Lote">
              <template slot-scope="scope">
                <span v-if="scope.row.lote"> {{ scope.row.lote }} </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column property="lote2" label="Lote 2">
              <template slot-scope="scope">
                <span v-if="scope.row.lote2"> {{ scope.row.lote2 }} </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              property="estadotxt"
              label="Estado"></el-table-column>
            <el-table-column property="vencimiento" label="Vencimiento">
              <template slot-scope="scope">
                <span v-if="scope.row.vencimiento">
                  {{ formatFecha(scope.row.vencimiento.date) }}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              property="deposito"
              label="Deposito"></el-table-column>
            <el-table-column property="stock" label="Stock">
              <template slot-scope="scope">
                <span v-if="scope.row.stock > 0"> {{ scope.row.stock }} </span>
                <span v-else style="color: red">{{ scope.row.stock }}</span>
              </template>
            </el-table-column>
            <el-table-column
              property="ventas"
              label="Ventas"
              width="70"></el-table-column>
            <el-table-column
              property="disponible"
              label="Disponible"
              width="70">
              <template slot-scope="scope">
                <span v-if="scope.row.disponible > 0" style="color: green">
                  {{ scope.row.disponible }}
                </span>
                <span v-else style="color: red"
                  >{{ scope.row.disponible }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row v-else style="background-color: #f2f6fc; text-align: center">
          <p style="text-align: center">No hay datos de Lotes</p>
        </el-row>
        <br />
        <!-- fin Tabla -->
        <!-- Tabla Ubicaciones -->
        <el-row style="background-color: #c0c4cc">
          <h3 style="text-align: center; margin: 5px">Ubicaciones</h3>
        </el-row>
        <el-row v-if="tablaDetalle.length > 0">
          <el-table
            :data="tablaDetalle"
            border
            v-loading="cargarTabla"
            :height="altDet"
            size="mini">
            <el-table-column property="rack" label="Rack"></el-table-column>
            <el-table-column property="piso" label="Piso"></el-table-column>
            <el-table-column
              property="posicion"
              label="Posicion"></el-table-column>

            <el-table-column property="tipo" label="Tipo">
              <template slot-scope="scope">
                <span v-if="scope.row.tipo"> {{ config[scope.row.tipo] }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              property="deposito"
              label="Depósito"></el-table-column>
            <el-table-column
              property="etiqueta"
              label="Etiqueta"></el-table-column>
            <el-table-column property="stock" label="Stock"></el-table-column>
            <!-- <el-table-column property="name"  width="50"><img src="@/assets/ico_preview.png" alt=""></el-table-column> -->
          </el-table>
        </el-row>
        <el-row v-else style="background-color: #f2f6fc; text-align: center">
          <p style="text-align: center">No hay datos de Ubicaciones</p>
        </el-row>
        <!-- fin Tabla -->

        <!-- Tabla UM -->
        <br />
        <el-row style="background-color: #c0c4cc">
          <h3 style="text-align: center; margin: 5px">Unidad Manipulación</h3>
        </el-row>
        <el-row v-if="tablaUM.length > 0">
          <el-table
            :data="tablaUM"
            border
            v-loading="cargarTabla"
            size="mini"
            :height="altUM">
            <el-table-column property="idum" label="UM"></el-table-column>
            <el-table-column property="sscc" label="SSCC"></el-table-column>
            <el-table-column
              property="deposito"
              label="Depósito"></el-table-column>
            <el-table-column
              property="etiqueta"
              label="Ubicación-Etiqueta"></el-table-column>
            <el-table-column property="stock" label="Stock"></el-table-column>
          </el-table>
        </el-row>
        <el-row v-else style="background-color: #f2f6fc; text-align: center">
          <p style="text-align: center">No hay datos de UM</p>
        </el-row>
        <!-- fin Tabla -->

        <!-- Tabla Información -->
        <br />
        <el-row style="background-color: #c0c4cc">
          <h3 style="text-align: center; margin: 5px">
            <i class="el-icon-info"></i> Información extendida
          </h3>
        </el-row>
        <el-row
          v-if="infoExtra.length > 0"
          style="overflow: auto; margin-bottom: -15px">
          <el-table
            :data="infoExtra"
            border
            v-loading="cargarTabla"
            size="mini"
            :height="altInfo">
            <el-table-column property="codbarra" label="Códigos de Barra">
              <template slot-scope="scope">
                <div>
                  <i class="el-icon-success"></i> {{ scope.row.codbarra }}
                </div>
              </template>
            </el-table-column>
            <el-table-column property="rubro" label="Rubro" width="70">
            </el-table-column>
            <el-table-column property="descrip_rubro" label="Descripción Rubro">
            </el-table-column>
            <el-table-column property="subrubro" label="Subrubro">
            </el-table-column>
            <el-table-column property="tipo" label="Tipo"> </el-table-column>
          </el-table>
        </el-row>
        <el-row
          v-else
          style="
            background-color: #f2f6fc;
            text-align: center;
            margin-bottom: 0px;
          ">
          <p style="text-align: center">
            No hay datos de Información extendida
          </p>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <center>
          <el-button @click="dialogVisible = false" type="danger"
            >Cerrar</el-button
          >
        </center>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
import { EventBus } from '@/event-bus'

export default {
  props: ['datos_stock', 'permisos_user'],
  components: {},
  data() {
    return {
      titleDialog: '',
      tablaResumen: [],
      tablaLotes: [],
      tablaDetalle: [],
      tablaUM: [],
      infoExtra: [],
      cargarTabla: false,
      dialogVisible: false,
      fullscreenLoading: false,
      altTabla: 0,
      altRes: 0,
      altDet: 0,
      altUM: 0,
      altInfo: 0,
      config: [],
      session: JSON.parse(window.localStorage.getItem('datos')),
      tabla_carrito_lote: [],
      carrito_previo_lote: [],
      codprov_: '',
      codigo_: '',
      codgenerico: '',
      descgenerico: '',
      no_genericos: false,
    }
  },
  mounted() {
    /* NUEVO PERMISO NO GENERICOS. NO MOSTRAR GENERICOS PARA QUIENES TIENEN ESTE PERMISO */
    this.no_genericos = this.session.permisos.includes('NO_GENERICOS')
      ? true
      : false
    // ------------------------------------------------------------------------------------
    EventBus.$on('dialogStock_2', (g) => {
      setTimeout(() => {
        this.modalCompleto(g)
      }, 1000)
    })
    EventBus.$on('carrito_cargado_tabla', (g) => {
      this.tabla_carrito_lote = g.tabla
      this.carrito_previo_lote = g.tabla
    })
  },
  beforeDestroy() {
    EventBus.$off('dialogStock_2', 0)
    EventBus.$off('carrito_cargado_tabla', 0)
  },
  methods: {
    formatFecha(fecha) {
      return this.$moment(fecha).format('DD/MM/YYYY')
    },
    modalCompleto(g) {
      this.dialogVisible = g.abrirModal
      this.titleDialog = g.datos.descrip
      this.visible = true
      this.fullscreenLoading = true
      this.codprov_ = g.datos.codprov
      this.codgenerico = this.datos_stock.codgenerico
      this.descgenerico = this.datos_stock.descgenerico
      this.cargarTabla = true

      HTTP.post('/vlogisticaLogin/stock_detalleArticulo', {
        codigo: g.datos.codigo,
        session: g.session,
      })
        // HTTP.post('/vlogisticanew/stock_detalleArticulo_prueba',{codigo:row.codigo,session:this.session})
        .then((res) => {
          // this.tablaLotes = res.data.detalleL ? res.data.detalleL : [] //DESCOMENTAR
          this.tablaLotes = res.data.detalleLote ? res.data.detalleLote : []
          this.tablaResumen = res.data.stockA ? res.data.stockA : []
          this.tablaDetalle = res.data.detalle ? res.data.detalle : []
          this.tablaUM = res.data.detalleUM ? res.data.detalleUM : []
          this.infoExtra = res.data.cobarras ? res.data.cobarras : []
          this.cargarTabla = false
          setTimeout(() => {
            this.altTabla = this.tablaLotes.length > 4 ? '16vh' : ''
            this.altRes = this.tablaResumen.length > 4 ? '16vh' : ''
            this.altDet = this.tablaDetalle.length > 4 ? '16vh' : ''
            this.altUM = this.tablaUM.length > 4 ? '16vh' : ''
            this.altInfo = this.infoExtra.length > 4 ? '16vh' : ''
          }, 100)
          setTimeout(() => {
            this.fullscreenLoading = false
          }, 500)
          this.config = res.data.config ? res.data.config : []
        })
        .catch((error) => {
          this.fullscreenLoading = false
          console.log(error)
          this.$alert('Error del servidor ' + error)
        })
    },
    comprarCarro(datos) {
      EventBus.$emit('dialogCarritoLote', {
        abrirModal: true,
        codigo: datos.codigo,
        carrito_completo: this.tabla_carrito_lote,
        producto: this.titleDialog,
      })
    },
    agregarCarrito(value) {
      value.estado_2 = 'btn_agregar'
      EventBus.$emit('cargarLote', value)
    },
    sumar(row) {
      /* Consultar si existe en el carro de compras */
      // let buscar_elemento = this.carrito_previo_lote.find(
      //   (elemento) =>
      //     row.codigo == elemento.articulo &&
      //     row.codprov == elemento.articulo_cli &&
      //     row.lote == elemento.lote
      // )

      let total = this.tablaLotes.reduce(function (a, b) {
        return b.cantidad < 0 ? a : a + b.cantidad
      }, 0)

      EventBus.$emit('cantidad_lote_art', {
        total: total,
        codprov: this.codprov_,
        codigo: this.codigo_,
      })
      row.codgenerico = this.codgenerico // agrego codgenerico
      if (typeof buscar_elemento != 'undefined') {
        row.estado_2 = 'actualizando'
        if (row.estado_2 != '') {
          this.agregarCarrito(row)
        } else {
          alert('No se hace')
        }
      } else {
        this.agregarCarrito(row)
      }
    },
  },
}
</script>
<style>
.css_codigo {
  /* background-color: #EBB563 !important; */
  margin-bottom: 5px;
  /* color: white; */
  text-align: center;
  font-size: 15px;
}
</style>
