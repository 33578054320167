<template>
  <div style="padding: 10px" v-if="user_pedidos && user_stock">
    <el-tabs type="border-card" @tab-click="handleClick" v-model="activeName">
      <el-tab-pane label="BUSCAR ARTÍCULOS" name="first" v-if="user_pedidos">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-button type="success" style="float: right; margin-bottom: 5px" @click="descargarStock" plain size="mini" icon="el-icon-download" :loading="loadBtn">
              Descargar Excel Stock
            </el-button>
          </el-col>
        </el-row>
        <stock-log-pedido></stock-log-pedido>
        <tabla-stock :permiso_user="user_pedidos"></tabla-stock>
      </el-tab-pane>
      <!-- Tabla con los pedidos sin realizar -->
      <el-tab-pane name="CARROS" v-if="user_pedidos">
        <span slot="label"><i :class="icon_carro"></i> CARRO DE COMPRAS</span>
        <CarritoLog></CarritoLog>
      </el-tab-pane>
      <!-- Tabla con los pedidos realizados -->
      <el-tab-pane name="PEDIDOS" v-if="user_pedidos">
        <span slot="label"><i class="el-icon-s-order"></i> PEDIDOS</span>
        <PedidosLog></PedidosLog>
      </el-tab-pane>
    </el-tabs>
    <!-- Modal descargar -->
    <el-dialog :visible.sync="dialogStock" width="50%" :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="false" :show-close="false" :top="'2vh'">
      <el-row style="border-bottom: 1px dashed #ddd; margin-top: -25px; margin-bottom: 10px" :gutter="10">
        <el-col :md="14">
          <span style="float: left; font-size: 20px; text-aling: center"
            ><strong> <i class="el-icon-download"></i> Descargar Stock 12 </strong></span
          >
        </el-col>
      </el-row>
      <p style="word-break: break-word">
        La descarga de resultados está limitada a 10.000 registros, su actual consulta arrojó un total de
        <strong style="color: #f76868">{{ numberTitle }}</strong> resultados.
      </p>
      <el-row style="padding: 5px !important">
        <center>
          <el-button type="success" icon="el-icon-download" @click="downloadSame" size="small" :loading="aceptar_load">Descargar 10.000 registros</el-button>
          <el-button @click="cancelarDownload" icon="el-icon-close" size="small" :disabled="blockCancelar">Cancelar</el-button>
        </center>
      </el-row>
    </el-dialog>
  </div>
  <div v-else>
    <stock-log></stock-log>
  </div>
</template>
<script>
import StockLogPedido from '@/components/stocks/login/stockLog.vue'
import StockLog from '@/components/stocks/login/stockLogComun.vue'
import TablaStock from '@/components/stocks/login/TablaStock.vue'
import CarritoLog from '@/components/stocks/login/carritoLog.vue'
import PedidosLog from '@/components/stocks/login/pedidosLog.vue'

import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'
import { EventBus } from '@/event-bus'

export default {
  components: {
    StockLog,
    StockLogPedido,
    TablaStock,
    CarritoLog,
    PedidosLog,
  },
  data() {
    return {
      activeName: 'first',
      datos: JSON.parse(window.localStorage.getItem('datos')),
      convenio: '',
      loadBtn: false,
      numberTitle: 0,
      dialogStock: false,
      aceptar_load: false,
      blockCancelar: false,
      cantidad_carrito: 0,
      nombre_carro: 'CARRO DE COMPRAS ',
      permisos_usuario: JSON.parse(window.localStorage.getItem('datos')).permisos,
      user_stock: false,
      user_pedidos: false,
      icon_carro: 'el-icon-shopping-cart-2',
      no_genericos: false,
    }
  },
  mounted() {
    /* Si el usuario solo tiene permiso STOCK, puede consultar productos */
    // Si el usuario tiene permiso PEDIDOS, puede realizar compras
    this.no_genericos = this.permisos_usuario.indexOf('NO_GENERICOS') != -1 ? true : false
    this.user_stock = this.permisos_usuario.indexOf('STOCK') != -1 ? true : false
    this.user_pedidos = (this.permisos_usuario.indexOf('PEDIDOS') != -1 || this.permisos_usuario.indexOf('PEDIDOS_TODOS') != -1) && this.user_stock ? true : false
    if (this.user_pedidos) {
      EventBus.$on('cantidad_total_carrito', (cantidad_elementos) => {
        this.cantidad_carrito = cantidad_elementos
        this.nombre_carro = ''
        this.nombre_carro = 'CARRO DE COMPRAS'
        EventBus.$emit('cantidad_total_stock', this.cantidad_carrito)
        this.icon_carro = this.cantidad_carrito ? 'el-icon-shopping-cart-full' : 'el-icon-shopping-cart-2'
      })
    } else {
      EventBus.$emit('cantidad_total_stock', 0)
    }
  },
  beforeDestroy() {
    EventBus.$off('cantidad_total_carrito', false)
  },
  methods: {
    handleClick(tab) {
      if (tab.name == 'PEDIDOS' && this.user_pedidos) {
        EventBus.$emit('actualizar_pedidos', true)
      }
    },
    descargarStock() {
      if (this.datos['convenio']) {
        this.convenio = this.datos['convenio']
      } else if (this.datos['ol_convenio']) {
        this.convenio = this.datos['ol_convenio']
      }
      this.loadBtn = true
      let url = ''
      // if (!this.no_genericos) {
      //   url = '/vlogisticalogin/stockConsultaLog'
      // } else {
      //   url = '/vlogisticaLogin/stockConsultaLogNoGenerico'
      // }
      if (!this.no_genericos) {
        url = '/vlogisticalogin/stockConsultaLog'
      } else {
        url = '/vlogisticaLogin/stockConsultaLogNoGenerico'
      }
      // HTTP.post('/vlogisticalogin/stockConsultaLog', {
      HTTP.post(url, {
        convenio: this.convenio,
        session: this.datos,
      })
        .then((res) => {
          this.loadBtn = false
          // console.log(res.data)
          if (Number.isInteger(res.data)) {
            this.dialogStock = true
            this.numberTitle = res.data
          } else if (res.data != '') {
            window.location.href = res.data
          } else {
            this.$message({
              message: 'No hay datos para descargar.',
              type: 'warning',
            })
          }
        })
        .catch((error) => {
          this.loadBtn = false
          this.$alert('Error del servidor ' + error)
        })
    },
    downloadSame() {
      this.aceptar_load = true
      this.blockCancelar = true
      HTTP.post('/vlogisticalogin/stockConsultaLog', {
        convenio: this.convenio,
        session: this.datos,
        ask_descargar: 1,
      })
        .then((res) => {
          window.location.href = res.data
          setTimeout(() => {
            ;(this.aceptar_load = false), (this.blockCancelar = false)
            this.dialogStock = false
          }, 200)
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
        })
    },
    cancelarDownload() {
      this.dialogStock = false
    },
  },
}
</script>
