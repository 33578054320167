<template>
  <div style="text-align: center; color: white; padding-top: 100px" class="body-back bg-s">
    <h1>Página inexistente</h1>
    <p>La página donde desea ingresar no existe</p>
    <p>
      <el-button type="info" @click="regresar" style="text-align: center; padding-left: 10vh; padding-right: 10vh; font-size: 2vh; background-color: #31445887" round
        ><i class="el-icon-back"></i> Regresar
      </el-button>
    </p>
    <img src="@/assets/advertencia.png" alt="error" class="img-banner" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgFavicon: require('@/assets/aplicar2.png'),
    }
  },
  metaInfo() {
    this.imgFavicon = require('@/assets/aplicar2.png')
    return {
      title: 'Error',
      htmlAttrs: {
        lang: 'en',
      },

      meta: [{ charset: 'utf-8' }, { name: 'description', content: 'An example Vue application with vue-meta.' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }],
      link: [
        { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Roboto:300,300i,500,700,900i&display=swap' },
        { rel: 'shortcut icon', type: 'image/png', href: this.imgFavicon },
        { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Varela+Round&display=swap' },
      ],
    }
  },
  created() {
    /* Imagen segu url y btn */
    // var url = 'seguimiento.suizoargentina.com.ar/#/login';
  },
  methods: {
    regresar() {
      this.$router.push({ name: 'login' })
      // if(window.localStorage.getItem('datos')!= null)
      // {
      //     this.$router.push({name:'inicio'})
      // }
      // else
      // {
      //     this.$router.push({name:'login'})
      // }
    },
  },
}
</script>
<style scoped>
.body-back {
  /* background: linear-gradient(96deg, #FFFAFA 0, #E5EBF6 64%); */
  overflow: hidden;
  height: 100%;
  text-align: center;
  font-family: 'Roboto';
}
.bg-s {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #66b1ff !important;
  background-image: linear-gradient(315deg, #3a638d 0%, #2a2b2d 74%);
  z-index: -1;
  transition: 850ms !important;
}
@media (max-width: 768px) {
  /* .body-back{
	background: linear-gradient(96deg, #FFFAFA 0, #E5EBF6 100%) !important;
	} */
  .img-banner {
    width: 80%;
  }
  .vform {
    width: 80%;
  }
}
</style>
