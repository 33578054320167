<template>
  <div class="body-back bg-s" style="overflow-y: scroll">
    <img :src="img_header" class="img-banner" fit="contain" alt="Logo Empresa" />
    <div v-if="formRecup">
      <el-row :gutter="10" class="hidden-xs-only">
        <!-- <el-col
          :lg="{ span: 8, offset: 8 }"
          :md="{ span: 10, offset: 7 }"
          :sm="{ span: 10, offset: 7 }"
          :xs="{ span: 22, offset: 1 }"> -->
        <el-col :span="12" :offset="6">
          <el-card class="box-card" style="/* centrar vertical y horizontalmente */ position: absolute; width: 100%; height: 100%; margin: auto; /* margin-top: 100px; */">
            <div slot="header" class="clearfix">
              <!-- <span style="font-size: 20px"
                ><i class="el-icon-user-solid"></i>Recuperar contraseña</span
              > -->
              <h2><i class="el-icon-user-solid"></i>RECUPERAR CONTRASEÑA</h2>
              <p style="margin-bottom: -5px !important" v-if="msj_success == ''">Para recuperar su contraseña ingrese su usuario.</p>
            </div>
            <el-form v-if="msj_success == ''">
              <el-row :gutter="10">
                <el-form-item label="Usuario/Email" label-width="100px" style="margin: 0px !important">
                  <el-input
                    type="text"
                    v-model="email"
                    autocomplete="off"
                    clearable
                    ref="email"
                    cleareble
                    placeholder="Ingrese Usuario/Email"
                    :disabled="disabledall"
                    @keypress.native.prevent.enter="recuperar">
                    <template slot="prepend"><i class="el-icon-user-solid"></i></template>
                  </el-input>
                </el-form-item>
              </el-row>
              <br />
              <el-row :gutter="10">
                <el-form-item style="margin: 0px !important">
                  <el-link :underline="false" style="float: right" @click="show_form" :disabled="disabledall">No recuerdo mis datos</el-link>
                </el-form-item>
              </el-row>
              <el-form-item class="el-form-button">
                <el-tooltip class="item" effect="dark" content="Recuperar contraseña" placement="bottom">
                  <el-button :type="showbtnC" @click="recuperar" :loading="loading_recup" :disabled="disabledRecup"><i class="el-icon-s-promotion"></i> Recuperar</el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Regresar" placement="bottom">
                  <el-button @click="regresarLink" :disabled="disabledall"><i class="el-icon-back"></i> Regresar</el-button>
                </el-tooltip>
              </el-form-item>
            </el-form>
            <div v-if="msj_success != ''">
              <img :src="imgResultado" alt="ok" />
              <p style="background-color: #5eac24; color: white; padding: 5px">Su petición se proceso correctamente.</p>
              <p style="text-align: justify">{{ msj_success }}</p>
              <p>
                <el-button type="info" size="small" plain @click="regresarLink" style="text-align: center; background-color: rgb(255 255 255); color: #303e4d"
                  ><i class="el-icon-back"></i> Regresar
                </el-button>
              </p>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <!-- Recup pass Movil -->
      <el-row class="hidden-sm-and-up">
        <el-col :xs="20" :offset="2">
          <el-card class="box-card" style="/* centrar vertical y horizontalmente */ position: absolute; width: 100%; height: 100%; margin: auto; /* margin-top: 100px; */">
            <div slot="header" class="clearfix">
              <span style="font-size: 20px"><i class="el-icon-user-solid"></i>Recuperar contraseña</span>
              <p v-if="msj_success == ''">Para recuperar su contraseña ingrese su usuario.</p>
            </div>
            <el-form v-if="msj_success == ''" @submit.native.prevent="recuperar">
              <el-row :gutter="10">
                <el-form-item label="Usuario" label-width="80px" style="margin: 0px !important">
                  <el-input type="text" v-model="email" autocomplete="off" ref="email" placeholder="Ingrese Usuario" :disabled="disabledall" clearable></el-input>
                </el-form-item>
              </el-row>
              <br />
              <el-row :gutter="10">
                <el-form-item style="margin: 0px !important">
                  <el-link :underline="false" style="float: right" @click="show_form" :disabled="disabledall">No recuerdo mis datos</el-link>
                </el-form-item>
              </el-row>
              <el-col :xs="24">
                <el-form-item class="el-form-button">
                  <el-tooltip class="item" effect="dark" content="Recuperar contraseña" placement="bottom">
                    <el-button :type="showbtnC" @click="recuperar" :loading="loading_recup" :disabled="disabledRecup" size="mini"><i class="el-icon-s-promotion"></i> Recuperar</el-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Regresar" placement="bottom">
                    <el-button @click="regresarLink" size="mini" :disabled="disabledall"><i class="el-icon-back"></i> Regresar</el-button>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-form>
            <div v-if="msj_success != ''">
              <img :src="imgResultado" alt="" />
              <p style="text-align: justify">{{ msj_success }}</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <!-- Formulario para enviar recuperar datos -->
    <div v-else>
      <form-recuperar :empresa="empresa"></form-recuperar>
    </div>
  </div>
</template>
<script>
// import { EventBus } from "@/event-bus";
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'
import FormRecuperar from '../recuperar/formRecuperar.vue'

export default {
  components: {
    FormRecuperar,
  },
  data() {
    return {
      email: '',
      loading_recup: false,
      img_header: require('@/assets/SuizoBlanco.svg'),
      titulo: 'Suizo Argentina S.A.',
      imgFavicon: 'favicon_suizo.ico',
      showbtnC: 'primary',
      disabledall: false,
      disabledRecup: false,
      formRecup: true,
      empresa: '',
      msj_success: '',
      imgResultado: '',
    }
  },
  metaInfo() {
    return {
      title: this.titulo,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        {
          name: 'description',
          content: 'An example Vue application with vue-meta.',
        },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css?family=Roboto:300,300i,500,700,900i&display=swap',
        },
        { rel: 'shortcut icon', type: 'image/png', href: this.imgFavicon },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Varela+Round&display=swap',
        },
      ],
    }
  },
  created() {
    /* Imagen segu url y btn */
    // var url = 'seguimiento.suizoargentina.com.ar/#/login';
    let urlpp = window.location.host,
      array_url = urlpp.split('/'),
      empresa_nombre = array_url[0].split('.')
    console.log(empresa_nombre)
    if (process.env.NODE_ENV !== 'development') {
      if (empresa_nombre[1] == 'suizoargentina') {
        this.img_header = require('@/assets/SuizoBlanco.svg')
        this.titulo = 'Suizo Argentina S.A.'
        this.imgFavicon = 'favicon_suizo.ico'
        this.showbtnC = 'primary'
        this.empresa = 'u'
      } else if (empresa_nombre[1] == 'log-in') {
        this.titulo = 'LOG-IN'
        this.img_header = require('@/assets/loginfarma.png')
        this.imgFavicon = 'favicon_log.png'
        this.showbtnC = 'warning'
        this.empresa = 'lo'
        this.showError = true
      } else {
        this.$router.push({ name: 'PageNotFound' })
      }
    } else if (empresa_nombre[1] == 'log-in') {
      this.titulo = 'LOG-IN'
      this.img_header = require('@/assets/loginfarma.png')
      this.imgFavicon = 'favicon_log.png'
      this.showbtnC = 'warning'
      this.empresa = 'lo'
      this.showError = true
    } else {
      //  this.$router.push({name:'PagError'})
      if (process.env.VUE_APP_EMPRESA === 'suizo') {
        this.titulo = 'Suizo Argentina S.A.'
        this.img_header = require('@/assets/SuizoBlanco.svg')
        this.imgFavicon = 'favicon_suizo.ico'
        this.showbtnC = 'primary'
        this.empresa = 'u'
      } else {
        this.titulo = 'LOG-IN'
        this.img_header = require('@/assets/loginfarma.png')
        this.imgFavicon = 'favicon_log.png'
        this.showbtnC = 'warning'
        this.empresa = 'lo'
      }
    }
  },
  methods: {
    recuperar() {
      if (this.email != '' && this.email != null) {
        // Enviar la consulta de busqueda de email
        // Si existe enviar el email con los datos solicitados
        this.loading_recup = true
        this.disabledall = true
        // let url = this.empresa == 'u' ? 'recuperarDSA' : 'recuperarLogin'
        HTTP.post('/loginguias/recuperarLoginPrueba', {
          // HTTP.post('/loginguias/recuperarLogin', {
          accion: 'pass',
          email: this.email,
          empresa: this.empresa,
        })

          .then((res) => {
            this.loading_recup = false
            this.disabledall = false

            switch (res.data) {
              case 1:
                this.msj_error =
                  this.empresa == 'lo'
                    ? 'La dirección de correo electrónico suministrada no es válida, si no puede ingresar con su cuenta por favor comuníquese al siguiente email<p style="color: black;font-size: 16px;"> <strong><i>soporteweb@log-in.com.ar</i></strong></p><p> Muchas gracias.</p>'
                    : 'La dirección de correo electrónico suministrada no es válida.'
                this.imgResultado = require('@/assets/003-cancelar.png')
                this.ErrorDialogVisible = true

                break
              case 2:
                this.msj_error =
                  this.empresa == 'lo'
                    ? 'El nombre de usuario suministrado no es válido o no tiene una cuenta asociada.<p style="padding-top: 5px;"> Por favor comuníquese al siguiente email:</p><hr><p style="color: black;font-size: 16px;text-align: center"><strong><i>soporteweb@log-in.com.ar</i></strong></p><hr><p style="text-align: center"> Muchas gracias.</p>'
                    : 'El nombre de usuario suministrado no es válido o no tiene una cuenta asociada.'
                this.imgResultado = require('@/assets/003-cancelar.png')
                this.ErrorDialogVisible = true
                break
              case 3:
                this.msj_success =
                  'Por favor verifique la bandeja de entrada de su correo electrónico. En caso de no encontrar el correo electrónico, por favor revise en la carpeta de correo no deseado (SPAM). Gracias.'
                this.imgResultado = require('@/assets/004-email.png')
                this.form_recupe = false

                break
              case 4:
                this.msj_error = 'No se pudo procesar su petición, por favor intente más tarde.'
                this.imgResultado = require('@/assets/003-cancelar.png')
                break
              case 5:
                this.msj_error =
                  this.empresa == 'lo'
                    ? 'La cuenta no posee un email asociado.<p> Por favor comuníquese al siguiente email:</p><p style="color: black;font-size: 16px;"> <strong><i>soporteweb@log-in.com.ar</i></strong></p><p> Muchas gracias.</p> '
                    : 'La cuenta no posee un email asociado.'
                this.imgResultado = require('@/assets/003-cancelar.png')
                break
              default:
                this.msj_error = 'No se pudo procesar su petición, por favor intente más tarde'
                this.imgResultado = require('@/assets/003-cancelar.png')
                break
            }

            if (this.msj_error && res.data != '3') {
              this.$alert(this.msj_error, 'Recuperar contraseña', {
                confirmButtonText: 'OK',
                type: 'error',
                dangerouslyUseHTMLString: true,
              })
            }
          })
          .catch((error) => {
            this.loading_recup = false
            this.$alert('Error del servidor ' + error)
          })
      } else {
        this.$alert('Para recuperar la contraseña ingrese email/usuario', 'Recuperar contraseña', {
          type: 'warning',
          dangerouslyUseHTMLString: true,
        })
      }
    },
    show_form() {
      /* Emitir evento */
      this.formRecup = false
    },
    regresarLink() {
      this.$router.push({ name: 'login' })
    },
    regresar() {
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
<style scoped>
.el-card__header {
  padding: 10px 10px !important;
}
.clearfix {
  margin: 0 auto;
  padding: 0px 0 10px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.bg-s {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #66b1ff !important;
  background-image: linear-gradient(315deg, #3a638d 0%, #2a2b2d 74%);
  z-index: -1;
  transition: 850ms !important;
  /* margin-top: 20%; */
}
.body-back {
  /* background: linear-gradient(96deg, #FFFAFA 0, #E5EBF6 64%); */
  overflow: hidden;
  height: 100%;
  text-align: center;
  font-family: 'Roboto';
}
.img-banner {
  padding-top: 20px;
  width: 290px;
  padding-bottom: 20px;
}

.el-form-item {
  margin-bottom: 20px;
}
</style>
