<template>
    <div>
        <el-row style="text-align: center;" :gutter="10">
            <center>
            <el-col :md="24">
                <p class="bold title-stock">Total de movimientos:{{cantidad_elementos}}         
                </p>                    
            </el-col>
            </center>
        </el-row >
        <el-table :data="tablaMov" width="80" border stripe fit v-loading="cargando" size="small" 
        height="40vh">
            <!-- <el-table-column prop="indice" width="40" label="#">
            </el-table-column> -->
            <el-table-column property="fecha_mov" label="Fecha" width="90">
                <template slot-scope="scope">
                    <!-- {{scope.row.fecha_mov}} -->
                    <span v-if="scope.row.fecha_mov"> {{formatFecha(scope.row.fecha_mov)}} </span>
                    <span v-else>-</span>
                </template>      
            </el-table-column>
            <el-table-column prop="descripcion" label="Nombre">
                <template slot-scope="scope">                    
                    <span>{{scope.row.descripcion}}</span> 
                </template>
            </el-table-column>
            <el-table-column prop="movimiento" label="Movimiento">
            </el-table-column>
            <el-table-column prop="sucursal" label="Sucursal">
                <template slot-scope="scope">                    
                    <span>{{scope.row.sucursal}}</span> 
                </template>
            </el-table-column>
            
            <el-table-column prop="cod_proveedor" label="Cod. Proveedor" width="80">
            </el-table-column>
            <el-table-column prop="cod_suizo" label="Cod. Suizo" width="80">
            </el-table-column>
            <el-table-column prop="lote" label="Lote" width="80">
            </el-table-column>
         
            <el-table-column prop="unidades" label="Unidades" width="70">
                <template slot-scope="scope">
                    <span v-if="scope.row.unidades !=null && scope.row.unidades !=0">{{scope.row.unidades}} </span>
                    <span v-else style="color:#dd6161;"><strong>0</strong></span>
                </template>
            </el-table-column>
            
        </el-table>
        <el-pagination v-if="cantidad_elementos" background layout="total,prev, pager, next" :page-size="20" 
        style="margin:20px 0px ;float:right" 
        :total="cantidad_elementos" @current-change="nextPage" :current-page.sync="pagina">
		</el-pagination>
    </div>
</template>

<script>
import { EventBus } from "@/event-bus";
const moment = require('moment')

export default {
    data() {
        return {
            tablaMov: [],
            cargando:false,
            cantidad_elementos:0,
            session: JSON.parse(window.localStorage.getItem("datos")),
            cargarTabla:false,
            pagina:1
        };
    },
    mounted() {
        EventBus.$on("cargandoTablaMovimientos", () => {
            this.cargando = true;
            this.cantidad_elementos = 0;
        });
        
        EventBus.$on("cargarTablaMovimiento", (g) => {            
            this.cargando           = false;
            this.tablaMov 	        = g;
            this.cantidad_elementos = 0
            if(this.tablaMov.length==0)
            {
                this.$message({
                message: 'No se encontraron artículos que concuerden con su búsqueda',
                type: 'warning'
                });
            }else{
                    this.cantidad_elementos = this.tablaMov[0].TotalRows;
            }                     
        });

        setTimeout(() => {
            EventBus.$on("limpiarMov", () => {
                this.tablaMov           = [];
                this.cargando           = false;
                this.cantidad_elementos = 0;
            });
        }, 700);
    },
    beforeDestroy(){
        EventBus.$off('cargarTablaMovimiento',false)
        EventBus.$off('cargandoTablaMovimientos',false)
        EventBus.$off('limpiarMov',false)
    },
    methods: {

        formatFecha(fecha) {
            return moment(fecha).format('DD/MM/YYYY');
        },
        nextPage(page) {
        //    alert(page)
           EventBus.$emit("cambiarPaginaMov",page);
        },
    },
    
}
</script>
<style scoped>
.title-stock
{
    margin:0px !important; 
    font-size:15px;
    background-color: #36404e;
    color:#ffff;
    font-size:13px
}
.el-button--mini.is-circle {
    padding: 1px;
}
</style>