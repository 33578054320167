export const fields = [
  {
    name: 'id',
    sortField: 'id',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
    width: '50px',
    visible: false,
  },
  {
    name: '__slot:convenio',
    title: 'Convenio',
    //name: 'convenio.nombre',
    //titleClass: 'text-center-titulo',
    //dataClass: 'text-center-data',
  },
  /*{
    title: 'Convenio',
    name: 'convenio.nombre',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },*/
  {
    title: 'Proveedor Convenio',
    name: '__slot:proveedor_convenio',
    sortField: 'proveedor_convenio',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
  },
  /*
  {
    name: '__slot:check_cliente',
    title: 'Proveedor de descarga',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '100px',
  },
  */
  {
    name: 'detalles',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
    visible: false,
  },
  {
    name: '__slot:check_frio',
    title: 'Necesita Frio',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '80px',
  },
  {
    name: 'tipo_unidad',
    sortField: 'tipo_unidad',
    title: 'Tipo de Unidad',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '100px',
  },
  {
    name: 'tipo_descarga',
    sortField: 'tipo_descarga',
    title: 'Tipo de Descarga',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '100px',
  },
  {
    name: 'contacto_nombre',
    title: 'Nombre del contacto',
    sortField: 'contacto_nombre',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: 'contacto_telefono',
    title: 'Telefono',
    sortField: 'contacto_telefono',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
    width: '100px',
  },
  {
    name: 'contacto_mail',
    title: 'Email',
    sortField: 'contacto_mail',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: '__component:agenda-actions',
    title: 'Acciones',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
    width: '100px',
  },
]
export const sortOrder = [{ field: 'convenio_id', sortField: 'convenio_id', direction: 'asc' }]
export const rules = {
  convenio_id: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
  /*
        tipo_unidad: [
          { required: true, message: 'Campo obligatorio', trigger: 'change' },
        ],
        tipo_descarga: [
          { required: true, message: 'Campo obligatorio', trigger: 'change' },
        ],*/
  contacto_nombre: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
  contacto_telefono: [{ required: true, message: 'Campo obligatorio', trigger: ['blur'] }],
  contacto_mail: [
    {
      required: true,
      type: 'email',
      message: 'Ingrese una direccion de email correcta',
      trigger: ['blur'],
    },
  ],
}
