<template>
  <el-dialog
    :visible.sync="modal.visible"
    width="70%"
    title="Logs de Notificaciones">
    <el-row :gutter="10">
      <el-col>
        <el-table
          :data="this.modal.datos"
          border
          empty-text="Realice una busqueda para ver la información">
          <el-table-column prop="id" label="Id" width="80px">
            <template slot-scope="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="mensaje" label="Mensaje">
            <template slot-scope="scope">
              <span>{{ scope.row.mensaje }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="alta" label="Fecha">
            <template slot-scope="scope">
              <span>{{
                formatDate(scope.row.alta, 'DD-MM-YYYY HH:mm:ss')
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="estado_informado" label="Codigo" width="80px">
            <template slot-scope="scope">
              <span>{{ scope.row.estado_informado }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="estado_informado" label="Descripcion">
            <template slot-scope="scope">
              <strong>{{
                callbackEstado(scope.row.estado_informado)['descripcion']
              }}</strong>
            </template>
          </el-table-column>
          <el-table-column prop="estado_informado" label="Estado Informado">
            <template slot-scope="scope">
              <el-tag
                :type="callbackEstado(scope.row.estado_informado)['color']"
                disable-transitions
                >{{
                  callbackEstado(scope.row.estado_informado)['texto']
                }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="estado" label="Respuesta API" width="80px">
            <template slot-scope="scope">
              <el-tag
                :type="callbackEstadoAPI(scope.row.estado)['color']"
                disable-transitions
                >{{ callbackEstadoAPI(scope.row.estado)['texto'] }}
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" @click="cerrar()" icon="el-icon-close"
        >Cerrar</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import estadosJson from './_estados.json'

export default {
  name: 'southDetails',
  props: {
    modal: { required: true },
  },
  mounted() {},
  methods: {
    formatDate(value, fmt = 'D MMM YYYY') {
      return value == null
        ? 'Sin registro'
        : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt)
    },
    cerrar() {
      this.$events.fire('cerrar-detalles-modal')
    },
    callbackEstado(codigo) {
      let estadoObj = estadosJson.find((x) => x.codigo == codigo)
      if (estadoObj === undefined) {
        var objDesconocido = {}
        objDesconocido.color = 'info'
        objDesconocido.texto = 'DESCONOCIDO'
        objDesconocido.descripcion = 'No se reconoce el codigo'
        return objDesconocido
      } else return estadoObj
    },
    callbackEstadoAPI(estado) {
      var objEstado = {}
      switch (estado) {
        case 'ok':
          objEstado.color = 'success'
          objEstado.texto = 'OK'
          break
        case 'error':
          objEstado.color = 'danger'
          objEstado.texto = 'ERROR'
          break
        default:
          objEstado.color = 'info'
          objEstado.texto = 'DESCONOCIDO'
          break
      }
      return objEstado
    },
  },
}
</script>
