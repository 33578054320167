<template>
  <div v-loading="loadingGral">
    <div class="vguias_buscar">
      <!-- Botones de sincronización  -->
      <el-row style="border-bottom: 1px solid #ddd; padding-bottom: 8px" :gutter="10">
        <el-col :md="24" :sm="24" :xs="24">
          <h2 style="margin: -3px">Resumen de estado de Guías</h2>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-form :labe-position="'top'" size="small" style="margin: 0px 5px">
          <el-col :md="4" :sm="8" :xs="24">
            <el-form-item label="Destino">
              <el-select v-model="resumen.destino" placeholder="Seleccione Convenios" size="mini" filterable style="width: 100%" clearable :disabled="deshabilitarCampos">
                <el-option :value="null" label="TODOS" key=""> </el-option>
                <el-option v-for="dest in destinos" :key="dest.olconv" :label="dest.codigo + ' - ' + dest.nombre" :value="dest.codigo"> </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :md="4" :sm="8" :xs="24" v-if="mostrar">
            <el-form-item label="Afinidad">
              <el-select style="width: 100%" v-model="resumen.afinidad" placeholder="Afinidad" size="mini" filterable clearable :disabled="disabledAfinidad" @change="mostrarConvenios">
                <el-option :value="null" label="TODAS" key=""> </el-option>
                <el-option v-for="afi in afinidades" :key="afi.codigo" :label="afi.descrip" :value="afi.codigo"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="8" :xs="24" v-if="mostrar > 0">
            <el-form-item label="Convenios">
              <div v-if="convenios.length > 1">
                <el-select
                  style="width: 100%"
                  v-model="resumen.convenio"
                  placeholder="Seleccione Convenios"
                  size="mini"
                  filterable
                  clearable
                  :disabled="deshabilitarCampos"
                  @change="mostrarAfinidad"
                  :loading="cargandoConvenios">
                  <el-option :value="null" label="TODOS" key=""> </el-option>
                  <el-option v-for="conv in convenios" :key="conv.olconv" :label="conv.nombre" :value="conv.olconv"> </el-option>
                </el-select>
              </div>
              <div v-else>
                <el-select style="width: 100%" v-model="resumen.convenio" placeholder="Seleccione Convenios" size="mini" filterable clearable disabled :loading="cargandoConvenios">
                  <el-option v-for="conv in convenios" :key="conv.olconv" :label="conv.nombre" :value="conv.olconv"> </el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="12" :xs="24">
            <el-form-item label="Fecha">
              <el-date-picker
                size="mini"
                v-model="resumen.fecha"
                style="width: 100%"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="-"
                start-placeholder="Desde"
                end-placeholder="Hasta"
                :picker-options="pickerOptions"
                format="dd/MM/yyyy"
                :disabled="deshabilitarCampos">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="12" :xs="24" :offset="0">
            <el-button-group style="margin-top: 33px">
              <el-button type="primary" size="mini" @click="getResumen2" :style="styleBtnBuscar" :disabled="deshabilitarCampos"> Filtrar </el-button>
              <el-button type="default" size="mini" @click="eliminarFiltros" :disabled="deshabilitarCampos"> Eliminar </el-button>
            </el-button-group>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div style="padding: 0px 20px 0px">
      <el-row style="background-color: #f0f2f5; padding: 10px; border-bottom: 1px solid #ddd" :gutter="10">
        <el-col :span="24">
          <h4 style="font-size: 18px; margin: -3px">Estados de guías por convenio</h4>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-table ref="filterTable" border :data="resumenByConvenio" width="100" height="250" v-loading="loading">
          <el-table-column prop="convenio" label="Convenio">
            <template slot-scope="scope">
              <span>{{ scope.row.convenio | uppercase }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="Pendiente" label="Pendientes" width="130" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.Pendiente || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="Entregada" label="Entregadas" sortable width="130">
            <template slot-scope="scope">
              <span>{{ scope.row.Entregada || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="Rechazado" label="Rechazadas" sortable width="130">
            <template slot-scope="scope">
              <span>{{ scope.row.Rechazado || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="Reprogramada" label="Reprogramadas" sortable width="140">
            <template slot-scope="scope">
              <span>{{ scope.row.Reprogramada || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="Retiro" label="Retiro" sortable width="110">
            <template slot-scope="scope">
              <span>{{ scope.row.Retiro || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
            </template>
          </el-table-column>
          <el-table-column prop="Anulada" label="Anuladas" sortable width="130">
            <template slot-scope="scope">
              <span>{{ scope.row.Anulada || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row style="background-color: #f0f2f5; padding: 10px; border-bottom: 1px solid #ddd" :gutter="10">
        <el-col :span="12">
          <h5 style="font-size: 13px; margin: 0px">{{ Cantresumen }} Convenios</h5>
        </el-col>

        <el-col :span="12">
          <h5 style="font-size: 13px; margin: -3px; text-align: end !important">
            <el-button
              type="info"
              size="mini"
              style="margin-right: 5px"
              :disabled="disabled_conv"
              @click="descargarTabla(resumenByConvenio, 'convenio')"
              :loading="btn_loading_conv"
              icon="el-icon-download">
              Descargar
            </el-button>
            {{ sumarGuias(resumenByConvenio) || 0 | currency('', 0, { thousandsSeparator: '.' }) }}
            guías
          </h5>
        </el-col>
      </el-row>
    </div>

    <el-row :gutter="10" style="padding: 20px">
      <el-row :gutter="10" style="background-color: #f0f2f5; padding: 10px; margin: 0px; border-bottom: 1px solid #ddd">
        <el-col :span="12">
          <h4 style="font-size: 18px; margin: 0px">Estados de guías por fecha</h4>
        </el-col>
      </el-row>
      <el-table :data="resumenEstado" border style="width: 100%" height="250" v-loading="loading">
        <el-table-column prop="fecha" label="Fecha">
          <template slot-scope="scope">
            <span>{{ formatFecha(scope.row.fecha.date) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="Pendiente" label="Pendientes" sortable width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.Pendiente || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="Entregada" label="Entregadas" sortable width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.Entregada || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="Rechazado" label="Rechazadas" sortable width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.Rechazado || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="Reprogramada" label="Reprogramadas" sortable width="140">
          <template slot-scope="scope">
            <span>{{ scope.row.Reprogramada || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="Retiro" label="Retiro" sortable width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.Retiro || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="Anulada" label="Anuladas" sortable width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.Anulada || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
          </template>
        </el-table-column>
      </el-table>
      <el-row :gutter="10" style="background-color: #f0f2f5; padding: 10px; margin: 0px; border-bottom: 1px solid #ddd; margin-bottom: 5px">
        <el-col :span="12">
          <h5 style="font-size: 13px; margin: -3px; font-weight: 500">
            {{ CantEstado || 0 | currency('', 0, { thousandsSeparator: '.' }) }}
            Registros
          </h5>
        </el-col>
        <el-col :span="12">
          <h5 style="font-size: 13px; margin: -3px; text-align: end !important; font-weight: 500">
            <el-button
              type="info"
              size="mini"
              style="margin-right: 5px"
              @click="descargarTabla(resumenEstado, 'fecha')"
              :loading="btn_loading_fecha"
              :disabled="disabled_fecha"
              icon="el-icon-download">
              Descargar
            </el-button>
            {{ sumarGuias(resumenEstado) || 0 | currency('', 0, { thousandsSeparator: '.' }) }}
            guías
          </h5>
        </el-col>
      </el-row>
    </el-row>
    <el-row :gutter="10" style="padding: 0px 20px; margin-bottom: 30px">
      <el-row :gutter="10" style="background-color: #f0f2f5; padding: 10px; margin: 0px; border-bottom: 1px solid #ddd">
        <el-col :span="12">
          <h4 style="font-size: 20px; margin: 0px">Resumen Guías no entregadas</h4>
        </el-col>
      </el-row>
      <el-table :data="noEntregados" border style="width: 100%" height="250" v-loading="loading">
        <el-table-column prop="motivo" label="Motivo">
          <template slot-scope="scope">
            <span v-if="scope.row.motivo != null">{{ scope.row.motivo }} </span>
            <span v-else>N/A</span>
          </template>
        </el-table-column>
        <el-table-column prop="Rechazado" label="Rechazadas" sortable width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.Rechazado || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="Reprogramada" label="Reprogramadas" sortable width="140">
          <template slot-scope="scope">
            <span>{{ scope.row.Reprogramada || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="Retiro" label="Retiro" sortable width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.Retiro || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="Anulada" label="Anuladas" sortable width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.Anulada || 0 | currency('', 0, { thousandsSeparator: '.' }) }} </span>
          </template>
        </el-table-column>
      </el-table>
      <el-row :gutter="10" style="background-color: #f0f2f5; padding: 10px; margin: 0px; border-bottom: 1px solid #ddd; margin-bottom: 5px">
        <el-col :span="12">
          <h5 style="font-size: 13px; margin: -3px; font-weight: 500">
            {{ CantnoEntregados || 0 | currency('', 0, { thousandsSeparator: '.' }) }}
            Registros
          </h5>
        </el-col>
        <el-col :span="12">
          <h5 style="font-size: 13px; margin: -3px; text-align: end !important; font-weight: 500">
            <el-button
              type="info"
              size="mini"
              style="margin-right: 5px"
              @click="descargarTabla(noEntregados, 'no entregados')"
              :loading="btn_loading_no"
              :disabled="disabled_no"
              icon="el-icon-download">
              Descargar
            </el-button>
            {{ sumarGuias(noEntregados) || 0 | currency('', 0, { thousandsSeparator: '.' }) }}
            guías
          </h5>
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import { API } from '@/plugins/api'

Vue.use(Vue2Filters)
export default {
  data() {
    return {
      deshabilitarCampos: false,
      resumen: {
        destino: null,
        afinidad: null,
        convenio: null,
        fecha: null,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: 'Últ. Semana',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Últ. Mes',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '3 Últ. meses',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      styleBtnBuscar: { borderColor: '#02172e00' },
      afinidades: [],
      destinos: [],
      convenios: [],
      mostrar: false,
      resumenByConvenio: [],
      Cantresumen: 0,
      noEntregados: [],
      CantnoEntregados: 0,
      resumenEstado: [],
      CantEstado: 0,
      loading: false,
      empresa: window.localStorage.getItem('empresa'),
      loadingGral: true,
      cargandoConvenios: true,
      conveniosInicio: [],
      afinidadInicio: [],
      disabledAfinidad: false,
      btn_loading_conv: false,
      btn_loading_fecha: false,
      btn_loading_no: false,
      disabled_conv: false,
      disabled_fecha: false,
      disabled_no: false,
      permisos_usuario: JSON.parse(window.localStorage.getItem('datos')).permisos,
    }
  },
  mounted() {
    this.permisos()
    this.getRecursos()
  },
  created() {},
  methods: {
    getRecursos() {
      this.loadingGral = true
      API.post('/resumen/getRecursos')
        .then((res) => {
          this.convenios = res.data.data.convenios
          this.afinidades = res.data.data.afinidad
          this.afinidadInicio = res.data.data.afinidad
          this.destinos = res.data.data.destinos
          this.cargandoConvenios = false
          this.conveniosInicio = res.data.data.convenios

          if (this.convenios.length === 1) {
            this.resumen.convenio = this.convenios[0].olconv
          }
          this.loadingGral = false
          setTimeout(() => {
            this.getResumen2()
          }, 1000)
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
          this.loadingGral = false
        })
    },
    armarObjeto() {
      const filtros = {}
      if (this.resumen.destino != null && this.resumen.destino != '') {
        filtros.destino = this.resumen.destino
      }
      if (Array.isArray(this.resumen.fecha) && this.resumen.fecha.length > 0) {
        filtros.fecha = this.resumen.fecha
      }
      if (this.resumen.convenio != null && this.resumen.convenio != '') {
        filtros.convenio = this.resumen.convenio
      }
      if (this.resumen.afinidad != null && this.resumen.afinidad != '') {
        filtros.afinidad = this.resumen.afinidad
      }
      return filtros
    },
    getResumen2() {
      this.loading = true
      this.deshabilitarCampos = true
      this.disabledAfinidad = true
      const filtros = this.armarObjeto()
      API.post('/resumen/getResumen', filtros)
        .then((res) => {
          this.resumenByConvenio = res.data.data.resumenByConvenio
          this.Cantresumen = res.data.data.resumenByConvenio.length
          this.noEntregados = res.data.data.noEntregados
          this.CantnoEntregados = res.data.data.noEntregados.length
          this.resumenEstado = res.data.data.resumenEstado
          this.CantEstado = res.data.data.resumenEstado.length
          /* disabled */
          this.disabled_conv = this.Cantresumen == 0 ? true : false
          this.disabled_fecha = this.CantEstado == 0 ? true : false
          this.disabled_no = this.CantnoEntregados == 0 ? true : false
          this.deshabilitarCampos = false
          this.loading = false
          this.disabledAfinidad = false
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
          this.deshabilitarCampos = false
          this.loading = false
          this.disabledAfinidad = false
        })
    },
    /* -----------------FUNCION ANTERIOS------------------- */
    // getResumen() {
    //   this.loading = true
    //   API.post('/resumen/getRecursos', this.resumen)
    //     .then((res) => {
    //       this.resumenByConvenio = res.data.resumen
    //       this.Cantresumen = res.data.resumen.length
    //       this.noEntregados = res.data.noEntregados
    //       this.CantnoEntregados = res.data.noentregados.length
    //       this.resumenEstado = res.data.resumenestado
    //       this.CantEstado = res.data.resumenestado.length
    //       /* disabled */
    //       this.disabled_conv = this.Cantresumen == 0 ? true : false
    //       this.disabled_fecha = this.CantEstado == 0 ? true : false
    //       this.disabled_no = this.CantnoEntregados == 0 ? true : false
    //       this.loading = false
    //     })
    //     .catch((error) => {
    //       this.$alert('Error del servidor ' + error)
    //       // this.loadRecursos = false
    //     })
    //   if (this.permisos_usuario.indexOf('ADMIN') != -1) {
    //     this.resumen.lista = true
    //     this.resumen.multi = true
    //   } else {
    //     this.resumen.lista = this.permisos_usuario.indexOf('OL_LISTACONV') != -1 ? true : false
    //     this.resumen.multi = this.permisos_usuario.indexOf('OL_MULTI') != -1 ? true : false
    //   }
    //   this.resumen.sesion.guia_convenios = this.datos['guia_convenios']
    //   this.resumen.sesion.ol_convenio = this.datos['ol_convenio']
    //   HTTP.post('/guiasNew/getResumen', this.resumen)
    //     .then((res) => {
    //       // console.log(res.data)
    //       this.resumenByConvenio = res.data.resumen
    //       this.Cantresumen = res.data.resumen.length
    //       this.noEntregados = res.data.noentregados
    //       this.CantnoEntregados = res.data.noentregados.length
    //       this.resumenEstado = res.data.resumenestado
    //       this.CantEstado = res.data.resumenestado.length
    //       /* disabled */
    //       this.disabled_conv = this.Cantresumen == 0 ? true : false
    //       this.disabled_fecha = this.CantEstado == 0 ? true : false
    //       this.disabled_no = this.CantnoEntregados == 0 ? true : false

    //       this.loading = false
    //       this.loadingGral = false
    //     })
    //     .catch((error) => {
    //       this.loading = false
    //       this.loadingGral = false

    //       console.log(error)
    //       this.$alert('No se pudo realizar la acción. Refresque la página')
    //     })
    // },
    /* --------------------FUNCIÓN MODIFICADA-------------- */
    permisos() {
      if (this.empresa == 'lo') {
        this.styleBtnBuscar['backgroundColor'] = '#e5590a !important'
      } else {
        this.styleBtnBuscar['backgroundColor'] = '#0b406a !important'
      }
      this.mostrar = this.permisos_usuario.indexOf('OL_LISTACONV') != -1 || this.permisos_usuario.indexOf('OL_MULTI') != -1 || this.permisos_usuario.indexOf('ADMIN') != -1 ? true : false
      // this.resumen.multi = this.permisos_usuario.indexOf('OL_MULTI') != -1 ? true : false
      // this.resumen.sesion.guia_convenios = this.datos['guia_convenios']
      // this.resumen.sesion.ol_convenio = this.datos['ol_convenio']
      // if (this.mostrar) {
      //   this.getConvenios()
      //   this.getAfinidad()
      //   this.getDestinos()
      // }
    },
    /* --------------- FIN FUNCIÓN MODIFICADA-------------- */
    /* -----------------FUNCION ANTERIOS------------------- */
    // getAfinidad() {
    //   HTTP.get('/guiasnew/getAfinidad')
    //     .then((res) => {
    //       if (res.data != '') {
    //         this.afinidades = res.data
    //         this.afinidadInicio = res.data
    //       } else {
    //         this.afinidades = []
    //       }
    //     })
    //     .catch((error) => {
    //       this.$alert('Error del servidor ' + error)
    //     })
    // },
    mostrarConvenios(afinidad) {
      this.convenios = []
      this.resumen.ol_convenio = ''
      if (afinidad != null && afinidad != '') {
        var filtrados = this.conveniosInicio.filter((elemento) => elemento.afinidad === afinidad)
        this.convenios = filtrados
      } else {
        this.convenios = this.conveniosInicio
        this.afinidades = this.afinidadInicio
      }
    },
    mostrarAfinidad(convenio) {
      this.afinidades = []

      if (convenio != '' && convenio != null) {
        var buscarConv = this.conveniosInicio.filter((elemento) => elemento.olconv === convenio)
        if (buscarConv[0]['afinidad'] != '' && buscarConv[0]['afinidad'] != 0) {
          this.disabledAfinidad = false
          var filtrarAfi = this.afinidadInicio.filter((elemento) => elemento.codigo === buscarConv[0]['afinidad'])
          this.afinidades = filtrarAfi
          this.resumen.afinidad = filtrarAfi[0]['codigo']
        } else {
          this.disabledAfinidad = true
        }
      } else {
        this.disabledAfinidad = false
        this.afinidades = this.afinidadInicio
        this.convenios = this.conveniosInicio
      }
    },
    // getDestinos() {
    //   HTTP.get('/guiasNew/getDestino')
    //     .then((res) => {
    //       this.destinos = res.data != '' ? res.data : []
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //       this.$alert('Hubo un error al cargar los destinos. Intente nuevamente.')
    //     })
    // },
    // getConvenios() {
    //   this.convenios = []
    //   HTTP.post('/guiasnew/getConvenios', {
    //     ol_lista: this.resumen.lista,
    //     ol_multi: this.resumen.multi,
    //     guia_convenios: this.datos['guia_convenios'],
    //     ol_convenio: this.datos['ol_convenio'],
    //   }).then((res) => {
    //     this.convenios = res.data
    //     this.cargandoConvenios = false
    //     this.conveniosInicio = res.data
    //   })
    // },
    sumarGuias(array) {
      var Pendiente = array.reduce(function (a, b) {
        return b.Pendiente == null ? a : a + parseInt(b.Pendiente)
      }, 0)
      var Entregada = array.reduce(function (a, b) {
        return b.Entregada == null ? a : a + parseInt(b.Entregada)
      }, 0)
      var Rechazado = array.reduce(function (a, b) {
        return b.Rechazado == null ? a : a + parseInt(b.Rechazado)
      }, 0)
      var Reprogramada = array.reduce(function (a, b) {
        return b.Reprogramada == null ? a : a + parseInt(b.Reprogramada)
      }, 0)
      var Retiro = array.reduce(function (a, b) {
        return b.Retiro == null ? a : a + parseInt(b.Retiro)
      }, 0)
      var Anulada = array.reduce(function (a, b) {
        return b.Anulada == null ? a : a + parseInt(b.Anulada)
      }, 0)
      return parseInt(Pendiente) + parseInt(Entregada) + parseInt(Rechazado) + parseInt(Reprogramada) + parseInt(Retiro) + parseInt(Anulada)
    },
    formatFecha(fecha) {
      return this.$moment(fecha).format('DD/MM/YYYY')
    },
    /* -----------------FUNCION ANTERIOS------------------- */
    // filtrarRes() {
    //   this.loading = true
    //   this.resumen.accion = 2
    //   // HTTP.post('/guiasNew/getResumen',this.resumen
    //   HTTP.post('/guiasNew/getResumen', this.resumen)
    //     .then((res) => {
    //       this.resumenByConvenio = res.data.resumenByConvenio
    //       this.Cantresumen = res.data.resumenByConvenio.length
    //       this.noEntregados = res.data.noEntregados
    //       this.CantnoEntregados = res.data.noEntregados.length
    //       this.resumenEstado = res.data.resumenEstado
    //       this.CantEstado = res.data.resumenEstado.length
    //       this.loading = false
    //       /* disabled */
    //       this.disabled_conv = this.Cantresumen == 0 ? true : false
    //       this.disabled_fecha = this.CantEstado == 0 ? true : false
    //       this.disabled_no = this.CantnoEntregados == 0 ? true : false
    //     })
    //     .catch((error) => {
    //       this.loading = false

    //       this.$alert('Error del servidor ' + error)
    //     })
    // },
    eliminarFiltros() {
      this.afinidades = this.afinidadInicio
      this.disabledAfinidad = false
      this.resumen.destino = null
      this.resumen.afinidad = null
      this.resumen.convenio = null
      this.resumen.fecha = null
      this.getResumen2()
    },
    descargarTabla(tabla, tipo) {
      switch (tipo) {
        case 'convenio':
          this.btn_loading_conv = true
          this.btn_loading_fecha = false
          this.btn_loading_no = false
          break
        case 'fecha':
          this.btn_loading_conv = false
          this.btn_loading_fecha = true
          this.btn_loading_no = false
          break
        case 'no entregados':
          this.btn_loading_conv = false
          this.btn_loading_fecha = false
          this.btn_loading_no = true
          break
      }

      let find_afinidad = this.afinidades.find((element) => element.codigo === this.resumen.afinidad)
      let find_convenio = this.convenios.find((element) => element.olconv === this.resumen.convenio)
      let find_destino = this.destinos.find((element) => element.codigo === this.resumen.destino)
      if (find_afinidad != null) {
        this.resumen.afinidad = find_afinidad.descrip
      }
      if (find_convenio != null) {
        this.resumen.convenio = find_convenio.descrip
      }
      if (find_destino != null) {
        this.resumen.destino = find_destino.nombre
      }

      HTTP.post('/guiasNew/excelResumenGuias', {
        tabla: tabla,
        tipo: tipo,
        filtros: this.resumen,
      })
        .then((res) => {
          this.btn_loading_conv = false
          this.btn_loading_fecha = false
          this.btn_loading_no = false
          if (res.data == 0) {
            this.$message({
              showClose: true,
              message: 'No hay datos para descargar.',
              type: 'warning',
            })
          } else {
            window.location.href = res.data
            this.disabled_conv = false
            this.disabled_fecha = false
            this.disabled_no = false
          }
        })
        .catch((error) => {
          this.loading = false

          this.$alert('Error del servidor ' + error)
        })
    },
  },
  watch: {
    resumen: {
      handler(val) {
        if (val) {
          this.disabled_conv = true
          this.disabled_fecha = true
          this.disabled_no = true
        }
      },
      deep: true,
    },
  },
}
</script>
