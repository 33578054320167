<template>
  <div style="padding-top: 0px !important">
    <div class="vguias_buscar">
      <!--=================================================================================================-->
      <el-row :gutter="10">
        <!-- <el-col :md="8" :sm="12">
        <h4 style="font-size: 20px">
          <center>Resumen de Hojas de Ruta</center>

          <p style="font-size:12px">
                        Seleccione una sucursal para visualizar los datos
                    </p>
        </h4>
      </el-col> -->
        <el-col :md="24">
          <div style="margin-top: 30px">
            <h2>
              Resumen de Hojas de Ruta
              <!-- <p style="font-size:12px">
                        Seleccione una sucursal para visualizar los datos
                    </p> -->
            </h2>
            <label style="font-size: 15px; color: #606266">Seleccione una sucursal y luego haga clic en "<strong>Buscar</strong>".</label>
            <el-form ref="form" :model="form">
              <el-form-item label="Sucursales">
                <el-select
                  v-model="destino"
                  size="small"
                  placeholder="Seleccione una sucursal"
                  class="select-suc"
                  filterable
                  :loading="loadingSuc"
                  :disabled="loadingFiltr"
                  @change="limpiarTabla()">
                  <el-option v-for="item in sucursales" :key="item.codigo" :label="item.codigo + ' - ' + item.nombre" :value="item.codigo"> </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
      <!-- ROW 2 - FILTRAR BUSQUEDA =======================================================================-->
      <el-row :gutter="10" v-if="showTablaGuias == false" style="margint-top: -30px">
        <el-col :md="24">
          <el-button-group style="margin-top: -30px; float: right" class="hidden-sm-and-down">
            <el-tooltip placement="bottom">
              <div slot="content">Exportar Hojas de Rutas</div>
              <el-button type="success" :size="itemForm" :disabled="resumenHR.length === 0" :loading="excel" @click="generarExcel">Exportar a excel</el-button>
            </el-tooltip>
          </el-button-group>
          <el-button-group style="margin-top: -10px; float: right; padding-bottom: 5px" class="hidden-md-and-up">
            <el-tooltip placement="bottom">
              <div slot="content">Exportar Hojas de Rutas</div>
              <el-button type="success" :size="itemForm" :disabled="resumenHR.length == 0" :loading="excel" @click="generarExcel">Exportar a excel</el-button>
            </el-tooltip>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
    <div style="background-color: #e4e8f2; margin: 0px; padding-left: 5px; padding-right: 5px" v-if="!showTablaGuias">
      <el-form ref="form" :model="form" :inline="true" :disabled="deshabilitar" label-position="top">
        <el-row :gutter="10">
          <el-col :lg="{ span: 4 }" :md="{ span: 5 }" :sm="{ span: 12 }" :xs="{ span: 12 }">
            <el-form-item label="Rango de fechas">
              <el-date-picker
                v-model="filtroFechas"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="-"
                start-placeholder="Desde"
                end-placeholder="Hasta"
                style="width: 100%"
                :picker-options="pickerOptions"
                format="dd/MM/yyyy"
                :size="itemForm"
                :disabled="!destino || loadingFiltr">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :lg="{ span: 3 }" :md="{ span: 3 }" :sm="{ span: 4 }" :xs="{ span: 12 }">
            <el-form-item label="H.R.">
              <el-input
                clearable
                v-model="form.hojaDeRuta"
                :disabled="disabledReparto || loadingFiltr"
                :size="itemForm"
                @change="controlDisabledHr"
                placeholder="HR"
                @keypress.native.enter="filtrar()"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="{ span: 3 }" :md="{ span: 3 }" :sm="{ span: 4 }" :xs="{ span: 8 }">
            <el-form-item label="Reparto">
              <el-input
                clearable
                v-model="form.reparto"
                :disabled="disabledReparto || loadingFiltr"
                :size="itemForm"
                @change="controlDisabledHr"
                placeholder="Reparto"
                @keypress.native.enter="filtrar()"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="{ span: 3 }" :md="{ span: 3 }" :sm="{ span: 4 }" :xs="{ span: 8 }">
            <el-form-item label="S.Reparto">
              <el-input
                clearable
                v-model="form.subreparto"
                :disabled="disabledReparto || loadingFiltr"
                :size="itemForm"
                @change="controlDisabledHr"
                placeholder="S.Reparto"
                @keypress.native.enter="filtrar()"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="{ span: 3 }" :md="{ span: 3 }" :sm="{ span: 6 }" :xs="{ span: 8 }">
            <el-form-item label="Ver">
              <el-select v-model="form.ver" placeholder="Seleccione ver" :size="itemForm" :disabled="disabledHr || loadingFiltr" clearable>
                <el-option label="Todos" value="0"></el-option>
                <el-option label="Con pendientes" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="{ span: 4 }" :md="{ span: 3 }" :sm="{ span: 6 }" :xs="{ span: 8 }">
            <el-form-item label="Tipo">
              <el-select v-model="form.tipo" placeholder="Seleccione tipo." :size="itemForm" :disabled="disabledHr || loadingFiltr" clearable>
                <el-option label="Todos" value="TT"></el-option>
                <el-option label="Última Milla" value="UM"></el-option>
                <el-option label="Transporte" value="TR"></el-option>
                <el-option label="Mixtas" value="MX"></el-option>
                <el-option label="Última Milla y Mixtas" value="UMM"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="{ span: 4 }" :md="{ span: 4 }" :sm="{ span: 6 }" :xs="{ span: 6 }">
            <center>
              <el-button-group style="margin-top: 35px">
                <el-button type="success" :size="itemForm" :disabled="deshabilitar" :loading="loadingFiltr" @click="filtrar" icon="el-icon-search">Buscar</el-button>
                <el-button type="danger" :size="itemForm" :disabled="deshabilitar" :loading="loadingElimi || loadingFiltr" @click="eliminarFiltro" icon="el-icon-close"> Eliminar</el-button>
              </el-button-group>
            </center>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- </div> -->
    <!-- Fin de Formulario -->
    <!--=================================================================================================-->
    <!--=================================================================================================-->
    <!-- ROW 3 ===== TABLA ==============================================================================-->
    <!--=================================================================================================-->
    <!--=================================================================================================-->
    <div v-if="!showTablaGuias" style="padding: 0px 5px">
      <el-row :gutter="10">
        <p class="caja" v-if="!mostrarTable" style="text-align: center; font-size: 18px; color: #303133">Seleccione una sucursal y haga clic en buscar.</p>
        <el-row style="border-bottom: 1px solid #ddd; text-align: center; padding: 8px" :style="styleTitle" v-if="mostrarTable">
          <el-col :md="24">
            <p class="font-title">Total de Hojas de Ruta:{{ total }} - {{ fecha_busqueda }}</p>
          </el-col>
        </el-row>
        <el-table
          v-if="mostrarTable"
          :data="resumenHR"
          border
          fit
          size="mini"
          height="42vh"
          @row-click="verDetalleHR"
          v-loading="loadingTable"
          style="width: 100%"
          :row-style="tableRowClassName">
          <el-table-column label="" width="60">
            <template slot-scope="scope">
              <img v-if="scope.row.um == 'TR' || scope.row.um == 'MX'" style="height: 16px" src="@/assets/img/stkremoto.png" alt="" />
              <img v-if="scope.row.um == 'UM' || scope.row.um == 'MX'" style="height: 18px" src="@/assets/img/ico-um1.png" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="hojaruta" label="Hoja de Ruta" width="88"> </el-table-column>
          <el-table-column prop="estado_hojaruta" label="Estado H.R." width="88" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.estado_hojaruta != ' '">
                {{ scope.row.estado_hojaruta }}
              </span>
              <span v-else> - </span>
            </template>
          </el-table-column>
          <el-table-column label="Fecha HR" width="136">
            <template slot-scope="scope">
              <span v-if="scope.row.fchhojaruta">
                {{ scope.row.fchhojaruta.date | moment1 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="rep" label="Reparto" width="80">
            <template slot-scope="scope"> {{ scope.row.rep }}/{{ scope.row.sub }} </template>
          </el-table-column>
          <el-table-column prop="reparto" label="Nombre Reparto">
            <template slot-scope="scope">
              <span v-if="scope.row.reparto">
                {{ scope.row.reparto }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="Fecha Rep." width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.fecharep">
                {{ scope.row.fecharep.date | moment2 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Chofer" width="65">
            <template slot-scope="scope">
              <div v-if="scope.row.chofer > 0">
                <el-popover placement="right" width="300" trigger="hover" :close-delay="50">
                  <h4 style="margin-top: 2px; margin-bottom: 5px; border-bottom: 1px solid #c0c0c0">Datos del chofer</h4>
                  <p v-if="scope.row.chofer_nombre">
                    Nombre: {{ scope.row.chofer_nombre }}
                    <br />
                    Celular: {{ scope.row.chofer_celular }}
                  </p>
                  <p v-else>
                    Nombre: -
                    <br />
                    Celular: -
                  </p>
                  <span slot="reference">{{ scope.row.chofer }} <i class="el-icon-user-solid"></i></span>
                </el-popover>
              </div>
              <div v-else>
                <span slot="reference">{{ scope.row.chofer }} <i class="el-icon-user-solid"></i></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="total" label="Total" width="49"> </el-table-column>
          <el-table-column prop="Pendiente" label="Pendientes" width="88"> </el-table-column>
          <el-table-column prop="Entregada" label="Entregadas" width="88"> </el-table-column>
          <el-table-column prop="Rechazado" label="Rechazadas" width="91"> </el-table-column>
          <el-table-column prop="Reprogramada" label="Reprogramadas" width="117"> </el-table-column>
          <el-table-column prop="Retiro" label="Retiro" width="56"> </el-table-column>
          <el-table-column prop="Anulada" label="Anulada" width="71"> </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        v-if="total"
        background
        layout="prev, pager, next"
        :page-size="10"
        style="margin: 20px 0px; float: right"
        :total="total"
        @current-change="nextPage"
        :current-page.sync="pagina">
      </el-pagination>
    </div>
    <div v-else>
      <div class="vguias_buscar" style="padding-bottom: 0px !important">
        <el-row :gutter="10">
          <el-col>
            <el-tooltip placement="left">
              <div slot="content">Regresar a la búsqueda en Hoja de Ruta</div>
              <el-button
                type="primary"
                style="
                  float: right;
                  margin-bottom: 5px;
                  border-color: #409eff;

                  margin-bottom: 5px;
                  margin-top: -40px;
                "
                size="mini"
                @click="regresarBusqueda()">
                <i class="el-icon-arrow-left"></i> Regresar a Hoja de ruta
              </el-button>
            </el-tooltip>
          </el-col>
        </el-row>
      </div>
      <tabla-guias :tguias="tGuias" :hruta="buscar.hojaruta" :filtrohr="buscar"></tabla-guias>
    </div>
    <!--=================================================================================================-->
    <!--======================================= END ==================================================== -->
    <!--=================================================================================================-->
    <!-- Modal si la consulta a descargar es muy extensa -->
    <el-dialog :visible.sync="dialogExcelH" width="50%" :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="false" :show-close="false" :top="'2vh'">
      <el-row style="border-bottom: 1px dashed #ddd; margin-top: -25px; margin-bottom: 10px" :gutter="10">
        <el-col :md="14">
          <span style="float: left; font-size: 20px; text-aling: center"
            ><strong> <i class="el-icon-download"></i> Descargar hojas de rutas </strong></span
          >
        </el-col>
      </el-row>
      <p style="word-break: break-word">
        La descarga de resultados está limitada a 10.000 registros, su actual consulta arrojó un total de
        <strong style="color: #f76868">{{ numberTitle }}</strong> resultados.
      </p>
      <p style="word-break: break-word">Puede ajustar los resultados agregando nuevos filtros o reduciendo el rango de fechas seleccionadas.</p>
      <el-row style="padding: 5px !important">
        <center>
          <el-button type="success" icon="el-icon-download" @click="downloadSame" size="small" :loading="aceptar_load">Descargar 10.000 registros</el-button>
          <el-button @click="cancelarDownload" icon="el-icon-close" size="small" :disabled="blockCancelar">Cancelar</el-button>
        </center>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
import TablaGuias from '../guias/tablaGuias.vue'
import { EventBus } from '@/event-bus'
import { mapGetters, mapActions } from 'vuex'

const moment = require('moment')

export default {
  components: {
    TablaGuias,
  },
  data() {
    return {
      sucursales: [],
      codigo: false,
      destino: '',
      form: {
        reparto: null,
        subreparto: null,
        ver: null,
        tipo: null,
        hojaDeRuta: null,
      },
      total: 0,
      pagina: 1,
      filtroFechas: '',
      itemForm: 'mini',
      mostrarTable: false,
      loadingTable: false,
      loadingFiltr: false,
      loadingElimi: false,
      fullscreenLoading: true,
      excel: false,
      resumenHR: [],
      deshabilitar: true,
      disabledReparto: false,
      disabledHr: false,
      disabledNoRep: false,
      pickerOptions: {
        shortcuts: [
          {
            text: 'Últ. Semana',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Últ. Mes',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '3 Últ. meses',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      fecha_busqueda: '',
      loadingSuc: true,
      buscar: {
        comprob: '',
        terminal: '',
        nguia: '',
        numpres: '',
        cporte: '',
        fecha: [],
        codpos: '',
        razsoc: '',
        estado: 'x',
        pdestino: '',
        afinidad: 'x',
        ol_convenio: '',
        destino: '',
        presentado: '',
        guia_convenios: '',
        hrsuc: '',
        hojaruta: '',
        origen: '',
        id: '',
        convenio: '',
        provincia: '',
        pagina: '',
        lista: '',
        multi: '',
        sesion: { guia_convenios: '', ol_convenio: '' },
      },
      permisos_usuario: JSON.parse(window.localStorage.getItem('datos')).permisos,
      datos: JSON.parse(window.localStorage.getItem('datos')),
      // convenios: [],
      // conveniosInicio: [],
      pagina_now: 1,
      showTablaGuias: false,
      tGuias: [],
      empresa: window.localStorage.getItem('empresa'),
      imgGuia: '',
      styleTitle: {},
      dialogExcelH: false,
      aceptar_load: false,
      blockCancelar: false,
      numberTitle: '',
      back_tabla_hr: { background: '#D4EBD0  !important' },
      datos_select: null,
    }
  },
  created() {
    this.getSucursales()
    this.getPermisos()
    /* Color del título según la empresa logeada */
    this.styleTitle['backgroundColor'] = this.empresa == 'u' ? '#0b406a' : '#0b406a'
  },
  mounted() {
    this.clearFiltros
    if (typeof window.localStorage.getItem('guiasHR') != 'undefined' && window.localStorage.getItem('guiasHR') != null) {
      // alert(1)
      setTimeout(() => {
        this.recargarDetalleHR(JSON.parse(window.localStorage.getItem('guiasHR')))
        EventBus.$emit('cargandoGuias', () => {
          this.cargando = true
        })
      }, 100)
      setTimeout(() => {
        window.localStorage.removeItem('guiasHR')
      }, 900)
    } else {
      if (window.location.hash === '#/resumenhojaruta') {
        // alert(2)
        this.clearFiltros()
        // let extra = this.getFiltro
        // console.log(this.getFiltro)
        let extra = JSON.parse(window.localStorage.getItem('filtro_hoja_ruta')) != null ? JSON.parse(window.localStorage.getItem('filtro_hoja_ruta')) : null
        if (extra != null) {
          this.regresarBusqueda()
        }
      } else {
        // alert(3)
        // this.clearFiltros
        // window.localStorage.removeItem('select_hr')
        window.localStorage.removeItem('guiasHR')
      }
      this.fullscreenLoading = true
      setTimeout(() => {
        this.fullscreenLoading = false
      }, 2000)
      if (this.$route.params.hr != undefined) {
        // this.showTablaGuias = true;
        setTimeout(() => {
          this.recargarDetalleHR(this.$route.params)
          EventBus.$emit('cargandoGuias', () => {
            this.cargando = true
          })
        }, 100)
      } else if (this.$route.params.buscar) {
        this.getResumenHR2(1)
      }
    }
    EventBus.$on('PaginaHj', (p) => {
      this.pagina_now = p
      setTimeout(() => {
        this.verDetalleHR(this.rowDetalle)
        return false
      }, 100)
    })
  },
  beforeDestroy() {
    EventBus.$off('PaginaHj')
  },
  methods: {
    getPermisos() {
      if (this.empresa == 'u') {
        this.imgGuia = require('@/assets/ico-guias.png')
      } else if (this.empresa == 'lo') {
        this.imgGuia = require('@/assets/ico-guias-login.png')
      }
      if (this.permisos_usuario.indexOf('ADMIN') != -1) {
        this.buscar.lista = true
        this.buscar.multi = true
      } else {
        this.buscar.lista = this.permisos_usuario.indexOf('OL_LISTACONV') != -1 ? true : false
        this.buscar.multi = this.permisos_usuario.indexOf('OL_MULTI') != -1 ? true : false
      }

      this.buscar.sesion.guia_convenios = this.datos['guia_convenios']
      this.buscar.sesion.ol_convenio = this.datos['ol_convenio']
      /* Cargamos los convenios de acuerdo al permiso */
      // if (this.buscar.lista || this.buscar.multi) {
      //   this.getConvenios()
      // }
    },
    // getConvenios() {
    //   this.convenios = []
    //   this.conveniosInicio = []
    //   var lista = this.buscar.lista && !this.buscar.multi ? true : false
    //   HTTP.post('/guiasnew/getConvenios', {
    //     ol_lista: lista,
    //     ol_multi: this.buscar.multi,
    //     guia_convenios: this.datos['guia_convenios'],
    //   })
    //     .then((res) => {
    //       this.convenios = res.data
    //       this.conveniosInicio = res.data
    //     })
    //     .catch((error) => {
    //       this.convenios = []
    //       this.conveniosInicio = []
    //       console.log(error)
    //       this.$alert('No se pudo realizar la acción. Refresque la página')
    //     })
    // },
    getSucursales() {
      HTTP.post('/guiasNew/getSucursales', {
        codigo: this.codigo,
      })
        .then((res) => {
          this.sucursales = res.data
          this.loadingSuc = false
        })
        .catch((error) => {
          this.sucursales = []
          this.loadingSuc = false
          console.log(error)
          this.$alert('No se pudo realizar la acción. Refresque la página')
        })
    },

    getResumenHR2(page, datos_guardados = null) {
      if (datos_guardados != null) {
        this.filtroFechas = datos_guardados.fechas
        this.form = datos_guardados.form
        this.pagina = datos_guardados.pagina
        this.destino = datos_guardados.destino
      } else {
        this.pagina = page
      }
      this.mostrarTable = true
      this.loadingTable = true
      if (this.filtroFechas == null) {
        this.filtroFechas = ''
      }
      this.loadingFiltr = true
      window.scrollTo(0, 0)
      this.showTablaGuias = false
      HTTP.post('/guiasNew/getResumenHR2', {
        destino: this.destino,
        reparto: this.form.reparto,
        sub: this.form.subreparto,
        mostrar: this.form.ver,
        hr: this.form.hojaDeRuta,
        um: this.form.tipo,
        desde: this.filtroFechas[0],
        hasta: this.filtroFechas[1],
        pagina: this.pagina,
      }).then((res) => {
        this.loadingTable = false
        this.loadingFiltr = false
        this.loadingElimi = false
        this.resumenHR = res.data['resultado']
        if (res.data['resultado'].length > 0) {
          this.total = res.data['resultado'][0].TotalRows
        } else {
          this.total = 0
        }
        if (res.data['fecha'] != '') {
          this.fecha_busqueda = 'Fecha: ' + res.data['fecha'] + ' - ' + this.formatFecha(new Date())
        } else {
          this.fecha_busqueda = 'Fecha: ' + this.formatFecha(this.filtroFechas[0]) + ' - ' + this.formatFecha(this.filtroFechas[1])
        }
      })
    },
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    controlDisabledR() {
      this.disabledReparto = this.form.reparto.length ? true : false
      if (this.disabledReparto) {
        this.form.hojaDeRuta = null
      }
      this.disabledNoRep = !this.form.reparto.length ? true : false
      if (this.disabledNoRep) {
        this.form.subreparto = null
      }
    },
    controlDisabledHr() {
      this.disabledHr = this.form.hojaDeRuta.length ? true : false
      if (this.disabledHr) {
        this.form.reparto = null
        this.form.subreparto = null
        this.form.ver = null
        this.form.tipo = null
      }
    },
    filtrar() {
      this.pagina = 1

      this.loadingFiltr = true
      setTimeout(() => {
        this.total = 0
        this.getResumenHR2(this.pagina)
      }, 50)
    },
    eliminarFiltro() {
      let reparto = this.form.reparto != '' && this.form.reparto != null
      let subreparto = this.form.subreparto != '' && this.form.subreparto != null
      let ver = this.form.ver != '' && this.form.ver != null
      let tipo = this.form.tipo != '' && this.form.tipo != null
      let hojaDeRuta = this.form.hojaDeRuta != '' && this.form.hojaDeRuta != null

      if (reparto || subreparto || ver || tipo || hojaDeRuta) {
        this.form.reparto = null
        this.form.subreparto = null
        this.form.ver = null
        this.form.tipo = null
        this.form.hojaDeRuta = null
        this.filtroFechas = []
        this.getResumenHR2(1)
      }
    },
    ...mapActions('HojaRuta', ['setFiltro', 'setSelectHj', 'clearFiltros']),
    /* sesion las guías y mandarla a la tabla Guías */
    verDetalleHR(row) {
      this.rowDetalle = row
      // /*  Envió permisos OL_MULTI Y LISTA  y guias_convenio y ol_convenio*/
      this.buscar.hojaruta = row.hojaruta
      this.buscar.hrsuc = row.hrsuc
      this.buscar.pagina = this.pagina_now

      // window.localStorage.removeItem('select_hr')
      // Guardamos los datos de PÁGINA Y HOJA DE RUTA para marcarla en la búsqueda
      // window.localStorage.setItem('select_hr', JSON.stringify({ pagina: this.pagina, hr: row.hrsuc, hj: row.hojaruta }))
      // window.localStorage.setItem(
      //   'filtro_hoja_ruta',
      //   JSON.stringify({
      //     pagina: this.pagina,
      //     form: this.form,
      //     fechas: this.filtroFechas,
      //     destino: this.destino,
      //   })
      // )
      /* NUEVA PARTE CON VUEX */
      let pagina = this.pagina
      let destino = this.destino
      let fechas = this.filtroFechas
      let form = this.form
      let hj = row.hojaruta
      let hr = row.hrsuc
      this.setFiltro({ pagina, destino, fechas, form })
      this.setSelectHj({ pagina, hj, hr })
      /* -------------------- */
      EventBus.$emit('cargandoGuias', true)
      EventBus.$emit('datosHR', this.buscar)

      setTimeout(() => {
        HTTP.post('/guiasnew/getDatosTabla', this.buscar)
          .then((res) => {
            if (res.data.length == 0) {
              // Mostrar mensaje de que no se encontraron resultados
              this.tGuias = []
              this.showTablaGuias = true
            } else {
              this.showTablaGuias = true
              this.tGuias = res.data
            }
          })
          .catch((error) => {
            this.loadingTabla = false
            this.$alert('Error del servidor ' + error)
          })
      }, 200)
    },

    verDetalleHR_NUEVA(row) {
      this.rowDetalle = row
      // /*  Envió permisos OL_MULTI Y LISTA  y guias_convenio y ol_convenio*/
      this.buscar.hojaruta = row.hojaruta
      this.buscar.hrsuc = row.hrsuc
      this.buscar.pagina = this.pagina_now
      // window.localStorage.removeItem('select_hr')
      // Guardamos los datos de PÁGINA Y HOJA DE RUTA para marcarla en la búsqueda
      // window.localStorage.setItem('select_hr', JSON.stringify({ pagina: this.pagina, hr: row.hrsuc, hj: row.hojaruta }))
      // window.localStorage.setItem(
      //   'filtro_hoja_ruta',
      //   JSON.stringify({
      //     pagina: this.pagina,
      //     form: this.form,
      //     fechas: this.filtroFechas,
      //     destino: this.destino,
      //   })
      // )

      EventBus.$emit('cargandoGuias', { cargando: true, buscar: this.buscar })
      //
      setTimeout(() => {
        HTTP.post('/guiasnew/getDatosTabla', this.buscar)
          .then((res) => {
            if (res.data.length == 0) {
              // Mostrar mensaje de que no se encontraron resultados
              this.tGuias = []
              this.showTablaGuias = true
            } else {
              this.showTablaGuias = true
              this.tGuias = res.data
            }
          })
          .catch((error) => {
            this.loadingTabla = false
            this.$alert('Error del servidor ' + error)
          })
      }, 200)
    },
    recargarDetalleHR(datos) {
      /*  Envió permisos OL_MULTI Y LISTA  y guias_convenio y ol_convenio*/
      this.buscar.hojaruta = datos.hj
      this.buscar.hrsuc = datos.hr
      this.buscar.pagina = 1
      EventBus.$emit('cargandoGuias', true)
      setTimeout(() => {
        HTTP.post('/guiasnew/getDatosTabla', this.buscar)
          .then((res) => {
            if (res.data.length == 0) {
              // Mostrar mensaje de que no se encontraron resultados
              this.showTablaGuias = true
              this.tGuias = []
            } else {
              this.showTablaGuias = true
              this.tGuias = res.data
            }
          })
          .catch((error) => {
            this.loadingTabla = false
            this.$alert('Error del servidor ' + error)
          })
      }, 200)
    },
    nextPage(page) {
      this.getResumenHR2(page)
    },
    // Exporta el resumen de HR a excel OK!
    generarExcel() {
      this.excel = true
      HTTP.post('/guiasNew/getResumenExcel', {
        destino: this.destino,
        reparto: this.form.reparto,
        sub: this.form.subreparto,
        mostrar: this.form.ver,
        hr: this.form.hojaDeRuta,
        um: this.form.tipo,
        desde: this.filtroFechas[0],
        hasta: this.filtroFechas[1],
      }).then((res) => {
        this.excel = false

        if (Number.isInteger(res.data)) {
          // Mostrar modal
          this.dialogExcelH = true
          this.numberTitle = res.data
        } else {
          window.location.href = res.data
        }
      })
    },
    regresarBusqueda() {
      this.showTablaGuias = false
      // let hoja_busqueda = JSON.parse(window.localStorage.getItem('select_hr'))
      // let extra = JSON.parse(window.localStorage.getItem('filtro_hoja_ruta')) != null ? JSON.parse(window.localStorage.getItem('filtro_hoja_ruta')) : null
      this.datos_select = hoja_busqueda
      let hoja_busqueda = this.getSelectHj
      let extra = this.getFiltro
      this.datos_select = hoja_busqueda
      // window.localStorage.removeItem('filtro_hoja_ruta')
      this.getResumenHR2(hoja_busqueda['pagina'], extra)
    },
    downloadSame() {
      this.buscar.askDescargar = 1
      this.aceptar_load = true
      this.blockCancelar = true
      HTTP.post('/guiasNew/getResumenExcel', {
        destino: this.destino,
        reparto: this.form.reparto,
        sub: this.form.subreparto,
        mostrar: this.form.ver,
        hr: this.form.hojaDeRuta,
        um: this.form.tipo,
        desde: this.filtroFechas[0],
        hasta: this.filtroFechas[1],
        descargar: 1,
      })
        .then((res) => {
          window.location.href = res.data
          setTimeout(() => {
            ;(this.aceptar_load = false), (this.blockCancelar = false)
            this.dialogExcelH = false
          }, 200)
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
        })
    },
    cancelarDownload() {
      this.dialogExcelH = false
    },
    tableRowClassName({ row }) {
      if (this.datos_select != null) {
        // Traigo los datos guardados de la hoja de ruta seleccionada
        let hojaruta = this.datos_select.hj
        let hrsuc = this.datos_select.hr

        if (row.hrsuc === hrsuc && row.hojaruta === hojaruta) {
          return this.back_tabla_hr
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    limpiarTabla() {
      if (this.resumenHR.length) {
        this.resumenHR = []
        this.total = 0
        this.fecha_busqueda = ''
        this.mostrarTable = false
      }
    },
  },
  computed: {
    ...mapGetters('HojaRuta', ['getFiltro', 'getSelectHj']),
  },
  watch: {
    destino: function () {
      if (this.destino != '') {
        this.deshabilitar = false
      }
    },
  },
  filters: {
    moment1: function (date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    moment2: function (date) {
      return moment(date).format('DD/MM/YYYY')
    },
  },
}
</script>
<style>
.select-suc {
  width: 270px;
  padding: 0px;
  margin: 0px;
}
@media (max-width: 768px) {
  .select-suc {
    width: 100%;
    padding: 0px;
  }
}
.el-form--inline.el-form--label-top .el-form-item__content {
  display: block;
  margin-top: -10px !important;
}
</style>
