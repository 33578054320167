<template>
    <div>
        <h1 style="text-align:center" :style="styleTitle">Error</h1>
        <h3 style="text-align:center">No cuenta con los permisos necesarios para poder ingresar </h3>
        <div class="picture" style="text-align:center">
            <img v-if="empresa=='u'" class="img-fluid" style="width:35%;" src="@/assets/001-permiso.png">
            <img v-if="empresa=='lo'" class="img-fluid" style="width:35%;" src="@/assets/002-permiso-1.png">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            empresa :window.localStorage.getItem("empresa"),
            styleTitle:{}
        }
    },
    mounted() {
        this.styleTitle['color'] = this.empresa == 'lo' ? '#e45303' :'#0B406A';
    },
}
</script>