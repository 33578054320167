<template>
  <div v-loading="sistema.loading">
    <guias-filter :convenios="this.getGuiasConfig.convenios_full" />
    <el-row :gutter="10">
      <el-col :span="24" v-show="this.sistema.datos">
        <div class="table-responsive-sm">
          <vuetable
            ref="vuetable"
            api-url="/cross/guias"
            :http-fetch="myFetch"
            :fields="fields"
            pagination-path=""
            :css="css.table"
            :sort-order="sortOrder"
            :multi-sort="true"
            :append-params="moreParams"
            :per-page="20"
            :row-class="onRowClass"
            detail-row-component="guias-comprobantes-bultos-details"
            @vuetable:cell-clicked="onCellClicked"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:loading="showLoader"
            @vuetable:loaded="hideLoader">
            <template slot="cobranza" slot-scope="props">
              <p v-if="props.rowData.cobranza === 1">SI</p>
              <p v-else>NO</p>
            </template>
            <template slot="cantidad_comprobantes" slot-scope="props">
              <el-tag>{{ props.rowData.cantidad_comprobantes }}</el-tag>
            </template>
            <template slot="cantidad_bultos" slot-scope="props">
              <el-tag type="success">{{ props.rowData.cantidad_bultos }}</el-tag>
            </template>
            <template slot="valor_cobranza" slot-scope="props">
              <p>{{ formatMoney(props.rowData.valor_cobranza) }}</p>
            </template>
            <template slot="total_valor_declarado" slot-scope="props">
              <p>{{ formatMoney(props.rowData.total_valor_declarado) }}</p>
            </template>
            <template slot="guia" slot-scope="props">
              <div @click="mostrarDetalles(props.rowData.id)">
                {{ props.rowData.terminal.toString().padStart(5, '0') }} -
                {{ props.rowData.numero.toString().padStart(8, '0') }}
              </div>
            </template>
            <template slot="estado" slot-scope="props">
              <div style="cursor: pointer" @click="mostrarLogs(props.rowData)" v-html="tagEstado(props.rowData)" />
            </template>
            <template slot="created_at" slot-scope="props">
              <div @click="mostrarDetalles(props.rowData.id)">
                {{ formatDate(props.rowData.created_at, 'DD-MM-YYYY HH:mm:ss') }}
              </div>
            </template>
          </vuetable>
        </div>
        <div class="el-pagination is-background" style="margin-top: 20px; float: right; padding-bottom: 1rem">
          <vuetable-pagination-info ref="paginationInfo" info-class="pagination-info" info-template="Mostrando de {from}-{to} registros de un total de {total}"></vuetable-pagination-info>
          <vuetable-pagination ref="pagination" :css="css.pagination" @vuetable-pagination:change-page="onChangePage"> </vuetable-pagination>
        </div>
      </el-col>
      <el-col :span="24" v-show="!this.sistema.datos">
        <el-alert type="info" show-icon :closable="false" center>
          <span slot="title">
            <p>No se encontraron guias asociadas a la busqueda.</p>
          </span>
        </el-alert>
      </el-col>
    </el-row>
    <guias-logs-details v-if="this.sistema.modal.visible" :modal="this.sistema.modal" />
  </div>
</template>
<script>
import { API } from '@/plugins/api'
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import accounting from 'accounting'
import GuiasComprobantesBultosDetails from './GuiasComprobantesBultosDetails'
import GuiasFilter from './Filter'
import GuiasActions from './Actions'
import moment from 'moment'
import GuiasLogsDetails from './GuiasLogsDetails'
import { mapGetters } from 'vuex'
import { css } from '@/components/cross/_guias'
import { fields, sortOrder, moreParams } from './_fields'
Vue.component('guias-comprobantes-bultos-details', GuiasComprobantesBultosDetails)
Vue.component('guias-consultas-actions', GuiasActions)

export default {
  name: 'GuiasTable',
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    GuiasFilter,
    GuiasLogsDetails,
  },
  created() {
    this.moreParams.convenio_select = this.getGuiasConfig.convenios
  },
  mounted() {
    setTimeout(() => {
      this.sistema.loading = false
    }, 500)
  },
  data() {
    return {
      fields,
      css,
      sortOrder,
      moreParams,
      sistema: {
        loading: true,
        datos: false,
        modal: {
          visible: false,
          datos: null,
        },
      },
    }
  },
  methods: {
    async myFetch(apiUrl, httpOptions) {
      const datos = await API.get(apiUrl, httpOptions)

      if (datos.data.data.data.length > 0) this.sistema.datos = true
      else this.sistema.datos = false

      return datos.data
    },
    formatMoney(value) {
      if (value == null) return '-'
      else return accounting.formatMoney(value, '$', '0', '.')
    },
    formatDate(value, fmt = 'D MMM YYYY') {
      return value == null ? 'Sin registro' : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt)
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    onCellClicked(data) {
      if (this.$refs.vuetable.isVisibleDetailRow(data.id)) {
        this.$refs.vuetable.hideDetailRow(data.id)
      } else {
        this.$refs.vuetable.visibleDetailRows = []
        this.$refs.vuetable.showDetailRow(data.id)
      }
    },
    showLoader() {
      this.sistema.loading = true
    },
    hideLoader() {
      this.sistema.loading = false
    },
    onRowClass(dataItem) {
      //index
      if (this.$refs.vuetable.isVisibleDetailRow(dataItem.id)) {
        //console.log("Abierto: "+dataItem.id)
        return 'selected-row'
      }
    },
    mostrarDetalles(value) {
      this.$events.fire('mostrar-detalles', value)
    },
    tagEstado(row) {
      if (row.fch_proc !== null) return "<span class='el-tag el-tag--success'>PROCESADO</span>"
      if (row.logs.length > 0) return "<span class='el-tag el-tag--danger'>ERROR</span>"
      else return "<span class='el-tag el-tag--warning'>PENDIENTE</span>"
    },
    mostrarLogs(row) {
      if (row.fch_proc !== null) return this.$swal('Guia procesada', moment(row.fch_proc, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY || HH:mm:ss'), 'success')
      if (row.logs.length > 0) {
        //Tomo ultimo log
        //return this.$swal('Error detectado:', row.logs[row.logs.length - 1].observa, 'error')
        this.sistema.modal.datos = row.logs
        this.sistema.modal.visible = true
      } else return this.$swal('Guia pendiente de procesamiento', 'Sin info para mostrar', 'warning')
    },
  },
  events: {
    'filter-set'(filtros) {
      this.moreParams = filtros
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'filter-reset'() {
      this.moreParams = {
        convenio_select: this.getGuiasConfig.convenios,
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    refrescarTabla() {
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'mostrar-detalles'(value) {
      if (this.$refs.vuetable.isVisibleDetailRow(value)) {
        this.$refs.vuetable.hideDetailRow(value)
      } else {
        this.$refs.vuetable.visibleDetailRows = []
        this.$refs.vuetable.showDetailRow(value)
      }
    },
    'mostrar-detalles-modal'(datos) {
      this.sistema.modal.datos = datos
      this.sistema.modal.visible = true
    },
    'cerrar-detalles-modal'() {
      this.sistema.modal.datos = null
      this.sistema.modal.visible = false
    },
  },
  computed: {
    ...mapGetters('Crossdocking', ['getGuiasConfig']),
  },
}
</script>
