<template>
  <div v-if="rowData.estado == 0">
    <el-button-group>
      <el-tooltip class="item" effect="dark" content="Editar" placement="top-start">
        <el-button size="mini" @click="editTurno(rowData)" icon="el-icon-edit" type="success"></el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="Eliminar" placement="top-start">
        <el-button size="mini" @click="eliminarTurno(rowData.id)" icon="el-icon-delete" type="danger"></el-button>
      </el-tooltip>
    </el-button-group>
  </div>
  <div v-else-if="rowData.estado == 1">
    <el-tooltip class="item" effect="dark" content="Cancelar" placement="top-start">
      <el-button size="mini" @click="cancelarTurno(rowData.id)" plain type="danger"><i class="fas fa-ban"></i></el-button>
    </el-tooltip>
  </div>
  <div v-else @click="mostrarDetalles(rowData.id)">
    <el-button-group>
      <el-tag size="mini">Sin acciones</el-tag>
    </el-button-group>
  </div>
</template>
<script>
import { API } from '@/plugins/api'
import { supabase } from '@/plugins/supabase'
export default {
  name: 'turnosActions',
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },
  methods: {
    editTurno() {
      this.$events.fire('open-form-turno', 'EDITAR', this.rowData)
    },
    eliminarTurno(id) {
      this.$swal({
        title: '¿Desea eliminar el Turno: #' + id + ' ?',
        text: 'Recuerde que no podrá revertir esta acción',
        icon: 'warning',
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Borrar Turno',
        cancelButtonText: 'Salir',
        preConfirm: () => {
          return API.delete('turnero/turnos/' + id)
            .then((response) => {
              //console.log(response.data.message)
              this.$events.fire('refrescar-tabla')
              return this.$swal.fire(response.data.message, '', 'success')
            })
            .catch((error) => {
              //console.log(error.data.message)
              return this.$swal(error.response.data.message, 'No se realizó la acción', 'error')
            })
        },
      }).then((result) => {
        if (!result.value) this.$swal('Accion Cancelada', 'No se realizo ninguna accion', 'info')
      }) //Fin de THEN del SWAL
    },
    cancelarTurno(id) {
      this.$swal({
        title: '¿Desea cancelar el Turno: #' + id + ' ?',
        text: 'Recuerde que no podrá revertir esta acción',
        icon: 'warning',
        input: 'textarea',
        inputPlaceholder: 'Motivo de la cancelación del turno. (150 caracteres como maximo)',
        showCancelButton: true,
        showCloseButton: true,
        //confirmButtonColor: '#309ED5',
        //cancelButtonColor: '#f86c6b',
        confirmButtonText: 'Cancelar Turno',
        cancelButtonText: 'Salir',
        preConfirm: async (result) => {
          try {
            const response = await API.post('turnero/turnos/change', {
              turno_id: id,
              estado: 99,
              estado_viejo: 1,
              cancelacion: result,
            })
            try {
              console.log('update supa - cancelado')
              await supabase.from(process.env.VUE_APP_SUPABASE_TABLE).delete().eq('turno_id', id)
            } catch (error) {
              console.log(error)
            }
            this.$events.fire('refrescar-tabla')
            return this.$swal.fire(response.data.message, '', 'success')
          } catch (error) {
            return this.$swal(error.response.data.message, 'No se realizó la acción', 'error')
          }
        },
      }).then((result) => {
        if (!result.value) this.$swal('Accion Cancelada', 'No se realizo ninguna accion', 'info')
      })
    },
    mostrarDetalles(value) {
      this.$events.fire('mostrar-detalles', value)
    },
  },
}
</script>
