<template>
  <el-dialog
    :visible.sync="modal.visible"
    width="80%"
    @closed="cerrarForm()"
    :close-on-click-modal="false"
    style="padding-top: 0px !important"
    :close-on-press-escape="false"
    :destroy-on-close="false"
    top="5vh"
    :show-close="false">
    <span slot="title">
      <h3 style="text-align: center"><i :class="config.icon"></i> {{ config.titulo }}</h3>
    </span>
    <el-form size="small" :model="agenda" :rules="rules" ref="turnoFormRef" label-width="180px" v-loading="config.loading">
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Seleccione un convenio:" prop="convenios" v-if="this.config.tipo === 'ALTA'">
            <div class="el-select el-select--small" style="width: 100%; line-height: 0px !important">
              <v-select
                id="convenios"
                name="convenios"
                ref="convenioRef"
                v-model="selectConvenio"
                :options="convenios"
                :filter="fuseSearch"
                :getOptionLabel="(option) => option.nombre"
                placeholder="Seleccione un convenio"
                @input="setSelected">
                <span slot="no-options">Sin Coincidencia</span>
                <template #option="{ nombre }">
                  <cite>{{ nombre }}</cite>
                </template>
              </v-select>
            </div>
          </el-form-item>
          <el-form-item label="Convenio:" prop="convenios" v-else>
            <el-input id="convenios" name="convenios" type="text" readonly :value="selectConvenio.nombre" />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Proveedor de Convenio:" prop="proveedor_convenio">
            <el-input id="proveedor_convenio" name="proveedor_convenio" v-model="agenda.proveedor_convenio" :maxlength="60" placeholder="Ingrese el nombre o razon social" clearable />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="¿Proveedor de descarga?" prop="check_cliente">
            <el-select id="check_cliente" name="check_cliente" style="width: 100%" v-model="agenda.check_cliente" placeholder="Seleccione una opción" @change="habilitarDetalles()">
              <el-option v-for="item in config.booleano" :key="item.value" :label="item.texto" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Detalles:" prop="check_cliente_detalles">
            <el-input
              ref="refClienteDetalles"
              id="check_cliente_detalles"
              name="check_cliente_detalles"
              type="textarea"
              :rows="3"
              :maxlength="100"
              placeholder="Detalles del proveedor de descarga que ingresara al predio."
              v-model="agenda.check_cliente_detalles"
              :disabled="config.inputClienteDetalles">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="¿Necesita Frio?" prop="check_frio">
            <el-select id="check_frio" name="check_frio" style="width: 100%" v-model="agenda.check_frio" placeholder="Seleccione una opcion">
              <el-option v-for="item in config.booleano" :key="item.value" :label="item.texto" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="¿Cliente sin turno?" prop="check_sin_turno">
            <el-select id="check_sin_turno" name="check_sin_turno" style="width: 100%" v-model="agenda.check_sin_turno" placeholder="Seleccione una opcion">
              <el-option v-for="item in config.booleano" :key="item.value" :label="item.texto" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Tipo Unidad" prop="tipo_unidad">
            <el-select id="tipo_unidad" style="width: 100%" v-model="agenda.tipo_unidad" placeholder="Seleccione un tipo de unidad">
              <el-option v-for="item in config.unidades" :key="item.value" :label="item.value" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Tipo Descarga" prop="tipo_descarga">
            <el-select id="tipo_descarga" style="width: 100%" v-model="agenda.tipo_descarga" placeholder="Seleccione el tipo de descarga">
              <el-option v-for="item in config.descargas" :key="item.value" :label="item.value" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 24 }">
          <el-form-item label="Contacto:" prop="contacto_nombre">
            <el-input id="contacto_nombre" name="contacto_nombre" v-model="agenda.contacto_nombre" :maxlength="60" placeholder="Apellido y nombre del contacto" clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Celular:" prop="contacto_telefono">
            <el-input
              id="contacto_telefono"
              name="contacto_telefono"
              type="text"
              :maxlength="11"
              prefix-icon="el-icon-phone"
              v-model="agenda.contacto_telefono"
              placeholder="Numero de celular"
              @input="cleanInput()"
              clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="E-mail:" prop="contacto_mail">
            <el-input id="contacto_mail" name="contacto_mail" prefix-icon="el-icon-message" :maxlength="50" v-model="agenda.contacto_mail" placeholder="Email contacto" clearable />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button type="success" size="medium" @click="saveAgenda('turnoFormRef')" icon="el-icon-check" :loading="btnDisabled">Guardar</el-button>
      <el-button type="danger" size="medium" @click="cerrarForm()" icon="el-icon-close">Cerrar</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { API } from '@/plugins/api'
import { unidades, descargas, booleano } from '@/components/turnos/_auxiliar'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import moment from 'moment'
import { rules } from './_AuxAgenda'
export default {
  name: 'form-agenda-modal',
  components: { vSelect },
  props: {
    modal: { required: true },
  },
  created() {
    this.agenda.fecha = moment().format('YYYY-MM-DD')
    this.convenios = this.getTurneroConfig.convenios_all
    const arrayAgendas = this.getTurneroConfig.agenda

    this.convenios = this.convenios.map((convenioItem) => {
      const agendaCoincidente = arrayAgendas.find((item) => item.convenio_id === convenioItem.convenio)
      return agendaCoincidente ? { ...convenioItem, agenda: agendaCoincidente } : { ...convenioItem, agenda: null }
    })
  },
  mounted() {
    switch (this.modal.tipo) {
      case 'ALTA':
        this.config.icon = 'el-icon-circle-plus'
        this.config.titulo = 'NUEVO CONTACTO'
        this.config.loading = false
        this.config.tipo = 'ALTA'
        break
      case 'EDITAR':
        this.config.icon = 'el-icon-circle-plus'
        if (this.modal.row.convenio_id === null) this.config.titulo = 'EDITAR CONTACTO : ' + this.getImportadora.nombre
        else this.config.titulo = 'EDITAR CONTACTO CONVENIO: #' + this.modal.row.convenio_id
        this.cargarForm(this.modal.row)
        break
    }
  },
  data() {
    return {
      btnDisabled: false,
      config: {
        loading: true,
        icon: null,
        titulo: null,
        hora_inicial: true,
        tipo: 'ALTA',
        unidades,
        descargas,
        booleano,
        inputClienteDetalles: true,
      },
      configFecha: {
        disabledDate(date) {
          return date.getTime() <= moment().subtract(1, 'days')
        },
      },
      agenda: {
        id: null,
        convenio_id: null,
        proveedor_convenio: null,
        check_cliente: 0,
        check_cliente_detalles: null,
        check_frio: 0,
        check_sin_turno: 0,
        tipo_unidad: null,
        tipo_descarga: null,
        contacto_nombre: null,
        contacto_mail: null,
        contacto_telefono: null,
      },
      selectConvenio: null,
      convenios: [],
      rules,
    }
  },
  methods: {
    cerrarForm() {
      this.$events.fire('close-form')
    },
    cleanInput() {
      this.agenda.contacto_telefono = this.agenda.contacto_telefono.replace(/[^a-zA-Z0-9]/g, '')
    },
    saveAgenda(formName) {
      this.btnDisabled = true
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          this.btnDisabled = false
          return this.$message({
            message: 'Por favor. Controle los campos obligatorios.',
            type: 'warning',
          })
        }
        if (this.selectConvenio == null) {
          this.btnDisabled = false
          return this.$message({
            message: 'Debe seleccionar un convenio.',
            type: 'warning',
          })
        }
        //Control en caso de tener proveedor de descarga
        if (this.agenda.check_cliente === 1 && this.agenda.check_cliente_detalles === null) {
          this.btnDisabled = false
          return this.$message({
            message: 'Debe completar los detalles del proveedor de descarga',
            type: 'warning',
          })
        }

        switch (this.modal.tipo) {
          case 'ALTA':
            API.post('turnero/agendas', this.agenda)
              .then((response) => {
                this.cerrarForm()
                this.$message({
                  message: response.data.message,
                  type: 'success',
                })
                this.$events.fire('refrescar-tabla')
              })
              .catch((error) => {
                //console.log(error)
                this.$message({
                  message: error.response.data.message,
                  type: 'error',
                })
                this.btnDisabled = false
              })
            break
          case 'EDITAR':
            API.put('turnero/agendas/' + this.modal.row.id, this.agenda)
              .then((response) => {
                this.cerrarForm()
                this.$message({
                  message: response.data.message,
                  type: 'success',
                })
                this.$events.fire('refrescar-tabla')
              })
              .catch((error) => {
                //console.log(error)
                this.$message({
                  message: error.response.data.message,
                  type: 'error',
                })
                this.btnDisabled = false
              })
            break
        }
      })
    },
    cargarForm(row) {
      API.get('turnero/agendas/' + row.id)
        .then((response) => {
          const agenda = response.data.data
          //Convenio
          var objIndex = this.convenios.findIndex((obj) => obj.convenio == agenda.convenio_id)
          this.selectConvenio = this.convenios[objIndex]
          this.agenda.convenio_id = agenda.convenio_id
          this.agenda.proveedor_convenio = agenda.proveedor_convenio
          //Datos
          this.agenda.id = agenda.id
          this.agenda.check_cliente = agenda.check_cliente

          if (this.agenda.check_cliente === 1) {
            this.config.inputClienteDetalles = false
            this.agenda.check_cliente_detalles = agenda.check_cliente_detalles
          }
          this.agenda.check_frio = agenda.check_frio
          this.agenda.check_sin_turno = agenda.check_sin_turno
          this.agenda.tipo_unidad = agenda.tipo_unidad
          this.agenda.tipo_descarga = agenda.tipo_descarga
          this.agenda.contacto_nombre = agenda.contacto_nombre
          this.agenda.contacto_mail = agenda.contacto_mail
          this.agenda.contacto_telefono = agenda.contacto_telefono

          this.config.tipo = 'EDITAR'
          this.config.loading = false
        })
        .catch((error) => {
          this.$message({
            message: error.response.data.message,
            type: 'error',
          })
          this.config.loading = false
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['nombre'],
        shouldSort: true,
        minMatchCharLength: 1,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    setSelected() {
      //console.log(this.selectConvenio)
      if (this.selectConvenio.agenda !== null) {
        this.selectConvenio = null
        return this.$message({
          message: 'El convenio seleccionado ya tiene datos precargados, para editarlos buscar en la tabla y hacer click en el boton editar',
          type: 'warning',
        })
      } else {
        this.agenda.convenio_id = this.selectConvenio.convol
      }
    },
    habilitarDetalles() {
      this.agenda.check_cliente_detalles = null
      if (this.agenda.check_cliente === 1) {
        this.config.inputClienteDetalles = false
        this.$nextTick(() => this.$refs.refClienteDetalles.focus())
      }
      if (this.agenda.check_cliente === 0) {
        this.config.inputClienteDetalles = true
      }
    },
  },
  computed: {
    ...mapGetters('Turnos', ['getTurneroConfig']),
    ...mapGetters('Turnos', ['getImportadora']),
  },
}
</script>
