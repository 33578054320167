<template>
  <div>
    <!-- Formulario de Búsqueda -->
    <el-row :gutter="10">
      <el-col :span="24">
        <el-button-group style="float: right">
          <el-tooltip class="item" effect="dark" content="Exportar Choferes" placement="bottom-start">
            <el-button type="primary" @click="generarExcelChoferes" :loading="loadingChofer" :disabled="btnDisabled" :style="styleBtn" plain size="mini" icon="el-icon-download">
              Exportar Choferes
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="Exportar Ingreso" placement="bottom-start">
            <el-button
              type="primary"
              @click="generarExcel"
              style="background-color: #e5590ae0"
              :loading="loadingExcel"
              :disabled="btnDisabled"
              :style="styleBtn2"
              plain
              size="mini"
              icon="el-icon-download">
              Exportar App. Ingreso
            </el-button>
          </el-tooltip>
        </el-button-group>
      </el-col>
    </el-row>
    <br />
    <el-form :label-position="'top'">
      <el-row :gutter="10">
        <el-col :md="4" :sm="4" :xs="12">
          <label for="" class="vpim-label">Seleccionar opción: </label>
          <el-select v-model="clave" :disabled="loading" placeholder="Buscar por..." size="mini">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
        <el-col :md="6" :sm="4" :xs="24">
          <label for="" class="vpim-label">Ingrese búsqueda:</label>
          <el-input
            id="caja_txt"
            placeholder=""
            v-model="valor"
            :disabled="loading || !clave.length | conversion"
            size="mini"
            ref="inputValor"
            @keypress.native.enter="busquedaPor()"></el-input>
        </el-col>
        <el-col :md="2" :sm="4" :xs="8">
          <el-button icon="el-icon-search" rounded style="margin-top: 19px" @click="busquedaPor()" size="mini" :loading="loadingBus" :disabled="!valor.length">Buscar </el-button>
        </el-col>
        <el-col :md="12" :sm="8" :xs="16" style="float: left">
          <center>
            <el-button-group style="float: left">
              <el-button :type="tipoBtn" size="mini" plain style="margin-top: 19px" @click="eliminarFiltro" :disabled="!filtro" :loading="loadingFil"
                ><i class="el-icon-delete"></i> Eliminar filtro
              </el-button>
              <el-button v-if="permisos.app_user" type="primary" size="mini" style="margin-top: 19px" @click="abrirModalCrearUsuario()" :disabled="btnDisabled" :style="styleBtn"
                ><i class="el-icon-circle-plus-outline"> </i> Crear usuario
              </el-button>
            </el-button-group>
          </center>
        </el-col>
      </el-row>
    </el-form>
    <!-- <el-row>
            <el-col >
                <el-tooltip placement="bottom">
                <div slot="content">Descargar Choferes</div>
                <el-button style="float:right;" type="primary" size="mini" @click="generarExcel"
                :loading	="loadingExcel" :disabled="btnDisabled" :style="styleBtn"
                ><i class="el-icon-download"></i> Exportar Choferes</el-button>
                </el-tooltip>
            </el-col>
        </el-row> -->
    <!-- <br /> -->
  </div>
</template>
<script>
import { HTTP } from '@/axios'
const moment = require('moment')
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      mostrar: false,
      loadPrincipal: true,
      activeIndex: '1',
      mensaje: '',
      pagina: 1,
      clave: 'dni',
      valor: '',
      filtro: false,
      loading: false,
      loadingFil: false,
      loadingBus: false,
      loadingExcel: false,
      loadingChofer: false,
      sucursales: [],
      sucursalUser: '',
      options: [
        {
          value: 'uuid',
          label: 'UUID',
        },
        {
          value: 'nombre',
          label: 'Nombre',
        },
        {
          value: 'dni',
          label: 'DNI',
        },
      ],
      exportar: false,
      permisos_usuario: JSON.parse(window.localStorage.getItem('datos')).permisos,
      permisos: { app_rfo: false, app_user: false },
      btnDisabled: false,
      styleBtn: { borderColor: '#02172e00', color: 'white' },
      styleBtn2: { borderColor: '#02172e00', color: 'white' },
      empresa: window.localStorage.getItem('empresa'),
      tipoBtn: 'info',
    }
  },
  created() {
    setTimeout(() => {
      this.mostrar = true
    }, 500)
    this.permisos_app()
    //   this.getDispositivos();
  },
  mounted() {
    /* Cargar los datos al principio */
    EventBus.$on('cargarChoferes', (f) => {
      this.pagina = 1
      if (f) {
        this.getDispositivos(1)
      }
    })
    /*  */
    EventBus.$on('closeDetalles', (f) => {
      this.pagina = 1
      if (f) {
        this.getDispositivos(1)
      }
    })
    /* CAMBIAR NOMBRE DE EVENTO */
    EventBus.$on('nuevaPaginaAdmin', (p) => {
      setTimeout(() => {
        this.getDispositivos(p)
        return false
      }, 100)
    })
  },
  beforeDestroy() {
    EventBus.$off('closeDetalles')
    EventBus.$off('nuevaPaginaAdmin')
    EventBus.$off('cargarChoferes')
  },
  methods: {
    permisos_app() {
      this.permisos.app_rfo = this.permisos_usuario.indexOf('APP_RFO') != -1 ? true : false
      this.permisos.app_user = this.permisos_usuario.indexOf('APP_USUARIOS') != -1 ? true : false
      if (this.empresa == 'lo') {
        this.styleBtn['backgroundColor'] = '#e5590a'
        this.styleBtn2['backgroundColor'] = '#e5590ae6'
        this.tipoBtn = 'warning'
      } else {
        this.styleBtn['backgroundColor'] = '#0b406a'
        this.styleBtn2['backgroundColor'] = '#0b406ad6'
        this.tipoBtn = 'primary'
      }
    },
    generarExcel() {
      setTimeout(() => {
        this.loadingExcel = true
        HTTP.post('/guiasNew/excelDispositivos', { tipo: 'ingreso' })
          .then((res) => {
            this.loadingExcel = false
            // console.log(res.data)
            if (!res.data || res.data == null) {
              this.$message({
                message: 'No hay registros para descargar',
                type: 'warning',
              })
            } else {
              window.location.href = res.data
            }
          })
          .catch((error) => console.log(error))
      }, 100)
    },
    generarExcelChoferes() {
      setTimeout(() => {
        this.loadingChofer = true
        HTTP.post('/guiasNew/excelDispositivos', {
          tipo: 'choferes',
          clave: this.clave,
          valor: this.valor,
        })
          .then((res) => {
            this.loadingChofer = false
            console.log(res.data)
            if (Number.isInteger(res.data)) {
              this.dialogStock = true
              this.numberTitle = res.data
            } else if (res.data != '') {
              window.location.href = res.data
            } else {
              this.$message({
                message: 'No hay datos para descargar.',
                type: 'warning',
              })
            }
          })
          .catch((error) => console.log(error))
      }, 100)
    },
    eliminarFiltro() {
      this.valor = ''
      this.clave = ''
      this.loadingFil = true
      this.loading = true
      this.filtro = false
      setTimeout(() => {
        this.loading = false
        this.loadingFil = false
        EventBus.$emit('limpiarTabla', true)
        this.getDispositivos(1)
      }, 1000)
    },
    abrirModalCrearUsuario() {
      EventBus.$emit('abrirModal', true)
    },
    getDispositivos(pagina) {
      EventBus.$emit('cargando', 0)
      this.pagina = pagina
      this.btnDisabled = true
      HTTP.post('/guiasNew/getDispositivos', {
        clave: this.clave,
        valor: this.valor,
        pagina: this.pagina,
      })
        .then((res) => {
          this.loading = false
          this.loadingBus = false
          this.loadingFil = false
          this.btnDisabled = false
          if (res.data.length == 0) {
            EventBus.$emit('cargarUsuarios', { tabla: 0, pagina: 0 })
            this.filtro = true
          } else {
            EventBus.$emit('cargarUsuarios', {
              tabla: res.data,
              pagina: this.pagina,
            })
            this.exportar = true
            this.filtro = true
            // EventBus.$emit("pagina",this.buscar.pagina);
          }
        })
        .catch((error) => console.log(error))
    },
    busquedaPor() {
      this.loadingBus = true
      this.loading = true
      this.getDispositivos(1)
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD-MM-YYYY H:mm:ss')
    },
    fecha: function (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    desde: function (date) {
      return moment(date).fromNow()
    },
    conversion: function (date) {
      return date ? true : false
    },
  },
}
</script>
<style scoped>
.r_button {
  width: 100%;
}
.r_button:last-child {
  width: 100%;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  /* padding: 0; */
}

a {
  color: #42b983;
}
.right-sep {
  /* padding: 1em 2em; */
  border-right: 3px solid rgb(224, 222, 222);
}
.el-form-item--small .el-form-item__content,
.el-form-item--small .el-form-item__label {
  line-height: 32px;
  /* padding-right: 10px; */
}
.loading {
  min-height: 100vh;
  background: white;
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
* Generated by Animista on 2020-12-21 15:22:19
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation slide-in-bottom
* ----------------------------------------
*/
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:hover,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:active {
  background-color: #c0c4cc !important;
}
</style>
