<template>
  <div>
    <el-row :gutter="10">
      <el-form :label-position="'top'" size="small" :model="formFacturas" class="demo-form-inline">
        <el-col :md="6" :sm="8">
          <el-form-item label="Fecha">
            <el-date-picker
              v-model="formFacturas.fecha"
              type="daterange"
              start-placeholder="Desde"
              end-placeholder="Hasta"
              size="mini"
              value-format="yyyyMMdd"
              format="dd-MM-yyyy"
              style="width: 100%"
              @change="cambiarDatos()"
              :disabled="loading_facturas"
              :picker-options="datePickerFactura">
            </el-date-picker>
            <div class="sub-title" style="font-size: 10px; margin-top: -5px">Por defecto toma última semana.</div>
          </el-form-item>
        </el-col>
        <el-col :md="6" :sm="8">
          <el-form-item label="Comprobante">
            <el-input
              size="mini"
              v-model="formFacturas.comprobante"
              :disabled="loading_facturas"
              class="uppertext"
              placeholder="Comprobante"
              @blur="cambiarDatos()"
              minlength="13"
              maxlength="13"
              clearable></el-input>
            <div class="sub-title" style="font-size: 10px; margin-top: -5px">Formato de ejemplo (LTTTTNNNNNNNN)</div>
          </el-form-item>
        </el-col>
        <el-col :md="6" :sm="8">
          <el-form-item>
            <el-button-group style="margin-top: 34px">
              <el-button type="success" size="mini" @click="onSubmit" :loading="loading_facturas">Buscar</el-button>
              <el-button type="default" size="mini" :loading="loading_facturas" @click="limpiarCampos()">Limpiar</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-divider></el-divider>
    <tablaFacturas></tablaFacturas>
  </div>
</template>
<script>
import tablaFacturas from '@/components/escaner/suizo/tablaFacturasEmitidas.vue'
import { HTTP } from '@/axios'
// const moment = require('moment')

export default {
  name: 'facturas',
  components: { tablaFacturas },
  data() {
    return {
      formFacturas: {
        fecha: null,
        comprobante: null,
        pagina: 1,
      },
      loading_facturas: false,
      datePickerFactura: {
        shortcuts: [
          {
            text: 'Últ. Semana',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Últ. Mes',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '3 Últ. meses',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        disabledDate(date) {
          return date > new Date()
        },
      },
    }
  },
  mounted() {
    this.formFacturas.fecha = [new Date(this.$moment().subtract(7, 'days')), new Date()]
  },
  methods: {
    ejecutarAccion() {
      this.loading_facturas = true
      this.$events.fire('loading_facturas', true)
      this.formFacturas.fechaUp = this.formFacturas.fecha !== null && this.formFacturas.fecha !== '' ? this.formFacturas.fecha : null
      HTTP.post('/escaner/getFacturasSuizo', this.formFacturas)
        .then((res) => {
          this.loading_facturas = false
          console.log('res2', res.data)
          this.$events.fire('set_facturas', { datos_tabla: res.data, formulario: this.formFacturas })
        })
        .catch((e) => {
          this.$alert('Error al descargar el archivo ', e)
          this.loading_facturas = false
          this.$events.fire('set_facturas', { datos_tabla: [], formulario: this.formFacturas })
        })
    },
    onSubmit() {
      if (this.formFacturas.comprobante !== '' && this.formFacturas.comprobante !== null && this.formFacturas.comprobante.length < 13) {
        // alert('Controle la cantidad de caracteres de búsqueda')
        this.$swal('Error!', 'Controle la cantidad de caracteres de búsqueda. Deben ser 13.', 'error')
      } else {
        if (this.formFacturas.fecha === '' || this.formFacturas.fecha === null) {
          this.formFacturas.fecha = [new Date(this.$moment().subtract(7, 'days')), new Date()]
        }
        this.ejecutarAccion()
        // if (this.formFacturas.fecha !== '' && this.formFacturas.fecha !== null) {
        //   this.$swal
        //     .fire({
        //       title: '¿Desea realizar la búsqueda?',
        //       text: ' Haga clic en aceptar si así fuese. ',
        //       icon: 'warning',
        //       showCancelButton: true,
        //       confirmButtonColor: '#309ED5',
        //       cancelButtonColor: '#f86c6b',
        //       confirmButtonText: 'Aceptar',
        //       cancelButtonText: 'Cancelar',
        //     })
        //     .then((result) => {
        //       if (result.value) {
        //         this.ejecutarAccion()
        //       }
        //     })
        // } else {
        //   this.ejecutarAccion()
        // }
      }
    },
    cambiarDatos() {
      if (this.formFacturas.comprobante !== '' && this.formFacturas.comprobante !== null && this.formFacturas.comprobante.length < 13) {
        this.$swal('Error!', 'Controle la cantidad de caracteres de búsqueda', 'error')
      } else {
        if (this.formFacturas.fecha === '' || this.formFacturas.fecha === null) {
          this.$events.fire('set_facturas', { datos_tabla: [], formulario: this.formFacturas })
        }
      }
    },
    limpiarCampos() {
      this.formFacturas.fecha = [new Date(this.$moment().subtract(7, 'days')), new Date()]
      this.formFacturas.comprobante = null
    },
  },
}
</script>
<style>
.uppertext input {
  text-transform: uppercase !important;
}
</style>
