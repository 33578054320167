<template>
  <el-dialog
    :visible.sync="modal.visible"
    width="95%"
    :close-on-click-modal="false"
    style="padding-top: 0px !important"
    :close-on-press-escape="false"
    :destroy-on-close="false"
    :show-close="false"
    top="5vh">
    <el-row style="margin-top: -37px">
      <h2 style="text-align: center; padding: 0px"><i :class="this.config.icon"></i> {{ this.config.titulo }}</h2>
      <hr />
    </el-row>
    <br />
    <el-form autocomplete="off" size="small" ref="guiaRef" :model="guia" :rules="rules" v-loading="sistema.loading">
      <el-row :gutter="20">
        <el-col :md="{ span: 24 }">
          <el-form-item label="Seleccione un convenio:">
            <el-select v-model="guia.convenio" style="width: 100%">
              <el-option v-for="(item, index) in this.convenios" :key="index" :label="item.nombre" :value="item.convenio" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="{ span: 24 }" v-if="selectDestinatario != null">
          <el-form-item label="Seleccione un destinatario:" prop="codigo" disabled>
            <div class="el-select el-select--small" style="width: 100%; line-height: 0px !important">
              <v-select
                v-model="selectDestinatario"
                :options="destinatarios"
                :filter="fuseSearchDestinatarios"
                :getOptionLabel="(option) => option.razon_social + ' - ' + option.codigo"
                ref="destinatarioSelect"
                placeholder="Puede buscar por razon social o codigo"
                @input="setSelected">
                <span slot="no-options">Sin Coincidencia</span>
                <template #option="{ razon_social, codigo }">
                  <cite
                    >{{ razon_social }} - <b>Cod: {{ codigo }}</b></cite
                  >
                </template>
              </v-select>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 8 }">
          <el-form-item label="Código:" prop="codigo" for="codigo">
            <el-input
              id="codigo"
              name="codigo"
              ref="cuitCodigo"
              type="text"
              :maxlength="10"
              v-model="guia.codigo"
              placeholder="Ingrese el codigo del destinatario o presione el boton para buscar">
              <el-button slot="append" icon="el-icon-search" :style="sistema.styleBtn" @click="buscarDestinatario()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 8 }">
          <el-form-item label="CUIT:" prop="cuit" for="cuit">
            <el-input id="cuit" name="cuit" ref="cuitRef" v-mask="'##-########-#'" v-model="guia.cuit" placeholder="Ingrese el CUIT del destinatario" clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 8 }">
          <el-form-item label="Razón Social:" prop="razon_social" for="razon_social">
            <el-input id="razon_social" name="razon_social" prefix-icon="el-icon-user-solid" v-model="guia.razon_social" placeholder="Ingrese la razón social del destinatario" clearable />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="{ span: 6 }">
          <el-form-item label="Dirección (Calle y Número):" prop="direccion" for="direccion">
            <el-input
              id="direccion"
              name="direccion"
              v-model="guia.direccion"
              placeholder="Ingrese el nombre de la calle del domicilio del destinatario"
              prefix-icon="el-icon-location"
              clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 6 }">
          <el-form-item label="Código Postal:" prop="codigo_postal" for="codigo_postal">
            <el-input
              id="codigo_postal"
              name="codigo_postal"
              size="small"
              v-mask="'####'"
              type="text"
              v-model="codigo_postal"
              placeholder="Ingrese los cuatro digitos del codigo postal"
              autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 6 }">
          <el-form-item label="Provincia:" prop="provincia" disabled>
            <div class="el-select el-select--small" style="width: 100%; line-height: 0px !important">
              <v-select
                v-model="provincia"
                :options="provincias"
                :filter="fuseSearch"
                :getOptionLabel="(option) => option.nombre"
                ref="provinciaRef"
                placeholder="Seleccione una provincia"
                :disabled="sistema.filtroProvincia"
                autocomplete="off">
                <span slot="no-options">Sin Coincidencia</span>
              </v-select>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 6 }">
          <el-form-item label="Localidad:" prop="localidad" disabled>
            <div class="el-select el-select--small" style="width: 100%; line-height: 0px !important">
              <v-select
                v-model="localidad"
                :options="localidades"
                :filter="fuseSearch"
                :getOptionLabel="(option) => option.nombre"
                ref="localidadRef"
                placeholder="Seleccione una localidad"
                :disabled="sistema.filtroLocalidad"
                autocomplete="off">
                <span slot="no-options">Sin Coincidencia</span>
              </v-select>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="{ span: 4 }">
          <el-form-item label="Terminal:" prop="terminal" for="terminal">
            <el-input id="terminal" name="terminal" size="small" v-mask="'#####'" v-model="guia.terminal" placeholder="Ingrese la terminal" disabled />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 4 }">
          <el-form-item label="Número:" prop="numero" for="numero">
            <el-input id="numero" name="numero" size="small" v-mask="'########'" v-model="guia.numero" placeholder="Ingrese el numero de guia" disabled />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 8 }">
          <el-form-item label="Fecha de entrega:" prop="fecha_entrega">
            <el-date-picker
              id="fecha_entrega"
              name="fecha_entrega"
              type="date"
              placeholder="Seleccione la fecha de entrega"
              value-format="yyyy-MM-dd"
              format="dd-MM-yyyy"
              v-model="guia.fecha_entrega"
              style="width: 100%"
              :picker-options="fechaOptions"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 8 }">
          <el-form-item label="Rango horario">
            <el-time-picker is-range start-placeholder="Desde" range-separator="a" end-placeholder="Hasta" value-format="HH:mm" format="HH:mm" v-model="rango" style="width: 100%" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="{ span: 24 }">
          <el-form-item label="Datos adicionales:" prop="adicional">
            <el-input id="adicional" name="adicional" type="textarea" placeholder="Informacion adicional" v-model="guia.adicional" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="{ span: 4 }">
          <el-checkbox label="Cobranza" :true-label="1" :false-label="0" v-model="guia.checkCobranza" @change="habilitarCobranza()" />
        </el-col>
        <el-col :md="{ span: 4 }">
          <el-input-number size="small" v-model="guia.valor_cobranza" controls-position="right" :precision="2" :step="0.1" :min="0" :disabled="sistema.inputCobranza" ref="refCobranza" />
        </el-col>
        <el-col :md="{ span: 4 }">
          <el-checkbox label="Acompañante" :true-label="1" :false-label="0" v-model="guia.checkAcompanante" @change="habilitarAcompanante()" />
        </el-col>
        <el-col :md="{ span: 4 }">
          <el-input-number
            size="small"
            v-model="guia.acompanante"
            controls-position="right"
            :precision="0"
            :step="1"
            :min="0"
            :max="5"
            :disabled="sistema.inputAcompanante"
            ref="refAcompanante" />
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :md="{ span: 4 }">
          <el-checkbox size="medium" :true-label="1" :false-label="0" label="Requerimientos específicos" v-model="guia.checkRequerimientos" @change="habilitarRequerimientos()" />
        </el-col>
      </el-row>
      <div v-if="guia.checkRequerimientos == 1">
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col :md="{ span: 8 }">
            <el-form-item label="Porte:">
              <el-select v-model="guia.porte" placeholder="Seleccione una opcion">
                <el-option v-for="item in sistema.tipoPorte" :key="item.nombre" :label="item.nombre" :value="item.nombre" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 4 }">
            <el-checkbox size="medium" :true-label="1" :false-label="0" label="Vehículo con Pala" v-model="guia.pala" />
          </el-col>
          <el-col :md="{ span: 5 }">
            <el-checkbox size="medium" :true-label="1" :false-label="0" label="Retiro de mercadería en depósito" v-model="guia.retiro_cliente" />
          </el-col>
          <el-col :md="{ span: 5 }">
            <el-checkbox style="display: none" readonly disabled size="medium" :true-label="1" :false-label="0" label="Contra entrega de mercadería" v-model="guia.contraentrega" />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 30px">
          <!-- <el-col :md="{ span: 12 }">
            <el-form-item label="GLN:" prop="gln">
                <el-input type="textarea" v-model="guia.gln" :rows="3" placeholder="GLN" clearable/>
            </el-form-item>
          </el-col> -->
          <el-col :md="{ span: 24 }">
            <el-form-item label="Otros requerimientos:">
              <el-input type="textarea" v-model="guia.otros" :rows="3" placeholder="Ingrese otros requerimientos" clearable="" />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-row type="flex" justify="end">
        <el-button type="danger" size="medium" @click="cerrarForm('guiaRef')" icon="el-icon-close">Cerrar</el-button>
        <el-button type="success" size="medium" @click="saveGuia('guiaRef')" icon="el-icon-check">Guardar</el-button>
      </el-row>
    </span>
  </el-dialog>
</template>

<script>
import { API } from '@/plugins/api'
import vSelect from 'vue-select'
import Fuse from 'fuse.js'

export default {
  name: 'guias-create-form',
  components: { vSelect },
  props: { modal: { type: Object, required: true } },

  mounted() {
    //Filtro solo convenios con configs validas para terminal 1005
    const conveniosFiltrados = this.modal.convenios.filter((convenio) => {
      return convenio.configs.some((config) => config.terminal == 1005)
    })
    this.convenios = conveniosFiltrados
    //console.log (this.convenios)
    //Control
    if (this.convenios.length <= 0) {
      this.$swal('Atencion!', 'No tiene convenios con permiso de carga manual de guias', 'warning')
      return this.cerrarForm('guiaRef')
    }
    this.guia.convenio = this.convenios[0].convenio
    this.convenios.filter((convenio) => {
      const config = convenio.configs.find((c) => c.terminal == 1005)
      if (config) {
        convenio.config = config
        return true
      } else return false
    })
    //Inicio Modulo
    this.sistema.tipoPorte = this.modal.tipoPorte
    switch (this.modal.tipo) {
      case 'ALTA':
        this.config.icon = 'el-icon-circle-plus'
        this.config.titulo = 'ALTA DE GUIA'
        break
      case 'EDITAR':
        this.config.icon = 'el-icon-circle-plus'
        if (this.modal.row.origen === 'MANUAL') this.config.titulo = 'EDICIÓN DE GUIA: Carga Manual #' + this.modal.row.id
        else this.config.titulo = 'EDICIÓN DE GUIA: ' + this.modal.row.terminal + ' - ' + this.modal.row.numero
        break
    }
    this.getDestinatarios()
  },
  data() {
    return {
      convenios: [],
      config: {
        icon: null,
        titulo: null,
      },
      sistema: {
        tipoPorte: [],
        loading: true,
        filtroLocalidad: true,
        filtroProvincia: true,
        inputCobranza: true,
        inputAcompanante: true,
        styleBtn: {
          borderColor: '#e5590a',
          backgroundColor: '#e5590a',
          color: 'white',
        },
      },
      localidad: null,
      provincia: null,
      rango: null,
      codigo_postal: null,
      localidades: [],
      provincias: [],
      destinatarios: [],
      selectDestinatario: null,
      guia: {
        codigo: null,
        cuit: null,
        razon_social: null,
        direccion: null,
        localidad: null,
        provincia: null,
        codeprov: null,
        codigo_postal: null,
        adicional: null,
        fecha_entrega: null,
        rango_inicial: null,
        rango_final: null,
        terminal: null,
        numero: null,
        checkCobranza: 0,
        valor_cobranza: null,
        checkAcompanante: 0,
        acompanante: null,
        checkRequerimientos: 0,
        porte: null,
        pala: 0,
        retiro_cliente: 0,
        otros: null,
        //gln:null,
        convenio: null,
        contraentrega: 0,
      },
      rules: {
        //codigo :[{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        razon_social: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        //cuit :[{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        direccion: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        //entrecalle1 :[{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        //entrecalle2 :[{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        provincia: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        localidad: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        codigo_postal: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        //terminal :[{ required: true, message: 'Campo obligatorio y solo numerico', trigger: 'blur'}],
        //numero :[{ required: true, message: 'Campo obligatorio y solo numerico', trigger: 'blur'}],
        acompanante: [{ type: 'integer', message: 'Campo solo numerico', trigger: 'blur' }],
      },
      fechaOptions: {
        disabledDate(time) {
          const date = new Date()
          return time.getTime() <= date.setDate(date.getDate() - 1)
        },
      },
    }
  },
  methods: {
    habilitarCobranza() {
      this.guia.valor_cobranza = null
      if (this.guia.checkCobranza == 1) {
        this.sistema.inputCobranza = false
        this.$nextTick(() => this.$refs.refCobranza.focus())
      }
      if (this.guia.checkCobranza == 0) {
        this.sistema.inputCobranza = true
      }
    },
    habilitarAcompanante() {
      this.guia.acompanante = null
      if (this.guia.checkAcompanante == 1) {
        this.sistema.inputAcompanante = false
        this.$nextTick(() => this.$refs.refAcompanante.focus())
      }
      if (this.guia.checkAcompanante == 0) {
        this.sistema.inputAcompanante = true
      }
    },
    habilitarRequerimientos() {
      this.guia.porte = null
      this.guia.pala = 0
      this.guia.retiro_cliente = 0
      //this.guia.contraentrega = 0
      this.guia.otros = null
      //this.guia.gln = null
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['nombre'],
        shouldSort: true,
        minMatchCharLength: 1,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    fuseSearchDestinatarios(options, search) {
      const fuse = new Fuse(options, {
        keys: ['razon_social', 'codigo'],
        shouldSort: true,
        minMatchCharLength: 1,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    getDestinatarios() {
      API.post('cross/destinatarios/lista', {
        convenios: this.modal.convenios_simple,
      })
        .then((response) => {
          this.destinatarios = response.data.data
          if (this.modal.tipo == 'EDITAR') {
            this.cargarForm(this.modal.row)
          } else {
            this.sistema.loading = false
            this.$nextTick(() => this.$refs.cuitCodigo.focus())
          }
        })
        .catch((error) => {
          this.$swal('Atencion!', error.response.data.message, 'warning')
          return this.cerrarForm('guiaRef')
        })
    },
    buscarDestinatario() {
      this.selectDestinatario = ''
      this.guia.codigo = null
      this.guia.cuit = null
      this.guia.razon_social = null
      this.guia.direccion = null
      this.guia.localidad = null
      this.guia.provincia = null
      this.guia.codeprov = null
      this.guia.codigo_postal = null
      this.codigo_postal = null
      this.localidad = null
      this.provincia = null

      this.$nextTick(() => this.$refs.destinatarioSelect.$refs.search.focus())
    },
    setSelected() {
      this.guia.codigo = this.selectDestinatario.codigo
      this.guia.cuit = this.selectDestinatario.cuit
      this.guia.razon_social = this.selectDestinatario.razon_social
      this.guia.direccion = this.selectDestinatario.direccion
      this.guia.codeprov = this.selectDestinatario.codeprov
      this.codigo_postal = this.selectDestinatario.codigo_postal
      //Objetos
      let provinciaObj = new Object()
      provinciaObj.nombre = this.selectDestinatario.provincia
      provinciaObj.codigo = this.selectDestinatario.codeprov
      setTimeout(() => {
        this.provincia = provinciaObj
      }, 50)

      let localidadObj = new Object()
      localidadObj.nombre = this.selectDestinatario.localidad
      setTimeout(() => {
        this.localidad = localidadObj
      }, 50)

      this.selectDestinatario = null
    },
    saveGuia(formName) {
      //Control CUIT
      if (this.guia.cuit != null && this.guia.cuit != '') {
        if (this.isValidCUIT() == false)
          return this.$message({
            message: 'CUIT invalido. Controle qu el CUIT ingresado sea valido.',
            type: 'warning',
          })
      }
      //Control Formulario
      this.$refs[formName].validate((valid) => {
        if (!valid)
          return this.$message({
            message: 'Por favor. Controle los campos obligatorios.',
            type: 'warning',
          })
        if (this.guia.checkCobranza == 1 && this.guia.valor_cobranza <= 0)
          return this.$message({
            message: 'Debe ingresar un numero mayor a cero en el campo cobranza.',
            type: 'warning',
          })
        if (this.guia.checkAcompanante == 1 && this.guia.acompanante <= 0)
          return this.$message({
            message: 'Debe ingresar un numero mayor a cero y menor a 200 para acompañante.',
            type: 'warning',
          })
        if (this.guia.acompanante > 200)
          return this.$message({
            message: 'Debe ingresar un numero mayor a cero y menor a 200 para acompañante.',
            type: 'warning',
          })
        let obj = {}

        obj['codigo'] = this.guia.codigo
        obj['cuit'] = this.guia.cuit
        obj['razon_social'] = this.guia.razon_social
        obj['direccion'] = this.guia.direccion
        obj['provincia'] = this.guia.provincia
        obj['localidad'] = this.guia.localidad
        obj['codeprov'] = this.guia.codeprov
        obj['codigo_postal'] = this.guia.codigo_postal
        obj['adicional'] = this.guia.adicional
        obj['fecha_entrega'] = this.guia.fecha_entrega
        obj['rango_inicial'] = this.guia.rango_inicial
        obj['rango_final'] = this.guia.rango_final
        obj['valor_cobranza'] = this.guia.valor_cobranza
        obj['acompanante'] = this.guia.acompanante
        obj['porte'] = this.guia.porte
        obj['pala'] = this.guia.pala
        obj['retiro_cliente'] = this.guia.retiro_cliente
        obj['contraentrega'] = this.guia.contraentrega
        obj['otros'] = this.guia.otros
        //obj["gln"] = this.guia.gln
        obj['convenio'] = this.guia.convenio
        obj['config'] = this.convenios.find((convenio) => convenio.convenio === this.guia.convenio).config

        switch (this.modal.tipo) {
          case 'ALTA':
            obj['id'] = null
            obj['comprobantes'] = []
            obj['bultos'] = []
            obj['terminal'] = null
            obj['numero'] = null
            obj['origen'] = 'MANUAL'
            break
          case 'EDITAR':
            obj['id'] = this.modal.row.id
            obj['comprobantes'] = this.modal.row.comprobantes
            obj['bultos'] = this.modal.row.bultos
            obj['terminal'] = this.modal.row.terminal
            obj['numero'] = this.modal.row.numero
            obj['origen'] = this.modal.row.origen
            break
        }
        this.$events.fire('add-guia', obj, this.modal.tipo)
      })
    },
    cargarForm(row) {
      this.guia.codigo = row.codigo
      this.guia.cuit = row.cuit
      this.guia.razon_social = row.razon_social
      this.guia.direccion = row.direccion

      //Provincia y Localidad
      this.codigo_postal = row.codigo_postal
      this.guia.codeprov = row.codeprov

      //Cargo Localidad
      let localidadObj = new Object()
      localidadObj.nombre = row.localidad
      setTimeout(() => {
        this.localidad = localidadObj
      }, 50)
      this.guia.localidad = row.localidad
      this.filtroLocalidad = false

      //Cargo Provincia
      let provinciaObj = new Object()
      provinciaObj.nombre = row.provincia
      provinciaObj.codigo = row.codeprov
      setTimeout(() => {
        this.provincia = provinciaObj
      }, 50)

      this.guia.provincia = row.provincia
      this.filtroProvincia = false
      this.guia.terminal = row.terminal
      this.guia.numero = row.numero
      this.guia.adicional = row.adicional
      this.guia.fecha_entrega = row.fecha_entrega
      //Rango horario
      if (row.rango_inicial != null || row.rango_final != null) {
        this.guia.rango_inicial = row.rango_inicial
        this.guia.rango_final = row.rango_final
        this.rango = [row.rango_inicial, row.rango_final]
      }
      //Check Requerimientos especificos
      if (row.porte != null || row.pala == 1 || row.retiro_cliente == 1 || row.otros != null || row.contraentrega == 1)
        // || row.gln != null )
        this.guia.checkRequerimientos = 1
      //check cobranza
      if (row.valor_cobranza > 0) {
        this.guia.valor_cobranza = row.valor_cobranza
        this.guia.checkCobranza = 1
        this.sistema.inputCobranza = false
      } else {
        this.guia.valor_cobranza = null
        this.guia.checkCobranza = 0
        this.sistema.inputCobranza = true
      }
      //check acompanante
      if (row.acompanante > 0) {
        this.guia.acompanante = row.acompanante
        this.guia.checkAcompanante = 1
        this.sistema.inputAcompanante = false
      } else {
        this.guia.acompanante = null
        this.guia.checkAcompanante = 0
        this.sistema.inputAcompanante = true
      }

      this.guia.porte = row.porte
      this.guia.pala = row.pala
      this.guia.retiro_cliente = row.retiro_cliente
      this.guia.contraentrega = row.contraentrega
      //this.guia.gln = row.gln
      this.guia.otros = row.otros
      this.guia.convenio = row.convenio

      this.sistema.loading = false
      this.$nextTick(() => this.$refs.cuitCodigo.focus())
    },
    cerrarForm() {
      this.$events.fire('close-modal')
    },
    isValidCUIT() {
      const cuit = this.guia.cuit
      if (cuit.length != 13) return false

      let rv = false
      let resultado = 0
      let cuit_nro = cuit.replace('-', '')
      const codes = '6789456789'
      let verificador = parseInt(cuit_nro[cuit_nro.length - 1])
      let x = 0

      while (x < 10) {
        let digitoValidador = parseInt(codes.substring(x, x + 1))
        if (isNaN(digitoValidador)) digitoValidador = 0
        let digito = parseInt(cuit_nro.substring(x, x + 1))
        if (isNaN(digito)) digito = 0
        let digitoValidacion = digitoValidador * digito
        resultado += digitoValidacion
        x++
      }
      resultado = resultado % 11
      rv = resultado == verificador

      return rv
    },
  },
  watch: {
    codigo_postal() {
      if (this.codigo_postal != null) {
        if (this.codigo_postal.length >= 4) {
          this.guia.codigo_postal = this.codigo_postal
          API.get('/aux/localidades/' + this.codigo_postal)
            .then((response) => {
              if (response.data.data == null) {
                this.localidades = []
                this.provincias = []
                this.guia.provincia = null
                this.guia.localidad = null
                this.guia.codeprov = null
                this.localidad = null
                this.provincia = null
                this.sistema.filtroLocalidad = true
                this.sistema.filtroProvincia = true
                this.$message({
                  message: 'La localidad ingresada, no existe.',
                  type: 'warning',
                })
              } else {
                this.provincias = response.data.data.provincias
                this.localidades = response.data.data.localidades
                //this.guia.codeprov = response.data.data.provincia.codigo
                //this.guia.provincia = response.data.data.provincia.nombre
                this.sistema.filtroLocalidad = false
                this.sistema.filtroProvincia = false
              }
            })
            .catch((error) => {
              console.log(error)
              this.localidades = []
              this.provincias = []
              this.guia.provincia = null
              this.guia.localidad = null
              this.guia.codeprov = null
              this.localidad = null
              this.provincia = null
              this.sistema.filtroLocalidad = true
              this.sistema.filtroProvincia = true
              this.$message({
                message: 'La localidad ingresada o provincia, no existe.',
                type: 'warning',
              })
              //mensaje error
            })
        } else {
          this.localidades = []
          this.provincias = []
          this.guia.provincia = null
          this.guia.localidad = null
          this.localidad = null
          this.provincia = null
          this.sistema.filtroLocalidad = true
          this.sistema.filtroProvincia = true
        }
      }
    },
    provincia() {
      if (this.provincia != null) {
        this.guia.provincia = this.provincia.nombre
        this.guia.codeprov = this.provincia.codigo
      } else {
        this.guia.provincia = null
        this.guia.codeprov = null
      }
    },
    localidad() {
      if (this.localidad != null) {
        this.guia.localidad = this.localidad.nombre
      } else {
        this.guia.localidad = null
      }
    },
    rango() {
      if (this.rango != null) {
        this.guia.rango_inicial = this.rango[0]
        this.guia.rango_final = this.rango[1]
      } else {
        this.guia.rango_inicial = null
        this.guia.rango_final = null
      }
    },
  },
}
</script>
