<template>
  <div>
    <el-row style="text-align: center" :gutter="10">
      <center>
        <el-col :md="24">
          <p class="bold title-stock">
            Total de productos encontrados:{{ cantidad_elementos }}
          </p>
        </el-col>
      </center>
    </el-row>
    <el-table
      :data="tableData"
      width="80"
      border
      stripe
      fit
      v-loading="cargando"
      size="small"
      height="40vh">
      <el-table-column
        prop="cantidad"
        label="Cantidada"
        align="center"
        width="230"
        v-if="permiso_user">
        <template slot-scope="scope">
          <el-input-number
            v-model="scope.row.cantidad"
            style="width: 75% !important"
            :min="0"
            :max="scope.row.disponible"
            size="mini"
            :disabled="scope.row.disponible <= 0"
            @change="sumar(scope.row)">
          </el-input-number>
          <el-tooltip
            class="item"
            effect="dark"
            content="Agregar al carro"
            placement="right-start"
            v-if="scope.row.disponible">
            <el-button
              type="success"
              v-loading="cargando_elemento"
              icon="el-icon-check"
              circle
              size="mini"
              :disabled="scope.row.cantidad <= 0"
              style="margin-left: 5px; padding: 9px !imporntant">
            </el-button>
          </el-tooltip>

          <el-button
            v-if="scope.row.disponible == 0"
            type="danger"
            v-loading="cargando_elemento"
            icon="el-icon-check"
            circle
            size="mini"
            :disabled="scope.row.cantidad <= 0"
            style="margin-left: 5px; padding: 9px !imporntant">
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="cantlote"
        label="Cant. por Lote"
        width="90"
        v-if="permiso_user">
        <template slot-scope="scope">
          <span v-if="scope.row.cantlote > 0" style="color: #dd6161">
            <strong>{{ scope.row.cantlote }}</strong>
          </span>
          <span v-else>{{ scope.row.cantlote }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="descrip" label="Descripción">
        <!-- <template slot-scope="scope">
                    <el-popover   placement="top" trigger="hover" style="float:left; background: #ffffffd6 !important;">
                        <h5 style="margin-bottom:0px;margin-top:0px;color:white;background-color:#e55a0b;text-align:center;padding:3px;">{{scope.row.descrip}}</h5>
                        <el-row><strong>Rubro:</strong>
                            <span v-if="scope.row.rubroN!=null"> {{scope.row.rubroN}}</span>
                            <span v-else> - </span>
                        </el-row>
                        <div slot="reference" class="name-wrapper" style="margin-top: 3px;" >
                            <span>{{scope.row.descrip}}</span>
                        </div>
                    </el-popover>
                </template> -->
      </el-table-column>

      <el-table-column prop="rubroN" label="Rubro"> </el-table-column>
      <el-table-column
        v-if="!no_genericos"
        prop="codgenerico"
        label="Genérico"
        width="100"
        align="center">
      </el-table-column>
      <el-table-column
        prop="codigo"
        label="Cod. Login"
        width="100"
        align="center">
      </el-table-column>
      <el-table-column
        property="vencimiento"
        label="Vencimiento"
        width="120"
        align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.vencimiento">
            {{ formatFecha(scope.row.vencimiento.date) }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="stock" label="Stock" width="100" align="center">
        <!-- <template slot-scope="scope">
                    <span v-if="scope.row.stock !=null && scope.row.stock !=0">{{scope.row.stock}} </span>
                    <span v-else style="color:#dd6161;"><strong>No</strong></span>
                </template> -->
      </el-table-column>
      <el-table-column
        v-if="permisoVerStock"
        prop="disponible"
        label="Disponible"
        width="90"
        align="center">
        <!-- <template slot-scope="scope">
                    <span v-if="scope.row.disponible !=null &&  scope.row.disponible !=0">{{scope.row.disponible}} </span>
                    <span v-else style="color:#dd6161;"><strong>No</strong></span>
                </template> -->
      </el-table-column>
      <el-table-column align="center" width="80">
        <template slot-scope="scope">
          <el-button size="mini" @click="detallesProd(scope.row)" type="danger"
            >Ver</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!---------- MODAL DETALLE STOCK ------------>
    <modal-datos :datos_stock="datos_stock" :permisos_user="permiso_user">
    </modal-datos>
    <!-- ///------ FIN Dialog------/// -->
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
const moment = require('moment')
import ModalDatos from '@/components/stocks/login/ModalDatosStock.vue'

export default {
  props: ['permiso_user'],
  components: {
    ModalDatos,
  },
  data() {
    return {
      tableData: [],
      cargando: true,
      permisoVerStock: '',
      cantidad_elementos: 0,
      session: JSON.parse(window.localStorage.getItem('datos')),
      permisoPrecio: '',
      dialogVisible: false,
      titleDialog: '',
      numProd: 0,
      visible: false,
      tablaResumen: [],
      tablaLotes: [],
      tablaDetalle: [],
      tablaUM: [],
      infoExtra: [],
      cargarTabla: false,
      altTabla: 0,
      altRes: 0,
      altDet: 0,
      altUM: 0,
      altInfo: 0,
      config: [],
      fullscreenLoading: false,
      carrito_previo: [],
      cargando_elemento: false,
      datos_stock: [],
      carrito_previo_LOTE: [],
      actualizando_tabla: false,
      no_genericos: false,
    }
  },
  mounted() {
    /* NUEVO PERMISO NO GENERICOS. NO MOSTRAR GENERICOS PARA QUIENES TIENEN ESTE PERMISO */
    this.no_genericos = this.session.permisos.includes('NO_GENERICOS')
      ? true
      : false
    // ------------------------------------------------------------------------------------
    EventBus.$on('cargandoStock', () => {
      this.cargando = true
      this.cantidad_elementos = ''
    })

    this.permisoVerStock =
      this.session.permisos.indexOf('STOCK') != -1 ? true : false
    this.permisoPrecio =
      this.session.permisos.indexOf('PRECIO') != -1 ? true : false
    EventBus.$on('cargarTablaStock', (g) => {
      if (g.length == 0) {
        this.$message({
          message: 'No se encontraron artículos que concuerden con su búsqueda',
          type: 'warning',
        })
        this.tableData = g
        this.cargando = false
      } else {
        if (this.carrito_previo.length) {
          this.carrito_previo.forEach((element) => {
            let buscar = g.find(
              (elemento) =>
                elemento.codigo == element.articulo &&
                elemento.codprov == element.articulo_cli &&
                elemento.descrip == element.descrip &&
                elemento.lote == null
            )
            if (typeof buscar != 'undefined') {
              buscar.cantidad = element.cantidad
            } else {
              buscar = g.find(
                (elemento) =>
                  elemento.codigo == element.articulo &&
                  elemento.codprov == element.articulo_cli &&
                  elemento.lote == null
              )
              if (typeof buscar != 'undefined') {
                buscar.cantidad = element.cantidad
              }
            }
          })
        }
        this.tableData = g
        this.cantidad_elementos = this.tableData.length
        this.cargando = false
      }
    })

    setTimeout(() => {
      EventBus.$on('limpiarStock', () => {
        this.tableData = []
        this.cargando = false
        this.cantidad_elementos = this.tableData.length
      })
    }, 700)

    /* ------------------ Cuando se elimina un prod. del carro ------------------ */
    EventBus.$on('cargarCarrito2_art', (g) => {
      let control = this.tableData.find(
        (element) =>
          element.codigo == g.elemento.articulo &&
          element.codprov == g.elemento.articulo_cli &&
          element.descrip == g.elemento.descrip
      )
      this.carrito_previo = g.carrito_nuevo

      if (typeof control !== 'undefined') {
        control.cantidad = g.elemento.lote == null ? 0 : control.cantidad
        control.cantlote =
          g.elemento.lote != null
            ? control.cantlote - g.elemento.cantidad
            : control.cantlote
      } else {
        control = this.tableData.find(
          (element) =>
            element.codigo == g.elemento.articulo &&
            element.codprov == g.elemento.articulo_cli
        )
        if (typeof control !== 'undefined') {
          control.cantidad = 0
          control.cantlote =
            g.elemento.lote != null
              ? control.cantlote - g.elemento.cantidad
              : control.cantlote
        }
      }
    })
    /* --------------------------------------------------------------------------- */
    EventBus.$on('carrito_previo', (tabla) => {
      let array_elementos_cantidad = []
      tabla.forEach((element) => {
        let buscar = this.tableData.find(
          (elemento) =>
            elemento.codigo == element.articulo &&
            elemento.codprov == element.articulo_cli &&
            elemento.descrip == element.descrip &&
            element.lote == null
        )

        if (typeof buscar != 'undefined') {
          buscar.cantidad = element.cantidad
          array_elementos_cantidad.push(buscar)
        } else {
          buscar = this.tableData.find(
            (elemento) =>
              elemento.codigo == element.articulo &&
              elemento.codprov == element.articulo_cli &&
              elemento.lote == null &&
              element.lote == null
          )

          if (typeof buscar != 'undefined') {
            buscar.cantidad = element.cantidad
            array_elementos_cantidad.push(buscar)
          }
        }
      })
      /* CUANDO EN EL CARRITO SE ELIMINA UN ELEMENTO AL VALIDAR PEDIDO ACÁ SE REGRESA A 0 CANTIDAD */
      let buscar_2 = this.tableData.filter((elemento) => elemento.cantidad > 0)
      let difference = buscar_2.filter(
        (x) => !array_elementos_cantidad.includes(x)
      )
      difference.forEach((element) => {
        element.cantidad = 0
      })

      this.carrito_previo = tabla
    })
    /* CARRO PREVIO PARA EL CASO DE LOTE */
    EventBus.$on('solo_stock', (tabla) => {
      if (this.tableData.length) {
        tabla.forEach((element) => {
          let buscar = this.tableData.find(
            (elemento) =>
              elemento.codigo == element.articulo &&
              elemento.descrip == element.descrip
          )
          if (typeof buscar != 'undefined') {
            buscar.cantlote = element.cantidad
          }
        })
      }
    })
    /* --------------------------------- */
    /* Cuando hago el envio debo actualizar la tabla de stock */
    EventBus.$on('recargarTablaCompleta', (tabla) => {
      this.tableData = tabla
      this.cantidad_elementos = this.tableData.length
      this.carrito_previo = []
    })

    EventBus.$on('loading_elemento', (tabla) => {
      this.cargando_elemento = tabla
    })
    EventBus.$on('cantidad_lote_art', (tabla) => {
      let buscar = this.tableData.find(
        (elemento) =>
          elemento.codigo == tabla.codigo && elemento.codprov == tabla.codprov
      )
      if (typeof buscar != 'undefined') {
        buscar.cantlote = tabla.total
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('cargarTablaStock', false)
    EventBus.$off('loading_elemento', false)
    EventBus.$off('cargandoStock', false)
    EventBus.$off('limpiarStock', false)
    EventBus.$off('carrito_previo', false)
    EventBus.$off('recargarTablaCompleta', false)
    EventBus.$off('solo_stock', false)
  },
  methods: {
    detallesProd(row) {
      this.datos_stock = row
      EventBus.$emit('dialogStock_2', {
        abrirModal: true,
        datos: row,
        session: this.session,
      })
      /* EMITE LOS DATOS DE LA TABLA A MODAL CARRO */
      EventBus.$emit('carrito_cargado_tabla', {
        tabla: this.carrito_previo_LOTE,
      })
    },
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    agregarCarrito(value) {
      value.estado_2 = 'btn_agregar'
      EventBus.$emit('cargarCarrito', value)
    },
    sumar(datos) {
      /* Consultar si existe en el carro de compras */
      if (datos.disponible >= datos.cantidad) {
        let buscar_elemento = this.carrito_previo.find(
          (elemento) =>
            datos.codigo == elemento.articulo &&
            datos.codprov == elemento.articulo_cli &&
            datos.lote == null
        )
        /* SI EL PRODUCTO YA SE AGREGO PERO SE LE ASIGNO CANTIDAD = 0, INGRESA POR EL LADO DE */
        if (typeof buscar_elemento != 'undefined') {
          datos.estado_2 = 'actualizar'
        } else {
          datos.estado_2 = 'btn_agregar'
        }
      } else {
        datos.cantidad = datos.disponible
        let buscar_elemento = this.carrito_previo.find(
          (elemento) =>
            datos.codigo == elemento.articulo &&
            datos.codprov == elemento.articulo_cli &&
            datos.lote == null
        )
        /* SI EL PRODUCTO YA SE AGREGO PERO SE LE ASIGNO CANTIDAD = 0, INGRESA POR EL LADO DE */
        if (typeof buscar_elemento != 'undefined') {
          datos.estado_2 = 'actualizar'
        } else {
          datos.estado_2 = 'btn_agregar'
        }
      }
      EventBus.$emit('cargarCarrito', datos)
    },
  },
}
</script>
<style scoped>
.title-stock {
  margin: 0px !important;
  font-size: 15px;
  background-color: #36404e;
  color: #ffff;
  font-size: 13px;
}
.el-button--mini.is-circle {
  padding: 1px;
}
</style>
