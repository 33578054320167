export const fields = [
  {
    name: 'id',
    title: 'Turno',
    sortField: 'id',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '50px',
  },
  {
    name: '__slot:convenio',
    title: 'Convenio',
    //name: 'convenio.nombre',
    //titleClass: 'text-center-titulo',
    //dataClass: 'text-center-data',
  },
  {
    title: 'Proveedor Convenio',
    name: '__slot:proveedor_convenio',
    sortField: 'proveedor_convenio',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
  },
  {
    name: '__slot:fecha',
    title: 'Fecha Reservada',
    sortField: 'fecha',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '70px',
  },
  {
    name: '__slot:hora_inicial',
    title: 'Hora de Entrada',
    sortField: 'hora_inicial',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '90px',
  },
  {
    name: 'tipo_unidad',
    sortField: 'tipo_unidad',
    title: 'Tipo de Unidad',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
  },
  {
    name: 'tipo_descarga',
    sortField: 'tipo_descarga',
    title: 'Tipo de Descarga',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  } /*
  {
    name: '__slot:check_cliente',
    title: 'Proveedor de Descarga',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },*/,
  {
    name: '__slot:check_frio',
    title: 'Necesita Frio',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: '__slot:aprox',
    title: 'Pallets / Granel',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '85px',
  },
  /*{
    name: 'contacto_nombre',
    title: 'Nombre del contacto',
    sortField: 'contacto_nombre',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: 'contacto_telefono',
    title: 'Telefono',
    sortField: 'contacto_telefono',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: 'contacto_mail',
    title: 'Email',
    sortField: 'contacto_mail',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },*/
  {
    name: '__slot:nave',
    title: 'Nave Asignada',
    sortField: 'nave',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
    width: '80px',
  },
  {
    name: '__slot:estado',
    title: 'Estado',
    sortField: 'estado',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
  },
]
export const sortOrder = [{ field: 'id', sortField: 'id', direction: 'desc' }]
export const rules = {
  convenio_id: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
  proveedor_convenio: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
  hora_inicial: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
  tipo_unidad: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
  tipo_descarga: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],

  contacto_nombre: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
  contacto_telefono: [{ required: true, message: 'Campo obligatorio', trigger: ['blur'] }],
  contacto_mail: [
    {
      required: true,
      type: 'email',
      message: 'Ingrese una direccion de email correcta',
      trigger: ['blur'],
    },
  ],
}
