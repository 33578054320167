import Vue from 'vue'

Vue.prototype.$sendModal = function () {
  this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel',
    type: 'warning',
  })
    .then(() => {
      this.$message({ type: 'success', message: 'Delete completed' })
    })
    .catch(() => {
      this.$message({ type: 'info', message: 'Delete canceled' })
    })
}
