export const estados = [
  {
    estado: 0,
    color: 'info',
    texto: 'PRETURNO',
  },
  {
    estado: 1,
    color: 'primary',
    texto: 'TURNADO',
  },
  {
    estado: 2,
    color: 'warning',
    texto: 'PLAYA',
  },
  {
    estado: 3,
    color: 'success',
    texto: 'DOCK',
  },
  {
    estado: 4,
    color: 'success',
    texto: 'ATENDIDO',
  },
  {
    estado: 5,
    color: 'dark',
    texto: 'FINALIZADO',
  },
  {
    estado: 99,
    color: 'danger',
    texto: 'CANCELADO',
  },
]
export const unidades = [{ value: 'BALANCIN' }, { value: 'CHASIS' }, { value: 'CONTENEDOR' }, { value: 'SEMI' }, { value: 'UTILITARIOS' }, { value: 'OTRO' }]
export const descargas = [{ value: 'GRANEL' }, { value: 'MIXTO' }, { value: 'PALLETS' }]
export const naves = [
  {
    texto: 'NAVE 1',
    value: 1,
  },
  {
    texto: 'NAVE 2',
    value: 2,
  },
]
export const camion = [
  {
    value: 'BUENO',
  },
  {
    value: 'MALO',
  },
]
export const booleano = [
  {
    texto: 'SI',
    value: 1,
  },
  {
    texto: 'NO',
    value: 0,
  },
]
export const config = {
  table: {
    tableClass: 'el-table el-table--fit el-table--striped el-table--mini el-table--border el-table--enable-row-hover el-table--enable-row-transition tabla-comprobante',
    //tableClass: 'table table-striped table-hover table-sm  table-border',
    loadingClass: 'loading',
    ascendingIcon: 'fas fa-chevron-up',
    descendingIcon: 'fas fa-chevron-down',
    detailRowClass: 'vuetable-detail-row',
    handleIcon: 'fas fa-shower',
  },
  pagination: {
    wrapperClass: 'el-pagination is-background',
    activeClass: 'el-button--primary',
    disabledClass: 'is-disabled',
    pageClass: 'el-button el-button--mini is-plain',
    linkClass: 'el-button el-button--mini is-plain',
    icons: {
      first: 'fas fa-angle-double-left grey',
      prev: 'fas fa-angle-left',
      next: 'fas fa-angle-right',
      last: 'fas fa-angle-double-right',
    },
  },
}
