<template>
    <div style="padding:0px 10px">
        <el-tabs  @tab-click="handleClick" v-model="activeName">

        <el-tab-pane label="Registro de actividad V4" name="actividad">
             <registro-actividad />

          

         </el-tab-pane>
          <!-- ---------------------------Administrar usuarios------------------------------------ -->
        <el-tab-pane label="Administrar usuarios" name="admin_user">
            <admin></admin>
            <tabla-admin-user></tabla-admin-user>
            <modal-detalles></modal-detalles>
            <modal-crear></modal-crear>
         </el-tab-pane>
          <!-- --------------------------- FIN Administrar usuarios------------------------------------ -->

          <!-- ---------------------------Configuración R.F.O------------------------------------ -->
        <el-tab-pane label="Configuración R.F.O" name="config" :disabled="(!permisos.app_rfo && !permisos.app_borrar_fotos)" >
          <div v-if="showConfig">
            <remitos-fotos></remitos-fotos>
          </div>
        </el-tab-pane>
         
          <!-- ---------------------------FIN Configuración R.F.O------------------------------------ -->

          <!-- ---------------------------Verificar HR------------------------------------ -->
        <el-tab-pane label="Verificar HR" name="verificar">
          <remitos-hr></remitos-hr>
        </el-tab-pane>
       
          <!-- ---------------------------FIN Verificar HR------------------------------------ -->
        <!-- ---------------------------Verificar HR------------------------------------ -->
        <!-- <el-tab-pane label="Notificaciones" name="notificacion">
          <notificaciones></notificaciones>
        </el-tab-pane>        -->
        <!-- ---------------------------FIN Verificar HR------------------------------------ -->

      </el-tabs>
      <!-- NUEVO MODAL BUSCAR HR Y REDIRECCIONAR A VERIFICAR HR -->
            <el-dialog title="Buscar por Hoja de ruta y Verificar" :top="'5px'"
             :visible.sync="dialogBuscarHr" width="30%">
            <el-form :model="form">
              <el-form-item label="Ingrese HR formato SSNNNNNNNN">
                <el-input v-model="form.hojaruta" ref="input" name="hojaruta"
                autocomplete="off" placeholder="Ingrese HR"  autofocus 
                :rules="[{ required: true, message: 'Obligatorio', trigger: 'blur' }]"
                @keypress.enter.native.prevent="redireccionar_hr" clearable>
                </el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogBuscarHr = false">Cancelar</el-button>
              <el-button type="primary" @click="redireccionar_hr">Buscar</el-button>
            </span>
            </el-dialog>
            <input v-hotkey="keymap" class="btn btn-default" hidden>
    </div>
</template>
<script>
import Admin from '@/components/remitos/adminUsuarios'
import TablaAdminUser from '@/components/remitos/TablaAdminUser.vue'
import ModalCrear from '@/components/remitos/modalCrear.vue'
import RemitosFotos from '@/components/remitos/remitosFotos.vue'
import RemitosHr from '@/components/remitos/remitosHr.vue'
import ModalDetalles from '@/components/remitos/modalDetalles.vue';
import registroActividad from '@/components/remitos/registroActividad.vue';
// import Notificaciones from '@/components/remitos/remitosNotificacion';
// import Notificaciones from '@/components/remitos/notificaciones/remitosNotificacionGral';

import { EventBus } from "@/event-bus";
import {HTTP} from '@/axios'
import Vue from 'vue'
import VueHotkey from 'v-hotkey'

Vue.use(VueHotkey)

export default {
    components: {
      Admin,
      TablaAdminUser,
      ModalCrear,
      RemitosFotos,
      RemitosHr,
      ModalDetalles  ,
      registroActividad,
      // Notificaciones
      // excel


    },  
 data() {
        return {
            permisos_usuario:window.localStorage.getItem("permisos"),
            permisos_view: window.localStorage.getItem('empresa'),
            btnRemito:{},
            permisos:{app_rfo:false,app_user:false,app_borrar_fotos:false},
            showConfig:false,
            activeName: 'actividad',
             // MODAL HR
            form:{hojaruta:''},
            dialogBuscarHr:false,
            // usuario : JSON.parse(window.localStorage.getItem('datos')).user,

        }
    },
     created() {      
      this.permisos_app();

    },
     beforeDestroy(){
    },
    mounted() {
       
      
    },
   
    computed: {
      keymap () {
        return {
                  // bind to hotkey 
        'F3': this.toggle
        }
      }
    },
    methods: {
      toggle () {
        this.dialogBuscarHr = (this.activeName =='actividad' ||  this.activeName =='verificar')
        // this.dialogBuscarHr = (this.activeName =='verificar')
        ? true : false
        if( this.dialogBuscarHr)
        {
            let self = this
            Vue.nextTick()
                .then(function () {
                    self.$refs.input.focus()
            })
        }

      },
      redireccionar_hr()
      {
        this.form.hojaruta = this.form.hojaruta.trim()
        if(this.form.hojaruta != '' && this.form.hojaruta.length === 10 )
        {
          let numero = parseInt(this.form.hojaruta)
          if (Number.isInteger(numero)) {
            this.$events.fire('event_hr', {hojaruta:this.form.hojaruta,noshow_input:false});
            this.activeName='verificar'
            this.dialogBuscarHr = false
            this.form.hojaruta = ''
          }else
          {
            this.$message.error('El formato de Hoja de ruta es incorrecto.');
          }
          
        }else
        {
          this.$message.error('El formato de Hoja de ruta es incorrecto.')
        }
      },
      permisos_app()
      {
          if(this.permisos_usuario.indexOf("ADMIN")!=-1)
          {
            this.permisos.app_rfo           = true;
            this.permisos.app_user          = true;
            this.permisos.app_borrar_fotos  = true;

          }
          else
          {
            this.permisos.app_rfo           = this.permisos_usuario.indexOf("APP_RFO")!=-1 ? true : false;
            this.permisos.app_user          = this.permisos_usuario.indexOf("APP_USUARIOS")!=-1?  true : false;
            this.permisos.app_borrar_fotos  = this.permisos_usuario.indexOf('APP_BORRAR_FOTOS') !=-1?  true : false;
          }
          
      },
      handleClick(tab) {  
        if(tab.name =='config' && (this.permisos.app_rfo || this.permisos.app_borrar_fotos))
        {
            this.showConfig = true;
        }else if(tab.name =='admin_user')
        {
          EventBus.$emit("cargarChoferes", true);
            HTTP.get('/guiasNew/deshabilitarChoferes')
                        .then( res => {
                            console.log(res.data)
                        })
                        .catch(error => console.log(error));
                
        }
        if(tab.name!='verificar')
        {
          this.$events.fire('limpiar_verificar',true);

        }
      }

    },
}
</script>