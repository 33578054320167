<template>
  <el-dialog
    :visible.sync="modal.visible"
    width="50%"
    @closed="limpiarForm()"
    :close-on-click-modal="false"
    style="padding-top: 0px !important"
    :close-on-press-escape="false"
    :destroy-on-close="false"
    :show-close="false"
    top="5vh">
    <el-row style="margin-top: -37px">
      <h2 style="text-align: center; padding: 0px"><i class="fas fa-file-import"></i> Importar Guias</h2>
    </el-row>
    <hr />
    <br />
    <el-form ref="destinatarioImpRef" :model="guias">
      <el-form-item style="text-align: center">
        <el-form-item style="text-align: center">
          <el-select v-model="convenio" style="width: 50%">
            <el-option v-for="(item, index) in this.convenios" :key="index" :label="item.nombre" :value="item.convenio" />
          </el-select>
        </el-form-item>
        <br />
        <el-upload
          ref="upload"
          class="upload-demo"
          drag
          action="#"
          :http-request="uploadGuias"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :on-change="changeFile"
          :limit="1"
          :on-exceed="handleExceed"
          :on-remove="onRemove">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Suelta tu archivo aquí o <em>haz clic para cargar</em></div>
          <div slot="tip" class="el-upload__tip">Solo archivos xls, xlsx o CSV con un tamaño menor de 5Mb</div>
        </el-upload>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-button type="danger" size="medium" style="width: 100%" @click="limpiarForm()" icon="el-icon-close"> Cerrar </el-button>
        </el-col>
        <el-col :md="12">
          <el-button :disabled="btnDisabled" type="success" size="medium" style="width: 100%" @click="importarGuias()" icon="el-icon-check"> Importar </el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>
import { API } from '@/plugins/api'

export default {
  name: 'guias-importar',
  props: {
    modal: { required: true },
  },
  mounted() {
    //Convenios
    this.convenios = this.modal.convenios
    //Control
    this.convenio = this.convenios[0].convenio
    if (this.convenio == null || this.convenio == '') {
      this.$swal('Atencion!', 'Debe cargar tener al menus un convenio', 'warning')
      return this.limpiarForm()
    }
  },
  data() {
    return {
      convenio: null,
      convenios: [],
      fileList: [],
      btnDisabled: true,
      guias: {
        file: null,
      },
      labelPosition: 'left',
    }
  },
  methods: {
    limpiarForm() {
      this.$events.fire('close-modal')
    },
    importarGuias() {
      this.btnDisabled = true
      //console.log('subiendo...')
      const formdata = new FormData()
      formdata.append('archivo', this.guias.file)
      formdata.append('convenio', this.convenio)
      API.post('cross/guias/import', formdata, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((response) => {
          this.btnDisabled = false
          this.$events.fire('importar-guia', response.data.data, response.data.message)
        })
        .catch((error) => {
          //console.log(error)
          this.$message({
            message: error.response.data.message,
            type: 'error',
          })
          this.fileList = []
          this.guias.file = null
        })
    },
    changeFile() {
      //console.log('P1: changeFile')
    },
    beforeUpload(file) {
      //console.log('P2: beforeUpload')
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      testmsg.toLowerCase()
      const extension = testmsg === 'xlsx' || testmsg === 'xls' || testmsg === 'csv'
      const pesoMb = (file.size / 1024 / 1024).toFixed(2)
      if (!extension) {
        this.$message({
          message: 'Los archivos cargados solo pueden estar en formato .xlsx , .xls o .csv',
          type: 'warning',
        })
        return false
      }
      if (pesoMb > 5) {
        this.$message({
          message: 'El archivo de pesar menos de 5Mb',
          type: 'warning',
        })
        return false
      }

      return true
    },
    uploadGuias(param) {
      //Uso solo para interceptar peticion y solo cargar cuando de click en importar
      //console.log('P3: UploadDestinatarios')
      this.guias.file = param.file
      this.btnDisabled = false
    },
    handleExceed() {
      this.$message({
        message: `Puede importar de a un archivo por vez.`,
        type: 'warning',
      })
    },
    onRemove() {
      //console.log('P4: onRemove')
      this.guias.file = null
      this.btnDisabled = true
    },
  },
}
</script>
