<template>
  <el-dialog
    :visible.sync="modal.visible"
    width="80%"
    @closed="limpiarForm()"
    :close-on-click-modal="false"
    style="padding-top: 0px !important"
    :close-on-press-escape="false"
    :destroy-on-close="false"
    :show-close="false"
    top="5vh">
    <el-row style="margin-top: -37px">
      <h2 style="text-align: center; padding: 0px"><i :class="this.config.icon"></i> {{ this.config.titulo }}</h2>
      <hr />
    </el-row>
    <br />
    <el-form size="small" ref="destinatarioRef" :model="destinatario" :rules="rules" label-width="150px">
      <el-row :gutter="20">
        <el-col :md="{ span: 24 }">
          <el-form-item label="Convenio:">
            <el-select v-model="destinatario.convenio" style="width: 100%">
              <el-option v-for="(item, index) in this.convenios" :key="index" :label="item.nombre" :value="item.convenio" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Codigo:" prop="codigo">
            <el-input prefix-icon="el-icon-key" type="text" :maxlength="10" v-model="destinatario.codigo" placeholder="Ingrese el codigo del destinatario" clearable ref="codigoRef" />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="CUIT:" prop="cuit">
            <el-input ref="cuitRef" v-mask="'##-########-#'" type="text" v-model="destinatario.cuit" placeholder="Ingrese el CUIT del destinatario" clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 24 }">
          <el-form-item label="Razon Social:" prop="razon_social">
            <el-input prefix-icon="el-icon-user-solid" v-model="destinatario.razon_social" :maxlength="100" placeholder="Ingrese la razón social del destinatario" clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 24 }">
          <el-form-item label="Direccion:" prop="direccion">
            <el-input v-model="destinatario.direccion" placeholder="Ingrese calle y numero del domicilio del destinatario" :maxlength="200" clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Codigo Postal:" prop="codigo_postal">
            <el-input size="small" v-mask="'####'" type="text" v-model="codigo_postal" placeholder="Ingrese los cuatro digitos del codigo postal" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Provincia:" prop="provincia" disabled>
            <div class="el-select el-select--small" style="width: 100%; line-height: 0px !important">
              <v-select
                v-model="provincia"
                :options="provincias"
                :filter="fuseSearch"
                :getOptionLabel="(option) => option.nombre"
                ref="provinciaRef"
                placeholder="Seleccione una provincia"
                :disabled="config.filtroProvincia">
                <span slot="no-options">Sin Coincidencia</span>
              </v-select>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Codigo Provincia:" prop="codeprov">
            <el-input v-model="destinatario.codeprov" disabled placeholder="Se autocompletara al seleccionar una provincia" />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 24 }">
          <el-form-item label="Localidad:" prop="localidad" disabled>
            <div class="el-select el-select--small" style="width: 100%; line-height: 0px !important">
              <v-select
                v-model="localidad"
                :options="localidades"
                :filter="fuseSearch"
                :getOptionLabel="(option) => option.nombre"
                ref="localidadRef"
                placeholder="Seleccione una localidad"
                :disabled="config.filtroLocalidad">
                <span slot="no-options">Sin Coincidencia</span>
              </v-select>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Contacto:" prop="contacto">
            <el-input v-model="destinatario.contacto" placeholder="Nombre de contacto" :maxlength="100" clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Email:" prop="email">
            <el-input prefix-icon="el-icon-message" v-model="destinatario.email" placeholder="Email no se si contacto o destinatarios" :maxlength="50" clearable />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Telefono:" prop="telefono">
            <el-input type="number" prefix-icon="el-icon-phone" v-model="destinatario.telefono" placeholder="Numero de telefono" :maxlength="25" clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="WhatsApps:" prop="celular">
            <el-input type="number" v-model="destinatario.celular" placeholder="Numero de whatsapps" :maxlength="25" clearable />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-button type="danger" size="medium" style="width: 100%" @click="limpiarForm('destinatarioRef')" icon="el-icon-close"> Cerrar </el-button>
        </el-col>
        <el-col :md="12">
          <el-button :disabled="btnDisabled" type="success" size="medium" style="width: 100%" @click="saveDestinatario('destinatarioRef')" icon="el-icon-check"> Guardar </el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>
import { API } from '@/plugins/api'
import vSelect from 'vue-select'
import Fuse from 'fuse.js'

export default {
  name: 'destinatarios-modal-form',
  components: {
    vSelect,
  },
  props: {
    modal: { required: true },
  },
  mounted() {
    this.convenios = this.modal.convenios
    this.destinatario.convenio = this.convenios[0].convenio
    switch (this.modal.tipo) {
      case 'ALTA':
        this.config.icon = 'el-icon-circle-plus'
        this.config.titulo = 'ALTA DE DESTINATARIO'
        break
      case 'EDITAR':
        this.config.icon = 'el-icon-circle-plus'
        this.config.titulo = 'EDITAR DESTINATARIO NRO ' + this.modal.row.id
        this.cargarForm(this.modal.row)
        break
    }
    this.$nextTick(() => this.$refs.codigoRef.focus())
  },
  data() {
    return {
      btnDisabled: false,
      config: {
        icon: null,
        titulo: null,
        filtroLocalidad: true,
        filtroProvincia: true,
      },
      codigo_postal: null,
      convenios: [],
      destinatario: {
        razon_social: null,
        codigo: null,
        direccion: null,
        localidad: null,
        provincia: null,
        codeprov: null,
        codigo_postal: null,
        cuit: null,
        telefono: null,
        contacto: null,
        email: null,
        celular: null,
        convenio: null,
        convenios_user: null,
      },
      localidades: [],
      provincias: [],
      provincia: null,
      localidad: null,
      rules: {
        codigo: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        razon_social: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        cuit: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        direccion: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        provincia: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        codeprov: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        localidad: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
        email: [
          {
            type: 'email',
            message: 'Ingrese una direccion de email correcta',
            trigger: ['blur'],
          },
        ],
        codigo_postal: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
      },
    }
  },
  methods: {
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['nombre'],
        shouldSort: true,
        minMatchCharLength: 1,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    getLocalidades(value) {
      this.config.filtroLocalidad = true
      this.config.filtroProvincia = true
      API.get('/aux/localidades/' + value)
        .then((response) => {
          this.provincias = response.data.data.provincias
          this.localidades = response.data.data.localidades
          this.config.filtroLocalidad = false
          this.config.filtroProvincia = false
        })
        .catch((error) => {
          console.log(error)
          this.config.filtroLocalidad = false
          this.config.filtroProvincia = false
        })
    },
    limpiarForm(FormName) {
      this.resetForm(FormName)
      this.$events.fire('close-form-destinatario')
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    isValidCUITCUIL() {
      const cuit = this.destinatario.cuit
      if (cuit.length != 13) return false

      let rv = false
      let resultado = 0
      let cuit_nro = cuit.replace('-', '')
      const codes = '6789456789'
      let verificador = parseInt(cuit_nro[cuit_nro.length - 1])
      let x = 0
      while (x < 10) {
        let digitoValidador = parseInt(codes.substring(x, x + 1))
        if (isNaN(digitoValidador)) digitoValidador = 0
        let digito = parseInt(cuit_nro.substring(x, x + 1))
        if (isNaN(digito)) digito = 0
        let digitoValidacion = digitoValidador * digito
        resultado += digitoValidacion
        x++
      }
      resultado = resultado % 11
      rv = resultado == verificador
      return rv
    },
    saveDestinatario(formName) {
      this.btnDisabled = true
      //Control CUIT
      if (this.destinatario.cuit != null && this.destinatario.cuit != '') {
        if (this.isValidCUITCUIL() == false) {
          this.btnDisabled = false
          return this.$message({
            message: 'CUIT invalido. Controle qu el CUIT ingresado sea valido.',
            type: 'warning',
          })
        }
      }
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          this.btnDisabled = false
          return this.$message({
            message: 'Por favor. Controle los campos obligatorios.',
            type: 'error',
          })
        }
        switch (this.modal.tipo) {
          case 'ALTA':
            //this.destinatario.cuit = this.destinatario.cuit.replace("-",'').replace("-",'')
            API.post('cross/destinatarios', this.destinatario)
              .then((response) => {
                this.limpiarForm(formName)
                this.$message({
                  message: response.data.message,
                  type: 'success',
                })
                this.$events.fire('refrescarTabla')
              })
              .catch((error) => {
                //console.log(error)
                this.$message({
                  message: error.response.data.message,
                  type: 'error',
                })
                this.btnDisabled = false
              })
            break
          case 'EDITAR':
            //this.destinatario.cuit = this.destinatario.cuit.replace("-")
            API.put('cross/destinatarios/' + this.modal.row.id, this.destinatario)
              .then((response) => {
                this.limpiarForm(formName)
                this.$message({
                  message: response.data.message,
                  type: 'success',
                })
                this.$events.fire('refrescarTabla')
              })
              .catch((error) => {
                //console.log(error)
                this.$message({
                  message: error.response.data.message,
                  type: 'error',
                })
                this.btnDisabled = false
              })
            break
        }
      })
    },
    cargarForm(row) {
      this.destinatario = row
      this.destinatario.convenios_user = this.modal.convenios_user
      this.codigo_postal = row.codigo_postal
      //Instancio Objeto Provincia
      let provinciaObj = new Object()
      provinciaObj.nombre = row.provincia
      provinciaObj.codigo = row.codeprov
      setTimeout(() => {
        this.provincia = provinciaObj
      }, 50)
      //Instancio Objeto Localidad
      let localidad = new Object()
      localidad.nombre = row.localidad
      setTimeout(() => {
        this.localidad = localidad
      }, 50)
    },
  },
  watch: {
    codigo_postal() {
      if (this.codigo_postal != null) {
        if (this.codigo_postal.length >= 4) {
          this.destinatario.codigo_postal = this.codigo_postal
          API.get('/aux/localidades/' + this.codigo_postal)
            .then((response) => {
              if (response.data.data == null) {
                this.localidades = []
                this.provincias = []

                this.destinatario.provincia = null
                this.destinatario.localidad = null
                this.destinatario.codeprov = null

                this.localidad = null
                this.provincia = null

                this.config.filtroLocalidad = true
                this.config.filtroProvincia = true

                this.$message({
                  message: 'La Provincia o Localidad ingresada, no existe.',
                  type: 'warning',
                })
              } else {
                //this.destinatario.codeprov = response.data.data.provincia.codigo ////REVISAR!!!!
                this.localidades = response.data.data.localidades
                this.provincias = response.data.data.provincias
                this.config.filtroLocalidad = false
                this.config.filtroProvincia = false
              }
            })
            .catch((error) => {
              console.log(error)
              this.localidades = []
              this.provincias = []

              this.destinatario.provincia = null
              this.destinatario.localidad = null
              this.destinatario.codeprov = null

              this.localidad = null
              this.provincia = null

              this.config.filtroProvincia = true
              this.config.filtroLocalidad = true
              this.$message({
                message: 'La Provincia o Localidad ingresada, no existe.',
                type: 'warning',
              })
            })
        } else {
          this.localidades = []
          this.provincias = []

          this.destinatario.provincia = null
          this.destinatario.localidad = null
          this.destinatario.codeprov = null

          this.localidad = null
          this.provincia = null

          this.config.filtroProvincia = true
          this.config.filtroLocalidad = true
        }
      }
    },
    localidad() {
      if (this.localidad != null) {
        this.destinatario.localidad = this.localidad.nombre
      } else {
        this.destinatario.localidad = null
      }
    },
    provincia() {
      if (this.provincia != null) {
        this.destinatario.provincia = this.provincia.nombre
        this.destinatario.codeprov = this.provincia.codigo
      } else {
        this.destinatario.provincia = null
        this.destinatario.codeprov = null
      }
    },
  },
}
</script>
