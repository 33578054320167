<template>
  <div>
    <el-tooltip class="item" effect="dark" content="Editar" placement="left">
      <el-button size="mini" @click="editContacto(rowData)" icon="el-icon-edit" type="success" />
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Eliminar" placement="right">
      <el-button size="mini" @click="eliminarContacto(rowData)" icon="el-icon-delete" type="danger" />
    </el-tooltip>
  </div>
</template>
<script>
import { API } from '@/plugins/api'
export default {
  name: 'agenda-actions',
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },
  methods: {
    editContacto(row) {
      this.$events.fire('open-form-agenda', 'EDITAR', row)
    },
    eliminarContacto(row) {
      this.$swal({
        title: `¿Desea eliminar los datos de precarga del Convenio: #${row.convenio_id}?`,
        text: `${row.convenio.nombre}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Borrar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          API.delete('/turnero/agendas/' + row.id)
            .then((response) => {
              this.$swal.fire(response.data.message, '', 'success')
              this.$events.fire('refrescar-tabla')
            })
            .catch((error) => {
              //console.log(error.data.message)
              this.$swal(error.response.data.message, 'No se realizó la acción', 'error')
            })
        } else {
          this.$swal('Accion Cancelada', 'No se realizo ninguna accion', 'info')
        }
      })
    },
  },
}
</script>
