<template>
  <div style="background: #fff; padding: 0px 10px">
    <div v-if="ol_multi">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Comprobantes" name="listado">
          <buscar-comprob :ol_multi="ol_multi"></buscar-comprob>
        </el-tab-pane>
        <el-tab-pane label="Facturas" name="factura" v-if="user_facturas"> <facturasPdf></facturasPdf></el-tab-pane>
        <el-tab-pane v-if="subir_comprob" label="Subir Comprobante" name="comprob">
          <subir-comprobante />
        </el-tab-pane>
        <el-tab-pane v-if="zip_permiso" label="Descargar PDF" name="excel">
          <excel></excel>
        </el-tab-pane>
        <el-tab-pane label="Índices" name="buscador" v-if="ol_buscador">
          <buscadorComprobantes :sucProps="sucSelect"></buscadorComprobantes>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div v-if="admin">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Comprobantes" name="listado">
          <buscar-comprob></buscar-comprob>
        </el-tab-pane>
        <el-tab-pane label="Facturas" name="factura" v-if="user_facturas"> <facturasPdf></facturasPdf></el-tab-pane>
        <el-tab-pane label="Resumen de escaneo" v-model="editableTabsValue" name="resumen">
          <resumen-escaneo :sucProps="sucSelect" :showSuma="showDatos"></resumen-escaneo>
        </el-tab-pane>
        <el-tab-pane label="Sincronización de comprobantes" name="sincro">
          <sincronizar-comprobantes />
        </el-tab-pane>
        <el-tab-pane label="Configuraciones y convenciones" name="config">
          <configuracion-comprobantes />
        </el-tab-pane>
        <el-tab-pane v-if="subir_comprob" label="Subir Comprobante" name="comprob">
          <subir-comprobante />
        </el-tab-pane>
        <el-tab-pane v-if="zip_permiso" label="Descargar PDF" name="excel">
          <excel></excel>
        </el-tab-pane>
        <el-tab-pane label="Índices" name="buscador" v-if="ol_buscador">
          <buscadorComprobantes :sucProps="sucSelect"></buscadorComprobantes>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import BuscarComprob from '@/components/escaner/buscarComprob.vue'
import sincronizarComprobantes from '@/components/escaner/sincronizarComprobantes.vue'
import configuracionComprobantes from '@/components/escaner/configuracionComprobantes.vue'
import ResumenEscaneo from '@/components/escaner/resumenEscaneo.vue'
import { HTTP } from '@/axios'
import { EventBus } from '@/event-bus'
import SubirComprobante from '../components/escaner/subirComprobante.vue'
import excel from '@/components/escaner/excelUpload.vue'
import buscadorComprobantes from '@/components/escaner/buscadorComprobantes.vue'
import facturasPdf from '@/components/escaner/suizo/facturasPdf.vue'

export default {
  components: {
    BuscarComprob,
    sincronizarComprobantes,
    configuracionComprobantes,
    ResumenEscaneo,
    SubirComprobante,
    excel,
    buscadorComprobantes,
    facturasPdf,
  },
  data() {
    return {
      activeName: '',
      editableTabsValue: 'k',
      sucSelect: [],
      empresa: window.localStorage.getItem('empresa'),
      showDatos: false,
      user_permisos: JSON.parse(window.localStorage.getItem('datos')).permisos,
      ol_multi: false,
      admin: false,
      operador: JSON.parse(window.localStorage.getItem('datos')).user,
      subir_comprob: false,
      zip_permiso: false,
      ol_buscador: false,
      ol_indice: false,
      user_facturas: false,
    }
  },
  mounted() {
    this.getSucursales()
    this.asignarVista()
    EventBus.$emit('actualizarComprobantes', true)
    if (window.localStorage.getItem('comprob') != null && window.localStorage.getItem('comprob') != '') {
      this.activeName = 'buscador'
    } else {
      this.activeName = 'listado'
    }
    // if(typeof this.$route.query.comprob !='undefined' && this.$route.query.comprob!='')
    // {
    //     this.activeName='buscador'
    // }else{
    //     this.activeName='listado'
    // }
  },
  methods: {
    handleClick(tab) {
      this.showDatos = false
      if (tab.name == 'resumen') {
        this.showDatos = true
        EventBus.$emit('pagPrincipal', true)
      }
      if (tab.name == 'listado') {
        this.showDatos = true
        EventBus.$emit('actualizarComprobantes', true)
      }
    },
    getSucursales() {
      HTTP.post('/guiasnew/getSucursales', { empresa: this.empresa })
        .then((res) => {
          if (res.data) {
            var arraySuc = Object.values(res.data)
            var suc_filter = []
            /* Quitar las siguientes sucursales 25,92,93,99 */
            suc_filter = arraySuc.filter((element) => element.codigo != 25 && element.codigo != 92 && element.codigo != 93 && element.codigo != 99 && element.codigo != 31)
            // console.log('filtrados aaa',suc_filter)
            EventBus.$emit('sucursales', res.data)
            setTimeout(() => {
              this.sucSelect = suc_filter
            }, 600)
          }
        })
        .catch((error) => this.$alert('Error del servidor ' + error))
    },
    asignarVista() {
      let ol_multi = this.user_permisos.includes('OL_MULTI') || this.user_permisos.includes('OL') || this.user_permisos.includes('OL_LISTACONV')
      let admin = this.user_permisos.includes('ADMIN')
      let escaner = this.user_permisos.includes('ESCANER')
      this.ol_buscador = this.user_permisos.includes('OL_MULTI') || this.user_permisos.includes('ESCANER') || this.user_permisos.includes('ADMIN')
      this.zip_permiso = this.user_permisos.includes('COMPROB_ZIP') || this.user_permisos.includes('ADMIN')
      this.subir_comprob = this.user_permisos.includes('SUBIR_COMPROB')
      if (admin || escaner) {
        this.admin = true
      } else if (ol_multi) {
        this.ol_multi = true
      }
      this.ol_buscador = this.user_permisos.includes('OL_MULTI') || this.user_permisos.includes('ESCANER') || this.user_permisos.includes('ADMIN')
      this.user_facturas = this.user_permisos.includes('WEB_FAC_ABBVIE') && this.empresa === 'u'
    },
  },
}
</script>
