import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/index.js'
import './helper/index.js'
import VueGtag from "vue-gtag"

Vue.config.productionTip = false
const isProd = process.env.NODE_ENV ==='production'
    console.log(isProd)
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_ANALITYCS,
  params: {
    send_page_view: false
  },
  enabled:isProd,
}
},router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
