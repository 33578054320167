<template>
    <div>
        <el-card shadow="never" :body-style="{ padding: '0px', minHeight:'350px' }">
        <div slot="header">
        <el-row :gutter="10"><span><i class="el-icon-search"></i> Buscador de Índices</span><hr></el-row>
        <!-- <el-row :gutter="10"><hr></el-row> -->
        <el-form ref="form" :model="formIndice" label-width="120px" size="mini" style="padding-top:10px;padding-bottom: 0px;" label-position="left" >
            <el-form-item label="Buscar Fecha">
                 <el-date-picker
                v-model="formIndice.fecha_buscar"
                type="month"
                value-format="yyyyMM"
                format="MM-yyyy"
                placeholder="Seleccione mes" size="small" :disabled="disTipo">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="Buscar indice">
                <el-input v-model="formIndice.search" size="small" placeholder="Buscar indice" clearable
                    @keypress.native.enter="getIndicesMostrar"></el-input>
            </el-form-item>
            <!-- <el-form-item label="Tipo">
                <el-select v-model="formIndice.selectEstado" placeholder="Filtrar por estado" clearable :disabled="disTipo">
                    <el-option 
                    v-for="item in options"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item size="large">
            <el-button @click="getIndicesMostrar()" style="float:right;margin-bottom: -15px;" size="small" type="primary" :loading="buscando_load"> Buscar</el-button>
            </el-form-item>
        </el-form>
        </div>
        <div style="margin-top:-10px;">
        <el-table  :data="indicesxmes.filter(data => !search || data.estado.toLowerCase().includes(search.toLowerCase()))"
             height="300" size="mini"  border stripe fit  style="width: 100%;" v-loading="buscando_load">
            <el-table-column
                prop="fecha"
                label="Fecha"
                width="150">
                <template slot-scope="scope">
                        <span v-if="scope.row.fecha">
                            {{ scope.row.fecha.date | moment }}
                        </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="indice"
                label="Índice"
                >
            </el-table-column>
            <el-table-column
                prop="estado"
                label="Estado" width="100" align="center">
                <template slot-scope="scope">
                    <span style="text-transform: uppercase;">{{scope.row.estado}}</span>
                </template>
            </el-table-column>
            <el-table-column
                align="center">
                 <template slot="header" slot-scope="{}">
                    <el-input
                    v-model="search"
                    size="mini" clearable
                    placeholder="Filtrar Estado"/>
                </template>
                <template slot-scope="scope">
                    <el-button v-if="scope.row.estado!='procesados'"
                    size="mini" type="danger" :loading="cargandoVer == scope.row.indice" 
                    @click="buscarIndice(scope.row)" icon="el-icon-search">Ver</el-button>
                    <el-button v-else
                    size="mini" type="success" :loading="cargandoVer == scope.row.indice" 
                    @click="buscarIndice(scope.row)" icon="el-icon-search">Ver</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="cant_datos>0" background layout="prev, pager, next" :page-size="20" style="margin:20px 0px ;float:right" 
        :total="cant_datos" @current-change="nextPage" :current-page.sync="pagina">
		</el-pagination>
        </div>
        </el-card>
        <el-dialog title="Datos de Índice" :visible.sync="dialogDatos"  width="55%" center top="0vh">
            <hr style="margin-top: -25px;">
            <h3 style="text-align:center;">{{indiceB}}</h3>
            <h3 style="color: firebrick;" >Ubicación actual : {{carpetaB}}</h3>
            <el-table
                :data="datosIndices"
                style="width: 100%" height="300">
                <el-table-column
                    prop="comprob"
                    label="Comprobantes"
                    width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.comprob && scope.row.errores.length==0">{{scope.row.comprob}}</span>
                        <span v-if="scope.row.comprob && scope.row.errores.length" style="color: firebrick;">{{scope.row.comprob}}</span>
                        <span  v-if="scope.row.comprob == ''" >-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="fecha_escaneo_2"
                    label="Fecha escaneo"
                    width="200">
                     <template slot-scope="scope">
                         <div v-if="scope.row.archivo.length">
                            <span v-if="scope.row.fecha_escaneo_2 && scope.row.archivo[0].fecha_arch==scope.row.fecha_comparar" >{{scope.row.fecha_escaneo_2}}</span>
                            <span v-if="scope.row.fecha_escaneo_2 && scope.row.archivo[0].fecha_arch!=scope.row.fecha_comparar" style="color: firebrick;">
                                {{scope.row.fecha_escaneo_2}} - (Ver fecha archivo)
                            </span>
                            <span v-if="scope.row.fecha_escaneo_2 == '' ">-</span>
                        </div>   
                        <div v-else>
                            {{scope.row.fecha_escaneo_2}}
                        </div>                     
                    </template>
                </el-table-column>
                <el-table-column
                    prop="archivo"
                    label="PDFs asociados al Índice">
                    <template slot-scope="scope">
                        <div>
                            
                        </div>
                        <div v-if="scope.row.archivo.length" >
                            <div  v-for="arch in scope.row.archivo" :key ="arch.comprobante+1">
                                <i class="el-icon-caret-right"></i> <span v-if="arch.fecha_arch">{{arch.archivo}}</span>
                            </div>
                        </div>
                        <div v-else><span>-</span></div>
                    </template>
                </el-table-column>
                </el-table>
         <!-- <div v-for="dato in datosIndices" :key="dato.comprob" class="text item">
          <h4 v-if="dato.existError" style="color:#cd1f13">
              <i class="el-icon-caret-right"></i>Comprobante:<span style="font-weight: 200;"> {{dato.comprob}}</span> 
            - Fecha Escaneo: <span v-if="dato.fecha_escaneo!=''" style="font-weight: 200;">{{ dato.fecha_escaneo_2}}
            </span>
            - PDF: <span v-if="dato.archivo!=''" style="font-weight: 200;">{{ dato.archivo}}
            </span>
            </h4>    
          <h4 v-else><i class="el-icon-caret-right"></i>
          Comprobante:<span style="font-weight: 200;"> {{dato.comprob}}</span> 
          - Fecha Escaneo: <span v-if="dato.fecha_escaneo!=''" style="font-weight: 200;">{{ dato.fecha_escaneo_2}}</span>
          - PDF: <span v-if="dato.archivo!=''" style="font-weight: 200;">{{ dato.archivo}} </span>
          </h4>    

        </div> -->
         
        <!-- <div>
            <i class="el-icon-check"></i>Comprobante:<span v-if="dato.comprob"> Comprobantes sin datos</span><span v-else> - </span>
            <i class="el-icon-check"></i>Conforme <span v-if="dato.conforme"> Comprobantes sin datos</span><span v-else> - </span>conforme: "conforme vacio"
            <i class="el-icon-check"></i>convol: "convol vacio"
            <i class="el-icon-check"></i>cuit: "cuit vacio"
            <i class="el-icon-check"></i>fecha_remito: "fecha_remito vacio"
            <i class="el-icon-check"></i>folder: "carpeta no existe"
            <i class="el-icon-check"></i>tipo: "tipo vacio"
        </div> -->
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogDatos = false" type="primary" icon="el-icon-close">Cerrar</el-button>
            <el-button @click="procesarNuevo" v-if="comparacion && estadoIndice!='procesados'" type="warning" 
            :loading="procesar" icon="el-icon-refresh-right">
                Volver a procesar
            </el-button>
        </span>
        </el-dialog>
        <!-- <el-card shadow="never" :body-style="{ padding: '10px', minHeight:'500px' }">
                 <div slot="header">
                     <span>Buscador de Índices</span>
                    <el-input
          v-model="search"
          size="mini"
          placeholder="buscar indice"
          @keypress.native.enter="buscarIndice"
          >
             <el-button slot="append"  icon="el-icon-search" :loading="buscando" @click="buscarIndice"></el-button> 
             <el-button slot="append" icon="el-icon-close" :loading="buscando" @click="search = ''; buscarIndice()"></el-button> -->
             <!-- <el-button slot="append" icon="el-icon-refresh" @click="getIndices"></el-button> -->
                    <!-- </el-input>
                      
                 </div>
                  <div :class="[buscando ? 'centrarLoader' : '']" v-loading="buscando">
                       <el-card shadow="never" v-if="indices && indices.pendientes &&  indices.pendientes.length" :body-style="{ padding: '5px' }">
                           <ul class="indice-estado" v-for="i in indices.pendientes" :key="i.path">
                               <li >Ubicación: <ul>
                                                  <li v-for="p in i.path.split('/')" :key="p">
                                                    {{p}}
                                                  </li>
                                               </ul></li>
                               <li >Estado: <strong> Pendiente de procesar</strong></li>
                               <li >Fecha: <strong>{{i.day}} {{i.month}} {{i.time}}</strong></li>

                           </ul>
                       </el-card>
                        <el-card shadow="never" v-if="indices && indices._errores_ &&  indices._errores_.length" :body-style="{ padding: '5px' }">
                           <ul class="indice-estado" v-for="i in indices._errores_" :key="i.path">
                               <li >Ubicación: <ul>
                                                  <li v-for="p in i.path.split('/')" :key="p">
                                                    {{p}}
                                                  </li>
                                               </ul></li>
                               <li >Estado: <strong> Procesado con errores</strong></li>
                               <li >Fecha: <strong>{{i.day}} {{i.month}} {{i.time}}</strong></li>

                           </ul>
                       </el-card>
                        <el-card shadow="never" v-if="indices && indices._incompletos_ &&  indices._incompletos_.length" :body-style="{ padding: '5px' }">
                           <ul class="indice-estado" v-for="i in indices._incompletos_" :key="i.path">
                               <li >Ubicación: <ul>
                                                  <li v-for="p in i.path.split('/')" :key="p">
                                                    {{p}}
                                                  </li>
                                               </ul></li>
                               <li >Estado: <strong> Procesado parcialmente</strong></li>
                               <li >Fecha: <strong>{{i.day}} {{i.month}} {{i.time}}</strong></li>

                           </ul>
                       </el-card>
                         <el-card shadow="never" v-if="indices && indices._procesados_ &&  indices._procesados_.length" :body-style="{ padding: '5px' }">
                           <ul class="indice-estado" v-for="i in indices._procesados_" :key="i.path">
                               <li >Ubicación: <ul>
                                                  <li v-for="p in i.path.split('/')" :key="p">
                                                    {{p}}
                                                  </li>
                                               </ul></li>
                               <li >Estado: <strong> Procesado correctamente</strong></li>
                               <li >Fecha: <strong>{{i.day}} {{i.month}} {{i.time}}</strong></li>

                           </ul>
                       </el-card> -->
                  <!-- </div>
                  
               
 </el-card> -->
    </div>
</template>

<script>
import {HTTP} from "@/axios"
const moment = require('moment')

    export default {
        props:['indice'],
        data() {
            return {
                buscando:undefined,
                loading:true,
                disableBtnSubir:false,
                indices: [],
                texto:'',
                carpeta:'/',
                empresa: window.localStorage.getItem("empresa") == "u" ? "DSA" : "LOG",
                
                indicesxmes : [],
                buscando_load:false,
                cant_datos:0,
                pagina:1,
                usuario:JSON.parse(window.localStorage.getItem("datos"))['user'],
                formIndice: {
                    search:'',
                    fecha_buscar:'',
                    selectEstado:''
                },
                dialogDatos:false,
                options:[{key:0,label:'TODOS',value:null},
                {key:1,label:'INCOMPLETOS',value:'incompletos'},
                {key:2,label:'ERRORES',value:'error'},
                {key:3,label:'PROCESADOS',value:'procesados'}],
                datosIndices:'',
                indiceB:'',
                carpetaB:'',
                archivosPdfs:'',
                cargandoVer:false,
                comparacion:false,
                search:'',
                disTipo:false,
                procesar:false,
                estadoIndice:''
               
            }
        },
        created() {

           
        
        },
        methods: {
          
            buscarIndice(row)
            {
                this.cargandoVer    = row.indice
                this.comparacion    = false
                this.estadoIndice   = row.estado    
                 this.indiceB  = ''
                HTTP.post('/robotescaner/buscarIndice', {indice: row.indice,estado:row.estado})
                   .then(res => {
                    //    console.log(res.data)
                       if(!res.data)
                       {
                            //this.$message.error('El archivo no contiene datos '+row.indice);
                            this.dialogDatos        = true
                            this.cargandoVer        = false
                            this.datosIndices       = []
                            this.indiceB            = row.indice
                            this.carpetaB           = 'CARPETA ' +row.estado.toUpperCase()
                       }else if(res.data!=2)
                       {

                           this.dialogDatos         = true
                           this.cargandoVer         = false
                           if(res.data!=3)
                           {
                                this.archivosPdfs        = res.data.archivos
                                let anio="", mes="", dia="",hora
                                let archivos_completo = []
                            
                                /* Separar los datos del archivo */
                                if(res.data.archivos != null)
                                {               
                                    let fecha_arch = ''                         
                                    res.data.archivos.forEach((element2) => {
                                        let separar =[] 
                                        separar = element2.split('-')
                                        fecha_arch = separar[separar.length-1].substring(0,separar[separar.length-1].length-4)
                                        archivos_completo.push({'comprobante' :separar[1],'archivo':element2,'fecha_arch':fecha_arch})
                                    });
                                }
                                // console.log(archivos_completo)
                                res.data.indice.forEach((element) => {         
                                    anio    = element.fecha_escaneo.substring(0,4)
                                    mes     = element.fecha_escaneo.substring(4,6)
                                    dia     = element.fecha_escaneo.substring(6,8)
                                    hora    = element.fecha_escaneo.substring(8, element.fecha_escaneo.length)
                                    element.fecha_escaneo_2 = dia+'-'+mes+'-'+anio+hora
                                    element.existError      = element.errores.length ? true : false
                                    element.fecha_comparar  = element.fecha_escaneo.substring(6,8)+element.fecha_escaneo.substring(4,6)+element.fecha_escaneo.substring(2,4)
                                    /* acomodar los archivos */
                                    element.archivo         = archivos_completo.filter(elemento => (elemento.comprobante == element.comprob || elemento.comprobante == element.comprob1))
                                    // element.comparacion     = archivos_completo.filter(elemento => elemento.fecha_arch != element.fecha_comparar).length ? true : false
                                    element.indice          = row.indice
                                    element.estado          = row.estado
                                });
                        
                                this.datosIndices    = res.data.indice
                                this.indiceB         = row.indice
                                this.carpetaB        = 'CARPETA ' +res.data.carpeta.toUpperCase()
                            /* Busco si alguno no cumple con la condicion deque la fecha y la fecha que contiene el nombre del pdf
                                sean iguales, si es así habilito para procesar nuevamente*/
                                // this.comparacion     = res.data.indice.filter(indice => indice.comparacion == true).length ? true : false;
                                if(res.data.indice.length)
                                {
                                    this.comparacion = archivos_completo[0].fecha_arch != res.data.indice[0].fecha_comparar ? true : false
                                }
                           }else
                           {
                                this.datosIndices    = []
                                this.indiceB         = row.indice
                                this.carpetaB        = 'No se encontró en  CARPETA '+row.estado.toUpperCase()
                           }
                           
                       }else
                       {
                            this.$message.success('El archivo ya fue procesado '+row.indice);
                            this.cargandoVer         = false
                       }
                   })
            },
            procesarByIndice(indice)
            {
                this.subiendo = indice
                HTTP.get('/robotescaner/forzarSincroByIndex?index=' + indice)
                    .then(res => {
                        console.log(res);
                        //  window.sessionStorage.removeItem('indices')
                        //  this.indices = []
                        //  setTimeout(() => {
                        //       this.getIndices()
                            //  this.subiendo = undefined
                        //  }, 150);
                         
                    })
                    .catch(() => {
                         this.subiendo = undefined
                    })
            },
            getIndicesMostrar(page)
            { 
                this.buscando_load = true
                let comienzo = '', final = '',fecha_b = []
                this.disTipo = false
                if(page == '' || page == null)
                {
                    page = 1

                }
                this.pagina = page;
                if(this.formIndice.fecha_buscar == null || this.formIndice.fecha_buscar == '')
                {                   
                    comienzo            = this.$moment().startOf('month').format('YYYYMMDD');
                    final               = this.$moment().format('YYYYMMDD')
                }
                else
                {
                    comienzo    =  this.$moment(this.formIndice.fecha_buscar).startOf('month').format('YYYYMMDD')
                    final       = this.$moment(this.formIndice.fecha_buscar).endOf('month').format('YYYYMMDD')
                }    
                fecha_b = [comienzo,final];  
                if(this.formIndice.search != '')
                {
                    this.disTipo = true
                    HTTP.post('/robotescaner/buscarunIndice',
                    {empresa:this.empresa,
                        indice:this.formIndice.search})
                    .then(res => {
                        this.indicesxmes    = []
                        let buscar = []
                        res.data.forEach(element => {
                            let existe = ''
                            existe = this.indicesxmes.find(ab => ab.indice == element.indice)
                            if(existe == undefined)
                            {
                                buscar = (res.data.filter(busca => busca.indice == element.indice))
                                if(buscar.length>1)
                                {
                                    this.indicesxmes.push(buscar[0])
                                }else
                                {
                                    this.indicesxmes.push(buscar[0])
                                }
                            }
                        });
                        this.buscando_load  = false
                        this.cant_datos = res.data.length ? res.data[0].TotalRows : 0;
                    })
                }else
                {
                    HTTP.post('/robotescaner/mostrarIndiceMes_2',
                    {empresa:this.empresa,
                        fecha:fecha_b,
                        pagina:page,
                        indice:this.formIndice.search,
                        estado : this.formIndice.selectEstado})
                    .then(res => {
                        this.indicesxmes    = []
                        let buscar = []
                        res.data.forEach(element => {
                            let existe = ''
                            existe = this.indicesxmes.find(ab => ab.indice == element.indice)
                            if(existe == undefined)
                            {
                                buscar = (res.data.filter(busca => busca.indice == element.indice))
                                this.indicesxmes.push(buscar[0])   
                            }
                        });
                        this.buscando_load  = false
                        this.cant_datos = res.data.length ? res.data[0].TotalRows : 0;  
                    }).catch(() => {
                         this.buscando_load  = false
                    })
                }

                // .catch( e => {
                //     this.$alert('No se pudo realizar la sincronización ', e)
                // })

            },
            groupBy(xs, key) {
                return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
                }, {});
            },
            fecha: function(date) {
                return this.$moment(date).format('DD-MM-YYYY');
            },
            nextPage(page)
            {
                this.getIndicesMostrar(page)
            },
            procesarNuevo()
            {
                this.procesar = true
                HTTP.post('/robotescaner/actualizarItemFTP',{empresa:this.empresa,indices:this.datosIndices})
                .then(res => {
                    // console.log(res.data)
                    this.procesar = false
                    if(res.data.estado == "procesados")
                    {
                        this.$message({
                        message: 'Se realizó la actualización',
                        type: 'success'
                        });
                        this.dialogDatos = false
                        setTimeout(() => {
                            this.getIndicesMostrar(1)

                        }, 300);
                    }else if(res.data.estado == "incompletos")
                    {
                        this.$message({
                        message: res.data.msj,
                        type: 'danger'
                        });
                    }else
                    {
                        this.$message({
                        message: 'Error. No se pudo procesar los comprobantes. Verifique que esten correctos.',
                        type: 'danger'
                        });
                    }
                }) .catch((error) => {
                    this.procesar = false;
                    console.log(error);
                });
            }
        },
        watch:{
            formIndice: {
            handler(val){
            // do stuff
                if(val.search == '')
                {
                    this.disTipo = false
                }else
                {
                    this.disTipo = true
                }
            },
            deep: true
            }
        },
        filters: {
            moment: function(date) {
                return moment(date).format('DD-MM-YYYY H:mm:ss');
            },
            }
    }
</script>

<style lang="scss" scoped>

.indice-estado{
    list-style: none;
    padding: 0;
    font-size: 14px;
}
.indice-estado > li{
    border-bottom: 1px solid #f1f1f1;
    padding: 4px;
}
.centrarLoader
{
    margin-top: 30px;
}
</style>