<template>
  <div>
    <el-tooltip class="item" effect="dark" content="Editar Destinatario" placement="left">
      <el-button size="mini" @click="editDestinatario(rowData)" icon="el-icon-edit" type="success"></el-button>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Eliminar Destinatario" placement="top-start">
      <el-button size="mini" @click="eliminarDestinatario(rowData.id)" icon="el-icon-delete" type="danger"></el-button>
    </el-tooltip>
  </div>
</template>
<script>
import { API } from '@/plugins/api'
import { mapGetters } from 'vuex'
export default {
  name: 'guiasActions',
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },
  methods: {
    editDestinatario(row) {
      this.$events.fire('open-form-destinatario', 'EDITAR', row)
    },
    eliminarDestinatario(id) {
      this.$swal
        .fire({
          title: '¿Desea eliminar esta Destinatario?',
          text: 'Recuerde que no podrá revertir esta acción',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#309ED5',
          cancelButtonColor: '#f86c6b',
          confirmButtonText: 'Borrar',
          cancelButtonText: 'Cancelar',
        })
        .then((result) => {
          if (result.value) {
            API.delete('/cross/destinatarios/' + id)
              .then((response) => {
                this.$swal.fire(response.data.message, '', 'success')
                this.$events.fire('refrescarTabla')
              })
              .catch((error) => {
                console.log(error.data.message)
                this.$swal(error.response.data.message, 'No se realizó la acción', 'error')
              })
          }
        })
    },
  },
  computed: {
    ...mapGetters('Crossdocking', ['getGuiasConfig']),
  },
}
</script>
