<template>
  <div class="vstock_buscar">
    <el-row :gutter="10">
      <el-col :span="24">
        <el-button
          type="success"
          style="float: right; margin-bottom: 5px"
          @click="descargarStock"
          plain
          size="mini"
          icon="el-icon-download"
          :loading="loadBtn">
          Descargar Excel Stock
        </el-button>
      </el-col>
    </el-row>
    <el-row
      style="border-bottom: 1px solid #ddd; padding-bottom: 8px"
      :gutter="10">
      <el-col :span="24">
        <h4 style="font-size: 24px; margin: -3px">
          Consulta de stock: <span v-if="titulo != ''">"{{ titulo }}" </span
          ><span v-else></span>
        </h4>
      </el-col>
    </el-row>
    <el-form
      label-position="top"
      :model="formBuscar"
      ref="formBuscar"
      class="demo-ruleForm">
      <el-row :gutter="10">
        <el-col :md="7" :sm="12" :xs="12">
          <el-form-item label="Buscar artículo">
            <el-input
              v-model="formBuscar.campo_unico"
              size="mini"
              clearable
              placeholder="Buscar por...(lote,cod. proveedor,descripción)"
              ref="empieza_con"
              @keypress.native.enter="buscarArticulo(1)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6" :sm="12" :xs="12">
          <el-form-item label="Sucurales">
            <el-select
              style="width: 100%"
              v-model="formBuscar.sucursal"
              size="mini"
              placeholder="Seleccionar sucursal"
              clearable
              class="vpim-select">
              <el-option
                v-for="item in sucursales"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <center>
          <el-col :md="3" :sm="12" :xs="12">
            <el-form-item
              label="Stock"
              style="float: left; padding-left: 10px; text-align: center">
              <el-switch
                style="text-align: center; width: 100%"
                v-model="formBuscar.sw_stock"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="Si"
                inactive-text="No"
                size="mini">
              </el-switch
            ></el-form-item>
          </el-col>
        </center>
        <el-col :md="6" :sm="12" :xs="12">
          <el-form-item style="margin-top: 40px">
            <el-button-group>
              <el-button
                type="primary"
                @click="buscarArticulo(1)"
                icon="el-icon-search"
                size="mini"
                :loading="loadingbtn"
                >Buscar</el-button
              >
              <el-button
                @click="resetForm(1)"
                size="mini"
                icon="el-icon-close"
                :disabled="loadingbtn"
                >Limpiar</el-button
              >
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      formBuscar: {
        campo_unico: '',
        sw_stock: true,
        sucursal: '',
        cliente: JSON.parse(window.localStorage.getItem('datos'))['cliente'],
        session: JSON.parse(window.localStorage.getItem('datos')),
        pagina: 1,
        laborat: JSON.parse(window.localStorage.getItem('datos')).laborat,
      },
      loadingbtn: false,
      titulo: '',
      sucursales: [
        { label: 'TUCUMÁN', value: 'TUCUMAN' },
        { label: 'PIEDRABUENA', value: 'PIEDRABUENA' },
        { label: 'CÓRDOBA', value: 'CORDOBA' },
        { label: 'JUNIN', value: 'JUNIN' },
        { label: 'TOM-LOGIN ACONDICIONAMIENTO', value: 'TOM-LOGIN' },
        { label: 'IMPORTADORA PB ACONDICIONAMIENTO', value: 'IMPORTADORA' },
      ],
      loadBtn: false,
      pagina: 1,
    }
  },
  mounted() {
    this.$refs.empieza_con.focus()
    EventBus.$on('cambiarPagina', (g) => {
      this.buscarArticulo(g)
    })
  },
  beforeDestroy() {
    EventBus.$off('cambiarPagina', false)
  },
  methods: {
    buscarArticulo(pagina) {
      this.loadingbtn = true
      this.formBuscar.pagina = pagina
      this.titulo = this.formBuscar.campo_unico + this.formBuscar.sucursal
      EventBus.$emit('cargandoStockDSA')
      HTTP.post('/vlogisticanew/stockDSA', this.formBuscar)
        .then((res) => {
          // console.log(res.data)
          var $respuesta = []
          if (res.data != 0) {
            // let indice = 1;
            // switch (pagina) {
            //     case 1:
            //         indice =1
            //         break;
            //     default:
            //         indice =10*pagina
            //         break;
            // }
            // res.data.forEach(element => {
            //     if(indice != 1)
            //     {
            //         indice=indice+1;
            //     }
            //     element['indice'] =   indice;
            //     //
            // });
            $respuesta = res.data
          }
          // console.log($respuesta)
          EventBus.$emit('cargarTablaStockDSA', $respuesta)
          this.loadingbtn = false
        })
        .catch((error) => {
          this.loadingbtn = false
          console.log(error)
        })
    },
    descargarStock() {
      this.loadBtn = true
      // HTTP.post('/vlogisticanew/stockConsultaDSA',this.formBuscar
      HTTP.post('/vlogisticanew/stockConsultaDSA_NUEVA', this.formBuscar) //ACTUAL FUNCIÓN DE DESCARGA
        .then((res) => {
          this.loadBtn = false
          console.log(res.data)
          if (Number.isInteger(res.data)) {
            this.numberTitle = res.data
          } else if (res.data != '') {
            window.location.href = res.data
          } else {
            this.$message({
              message: 'No hay datos para descargar.',
              type: 'warning',
            })
          }
        })
        .catch((error) => {
          this.loadBtn = false
          this.$alert('Error del servidor ' + error)
        })
    },
    resetForm() {
      this.formBuscar.campo_unico = ''
      this.formBuscar.sucursal = ''
      this.formBuscar.sw_stock = true
      this.titulo = ''
      setTimeout(() => {
        EventBus.$emit('limpiarStockDSA')
      }, 500)
    },
  },
}
</script>
<style scoped>
.el-form-item {
  margin-bottom: 10px !important;
}
</style>
