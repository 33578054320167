<template>
  <div v-loading="sistema.loading" style="padding: 0px 20px">
    <el-row style="border-bottom: 1px solid #ddd; padding-bottom: 10px">
      <el-col :span="24" :xs="24">
        <el-tabs v-model="sistema.activeTab" v-if="!sistema.loading">
          <!-----------------------------Consulta de guias por crossdocking---------------------------->
          <el-tab-pane name="consulta">
            <span slot="label"><i class="el-icon-search"></i> CONSULTAS DE GUIAS POR CROSSDOCKING</span>
            <GuiasConsultas v-if="this.sistema.activeTab == 'consulta' && !sistema.loading" />
          </el-tab-pane>
          <!-----------------------------Carga Manual-------------------------------------------------->
          <el-tab-pane name="carga_manual">
            <span slot="label"><i class="el-icon-plus"></i> ALTA DE GUIAS, COMPROBANTES Y BULTOS</span>
            <GuiasCreate v-if="this.sistema.activeTab == 'carga_manual' && !sistema.loading" />
          </el-tab-pane>
          <!-----------------------------Administrar Destinatarios------------------------------------->
          <el-tab-pane name="destinatarios" v-if="this.destinatarios == true">
            <span slot="label"><i class="el-icon-notebook-1"></i> ADMINISTRADOR DE DESTINATARIO</span>
            <Destinatarios v-if="this.sistema.activeTab == 'destinatarios' && !sistema.loading" />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Destinatarios from '@/components/cross/Destinatarios/Index.vue'
import GuiasConsultas from '@/components/cross/GuiasConsultas/Index.vue'
import GuiasCreate from '@/components/cross/GuiasCreate/Index.vue'
import { API } from '@/plugins/api'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Destinatarios,
    GuiasConsultas,
    GuiasCreate,
  },
  data() {
    return {
      destinatarios: false,
      sistema: {
        activeTab: 'consulta',
        loading: true,
        tabChanging: false,
      },
      datos: {
        porte: [],
        convenios: [],
        bultos: [],
      },
    }
  },
  async created() {
    this.destinatarios = this.getDatos.permisos.includes('CD_DESTINATARIO') ? true : false
    await this.getConvenios()
  },
  methods: {
    ...mapActions('Crossdocking', ['setCrossConfig']),
    async getConvenios() {
      let convenios = []
      if (this.getDatos.guia_convenios == null || this.getDatos.guia_convenios.length <= 0) convenios.push(this.getDatos.ol_convenio)
      else convenios = this.getDatos.guia_convenios
      //Convenios
      await API.get('/cross/getConvenios')
        .then((response) => {
          let convenios_full = response.data.data.convenios
          let bultos = response.data.data.bultos
          let porte = response.data.data.porte
          this.setCrossConfig({
            convenios,
            convenios_full,
            bultos,
            porte,
          })
          this.sistema.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async createAlert() {
      try {
        let result = await this.$swal({
          title: '¿Desea abandonar la carga de guias?',
          text: 'Las guias aun no fueron confirmadas',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        })
        // SUCCESS
        return result
      } catch (e) {
        // Fail!
        console.error(e)
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.sistema.activeTab === 'carga_manual' && this.getGuiasCross.length > 0) {
      this.$swal.fire({
        title: '¿Desea abandonar la carga de guias?',
        text: 'Las guias aun no fueron confirmadas',
        icon: 'question',
        showCancelButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return next()
        },
      })
    } else {
      next()
    }
  },
  watch: {
    'sistema.activeTab'(newValue, oldValue) {
      if (oldValue === 'carga_manual' && (newValue === 'consulta' || newValue === 'destinatarios') && this.getGuiasCross.length > 0) {
        if (!this.sistema.tabChanging) {
          if (confirm('¿Desea abandonar la carga de guias? Las guias aun no fueron confirmadas')) {
            console.log('si')
            this.sistema.tabChanging = true
            console.log(newValue)
            this.$nextTick(() => {
              this.sistema.activeTab = newValue // Cambia a la nueva pestaña después de la confirmación
              this.sistema.tabChanging = false
            })
          } else {
            console.log('no')
            this.$nextTick(() => {
              this.sistema.activeTab = oldValue // Cambia a la nueva pestaña después de la confirmación
              this.sistema.tabChanging = false
            })
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters('Crossdocking', ['getGuiasCross']),
    ...mapGetters(['getDatos']),
  },
}
</script>
