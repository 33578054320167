<template>
  <div v-loading="sistema.loading">
    <turnos-filter />
    <el-row :gutter="10">
      <el-col :span="24" v-show="this.sistema.datos">
        <div class="table-responsive-sm">
          <vuetable
            ref="vuetable"
            api-url="/turnero/turnos"
            :http-fetch="myFetch"
            :fields="fields"
            pagination-path=""
            :css="css.table"
            :sort-order="sortOrder"
            :multi-sort="true"
            :append-params="moreParams"
            :per-page="20"
            detail-row-component="turnos-details-row"
            @vuetable:cell-clicked="onCellClicked"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:loading="showLoader"
            @vuetable:loaded="hideLoader"
            :row-class="onRowClass">
            <template slot="convenio" slot-scope="props">
              <div @click="mostrarDetalles(props.rowData.id)">
                <div v-if="props.rowData.convenio === null">
                  <p>{{ getImportadora.nombre }}</p>
                </div>
                <div v-else>
                  <p>{{ props.rowData.convenio.nombre }}</p>
                </div>
              </div>
            </template>
            <template slot="proveedor_convenio" slot-scope="props">
              <div @click="mostrarDetalles(props.rowData.id)">
                <div v-if="props.rowData.proveedor_convenio === null">
                  <p>-</p>
                </div>
                <div v-else>
                  <p>{{ props.rowData.proveedor_convenio }}</p>
                </div>
              </div>
            </template>
            <template slot="estado" slot-scope="props">
              <div v-if="isAdmin">
                <el-tooltip effect="dark" placement="left" v-if="props.rowData.estado < 5">
                  <div slot="content">
                    Actualizar estado a
                    {{ formatEstado(props.rowData.estado, 1).texto }}
                  </div>
                  <el-button size="mini" plain @click="$events.fire('cambiar-estado', props.rowData)" icon="el-icon-refresh" :type="formatEstado(props.rowData.estado).color">{{
                    formatEstado(props.rowData.estado).texto
                  }}</el-button>
                </el-tooltip>
                <el-tag type="danger" v-else-if="props.rowData.estado == 99" @click="mostrarDetalles(props.rowData.id)">CANCELADO</el-tag>
                <el-button v-else style="background-color: green; color: white" icon="el-icon-check" size="mini" @click="$events.fire('cambiar-estado', props.rowData)">FINALIZADO</el-button>
              </div>
              <div v-else @click="mostrarDetalles(props.rowData.id)">
                <el-tag :type="formatEstado(props.rowData.estado).color">{{ formatEstado(props.rowData.estado).texto }}</el-tag>
              </div>
            </template>
            <template slot="fecha" slot-scope="props">
              <div @click="mostrarDetalles(props.rowData.id)">
                {{ formatDate(props.rowData.fecha, 'DD-MM-YYYY') }}
              </div>
            </template>
            <template slot="hora_inicial" slot-scope="props">
              <div @click="mostrarDetalles(props.rowData.id)">
                {{ formatTime(props.rowData.hora_inicial, 'HH:mm') }}
              </div>
            </template>
            <template slot="aprox" slot-scope="props">
              <div @click="mostrarDetalles(props.rowData.id)">
                {{ props.rowData.aprox_pallets }} /
                {{ props.rowData.aprox_bultos }}
              </div>
            </template>
            <template slot="check_cliente" slot-scope="props">
              <div v-if="props.rowData.check_cliente === 1" @click="mostrarDetalles(props.rowData.id)">
                <span class="el-tag el-tag--success">SI</span>
              </div>
              <div v-else @click="mostrarDetalles(props.rowData.id)">
                <span class="el-tag el-tag--danger">NO</span>
              </div>
            </template>
            <template slot="check_frio" slot-scope="props">
              <div v-if="props.rowData.check_frio === 1" @click="mostrarDetalles(props.rowData.id)">
                <span class="el-tag el-tag--success">SI</span>
              </div>
              <div v-else @click="mostrarDetalles(props.rowData.id)">
                <span class="el-tag el-tag--danger">NO</span>
              </div>
            </template>
            <template slot="nave" slot-scope="props">
              <div v-if="props.rowData.nave === 1" @click="mostrarDetalles(props.rowData.id)">
                <span class="el-tag el-tag--primary">NAVE 1</span>
              </div>
              <div v-if="props.rowData.nave === 2" @click="mostrarDetalles(props.rowData.id)">
                <span class="el-tag el-tag--warning">NAVE 2</span>
              </div>
              <div v-if="props.rowData.nave != 1 && props.rowData.nave != 2" @click="mostrarDetalles(props.rowData.id)">
                <p>Sin asignar</p>
              </div>
            </template>
          </vuetable>
        </div>
        <div class="el-pagination is-background" style="margin-top: 20px; float: right; padding-bottom: 1rem">
          <vuetable-pagination-info ref="paginationInfo" info-class="pagination-info" info-template="Mostrando de {from}-{to} registros de un total de {total}" />
          <vuetable-pagination ref="pagination" :css="css.pagination" @vuetable-pagination:change-page="onChangePage" />
        </div>
      </el-col>
      <el-col :span="24" v-show="!this.sistema.datos">
        <el-alert type="info" show-icon :closable="false" center>
          <span slot="title">
            <p>No se encontraron turnos en la busqueda solicitada.</p>
          </span>
        </el-alert>
      </el-col>
    </el-row>
    <form-turno-modal v-if="this.sistema.modal.visible" :modal="this.sistema.modal" />
    <form-cambio-estado v-if="this.sistema.modalEstado.visible" :modal="this.sistema.modalEstado" />
    <qr-modal v-if="this.sistema.modalQr.visible" :modal="this.sistema.modalQr" />
  </div>
</template>
<script>
import { API } from '@/plugins/api'
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import { estados, config } from '@/components/turnos/_auxiliar'
import TurnosFilter from './Filter'
import TurnosActions from './Actions'
import FormTurnoModal from './FormTurnoModal'
import FormCambioEstado from './FormCambioEstado'
import moment from 'moment'
import QrModal from '@/components/turnos/Gestor/QrModal'
import TurnosDetailsRow from './TurnosDetailsRow'
import { mapGetters } from 'vuex'
import { fields, sortOrder } from './_AuxTurnos'
Vue.component('turnos-details-row', TurnosDetailsRow)
Vue.component('turnos-actions', TurnosActions)

export default {
  name: 'TurnosTable',
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    TurnosFilter,
    FormTurnoModal,
    FormCambioEstado,
    QrModal,
  },
  mounted() {
    if (this.isAdmin == true) {
      var objeto = {}
      objeto.name = '__component:turnos-actions'
      objeto.title = 'Acciones'
      objeto.titleClass = 'text-center-titulo'
      objeto.dataClass = 'text-center-data'
      objeto.width = '100px'
      this.fields.push(objeto)
      Vue.nextTick(() => this.$refs.vuetable.normalizeFields())
    }
  },
  data() {
    return {
      sistema: {
        loading: false,
        boton: 'btnCross',
        datos: false,
        modal: {
          visible: false,
          row: null,
          tipo: null,
        },
        modalQr: {
          visible: false,
          row: null,
        },
        modalEstado: {
          visible: false,
          row: null,
        },
      },
      fields,
      css: config,
      sortOrder,
      moreParams: {
        estado_select: [0, 1, 2, 3, 4],
      },
    }
  },
  methods: {
    async myFetch(apiUrl, httpOptions) {
      try {
        const datos = await API.get(apiUrl, httpOptions)
        //console.log(datos.data)
        if (datos.data.data.data.length > 0) this.sistema.datos = true
        else this.sistema.datos = false
        return datos.data
      } catch (error) {
        return console.log(error)
        //return this.$swal("Error!", error.response.data.message, "error")
      }
    },
    showLoader() {
      this.sistema.loading = true
    },
    hideLoader() {
      this.sistema.loading = false
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    onCellClicked(data) {
      if (this.$refs.vuetable.isVisibleDetailRow(data.id)) {
        this.$refs.vuetable.hideDetailRow(data.id)
      } else {
        this.$refs.vuetable.visibleDetailRows = []
        this.$refs.vuetable.showDetailRow(data.id)
      }
    },
    mostrarDetalles(value) {
      this.$events.fire('mostrar-detalles', value)
    },

    formatDate(value, fmt = 'D MMM YYYY') {
      return value == null ? 'Sin registro' : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt)
    },
    formatTime(value, fmt = 'D MMM YYYY') {
      return value == null ? 'Sin registro' : moment(value, 'HH:mm').format(fmt)
    },
    formatEstado(value, tipo = 0) {
      let estadoProps
      switch (tipo) {
        case 1:
          estadoProps = value + 1
          break
        default:
          estadoProps = value
          break
      }

      let objeto = estados.find((x) => x.estado == estadoProps)
      if (objeto === undefined) return null
      else return objeto
    },
    onRowClass(dataItem) {
      if (this.$refs.vuetable.isVisibleDetailRow(dataItem.id)) {
        //console.log("Abierto: "+dataItem.id)
        return 'el-table__row yellow-row'
      }
      //if (dataItem.estado == 2) return 'el-table__row yellow-row'
      if (dataItem.estado == 99) return 'el-table__row red-row'
      if (dataItem.estado == 3) return 'el-table__row green-row'
    },
  },
  events: {
    'filter-set'(filtros) {
      this.moreParams = filtros
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'filter-reset'() {
      this.moreParams = {
        estado_select: [0, 1, 2, 3, 4],
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'refrescar-tabla'() {
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'open-qr'(row) {
      this.sistema.modalQr.row = row
      this.sistema.modalQr.visible = true
    },
    'open-form-turno'(tipo, row) {
      if (tipo == 'ALTA') {
        this.sistema.modal.row = null
        this.sistema.modal.tipo = 'ALTA'
      }
      if (tipo == 'EDITAR') {
        this.sistema.modal.row = row
        this.sistema.modal.tipo = 'EDITAR'
      }
      this.sistema.modal.visible = true
    },
    'close-form'() {
      this.sistema.modal.row = null
      this.sistema.modalEstado.row = null
      this.sistema.modal.tipo = null
      this.sistema.modal.visible = false
      this.sistema.modalEstado.visible = false
    },
    'close-qr'() {
      this.sistema.modalQr.visible = false
    },
    'cambiar-estado'(row) {
      this.sistema.modalEstado.row = row
      this.sistema.modalEstado.visible = true
    },
    'mostrar-detalles'(value) {
      if (this.$refs.vuetable.isVisibleDetailRow(value)) {
        this.$refs.vuetable.hideDetailRow(value)
      } else {
        this.$refs.vuetable.visibleDetailRows = []
        this.$refs.vuetable.showDetailRow(value)
      }
    },
  },
  computed: {
    ...mapGetters(['getDatos']),
    ...mapGetters('Turnos', ['getImportadora']),
    isAdmin() {
      return this.getDatos.permisos.includes('TURNOS_ADMIN') || this.getDatos.permisos.includes('TURNOS_OPERARIO') ? true : false
    },
  },
}
</script>
