import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Crypto from 'crypto-js'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import Turnos from './turnos'
import Integraciones from './integraciones'
import Crossdocking from './crossdocking'
import HojaRuta from './hojaruta'

Vue.use(Vuex)
const storageKey = 'store'
const cookieName = 'cae18158bda2c54186f025c90a818082'
// Recibe el token encriptado o genera uno
const encryptionToken = Cookies.get(cookieName) || uuidv4()
// Guarda la cookie encriptada en una cookie segura
//Cookies.set(cookieName, encryptionToken, { expires: 180 })
Cookies.set(cookieName, encryptionToken) //, { expires: 180 });
const vuexPersistence = new VuexPersistence({
  //Edito le funcionamiento del storage, reescribiendo funciones
  storage: {
    getItem: () => {
      const store = window.localStorage.getItem(storageKey)

      if (store) {
        try {
          // Desencripta el store recibido desde el storage, usando el token de la cookie cifrada
          const bytes = Crypto.AES.decrypt(store, encryptionToken)

          return JSON.parse(bytes.toString(Crypto.enc.Utf8))
        } catch (e) {
          //error si todo falla
          window.localStorage.removeItem(storageKey)
        }
      }

      return null
    },
    setItem: (key, value) => {
      // Encripta el store
      const store = Crypto.AES.encrypt(value, encryptionToken).toString()

      // Guarda el store encriptado
      return window.localStorage.setItem(storageKey, store)
    },
    removeItem: () => window.localStorage.removeItem(storageKey),
  },
})

export default new Vuex.Store({
  state: {
    datos: {},
    token: '',
    tokenFake: '',
    status: false,
  },
  mutations: {
    LOGIN(state, token) {
      state.token = token
      state.status = true
    },
    LOGOUT(state) {
      state.datos = {}
      state.token = ''
      state.tokenFake = ''
      state.status = false
    },
    SET_DATOS(state, { datosTok, empresaTok }) {
      state.datos = datosTok
      state.tokenFake = btoa(datosTok.user + '|' + datosTok.uid + '|' + datosTok.cliente + '|' + datosTok.suc + '|' + empresaTok)
    },
  },
  actions: {
    login({ commit }, token) {
      commit('LOGIN', token)
    },
    logout({ commit }) {
      commit('LOGOUT')
      commit('Turnos/CLEAR_TURNERO')
      commit('Integraciones/CLEAR_INTEGRACIONES')
      commit('Crossdocking/CLEAR_CROSS')
      localStorage.removeItem(storageKey)
    },
    setDatos({ commit }, datos) {
      commit('SET_DATOS', datos)
    },
  },
  getters: {
    authStatus: (state) => state.status,
    getToken: (state) => state.token,
    getTokenFake: (state) => state.tokenFake,
    getUid: (state) => state.datos.uid,
    getDatos: (state) => state.datos,
  },
  plugins: [vuexPersistence.plugin],
  modules: { Turnos, Integraciones, Crossdocking, HojaRuta },
})
