import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio.vue'
import Guia from '../views/Guias.vue'
import Detalles from '../views/Detalles.vue'
import Presentacion from '../views/Presentacion.vue'
import Resumen from '../views/Resumen.vue'
import HojaRuta from '../views/HojaRuta.vue'
import Cuenta from '../views/Cuenta.vue'
import Comprobantes from '../views/Comprobantes.vue'
import Remitos from '../views/Remitos.vue'
import Login from '../views/Login.vue'
import PageNot from '../views/PageNotFound.vue'
import Recuperar from '../views/Recuperar.vue'
import Stock from '../views/Stock.vue'
import StockDSA from '../views/StockDsa.vue'
import User from '../views/User.vue'
import ChangePass from '../components/recuperar/CambiarPass.vue'
import PagError from '../components/pagError.vue'
import Editor from '../components/editor/editor.vue'
import store from '@/store'
import Cross from '../views/Cross.vue'
import Integraciones from '../views/Integraciones.vue'
import Turnos from '../views/Turnos.vue'
import Fm from '../components/fm/Fm.vue'
import Expirar from '../components/recuperar/ExpirarPass.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/recuperar',
    name: 'recuperar',
    component: Recuperar,
    beforeEnter: (to, from, next) => {
      var urlpp = window.location.host,
        array_url = urlpp.split('/'),
        empresa_nombre = array_url[0].split('.')
      console.log(to, from['name'], empresa_nombre)
      // next()
      if (empresa_nombre[1] == 'log-in' || empresa_nombre[1] === 'suizoargentina') {
        next()
      } else {
        next({
          name: 'PageNotFound',
        })
      }
    },
  },
  {
    path: '/cambiar',
    name: 'changepass',
    component: ChangePass,
    props: (route) => ({ query: route.query.s }),
    beforeEnter: (to, from, next) => {
      var urlpp = window.location.host,
        array_url = urlpp.split('/'),
        empresa_nombre = array_url[0].split('.')
      // console.log(to, from, empresa_nombre)
      // next()
      if (empresa_nombre[1] == 'log-in' || empresa_nombre[1] === 'suizoargentina') {
        next()
      } else {
        //  next();
        next({
          name: 'PageNotFound',
        })
      }
    },
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: Inicio,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/guias',
    name: 'guias',
    component: Guia,
    meta: { requiresAuth: true },
  },
  {
    path: '/detalles',
    name: 'detalles',
    // props:true,
    component: Detalles,
    meta: { requiresAuth: true },
  },
  {
    path: '/presentaciones',
    name: 'presentaciones',
    props: true,
    component: Presentacion,
    meta: { requiresAuth: true },
  },
  {
    path: '/resumen',
    name: 'resumenes',
    props: true,
    component: Resumen,
    meta: { requiresAuth: true },
  },
  {
    path: '/resumenhojaruta',
    name: 'resumenhojaruta',
    props: true,
    component: HojaRuta,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var olmulti = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('OL_MULTI') != -1 ? true : false
        var admin = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('ADMIN') != -1 ? true : false
      }
      // console.log('app','admin',olmulti,admin)
      if (olmulti || admin) {
        next()
      } else {
        next({
          path: '/error',
        })
      }
    },
  },
  {
    path: '/resumenhojaruta1',
    name: 'resumenhojaruta1',
    props: true,
    component: HojaRuta,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var olmulti = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('OL_MULTI') != -1 ? true : false
        var admin = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('ADMIN') != -1 ? true : false
      }
      // console.log('app','admin',olmulti,admin)
      if (olmulti || admin) {
        next()
      } else {
        next({
          path: '/error',
        })
      }
    },
  },
  {
    path: '/remitos',
    name: 'remitos',
    props: true,
    component: Remitos,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var app = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('APP_USUARIOS') != -1 ? true : false
        var admin = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('ADMIN') != -1 ? true : false
      }
      if (app || admin) {
        next()
      } else {
        next({
          path: '/error',
        })
      }
    },
  },
  {
    path: '/comprobantes',
    name: 'comprobantes',
    props: true,
    component: Comprobantes,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var escaner = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('ESCANER') != -1 ? true : false
        var admin = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('ADMIN') != -1 ? true : false
        var ol =
          JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('OL') != -1 ||
          JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('OL_LISTACONV') != -1 ||
          JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('OL_MULTI') != -1
            ? true
            : false
      }
      if (escaner || admin || ol) {
        next()
      } else {
        next({
          path: '/error',
        })
      }
    },
  },
  {
    path: '/cuenta',
    name: 'cuenta',
    props: true,
    component: Cuenta,
    meta: { requiresAuth: true },
  },
  {
    path: '/stock',
    name: 'stock',
    props: true,
    component: Stock,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      var urlpp = window.location.host,
        array_url = urlpp.split('/'),
        empresa_nombre = array_url[0].split('.')
      // console.log(to, from, empresa_nombre)
      // next()

      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var stock = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('STOCK') != -1 ? true : false
        var buscar = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('NO_BUSCA') != -1 ? true : false
        var admin = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('ADMIN') != -1 ? true : false
      }

      // if((stock || admin) && !buscar && empresa_nombre[1] == "log-in")
      if ((stock || admin) && !buscar && empresa_nombre[1] == 'log-in') {
        next()
      } else {
        next({
          path: '/error',
        })
      }
    },
  },
  {
    path: '/stockdsa',
    name: 'stockdsa',
    props: true,
    component: StockDSA,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      var urlpp = window.location.host,
        array_url = urlpp.split('/'),
        empresa_nombre = array_url[0].split('.')
      // console.log(to, from, empresa_nombre)
      // next();

      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var stock = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('STOCK') != -1 ? true : false
        // var buscar  = window.localStorage.getItem('permisos').indexOf('OL')!=-1 ? true:false;
        var admin = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('ADMIN') != -1 ? true : false
      }
      if ((stock || admin) && empresa_nombre != 'log-in') {
        next()
      } else {
        next({
          path: '/error',
        })
      }
    },
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: User,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var abm = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('OL_ABM_USUARIOS') != -1 ? true : false
      }
      if (abm) {
        next()
      } else {
        next({
          path: '/error',
        })
      }
    },
  },
  {
    path: '/cross',
    name: 'cross',
    meta: { requiresAuthVuex: true, requiresAuth: true },
    component: Cross,
    //component: () => import('@/views/Cross'),
    beforeEnter: (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var cross = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('CROSSDOCK') != -1 ? true : false
      }
      if (cross) {
        next()
      } else {
        next({ path: '/error' })
      }
    },
  },
  {
    path: '/integraciones',
    name: 'integraciones',
    meta: { requiresAuthVuex: true, requiresAuth: true },
    component: Integraciones,
    //component: () => import('@/views/Integraciones'),
    beforeEnter: (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var integraciones = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('INTEGRACIONES') != -1 ? true : false
      }
      if (integraciones) {
        next()
      } else {
        next({ path: '/error' })
      }
    },
  },
  {
    path: '/turnos',
    name: 'turnos',
    meta: { requiresAuthVuex: true, requiresAuth: true },
    component: Turnos,
    beforeEnter: (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var turnos = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('TURNOS') != -1 ? true : false
      }
      if (turnos) {
        next()
      } else {
        next({ path: '/error' })
      }
    },
  },
  {
    path: '/fm',
    name: 'fm',
    meta: { requiresAuthVuex: true, requiresAuth: true },
    component: Fm,
    beforeEnter: (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        var fm = JSON.parse(window.localStorage.getItem('datos')).permisos.indexOf('FM') != -1 ? true : false
      }
      if (fm) {
        next()
      } else {
        next({ path: '/error' })
      }
    },
  },
  {
    path: '/expirar',
    name: 'expirar',
    component: Expirar,
    props: true,
    beforeEnter: (to, from, next) => {
      var urlpp = window.location.host,
        array_url = urlpp.split('/'),
        empresa_nombre = array_url[0].split('.')
      // next()
      if (empresa_nombre[1] == 'log-in' || empresa_nombre[1] === 'suizoargentina') {
        next()
      } else {
        // next()
        next({
          name: 'PageNotFound',
        })
      }
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: PagError,
  },
  {
    path: '/editor',
    name: 'editor',
    component: Editor,
  },
  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: PageNot,
  },

  // {
  //   path: '/carros',
  //   name: 'carros',
  //   props:true,
  //   component: Carro,
  //   meta: { requiresAuth: true,},
  //   beforeEnter: (to, from, next) => {

  //     if (to.matched.some(record => record.meta.requiresAuth)) {
  //       var stock   = window.localStorage.getItem('permisos').indexOf('STOCK')!=-1 ? true:false;
  //       var buscar  = window.localStorage.getItem('permisos').indexOf('NO_BUSCA')!=-1 ? true:false;

  //     }
  //     if(stock && !buscar)
  //     {
  //       next();
  //     }
  //     else
  //     {
  //       next({
  //         path: '/',
  //       })
  //     }
  //   }
  // },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
router.beforeEach((to, from, next) => {
  //Check Auth
  if (to.matched.some((record) => record.meta.requiresAuthVuex)) {
    if (!store.getters.authStatus) {
      next({ path: '/' })
    } else {
      next()
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let auth = to.query.token ? to.query.token : window.localStorage.getItem('token')
    if (!auth) {
      next({ path: '/' })
      // path:'/login'
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
