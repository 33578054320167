<template>
  <el-button-group>
    <el-tooltip class="item" effect="dark" content="Ver detalles" placement="left">
      <el-button size="mini" @click="detalles(rowData.id)" icon="el-icon-zoom-in" type="primary"></el-button>
    </el-tooltip>
    <!-- <el-tooltip class="item" effect="dark" content="Eliminar" placement="top-start">
        <el-button size="mini" @click="eliminarGuia(rowData)" icon="el-icon-delete" type="danger" v-if="rowData.fch_proc == null"></el-button>
      </el-tooltip> -->
  </el-button-group>
</template>
<script>
//import { API } from '@/plugins/api'
//import { mapGetters } from 'vuex'
export default {
  name: 'guias-actions',
  props: {
    rowData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    detalles(value) {
      this.$events.fire('mostrar-detalles', value)
    },
    // eliminarGuia(row) {
    //   let guia = row.terminal.toString().padStart(5, '0') + ' - ' + row.numero.toString().padStart(8, '0')
    //   this.$swal({
    //     title: '¿Desea eliminar la guia: #' + guia + ' ?',
    //     text: 'Recuerde que no podrá revertir esta acción',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#309ED5',
    //     cancelButtonColor: '#f86c6b',
    //     confirmButtonText: 'Borrar',
    //     cancelButtonText: 'Cancelar',
    //     preConfirm: () => {
    //       //Agregar en BACKEND CONTROL DE CONVENIOS
    //       return API.delete('cross/guias/' + row.id, {
    //         data: { convenios: this.getGuiasConfig.convenios }, //agregar datos usuario
    //       })
    //         .then((response) => {
    //           //console.log(response.data.message)
    //           this.$events.fire('refrescarTabla')
    //           return this.$swal.fire(response.data.message, '', 'success')
    //         })
    //         .catch((error) => {
    //           //console.log(error.data.message)
    //           return this.$swal(error.response.data.message, 'No se realizó la acción', 'error')
    //         })
    //     },
    //   }).then((result) => {
    //     if (!result.value) this.$swal('Accion Cancelada', 'No se realizo ninguna accion', 'info')
    //   }) //Fin de THEN del SWAL
    // },
  },
  // computed: {
  //   ...mapGetters('Crossdocking', ['getGuiasConfig']),
  // },
}
</script>
