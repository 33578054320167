<template>
  <el-dialog :visible.sync="modal.visible" width="80%" title="Logs de errores">
    <el-row :gutter="10">
      <el-col>
        <el-table :data="this.modal.datos" border>
          <el-table-column prop="terminal" label="Terminal" width="80px">
            <template slot-scope="scope">
              <span>{{ scope.row.terminal }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="numero" label="Numero" width="80px">
            <template slot-scope="scope">
              <span>{{ scope.row.numero }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="fch_proceso" label="Fecha" width="160px">
            <template slot-scope="scope">
              <span>{{ formatDate(scope.row.fch_proceso, 'DD-MM-YYYY HH:mm:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="observa" label="Observación">
            <template slot-scope="scope">
              <span>{{ scope.row.observa }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" @click="cerrar()" icon="el-icon-close">Cerrar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import moment from 'moment'

export default {
  name: 'guiasLogsDetails',
  props: {
    modal: { required: true },
  },
  mounted() {
    //console.log(this.modal.datos)
  },
  methods: {
    formatDate(value, fmt = 'D MMM YYYY') {
      return value == null ? 'Sin registro' : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt)
    },
    cerrar() {
      this.$events.fire('cerrar-detalles-modal')
    },
  },
}
</script>
