export default {
  css: {
    table: {
      tableClass:
        'el-table el-table--fit el-table--striped el-table--mini el-table--border el-table--enable-row-hover el-table--enable-row-transition tabla-comprobante',
      //tableClass: 'table table-striped table-hover table-sm  table-border',
      loadingClass: 'loading',
      ascendingIcon: 'fas fa-chevron-up',
      descendingIcon: 'fas fa-chevron-down',
      detailRowClass: 'vuetable-detail-row',
      handleIcon: 'fas fa-shower',
    },
    pagination: {
      wrapperClass: 'el-pagination is-background',
      activeClass: 'el-button--primary',
      disabledClass: 'is-disabled',
      pageClass: 'el-button el-button--mini is-plain',
      linkClass: 'el-button el-button--mini is-plain',
      icons: {
        first: 'fas fa-angle-double-left grey',
        prev: 'fas fa-angle-left',
        next: 'fas fa-angle-right',
        last: 'fas fa-angle-double-right',
      },
    },
  },
}
