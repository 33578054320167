<template>
    <div>
<el-dialog title="Exportar Comprobantes no digitalizados" :visible.sync="dialogExcelNoDig" @close="borrarDatos" 
 :rules="rules">
    <el-form :model="formExportar" style="padding: 0px 0px !important;" 
     ref="formExportar" class="demo-ruleForm">
      <el-row :gutter="10">
          <el-col :md="12">
            <el-form-item label="Convenio" prop="convenio">
              <el-select
                v-model="formExportar.convenio" filterable  placeholder="Buscar Convenio" style="width: 100%" 
                clearable  size="small" @change="cambiando()">
                <el-option
                  v-for="item in convenios"
                  :key="item.codigo"
                  :label="item.codigo + ' - ' + item.descrip"
                  :value="item.codigo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="Sucursal" prop="sucursal">
              <el-select
                v-model="formExportar.sucursal"
                filterable
                placeholder="Buscar sucursal"   @change="cambiando()"               style="width: 100%"
                clearable size="small">
                <el-option
                  v-for="item in sucursales"
                  :key="item.codigo"
                  :label="item.codigo + ' - ' + item.nombre"
                  :value="item.codigo">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>          
      </el-row>
      <el-row :gutter="10">
          <el-col :md="12" >
            <el-form-item label="Fecha de búsqueda"  prop="fecha" :rules="[
                  {
                    required: true,
                    message: 'Elija una fecha',
                    trigger: 'blur',
                  },
                ]">
              <el-date-picker
                style="width: 100%"
                clearable
                v-model="formExportar.fecha"
                format="dd-MM-yyyy"
                value-format="yyyyMMdd"
                type="daterange"
                start-placeholder="Desde"
                end-placeholder="Hasta" size="small" :picker-options="datePickerOptions2" @change="controlFecha()">
              </el-date-picker>
              <span v-if="msj_error" class="msj-error">{{msj_error}}</span>
            </el-form-item>
          </el-col>   
           <el-col :md="12">
            <el-form-item label="Afinidad" prop="afinidad">
              <el-select
                v-model="formExportar.afinidad"
                filterable
                placeholder="Buscar afinidad" style="width: 100%"
                clearable size="small">
                <el-option
                  v-for="item in afinidades"
                  :key="item.codigo"
                  :label="item.descrip"
                  :value="item.codigo">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>   
      </el-row>
  </el-form>
  <!-- Si no hay registro mostrar alerta aqui -->
  <el-alert v-if="mensaje!=''" :title="mensaje" type="warning" center show-icon></el-alert>
  <div  v-if="msj2!=''"><el-alert title="MÁXIMA CANTIDAD" :description="msj2" type="error" center show-icon></el-alert></div>
   <span slot="footer" class="dialog-footer" style="padding-top :0px !important;">
    <el-button type="primary" @click="exportarNodigital('formExportar')" size="small" 
    icon="el-icon-download" :loading="loading_nodig">
      Exportar
    </el-button>
    <el-button @click="resetForm('formExportar')" size="small" icon="el-icon-close">Cancelar</el-button>
  </span>
  </el-dialog>
    </div>
</template>
<script>
import { HTTP } from "@/axios";
import { EventBus } from "@/event-bus";

export default {
    props:['sucursales','convenios','afinidades'],
    data(){
        return{
        dialogExcelNoDig:this.modal,
        formExportar:{
            convenio:'',
            sucursal:'',
            fecha:'',
            afinidad:'x'
        },
        rules: {
            sucursal: [{ required: true, message: 'Por favor elija una sucursal', trigger: 'change' }],
            convenio: [{ required: true, message: 'Elija un convenio', trigger: 'change' }],
        },
        msj_error:'',
        loading_nodig:false,
        datePickerOptions2: {
                disabledDate (date) {
                    return date > new Date();
                }
        },
        mensaje:'',
        msj2:'',
        // afinidades:[]
        }
    },
    mounted() {
        // this.dialogExcelNoDig = this.modal
        EventBus.$on("dialog", (g) => {
            this.dialogExcelNoDig = g.abrirModal;
            this.formExportar.convenio = g.datos.convenio != '' ?g.datos.convenio : '';
            this.msj2 = ''
            if(typeof g.datos.sucursal != 'undefined')
            {
              this.formExportar.sucursal = g.datos.sucursal
            }else
            {
              this.formExportar.sucursal = ''
            }

            if(g.datos.fecha != null)
            {
              this.formExportar.fecha = g.datos.fecha.length ? g.datos.fecha : []
            }else
            {
              this.formExportar.fecha = ''
            }
            // this.getAfinidad()
        });
    },
    beforeDestroy(){
      EventBus.$off('dialog', 0)
    },
    methods: {
    exportarNodigital(formName)
    {        
       this.mensaje  = ''
       this.msj2     = ''
       this.$refs[formName].validate((valid) => {
          
          if(this.msj_error!='')
          {
            valid = false
          }     

          if (valid) 
          {   
              this.loading_nodig = true
             let empresa = window.localStorage.getItem("empresa") == "u" ? "DSA" : "LOG"
              HTTP.post('/escaner/exportarNodig_prueba',{
              convenio    : this.formExportar.convenio, // 22202
              sucursal    : this.formExportar.sucursal, // {tipo: 1}
              fecha       : this.formExportar.fecha,
              afinidad    : this.formExportar.afinidad,
              empresa     : empresa,
              }
              ).then( res=>{
                  this.loading_nodig = false;
                  // console.log(res.data)	
                  if(res.data ==0 || res.data == 2)
                  {
                    this.mensaje = 'No hay resultados para los filtros solicitados.'
                  }
                  else if(typeof res.data == 'number' && res.data > 2)
                  {						
                    this.msj2 = 'La descarga de resultados está limitada a 20.000 comprobantes, su actual consulta arrojó un total de '+ res.data +' resultados. Puede ajustar los resultados agregando nuevos filtros o reduciendo el rango de fechas seleccionadas.'
                  }else
                  { 
                    window.location.href 		  = res.data
                  }	
              })
              .catch(error=>{
                console.log(error)
                this.$alert('No se pudo realizar la acción. Intente nuevamente');
                this.loading_nodig 		= false;
              });	

          }
      });
    },
    controlFecha()
    {
      this.msj_error = ''
      let fecha1 = this.$moment(this.formExportar.fecha[0]); //todays date
      let fecha2 = this.$moment(this.formExportar.fecha[1]); // another date
      let duracion = fecha2.diff(fecha1, 'days');
      if(duracion > 30)
      {
        this.msj_error = 'El intervalo de tiempo debe ser hasta 30 días'
      }
      this.mensaje = ''
      this.msj2 = ''

    },
    cambiando()
    {
      this.mensaje = ''
      this.msj2 = ''
    },
    borrarDatos() {
      this.formExportar.convenio = ''
      this.formExportar.sucursal = ''
      this.formExportar.fecha    = ''
    },
    resetForm(formName) {
        this.$refs[formName].resetFields();
        this.dialogExcelNoDig = false
    },
    getAfinidad()
    {
      HTTP.get("/guiasnew/getAfinidad").then((res) => {
        this.afinidades     = res.data;
        this.afinidadInicio = res.data;
      }).catch(error=>{
        console.log(error)
        this.$alert('No se pudo realizar la acción. Intente nuevamente');
      });	
    },
    },
}
</script>
<style scoped>
.msj-error{
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
}
</style>