<template>
  <div>
    <el-row :gutter="10">
      <el-card
        shadow="always"
        class="instalaciones"
        :body-style="{ padding: '0px' }">
        <el-row :gutter="5">
          <el-col
            :lg="{ span: 7 }"
            :md="{ span: 7 }"
            :sm="{ span: 8 }"
            :xs="{ span: 24 }">
            <el-row :gutter="5">
              <el-col
                :lg="{ span: 12 }"
                :md="{ span: 12 }"
                :sm="{ span: 12 }"
                :xs="{ span: 12 }"
                :loading="cargandoSincro">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Descargar Instalaciones"
                  placement="top-start">
                  <el-button
                    :loading="cargandoSincro"
                    style="
                      width: 90%;
                      max-height: 92px !important;
                      height: 92px !important;
                    "
                    plain
                    @click="descargarInstalaciones">
                    <h4>
                      <small>INSTALACIONES</small> <br />{{
                        instalaciones.length
                      }}
                    </h4>
                    <center>
                      <small style="white-space: break-spaces">{{
                        nameSucursal
                      }}</small>
                    </center>
                  </el-button>
                </el-tooltip>
              </el-col>
              <el-col
                :lg="{ span: 12 }"
                :md="{ span: 12 }"
                :sm="{ span: 12 }"
                :xs="{ span: 12 }"
                :loading="cargandoSincro">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Sincronizar Guías"
                  placement="top-start">
                  <el-button
                    :loading="cargandoSincro"
                    @click="abrirSincro"
                    :disabled="disableSincro"
                    style="
                      width: 90%;
                      max-height: 92px;
                      height: 92px !important;
                    ">
                    <div v-if="showBtnSincro">
                      <small v-if="bloqueado">Bloqueado por 10 minutos</small>
                      <h4>
                        <small style="white-space: break-spaces"
                          >GUÍAS SIN SINCRONIZAR</small
                        >
                        <br />
                        {{ pendientesSincro }}
                      </h4>
                      <center>
                        <small style="white-space: break-spaces"
                          >TODAS SUC.</small
                        >
                      </center>
                    </div>
                    <div v-else>
                      <h3 style="margin-top: 0px">
                        <small style="white-space: break-spaces"
                          >GUÍAS SIN SINCRONIZAR</small
                        >
                        <br />
                        {{ pendientesTotal.length }}
                      </h3>
                      <center>
                        <small style="white-space: break-spaces"
                          >TODAS SUC.</small
                        >
                      </center>
                    </div>
                  </el-button>
                </el-tooltip>
              </el-col>
            </el-row>
          </el-col>
          <el-col
            :lg="{ span: 17 }"
            :md="{ span: 17 }"
            :sm="{ span: 14 }"
            :xs="{ span: 24 }"
            style="padding-right: 0px">
            <el-form
              :model="filtros"
              ref="form"
              :label-position="'top'"
              :inline="true"
              size="small">
              <el-row>
                <el-col
                  :lg="{ span: 6 }"
                  :md="{ span: 5 }"
                  :sm="{ span: 12 }"
                  :xs="{ span: 12 }">
                  <el-form-item label="Sucursal">
                    <el-select
                      v-model="filtros.sucursal"
                      clearable
                      filterable
                      @change="getDatos"
                      size="mini">
                      <el-option
                        v-for="item in sucursales"
                        :key="item.codigo"
                        :label="item.codigo + ' - ' + item.nombre"
                        :value="item.codigo">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :lg="{ span: 5 }"
                  :md="{ span: 5 }"
                  :sm="{ span: 12 }"
                  :xs="{ span: 12 }">
                  <el-form-item label="Fecha">
                    <el-date-picker
                      v-model="filtros.fecha"
                      type="date"
                      size="mini"
                      value-format="yyyyMMdd"
                      format="dd-MM-yyyy"
                      style="width: 100%"
                      placeholder="Seleccione una fecha">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col
                  :lg="{ span: 4 }"
                  :md="{ span: 4 }"
                  :sm="{ span: 8 }"
                  :xs="{ span: 12 }">
                  <el-form-item label="Chofer (DNI)">
                    <el-input
                      v-model="filtros.dni"
                      placeholder="DNI"
                      size="mini"
                      clearable
                      @keypress.native.enter="filtrar"></el-input>
                  </el-form-item>
                </el-col>
                <el-col
                  :lg="{ span: 4 }"
                  :md="{ span: 4 }"
                  :sm="{ span: 8 }"
                  :xs="{ span: 12 }">
                  <el-form-item label="Hoja de ruta">
                    <el-input
                      v-model="hojaRuta"
                      placeholder="Hoja"
                      size="mini"
                      clearable
                      ref="hj"
                      @keypress.native.enter="filtrar"></el-input>
                    <div
                      class="sub-title"
                      style="font-size: 11px; text-align: center">
                      Formato SSNNNNNNNN
                    </div>
                  </el-form-item>
                </el-col>
                <el-col
                  :lg="{ span: 5 }"
                  :md="{ span: 6 }"
                  :sm="{ span: 8 }"
                  :xs="{ span: 12 }"
                  style="float: left">
                  <el-form-item>
                    <br />
                    <el-button-group>
                      <el-button
                        size="mini"
                        :loading="filtrando"
                        type="primary"
                        @click="filtrar"
                        >Filtrar</el-button
                      >
                      <el-button
                        size="mini"
                        :loading="filtrando"
                        @click="limpiar"
                        >Limpiar
                      </el-button>
                    </el-button-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </el-row>
    <el-row :gutter="10" style="margin-top: 20px">
      <el-col :md="12" :offset="0">
        <el-card
          shadow="always"
          class="card-remitos"
          :body-style="{ padding: '0px' }">
          <div slot="header" style="font-size: 14px">
            <span> Registro de actividad</span>
            <span v-if="filtros.hrsuc != '' && filtros.hojaruta != ''">
              - Hoja de ruta : {{ hojaRuta }}
            </span>
            <span style="color: purple" v-if="!filtros.sucursal">
              - Sucursal: TODAS
            </span>
            <span style="color: purple" v-if="filtros.sucursal">
              - Sucursal: {{ filtros.sucursal }}
            </span>
            <el-tooltip
              class="item"
              effect="dark"
              content="Descargar Excel"
              placement="top-start">
              <el-button
                icon="el-icon-download"
                type="info"
                size="mini"
                :loading="dowload_loading"
                circle
                style="float: right; margin-top: -5px"
                plain
                @click="descargarRegistro"></el-button>
            </el-tooltip>
          </div>
          <div
            class="wrapper"
            v-loading="filtrando"
            element-loading-text="cargando..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255,255,255, 0.8)">
            <table class="table table-remitos table-bordered">
              <thead>
                <tr>
                  <td style="width: 90px">Fecha</td>
                  <td style="width: 270px">Versión | DNI | nombre</td>

                  <td>Acción</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="r in registros"
                  class="el-table__body-wrapper"
                  :key="r.key"
                  :class="getClassEvento(r.accion)">
                  <td>{{ r.fecha.date | moment('DD-MM-YY H:mm') }}</td>
                  <td>
                    {{ r.app_version || '4.0.50' }} - {{ r.dni }} -
                    {{ r.nombre }}
                  </td>

                  <td>{{ r.accion }}</td>
                </tr>
              </tbody>
            </table>
            <div id="pagination" v-if="totalEventos > 0">
              <el-pagination
                background
                layout="total,prev, pager, next"
                :total="totalEventos"
                :page-size="50"
                style="margin-top: 20px; float: right"
                @current-change="nextEvent"
                :current-page.sync="pagina_eve"></el-pagination>
            </div>
            <div v-else style="text-align: center">
              <div slot="no-results">No hay registros</div>
            </div>
            <!-- <infinite-loading-2    :distance="10" @infinite="getEventosV4" ref="infiniteEvento">
                    <div slot="spinner">Cargando...</div>
                    <div slot="no-more">Fin de resultados para la fecha seleccionada</div>
                    <div slot="no-results">No hay resultados</div>
                </infinite-loading-2>  -->
          </div>
        </el-card>
      </el-col>
      <el-col :md="12" :offset="0">
        <el-card
          shadow="always"
          class="card-remitos"
          :body-style="{ padding: '0px' }">
          <div slot="header" style="font-size: 14px">
            <span
              >Guías procesadas
              <span v-if="sincronizados.length">
                {{ sincronizados[0].TotalRows }}
              </span>
            </span>
            <!-- <span style="color:red" v-if="filtros.fecha && hojaRuta == ''">
                - Fecha: {{ filtros.fecha  | moment('DD-MM-YY') }}
            </span>

             <span style="color:red" v-if="!filtros.fecha && hojaRuta == ''">
                - Fecha: {{fecha_toda}}
            </span> -->

            <span style="color: purple" v-if="!filtros.sucursal">
              - Sucursal: TODAS
            </span>
            <span style="color: purple" v-if="filtros.sucursal">
              - Sucursal: {{ filtros.sucursal }}
            </span>
            <!-- <span  style="color:#ff5722" v-if="!filtros.dni && filtros.hrsuc == '' && filtros.hojaruta == ''">
             - Chofer: TODOS
            </span>
            <span  style="color:#ff5722" v-if="!filtros.dni && filtros.hrsuc == '' && filtros.hojaruta == ''">
             - Chofer: {{filtros.dni}}
            </span> -->
          </div>
          <div
            class="wrapper el-table__body-wrapper"
            v-loading="filtrando_guias"
            element-loading-text="cargando..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255,255,255, 0.8)">
            <table class="table table-remitos table-bordered">
              <thead>
                <tr>
                  <td>Fecha</td>
                  <td>Guía</td>
                  <td>HR</td>
                  <td>Estado</td>
                  <td>Chofer</td>
                  <td>Sincronización</td>
                </tr>
              </thead>
              <tbody class="el-table__body-wrapper">
                <tr v-for="s in sincronizados" :key="s.id">
                  <td>{{ s.fecha_escaneo.date | moment('DD-MM-YY H:mm') }}</td>

                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Ver seguimiento"
                    placement="top-left">
                    <td
                      style="cursor: pointer"
                      @click="
                        getSeguimiento(
                          s.terminal,
                          s.numero,
                          s.estado_letra,
                          s.dni
                        )
                      ">
                      {{ zeroPad(s.terminal, 4) }}-{{ zeroPad(s.numero, 8) }}
                    </td>
                  </el-tooltip>
                  <td>{{ zeroPad(s.hrsuc, 2) }}{{ zeroPad(s.hojaruta, 8) }}</td>
                  <td>
                    {{ s.estado }}
                  </td>
                  <td>
                    <span v-if="s.dni != ''">{{ getNombreChofer(s.dni) }}</span
                    ><span v-else> - </span>
                  </td>
                  <td>
                    <center>
                      <el-button
                        type="success"
                        style="padding: 4px"
                        size="mini"
                        v-if="s.sincronizado"
                        :disabled="s.sincronizado ? true : false"
                        >Sincronizado
                      </el-button>
                      <!-- <el-tooltip class="item" effect="dark" content="Sincronizar guìa" placement="left-start">
                  <el-button
                    type="danger"
                    style="padding:4px"
                    size="mini"
                    v-if="!s.sincronizado"
                    :disabled="s.sincronizado ? true : false"
                    >Pendiente</el-button>
                    </el-tooltip> -->
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Sincronizar guía"
                        placement="left-start">
                        <el-popconfirm
                          confirm-button-text="SÍ"
                          cancel-button-text="NO"
                          icon="el-icon-info"
                          icon-color="red"
                          title="¿Desea sincronizar guía?"
                          @confirm="sincronizarGuia(s)">
                          <el-button
                            slot="reference"
                            type="danger"
                            style="padding: 4px"
                            :loading="loadSincroGuia == s.id"
                            v-if="!s.sincronizado"
                            size="mini"
                            :disabled="disableSincro">
                            Pendiente</el-button
                          >
                        </el-popconfirm>
                      </el-tooltip>
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
            <div id="pagination" v-if="totalPendientes > 0">
              <el-pagination
                background
                layout="total,prev, pager, next"
                :total="totalPendientes"
                :page-size="50"
                style="margin-top: 20px; float: right"
                @current-change="nextPend"
                :current-page.sync="pagina_actual"></el-pagination>
            </div>
            <div v-else style="text-align: center">
              <div slot="no-results">
                No hay registros de guías procesadas en la App
              </div>
            </div>
            <div v-if="mostrarTable" style="margin-top: 13%">
              <hr />
              <p><center>Datos de Hoja de Ruta</center></p>
              <el-table
                :data="resumenHR"
                border
                stripe
                fit
                size="mini"
                v-loading="loadingTable"
                style="width: 100%">
                <el-table-column label="Chofer" width="65">
                  <template slot-scope="scope">
                    <div v-if="scope.row.chofer > 0">
                      <el-popover
                        placement="right"
                        width="300"
                        trigger="hover"
                        :close-delay="50">
                        <h4
                          style="
                            margin-top: 2px;
                            margin-bottom: 5px;
                            border-bottom: 1px solid #c0c0c0;
                          ">
                          Datos del chofer
                        </h4>
                        <p v-if="scope.row.chofer_nombre">
                          Nombre: {{ scope.row.chofer_nombre }}
                          <br />
                          Celular: {{ scope.row.chofer_celular }}
                        </p>
                        <p v-else>
                          Nombre: -
                          <br />
                          Celular: -
                        </p>
                        <span slot="reference"
                          ><i class="el-icon-user-solid"></i
                        ></span>
                      </el-popover>
                    </div>
                    <div v-else>
                      <span slot="reference"
                        >KKK{{ scope.row.chofer }}
                        <i class="el-icon-user-solid"></i
                      ></span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="" width="60">
                  <template slot-scope="scope">
                    <img
                      v-if="scope.row.um == 'TR' || scope.row.um == 'MX'"
                      style="height: 16px"
                      src="@/assets/img/stkremoto.png"
                      alt="" />
                    <img
                      v-if="scope.row.um == 'UM' || scope.row.um == 'MX'"
                      style="height: 18px"
                      src="@/assets/img/ico-um1.png"
                      alt="" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="hojaruta"
                  label="Hoja de Ruta"
                  width="88">
                </el-table-column>
                <el-table-column label="Fecha HR" width="136">
                  <template slot-scope="scope">
                    <span v-if="scope.row.fchhojaruta">
                      {{
                        scope.row.fchhojaruta.date | moment('DD-MM-YYYY HH:mm')
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="rep" label="Reparto" width="80">
                  <template slot-scope="scope">
                    {{ scope.row.rep }}/{{ scope.row.sub }}
                  </template>
                </el-table-column>
                <el-table-column prop="reparto" label="Nombre Reparto">
                  <template slot-scope="scope">
                    <span v-if="scope.row.reparto">
                      {{ scope.row.reparto }}
                    </span>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
                <el-table-column label="Fecha Rep." width="90">
                  <template slot-scope="scope">
                    <span v-if="scope.row.fecharep">
                      {{ scope.row.fecharep.date | moment('DD-MM-YYYY HH:mm') }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <br />
              <el-table
                :data="resumenHR"
                border
                stripe
                fit
                size="mini"
                v-loading="loadingTable"
                style="width: 100%"
                align="center">
                <el-table-column prop="Pendiente" label="Pendientes">
                </el-table-column>
                <el-table-column prop="Entregada" label="Entregadas">
                </el-table-column>
                <el-table-column prop="Rechazado" label="Rechazadas">
                </el-table-column>
                <el-table-column prop="Reprogramada" label="Reprogramadas">
                </el-table-column>
                <el-table-column prop="Retiro" label="Retiro" width="60">
                </el-table-column>
                <el-table-column prop="Anulada" label="Anulada" width="60">
                </el-table-column>
                <el-table-column prop="total" label="Total" width="80">
                </el-table-column>
              </el-table>
            </div>
            <!-- MUESTRO LOS DATOS DE LA HOJA DE RUTA -->

            <!-- <infinite-loading   :distance="10" @infinite="getPendienteSincro"  force-use-infinite-wrapper=".el-table__body-wrapper" ref="infiniteLoading">
                  <div slot="spinner">Cargando...</div>
                  <div slot="no-more">Fin de resultados para la fecha seleccionada</div>
                  <div slot="no-results">No hay resultados</div>
          </infinite-loading> -->
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- Dialog de sincronizar -->

    <el-dialog
      title="Sincronizar Guías"
      :visible.sync="dialogSincro"
      class="ancho"
      top="5vh"
      center
      @closed="refrescarDatos()">
      <el-table
        :data="tablaSincro.filter((data) => !search || data.hojaruta == search)"
        height="500"
        stripe
        style="width: 100%"
        size="mini"
        show-summary
        v-loading="loadingTabla"
        element-loading-text="Cargando comprobantes..."
        element-loading-spinner="el-icon-loading">
        <el-table-column label="Hoja de Ruta" prop="hojaruta">
        </el-table-column>
        <el-table-column label="Cant. Guías" prop="guias" sortable>
        </el-table-column>
        <el-table-column label="Sucursal" prop="name">
          <template slot-scope="scope">
            <div>
              {{ getNombreSuc(scope.row.hrsuc) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot="header" slot-scope="{}">
            <el-input
              v-model="search"
              size="mini"
              placeholder="Buscar hoja de ruta"
              clearable />
          </template>
          <template slot-scope="scope">
            <center>
              <el-button
                type="success"
                size="mini"
                icon="el-icon-refresh"
                @click="sincronizarHj(scope.row)"
                :disabled="disabledBtnSincro">
                Sincronizar</el-button
              >
            </center>
          </template>
        </el-table-column>
      </el-table>

      <!-- <div v-if="pendientesTotal.length < 100" style="text-align:center">
            <el-row :gutter="10" class="vguia-row">
                <el-col :md="24" :sm="24" :xs="24" >
                  <center><el-button type="success" @click="sincronizarTodas()" size="small" :loading="loadSincro" icon="el-icon-refresh">Sincronizar Guías</el-button>
                  <el-button type="default" @click="cerrarModal"  size="small" icon="el-icon-close">Cancelar</el-button>  </center>
                </el-col>
            </el-row>
          </div>
          <div v-else>
              <el-form ref="filtroSincronizar" :model="filtroSincronizar" :rules="reglas" label-position="top" >
              <el-row :gutter="10" class="vguia-row">
                <el-col :md="15" :sm="12" :xs="24">
                  <el-form-item label="Sucursal:" prop="hrsuc">
                    <el-select v-model="filtroSincronizar.hrsuc" size="small" class="vpim-select" filterable clearable>
                        <el-option v-for="item in sucursales"
                            :key="item.codigo"
                            :label="item.codigo + ' - '+ item.nombre"
                            :value="item.codigo">
                        </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="9" :sm="12" :xs="24">
              <el-form-item label="Hoja de ruta"  prop="hojaruta">
                 <el-input v-model="filtroSincronizar.hojaruta" size="small" placeholder="Ingrese hoja de ruta" clearable></el-input>
              </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-row :gutter="10" class="vguia-row">
              <center>
                <el-col :span="24">
                  <el-button type="success" @click="sincronizarSuc('filtroSincronizar')" :loading="loadSincro" icon="el-icon-refresh"  size="small">Sincronizar</el-button>
                  <el-button type="default" @click="cerrarModal" icon="el-icon-close"  size="small">Cancelar</el-button>
                </el-col>
                </center>
                </el-row>
            </div>           -->
    </el-dialog>
    <!-- Modal para mostrar seguimiento -->
    <el-dialog
      :title="'Seguimiento de Guía: ' + guia_seguimiento"
      :visible.sync="dialogSeguimiento"
      class="ancho"
      top="5vh"
      width="90%"
      center>
      <!-- Datos extras de chofer -->
      <div v-loading="cargarChofer">
        <p>
          <u>Datos del Chofer</u>
          <span style="float: right"
            >Última conexión:
            <span style="color: red" v-if="datosExtraChofer.ultima_fecha != ''">
              {{ datosExtraChofer.ultima_fecha }}</span
            >
            <span v-else>-</span></span
          >
        </p>
        <ul>
          <li>
            <span
              v-if="datosExtraChofer.nombre != ''"
              style="text-transform: uppercase"
              >{{ datosExtraChofer.nombre }}</span
            ><span v-else> S/N </span> - Uuid:
            <span v-if="datosExtraChofer.uuid != ''">{{
              datosExtraChofer.uuid
            }}</span
            ><span v-else> S/DNI </span>
          </li>
          <li>
            App Versión:
            <span v-if="datosExtraChofer.appversion != ''">{{
              datosExtraChofer.appversion
            }}</span
            ><span v-else> S/APP </span> - Dispositivo:
            <span v-if="datosExtraChofer.dispositivo != ''">{{
              datosExtraChofer.dispositivo
            }}</span
            ><span v-else> S/dispositivo </span>
          </li>
        </ul>
      </div>
      <div v-if="showSeguimiento">
        <table class="table table-bordered table-remitos">
          <thead style="color: #014a82">
            <tr>
              <th style="width: 110px">Fecha</th>
              <th scope="col">Acción</th>
              <th scope="col">Sucursal</th>
              <th scope="col">Operador</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="seg in guia.seguimientos" :key="seg.id">
              <td v-if="seg.fecha != null">
                {{ seg.fecha.date | moment('DD-MM-YYYY HH:mm:ss') }}
              </td>
              <td>
                <span v-if="seg.entrega">
                  {{ seg.accion.split('|')[0] }}
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Ver ubicación"
                    placement="bottom">
                    <el-button
                      v-if="gerLatLong(seg.accion)"
                      plain
                      size="mini"
                      style="
                        padding: 7px 7px !important;
                        margin-left: 5px !important;
                        font-size: 10px !important;
                      "
                      @click="mapaShow(seg.accion)">
                      <i class="el-icon-location" :style="styleIconMapa"></i
                      ><strong>VER MAPA</strong>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Ver foto"
                    placement="right">
                    <i
                      v-if="seg.fotos && seg.fotos.length"
                      @click="showGaleria(seg.fotos)"
                      style="
                        margin-left: 5px !important;
                        font-size: 27px !important;
                        display: inline-block;
                        vertical-align: middle;
                      "
                      class="el-icon-camera-solid"></i>
                  </el-tooltip>
                </span>
                <span v-else>
                  {{ seg.accion.toUpperCase().replace('ENTREGADA', 'RENDIDA') }}
                </span>
              </td>

              <td v-if="seg.sucursal">{{ seg.sucursal }}</td>
              <td v-else>-</td>
              <td v-if="seg.operador">{{ seg.operador }}</td>
              <td v-else>
                {{ seg.accion.split('|')[1].toUpperCase() || '-' }}
              </td>
            </tr>
            <tr v-for="(d, index) in guia.guiadev" :key="index">
              <td v-if="d.fecha != ''">
                {{ d.fecha.date | moment('DD-MM-YYYY HH:mm:ss') }}
              </td>
              <td>
                <span v-if="getNroPres(d.accion) && d.nropres">
                  {{ d.accion }} - NRO. PRESENTACIÓN:
                  <strong>{{ d.nropres }}</strong>
                  <el-popover placement="right" trigger="hover">
                    <p>
                      <i class="el-icon-date" type="primary"></i> Recibido:
                      {{ d.recibido.date | moment('DD-MM-YYYY HH:mm:ss') }}
                    </p>
                    <p>
                      <i class="el-icon-location" type="primary"></i> Suc.
                      Origen: {{ filterSuc(d.sucorigen) }}
                    </p>
                    <p>
                      <i class="el-icon-location" type="primary"></i> Suc.
                      Destino: {{ filterSuc(d.sucdestino) }}
                    </p>
                    <el-button
                      slot="reference"
                      size="mini"
                      circle
                      style="padding: 0px; margin-left: 6px"
                      ><i
                        type="primary"
                        class="el-icon-info"
                        style="font-size: 20px"></i
                    ></el-button>
                  </el-popover>
                </span>
                <span v-else>{{ d.accion }} </span>
              </td>
              <td>
                {{ d.sucursal }}
              </td>
              <td>
                {{ d.operador }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <!-- TABLA DE HOJA DE RUTA -->
        <el-table
          v-if="mostrarTable"
          :data="resumenHR"
          border
          stripe
          fit
          size="mini"
          v-loading="loadingTable"
          style="width: 100%">
          <el-table-column label="" width="60">
            <template slot-scope="scope">
              <img
                v-if="scope.row.um == 'TR' || scope.row.um == 'MX'"
                style="height: 16px"
                src="@/assets/img/stkremoto.png"
                alt="" />
              <img
                v-if="scope.row.um == 'UM' || scope.row.um == 'MX'"
                style="height: 18px"
                src="@/assets/img/ico-um1.png"
                alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="hojaruta" label="Hoja de Ruta" width="88">
          </el-table-column>
          <el-table-column label="Fecha HR" width="136">
            <template slot-scope="scope">
              <span v-if="scope.row.fchhojaruta">
                {{ scope.row.fchhojaruta.date | moment }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="rep" label="Reparto" width="80">
            <template slot-scope="scope">
              {{ scope.row.rep }}/{{ scope.row.sub }}
            </template>
          </el-table-column>
          <el-table-column prop="reparto" label="Nombre Reparto" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.reparto">
                {{ scope.row.reparto }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="Fecha Rep." width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.fecharep">
                {{ scope.row.fecharep.date | moment('DD-MM-YYYY HH:mm') }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Chofer" width="65">
            <template slot-scope="scope">
              <div v-if="scope.row.chofer > 0">
                <el-popover
                  placement="right"
                  width="300"
                  trigger="hover"
                  :close-delay="50">
                  <h4
                    style="
                      margin-top: 2px;
                      margin-bottom: 5px;
                      border-bottom: 1px solid #c0c0c0;
                    ">
                    Datos del chofer
                  </h4>
                  <p v-if="scope.row.chofer_nombre">
                    Nombre: {{ scope.row.chofer_nombre }}
                    <br />
                    Celular: {{ scope.row.chofer_celular }}
                  </p>
                  <p v-else>
                    Nombre: -
                    <br />
                    Celular: -
                  </p>
                  <span slot="reference"
                    >{{ scope.row.chofer }} <i class="el-icon-user-solid"></i
                  ></span>
                </el-popover>
              </div>
              <div v-else>
                <span slot="reference"
                  >{{ scope.row.chofer }} <i class="el-icon-user-solid"></i
                ></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="total" label="Total" width="49">
          </el-table-column>
          <el-table-column prop="Pendiente" label="Pendientes" width="88">
          </el-table-column>
          <el-table-column prop="Entregada" label="Entregadas" width="88">
          </el-table-column>
          <el-table-column prop="Rechazado" label="Rechazadas" width="91">
          </el-table-column>
          <el-table-column
            prop="Reprogramada"
            label="Reprogramadas"
            width="117">
          </el-table-column>
          <el-table-column prop="Retiro" label="Retiro" width="56">
          </el-table-column>
          <el-table-column prop="Anulada" label="Anulada" width="71">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- FIN MODAL SEGUIMIENTO -->
    <!-- Modal para mostrar MAPA -->
    <el-dialog title="DOMICILIO DE GUÍA" :visible.sync="dialogVisible">
      <div style="margin-top: -40px">
        <p>
          <strong>{{ guia.direc }}</strong>
        </p>
      </div>
      <el-row>
        <MglMap
          :accessToken="accessToken"
          :mapStyle="mapStyle"
          :center="center"
          :zoom="zoom">
          <MglNavigationControl position="top-right" />
          <MglGeojsonLayer
            :sourceId="geoJsonSource.data.id"
            :source="geoJsonSource"
            :layerId="geoJsonLayer.id"
            :layer="geoJsonLayer" />
          <MglFullscreenControl position="top-right" />
        </MglMap>
      </el-row>
    </el-dialog>
    <!-- FIN MODAL PARA MAPA -->
  </div>
</template>

<script>
import { HTTP } from '@/axios'
import Vue2Filters from 'vue2-filters'
import Mapbox from 'mapbox-gl'
import { EventBus } from '@/event-bus'
import {
  MglMap,
  MglGeojsonLayer,
  MglNavigationControl,
  MglFullscreenControl,
} from 'vue-mapbox'
// import InfiniteLoading from 'vue-infinite-loading';

export default {
  mixins: [Vue2Filters.mixin],
  components: {
    MglMap,
    MglGeojsonLayer,
    MglNavigationControl,
    MglFullscreenControl,
  },
  data() {
    return {
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      filtrando: false,
      filtrando_guias: false,
      instalaciones: 0,
      registros: [],
      sincronizados: [],
      sucursales: [],
      filtros: {
        sucursal: '',
        fecha: this.$moment().format('YYYYMMDD'),
        pagina_sincro: 1,
        pagina_evento: 1,
        dni: '',
        hrsuc: '',
        hojaruta: '',
      },
      fecha_toda: '',
      pendientesTotal: 0,
      nameSucursal: '',
      dialogSincro: false,
      filtroSincronizar: {
        hrsuc: '',
        hojaruta: '',
      },
      totalPendientes: 0,
      totalEventos: 0,
      pagina_actual: 0,
      pagina_eve: 0,
      disableSincro: false,
      tiempo: 0,
      loadSincro: false,
      bloqueado: false,
      usuario: JSON.parse(window.localStorage.getItem('datos')).user,
      empresa: window.localStorage.getItem('empresa') == 'u' ? 'DSA' : 'LOG',
      showBtnSincro: false,
      operador:
        JSON.parse(window.localStorage.getItem('datos')).uid +
        '-' +
        JSON.parse(window.localStorage.getItem('datos')).user,
      reglas: {
        hrsuc: [
          {
            required: true,
            message: 'Por favor elija una sucursal',
            trigger: 'change',
          },
        ],
        hojaruta: [
          {
            required: true,
            message: 'Ingrese una hoja de ruta',
            trigger: 'change',
          },
        ],
      },
      tablaSincro: [],
      pendientesSincro: '',
      search: '',
      disabledBtnSincro: false,
      loadingTabla: false,
      cargandoSincro: false,
      dowload_loading: false,
      dowload_instalaciones: false,
      hojaRuta: '',
      choferes: [],
      dialogSeguimiento: false,
      showSeguimiento: false,
      guia: [],
      styleIconMapa: {
        color: '#006dcc',
        fontSize: '15px',
      },
      dialogVisible: false,
      center: '',
      zoom: 14,
      geoJsonSource: {
        type: 'geojson',
        data: {
          id: 'points',
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: '',
              },
            },
          ],
        },
      },

      geoJsonLayer: {
        id: 'points',
        type: 'circle',
        paint: {
          'circle-radius': 6,
          'circle-color': '#df0c0c',
          'circle-stroke-width': 2,
          'circle-stroke-color': '#e90505',
        },
      },
      accessToken:
        'pk.eyJ1IjoibWFwc2d1aWFzIiwiYSI6ImNqenU1OGZhdjBia2szbGxtNG91eDBwcXYifQ.3U4PVAVy0FRsm5O24EBIPA',
      style: 'streets-v9',
      guia_seguimiento: '',
      loadSincroGuia: false,
      datosExtraChofer: [],
      cargarChofer: false,
      mostrarTable: false,
      resumenHR: [],
      loadingTable: false,
    }
  },
  created() {
    this.getTotalV4()
    this.getTotalPendiente()
    this.getSucursales()
    this.getEventosV4()
    this.getPendienteSincro()
    this.filtros.fecha = this.$moment().format('YYYYMMDD')
    // console.log('fecha',this.$moment().format("YYYYMMDD"))
    this.nameSucursal = 'TODAS SUC.'
    var guardado = localStorage.getItem('comparar')
    this.mapbox = Mapbox

    if (guardado) {
      var datos = JSON.parse(guardado)
      var fecha2 = this.$moment().format('YYYY-MM-DD')
      if (this.$moment(datos.fecha).isSame(fecha2)) {
        // Saco los minutos que faltan
        var actual = this.$moment().format('LT')
        var entryHour = this.$moment(actual, 'hh:mm')
        var exitHour = this.$moment(datos.tiempo, 'hh:mm')
        var duration = this.$moment
          .duration(entryHour.diff(exitHour))
          .asMinutes()
        if (duration <= 10) {
          var cant_minutos = 10 - duration
          /* Pasar minutos a milisegundos */
          var cant_miliseg =
            cant_minutos == 0 && duration == 10 ? 60000 : cant_minutos * 60000
          this.disableSincro = true
          this.bloqueado = true
          setTimeout(this.disabledBtn, cant_miliseg)
        } else {
          this.disableSincro = false
          localStorage.removeItem('comparar')
          this.bloqueado = false
        }
      } else {
        this.disableSincro = false
        localStorage.removeItem('comparar')
        this.bloqueado = false
      }
    }
  },
  beforeDestroy() {
    EventBus.$off('sucursalesHR')
  },
  mounted() {
    if (
      (this.usuario == 'guia_mariano' && this.empresa == 'DSA') ||
      this.usuario == 'guias_ol' ||
      this.usuario == 'guia_jef' ||
      this.usuario == 'kikeol'
    ) {
      this.showBtnSincro = true
      this.disableSincro = false
    } else {
      this.showBtnSincro = false
      this.disableSincro = true
    }
    this.getChoferes()
  },
  methods: {
    filtrar() {
      this.filtros.pagina_evento = 1
      this.filtros.pagina_sincro = 1
      this.registros = []
      this.sincronizados = []
      this.nameSucursal =
        this.filtros.sucursal != ''
          ? this.sucursales.find(
              (element) => element.codigo == this.filtros.sucursal
            ).nombre
          : 'TODAS SUC.'
      this.resumenHR = [] //reinicio la tabla
      this.mostrarTable = false
      setTimeout(() => {
        this.getEventosV4()
        this.getPendienteSincro()
      }, 250)
    },
    limpiar() {
      this.filtrando = true
      this.filtrando_guias = true
      this.registros = []
      this.sincronizados = []
      this.nameSucursal = 'TODAS SUC.'
      this.totalPendientes = 0
      this.totalEventos = 0
      this.mostrarTable = false
      this.resumenHR = []
      ;(this.filtros = {
        sucursal: '',
        fecha: this.$moment().format('YYYYMMDD'),
        pagina_sincro: 1,
        pagina_evento: 1,
        dni: '',
        hojaruta: '',
        hrsuc: '',
      }),
        (this.hojaRuta = '')
      setTimeout(() => {
        this.getEventosV4()
        this.getPendienteSincro()
        this.getTotalV4()
        this.getTotalPendiente()
      }, 200)
    },
    getSucursales() {
      HTTP.post('/remitos/getSucursales', { suc: this.filtros.sucursal }).then(
        (res) => {
          this.sucursales = res.data
          EventBus.$emit('sucursalesHR', {
            sucursales: res.data,
          })
        }
      )
    },
    getTotalV4() {
      HTTP.post('/remitos/getTotalV4', { suc: this.filtros.sucursal }).then(
        (res) => {
          this.instalaciones = res.data
        }
      )
    },
    /* Datos para mostrar en una tabla */
    getTotalPendiente() {
      this.loadingTabla = true
      this.cargandoSincro = true
      HTTP.get('/remitos/getTotalPendientes').then((res) => {
        setTimeout(() => {
          this.pendientesSincro =
            res.data.length > 0 ? res.data[0].total_count : 0
          this.loadingTabla = false
        }, 100)
        this.tablaSincro = res.data
        this.disableSincro = res.data.length == 0 ? true : false
        this.cargandoSincro = false
      })
    },
    getEventosV4() {
      this.filtrando = true

      if (!this.filtros.fecha) {
        var today = this.$moment().format('DD-MM-YY')
        var fecha = new Date()
        var dias = 30 // Número de días a agregar
        fecha.setDate(fecha.getDate() - dias)
        fecha = this.$moment(fecha).format('DD-MM-YY')
        this.fecha_toda = fecha + ' - ' + today
      }
      this.pagina_eve = this.filtros.pagina_evento
      this.registros = []
      this.totalEventos = 0
      let buscar_suc = true

      if (this.hojaRuta != '') {
        //Busco por hoja de ruta
        if (this.hojaRuta.length >= 8) {
          this.filtros.sucursal = ''
          this.filtros.hojaruta = parseInt(this.hojaRuta.substring(2))
          this.filtros.hrsuc = parseInt(this.hojaRuta.substring(0, 2))
          this.filtros.sucursal = parseInt(this.hojaRuta.substring(0, 2))
          this.filtros.fecha = ''
          this.filtros.pagina_sincro = 1
          this.filtros.pagina_evento = 1
          this.filtros.dni = ''

          if (this.filtros.sucursal != '') {
            buscar_suc = this.sucursales.find(
              (element) => element.codigo == this.filtros.hrsuc
            )
            if (typeof buscar_suc != 'undefined') {
              this.nameSucursal = this.sucursales.find(
                (element) => element.codigo == this.filtros.hrsuc
              ).nombre
            } else {
              buscar_suc = false
              this.filtrando_guias = false
              // Limpio los campos
              this.nameSucursal = 'TODAS SUC.'
              this.filtros.sucursal = ''
            }
          }
        } else {
          buscar_suc = false
        }
      } else {
        this.filtros.hrsuc = ''
        this.filtros.hojaruta = ''
      }
      if (buscar_suc) {
        let index = 1
        HTTP.post('/remitos/getEventosV4', this.filtros)
          .then((res) => {
            if (res.data.length) {
              // this.filtros.pagina_evento += 1;
              this.registros = res.data
              this.registros.forEach((element) => {
                index++
                element['key'] = element.id + element.nombre + index
              })
              this.totalEventos = res.data[0].TotalRows
              // this.registros.push(...res.data);
              // $state.loaded();
              this.filtrando = false
            } else {
              this.registros = []
              this.totalEventos = 0
              this.filtrando = false
              // $state.complete();
            }
            // this.registros = res.data;
          })
          .catch((e) => {
            console.log(e)
            this.filtrando = false
          })
      } else {
        this.filtrando = false
        this.nameSucursal = 'TODAS SUC.'
        this.filtros.sucursal = ''
      }
    },
    getPendienteSincro() {
      this.filtrando_guias = true
      this.totalPendientes = 0
      this.sincronizados = []
      this.pagina_actual = this.filtros.pagina_sincro
      let buscar_suc = true

      if (this.hojaRuta != '') {
        //Busco por hoja de ruta
        if (this.hojaRuta.length >= 8) {
          this.filtros.sucursal = ''
          this.filtros.hojaruta = parseInt(this.hojaRuta.substring(4))
          this.filtros.hrsuc = parseInt(this.hojaRuta.substring(0, 2))
          this.filtros.sucursal = parseInt(this.hojaRuta.substring(0, 2))
          this.filtros.fecha = ''
          this.filtros.pagina_sincro = 1
          this.filtros.pagina_evento = 1
          this.filtros.dni = ''

          if (this.filtros.sucursal != '') {
            buscar_suc = this.sucursales.find(
              (element) => element.codigo == this.filtros.hrsuc
            )
            if (typeof buscar_suc != 'undefined') {
              this.nameSucursal = this.sucursales.find(
                (element) => element.codigo == this.filtros.hrsuc
              ).nombre
            } else {
              buscar_suc = false
              this.$refs['hj'].focus()
              this.$notify.error({
                title: 'Error',
                message: 'La hoja de ruta no existe',
              })
              this.filtrando_guias = false
            }
          }
        } else {
          buscar_suc = false
          this.$refs['hj'].focus()
          this.$notify({
            title: 'Cuidado',
            message: 'El formato de búsqueda no es correcto',
            type: 'warning',
          })
          this.filtrando_guias = false
        }
      } else {
        this.filtros.hrsuc = ''
        this.filtros.hojaruta = ''
      }

      if (buscar_suc) {
        // alert('buscar')
        HTTP.post('/remitos/getPendienteSincro', this.filtros)
          .then((res) => {
            this.filtrando_guias = false
            if (res.data.length) {
              // this.filtros.pagina_sincro += 1;
              this.sincronizados = res.data
              let notiene = []
              this.sincronizados.forEach((element) => {
                if (element.dni == '' || element.dni == null) {
                  notiene.push(element)
                }
              })
              // console.log(notiene)
              this.totalPendientes = res.data[0].TotalRows
              if (this.hojaRuta != '') {
                //Busco por hoja de ruta
                this.getHojaRuta(this.filtros.hrsuc, this.filtros.hojaruta)
              }
              // this.sincronizados.push(...res.data);
              // this.$refs.infiniteLoading.stateChanger.loaded();
            } else {
              // this.$refs.infiniteLoading.stateChanger.complete();
              this.totalPendientes = 0
              this.sincronizados = []
            }

            // this.sincronizados = res.data;
          })
          .catch((e) => {
            this.filtrando_guias = false
            this.filtrando = false
            this.$alert('Error al buscar', e)
          })
      } else {
        this.nameSucursal = 'TODAS SUC.'
        this.filtros.sucursal = ''
      }
    },
    nextEvent(page) {
      // console.log(page)
      this.filtros.pagina_evento = page
      this.getEventosV4()
    },
    nextPend(page) {
      // console.log(page)
      this.filtros.pagina_sincro = page
      this.getPendienteSincro()
    },
    getClassEvento(accion) {
      let clase = ''
      if (accion.includes('LOGIN CORRECTO')) {
        clase = 'login-ok'
      }
      if (accion.includes('HR')) {
        clase = 'hr-ok'
      }
      if (accion.includes('INCORRECTA')) {
        clase = 'pass-error'
      }
      if (accion.includes('INCORRECTO')) {
        clase = 'pass-error'
      }
      if (accion.includes('HABILITADO')) {
        clase = 'estado-error'
      }
      return clase
    },
    zeroPad(num, places) {
      var zero = places - num.toString().length + 1
      return Array(+(zero > 0 && zero)).join('0') + num
    },
    getDatos() {
      // console.log('datos',sucursal)
      this.nameSucursal =
        this.filtros.sucursal != ''
          ? this.sucursales.find(
              (element) => element.codigo == this.filtros.sucursal
            ).nombre
          : 'TODAS SUC.'
      this.getTotalV4()
      this.getTotalPendiente()
    },
    abrirSincro() {
      this.dialogSincro = true
      this.search = ''
    },
    // sincronizarTodas()
    // {
    //   if(this.pendientesTotal.length<100 && this.pendientesTotal.length > 0)
    //   {
    //     this.loadSincro = true;
    //     HTTP.post("/approbot/sincronizarFusion",{hrsuc:'',hojaruta:'',operador:this.operador}).then((res) => {
    //       // console.log('resultado',res.data)
    //       this.loadSincro = false;
    //       if(res.data == 0)
    //       {
    //         this.$message({
    //           message: 'No hay guías dispobibles para sincronizar',
    //           type: 'warning'
    //         });

    //       }
    //       else if(res.data == -10 || res.data == -11)
    //       {
    //         /* Guardamos fecha y hora en la que se bloquea */
    //         var hoy = this.$moment().format("YYYY-MM-DD")
    //         var tiempos = { 'tiempo': this.$moment().format('LT'), 'fecha': hoy };
    //         window.localStorage.setItem('comparar',JSON.stringify(tiempos));
    //         this.disableSincro = true;
    //         setTimeout(this.disabledBtn,600000);
    //       }else
    //       {
    //         // Sincronización exitosa
    //         this.$message({
    //           message: 'Sincronizacion exitosa.',
    //           type: 'success'
    //         })
    //         // Actualizar la cantidad de guías a sincronizar

    //       }
    //       this.dialogSincro = false;
    //       this.getTotalPendiente();
    //     }).catch((e) => {
    //       this.loadSincro = false;
    //       this.dialogSincro = false;
    //       this.$alert("Error al sincronizar", e);
    //     });
    //   }
    //   else{
    //     this.dialogSincro = false;
    //   }
    // },
    // sincronizarSuc(formName)
    // {
    //    this.$refs[formName].validate((valid) => {
    //     if (valid){

    //     let cant_pendiente;
    //     /* Consulto si la cantidad de guias de la suc y hojas de ruta es meor a 100 */
    //     HTTP.post("/remitos/getTotalPendientes", { suc: this.filtros.sucursal }).then(
    //     (res) => {
    //       this.cant_pendiente = res.data;
    //     });
    //     // console.log(cant_pendiente.length)
    //     if(cant_pendiente<100 && cant_pendiente > 0)
    //     {
    //       this.loadSincro = true;
    //       HTTP.post("/approbot/sincronizarFusion", {
    //         hrsuc:this.filtroSincronizar.hrsuc,
    //         hojaruta:this.filtroSincronizar.hojaruta,
    //         operador:this.operador}).then((res) => {
    //         console.log('resultado',res.data)
    //         this.loadSincro = false;
    //         if(res.data == 0)
    //         {
    //           this.$message({
    //             message: 'No hay guías dispobibles para sincronizar',
    //             type: 'warning'
    //           });
    //         }
    //         else if(res.data == -10 || res.data == -11)
    //         {
    //           /* Guardamos fecha y hora en la que se bloquea */
    //           var hoy = this.$moment().format("YYYY-MM-DD")
    //           var tiempos = { 'tiempo': this.$moment().format('LT'), 'fecha': hoy };
    //           window.localStorage.setItem('comparar',JSON.stringify(tiempos));
    //           this.disableSincro = true;
    //           setTimeout(this.disabledBtn,600000);
    //         }else
    //         {
    //             // Sincronización exitosa
    //             this.$message({
    //               message: 'Sincronizacion exitosa.',
    //               type: 'success'
    //             });
    //         }
    //         this.dialogSincro = false;
    //         this.getTotalPendiente();
    //       }).catch((e) => {
    //         this.loadSincro = false;
    //         this.$alert("Error al sincronizar", e);
    //       });
    //     }
    //     else{
    //       this.dialogSincro = false;
    //     }
    //   }
    //   else
    //   {
    //       this.$message({
    //           message: 'Por favor. Controle los campos obligatorios.',
    //           type: 'warning'
    //       });
    //       return false;
    //     }
    //   });

    // },
    disabledBtn() {
      this.disableSincro = false
      this.bloqueado = false
    },
    getNombreSuc(codigo) {
      let result = this.sucursales.filter((word) => word.codigo == codigo)
      if (result) {
        return result[0].nombre
      } else {
        return ''
      }
    },
    sincronizarHj(row) {
      // console.log(row)
      this.disabledBtnSincro = true
      HTTP.post('/approbot/sincronizarFusion', {
        hrsuc: row.hrsuc,
        hojaruta: row.hojaruta,
        operador: this.operador,
      })
        .then((res) => {
          // console.log('resultado',res.data)
          this.loadSincro = false
          if (res.data == 0) {
            this.$message({
              message: 'No hay guías dispobibles para sincronizar',
              type: 'warning',
            })
          } else if (res.data == -10 || res.data == -11) {
            /* Guardamos fecha y hora en la que se bloquea */
            var hoy = this.$moment().format('YYYY-MM-DD')
            var tiempos = { tiempo: this.$moment().format('LT'), fecha: hoy }
            window.localStorage.setItem('comparar', JSON.stringify(tiempos))
            this.disableSincro = true
            setTimeout(this.disabledBtn, 600000)
          } else {
            // Sincronización exitosa
            this.$message({
              message: 'Sincronizacion exitosa.',
              type: 'success',
            })
          }
          this.disabledBtnSincro = false
          /* Refresco tabla */
          setTimeout(() => {
            this.getTotalPendiente()
          }, 150)
        })
        .catch((e) => {
          this.loadSincro = false
          this.$alert('Error al sincronizar', e)
        })
    },
    refrescarDatos() {
      this.getTotalV4()
      this.getTotalPendiente()
      this.getSucursales()
      this.getEventosV4()
      this.getPendienteSincro()
    },
    descargarRegistro() {
      if (!this.filtros.fecha) {
        this.filtros.fecha = this.$moment().format('DD-MM-YY')
      }
      this.dowload_loading = true
      HTTP.post('/remitos/descargarEventos', this.filtros)
        .then((res) => {
          // console.log(res.data)
          this.dowload_loading = false
          if (!res.data || res.data == null) {
            this.$message({
              message: 'No hay registros para descargar',
              type: 'warning',
            })
          } else {
            window.location.href = res.data
          }
        })
        .catch((error) => {
          console.log(error)
          this.$alert('No se pudo realizar la acción. Intente nuevamente')
          this.dowload_loading = false
        })
    },
    descargarInstalaciones() {
      this.dowload_instalaciones = true
      HTTP.post('/remitos/descargarInstalaciones', this.filtros)
        .then((res) => {
          this.dowload_instalaciones = false
          if (!res.data || res.data == null) {
            this.$message({
              message: 'No hay registros para descargar',
              type: 'warning',
            })
          } else {
            window.location.href = res.data
          }
        })
        .catch((error) => {
          console.log(error)
          this.$alert('No se pudo realizar la acción. Intente nuevamente')
          this.dowload_instalaciones = false
        })
    },
    getChoferes() {
      HTTP.get('/remitos/getDatosChoferes')
        .then((res) => {
          this.choferes = res.data
        })
        .catch((error) => {
          console.log(error)
          this.$alert('No se pudo realizar la acción. Intente nuevamente')
        })
    },
    getNombreChofer(dni) {
      let result = this.choferes.filter((word) => word.dni == dni)
      // console.log(result[0].nombre)
      if (result) {
        if (typeof result[0] != undefined) {
          // console.log(result[0].nombre)
          return result[0].nombre != '' ? result[0].nombre : '-'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    getUuidChofer(dni) {
      let uuid = this.choferes.filter((word) => word.dni == dni)
      this.datosExtraChofer = []
      this.cargarChofer = true
      if (uuid) {
        HTTP.post('/remitos/getDatosExtraChofer', {
          uuid: uuid[0].uuid,
          nombre: uuid[0].nombre,
        })
          .then((res) => {
            if (res.data) {
              let nombre = { nombre: uuid[0].nombre }
              if (typeof res.data.ultima_fecha != 'undefined') {
                res.data.ultima_fecha = this.$moment(
                  res.data.ultima_fecha.date
                ).format('DD/MM/YYYY HH:mm:ss')
              }
              this.datosExtraChofer = Object.assign(res.data, nombre)
            }
            this.cargarChofer = false
          })
          .catch((error) => {
            this.cargarChofer = false
            console.log(error)
            this.$alert('No se pudo realizar la acción. Intente nuevamente')
          })
      } else {
        this.datosExtraChofer = []
      }

      let result = this.choferes.filter((word) => word.dni == dni)
      if (result) {
        return result[0].uuid
      } else {
        return ''
      }
    },
    getSeguimiento(terminal, numero, letra, dni) {
      this.guia_seguimiento =
        terminal.toString().padStart(4, 0) +
        '-' +
        numero.toString().padStart(8, 0)
      this.getUuidChofer(dni) //Obtengo más datos sobre el chofer encargado
      // Obtengo los datos del seguimiento Si posee
      HTTP.post('/remitos/getSeguimientoGuia', {
        terminal: terminal,
        numero: numero,
      })
        .then((response) => {
          if (response.data) {
            this.guia = response.data
            this.guia.seguimientos = response.data.seguimiento
            this.guia.guiadev = response.data.guiadev
            this.showSeguimiento = true
            setTimeout(() => {
              this.dialogSeguimiento = true
            }, 100)
          }
        })
        .catch((error) => {
          this.dialogSeguimiento = false
          this.$alert('Error en la búsqueda ' + error)
        })
      // Buscar datos de
    },
    getNroPres(seguimiento) {
      if (seguimiento.split('|')[0] == 'COMPROB RECIBIDO') {
        return true
      }
      return false
    },
    filterSuc(codigo) {
      return this.sucursales[codigo].nombre
        ? this.sucursales[codigo].nombre
        : '-'
    },
    openFoto(url) {
      window.open(url, '_blank')
    },
    gerLatLong(seguimiento) {
      var estado = seguimiento ? seguimiento.split('|') : ''
      var lat = estado[3].trim(),
        long = estado[2].trim()

      if (lat && long) {
        return true
      } else {
        return false
      }
    },
    mapaShow(seguimiento) {
      var estado = seguimiento ? seguimiento.split('|') : ''
      var lat = estado[3].trim(),
        long = estado[2].trim()
      this.dialogVisible = true
      this.center = [lat, long]
      this.geoJsonSource.data.features[0].geometry.coordinates = [lat, long]
    },
    sincronizarGuia(guia) {
      this.loadSincroGuia = guia.id
      HTTP.post('/approbot/sincronizarFusion', {
        hrsuc: guia.hrsuc,
        hojaruta: guia.hojaruta,
        operador: this.operador,
      })
        .then((res) => {
          // console.log('resultado',res.data)
          this.loadSincroGuia = false
          if (res.data == 0) {
            this.$message({
              message: 'No hay guías dispobibles para sincronizar',
              type: 'warning',
            })
          } else if (res.data == -10 || res.data == -11) {
            /* Guardamos fecha y hora en la que se bloquea */
            var hoy = this.$moment().format('YYYY-MM-DD')
            var tiempos = { tiempo: this.$moment().format('LT'), fecha: hoy }
            window.localStorage.setItem('comparar', JSON.stringify(tiempos))
            this.disableSincro = true
            setTimeout(this.disabledBtn, 600000)
          } else {
            // Sincronización exitosa
            this.$message({
              message: 'Sincronizacion exitosa.',
              type: 'success',
            })
          }
          this.disabledBtnSincro = false
          /* Refresco tabla */
          setTimeout(() => {
            this.getPendienteSincro()
            this.getTotalPendiente()
          }, 150)
        })
        .catch((e) => {
          this.loadSincro = false
          this.$alert('Error al sincronizar', e)
        })
      setTimeout(() => {
        this.loadSincroGuia = false
      }, 300)
    },
    getHojaRuta(hrsuc, hojaruta) {
      this.resumenHR = []
      HTTP.post('/guiasNew/getResumenHR2', {
        destino: hrsuc,
        hr: hojaruta,
      })
        .then((res) => {
          this.mostrarTable = true
          if (typeof res.data.resultado != 'undefined') {
            this.resumenHR = res.data.resultado
          }
        })
        .catch((e) => {
          console.log(e)
          this.mostrarTable = false
        })
    },
  },
}
</script>
<style lang="css">
.card-remitos > .el-card__header {
  padding: 10px;
  border-bottom: none;
}
.instalaciones > .el-card__header {
  padding: 10px;
}
</style>
<style lang="scss" scoped>
table.table-remitos tr td {
  padding: 5px !important;
  color: #333;
  font-size: 12px;
}

table.table-remitos > thead > tr > td {
  text-align: left;
  background: rgb(1, 74, 130) !important;
  color: white;
  position: sticky;
  top: -1px;
}

.wrapper {
  border: 1px solid #ddd;
  width: 100%;
  height: 500px;
  overflow-y: auto;
}

.instalaciones {
  padding: 10px;
}
.instalaciones h4 {
  text-align: center;
  margin: 0;
  margin-bottom: 6px;
  // line-height: 0.8;
}
.instalaciones h4 > small {
  color: #f44336;
  font-size: 13px;
  display: block;
}
.card-remitos.el-card__header {
  padding: 10px;
}
.card-remitos {
  height: 550px;
  margin-bottom: 60px;
  //   padding-bottom: 30px;
}
.login-ok > td {
  background: #f1f8e9 !important;
}

.hr-ok > td {
  background: #fff9c4 !important;
}
.pass-error > td {
  background: #ffccbc !important;
  border-color: #ffffff !important;
}
.estado-error > td {
  background: #f44336 !important;
  border-color: #ffffff !important;
  color: white !important;
}
@media (max-width: 768px) {
  .el-dialog {
    width: 100% !important;
  }
}
#table_detail {
  width: 500px;
  text-align: left;
  border-collapse: collapse;
  color: #2e2e2e;
  border: #a4a4a4;
}

#table_detail tr:hover {
  background-color: #f2f2f2;
}

details {
  width: 50%;
  margin: 0 auto;
  background: #282828;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.1rem 1rem -0.5rem rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
}

summary {
  padding: 1rem;
  display: block;
  background: #333;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

summary:before {
  content: '';
  border-width: 0.4rem;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.25s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
details[open] > summary:before {
  transform: rotate(90deg);
}

details summary::-webkit-details-marker {
  display: none;
}

details > ul {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.test {
  color: brown !important;
}
</style>
