<template>
  <el-form label-position="top" size="small">
    <el-row :gutter="10">
      <el-col :md="4" :xs="24">
        <el-form-item label="Busqueda:">
          <el-input clearable style="width: 100%" @keypress.native.enter="filtrar()" id="filter" name="filter" placeholder="Busque por razon social o numero" v-model="filter" size="mini" />
          <el-input type="text" v-show="false" /><!-- Previene reload -->
        </el-form-item>
      </el-col>
      <el-col :md="8" :xs="24">
        <el-form-item label="Convenio:">
          <el-select @change="filtrar()" size="mini" v-model="convenio_select" placeholder="Seleccione convenio" multiple clereable filterable style="width: 100%" clearable>
            <el-option v-for="item in convenios" :key="item.convenio" :label="item.nombre" :value="item.convenio">
              <span style="float: left"
                >{{ item.nombre }} - <span style="color: #8492a6; font-size: 13px"> {{ item.convenio }}</span></span
              >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="6" :xs="24">
        <el-form-item label="Respuesta API:">
          <el-select @change="filtrar()" size="mini" v-model="estado_select_api" placeholder="Seleccione el estado a Estados" clereable filterable style="width: 100%" clearable>
            <el-option v-for="item in estadosApi" :key="item.codigo" :label="item.codigo" :value="item.codigo">
              <span style="float: left">{{ item.codigo }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="4" :xs="24">
        <el-form-item label="Fechas:">
          <el-date-picker
            @change="filtrar()"
            size="mini"
            style="width: 100%"
            clearable
            v-model="fecha"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="Desde"
            end-placeholder="Hasta"
            :picker-options="datePickerOptions" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :md="12" :xs="24">
        <el-form-item label="Estado Informado:">
          <el-select @change="filtrar()" size="mini" v-model="estado_select" placeholder="Seleccione el estado a filtrar" multiple clereable filterable style="width: 100%" clearable>
            <el-option v-for="item in estados" :key="item.codigo" :label="item.completo" :value="item.codigo">
              <span style="float: left">{{ item.completo }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="10" :xs="24">
        <el-form-item label=".">
          <el-button-group>
            <el-button @click="filtrar()" icon="el-icon-search" size="mini" rounded>Buscar</el-button>
            <el-button @click="limpiarFiltro()" icon="el-icon-refresh" size="mini" rounded>Limpiar</el-button>
            <el-button :loading="btnLoading" @click="exportExcel()" size="mini" class="btnCross" style="margin-left: 5px"><i class="fas fa-file-excel"></i> Exportar</el-button>
          </el-button-group>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import XLSX from 'xlsx'
import { API } from '@/plugins/api'
import estadosJson from './_estados.json'
import { mapGetters } from 'vuex'

export default {
  name: 'south-filter',
  mounted() {
    this.convenios = this.getIntegraciones.convenios
  },
  data() {
    return {
      convenios: [],
      btnLoading: false,
      filter: '',
      convenio_select: ['608'],
      estado_select: [],
      estado_select_api: '',
      estados: estadosJson,
      estadosApi: [{ codigo: 'ERROR' }, { codigo: 'OK' }],
      fecha: null,
      datePickerOptions: null, // { 			disabledDate (date) { return date > new Date();} 		},
    }
  },
  methods: {
    filtrar() {
      var filtros = {}
      filtros.filter = this.filter
      //filtros.convenio = this.$store.getters.getDatos.convenio
      //Check fechas
      if (this.fecha != null) {
        filtros.filter_date = this.fecha[0] + '|' + this.fecha[1]
      }
      //Check convenios
      if (this.convenio_select.length > 0) {
        filtros.convenio_select = this.convenio_select
      }
      //Check estados
      if (this.estado_select.length > 0) {
        filtros.estado_select = this.estado_select
      }
      //Check estado api
      if (this.estado_select_api.length != '') {
        filtros.estado_select_api = this.estado_select_api
      }
      this.$events.fire('filter-set', filtros)
    },
    limpiarFiltro() {
      this.filter = ''
      this.convenio_select = []
      this.estado_select = []
      this.estado_select_api = ''
      this.fecha = null
      this.$events.fire('filter-reset')
    },
    exportExcel() {
      var filtros = {}
      filtros.filter = this.filter
      //filtros.convenio = this.$store.getters.getDatos.convenio
      //Check fechas
      if (this.fecha != null) {
        filtros.filter_date = this.fecha[0] + '|' + this.fecha[1]
      }
      //Check convenios
      if (this.convenio_select.length > 0) {
        filtros.convenio_select = this.convenio_select
      }
      //Check estados
      if (this.estado_select.length > 0) {
        filtros.estado_select = this.estado_select
      }
      this.btnLoading = true
      API.post('/south/export', filtros)
        .then((response) => {
          this.btnLoading = false
          let guias = response.data.data
          guias = guias.map((row) => {
            if (row.logs.length > 0) {
              let estado = estadosJson.find((x) => x.codigo == row.logs[0].estado_informado)
              let estadoTexto = 'DESCONOCIDO'

              if (estado !== undefined) estadoTexto = estado.texto
              return {
                ...row,
                estado_informado: estadoTexto,
                respuesta_api: row.logs[0].estado,
              }
            } else {
              return {
                ...row,
                estado_informado: 'SIN LOG',
                respuesta_api: 'SIN LOG',
              }
            }
          })
          console.log(guias)
          let data = XLSX.utils.json_to_sheet(guias)
          const workbook = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(workbook, data, 'southpost_log')
          XLSX.writeFile(workbook, `southpost_log.xlsx`)
        })
        .catch((error) => {
          this.btnLoading = false
          this.$message({
            message: error.response.data.message,
            type: 'error',
          })
          console.log(error)
        })
    },
  },
  computed: {
    ...mapGetters('Integraciones', ['getIntegraciones']),
  },
}
</script>
