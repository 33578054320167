<template>
  <el-dialog :visible.sync="modal.visible" width="80%">
    <span slot="title">
      <el-alert center :closable="false" effect="dark" style="background-color: #e5590a">
        <span slot="title"
          ><h2 style="text-align: center">Turnos {{ modal.tipo }}</h2></span
        >
        <h3 style="text-align: center">
          <i class="far fa-calendar"></i>
          {{ formatDate(modal.row[0].fecha, 'LL') }} | <i class="far fa-clock"></i> {{ modal.row[0].hora_inicial }}
        </h3>
      </el-alert>
    </span>
    <el-table :data="this.modal.row" size="mini" border fit>
      <el-table-column prop="id" label="#" width="50px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="convenio_id" label="CONVENIO" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.convenio != null">{{ scope.row.convenio.nombre }}</span>
          <span v-else>{{ this.getImportadora.nombre }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="proveedor_convenio" label="PROVEEDOR DE CONVENIO" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.proveedor_convenio !== null">{{ scope.row.proveedor_convenio }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="nave" label="NAVE" align="center" width="100px">
        <template slot-scope="scope">
          <el-tag :type="callNave(scope.row.nave)['color']" disable-transitions>{{ callNave(scope.row.nave)['texto'] }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="tipo_unidad" label="TIPO UNIDAD" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.tipo_unidad }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tipo_descarga" label="TIPO DESCARGA" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.tipo_descarga }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="cliente" label="PROVEEDOR DE DESCARGA" align="center">
        <template slot-scope="scope">
          <el-tag :type="callCliente(scope.row.cliente)['color']" disable-transitions>{{ callCliente(scope.row.cliente)['texto'] }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="estado_informado" label="Acciones" align="center">
        <template slot-scope="scope">
          <!-- <el-button type="primary" icon="el-icon-search" size="mini" @click="$events.fire('open-form-visor', scope.row)"></el-button> -->
          <el-button v-if="scope.row.estado < 4" type="info" icon="el-icon-refresh" size="mini" @click="$events.fire('cambiar-estado', scope.row)"></el-button>
          <el-button v-else type="primary" icon="el-icon-search" size="mini" @click="$events.fire('cambiar-estado', scope.row)"></el-button>
          <el-button type="primary" size="mini" @click="$events.fire('open-qr', scope.row)"><i class="fas fa-qrcode"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer">
      <el-button type="danger" size="medium" @click="$events.fire('close-form')" icon="el-icon-close">Cerrar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  name: 'visorTabla',
  props: {
    modal: { required: true },
  },
  methods: {
    formatDate(value, fmt = 'D MMM YYYY') {
      return value == null ? 'Sin registro' : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt)
    },
    callCliente(cliente) {
      var objEstado = {}
      if (cliente == 0) {
        objEstado.color = 'danger'
        objEstado.texto = 'NO'
      } else {
        objEstado.color = 'success'
        objEstado.texto = 'SI'
      }
      return objEstado
    },
    callNave(nave) {
      var objEstado = {}
      switch (nave) {
        case 1:
          objEstado.color = 'primary'
          objEstado.texto = 'NAVE 1'
          break
        case 2:
          objEstado.color = 'warning'
          objEstado.texto = 'NAVE 2'
          break
        default:
          objEstado.color = 'danger'
          objEstado.texto = 'Sin asignar'
          break
      }

      return objEstado
    },
  },
  computed: {
    ...mapGetters('Turnos', ['getImportadora']),
  },
}
</script>
