<template>
  <div>
    <!-- Modal de RFO -->
    <el-dialog width="80%" :visible.sync="loadingModal" top="5vh">
      <h1 class="h3-convenio">
        <center>{{ detalleDescrip.descrip }}</center>
      </h1>
      <el-row>
        <el-col :span="22" :offset="1">
          <el-card class="box-card">
            <el-date-picker
              v-model="fecha"
              type="daterange"
              range-separator="-"
              start-placeholder="Desde"
              end-placeholder="Hasta"
              size="small"
              format="dd/MM/yyyy"
              value-format="yyyyMMdd"
              :picker-options="pickerOptions">
            </el-date-picker>
            <el-button-group style="padding-left: 5px">
              <el-button
                size="small"
                type="success"
                @click="filtrar"
                :disabled="!fecha.length | conversion">
                Filtrar
              </el-button>
              <el-button
                size="small"
                type="danger"
                @click="borrarFiltro"
                :disabled="!borrarFiltroL"
                :loading="loadingFiltrar">
                Eliminar filtro
              </el-button>
            </el-button-group>
          </el-card>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" :offset="1">
          <el-card class="box-card" style="height: 440px">
            <div
              class=""
              style="
                margin-bottom: 20px;
                text-align: center;
                padding-bottom: 10px;
                border-bottom: 3px solid #f1f1f1;
              ">
              <span>Nombre de convenio</span>
              <h3 class="h3-convenio">{{ detalleDescrip.descrip }}</h3>
            </div>

            <div style="text-align: center">
              <span>Total de fotos</span>
              <h3 style="margin-top: 0px; font-size: 40px">
                {{ fotosRFO }}
              </h3>
            </div>
          </el-card>
        </el-col>
        <el-col :span="16" :offset="0">
          <el-card class="box-card" style="height: 442px">
            <el-table
              :data="tableDataModal"
              size="mini"
              border
              style="width: 100%"
              height="400">
              <el-table-column
                type="index"
                label="#"
                width="50"></el-table-column>
              <el-table-column
                align="center"
                prop="hojaruta"
                label="HOJA DE RUTA"
                width="100px;"
                style="text-align: center">
              </el-table-column>
              <el-table-column
                align="center"
                prop="hrsuc"
                label="HRSUC"
                width="100px;"
                style="text-align: center">
              </el-table-column>
              <el-table-column
                v-if="tableDataModal['fecha_app'] !== null"
                align="center"
                prop="fecha_app.date"
                label="FECHA - HORA"
                width="150px;"
                style="text-align: center">
                <template slot-scope="scope">
                  <span v-if="scope.row.fecha_app != null">
                    {{ scope.row.fecha_app.date | fecha }}
                  </span>
                  <span v-else> - </span>
                </template>
              </el-table-column>
              <el-table-column prop="" label="FOTOGRAFÍAS" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="success"
                    v-if="scope.row.url"
                    size="mini"
                    @click="mostrarFoto(scope.row)">
                    <i class="el-icon-zoom-in"></i> Ver foto
                  </el-button>

                  <el-button
                    type="danger"
                    v-if="!scope.row.url"
                    size="mini"
                    disabled="true">
                    Sin foto
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import { HTTP } from '@/axios'
const moment = require('moment')

export default {
  data() {
    return {
      loadingModal: false,
      tableDataModal: [],
      loadingFiltrar: false,
      borrarFiltroL: false,
      fotosRFO: 0,
      fecha: '',
      detalleDescrip: '',
      pickerOptions: {
        shortcuts: [
          {
            text: 'Últ. Semana',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Últ. Mes',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '3 Últ. meses',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      // urlempresa : window.localStorage.getItem('empresa') == 'u' ? 'https://caba33.suizoargentina.com.ar' : 'https://cdn.log-in.com.ar'
      urlempresa:
        window.localStorage.getItem('empresa') == 'u'
          ? 'https://img.suizoargentina.com.ar'
          : 'https://img.log-in.com.ar',
    }
  },
  mounted() {
    EventBus.$on('detalleDescrip', (descrip) => {
      this.detalleDescrip = descrip
      // console.log('datos',descrip)
    })
    EventBus.$on('detalleConvenio', (g) => {
      this.loadingModal = true
      if (this.$refs.tableViewBox && this.$refs.tableViewBox.bodyWrapper) {
        this.$refs.tableViewBox.bodyWrapper.scrollTop = 0
      }
      this.cargando = false
      /* Si vienen elementos cargo la tabla */
      if (g.length > 0) {
        // console.log(g)
        this.tableDataModal = g
        this.cantidad_elementos = g[0].TotalRows
        this.fotosRFO = g.length ? g[0].TotalRows : 0
      } else {
        this.tableDataModal = []
        this.cantidad_elementos = 0
        this.fotosRFO = 0
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('detalleDescrip')
    EventBus.$off('detalleConvenio')
  },
  methods: {
    filtrar() {
      this.loadingFiltrar = true
      HTTP.post('/guiasNew/detallesRFO', {
        olconv: this.detalleDescrip.olconv,
        desde: this.fecha[0],
        hasta: this.fecha[1],
      })
        .then(async (res) => {
          this.borrarFiltroL = true
          this.loadingFiltrar = false
          this.tableDataModal = res.data
          this.fotosRFO = res.data[0].TotalRows
        })
        .catch((error) => console.log(error))
    },
    mostrarFoto(row) {
      window.open(this.urlempresa + row.url)
    },
    borrarFiltro() {
      this.loadingFiltrar = true
      HTTP.post('/guiasNew/detallesRFO', {
        olconv: this.detalleDescrip.olconv,
      })
        .then(async (res) => {
          this.borrarFiltroL = false
          this.loadingFiltrar = false
          this.tableDataModal = res.data
          this.fecha = []
          this.fotosRFO = res.data[0].TotalRows
        })
        .catch((error) => console.log(error))
    },
  },
  filters: {
    // Pipe para pransforma 1 en true y 0 en false.
    conversion: function (date) {
      return date ? true : false
    },
    fecha: function (date) {
      return moment(date).format('DD-MM-YYYY H:mm:ss')
    },
  },
}
</script>
