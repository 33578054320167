<template>
<div>
        <center>
          <div style="text-aling:center;width: 80%;border-bottom:1px dashed #ddd"></div>
          <el-row>
            <h3 style="margin-top: 10px;padding-left: 15%; padding-right: 15%;margin-bottom:0px">
                Convenios con registro fotográfico obligatorio: <span>{{tableData.length}}</span>
            </h3>
          </el-row>
            <el-table  size="mini" border
                  :data="tableData.filter(data => !search || data.descrip.toLowerCase().includes(search.toLowerCase()) ||  data.olconv == search)" 
                                 v-loading="loading" style="width: 80%;" height="50vh">
                    <el-table-column label="#" type="index" width="50" ></el-table-column>
                    <el-table-column width="100" label="Convenio"  prop="olconv">      
                    </el-table-column>
                    <el-table-column  label="Descripción" prop="descrip">
                    </el-table-column>
                    <el-table-column align="right" width="300">
                         <template slot="header" slot-scope="{}">
                            <el-input
                              v-model="search"
                              size="mini"
                              placeholder="Buscar convenio"/>
                          </template>
                        
                        <template slot-scope="scope">
                        <center v-if="scope.row.escaneo_APP == 'S'">
                          <el-button :disabled="scope.row.escaneo_APP != 'S'"
                          size="mini"
                          @click="getDetallesRFO(scope.row)" :loading="loadingDetalles == scope.row.olconv">Detalles</el-button>
                          <el-button :disabled="scope.row.escaneo_APP !='S' "
                          size="mini"
                          type="danger" @click="deleteConvenio(scope.row)">Quitar RFO</el-button>
                        </center>    
                        <center v-else>
                          <el-button :disabled="scope.row.escaneo_APP == 'S'" type="success"
                          size="mini"
                          @click="addConvenio(scope.row)" :loading="loadingDetalles == scope.row.olconv">Agregar RFO</el-button>
                         
                        </center>        
                    </template>
                    </el-table-column>
            </el-table>
        </center>
        <br>
    <modal-remitos-f></modal-remitos-f>
</div>
</template>
<script>
import {HTTP} from '@/axios'
const moment = require('moment')
import { EventBus } from "@/event-bus";
import ModalRemitosF from '@/components/remitos/modalRemitosF.vue';


export default {
    props:['tabla'],
    components:{
        ModalRemitosF
    },
    data() {
        return {
            tableData: [],
            loadingDetalles: false,
            search: '',
            loading: true,
            detalleConvenio: [],
            fotosRFO: 0,
            operador:JSON.parse(window.localStorage.getItem("datos")).uid + '-'+JSON.parse(window.localStorage.getItem("datos")).user,
          tablaData: [{
          date: '2016-05-03',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-02',
          name: 'John',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-04',
          name: 'Morgan',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-01',
          name: 'Jessy',
          address: 'No. 189, Grove St, Los Angeles'
        }],
        }
    },
    mounted() {     
      this.getAllConv()
    },
  
    methods: {
      /* Cargar todos los convenios */
      getAllConv() {
        this.loading = true;
        HTTP.get('/guiasNew/getAllConvenios')
          .then(res => {
            this.tableData = res.data;
            setTimeout(() => {
                this.loading = false;
            }, 500);
           
        });
      },
      // abre un modal con la información de un convenio con RFO - ok! Modificado 15/01/21
      getDetallesRFO( row) {
        this.loadingDetalles = row.olconv;
        HTTP.post('/guiasNew/detallesRFO', {
            olconv: row.olconv,
          })
          .then(async (res) => {
            // this.loadingModal = true;
            EventBus.$emit("detalleDescrip",row);
            if(res.data.length == 0)
            {
                EventBus.$emit("detalleConvenio",0);
            }
            else
            {
                EventBus.$emit("detalleConvenio", res.data);
            }
            this.loadingDetalles = false;
          })
          .catch(error => console.log(error));
      },
      deleteConvenio(row) {
        // console.log(row)
        this.$confirm(`¿Esta seguro que desea quitar el convenio: <strong>${row.descrip}<strong>?`, 'Remitos App - RFO', {
            confirmButtonText: 'SI',
            cancelButtonText: 'CANCELAR',
            type: 'warning',
            dangerouslyUseHTMLString: true,
            closeOnPressEscape: false,
            closeOnClickModal: false
          }).then(() => {
            HTTP.post('/guiasNew/deleteConvenio', {
                olconv: row.olconv,
                operador: this.operador
              })
              .then(res => {
                this.loading = true;
                if(res.data !=0)
                {
                this.getAllConv();
                this.$message({
                  type: 'info',
                  message: `<strong>Se quito el convenio: ${row.descrip}</strong>`,
                  dangerouslyUseHTMLString: true,
                  duration: 2000
                });
                }else
                {
                    this.$message.error('Lo siento, no se pudo realizar la acción');
                }

              })
              .catch(error => console.log(error));
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: `No se quito el convenio: ${row.olconv}`,
              duration: 2000
            });
          });
      },
      addConvenio(row) {
          this.loading = true;
             this.$confirm(`¿Esta seguro que desea agregar el convenio: <strong>${row.descrip}<strong>?`, 'Remitos App - RFO', {
            confirmButtonText: 'SI',
            cancelButtonText: 'CANCELAR',
            type: 'success',
            dangerouslyUseHTMLString: true,
            closeOnPressEscape: false,
            closeOnClickModal: false
          }).then(() => {
              HTTP.post('/guiasNew/addConvenio', {
                olconv: row.olconv,
                operador: this.operador
              })
              .then(res => {
                if(res.data['estado']==1)
                {
                  this.$message({
                    message: 'Se agregó convenio correctamente',
                    type: 'success'
                  });
                    this.getAllConv();
                }else
                {
                  this.$message.error('No se pudo realizar la acción. Intente nuevamente.');
                }          
              })
              .catch(error => console.log(error));
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: `No se quito el convenio: ${row.olconv}`,
              duration: 2000
            });
          });
    },
    },
    filters: {
      // Pipe para pransforma 1 en true y 0 en false.
      conversion: function(date) {
        return date ? true : false;
      },
      fecha: function(date) {
				return moment(date).format('DD-MM-YYYY H:mm:ss');
			},
    }
}
</script>