<template>
  <div v-loading="sincronizando" element-loading-text="Sincronizando..." element-loading-background="rgba(0, 0, 0, 0.8)">
    <div v-if="!showEscaneo">
      <el-row :gutter="20">
        <el-col :md="24">
          <el-card shadow="always" class="instalaciones" style="padding-bottom: 0px; padding-top: 10px; padding: 0px !important">
            <el-row :gutter="20">
              <el-col :md="4" :sm="8" :xs="12">
                <label for="" class="vpim-label">Fecha escaneo:</label>
                <el-select v-model="formInline.fecha" placeholder="Seleccione fecha" clearable size="small" class="vpim-select">
                  <el-option v-for="fcha in fechaSelect" :key="fcha.id" :label="fcha.nombre" :value="fcha.id" :disabled="blockMes(fcha.id)"></el-option>
                </el-select>
              </el-col>
              <!-- <el-col :md="6" :sm='8' :xs='12'>
                            <label for="" class="vpim-label">Sucursales:</label>
                            <el-select v-model="formInline.value" placeholder="Seleccionar sucursal" class="vpim-select" size="small" multiple  collapse-tags clearable filterable >
                        <el-option
                        v-for="item in sucProps"
                        :key="item.codigo"
                        :label="item.codigo+'-'+item.nombre "
                        :value="item.codigo">
                        </el-option>
                    </el-select>
                        </el-col> -->
              <el-col :md="6">
                <el-button-group style="margin-top: 18px">
                  <el-button type="primary" size="small" icon="el-icon-search" @click="buscarDatos(formInline)"> Buscar </el-button>
                  <el-button type="default" size="small" icon="el-icon-close" @click="limpiarFiltro()"> Limpiar </el-button>
                </el-button-group>
              </el-col>
              <el-col :md="14">
                <el-button type="success" plain @click="descargarExcel(formInline)" icon="el-icon-download" style="margin-top: 18px; float: right" size="small" :loading="loadDescargar"
                  >Descargar</el-button
                >
                <el-button type="danger" style="margin-top: 18px; float: right; margin-right: 5px" size="small" icon="el-icon-refresh" @click="sincronizarTablas"></el-button>
                <br />
                <small v-if="lastSincro" class="lastSincro"
                  >última sincronización <br />
                  <strong> {{ lastSincro | moment('DD-MM-YYYY HH:mm') }}</strong></small
                >
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <center>
          <el-col :span="24">
            <p class="font-title" :style="styleTitleTabla">Búsqueda realizada entre: {{ fecha_busqueda }}{{ fraseTotal }}</p>
          </el-col>
        </center>
      </el-row>
      <div v-if="mostrarResultado">
        <el-row :gutter="20">
          <el-col :md="10" :sm="24" :xs="24">
            <el-table
              :data="tablaSuc"
              style="width: 100%"
              v-loading="cargandoTabla"
              element-loading-text="Loading..."
              show-summary
              element-loading-spinner="el-icon-loading"
              border
              fit
              stripe
              height="600"
              :default-sort="{ prop: 'cantidad', order: 'descending' }">
              <el-table-column prop="nombre" label="Sucursal"> </el-table-column>
              <el-table-column prop="cantidad" sortable label="Cantidad" width="70"> </el-table-column>
              <el-table-column width="100" label="">
                <template slot-scope="scope">
                  <center>
                    <el-button v-if="scope.row.suc == null" :type="'danger'" :icon="'el-icon-s-order'" size="mini" plain @click="getGroup()" :disabled="scope.row.cantidad == 0"
                      >Ver</el-button
                    >
                  </center>
                  <center>
                    <el-button v-if="scope.row.suc != null" :type="'danger'" :icon="'el-icon-s-order'" size="mini" plain @click="comprobSuc(scope.row)" :disabled="scope.row.cantidad == 0"
                      >Ver</el-button
                    >
                  </center>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :md="14" :sm="24" :xs="24">
            <center><el-button @click="updateChart" icon="el-icon-refresh" size="medium" style="margin-top: 20px" type="info">Refrescar gráfica</el-button></center>
            <div v-if="showGrafica && total > 0">
              <center>
                <apexchart
                  class="hidden-xs-only"
                  width="90%"
                  v-loading="cargarGrafica"
                  type="pie"
                  style="padding-top: 50px; padding-left: 5px; padding-right: 5px"
                  :options="chartOptions"
                  :series="series"></apexchart>

                <!-- <apexchart  width="150%" v-loading="cargarGrafica"  type="pie"
                    :options="chartOptions"
                    :series="series"></apexchart> -->
              </center>
            </div>
            <div v-if="showGrafica && total == 0">
              <center><p>No hay datos para mostrar</p></center>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <el-row :gutter="20" style="padding-top: 10px;">
            <el-col :md="6" :sm="8" :xs="12" style="padding-bottom:10px;" v-if="showSinGuias">
                <el-card
                    shadow="always"
                    class="instalaciones"
                    :body-style="{ padding: '0px',textAlign:'center' }" :loading="cargarDatos"
                    >
                    <el-button plain style="border: 0px solid #DCDFE6;"
                    @click="getGroup" :loading="cargarS" :disabled ="cantSguias==0">
                     <h1>
                     <small>  COMPROBANTES SIN GUÍAS</small> <br>
                     {{cantSguias}}
                     </h1>
                    </el-button>
                </el-card>
            </el-col>
            <div v-for="suc in sucursales" :key="suc.codigo">
            <el-col :md="6" :sm="8" :xs="12" style="padding-bottom:10px;" >
                <el-card
                    shadow="always"
                    class="instalaciones"
                    :body-style="{ padding: '0px',textAlign:'center' }" :loading="cargarDatos"
                    >
                    <el-button plain style="border: 0px solid #DCDFE6;" :loading="cargarPag" @click="comprobSuc(suc)"
                    :disabled ="suc.cantidad==0">
                     <h1>
                     <small>{{suc.codigo}}-{{suc.nombre}}</small>
                     <br>
                     {{suc.cantidad}}
                     </h1>
                    </el-button>
                </el-card>
            </el-col>
            </div>
        </el-row> -->
      <br />
    </div>
    <!-- Mostrar los comprobantes sin guias por convenios -->
    <div v-if="showGroup" v-loading="loadGroup">
      <!-- la cantidad de guias por convenios -->
      <sin-guias v-loading="loadGroup" :datos="datos"></sin-guias>
      <!-- ---------------------------------- -->
    </div>
    <!-- Mostrar los comprobantes con guias por sucursal -->
    <div v-if="showComprobSuc">
      <!-- TABLA COMPROBANTES CON GUÍA POR SUCURSAL -->
      <tabla-suc-escaneo :tipo="'SUC'" :mes="buscarMes()" :datosTabla="datosComprob" :styleTitleTabla="styleTitleTabla"></tabla-suc-escaneo>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
import { EventBus } from '@/event-bus'

import tablaSucEscaneo from './tablaEscaneo.vue'
import SinGuias from './resumenSinGuias.vue'

export default {
  props: ['sucProps', 'showSuma'],
  components: { SinGuias, tablaSucEscaneo },
  data() {
    return {
      sincronizando: false,
      lastSincro: window.localStorage.getItem('lastSincro') ? JSON.parse(window.localStorage.getItem('lastSincro')) : undefined,
      sucursales: [],
      empresa: window.localStorage.getItem('empresa'),
      cantSguias: 0,
      cargarDatos: false,
      ceros: [],
      cerosGuias: [],
      value: [],
      sinGuia: { codigo: 0, nombre: 'COMPROBANTES SIN GUÍAS' },
      sucFiltrar: [],
      showSinGuias: false,
      // sucProps:this.sucProps,
      sucTabla: '',
      showEscaneo: false,
      showComprobSuc: false,
      total: 0,
      fechaSelect: [
        { id: '0', nombre: 'TODOS' },
        { id: '01', nombre: 'ENERO' },
        { id: '02', nombre: 'FEBRERO' },
        { id: '03', nombre: 'MARZO' },
        { id: '04', nombre: 'ABRIL' },
        { id: '05', nombre: 'MAYO' },
        { id: '06', nombre: 'JUNIO' },
        { id: '07', nombre: 'JULIO' },
        { id: '08', nombre: 'AGOSTO' },
        { id: '09', nombre: 'SEPTIEMBRE' },
        { id: '10', nombre: 'OCTUBRE' },
        { id: '11', nombre: 'NOVIEMBRE' },
        { id: '12', nombre: 'DICIEMBRE' },
      ],
      formInline: {
        value: [],
        fecha: '',
      },
      fecha_busqueda: '',
      cargarPag: false,
      cargarS: false,
      mesActual: this.$moment().format('MM'),
      fraseTotal: '',
      idsSinSuc: [],
      loadDescargar: false,
      styleTitleTabla: { color: 'white' },
      fecha_aux: '',
      arraySinGuias: [],
      showGroup: false,
      permisos_usuario: window.localStorage.getItem('permisos'),
      permisos: {
        lista: false,
        multi: false,
      },
      loadGroup: false,
      convenios: [],
      datos: {}, //Datos de comprobantes sin guias
      datosComprob: {}, //Datos de comprobantes por suc,
      volverBuscar: false,
      precarga: [],
      tablaSuc: [],
      cargandoTabla: false,
      series: [],
      chartOptions: {
        // theme: {
        //      monochrome: {
        //         enabled: true
        //     }
        // },

        labels: [],
        colors: [],
        title: {
          text: 'Resumen de escaneo',
        },
      },
      seriesArray: [],
      cargarGrafica: true,
      showGrafica: false,
      mostrarResultado: false,
      bloquearMes: false,
    }
  },
  created() {
    this.buscarDatos(this.formInline)
    this.blockMes()
  },
  mounted() {
    this.chartOptions.labels = []
    this.seriesArray = []
    this.series = []
    EventBus.$on('pagPrincipal', (p) => {
      if (p) {
        this.showEscaneo = false
        this.showGroup = false
        this.showComprobSuc = false
        this.volverBuscar = true
        setTimeout(() => {
          this.buscarDatos(this.formInline)
        }, 500)
      }
    })

    this.showEscaneo = false
    setTimeout(() => {
      let actual = this.$moment().format('DD/MM/YYYY')
      this.fecha_busqueda = this.$moment().startOf('month').format('DD/MM/YYYY') + '-' + actual
    }, 600)
    this.styleTitleTabla['backgroundColor'] = this.empresa == 'u' ? '#0b406a' : '#36404e'
  },
  // beforeDestroy() {
  //   EventBus.$off('pagPrincipal')
  // },
  methods: {
    updateChart() {
      this.chartOptions.labels = []
      this.series = []
      var cantidad = 0
      this.cargarGrafica = false

      for (let index = 0; index < this.tablaSuc.length; index++) {
        this.chartOptions.labels[index] = this.tablaSuc[index].nombre
        this.series[index] = this.tablaSuc[index].cantidad
        cantidad += 1
        if (cantidad == this.tablaSuc.length) {
          this.mostrarResultado = true
          this.showGrafica = true
        }
      }
    },
    sincronizarTablas() {
      this.sincronizando = true
      HTTP.get('/robotescaner/sincronizarTablas')
        .then((res) => {
          this.sincronizando = false
          if (res.data) {
            window.localStorage.setItem('lastSincro', JSON.stringify(new Date()))
          }
        })
        .catch((e) => {
          this.$alert('No se pudo realizar la sincronización error: ' + e)
          this.sincronizando = false
        })
    },
    getComprobGuias(fecha_buscar) {
      if (fecha_buscar == '') {
        fecha_buscar = [this.$moment().startOf('month').format('YYYYMMDD'), this.$moment().format('YYYYMMDD')]
      }
      this.cargarS = true
      HTTP.post('/escaner/contarComprobGuias', { empresa: this.empresa, fecha: fecha_buscar })
        .then((res) => {
          if (res.data.length > 0) {
            this.cantSguias = res.data.reduce((a, b) => {
              return a + b.cantidad
            }, 0)
            this.arraySinGuias = res.data
            this.convenios = this.arraySinGuias.map(function (obj) {
              let rObj = {}
              rObj['convol'] = obj.convol
              rObj['nombre'] = obj.convenio
              return rObj
            })
          } else {
            this.cantSguias = 0
            this.arraySinGuias = []
            this.convenios = []
          }

          this.cargarS = false
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
          this.cargarS = false
        })
    },
    getComprobSuc(sucursales, fecha_buscar) {
      this.total = 0
      if (fecha_buscar == '') {
        fecha_buscar = [this.$moment().startOf('month').format('YYYYMMDD'), this.$moment().format('YYYYMMDD')]
      }
      this.cargarPag = true
      this.total = 0
      // this.getComprobGuias(fecha_buscar)
      this.chartOptions.labels = []
      this.chartOptions.colors = []
      // this.chartOptions.labels [0]   = 'COMPROBANTES SIN GUÍAS'
      HTTP.post('/escaner/contadorComprobxSuc', { suc: sucursales, fecha: fecha_buscar }).then((res) => {
        if (res.data) {
          let arraySum = Object.values(res.data)
          /* Primera busqueda por canalizador_suc */
          this.precarga[0] = { suc: null, nombre: 'COMPROBANTES SIN GUÍAS', cantidad: arraySum[arraySum.length - 1] }
          var cantidad = 1
          this.seriesArray[0] = arraySum[arraySum.length - 1]
          this.chartOptions.colors[0] = this.colorHEX()
          this.chartOptions.labels[0] = 'COMPROBANTES SIN GUIAS'
          for (let i = 0; i < arraySum.length - 1; i++) {
            if (arraySum[i] != null) {
              sucursales[i].cantidad = arraySum[i]
              this.total = this.total + arraySum[i]
              this.seriesArray[i + 1] = arraySum[i]
            } else {
              sucursales[i].cantidad = 0
              this.seriesArray[i + 1] = 0
            }
            this.precarga[i + 1] = { suc: sucursales[i], nombre: sucursales[i].codigo + ' - ' + sucursales[i].nombre, cantidad: sucursales[i].cantidad }
            this.chartOptions.labels.push(sucursales[i].codigo + ' - ' + sucursales[i].nombre)
            this.chartOptions.colors[i + 1] = this.colorHEX()
            cantidad += 1
          }

          this.total = this.total + arraySum[arraySum.length - 1]
          this.cargarS = false
          this.fraseTotal = '-' + 'Comprobantes asociados con alguna sucursal en el período de búsqueda: ' + this.total
          this.cargandoTabla = false
          if (cantidad == arraySum.length) {
            this.mostrarResultado = true
            this.showGrafica = true
            this.cargarGrafica = false

            setTimeout(() => {
              this.tablaSuc = this.precarga.slice()
              this.series = this.seriesArray.slice()
              // this.colorElegidos()
            }, 350)
          }
        }
        setTimeout(() => {
          this.sucursales = sucursales
          this.sucFiltrar = sucursales //let aux
          this.cargarPag = false
          this.showSinGuias = true
          this.cargandoTabla = false
        }, 400)
      })
    },
    datosFiltro(filtros) {
      let comienzo = '',
        final = '',
        suc = [],
        fecha_b = '',
        sucFiltrados = [],
        anio = this.$moment().years()

      if (filtros.value.length > 0) {
        for (let index = 0; index < filtros.value.length; index++) {
          sucFiltrados.push(this.sucProps[this.sucProps.findIndex((element) => element.codigo == filtros.value[index])])
        }
        suc = sucFiltrados
      } else {
        suc = this.sucProps
      }

      if (filtros.fecha != '') {
        // Mes actual
        // let mes = this.$moment().format('MM');

        /* Si el mes de búsqueda es la actual */
        if (filtros.fecha == '0') {
          comienzo = this.$moment().startOf('year').format('YYYYMMDD')
          final = this.$moment().endOf('year').format('YYYYMMDD')
          this.fecha_busqueda = this.$moment().startOf('year').format('DD/MM/YYYY') + '-' + this.$moment().format('DD/MM/YYYY')
        } else if (this.mesActual == filtros.fecha) {
          comienzo = this.$moment().startOf('month').format('YYYYMMDD')
          final = this.$moment().format('YYYYMMDD')
          this.fecha_busqueda = this.$moment().startOf('month').format('DD/MM/YYYY') + '-' + this.$moment().format('DD/MM/YYYY')
        } else {
          let month = this.$moment(anio + '-' + filtros.fecha, 'YYYY-MM')
          comienzo = this.$moment(month).startOf('month').format('YYYYMMDD')
          final = this.$moment(month).endOf('month').format('YYYYMMDD')
          this.fecha_busqueda = this.$moment(month).startOf('month').format('DD/MM/YYYY') + ' - ' + this.$moment(month).endOf('month').format('DD/MM/YYYY')
        }
        fecha_b = [comienzo, final]
      }

      return { sucursales: suc, fecha_busq: fecha_b }
    },
    buscarDatos(filtros) {
      this.cargarPag = true
      filtros.fecha = filtros.fecha == '' ? this.$moment().format('MM') : filtros.fecha
      let resultados = this.datosFiltro(filtros)
      this.cargandoTabla = true
      this.cargarGrafica = true
      this.series = []
      this.chartOptions.labels = []
      this.chartOptions.colors = []
      this.getComprobSuc(resultados.sucursales, resultados.fecha_busq)
      // this.updateChart()
      // Asignar colores

      if (this.series != []) {
        //    this.updateChart()
      }
      this.fecha_aux = filtros.fecha
    },
    getTable() {
      this.showEscaneo = true
    },
    limpiarFiltro() {
      this.fecha_aux = this.$moment().format('MM')
      let comienzo = this.$moment().startOf('month').format('YYYYMMDD')
      let final = this.$moment().format('YYYYMMDD')
      this.fecha_busqueda = this.$moment().startOf('month').format('DD/MM/YYYY') + '-' + this.$moment().format('DD/MM/YYYY')
      this.formInline.value = []
      this.formInline.fecha = this.fecha_aux
      let fecha_b = [comienzo, final]
      this.cargarPag = true
      this.getComprobGuias(fecha_b)
      this.getComprobSuc(this.sucProps, fecha_b)
    },
    descargarExcel(filtros) {
      let sucFiltrados = [],
        cantidadDias = 0,
        // mes             = this.$moment().format('MM'),
        anio = this.$moment().years(),
        arrayFechas = [],
        fecha_dias = ''

      this.loadDescargar = true
      if (filtros.value.length > 0) {
        for (let index = 0; index < filtros.value.length; index++) {
          sucFiltrados.push(this.sucProps[this.sucProps.findIndex((element) => element.codigo == filtros.value[index])])
        }
      } else {
        sucFiltrados = this.sucProps
      }
      let month = ''
      /* Si el mes de búsqueda es la actual */
      if (filtros.fecha == '' || filtros.fecha == this.mesActual) {
        cantidadDias = this.$moment().format('DD')
        filtros.fecha = this.mesActual
      } else {
        month = this.$moment(anio + '-' + filtros.fecha, 'YYYY-MM')
        cantidadDias = this.$moment(month).endOf('month').format('DD')
      }
      // Todo el año
      if (filtros.fecha != '0') {
        for (let index = 1; index <= cantidadDias; index++) {
          if (index < 10) {
            fecha_dias = this.$moment(this.$moment(anio + '-' + filtros.fecha + '-' + 0 + index, 'YYYY-MM-DD')).format('YYYYMMDD')
          } else {
            fecha_dias = this.$moment(this.$moment(anio + '-' + filtros.fecha + '-' + index, 'YYYY-MM-DD')).format('YYYYMMDD')
          }
          arrayFechas.push(fecha_dias)
        }
      } //por mes
      else {
        for (let i_mes = 1; i_mes <= this.mesActual; i_mes++) {
          month = this.$moment(anio + '-' + i_mes, 'YYYY-MM')
          cantidadDias = i_mes == this.mesActual ? this.$moment().format('DD') : this.$moment(month).endOf('month').format('DD')

          for (let index = 1; index <= cantidadDias; index++) {
            if (index < 10) {
              fecha_dias = this.$moment(this.$moment(anio + '-' + i_mes + '-' + 0 + index, 'YYYY-MM-DD')).format('YYYYMMDD')
            } else {
              fecha_dias = this.$moment(this.$moment(anio + '-' + i_mes + '-' + index, 'YYYY-MM-DD')).format('YYYYMMDD')
            }
            arrayFechas.push(fecha_dias)
          }
        }
      }

      HTTP.post('/escaner/excelComprobEscaneados', { suc: sucFiltrados, fechas: arrayFechas }).then((res) => {
        this.loadDescargar = false
        window.open(res.data, '_blank')
      })
    },

    getGroup() {
      this.showEscaneo = true
      this.showComprobSuc = false
      setTimeout(() => {
        this.showGroup = true
        this.loadGroup = false
      }, 400)
      this.datos = {
        busqueda: 'Convenios',
        arraySinGuias: this.arraySinGuias,
        fecha_b: this.fecha_busqueda,
        cantSguias: this.cantSguias,
        styleTitleTabla: this.styleTitleTabla,
        fecha_Select: this.fechaSelect,
        mes: this.formInline.fecha,
      }
    },
    bloquearVistas() {
      this.showEscaneo = false
      this.showGroup = false
      setTimeout(() => {
        this.buscarDatos(this.formInline)
      }, 200)
    },
    comprobSuc(suc) {
      this.showEscaneo = true
      this.showGroup = false
      setTimeout(() => {
        this.showComprobSuc = true
      }, 200)

      let filtros_cambiados = this.datosFiltro(this.formInline)
      this.datosComprob = { sucursal: suc.suc, fecha: filtros_cambiados.fecha_busq }
    },
    generarLetra() {
      var letras = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      var numero = (Math.random() * 15).toFixed(0)
      return letras[numero]
    },
    colorHEX() {
      var color = '46'
      for (var i = 0; i < 2; i++) {
        color = color + this.generarLetra()
      }
      color += 'b4'
      return '#' + color
    },
    // colorElegidos()
    // {
    //     this.chartOptions.colors[0] = '#3D56B2'
    //     this.chartOptions.colors[1] = '#5C7AEA'
    //     this.chartOptions.colors[2] = '#90AACB'
    //     this.chartOptions.colors[3] = '#A2CDCD'
    //     this.chartOptions.colors[4] = '#889EAF'
    //     this.chartOptions.colors[5] = '#22577A'
    //     this.chartOptions.colors[6] = '#2D46B9'
    //     this.chartOptions.colors[7] = '#6E85B2'
    //     this.chartOptions.colors[8] = '#0F52BA'
    //     this.chartOptions.colors[9] = '#5089C6'
    //     this.chartOptions.colors[10] = '#96BAFF'
    //     this.chartOptions.colors[11] = '#39A2DB'
    //     this.chartOptions.colors[12] = '#77ACF1'
    //     this.chartOptions.colors[13] = '#2940D3'
    //     this.chartOptions.colors[14] = '#185ADB'
    //     this.chartOptions.colors[15] = '#344FA1'
    //     this.chartOptions.colors[16] = '#0061A8'
    //     this.chartOptions.colors[17] = '#31326F'
    //     this.chartOptions.colors[18] = '#719FB0'
    //     this.chartOptions.colors[19] = '#23689B'
    //     this.chartOptions.colors[20] = '#55B3B1'
    //     this.chartOptions.colors[21] = '#30475E'
    //     this.chartOptions.colors[22] = '#6F9EAF'
    //     this.chartOptions.colors[23] = '#A7C5EB'
    //     this.chartOptions.colors[23] = '#98ACF8'

    // },
    blockMes(mes) {
      let actual = this.$moment().format('MM')
      if (mes <= actual) {
        return false
      }
      return true
    },
    buscarMes() {
      return this.fechaSelect.filter((elemento) => elemento.id == this.fecha_aux)[0].nombre
    },
  },
}
</script>
<style scoped>
.instalaciones {
  padding: 10px;
}
.instalaciones h1 {
  text-align: center;
  margin: 0;
  margin-bottom: 6px;
  color: #0b406a;
}
.instalaciones h1 > small {
  color: #f44336db;
  font-size: 12px;
}
.font-title {
  padding: 10px;
}
.lastSincro {
  display: block;
  float: right;
}
.lastSincro strong {
  color: red;
}
</style>
