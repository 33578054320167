<template>
  <div>
    <el-row style="text-align: center" :gutter="10">
      <center>
        <el-col :md="24">
          <p class="bold title-stock">Total de productos encontrados:{{ cantidad_elementos }}</p>
        </el-col>
      </center>
    </el-row>
    <el-table :data="tableData" width="80" border stripe fit v-loading="cargando" size="small" height="40vh" @row-click="detallesProd">
      <el-table-column type="index" width="50" label="#"> </el-table-column>
      <el-table-column prop="descrip" label="Descripción">
        <template slot-scope="scope">
          <el-popover placement="top" trigger="hover" style="float: left; background: #ffffffd6 !important">
            <h5 style="margin-bottom: 0px; margin-top: 0px; color: white; background-color: #e55a0b; text-align: center; padding: 3px">
              {{ scope.row.descrip }}
            </h5>
            <el-row
              ><strong>Rubro:</strong>
              <span v-if="scope.row.rubroN != null"> {{ scope.row.rubroN }}</span>
              <span v-else> - </span>
            </el-row>
            <div slot="reference" class="name-wrapper" style="margin-top: 3px">
              <span>{{ scope.row.descrip }}</span>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="codprov" label="Código" width="100"> </el-table-column>
      <el-table-column prop="codigo" label="Cód. Login" width="100"> </el-table-column>
      <el-table-column prop="lf_cod" label="Cód. Prov." width="100"> </el-table-column>
      <el-table-column prop="deposito" label="Depósito" width="80"> </el-table-column>
      <el-table-column prop="stock" label="Stock" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.stock != null && scope.row.stock != 0">{{ scope.row.stock }} </span>
          <span v-else style="color: #dd6161"><strong>No</strong></span>
        </template>
      </el-table-column>
      <el-table-column v-if="permisoVerStock" prop="disponible" label="Disponible" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.disponible != 0">{{ scope.row.disponible }} </span>
          <span v-else style="color: #dd6161"
            ><strong>{{ scope.row.disponible }}</strong></span
          >
        </template>
      </el-table-column>
      <el-table-column v-if="permisoVerStock" prop="No_disponible" label="No disponible" width="100"> </el-table-column>
      <!-- <el-table-column
        v-if="permisoPrecio"
        prop="disponible"
        label="Precio"
        width="90">
        <template slot-scope="scope">
          <span v-if="scope.row.predesc">{{ scope.row.predesc }} </span>
          <span v-else style="color: #dd6161"><strong>No</strong></span>
        </template>
      </el-table-column> -->
    </el-table>

    <!-- ///------Dialog------/// -->
    <el-dialog :visible.sync="dialogVisible" width="80%" :top="'0vh'">
      <h2 style="text-align: center; margin-top: -36px; margin-bottom: 0px; font-size: 16px; margin-bottom: 10px">
        {{ titleDialog }}
      </h2>
      <div v-loading="fullscreenLoading">
        <el-row style="background-color: #c0c4cc">
          <h3 style="text-align: center !important; margin: 5px">Resumen de Stock</h3>
        </el-row>
        <el-row v-if="tablaResumen.length > 0">
          <el-table :data="tablaResumen" border fit style="width: 100%" v-loading="cargarTabla" :height="altRes" size="mini">
            <el-table-column property="deposito" label="Dep."></el-table-column>
            <el-table-column property="fchulting" label="Últ.Ingreso">
              <template slot-scope="scope">
                <span v-if="scope.row.fchulting">
                  {{ formatFecha(scope.row.fchulting.date) }}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column property="stock" label="Stock"></el-table-column>
            <el-table-column property="ventas" label="Ventas"></el-table-column>
            <el-table-column property="reserva" label="Reserva"></el-table-column>
            <el-table-column property="disponible" label="Disponible" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.disponible > 0" style="color: green">
                  {{ scope.row.disponible }}
                  <el-tooltip class="item" effect="dark" content="Agregar al carro" placement="right-start">
                    <!-- <el-button type="success" icon="el-icon-plus" circle size="mini" disabled></el-button>  -->
                  </el-tooltip>
                </span>
                <span v-else style="color: red">{{ scope.row.disponible }} </span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row v-else style="background-color: #f2f6fc; text-align: center">
          <p style="text-align: center">No hay datos de Resumen</p>
        </el-row>
        <br />
        <!-- fin Tabla -->

        <!-- Tabla Lotes -->
        <el-row style="background-color: #c0c4cc">
          <h3 style="text-align: center; margin: 5px">Lotes</h3>
        </el-row>
        <el-row v-if="tablaLotes.length > 0">
          <el-table :data="tablaLotes" border v-loading="cargarTabla" :height="altTabla" size="mini">
            <el-table-column property="lote" label="Lote">
              <template slot-scope="scope">
                <span v-if="scope.row.lote"> {{ scope.row.lote }} </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column property="lote2" label="Lote 2">
              <template slot-scope="scope">
                <span v-if="scope.row.lote2"> {{ scope.row.lote2 }} </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column property="estadotxt" label="Estado"></el-table-column>
            <el-table-column property="vencimiento" label="Vencimiento">
              <template slot-scope="scope">
                <span v-if="scope.row.vencimiento">
                  {{ formatFecha(scope.row.vencimiento.date) }}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column property="deposito" label="Deposito"></el-table-column>
            <el-table-column property="stock" label="Stock">
              <template slot-scope="scope">
                <span v-if="scope.row.stock > 0"> {{ scope.row.stock }} </span>
                <span v-else style="color: red">{{ scope.row.stock }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ventas" label="Ventas" width="70"></el-table-column>
            <el-table-column property="disponible" label="Disponible" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.disponible > 0" style="color: green">
                  {{ scope.row.disponible }}
                  <el-tooltip class="item" effect="dark" content="Agregar al carro" placement="right-start"> </el-tooltip>
                </span>
                <span v-else style="color: red">{{ scope.row.disponible }} </span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row v-else style="background-color: #f2f6fc; text-align: center">
          <p style="text-align: center">No hay datos de Lotes</p>
        </el-row>
        <br />
        <!-- fin Tabla -->
        <!-- Tabla Ubicaciones -->
        <el-row style="background-color: #c0c4cc">
          <h3 style="text-align: center; margin: 5px">Ubicaciones</h3>
        </el-row>
        <el-row v-if="tablaDetalle.length > 0">
          <el-table :data="tablaDetalle" border v-loading="cargarTabla" :height="altDet" size="mini">
            <el-table-column property="rack" label="Rack"></el-table-column>
            <el-table-column property="piso" label="Piso"></el-table-column>
            <el-table-column property="posicion" label="Posicion"></el-table-column>

            <el-table-column property="tipo" label="Tipo">
              <template slot-scope="scope">
                <span v-if="scope.row.tipo"> {{ config[scope.row.tipo] }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column property="deposito" label="Depósito"></el-table-column>
            <el-table-column property="etiqueta" label="Etiqueta"></el-table-column>
            <el-table-column property="stock" label="Stock"></el-table-column>
          </el-table>
        </el-row>
        <el-row v-else style="background-color: #f2f6fc; text-align: center">
          <p style="text-align: center">No hay datos de Ubicaciones</p>
        </el-row>
        <!-- fin Tabla -->

        <!-- Tabla UM -->
        <br />
        <el-row style="background-color: #c0c4cc">
          <h3 style="text-align: center; margin: 5px">Unidad Manipulación</h3>
        </el-row>
        <el-row v-if="tablaUM.length > 0">
          <el-table :data="tablaUM" border v-loading="cargarTabla" size="mini" :height="altUM">
            <el-table-column property="idum" label="UM"></el-table-column>
            <el-table-column property="sscc" label="SSCC"></el-table-column>
            <el-table-column property="deposito" label="Depósito"></el-table-column>
            <el-table-column property="etiqueta" label="Ubicación-Etiqueta"></el-table-column>
            <el-table-column property="stock" label="Stock"></el-table-column>
          </el-table>
        </el-row>
        <el-row v-else style="background-color: #f2f6fc; text-align: center">
          <p style="text-align: center">No hay datos de UM</p>
        </el-row>
        <!-- fin Tabla -->

        <!-- Tabla Información -->
        <br />
        <el-row style="background-color: #c0c4cc">
          <h3 style="text-align: center; margin: 5px"><i class="el-icon-info"></i> Información extendida</h3>
        </el-row>
        <el-row v-if="infoExtra.length > 0" style="overflow: auto; margin-bottom: -15px">
          <el-table :data="infoExtra" border v-loading="cargarTabla" size="mini" :height="altInfo">
            <el-table-column property="codbarra" label="Códigos de Barra">
              <template slot-scope="scope">
                <div><i class="el-icon-success"></i> {{ scope.row.codbarra }}</div>
              </template>
            </el-table-column>
            <el-table-column property="rubro" label="Rubro" width="70"> </el-table-column>
            <el-table-column property="subrubro" label="Subrubro" width="80"> </el-table-column>
            <el-table-column property="descrip_rubro" label="Descrip. Rubro"> </el-table-column>
            <el-table-column property="tipo" label="Tipo" width="70">
              <template slot-scope="scope">
                <div v-if="scope.row.tipo !== ''">
                  {{ scope.row.tipo }}
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column property="descrip_tipo" label="Descrip. Tipo">
              <template slot-scope="scope">
                <div v-if="scope.row.descrip_tipo !== '' && scope.row.descrip_tipo !== null">
                  {{ scope.row.tipo }}
                </div>
                <div v-else>SIN TIPO</div>
              </template>
            </el-table-column>
            <el-table-column property="estado_stocklote" label="Estado" width="90">
              <template slot-scope="scope">
                <div v-if="scope.row.estado_stocklote !== '' && scope.row.estado_stocklote !== null">
                  {{ scope.row.estado_stocklote }}
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row v-else style="background-color: #f2f6fc; text-align: center; margin-bottom: 0px">
          <p style="text-align: center">No hay datos de Información extendida</p>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <center>
          <el-button @click="dialogVisible = false" type="danger">Cerrar</el-button>
        </center>
      </span>
    </el-dialog>
    <!-- ///------ FIN Dialog------/// -->
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { HTTP } from '@/axios'
const moment = require('moment')

export default {
  data() {
    return {
      tableData: [],
      cargando: false,
      permisoVerStock: '',
      cantidad_elementos: 0,
      session: JSON.parse(window.localStorage.getItem('datos')),
      permisoPrecio: '',
      dialogVisible: false,
      titleDialog: '',
      visible: false,
      tablaResumen: [],
      tablaLotes: [],
      tablaDetalle: [],
      tablaUM: [],
      infoExtra: [],
      cargarTabla: false,
      altTabla: 0,
      altRes: 0,
      altDet: 0,
      altUM: 0,
      altInfo: 0,
      config: [],
      fullscreenLoading: false,
    }
  },
  mounted() {
    EventBus.$on('cargandoStock', () => {
      this.cargando = true
      this.cantidad_elementos = ''
    })
    /* ---- PERMISOS ---- */
    this.permisoVerStock = this.session.permisos.indexOf('STOCK') != -1 ? true : false
    this.permisoPrecio = this.session.permisos.indexOf('PRECIO') != -1 ? true : false
    /* --- FIN PERMISOS --- */
    setTimeout(() => {
      EventBus.$on('cargarTablaStockComun', (g) => {
        this.cargando = false
        this.tableData = g
        this.cantidad_elementos = this.tableData.length
        if (this.tableData.length == 0) {
          this.$message({
            message: 'No se encontraron artículos.',
            type: 'warning',
          })
        }
      })
    }, 700)

    setTimeout(() => {
      EventBus.$on('cargarTablaStockCOD', (g) => {
        this.tableData = g
        this.cantidad_elementos = this.tableData.length
        this.cargando = false

        if (this.tableData.length == 0) {
          this.tableData = []
          this.$message({
            message: 'No se encontraron artículos que concuerden con su búsqueda',
            type: 'warning',
          })
        }
      })
    }, 700)
    setTimeout(() => {
      EventBus.$on('limpiarStock', () => {
        this.tableData = []
        this.cargando = false
        this.cantidad_elementos = this.tableData.length
      })
    }, 700)
  },
  beforeDestroy() {
    EventBus.$off('cargarTablaStockComun', false)
    EventBus.$off('cargarTablaStockCOD', false)
    EventBus.$off('cargandoStock', false)
    EventBus.$off('limpiarStock', false)
  },
  methods: {
    detallesProd(row) {
      this.dialogVisible = true
      this.titleDialog = row.descrip
      this.visible = true
      this.fullscreenLoading = true

      this.cargarTabla = true
      // HTTP.post('/vlogisticanew/stock_detalleArticulo',{codigo:row.codigo,session:this.session})
      HTTP.post('/vlogisticaLogin/stock_detalleArticulo_25_08_2023', {
        codigo: row.codigo,
        session: this.session,
      }).then((res) => {
        this.tablaLotes = res.data.detalleL ? res.data.detalleL : []
        this.tablaResumen = res.data.stockA ? res.data.stockA : []
        this.tablaDetalle = res.data.detalle ? res.data.detalle : []
        this.tablaUM = res.data.detalleUM ? res.data.detalleUM : []
        this.infoExtra = res.data.cobarras ? res.data.cobarras : []
        this.cargarTabla = false

        setTimeout(() => {
          this.altTabla = this.tablaLotes.length > 4 ? '16vh' : ''
          this.altRes = this.tablaResumen.length > 4 ? '16vh' : ''
          this.altDet = this.tablaDetalle.length > 4 ? '16vh' : ''
          this.altUM = this.tablaUM.length > 4 ? '16vh' : ''
          this.altInfo = this.infoExtra.length > 4 ? '16vh' : ''
        }, 100)
        setTimeout(() => {
          this.fullscreenLoading = false
        }, 500)
        this.config = res.data.config ? res.data.config : []
      })
    },

    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
  },
}
</script>
<style scoped>
.title-stock {
  margin: 0px !important;
  font-size: 15px;
  background-color: #36404e;
  color: #ffff;
  font-size: 13px;
}
.el-button--mini.is-circle {
  padding: 1px;
}
</style>
