<template>
    <div>
    <div class="loading slide-in-bottom" v-if="!mostrar"></div>
    <div style="background: #FFF;padding-bottom: 10px;" v-if="mostrar">
          <center>
              <el-row style="border-bottom:1px dashed #ddd;padding-bottom:8px;width:80%;" :gutter="10">
                  <el-col :md="14">
                  <span style="float:left; font-size:20px;"><strong>Remitos App -RFO</strong></span>
                  </el-col>
                  <el-col  :md="10">
                  <span style="float:right; font-size:16px; color:#0b406a;"><strong>Total de fotos tomadas: {{fotosT}}</strong></span>
                  </el-col>
              </el-row>
          </center>
          <br>        
              <center>
                <el-row style="width:80%;margin-right: 0px !important;" :gutter="10">
                    <el-col :md="14">
                    <span style="float:left;"><strong>Buscar Fotos</strong></span>
                    </el-col>
                </el-row>
                <el-form size="mini" :label-position="'top'" class="form-el"  style="width:80%;">
                  <el-row :gutter="10">                    
                      <el-col :span="4" :sm="6" :xs="12" style="text-align: left;">
                          <label for="" class="vpim-label"><strong> Terminal: </strong></label>                       
                          <el-input placeholder="Terminal" v-model.number="formFotos.terminal" size="mini" 
                          @keypress.native.enter="getFotos()" clearable></el-input>
                      </el-col>
                      <el-col :span="4" :sm="6" :xs="12" style="text-align: left;">
                          <label for="" class="vpim-label"><strong>Número: </strong></label>                       
                          <el-input placeholder="Número" v-model.number="formFotos.numero" 
                          size="mini" @keypress.native.enter="getFotos()" clearable></el-input>
                      </el-col>
                      <el-col :span="6" :sm="12" :xs="24">                      
                        <el-button-group  style="float: left;margin-top:19px">
                          <el-button type="primary" :style="btnRemito" icon="el-icon-search" size="mini" @click="getFotos()">Buscar</el-button>
                          <el-button type="default"  size="mini" icon="el-icon-close" @click="limpiarFotos">Limpiar</el-button>
                        </el-button-group>
                      </el-col>
                  </el-row>
                </el-form> 
              </center> 
      </div>
      <tabla-remitos-f v-if="permiso_rfo"></tabla-remitos-f>
      
      <el-dialog title="Resultado de la búsqueda" :visible.sync="dialogTableVisible" width="70%" >
       <div class="demo-image">
         <el-row v-if="tablaFotos.length > 0">
           <el-col :md="colspan" :xs="24"  v-for="img in tablaFotos" :key="img.id" v-loading="loading">
             <center>
              <el-image
                style="width:90%; height:280px"
                :src="img.urlcompleta"
              fit="cover" :preview-src-list="[img.urlcompleta]">
              </el-image>
              <br>
              <el-popconfirm
                confirm-button-text='Si'
                cancel-button-text='No'
                icon="el-icon-info"
                icon-color="red"
                title="Está seguro de eliminar la foto?"
                @confirm="eliminarFoto(img)"
              >
              <el-button type="danger" slot="reference" icon="el-icon-delete" size="small" style="margin-bottom: 9px;" circle></el-button>
              </el-popconfirm>
             </center>
           </el-col>
         </el-row>
         <el-row v-else>
            <center>
              <h3>No se encontraron resultados</h3>
            </center>
         </el-row>
      
      </div>
      </el-dialog>
    </div>
</template>
<script>
import {HTTP} from '@/axios'
import TablaRemitosF from '@/components/remitos/TablaRemitosF.vue'
const moment = require('moment')

export default {
  components: {
      TablaRemitosF  
    },  
    data() {
        return {
          mostrar               :true,
          fotosT                :0,
          convenioSeleccionado  :'',
          labelPosition         :'top',
          options               :[],
          loadingAdd            :false,
          btnRemito             :{},
          empresa               :window.localStorage.getItem('empresa'),
          operador              :JSON.parse(window.localStorage.getItem("datos")).uid + '-'+JSON.parse(window.localStorage.getItem("datos")).user,
          tableData             :[],
          formFotos :{
            terminal  :'',
            numero    :'',
          },
          dialogTableVisible    :false,
          tablaFotos            :[],
          titleFotos            :'',
          colspan               :24,
          loading               :false,
          urlempresa            :'',
          permisos_usuario      :window.localStorage.getItem("permisos"),   
          permiso_rfo           :false,
          permiso_foto          :false       
        }
    },
    created() {
      
    },
    mounted() {
      this.permisos();
      this.detallerView();
      this.totalDeFotos();
    },
  
    methods: {
      permisos()
      {
        this.permiso_rfo   = this.permisos_usuario.indexOf("APP_RFO")!=-1 ? true : false;
        this.permiso_foto  = this.permisos_usuario.indexOf('APP_BORRAR_FOTOS') !=-1? true : false;
      },
      filtraLista(a) {

        let aux = this.options

        let filtro = aux.filter(f => {
          // console.log(a);
          // console.log(f);

          if ((f.olconv === a) || (f.descrip.toLowerCase().search(a.toLowerCase()) != -1)) {
            return f
          }
        })

        if (filtro.length) {
          this.options = filtro
        }

      },
      detallerView()
      {
        switch (this.empresa) {
          case 'lo':
            this.btnRemito['backgroundColor'] = '#0b406a';
            break;
        
          case 'u':
            this.btnRemito['backgroundColor'] = '#0b406a';
            break;
        }
      },
      getConvenios() {
        HTTP.post('/guiasNew/getConvenios',	{ol_lista:false,
          guia_convenios:''})
          .then(res => {
            const results = res.data.filter(({ olconv: id1 }) => 
            !this.tableData.some(({ olconv: id2 }) => id2 === id1));
            this.options    = results;
            this.loadingAdd = false;
          })
          .catch(error => console.log(error))   
          


      },
  //  Carga todos los convenios con RFO en la tabla - ok!
      // getConveniosActivos() {
      //   HTTP.get('/guiasNew/getConveniosActivos')
      //     .then(res => {
      //       this.loading = false;
      //       this.tableData = res.data;
      //       EventBus.$emit("cargarConveniosActivos", {
			// 					tabla:res.data});
      //     })
      //     .catch(error => console.log(error))
      // },   
      
      //  addConvenio() {
      //   this.loadingAdd = true;
      //   let new_conv = false;
      //   HTTP.post('/guiasNew/addConvenio', {
      //       olconv: this.convenioSeleccionado,
      //       operador: this.operador
      //     })
      //     .then(res => {
      //       this.convenioSeleccionado = '';
      //       this.loadingAdd = false;
      //       /* Comunico nuevo convenio */
      //       if(res.data['estado']==1)
      //       {
      //         new_conv = true;
      //         this.$message({
      //           message: 'Se agregó convenio correctamente',
      //           type: 'success'
      //         });
      //       }else
      //       {
      //        this.$message.error('No se pudo realizar la acción. Intente nuevamente.');
      //       }

      //       EventBus.$emit('nuevoConv',new_conv)
      //       this.getConvenios();
      //     })
      //     .catch(error => console.log(error));
      // },
       totalDeFotos() {
        HTTP.get('/guiasNew/totalDeFotos')
          .then(res => {
            this.fotosT = res.data[0].total;
          })
          .catch(error => console.log(error));
      },
       getFotos() 
       {
        this.loading = true
        // this.urlempresa = this.empresa == 'u'
        //     ? 'https://cdn.suizoargentina.com.ar' 
        //     : 'https://cdn.log-in.com.ar'
            /* CAMBIO DE SERVIDOR */
        this.urlempresa = this.empresa == 'u'
            ? 'https://img.suizoargentina.com.ar' 
            : 'https://img.log-in.com.ar'
        let buscar_terminal = true,buscar_numero = true
        
        buscar_terminal = (Number.isInteger(this.formFotos.terminal) && this.formFotos.terminal!='') 
        ? true
        : ((this.formFotos.terminal =='') ? true : false)
        buscar_numero = (Number.isInteger(this.formFotos.numero) && this.formFotos.numero!='') 
        ? true
        : ((this.formFotos.numero =='') ? true : false)
        console.log('integer',Number.isInteger(this.formFotos.terminal),buscar_terminal,buscar_numero)
        if(buscar_terminal && buscar_numero)
        {
          HTTP.post('/guiasNew/fotosRFO',this.formFotos)
            .then(res => {
              this.tablaFotos = res.data;
              this.tablaFotos.forEach(element => {
                let separar_url = element.url.split("/")
                if(separar_url[1]== 'appfiles')
                {
                  element['urlcompleta'] = this.urlempresa + element.url;
                }else
                {
                  element['urlcompleta']= this.urlempresa +'/appfiles'+ element.url;
                }
              })   
              
              this.dialogTableVisible = true;
              this.titleFotos         = 'Total de fotos: '+ res.data[0].TotalRows;
              switch (res.data.length) {
                case 4:
                  this.colspan  = 6
                  break;
                case 3:
                  this.colspan  = 8
                  break;
                case 2:
                  this.colspan=12
                  break;
                default:
                  this.colspan=24
                  break;
              }
              setTimeout(() => {
                this.loading = false
              }, 4000);
            
            })
            .catch(error => console.log(error));
        }else
        {
          this.$message.error('El formato de los datos es incorrecto.');
        }
      },
      limpiarFotos()
      {
          this.formFotos.terminal = ''
          this.formFotos.numero   = ''
      },
      formatFecha(fecha) {
            return moment(fecha).format('DD/MM/YYYY');
      },
      eliminarFoto(foto)
      {
        // console.log('datos',foto)
         HTTP.post('/guiasNew/eliminarFoto',{
           id:foto.id,
           terminal:foto.terminal,
           numero:foto.numero,
           operador: this.operador})
          .then(res => {
            // console.log(res.data)
            if(res.data)
            {
                this.$message({
                  showClose: true,
                  message: 'La foto se elimino correctamente',
                  type: 'success'
                });
                this.getFotos()
            }else
            {
              this.$message({
                showClose: true,
                message: 'No pudo llevarse acabo la acción. Intente nuevamente.',
                type: 'warning'
              });
            }              
          }).catch(error => console.log(error));
        // alert('eliminar')
      }
    },
}
</script>
<style scoped>
.img-bg{
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}
</style>