<template>
  <div>
    <principal></principal>
  </div>
</template>
<script>
import Principal from '@/components/home/Principal'
export default {
  components: {
    Principal,
  },
}
</script>
