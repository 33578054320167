<template>
  <el-dialog
    :visible.sync="modal.bultos"
    width="90%"
    @closed="limpiarForm()"
    :close-on-click-modal="false"
    style="padding-top: 0px !important"
    :destroy-on-close="true"
    :show-close="false"
    :close-on-press-escape="true"
    top="5vh">
    <el-row style="margin-top: -37px">
      <h2 style="text-align: center; padding: 0px">Carga de Bultos</h2>
      <hr />
    </el-row>
    <el-form label-position="right" label-width="130px">
      <el-row :gutter="10" v-if="this.config.bultovacio === 'S'">
        <el-col :md="5">
          <el-input
            name="cantidadBultos"
            @keypress.native.enter="addBulto()"
            v-model="cantidadBultos"
            controls-position="right"
            :maxlength="2"
            size="medium">
            <el-button
              slot="append"
              size="medium"
              icon="el-icon-plus"
              type="primary"
              plain
              @click="addBulto()">
              Agregar Bultos</el-button
            >
          </el-input>
          <el-input type="text" v-show="false" /><!-- Previene reload -->
          &nbsp;
        </el-col>
        <el-col :md="7" :offset="10">
          <el-button
            :loading="load2"
            size="medium"
            type="success"
            icon="el-icon-check"
            @click="validarBultos()"
            >Validar todo</el-button
          >
          <el-button
            :loading="load3"
            size="medium"
            type="danger"
            icon="el-icon-delete"
            @click="vaciarBultos()"
            >Eliminar todo</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="10" v-else>
        <el-col :md="{ span: 4 }">
          <el-button
            type="primary"
            icon="el-icon-plus"
            round
            plain
            @click="addBulto()">
            Agregar Bulto</el-button
          >
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-col>
          <el-table
            fit
            size="mini"
            border
            :data="bultos"
            empty-text="Realice una busqueda para ver la información"
            style="width: 100%">
            <el-table-column
              label="#"
              type="index"
              width="50"></el-table-column>
            <el-table-column ref="refTB" label="Tipo Bulto" min-width="130">
              <editable-cell
                :show-input="row.editMode"
                slot-scope="{ row }"
                v-model="row.tipo_bulto"
                editable-component="el-select"
                close-event="change">
                <span slot="content">{{ row.tipo_bulto }}</span>
                <template slot="edit-component-slot">
                  <el-option
                    v-for="item in tiposBultos"
                    :key="item.nombre"
                    :label="item.nombre"
                    :value="item.nombre" />
                </template>
              </editable-cell>
            </el-table-column>
            <el-table-column label="Peso Declarado (kg)" min-width="130">
              <editable-cell
                :show-input="row.editMode"
                slot-scope="{ row }"
                v-model="row.peso_declarado"
                clearable
                editable-component="el-input-number"
                controls-position="right"
                :min="0"
                :precision="2">
                <span slot="content">{{ row.peso_declarado }}</span>
              </editable-cell>
            </el-table-column>
            <el-table-column label="Volumen Declarado (m³)" min-width="180">
              <editable-cell
                :show-input="row.editMode"
                slot-scope="{ row }"
                v-model="row.volumen_declarado"
                clearable
                editable-component="el-input-number"
                controls-position="right"
                :min="0"
                :precision="2">
                <span slot="content">{{ row.volumen_declarado }}</span>
              </editable-cell>
            </el-table-column>
            <el-table-column label="Valor Declarado" min-width="180">
              <editable-cell
                :show-input="row.editMode"
                slot-scope="{ row }"
                v-model="row.valor_declarado"
                clearable
                editable-component="el-input-number"
                controls-position="right"
                :min="0"
                :precision="2">
                <span slot="content">{{ row.valor_declarado }}</span>
              </editable-cell>
            </el-table-column>
            <el-table-column min-width="60" label="Frio">
              <editable-cell
                :show-input="row.editMode"
                slot-scope="{ row }"
                editable-component="el-select"
                close-event="change"
                v-model="row.frio">
                <el-tag
                  size="medium"
                  :type="row.frio === '0' ? 'primary' : 'danger'"
                  slot="content">
                  {{ row.frio === '0' ? 'NO' : 'SI' }}
                </el-tag>
                <template slot="edit-component-slot">
                  <el-option :value="'0'" label="NO"></el-option>
                  <el-option :value="'1'" label="SI"></el-option>
                </template>
              </editable-cell>
            </el-table-column>
            <el-table-column label="Acciones" min-width="140">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.editMode == false"
                  plain
                  size="mini"
                  type="primary"
                  icon="el-icon-edit"
                  @click="setEditMode(scope.row)" />
                <el-button
                  v-if="scope.row.editMode == true"
                  plain
                  size="mini"
                  type="success"
                  icon="el-icon-check"
                  @click="saveRow(scope.row)" />
                <el-button
                  plain
                  size="mini"
                  icon="el-icon-delete"
                  type="danger"
                  @click="deleteRow(scope.$index)" />
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-row type="flex" justify="end">
        <el-button
          type="success"
          size="medium"
          @click="agregarBultos()"
          icon="el-icon-check"
          >Guardar</el-button
        >
        <el-button
          type="danger"
          size="medium"
          @click="limpiarForm()"
          icon="el-icon-close"
          >Cerrar</el-button
        >
      </el-row>
    </span>
  </el-dialog>
</template>
<script>
import EditableCell from '@/components/cross/EditableCell'

export default {
  name: 'GuiasCreateBultosForm',
  components: { EditableCell },
  props: { modal: { required: true } },
  created() {
    this.config = this.modal.rowGuia.config
    this.bultos = this.modal.rowGuia.bultos
    this.tiposBultos = this.modal.tiposBultos
    this.bultos = this.bultos.map((row) => {
      return {
        ...row,
        editMode: false,
      }
    })
  },
  data() {
    return {
      config: null,
      bultos: [],
      tiposBultos: [],
      cantidadBultos: 1,
      load2: false,
      load3: false,
    }
  },
  methods: {
    limpiarForm() {
      this.$events.fire('close-modal')
    },
    addBulto() {
      //Control numerico
      if (isNaN(this.cantidadBultos) || this.cantidadBultos >= 51)
        return this.$message({
          showClose: true,
          message:
            'Solo se puede agregar valores numericos y de a 50 bultos a la vez.',
          type: 'warning',
        })

      for (let index = 0; index < this.cantidadBultos; index++) {
        let bulto = {}
        bulto['id'] = Date.now()
        bulto['tipo_bulto'] = 'OTROS ENVASES'
        bulto['peso_declarado'] = 0
        bulto['volumen_declarado'] = 0
        bulto['valor_declarado'] = 0
        bulto['frio'] = '0'
        //bulto["comprobante"] = null
        bulto['editMode'] = true
        this.bultos.push(bulto)
      }
      /*this.$message({
				showClose: true,
				message: 'Se agregaron ' + this.cantidadBultos + ' bultos.',
				type: 'info'
			})*/
      this.cantidadBultos = 1
    },
    setEditMode(row) {
      row.editMode = true
    },
    saveRow(row) {
      //Validacion por Config
      //bultovacio = S permite que carguen bultos en 0 pero el total debe ser mayor que cero.
      if (
        this.config.pesoxbulto === 'S' &&
        this.config.bultovacio != 'S' &&
        row.peso_declarado <= 0
      ) {
        return this.$message({
          showClose: true,
          message:
            'El peso declarado, debe ser mayor que cero en todos los bulto.',
          type: 'warning',
        })
      }
      if (this.config.valordec === 'S' && row.valor_declarado <= 0) {
        return this.$message({
          showClose: true,
          message:
            'El valor declarado, debe ser valor mayor que cero en cada bulto.',
          type: 'warning',
        })
      }
      row.editMode = false
    },
    deleteRow(index) {
      this.bultos.splice(index, 1)
    },
    agregarBultos() {
      if (this.bultos.length <= 0)
        return this.$events.fire('add-bultos', this.modal.rowGuia, this.bultos)
      //Validar que esten todos validados
      if (
        this.bultos.filter(function (e) {
          return e.editMode === true
        }).length > 0
      ) {
        return this.$message({
          showClose: true,
          message: 'Debe confirmar los datos de todos los bultos cargados.',
          type: 'warning',
        })
      }
      //Validar totales
      if (this.config.bultovacio === 'S') {
        var bultM = {}
        bultM['peso_declarado'] = 0
        this.bultos.forEach((bulto) => {
          bultM['peso_declarado'] =
            bultM['peso_declarado'] + bulto.peso_declarado
        })
        if (bultM.peso_declarado <= 0) {
          return this.$message({
            showClose: true,
            message: 'El total del peso x bultos debe ser mayor que cero.',
            type: 'warning',
          })
        }
      }
      this.$events.fire('add-bultos', this.modal.rowGuia, this.bultos)
    },
    vaciarBultos() {
      this.bultos = []
    },
    validarBultos() {
      this.load2 = true
      for (let bulto of this.bultos) {
        //Repito controles de saveRow()
        if (
          this.config.pesoxbulto === 'S' &&
          this.config.bultovacio != 'S' &&
          bulto.peso_declarado <= 0
        ) {
          this.load2 = false
          return this.$message({
            showClose: true,
            message:
              'El peso declarado, debe ser mayor que cero en todos los bulto.',
            type: 'warning',
          })
        }
        if (this.config.valordec === 'S' && bulto.valor_declarado <= 0) {
          this.load2 = false
          return this.$message({
            showClose: true,
            message:
              'El valor declarado, debe ser valor mayor que cero en cada bulto.',
            type: 'warning',
          })
        }
        bulto.editMode = false
      }
      this.load2 = false
    },
  },
}
</script>
