<template>
    <div>
        <hoja-ruta></hoja-ruta>
    </div>
</template>
<script>
import HojaRuta from '@/components/hojaruta/hojaRuta.vue'
export default {
    components:{
        HojaRuta
    }
}
</script>