<template>
  <div style="padding: 0px 20px">
    <el-row :gutter="10">
      <el-col :span="24" :xs="24">
        <br />
        <el-alert center :closable="false" effect="dark" style="background-color: #e5590a">
          <span slot="title">
            <h2 v-if="rowData.convenio != null">
              Turno #{{ rowData.id }}: {{ rowData.convenio.convenio }} -
              {{ rowData.convenio.descrip }}
            </h2>
            <h2 v-else>Turno #{{ rowData.id }}: {{ this.getImportadora.nombre }}</h2>
          </span>
          <h3 style="text-align: center">
            <i class="far fa-calendar"></i>
            {{ formatDate(rowData.fecha, 'DD-MM-YYYY') }} | <i class="far fa-clock"></i> {{ rowData.hora_inicial }}
          </h3>
          <h3 style="text-align: center" v-if="rowData.estado > 2 && rowData.estado <= 4">
            Recepción en NAVE {{ rowData.nave }} - Dock Nº
            {{ rowData.dock.numero }}
          </h3>
        </el-alert>
        <el-card style="background-color: #fcf5e9">
          <el-form size="mini" label-width="170px">
            <!-- Datos basicos -->
            <el-row>
              <el-col :md="{ span: 24 }">
                <el-form-item label="Proveedor de Convenio">
                  <el-input name="proveedor_convenio" type="text" readonly :value="rowData.proveedor_convenio" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }">
                <el-form-item label="¿Cliente sin turno?">
                  <el-input name="check_sin_turno" type="text" readonly :value="rowData.check_sin_turno === 1 ? 'SI' : 'NO'" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }">
                <el-form-item label="¿Necesita Frio?">
                  <el-input name="check_frio" type="text" readonly :value="rowData.check_frio === 1 ? 'SI' : 'NO'" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="{ span: 12 }">
                <el-form-item label="¿Proveedor de descarga?">
                  <el-input name="check_cliente" type="text" readonly :value="rowData.check_cliente === 1 ? 'SI' : 'NO'" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }" v-if="rowData.check_cliente === 1">
                <el-form-item label="Detalles:" prop="check_cliente_detalles">
                  <el-input
                    name="check_cliente_detalles"
                    type="textarea"
                    :rows="3"
                    :maxlength="100"
                    placeholder="Detalles del proveedor de descarga que ingresara al predio."
                    :value="rowData.check_cliente_detalles"
                    readonly>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="{ span: 12 }">
                <el-form-item label="Tipo Unidad">
                  <el-input name="tipo_unidad" type="text" readonly :value="rowData.tipo_unidad" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }">
                <el-form-item label="Tipo Descarga">
                  <el-input name="tipo_descarga" type="text" readonly :value="rowData.tipo_descarga" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }">
                <el-form-item label="Contacto">
                  <el-input name="contacto_nombre" type="text" readonly :value="rowData.contacto_nombre" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }">
                <el-form-item label="Celular">
                  <el-input name="contacto_telefono" type="text" readonly :value="rowData.contacto_telefono" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 24 }">
                <el-form-item label="E-mail">
                  <el-input name="contacto_mail" type="text" readonly :value="rowData.contacto_mail" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }">
                <el-form-item label="Pallets aproximados:">
                  <el-input name="aprox_pallets" type="text" readonly :value="rowData.estado === 0 ? 'Sin Registrar' : rowData.aprox_pallets" placeholder="Sin registrar" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }">
                <el-form-item label="Bultos/Granel aproximados:">
                  <el-input name="aprox_bultos" type="text" readonly :value="rowData.estado === 0 ? 'Sin Registrar' : rowData.aprox_bultos" placeholder="Sin registrar" />
                </el-form-item>
              </el-col>
            </el-row>
            <!-- Recuadro ESTADO -->
            <el-row style="box-shadow: 0 5px 10px rgba(9, 0, 0, 0.3)">
              <el-alert type="info" center :closable="false" v-if="rowData.estado <= 6">
                <el-col :md="{ span: 12 }">
                  <el-form-item label="Ingreso a Playa:">
                    <el-input
                      name="datetime_playa"
                      type="text"
                      readonly
                      :value="rowData.datetime_playa === null ? 'Sin registrar' : formatDate(rowData.datetime_playa, 'DD-MM-YYYY // HH:mm') + 'hs'" />
                  </el-form-item>
                </el-col>
                <el-col :md="{ span: 12 }">
                  <el-form-item label="Ingreso a Dock:">
                    <el-input
                      name="datetime_dock"
                      type="text"
                      readonly
                      :value="rowData.datetime_dock === null ? 'Sin registrar' : formatDate(rowData.datetime_dock, 'DD-MM-YYYY // HH:mm') + 'hs'" />
                  </el-form-item>
                </el-col>
                <el-col :md="{ span: 12 }">
                  <el-form-item label="Salida:">
                    <el-input
                      name="datetime_atendido"
                      type="text"
                      readonly
                      :value="rowData.datetime_atendido === null ? 'Sin registrar' : formatDate(rowData.datetime_atendido, 'DD-MM-YYYY // HH:mm') + 'hs'" />
                  </el-form-item>
                </el-col>
                <el-col :md="{ span: 12 }">
                  <el-form-item label="Mostrar QR:">
                    <el-button type="primary" size="mini" @click="$events.fire('open-qr', rowData)"><i class="fas fa-qrcode"> QR Turno</i></el-button>
                  </el-form-item>
                </el-col>
              </el-alert>
              <el-alert type="info" center :closable="false" v-if="rowData.estado == 99">
                <el-form-item label="Horario de cancelacion:" style="width: 100%">
                  <el-date-picker
                    name="datetime_cancelado"
                    type="datetime"
                    readonly
                    :value="rowData.datetime_cancelado"
                    value-format="dd-MM-yyyy hh:mm:ss"
                    format="HH:mm"
                    style="width: 100%"
                    placeholder="Sin registrar" />
                </el-form-item>
              </el-alert>
              <el-alert type="info" center :closable="false" v-if="rowData.estado < 5">
                <span slot="title">
                  <h3>
                    Proximo Estado
                    <el-tag :type="formatEstado(rowData.estado, 1).color">{{ formatEstado(rowData.estado, 1).texto }}</el-tag>
                  </h3>
                </span>
              </el-alert>
              <el-alert type="info" center :closable="false" v-if="rowData.estado == 99">
                <span slot="title">
                  <h3><el-tag type="danger">Turno Cancelado</el-tag></h3>
                </span>
              </el-alert>
            </el-row>
            <!-- Datos del Chofer -->
            <br />
            <el-row v-if="rowData.estado > 1 && rowData.estado <= 4">
              <el-col :md="{ span: 12 }">
                <el-form-item label="Chofer:">
                  <el-input name="chofer_nombre" :value="rowData.chofer_nombre" readonly />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }">
                <el-form-item label="Dni:">
                  <el-input name="chofer_dni" :value="rowData.chofer_dni" readonly />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }">
                <el-form-item label="Empresa:">
                  <el-input name="chofer_empresa" prefix-icon="el-icon-s-cooperation" :value="rowData.chofer_empresa" readonly />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 12 }">
                <el-form-item label="Patente:">
                  <el-input name="chofer_patente" prefix-icon="el-icon-truck" :value="rowData.chofer_patente" readonly />
                </el-form-item>
              </el-col>
            </el-row>
            <!-- Detalles -->
            <el-row v-if="rowData.estado > 3">
              <el-tabs type="border-card">
                <el-tab-pane>
                  <span slot="label"> <i class="el-icon-s-order"></i> Datos de finalización </span>
                  <el-form label-position="right" size="mini" label-width="170px">
                    <el-col :md="{ span: 7 }">
                      <el-form-item label="Estado del camion">
                        <el-input name="carga_camion4" type="text" readonly :value="rowData.carga_camion" prefix-icon="fas fa-truck" />
                      </el-form-item>
                      <el-form-item label="Estado de la carga">
                        <el-input name="carga_estado" type="text" readonly :value="rowData.carga_estado" prefix-icon="fas fa-box" />
                      </el-form-item>
                      <el-form-item label="Nro Precinto:">
                        <el-input name="carga_precinto4" type="text" readonly :value="rowData.carga_precinto" prefix-icon="fas fa-info" />
                      </el-form-item>
                    </el-col>
                    <el-col :md="{ span: 7 }">
                      <el-form-item label="Cantidad de Pallets:">
                        <el-input name="carga_pallets4" type="text" readonly :value="rowData.carga_pallets" prefix-icon="fas fa-pallet" />
                      </el-form-item>
                      <el-form-item label="Temperatura:">
                        <el-input name="carga_temperatura4" type="text" readonly :value="rowData.carga_temperatura" prefix-icon="fas fa-thermometer-quarter" />
                      </el-form-item>
                      <el-form-item label="Tarimas devueltas:">
                        <el-input name="carga_tarimas" type="text" readonly :value="rowData.carga_tarimas" prefix-icon="fas fa-undo" />
                      </el-form-item>
                      <el-form-item label="Cantidad a Granel:">
                        <el-input name="carga_granel" type="text" readonly :value="rowData.carga_granel" prefix-icon="fas fa-undo" />
                      </el-form-item>
                    </el-col>
                    <el-col :md="{ span: 10 }">
                      <el-form-item label="Observaciones:">
                        <el-input name="observaciones" type="textarea" :rows="4" :value="rowData.carga_granel"> </el-input>
                      </el-form-item>
                    </el-col>
                  </el-form>
                </el-tab-pane>
                <el-tab-pane>
                  <span slot="label"> <i class="el-icon-s-management"></i> Comprobantes </span>
                  <el-form label-position="right" label-width="130px">
                    <br />
                    <el-row>
                      <el-col>
                        <el-table border stripe :data="rowData.comprobantes" style="width: 100%">
                          <el-table-column label="#" type="index" width="50"></el-table-column>
                          <el-table-column label="Comprobantes" min-width="25" prop="valor"> </el-table-column>
                        </el-table>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-tab-pane>
              </el-tabs>
            </el-row>
            <el-row v-if="rowData.estado >= 2 && rowData.estado < 4 && isAdmin">
              <br />
              <el-button type="warning" size="small" @click="reiniciar(rowData.id)" icon="el-icon-refresh" style="float: right !important">Reiniciar turno</el-button>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { API } from '@/plugins/api'
import { supabase } from '@/plugins/supabase'
import { estados } from '@/components/turnos/_auxiliar'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'turnosDetailsRow',
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },
  methods: {
    formatEstado(value, tipo = 0) {
      let estadoProps
      if (tipo == 1) estadoProps = value + 1
      else estadoProps = value
      let objeto = estados.find((x) => x.estado == estadoProps)
      if (objeto === undefined) return null
      else return objeto
    },
    formatDate(value, fmt = 'D MMM YYYY') {
      return value == null ? 'Sin registro' : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt)
    },
    reiniciar(id) {
      this.$swal({
        title: '¿Desea reiniciar el Turno: #' + id + ' ?',
        text: 'El turno sera actualizado al estado TURNADO',
        icon: 'info',
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Reiniciar',
        cancelButtonText: 'Salir',
        preConfirm: async () => {
          const response = await API.post('turnero/turnos/reiniciar', { turno_id: id })
          if (!response.status === 200) return this.$swal(response.response.data.message, 'No se realizó la acción', 'error')
          console.log('update supa - reinicio')
          const turnito = response.data.data
          this.$message({ message: response.data.message, type: 'success' })
          await supabase
            .from(process.env.VUE_APP_SUPABASE_TABLE)
            .update([
              {
                estado: turnito.estado,
                chofer_nombre: null,
                chofer_dni: null,
                chofer_empresa: null,
                chofer_patente: null,
                datetime_playa: null,
                datetime_dock: null,
                datetime_atendido: null,
                dock: null,
              },
            ])
            .eq('turno_id', id)
            .select()

          this.$events.fire('refrescar-tabla')

          return this.$swal.fire(response.data.message, '', 'success')
        },
      }).then((result) => {
        if (!result.value) this.$swal('Accion Cancelada', 'No se realizo ninguna accion', 'info')
      })
    },
  },
  computed: {
    ...mapGetters(['getDatos']),
    ...mapGetters('Turnos', ['getImportadora']),
    isAdmin() {
      return this.getDatos.permisos.includes('TURNOS_ADMIN') ? true : false
    },
  },
}
</script>
