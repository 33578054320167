<template>
  <div v-loading="sistema.loading">
    <filter-agenda /><br />
    <el-row :gutter="10">
      <el-col :span="24" v-show="this.sistema.datos">
        <div class="table-responsive-sm">
          <vuetable
            ref="vuetable"
            api-url="/turnero/agendas"
            :http-fetch="myFetch"
            :fields="fields"
            pagination-path=""
            :css="css.table"
            :sort-order="sortOrder"
            :multi-sort="true"
            :append-params="moreParams"
            :per-page="20"
            detail-row-component="turnos-details-row"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:loading="showLoader"
            @vuetable:loaded="hideLoader">
            <template slot="convenio" slot-scope="props">
              <div v-if="props.rowData.convenio === null">
                <p>{{ getImportadora.nombre }}</p>
              </div>
              <div v-else>
                <p>{{ props.rowData.convenio.nombre }}</p>
              </div>
            </template>
            <template slot="proveedor_convenio" slot-scope="props">
              <div v-if="props.rowData.proveedor_convenio === null">
                <p>-</p>
              </div>
              <div v-else>
                <p>{{ props.rowData.proveedor_convenio }}</p>
              </div>
            </template>
            <template slot="check_cliente" slot-scope="props">
              <div v-if="props.rowData.check_cliente === 1">
                <span class="el-tag el-tag--success">SI</span>
              </div>
              <div v-else>
                <span class="el-tag el-tag--danger">NO</span>
              </div>
            </template>
            <template slot="check_frio" slot-scope="props">
              <div v-if="props.rowData.check_frio === 1">
                <span class="el-tag el-tag--success">SI</span>
              </div>
              <div v-else>
                <span class="el-tag el-tag--danger">NO</span>
              </div>
            </template>
          </vuetable>
        </div>
        <div class="el-pagination is-background" style="margin-top: 20px; float: right; padding-bottom: 1rem">
          <vuetable-pagination-info ref="paginationInfo" info-class="pagination-info" info-template="Mostrando de {from}-{to} registros de un total de {total}" />
          <vuetable-pagination ref="pagination" :css="css.pagination" @vuetable-pagination:change-page="onChangePage" />
        </div>
      </el-col>
      <el-col :span="24" v-show="!this.sistema.datos">
        <el-alert type="info" show-icon :closable="false" center>
          <span slot="title">
            <p>No se encontraron turnos en la busqueda solicitada.</p>
          </span>
        </el-alert>
      </el-col>
    </el-row>
    <form-agenda v-if="this.sistema.modal.visible" :modal="this.sistema.modal" />
  </div>
</template>
<script>
import { config } from '@/components/turnos/_auxiliar'
import { API } from '@/plugins/api'
import Actions from './Actions'
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import FilterAgenda from './FilterAgenda'
import FormAgenda from './FormAgenda'
import { fields, sortOrder } from './_AuxAgenda'
import { mapGetters } from 'vuex'
Vue.component('agenda-actions', Actions)

export default {
  name: 'AgendasTable',
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    FilterAgenda,
    FormAgenda,
  },

  data() {
    return {
      sistema: {
        loading: false,
        boton: 'btnCross',
        datos: false,
        modal: {
          visible: false,
          row: null,
          tipo: null,
        },
        modalQr: {
          visible: false,
          row: null,
        },
        modalEstado: {
          visible: false,
          row: null,
        },
      },
      fields,
      sortOrder,
      css: config,
      moreParams: {},
    }
  },
  methods: {
    async myFetch(apiUrl, httpOptions) {
      try {
        const datos = await API.get(apiUrl, httpOptions)

        if (datos.data.data.data.length > 0) this.sistema.datos = true
        else this.sistema.datos = false
        return datos.data
      } catch (error) {
        return console.log(error)
      }
    },
    showLoader() {
      this.sistema.loading = true
    },
    hideLoader() {
      this.sistema.loading = false
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    callCheck(value) {
      if (value == 0) return "<span class='el-tag el-tag--danger'>NO</span>"
      else return "<span class='el-tag el-tag--success'>SI</span>"
    },
  },
  events: {
    'filter-set'(filtros) {
      this.moreParams = filtros
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'filter-reset'() {
      this.moreParams = {
        estado_select: [0, 1, 2, 3],
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'refrescar-tabla'() {
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'open-form-agenda'(tipo, row) {
      if (tipo == 'ALTA') {
        this.sistema.modal.row = null
        this.sistema.modal.tipo = 'ALTA'
      }
      if (tipo == 'EDITAR') {
        this.sistema.modal.row = row
        this.sistema.modal.tipo = 'EDITAR'
      }
      this.sistema.modal.visible = true
    },
    'close-form'() {
      this.sistema.modal.row = null
      this.sistema.modalEstado.row = null
      this.sistema.modal.tipo = null
      this.sistema.modal.visible = false
      this.sistema.modalEstado.visible = false
    },
  },
  computed: {
    ...mapGetters('Turnos', ['getImportadora']),
  },
}
</script>
