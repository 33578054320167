<template>
  <div class="vstock_buscar">
    <el-row style="border-bottom: 1px solid #ddd; padding-bottom: 8px" :gutter="10">
      <el-col :span="24">
        <h4 style="font-size: 24px; margin: -3px">
          Consulta de stock: <span v-if="titulo != ''">" {{ titulo }} " </span><span v-else></span>
        </h4>
      </el-col>
    </el-row>
    <el-form label-position="top" :model="formBuscar" ref="formBuscar" class="demo-ruleForm">
      <el-row :gutter="10">
        <el-col :md="5" :sm="12" :xs="12">
          <el-form-item label="Empieza con">
            <el-input v-model="formBuscar.empieza_con" size="mini" clearable placeholder="Empieza con" ref="empieza_con" @keypress.native.enter="buscarArticulo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="4" :sm="12" :xs="12">
          <el-form-item label="Contiene">
            <el-input v-model="formBuscar.contiene" clearable placeholder="Contiene.." size="mini" @keypress.native.enter="buscarArticulo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="4" :sm="12" :xs="12">
          <el-form-item label="Código">
            <el-input v-model="formBuscar.codigo" clearable placeholder="Ingrese Código" size="mini" @keypress.native.enter="buscarArticulo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="4" :sm="12" :xs="12">
          <el-form-item label="Tipo de artículo">
            <el-select
              v-model="formBuscar.tipo"
              placeholder="Seleccione Tipo"
              style="width: 100%"
              clearable
              filterable
              remote
              :loading="cargandoInfo"
              :disabled="depositos.length === 0"
              reserve-keyword
              size="mini">
              <el-option value="" label="TODOS" key=""> </el-option>
              <el-option v-for="item in tiposArticulos" :key="item.tipo" :label="item.titulo" :value="item.tipo"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="3" :sm="6" :xs="12">
          <el-form-item label="Depósitos">
            <el-select
              v-model="formBuscar.deposito"
              placeholder="Depósito con stock"
              style="width: 100%"
              clearable
              filterable
              remote
              reserve-keyword
              :loading="cargandoInfo"
              :disabled="depositos.length === 0"
              size="mini">
              <el-option value="" label="TODOS" key=""> </el-option>
              <el-option v-for="item in depositos" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="4" :sm="6" :xs="24">
          <el-form-item label="Incluir artículos sin stock">
            <el-switch
              style="margin-top: -10px; margin-left: 20px"
              v-model="formBuscar.sw_stock"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Si"
              inactive-text="No"
              size="mini"
              @change="changeFormatos()">
            </el-switch
          ></el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24" :sm="24" :xs="24">
          <el-form-item style="float: right">
            <el-button-group>
              <el-button type="primary" @click="buscarArticulo()" size="mini">Buscar</el-button>
              <el-button @click="resetForm(1)" size="mini">Limpiar</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      formBuscar: {
        empieza_con: '',
        contiene: '',
        codigo: '',
        sw_stock: false,
        cliente: JSON.parse(window.localStorage.getItem('datos'))['cliente'],
        session: JSON.parse(window.localStorage.getItem('datos')),
        tipo_busqueda: 'sinlote',
        tipo: '',
        deposito: '',
      },
      titulo: '',
      labelPosition: 'left',
      tiposArticulos: [],
      convenio: JSON.parse(window.localStorage.getItem('datos'))['convenio'],
      depositos: [],
      cargandoInfo: false,
    }
  },
  mounted() {
    this.$refs.empieza_con.focus()
    this.getTiposArticulos()
    this.getDepositosArticulo()
  },
  methods: {
    buscarArticulo() {
      this.titulo = this.formBuscar.empieza_con + this.formBuscar.contiene + this.formBuscar.codigo
      EventBus.$emit('cargandoStock')
      // HTTP.post('/vlogisticanew/stock',this.formBuscar)
      // HTTP.post('/vlogisticaLogin/stock_prueba_22_05', this.formBuscar) //nueva funcion
      HTTP.post('/vlogisticaLogin/StockGralLogin', this.formBuscar) //nueva funcion
        // HTTP_DESARROLLO.post('/vlogisticaLogin/StockGralLogin', this.formBuscar) //nueva funcion
        .then((res) => {
          var $respuesta = []

          if (res.data != 0) {
            $respuesta = res.data.resultados
          }
          EventBus.$emit('cargarTablaStockComun', $respuesta)
        })
    },
    resetForm() {
      this.formBuscar.empieza_con = ''
      this.formBuscar.contiene = ''
      this.formBuscar.codigo = ''
      this.formBuscar.sw_stock = false
      this.formBuscar.tipo = ''
      this.formBuscar.deposito = ''
      this.titulo = ''
      setTimeout(() => {
        EventBus.$emit('limpiarStock')
      }, 500)
    },
    changeFormatos() {
      this.cargandoInfo = true
      this.getTiposArticulos()
      this.getDepositosArticulo()
    },
    getTiposArticulos() {
      HTTP.post('/vlogisticaLogin/getTiposArticulo', {
        convenio: this.convenio,
        switched: this.formBuscar.sw_stock,
      })
        .then((response) => {
          this.tiposArticulos = response.data.map(function (obj) {
            obj.titulo = obj.descrip === null ? ' SIN TIPO ' : obj.tipo + ' - ' + obj.descrip
            return obj
          })
          this.cargandoInfo = false
          // this.tiposArticulos = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getDepositosArticulo() {
      HTTP.post('/vlogisticaLogin/getDepositosArticulo', {
        convenio: this.convenio,
        switched: this.formBuscar.sw_stock,
      })
        .then((response) => {
          this.depositos = response.data
          this.cargandoInfo = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style scoped>
.el-form-item {
  margin-bottom: 10px !important;
}
</style>
