<template>
  <div>
    <el-row :gutter="20" v-loading="cargandoWeb">
      <el-col>
        <iframe
          id="myframe1"
          name="myframe1"
          style="
            background-color: #efefef;
            padding-right: 10px;
            padding-left: 10px;
          "
          class="responsive-iframe"
          :src="http_public"></iframe>
        <!-- :src="/public/fm/index.php"></iframe> -->
      </el-col>
    </el-row>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/crypto-js.min.js"></script>

<script>
import { HTTP } from '@/axios'
export default {
  data() {
    return {
      http_public: null,
      cargandoWeb: true,
      datos: JSON.parse(window.localStorage.getItem('datos')),
    }
  },
  created() {
    var intervalID = setInterval(this.myCallback(), 1000)
    console.log(intervalID)
  },
  mounted() {
    setTimeout(() => {
      this.cargandoWeb = false
      this.encriptar()
    }, 500)
  },
  methods: {
    encriptar() {
      HTTP.post('/loginguias/encriptarUser', {
        user: this.datos.user,
        alias: 'Fm',
      })
        .then((res) => {
          this.http_public =
            'https://web2.log-in.com.ar/public/fm/?a=' + res.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style>
.responsive-iframe {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  min-height: 82vh;
}
</style>
