<template>
  <div style="background: #fff">
    <el-dialog
      :visible.sync="dialogDetalles"
      width="85%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="false"
      :top="'0vh'"
      :append-to-body="true"
      :lock-scroll="true">
      <el-row :gutter="10">
        <el-col :md="12" :xs="24">
          <div class="menu-grid">
            <div style="margin-top: 15px">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <!-- <el-breadcrumb-item
                  v-if="viewName == 'resumenhojaruta'"
                  :to="{
                    name: 'resumenhojaruta',
                    params: {
                      terminal: $route.params.terminal,
                      numero: $route.params.numero,
                      hr: $route.params.hrsuc,
                      hj: $route.params.hojaruta,
                    },
                  }"
                  >Hoja de Ruta</el-breadcrumb-item
                > -->
                <el-breadcrumb-item v-if="viewName == 'resumenhojaruta'">Hoja de Ruta</el-breadcrumb-item>
                <!-- <el-breadcrumb-item v-else :to="{ name: 'guias' }">Guías</el-breadcrumb-item> -->
                <el-breadcrumb-item v-else>Guías</el-breadcrumb-item>
                <el-breadcrumb-item>Detalle de Guía:</el-breadcrumb-item><span :style="headerStyle">{{ guia.letra }}-{{ guia.terminal }}-{{ guia.numero }} </span>
              </el-breadcrumb>
            </div>
          </div>
        </el-col>
        <el-col :md="12" :xs="24">
          <div style="margin-top: 15px; float: right; font-size: 14px">
            <strong>Convenio:</strong>
            ({{ guia.olconv }}) {{ guia.nombreConvenio }}
          </div>
        </el-col>
      </el-row>
      <!-- Cuerpo detalle -->
      <div class="guiadetalles" style="margin-top: 15px" v-loading="loading">
        <el-row>
          <el-col :md="24">
            <h3 :style="styleVguiaTitle" class="bloque-titulo">
              <strong>ESTADO:</strong> {{ guia.estadoc }}
              <div v-if="guia.contrareem > 0" class="bloque-titulo" style="float: right; margin-top: -8px">
                <img height="18" src="@/assets/reembolso_1.png" alt="Gestión de Cobranza" />
                <span> Gestión cobranza: {{ formatoMoneda(guia.contrareem) }}</span>
              </div>
            </h3>
            <table class="table table-bordered tabla-detalle">
              <tbody>
                <tr>
                  <th scope="col">Origen</th>
                  <th scope="col" v-if="guia.sucinter && guia.sucinter != guia.sucdest && guia.sucinter != guia.sucorig">Intermedio</th>
                  <th scope="col">Destino</th>
                </tr>
                <tr>
                  <td>{{ guia.origen_codigo }} - {{ guia.origen_nombre }}</td>
                  <td v-if="guia.sucinter && guia.sucinter != guia.sucdest && guia.sucinter != guia.sucorig">{{ guia.intermedia_codigo }} - {{ guia.intermedia_nombre }}</td>
                  <td>{{ guia.destino_codigo }} - {{ guia.destino_nombre }}</td>
                </tr>
              </tbody>
            </table>
          </el-col>
        </el-row>
        <!-- ------------Tabla de Destino---------------- -->
        <el-row :gutter="10">
          <el-col :md="24">
            <h3 :style="styleVguiaTitle" class="bloque-titulo">
              <strong>DESTINO</strong>
            </h3>
            <table class="table table-bordered tabla-detalle">
              <tbody>
                <tr>
                  <td colspan="2">
                    <strong :style="styleLetra">NOMBRE / RAZÓN SOCIAL</strong>
                    <br /><span :style="datosStyle" v-if="guia.razsoc">{{ guia.razsoc }}</span>
                    <span :style="datosStyle" v-else>-</span>
                  </td>
                  <td colspan="2">
                    <strong :style="styleLetra">OBSERVACIÓN</strong> <br /><span :style="datosStyle" v-if="guia.obsent">{{ guia.obsent }}</span>
                    <span :style="datosStyle" v-else>-</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong :style="styleLetra">DIRECCIÓN</strong>
                    <br />
                    <span :style="datosStyle" class="cdir" v-if="guia.direc">{{ guia.direc }} </span>
                    <span :style="datosStyle" v-else>-</span>
                  </td>
                  <td>
                    <strong :style="styleLetra">LOCALIDAD</strong>
                    <br />
                    <span :style="datosStyle" class="cdir" v-if="guia.localid">{{ guia.localid }} </span>
                    <span :style="datosStyle" v-else>-</span>
                  </td>
                  <td>
                    <strong :style="styleLetra">PROVINCIA</strong>
                    <br />
                    <span :style="datosStyle" class="cdir" v-if="guia.provin">{{ guia.provincia }} </span>
                    <span :style="datosStyle" v-else>-</span>
                  </td>
                  <td>
                    <strong :style="styleLetra">CÓDIGO POSTAL</strong>
                    <br />
                    <span :style="datosStyle" class="cdir" v-if="guia.codpos">{{ guia.codpos }} </span>
                    <span :style="datosStyle" v-else>-</span>
                  </td>
                </tr>
                <!-- Caso de ESTADO = A -->
                <tr v-if="guia.estado == 'A'">
                  <td colspan="4" style="background: #f56c6c !important; text-align: center">
                    <span :style="anuladaStyle" style="color: white !important; font-size: x-large" v-if="guia.estado == 'A'"> GUÍA ANULADA {{ formatFecha(guia.anulafec.date) }} </span>
                  </td>
                </tr>
                <!-- Caso de ESTADO = E -->

                <tr v-if="guia.estado == 'E'">
                  <td>
                    <strong :style="styleLetra">ENTREGADO A</strong>
                    <br />
                    <span :style="datosStyle" class="cdir" v-if="guia.receptor">
                      {{ guia.receptor.nombre }}
                    </span>
                    <span :style="datosStyle" v-else>-</span>
                  </td>
                  <td>
                    <strong :style="styleLetra">DNI</strong>
                    <br />
                    <span :style="datosStyle" class="cdir" v-if="guia.receptor">{{ guia.receptor.dni }} </span>
                    <span :style="datosStyle" v-else>-</span>
                  </td>
                  <td>
                    <strong :style="styleLetra">FECHA</strong>
                    <br />
                    <span :style="datosStyle" class="cdir" v-if="guia.fchentrega"> {{ formatFechaHora(guia.fchentrega.date) }}</span>
                    <span :style="datosStyle" v-else>-</span>
                  </td>
                  <td>
                    <strong :style="styleLetra">PRESENTACIÓN</strong>
                    <br />
                    <span :style="datosStyle" class="cdir" v-if="guia.nropres">{{ guia.nropres }} </span>
                    <span :style="datosStyle" v-else>{{ getPresentacionC() }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-col>
        </el-row>
        <!-- ------------ FIN Tabla de Destino---------------- -->
        <!-- ------------  Comprobantes y Bultos---------------- -- -->
        <el-row :gutter="10">
          <el-col :md="12" :sm="24" :xs="24">
            <h3 :style="styleVguiaTitle" class="bloque-titulo">
              <span :style="styleVguiaSpan">{{ cantidadCompr }}</span>
              <strong>COMPROBANTES:</strong>
            </h3>
            <div v-if="cantidadCompr > 0">
              <div v-if="cantidadCompr < 9">
                <table class="table table-bordered tabla-detalle">
                  <tbody>
                    <tr>
                      <th scope="col">Comprobante</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Fecha Present.</th>
                      <th scope="col">Nro. Present.</th>
                      <th scope="col">PDF</th>
                    </tr>
                    <tr v-for="compro in comprob_guia" :key="compro.id">
                      <td>{{ compro.remito }}</td>
                      <td v-if="compro.fchcomp != null">
                        {{ formatFecha(compro.fchcomp.date) }}
                      </td>
                      <td v-if="compro.presentaciones.length > 0">
                        <div v-if="compro.presentaciones[0].recibido != null">
                          {{ compro.presentaciones[0].recibido.date | moment('DD-MM-YYYY') }}
                        </div>
                        <div v-else>-</div>
                      </td>
                      <td v-else>-</td>
                      <td v-if="compro.presentaciones.length > 0">
                        <div v-if="compro.presentaciones[0] != null">
                          {{ compro.presentaciones[0].nropres }}
                        </div>
                        <div v-else>-</div>
                      </td>
                      <td v-else>-</td>
                      <td v-if="compro.ruta && compro.borrado == 0">
                        <!-- {{compro.ruta}} -->
                        <el-button
                          :type="'danger'"
                          style="padding: 4px; font-size: 12px"
                          :icon="'el-icon-download'"
                          size="mini"
                          :loading="descargando == compro.id"
                          plain
                          @click="openPdf(compro)"
                          >Descargar <span v-if="compro.hojas">({{ compro.hojas }})</span></el-button
                        >
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="scroll_tabla">
                <table class="table table-bordered tabla-detalle">
                  <tbody>
                    <tr>
                      <th scope="col">Comprobante</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Fecha Present.</th>
                      <th scope="col">Nro. Present.</th>
                      <th scope="col">PDF</th>
                    </tr>
                    <tr v-for="compro in comprob_guia" :key="compro.id">
                      <td>{{ compro.remito }}</td>
                      <td v-if="compro.fchcomp != null">
                        {{ formatFecha(compro.fchcomp.date) }}
                      </td>
                      <td v-if="compro.presentaciones.length > 0">
                        <div v-if="compro.presentaciones[0].recibido != null">
                          {{ compro.presentaciones[0].recibido.date | moment('DD-MM-YYYY') }}
                        </div>
                        <div v-else>-</div>
                      </td>
                      <td v-else>-</td>
                      <td v-if="compro.presentaciones.length > 0">
                        <div v-if="compro.presentaciones[0] != null">
                          {{ compro.presentaciones[0].nropres }}
                        </div>
                        <div v-else>-</div>
                      </td>
                      <td v-else>-</td>
                      <td v-if="compro.ruta">
                        <el-button
                          :type="'danger'"
                          style="padding: 4px; font-size: 12px"
                          :icon="'el-icon-download'"
                          size="mini"
                          :loading="descargando == compro.id"
                          plain
                          @click="openPdf(compro)"
                          >Descargar <span v-if="compro.hojas">({{ compro.hojas }})</span></el-button
                        >
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <h3 :style="styleVguiaVacio">No se encontraron comprobantes</h3>
            </div>
          </el-col>
          <el-col :md="12" :sm="24" :xs="24">
            <h3 :style="styleVguiaTitle" class="bloque-titulo">
              <span :style="styleVguiaSpan">{{ cantidadBultos }}</span>
              <strong>BULTO/S:</strong>
            </h3>
            <div v-if="cantidadBultos > 0">
              <div v-if="cantidadBultos < 8">
                <table class="table table-bordered tabla-detalle">
                  <tbody>
                    <tr>
                      <th scope="col">Cant.</th>
                      <th scope="col">Descripción</th>
                      <th scope="col">Peso</th>
                      <th scope="col">Volumen</th>
                      <th scope="col">Valor declarado</th>
                    </tr>

                    <tr v-for="bulto in bultos" :key="bulto.idbulto">
                      <td>{{ bulto.cantidad }}</td>
                      <td>{{ bulto.descrip }}</td>
                      <td v-if="bulto.peso > 0">
                        {{ bulto.peso | number('0.00') }}
                      </td>
                      <td v-else>{{ bulto.peso | number('0.00') }}</td>
                      <td v-if="bulto.volumen > 0">
                        {{ bulto.volumen | number('0.00') }}
                      </td>
                      <td v-else>{{ bulto.volumen | number('0.00') }}</td>
                      <td v-if="bulto.valordec > 0">
                        {{ bulto.valordec | number('0,0.00') }}
                      </td>
                      <td v-else>{{ bulto.valordec | number('0.00') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="scroll_tabla">
                <table class="table table-bordered tabla-detalle">
                  <tbody>
                    <tr>
                      <th scope="col">Cant.</th>
                      <th scope="col">Descripción</th>
                      <th scope="col">Peso</th>
                      <th scope="col">Volumen</th>
                      <th scope="col">Valor declarado</th>
                    </tr>

                    <tr v-for="bulto in bultos" :key="bulto.idbulto">
                      <td>{{ bulto.cantidad }}</td>
                      <td>{{ bulto.descrip }}</td>
                      <td v-if="bulto.peso > 0">
                        {{ bulto.peso | number('0.00') }}
                      </td>
                      <td v-else>{{ bulto.peso | number('0.00') }}</td>
                      <td v-if="bulto.volumen > 0">
                        {{ bulto.volumen | number('0.00') }}
                      </td>
                      <td v-else>{{ bulto.volumen | number('0.00') }}</td>
                      <td v-if="bulto.valordec > 0">
                        {{ bulto.valordec | number('0,0.00') }}
                      </td>
                      <td v-else>{{ bulto.valordec | number('0.00') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <h3 :style="styleVguiaVacio">No se encontraron bultos</h3>
            </div>
          </el-col>
        </el-row>
        <!-- ------------ fin Comprobantes y Bultos---------------- -- -->
        <!-- <br> -->

        <!-- ------------  Tabla Seguimiento---------------- -- -->
        <el-row v-if="guia.seguimiento && guia.seguimiento.length">
          <el-col :md="24" :sm="24" :xs="24">
            <h3 :style="styleVguiaTitle" class="bloque-titulo">
              <strong>Seguimiento de guía</strong>

              <el-button style="padding: 6.5px; float: right; margin-top: -5px; border-radius: 0px; margin-right: -10px" size="mini" :style="styleBtnRuta" @click="dialogRuta = true">
                Ver ruta de la guía
              </el-button>
            </h3>
            <div>
              <table class="table table-bordered tabla-detalle">
                <tbody>
                  <tr>
                    <th style="width: 110px">Fecha</th>
                    <th scope="col">Acción</th>
                    <th scope="col">Sucursal</th>
                    <th scope="col">Operador</th>
                  </tr>
                  <tr v-for="seg in guia.seguimiento" :key="seg.id">
                    <td v-if="seg.fecha != null">
                      {{ seg.fecha.date | moment('DD-MM-YYYY HH:mm:ss') }}
                    </td>
                    <td>
                      <span v-if="seg.entrega != null">
                        {{ seg.accion.split('|')[0] }}
                        <strong v-if="seg.entrega.estado_id != 100" style="color: red">
                          <div v-if="seg.entrega.estado != ''">
                            - {{ seg.entrega.estado.toUpperCase() }}
                            <span v-if="seg.entrega != null && seg.entrega.estado.toUpperCase() != getSubEstado(seg.entrega)"> : {{ getSubEstado(seg.entrega) }}</span>
                          </div>
                          <div v-else>
                            -
                            <span v-if="seg.entrega != null">{{ getSubEstado(seg.entrega) }}</span>
                            <span v-else> - </span>
                          </div>
                        </strong>

                        <el-tooltip class="item" effect="dark" content="Ver ubicación" placement="bottom">
                          <el-button
                            v-if="getLatLong(seg.accion)"
                            plain
                            size="mini"
                            style="padding: 7px 7px !important; margin-left: 5px !important; font-size: 10px !important"
                            @click="mapaShow(seg.accion)">
                            <i class="el-icon-location" :style="styleIconMapa"></i><strong>VER MAPA</strong>
                          </el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Ver foto" placement="right">
                          <i
                            v-if="seg.fotos && seg.fotos.length"
                            @click="showGaleria(seg.fotos)"
                            :style="styleLetra"
                            style="margin-left: 5px !important; font-size: 27px !important; display: inline-block; vertical-align: middle"
                            class="el-icon-camera-solid"></i>
                        </el-tooltip>
                      </span>
                      <span v-else>
                        <span v-if="seg.estado_app == 100">
                          {{ seg.accion.split('|')[0] }}
                        </span>

                        <span v-else>
                          {{ seg.accion.toUpperCase().replace('ENTREGADA', 'RENDIDA') }}
                        </span>
                        <!-- Fotos y Mapa -->
                        <span v-if="seg.fotos">
                          <el-tooltip class="item" effect="dark" content="Ver foto" placement="right">
                            <i
                              v-if="seg.fotos && seg.fotos.url.length"
                              @click="showGaleria(seg.fotos)"
                              :style="styleLetra"
                              style="margin-left: 5px !important; font-size: 27px !important; display: inline-block; vertical-align: middle"
                              class="el-icon-camera-solid"></i>
                          </el-tooltip>
                        </span>
                        <span v-if="getLatLong(seg.accion)">
                          <el-tooltip class="item" effect="dark" content="Ver ubicación" placement="bottom">
                            <el-button
                              v-if="getLatLong(seg.accion)"
                              plain
                              size="mini"
                              style="padding: 7px 7px !important; margin-left: 5px !important; font-size: 10px !important"
                              @click="mapaShow(seg.accion)">
                              <i class="el-icon-location" :style="styleIconMapa"></i><strong>VER MAPA</strong>
                            </el-button>
                          </el-tooltip>
                        </span>
                      </span>
                    </td>

                    <td v-if="seg.sucursal">{{ seg.sucursal }}</td>
                    <td v-else>-</td>
                    <td v-if="seg.operador">{{ seg.operador }}</td>
                    <td v-else>
                      {{ seg.accion.split('|')[1].toUpperCase() || '-' }}
                    </td>
                  </tr>
                  <tr v-for="(d, index) in guia.guiadev" :key="index">
                    <td>
                      {{ d.fecha.date | moment('DD-MM-YYYY HH:mm:ss') }}
                    </td>
                    <td>
                      <span v-if="getNroPres(d.accion) && d.nropres">
                        {{ d.accion }} - NRO. PRESENTACIÓN:
                        <strong>{{ d.nropres }}</strong>
                        <el-popover placement="right" trigger="hover">
                          <p>
                            <i class="el-icon-date" type="primary"></i>
                            Recibido:
                            {{ d.recibido.date | moment('DD-MM-YYYY HH:mm:ss') }}
                          </p>
                          <p v-if="d.sucorigen"><i class="el-icon-location" type="primary"></i> Suc. Origen: {{ filterSuc(d.sucorigen) }}</p>
                          <p v-else>Suc. Origen: -</p>
                          <p v-if="d.sucdestino"><i class="el-icon-location" type="primary"></i> Suc. Destino: {{ filterSuc(d.sucdestino) }}</p>
                          <p v-else>Suc. Destino: -</p>
                          <el-button slot="reference" size="mini" circle style="padding: 0px; margin-left: 6px"
                            ><i type="primary" class="el-icon-info" style="font-size: 20px"></i
                          ></el-button>
                        </el-popover>
                      </span>
                      <span v-else-if="d.accion != null">{{ d.accion }}</span>
                      <span v-else>-</span>
                    </td>
                    <td>
                      {{ d.sucursal }}
                    </td>
                    <td>
                      {{ d.operador }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-col>
        </el-row>
        <!-- ------------ FIN Tabla Seguimiento---------------- -- -->
        <br />
        <el-row :gutter="20">
          <el-col :md="24">
            <center>
              <!-- <el-button
                  type="info"
                  v-if="viewName == 'resumenhojaruta'"
                  @click="
                    $router.push({
                      name: 'resumenhojaruta',
                      params: {
                        terminal: $route.params.terminal,
                        numero: $route.params.numero,
                        hr: $route.params.hrsuc,
                        hj: $route.params.hojaruta,
                      },
                    })
                  "
                  ><i class="el-icon-back"></i> Volver HR
                </el-button> -->
              <el-button type="info" @click="dialogDetalles = false"><i class="el-icon-close"></i> Cerrar </el-button>
            </center>
          </el-col>
        </el-row>
        <br />
      </div>
      <!--  -->
      <el-dialog :modal="false" title="DOMICILIO DE GUÍA" :top="'0vh'" :visible.sync="dialogVisible" width="50%">
        <div style="margin-top: -40px">
          <p>
            <span :style="datosStyle" class="cdir" v-if="guia.direc">{{ guia.direc }}</span>
            <span :style="datosStyle" class="cdir" v-if="guia.localid"> - {{ guia.localid }} </span>
            <span :style="datosStyle" class="cdir" v-if="guia.provin"> - {{ guia.provincia }} </span>
            <span :style="datosStyle" class="cdir" v-if="guia.codpos"> - CP:{{ guia.codpos }} </span>
            <!-- <i class="el-icon-location"></i> Entregado: {{ fecha_entrega }} -->
          </p>
        </div>
        <el-row>
          <MglMap :accessToken="accessToken" :mapStyle="mapStyle" :center="center" :zoom="zoom">
            <MglNavigationControl position="top-right" />
            <MglGeojsonLayer :sourceId="geoJsonSource.data.id" :source="geoJsonSource" :layerId="geoJsonLayer.id" :layer="geoJsonLayer" />
            <MglFullscreenControl position="top-right" />
            <MglPopup :coordinates="center" anchor="top">
              <el-dialog title="Tips" :visible.sync="dialogVisibleMap" width="100%">
                <span>This is a message</span>
              </el-dialog>
            </MglPopup>
          </MglMap>
        </el-row>
      </el-dialog>
      <!-- Tabla de rutas -->
      <el-dialog :modal="false" title="RUTA DE LA GUÍA" :visible.sync="dialogRuta" width="60%">
        <el-table :data="tablaRutas" style="width: 100%" size="medium">
          <el-table-column prop="abrevia" label="Sucursal"> </el-table-column>
          <el-table-column prop="rep" label="Reparto"> </el-table-column>
          <el-table-column prop="sub" label="Sub"> </el-table-column>
          <el-table-column prop="fchrep" label="Fecha Reparto">
            <template slot-scope="scope">
              <span v-if="scope.row.fchrep">
                {{ formatFecha(scope.row.fchrep.date) }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="hojaruta" label="Hoja de ruta"> </el-table-column>
          <el-table-column prop="fchhruta" label="Fecha">
            <template slot-scope="scope">
              <span v-if="scope.row.fchhruta">
                {{ formatFecha(scope.row.fchhruta.date) }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog :modal="false" :title="'Fotos asociadas a la Hoja de ruta N° ' + hojarutal" :visible.sync="dialogGaleria" style="margin-top: -10vh !important">
        <div class="block">
          <!-- {{srcList}} -->
          <!-- {{urlImg}} -->
          <div class="demo-image__preview" v-loading="loading_fotos">
            <center>
              <el-carousel :autoplay="false" arrow="always" height="70vh" :indicator-position="'none'" :top="'-20vh'" :initial-index="0">
                <el-carousel-item v-for="item in srcList" :key="item" class="comprob">
                  <!-- @click.native="openFoto(item)" -->
                  <el-image fit="cover" :src="item" :preview-src-list="srcList" style="width: 100% !important"> </el-image>
                </el-carousel-item>
              </el-carousel>
              <!-- <el-carousel indicator-position="outside">
              <el-carousel-item v-for="item in srcList" :key="item">
                <el-image
                  fit="cover"
                  :src="item"
                  :preview-src-list="srcList"
                  style="width: 100% !important"
                >
                </el-image>
              </el-carousel-item>
            </el-carousel> -->
              <!-- <el-image
              fit="cover"
              :src="srcList[0]"
              :preview-src-list="srcList"
              style="width: 50% !important"
            >
            </el-image> -->
            </center>
          </div>
        </div>
      </el-dialog>
      <el-dialog :modal="false" :visible.sync="modalDetalle" width="500px" top="0vh" class="soporteModal">
        <el-carousel :autoplay="false" arrow="always" height="80vh" :indicator-position="'none'">
          <el-carousel-item v-for="c in numPages" :key="c.id" class="comprob" @click.native="openFoto(c.ruta)">
            <pdf :src="c.ruta"></pdf>
          </el-carousel-item>
        </el-carousel>
        <div v-for="i in numPages" :key="i.id + 'B'">
          <el-col :lg="{ span: 8, offset: 1 }" :md="{ span: 8, offset: 1 }" :sm="{ span: 8, offset: 1 }" :xs="{ span: 12, offset: 5 }">
            <el-button size="mini" type="danger" style="margin-bottom: 5px" @click="openFoto(i.ruta)">DESCARGAR HOJA {{ i.id }} </el-button>
          </el-col>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" type="primary" @click="modalDetalle = false">Cerrar</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
const moment = require('moment')
import Vue2Filters from 'vue2-filters'
import Mapbox from 'mapbox-gl'
import pdf from 'vue-pdf'
import accounting from 'accounting'
import { EventBus } from '@/event-bus'

import { MglMap, MglGeojsonLayer, MglPopup, MglNavigationControl, MglFullscreenControl } from 'vue-mapbox'
export default {
  mixins: [Vue2Filters.mixin],
  components: {
    MglMap,
    MglGeojsonLayer,
    MglPopup,
    MglNavigationControl,
    MglFullscreenControl,
    pdf,
  },
  data() {
    return {
      descargando: false,
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      codigo: '',
      guia: {},
      empresa: window.localStorage.getItem('empresa'),
      headerStyle: {
        fontWeight: 'bold',
      },
      datosStyle: {
        fontWeight: 'bold',
      },
      anuladaStyle: {
        fontWeight: 'bold',
        color: 'white',
      },
      imgCamara: {
        fontSize: '35px',
      },
      datosEstado: [
        {
          sucorig: '',
          sucdest: '',
        },
      ],
      controlar: false,
      sucdest: '-',
      sucorig: '-',
      sucinter: '-',
      styleVguiaTitle: {},
      styleVguiaSpan: {
        padding: '4px',
        borderRadius: '2px',
        marginRight: '5px',
        fontWeight: 'bold',
        fontSize: '12px',
      },
      styleVguiaVacio: {
        paddingBottom: '0px',
        fontSize: '13px',
        fontWeight: '700',
        margin: '0px 0',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        padding: '8px',
      },
      cantidadCompr: 0,
      cantidadBultos: 0,
      cantidadComprTabla: 0,
      cantidadBultosTabla: 0,
      bultos: [],
      showSeguimiento: 0,
      seguimientos: [],
      styleIconMapa: {
        color: '#006dcc',
        fontSize: '15px',
      },
      dialogVisible: false,
      loading: true,
      accessToken: 'pk.eyJ1IjoibWFwc2d1aWFzIiwiYSI6ImNqenU1OGZhdjBia2szbGxtNG91eDBwcXYifQ.3U4PVAVy0FRsm5O24EBIPA',
      style: 'streets-v9',
      estado: '',
      operador: '',
      showMapa: false,
      dialogRuta: false,
      tablaRutas: [],
      centerDialogVisible: false,
      /* Datos para seguimiento */
      estadoSeg: '',
      sucursal: '',
      fotoSeg: '',
      tablaSeguimiento: [],
      imagenes: [],
      dialogGaleria: false,
      loadMap: false,
      urlImg: '',
      srcList: [],
      urlPdf: '',
      fecha_entrega: '',
      center: '',
      zoom: 14,
      showPath: true,
      geoJsonSource: {
        type: 'geojson',
        data: {
          id: 'points',
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: '',
              },
            },
          ],
        },
      },

      geoJsonLayer: {
        id: 'points',
        type: 'circle',
        paint: {
          'circle-radius': 6,
          'circle-color': '#df0c0c',
          'circle-stroke-width': 2,
          'circle-stroke-color': '#e90505',
        },
      },
      dialogVisibleMap: false,
      viewName: '',
      datosBusqueda: { hr: '', hj: '' },
      styleLetra: {},
      styleBtnRuta: {
        color: 'white',
      },
      comprob_guia: [],
      sucursales: [],
      modalDetalle: false,
      numPages: [],
      existe_mapa: false,
      hojarutal: '',
      dialogDetalles: false,
      loading_fotos: false,
    }
  },
  created() {
    EventBus.$on('abrirModalDetalles', (datos) => {
      this.getDetalles(datos.info)
      setTimeout(() => {
        this.dialogDetalles = true
        this.loading = false
      }, 2000)
    })

    this.permisos()
    this.getSucursales()
    this.mapbox = Mapbox
    if (this.$route.query.hj && this.$route.query.hr) {
      this.viewName = 'resumenhojaruta'
    } else {
      this.viewName = 'guias'
    }
  },
  methods: {
    beforeDestroy() {
      EventBus.$off('abrirModalDetalles', false)
    },
    openPdf(c) {
      this.descargando = c.id
      // console.log('rutaopen',c.ruta)
      this.numPages = []
      if (c.comprob) {
        if (c.empresa == 'DSA' && this.empresa != 'lo') {
          this.url = 'https://seguimiento.suizoargentina.com.ar/seguimiento'
        } else {
          this.url = this.empresa != 'lo' ? 'https://seguimiento.suizoargentina.com.ar/seguimiento' : 'https://seguimiento.log-in.com.ar/seguimiento'
        }

        // let url = (this.empresa == 'lo' && c.empresa != "DSA") ? "robotescanerlogin/downloadPdfLogin" : "/escaner/downloadPDF"
        let url = 'robotescanerlogin/downloadPdfs'
        let empresa_comprobante = c.empresa == 'DSA' ? 'DSA' : 'LOGIN'
        HTTP.post(url, {
          ruta: c.ruta,
          id: c.id,
          hojas: c.hojas,
          empresa: empresa_comprobante,
        })
          .then((res) => {
            this.descargando = false
            if (c.hojas == 1 || c.hojas == null) {
              if (res.data.status === 1) {
                this.ruta = this.url + res.data.archivo
                this.numPages.push({
                  id: c.hojas,
                  ruta: this.url + res.data.archivo,
                })
                setTimeout(() => {
                  this.modalDetalle = true
                }, 100)
              } else if (res.data.status <= 0) {
                this.$alert('No se pudo recuperar el archivo ', res.data)
              }
            } else {
              // console.log(res.data)
              res.data.forEach((value, key) => {
                if (value.status === 1) {
                  this.numPages.push({
                    id: key + 1,
                    ruta: this.url + value.archivo,
                  })
                }
              })
              if (this.numPages.length) {
                setTimeout(() => {
                  this.modalDetalle = true
                }, 250)
              } else {
                this.modalDetalle = false
              }
            }
          })
          .catch((e) => {
            this.descargando = false

            this.$alert('Error al descargar el archivo ', e)
          })
      } else {
        this.descargando = false
        // this.$alert("No se pudo recuperar el archivo ");
        let pdf = c.ruta.split('.')[1]
        if (pdf && pdf == 'pdf') {
          this.url = 'https://cdn.log-in.com.ar/escaner/'
          this.ruta = this.url + c.ruta
          // console.log(this.ruta)
          window.open(this.ruta, '_blank')
        } else {
          this.$alert('El archivo está dañado o no existe ')
        }
      }
    },
    abrirModal() {
      this.dialogVisibleMap = true
    },
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    formatoMoneda(value) {
      return accounting.formatMoney(value, '$', 2)
    },
    permisos() {
      if (this.empresa == 'lo') {
        this.headerStyle['color'] = '#e5590a'
        this.styleVguiaTitle['backgroundColor'] = '#e65c0bd9'
        this.styleVguiaSpan['backgroundColor'] = '#ff9659'
        this.datosStyle['color'] = '#e5590a'
        this.styleVguiaVacio['backgroundColor'] = '#ea742f52'
        this.urlPdf = 'https://caba33.log-in.com.ar/escaner/'
        this.styleLetra['color'] = '#0B406A'
        this.styleBtnRuta['backgroundColor'] = '#0B406A'
      } else if (this.empresa == 'u') {
        this.headerStyle['color'] = '#0b406a'
        this.datosStyle['color'] = 'rgb(11, 64, 106)'
        this.styleVguiaTitle['backgroundColor'] = 'rgb(11, 64, 106)'
        this.styleVguiaVacio['backgroundColor'] = '#006dcc2e'
        this.styleVguiaSpan['backgroundColor'] = '#77b2e2d1'
        this.urlPdf = 'https://caba33.suizoargentina.com.ar/escaner/'
        this.styleLetra['color'] = '#f56c6c'
        this.styleBtnRuta['backgroundColor'] = '#f56c6c'
      }
    },
    getDetalles(info) {
      setTimeout(() => {
        HTTP.post('/guiasNew/getDetallesNuevo', {
          terminal: info.terminal,
          numero: info.numero,
          empresa: this.empresa == 'u' ? 'DSA' : 'LOG',
        })
          .then((response) => {
            this.guia = response.data
            if (response.data != 0) {
              if (this.guia.letra === '') {
                this.guia.letra = 'G'
              }

              /* Control de sucursales */
              this.controlar = this.guia.sucorig != this.guia.sucinter && this.guia.sucdest != this.guia.sucinter

              if (response.data.anulafec != null) {
                response.data.estado = 'A'
              }

              /* Variables */
              var estado = ''
              var op = '',
                recep = '',
                dnicomp = '',
                fechares1 = ''
              if (response.data.entrega != null) {
                estado = response.data.entrega['operacion'] ? response.data.entrega['operacion'].split('|') : ''
                if (estado[0] && estado[0].toUpperCase().trim() == 'GUIA ENTREGADA') {
                  // estion = 'GUIA RENDIDA';

                  op = estado[1].split(':')
                  recep = op[1].toUpperCase().trim()

                  if (recep != '') {
                    dnicomp = estado[2].split(':')
                    // dni1 = dnicomp[1].toUpperCase().trim();
                  }

                  dnicomp = estado[3].split('a:')
                  fechares1 = dnicomp[1].toUpperCase().trim()

                  if (fechares1 != '') {
                    fechares1 = fechares1.replace(' 00:00:00', '')
                  }
                }
              }
              /* Comprobantes */
              if (response.data.comprobantes) {
                this.showComprobantes(this.guia.comprobantes)
              }

              /* Bultos */
              this.cantidadBultos = response.data.bultos.total
              this.cantidadBultosTabla = response.data.bultos.bultos.length
              this.bultos = response.data.bultos.bultos
              this.bultos.forEach((element) => {
                element['peso'] = element['peso'] != '' ? Number.parseFloat(element['peso']).toFixed(2) : Number.parseFloat(element['volumen'])
                element['volumen'] = element['volumen'] != '' ? Number.parseFloat(element['volumen']).toFixed(2) : Number.parseFloat(element['volumen'])
                element['valordec'] = element['valordec'] != '' ? Number.parseFloat(element['valordec']).toFixed(2) : Number.parseFloat(element['valordec'])
              })
              /* Seguimientos  */
              if (response.data.seguimiento != '') this.showSeguimiento = response.data.seguimiento.length

              if (response.data.ruta) {
                this.tablaRutas = response.data.ruta
              }

              this.loading = false
            }
          })
          .catch((error) => {
            // this.$alert('Error del servidor ' + error)
            console.log(error)
            this.$alert('Comprobante sin guía asociada')
          })
      }, 1000)
    },
    formatFechaHora(fecha) {
      return moment(fecha).format('DD/MM/YYYY HH:mm:ss')
    },
    datosGuiaDev(guiaDev) {
      var fechaDev = guiaDev.alta ? this.formatFechaHora(guiaDev.alta.date) : '-'
      var accion = guiaDev.accion ? guiaDev.accion : '-'
      return {
        fecha: fechaDev,
        estado: accion,
        sucursal: guiaDev.suc_orig,
        operador: guiaDev.nom_ope,
        showMapa: '',
        fotoSeg: '',
        toolFecha: guiaDev.GLOBO,
      }
    },
    getLatLong(seguimiento) {
      let estado = seguimiento ? seguimiento.split('|') : ''
      let lat = typeof estado[3] !== 'undefined' ? (estado[3].trim().length > 5 && !isNaN(parseInt(estado[3])) ? estado[3].trim() : 0) : 0
      let long = typeof estado[2] !== 'undefined' ? (estado[2].trim().length > 5 && !isNaN(parseInt(estado[2])) ? estado[2].trim() : 0) : 0
      // console.log(lat,long)
      if (lat && long) {
        return true
      } else {
        return false
      }
    },
    mapaShow(seguimiento) {
      let estado = seguimiento ? seguimiento.split('|') : ''
      // console.log(estado)
      let lat = estado[3].trim().length > 5 ? estado[3].trim() : 0,
        long = estado[2].trim().length > 5 ? estado[2].trim() : 0
      if (lat && long) {
        this.existe_mapa = true
        this.dialogVisible = true
        this.center = [lat, long]
        this.geoJsonSource.data.features[0].geometry.coordinates = [lat, long]
      } else {
        this.existe_mapa = false
      }
    },
    showGaleria(fotos) {
      this.urlFotos(fotos)
      this.dialogGaleria = true
      setTimeout(() => {
        this.loading_fotos = false
      }, 1000)
    },
    urlFotos(fotos) {
      this.loading_fotos = true
      let urlEmpresa = this.empresa == 'lo' ? 'https://img.log-in.com.ar' : this.empresa == 'u' ? 'https://img.suizoargentina.com.ar' : false

      let urlCompleta = ''
      let separar_url = fotos.url[0].url.split('/')

      if (separar_url[1] == 'appfiles') {
        urlCompleta = urlEmpresa + fotos.url[0].url
      } else {
        urlCompleta = urlEmpresa + '/appfiles' + fotos.url[0].url
      }
      this.srcList = []
      fotos.url.forEach((element) => {
        let separar_url = element.url.split('/')
        if (separar_url[1] == 'appfiles') {
          urlCompleta = urlEmpresa + element.url
        } else {
          urlCompleta = urlEmpresa + '/appfiles' + element.url
        }
        let urlArray = urlCompleta
        this.srcList.push(urlArray)
        this.hojarutal = fotos.hojaruta_asoc
      })
      return urlCompleta
    },
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    },
    showComprobantes(comprobantes) {
      var hash = {}
      var ruta = comprobantes.filter((e) => e.ruta)
      var borrar = comprobantes.filter((e) => e.ruta).map((m) => m.remito)
      var sinruta = comprobantes.filter((sr) => !borrar.includes(sr.remito) && sr.remito).filter((v, i, a) => a.indexOf(v) == i)
      var array = []
      array = sinruta.filter(function (current) {
        var exists = !hash[current.remito]
        hash[current.remito] = true
        return exists
      })

      ruta = ruta.filter(function (current) {
        var exists = !hash[current.remito]
        hash[current.remito] = true
        return exists
      })

      let array1 = array.concat(ruta)

      if (array1.length) {
        /* Traer las rutas correctas */
        if (array1.length > 1) {
          array1.forEach((element) => {
            if (element.ruta != null) {
              HTTP.post('/guiasnew/getRutaComprob', element).then((res) => {
                if (res.data != null) {
                  element.ruta = res.data['ruta']
                  element.id = res.data['id']
                  element.hojas = res.data['hojas']
                } else {
                  element.ruta = ''
                }
              })
            }
          })
        }
      }
      this.comprob_guia = array1
      this.cantidadCompr = this.comprob_guia.length
    },
    getPresentacionC() {
      const array_nropres = this.comprob_guia.filter((elemento) => typeof elemento.presentaciones !== 'undefined' && elemento.presentaciones.length > 0)
      if (array_nropres.length) {
        return array_nropres[0].presentaciones[0].nropres
      } else {
        return '-'
      }
    },
    getNroPres(seguimiento) {
      if (seguimiento != null) {
        if (seguimiento.split('|')[0] == 'COMPROB RECIBIDO') {
          return true
        }
      }

      return false
    },
    getSucursales() {
      HTTP.post('/guiasnew/getSucursales').then((res) => {
        this.sucursales = res.data
      })
    },
    filterSuc(codigo) {
      return this.sucursales[codigo].nombre ? this.sucursales[codigo].nombre : '-'
    },
    getRutaCorrecta() {},
    openFoto(url) {
      window.open(url, '_blank')
    },
    getSubEstado(entrega) {
      // return entrega.sub_estado
      if (entrega.sub_estado.toUpperCase() !== 'NO CORRESPONDE') {
        return entrega.sub_estado.toUpperCase()
      } else {
        return entrega.estado_id != 100 ? entrega.estado.toUpperCase() : ''
      }
    },
  },
  computed: {},
}
</script>

<style scoped>
.bloque-titulo {
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  margin: 0;
  font-weight: normal;
  text-transform: uppercase;
}
.el-collapse-item__content {
  padding-bottom: 0px !important;
  font-size: 13px;
  color: #303133;
  line-height: 1.7692307692;
}

.conv-span {
  float: right;
  padding-top: 5px;
  font-size: 14px;
}
table td {
  line-height: 20px;
  vertical-align: middle;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13pt;
}

hr {
  margin-bottom: 5px;
  margin-top: 5px;
}

table .deshabilitado td {
  background-color: #ffd6d6;
}

form.acciones {
  border: 6px solid #eee;
  border-radius: 8px;
  margin: auto;
  padding: 15px;
  width: 70%;
}

a:focus {
  text-decoration: none;
}
/* .tabla-detalle > tbody > tr > td > strong {

} */
@media (max-width: 1300px) {
  .el-dialog {
    width: 40% !important;
  }
}
@media (max-width: 780px) {
  .conv-span {
    float: initial;
  }
}
.footer {
  position: absolute;
  /* bottom: 0px; */
  /* padding: 10px 10px 0px 10px; */
  width: 100%;
  margin-top: 65px;
}
.scroll_tabla {
  max-height: 336px;
  overflow: auto;
  margin-bottom: 15px;
}
</style>
