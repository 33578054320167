<template>
  <div id="app">
    <!-- <gheader v-if="$route.name !='login' && $route.name !='PageNotFound' && $route.name !='recuperar' && $route.name !='changepass'" />
   <router-view></router-view>
   <gfooter v-if="$route.name !='login'  && $route.name !='PageNotFound'  && $route.name !='recuperar' && $route.name !='changepass' " /> -->
    <!-- <div class="container-main">
     <gheader v-if="$route.name !='login' && $route.name !='PageNotFound' && $route.name !='recuperar' && $route.name !='changepass'" />
      <div class="main">
        <router-view></router-view>
      </div>
      <gfooter v-if="$route.name !='login'  && $route.name !='PageNotFound'  && $route.name !='recuperar' && $route.name !='changepass' " />

   </div> -->
    <div v-if="$route.name != 'login' && $route.name != 'PageNotFound' && $route.name != 'recuperar' && $route.name != 'expirar' && $route.name != 'changepass'">
      <div class="f-container fixed-hf">
        <header>
          <gheader />
        </header>
        <div class="main">
          <router-view></router-view>
        </div>
        <footer>
          <gfooter v-if="$route.name != 'login' && $route.name != 'PageNotFound' && $route.name != 'recuperar' && $route.name != 'expirar' && $route.name != 'changepass'" />
        </footer>
      </div>
    </div>

    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import guiasHeader from '@/components/menu/header'
import guiasFooter from '@/components/menu/footer'
// import jwt_decode from "jwt-decode";

export default {
  name: 'app',
  data() {
    return {
      titulo: '',
      imgFavicon: '',
      back: '',
      formIngresar: {},
    }
  },
  metaInfo() {
    return {
      title: this.titulo,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [{ charset: 'utf-8' }, { name: 'description', content: 'Seguimiento de Guías' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css?family=Roboto:300,300i,500,700,900i&display=swap',
        },
        { rel: 'shortcut icon', type: 'image/png', href: this.imgFavicon },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Varela+Round&display=swap',
        },
      ],
    }
  },
  components: {
    gheader: guiasHeader,
    gfooter: guiasFooter,
  },
  mounted() {
    //  Titulo y logo según empresa
    if (process.env.NODE_ENV !== 'development') {
      let urlpp = window.location.host,
        array_url = urlpp.split('/'),
        empresa_nombre = array_url[0].split('.')

      if (empresa_nombre[1] == 'suizoargentina') {
        this.img_header = require('@/assets/SuizoBlanco.svg')
        this.titulo = 'Suizo Argentina S.A.'
        this.imgFavicon = 'favicon_suizo.ico'
        this.showbtnC = 'primary'
        this.formIngresar.empresa = 'u'
      } else if (empresa_nombre[1] == 'log-in') {
        this.titulo = 'LOG-IN'
        this.img_header = require('@/assets/loginfarma.png')
        this.imgFavicon = 'favicon_log.png'
        this.showbtnC = 'warning'
        this.formIngresar.empresa = 'lo'
      } else {
        this.$router.push({ name: 'PageNotFound' })
      }
    } else {
      this.titulo = 'LOG-IN'
      this.img_header = require('@/assets/loginfarma.png')
      this.imgFavicon = 'favicon_log.png'
      this.showbtnC = 'warning'
      this.formIngresar.empresa = 'lo'
    }
  },
}
</script>
<style lang="css">
/*//Iconos Fontawesome */
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import 'assets/css/style.css';
@import '~sweetalert2/dist/sweetalert2.min.css';

body,
html {
  margin: 0;
  font-family: 'Roboto';
  overflow-x: hidden;
  padding: 0;
}
.f-container {
  height: 100%; /* for shorter content */
  display: flex;
  flex-direction: column;

  /*ie10*/
  display: -ms-flexbox;
  -ms-flex-direction: column;
}

.main {
  background-color: #fff;
  flex: 1;
  min-height: 82vh;
  /*ie10*/
  -ms-flex: 1 1 auto;
  /*needs both shrink and grow*/
}

footer {
  /*ie10 short content*/
  margin-top: auto;
}

/* fixed header and footer with scrollable main section */
.fixed-hf .main {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
