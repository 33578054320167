<template>
  <div v-loading="sistema.loading">
    <br />
    <destinatarios-filter :sistema="this.sistema" /><br />
    <el-row :gutter="10">
      <el-col :span="24" v-show="this.sistema.datos">
        <div class="table-responsive-sm">
          <vuetable
            ref="vuetable"
            api-url="/cross/destinatarios"
            :http-fetch="myFetch"
            :fields="fieldsDestinatarios"
            pagination-path=""
            :css="css.table"
            :sort-order="sortOrder"
            :multi-sort="true"
            :append-params="moreParams"
            :per-page="20"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:loading="showLoader"
            @vuetable:loaded="hideLoader">
          </vuetable>
        </div>
        <div class="el-pagination is-background" style="margin-top: 20px; float: right; padding-bottom: 1rem">
          <vuetable-pagination-info ref="paginationInfo" info-class="pagination-info" info-template="Mostrando de {from}-{to} registros de un total de {total}"></vuetable-pagination-info>
          <vuetable-pagination ref="pagination" :css="css.pagination" @vuetable-pagination:change-page="onChangePage"> </vuetable-pagination>
        </div>
      </el-col>
      <el-col :span="24" v-show="!this.sistema.datos">
        <el-alert type="info" show-icon :closable="false" center>
          <span slot="title">
            <p>No se encontraron destinatarios asociados a la busqueda.</p>
          </span>
        </el-alert>
      </el-col>
    </el-row>
    <destinatarios-modal-form v-if="this.sistema.modal.visible" :modal="this.sistema.modal" />
    <destinatarios-importar v-if="this.sistema.modalImportar.visible" :modal="this.sistema.modalImportar" />
  </div>
</template>
<script>
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import DestinatariosFilter from './Filter'
import DestinatariosModalForm from './CreateModalForm'
import DestinatariosImportar from './Importar'
import DestinatariosActions from './Actions'
import { API } from '@/plugins/api'
import { css, fieldsDestinatarios } from '@/components/cross/_guias'
import { mapGetters } from 'vuex'

Vue.component('destinatarios-actions', DestinatariosActions)

export default {
  name: 'DestinatariosTable',
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    DestinatariosFilter,
    DestinatariosModalForm,
    DestinatariosImportar,
  },
  data() {
    return {
      sistema: {
        loading: true,
        modal: {
          visible: false,
          row: null,
          tipo: null,
          provincias: [],
          convenios: [],
          convenios_user: [],
        },
        modalImportar: {
          convenios: [],
          visible: false,
        },
        boton: 'btnCross',
        datos: false,
      },
      fieldsDestinatarios,
      css,
      sortOrder: [{ field: 'id', sortField: 'id', direction: 'asc' }],
      moreParams: {},
    }
  },

  mounted() {
    this.sistema.modal.convenios = this.getGuiasConfig.convenios_full
    this.sistema.modal.convenios_user = this.getGuiasConfig.convenios
    this.sistema.modalImportar.convenios = this.getGuiasConfig.convenios_full
    this.getProvincias()
  },
  methods: {
    async myFetch(apiUrl, httpOptions) {
      const datos = await API.get(apiUrl, httpOptions)

      if (datos.data.data.data.length > 0) this.sistema.datos = true
      else this.sistema.datos = false

      return datos.data
    },
    getProvincias() {
      API.get('/aux/provincias')
        .then((response) => {
          this.sistema.modal.provincias = response.data.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    showLoader() {
      this.sistema.loading = true
    },
    hideLoader() {
      this.sistema.loading = false
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    /* callTurnado (value){
          if(value == "1")
            return "<span class='el-tag el-tag--success'>SI<span>"
          if(value == "0")
            return "<span class='el-tag el-tag--danger'>NO<span>"
        },                 */
  },
  events: {
    'filter-set'(filterText) {
      this.moreParams = { filter: filterText }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'filter-reset'() {
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    refrescarTabla() {
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'open-form-destinatario'(tipo, row) {
      if (tipo == 'ALTA') {
        this.sistema.modal.row = null
        this.sistema.modal.tipo = 'ALTA'
      }
      if (tipo == 'EDITAR') {
        this.sistema.modal.row = row
        this.sistema.modal.tipo = 'EDITAR'
      }
      this.sistema.modal.visible = true
    },
    'open-form-importar'() {
      this.sistema.modalImportar.visible = true
    },
    'close-form-destinatario'() {
      this.sistema.modal.row = null
      this.sistema.modal.tipo = null
      this.sistema.modal.visible = false
    },
    'close-destinatario-import'() {
      this.sistema.modalImportar.visible = false
    },
  },
  computed: {
    ...mapGetters('Crossdocking', ['getGuiasConfig']),
  },
}
</script>
