<template>
  <el-dialog
    :visible.sync="modal.visible"
    width="80%"
    @closed="cerrarForm()"
    :close-on-click-modal="false"
    style="padding-top: 0px !important"
    :close-on-press-escape="false"
    :destroy-on-close="false"
    top="5vh"
    :show-close="false">
    <span slot="title">
      <h3 style="text-align: center"><i :class="config.icon"></i> {{ config.titulo }}</h3>
    </span>
    <el-form size="small" :model="turno" :rules="rules" ref="turnoFormRef" label-width="180px" v-loading="config.loading">
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Seleccione un convenio:" prop="convenios">
            <div class="el-select el-select--small" style="width: 100%; line-height: 0px !important">
              <v-select
                id="convenios"
                name="convenios"
                ref="convenioRef"
                v-model="selectConvenio"
                :options="convenios"
                :filter="fuseSearch"
                :getOptionLabel="(option) => option.nombre"
                placeholder="Seleccione un convenio"
                @input="setSelected">
                <span slot="no-options">Sin Coincidencia</span>
                <template #option="{ nombre }">
                  <cite>{{ nombre }}</cite>
                </template>
              </v-select>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Proveedor de Convenio:" prop="proveedor_convenio">
            <el-input id="proveedor_convenio" name="proveedor_convenio" v-model="turno.proveedor_convenio" :maxlength="60" placeholder="Ingrese el nombre o razon social" clearable />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="¿Proveedor de descarga?" prop="check_cliente">
            <el-select id="check_cliente" name="check_cliente" style="width: 100%" v-model="turno.check_cliente" placeholder="Seleccione una opción" @change="habilitarDetalles()">
              <el-option v-for="item in config.booleano" :key="item.value" :label="item.texto" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Detalles:" prop="check_cliente_detalles">
            <el-input
              ref="refClienteDetalles"
              id="check_cliente_detalles"
              name="check_cliente_detalles"
              type="textarea"
              :rows="3"
              :maxlength="100"
              placeholder="Detalles del proveedor de descarga que ingresara al predio."
              v-model="turno.check_cliente_detalles"
              :disabled="config.inputClienteDetalles">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="¿Necesita Frio?" prop="check_frio">
            <el-select id="check_frio" name="check_frio" style="width: 100%" v-model="turno.check_frio" placeholder="Seleccione una opcion">
              <el-option v-for="item in config.booleano" :key="item.value" :label="item.texto" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="¿Cliente sin turno?" prop="check_sin_turno">
            <el-select id="check_sin_turno" name="check_sin_turno" style="width: 100%" v-model="turno.check_sin_turno" placeholder="Seleccione una opcion">
              <el-option v-for="item in config.booleano" :key="item.value" :label="item.texto" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Fecha" prop="fecha">
            <el-date-picker
              id="fecha"
              style="width: 100%"
              type="date"
              placeholder="Seleccione la fecha de la reserva"
              :picker-options="pickerOptions"
              clearable
              value-format="yyyy-MM-dd"
              format="dd-MM-yyyy"
              v-model="turno.fecha"
              @change="habilitarHorarios()"
              name="fecha" />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Horario" prop="hora_inicial">
            <el-select id="hora_inicial" style="width: 100%" v-model="turno.hora_inicial" placeholder="Seleccione un turno disponible" :disabled="config.hora_inicial">
              <el-option v-for="item in turnos_disponibles" :key="item.horario" :label="item.horario" :value="item.horario" :disabled="!item.disponible">
                <span v-if="item.disponible" style="float: left; font-size: 16px; font-style: italic; font-weight: bold; color: #27ae60">{{ item.horario }} </span>
                <span v-else style="float: left; font-size: 16px; font-style: italic; font-weight: bold; color: #e74c3c">{{ item.horario }} - No Disponible</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Tipo Unidad" prop="tipo_unidad">
            <el-select id="tipo_unidad" style="width: 100%" v-model="turno.tipo_unidad" placeholder="Seleccione un tipo de unidad">
              <el-option v-for="item in config.unidades" :key="item.value" :label="item.value" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Tipo Descarga" prop="tipo_descarga">
            <el-select id="tipo_descarga" style="width: 100%" v-model="turno.tipo_descarga" placeholder="Seleccione el tipo de descarga">
              <el-option v-for="item in config.descargas" :key="item.value" :label="item.value" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="{ span: 24 }">
          <el-form-item label="Contacto:" prop="contacto_nombre">
            <el-input id="contacto_nombre" name="contacto_nombre" v-model="turno.contacto_nombre" :maxlength="60" placeholder="Apellido y nombre del contacto" clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Celular:" prop="contacto_telefono">
            <el-input
              id="contacto_telefono"
              name="contacto_telefono"
              type="text"
              :maxlength="11"
              prefix-icon="el-icon-phone"
              v-model="turno.contacto_telefono"
              placeholder="Numero de celular"
              @input="cleanInput()"
              clearable />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="E-mail:" prop="contacto_mail">
            <el-input id="contacto_mail" name="contacto_mail" prefix-icon="el-icon-message" :maxlength="50" v-model="turno.contacto_mail" placeholder="Email contacto" clearable />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button type="success" size="medium" @click="saveTurno('turnoFormRef')" icon="el-icon-check" :loading="btnDisabled">Guardar</el-button>
      <el-button type="danger" size="medium" @click="cerrarForm()" icon="el-icon-close">Cerrar</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { API } from '@/plugins/api'
import moment from 'moment'
import { unidades, descargas, booleano } from '@/components/turnos/_auxiliar'
import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import { mapGetters } from 'vuex'
import { rules } from './_AuxTurnos'

export default {
  name: 'form-turno-modal',
  components: { vSelect },
  props: {
    modal: { required: true },
  },
  created() {
    this.turno.fecha = moment().format('YYYY-MM-DD')
    this.convenios = this.getTurneroConfig.convenios_all
    const arrayAgendas = this.getTurneroConfig.agenda
    //Agrego objeto agenda si coincide sino null
    this.convenios = this.convenios.map((convenioItem) => {
      const agendaCoincidente = arrayAgendas.find((item) => item.convenio_id === convenioItem.convenio)
      return agendaCoincidente ? { ...convenioItem, agenda: agendaCoincidente } : { ...convenioItem, agenda: null }
    })
  },
  mounted() {
    //console.log(this.convenios)
    switch (this.modal.tipo) {
      case 'ALTA':
        this.config.icon = 'el-icon-circle-plus'
        this.config.titulo = 'RESERVAR TURNO'
        this.checkDisponibles()
        break
      case 'EDITAR':
        this.config.icon = 'el-icon-circle-plus'
        this.config.titulo = 'EDITAR TURNO NRO ' + this.modal.row.id
        this.cargarForm(this.modal.row)
        break
    }
    //this.$nextTick(() => this.$refs.convenioRef.focus())
  },
  data() {
    return {
      btnDisabled: false,
      config: {
        loading: true,
        icon: null,
        titulo: null,
        hora_inicial: true,
        unidades,
        descargas,
        booleano,
        inputClienteDetalles: true,
      },
      configFecha: {
        disabledDate(date) {
          return date.getTime() <= moment().subtract(1, 'days')
        },
      },
      fechasDeshabilitadas: [],
      options: [],
      turno: {
        id: null,
        convenio_id: null,
        proveedor_convenio: null,
        fecha: null,
        hora_inicial: null,
        tipo_unidad: null,
        tipo_descarga: null,
        check_frio: 0,
        check_cliente: 0,
        check_cliente_detalles: null,
        check_sin_turno: 0,
        contacto_nombre: null,
        contacto_mail: null,
        contacto_telefono: null,
      },
      rules,
      turnos_disponibles: [],
      selectConvenio: null,
      convenios: [],
    }
  },
  methods: {
    habilitarHorarios() {
      this.turno.hora_inicial = null
      this.config.hora_inicial = true
      this.config.loading = true
      this.checkDisponibles()
    },
    cerrarForm() {
      this.$events.fire('close-form')
    },
    cleanInput() {
      this.turno.contacto_telefono = this.turno.contacto_telefono.replace(/[^a-zA-Z0-9]/g, '')
    },
    saveTurno(formName) {
      this.btnDisabled = true
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          this.btnDisabled = false
          return this.$message({
            message: 'Por favor. Controle los campos obligatorios.',
            type: 'warning',
          })
        }
        if (this.selectConvenio == null) {
          this.btnDisabled = false
          return this.$message({
            message: 'Debe seleccionar un convenio.',
            type: 'warning',
          })
        }
        //Control en caso de tener proveedor de descarga
        if (this.turno.check_cliente === 1 && this.turno.check_cliente_detalles === null) {
          this.btnDisabled = false
          return this.$message({
            message: 'Debe completar los detalles del proveedor de descarga',
            type: 'warning',
          })
        }

        switch (this.modal.tipo) {
          case 'ALTA':
            API.post('turnero/turnos', this.turno)
              .then((response) => {
                this.cerrarForm()
                this.$message({
                  message: response.data.message,
                  type: 'success',
                })
                this.$events.fire('refrescar-tabla')
              })
              .catch((error) => {
                //console.log(error)
                this.$message({
                  message: error.response.data.message,
                  type: 'error',
                })
                this.btnDisabled = false
              })
            break
          case 'EDITAR':
            API.put('turnero/turnos/' + this.modal.row.id, this.turno)
              .then((response) => {
                this.cerrarForm()
                this.$message({
                  message: response.data.message,
                  type: 'success',
                })
                this.$events.fire('refrescar-tabla')
              })
              .catch((error) => {
                //console.log(error)
                this.$message({
                  message: error.response.data.message,
                  type: 'error',
                })
                this.btnDisabled = false
              })
            break
        }
      })
    },
    cargarForm(row) {
      API.get('turnero/turnos/' + row.qr)
        .then((response) => {
          const turno = response.data.data
          this.turno.proveedor_convenio = turno.proveedor_convenio
          this.turno.fecha = turno.fecha
          this.turno.tipo_unidad = turno.tipo_unidad
          this.turno.hora_inicial = turno.hora_inicial
          this.turno.tipo_descarga = turno.tipo_descarga
          this.turno.check_cliente = turno.check_cliente
          this.turno.check_cliente_detalles = turno.check_cliente_detalles
          this.turno.check_sin_turno = turno.check_sin_turno
          this.turno.check_frio = turno.check_frio
          this.turno.id = turno.id
          this.turno.contacto_nombre = turno.contacto_nombre
          this.turno.contacto_mail = turno.contacto_mail
          this.turno.contacto_telefono = turno.contacto_telefono
          //Convenio
          var objIndex = this.convenios.findIndex((obj) => obj.convenio == turno.convenio_id)
          this.selectConvenio = this.convenios[objIndex]
          this.turno.convenio_id = turno.convenio_id
          this.checkDisponibles()
        })
        .catch((error) => {
          this.$message({
            message: error.response.data.message,
            type: 'error',
          })
          this.config.loading = false
        })
    },
    checkDisponibles() {
      API.post('turnero/turnos/disponibles', {
        fecha: this.turno.fecha,
        turno_id: this.turno.id,
      })
        .then((response) => {
          this.turnos_disponibles = response.data.data.disponibles
          this.fechasDeshabilitadas = response.data.data.feriados
          this.config.loading = false
          this.config.hora_inicial = false
          //this.$nextTick(() => this.$refs.convenioRef.$refs.search.focus())
        })
        .catch((error) => {
          //console.log(error)
          this.$message({ message: error.response.data.message, type: 'error' })
          this.config.loading = false
          this.btnDisabled = false
        })
    },
    formatDate(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['nombre'],
        shouldSort: true,
        minMatchCharLength: 1,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    setSelected() {
      if (this.selectConvenio.agenda === null) {
        this.turno.convenio_id = this.selectConvenio.convol
        this.turno.proveedor_convenio = null
        this.turno.check_cliente = 0
        this.turno.check_frio = 0
        this.turno.check_sin_turno = 0
        this.turno.contacto_mail = null
        this.turno.contacto_nombre = null
        this.turno.contacto_telefono = null
        this.turno.tipo_descarga = null
        this.turno.tipo_unidad = null
        this.turno.check_cliente_detalles = null
        this.config.inputClienteDetalles = true
        this.$refs.turnoFormRef.resetFields()
      } else {
        this.turno.convenio_id = this.selectConvenio.convol
        this.turno.proveedor_convenio = this.selectConvenio.agenda.proveedor_convenio
        //agrego template
        this.turno.check_cliente = this.selectConvenio.agenda.check_cliente
        this.turno.check_frio = this.selectConvenio.agenda.check_frio
        this.turno.check_sin_turno = this.selectConvenio.agenda.check_sin_turno
        this.turno.contacto_mail = this.selectConvenio.agenda.contacto_mail
        this.turno.contacto_nombre = this.selectConvenio.agenda.contacto_nombre
        this.turno.contacto_telefono = this.selectConvenio.agenda.contacto_telefono
        this.turno.tipo_descarga = this.selectConvenio.agenda.tipo_descarga
        this.turno.tipo_unidad = this.selectConvenio.agenda.tipo_unidad
        if (this.selectConvenio.agenda.check_cliente === 1) {
          this.config.inputClienteDetalles = false
          this.turno.check_cliente_detalles = this.selectConvenio.agenda.check_cliente_detalles
        }
        return this.$message({
          message: 'El convenio tiene una agenda de datos precargados, se autocompleto la ficha.',
          type: 'info',
        })
      }
    },
    habilitarDetalles() {
      this.turno.check_cliente_detalles = null
      if (this.turno.check_cliente === 1) {
        this.config.inputClienteDetalles = false
        this.$nextTick(() => this.$refs.refClienteDetalles.focus())
      }
      if (this.turno.check_cliente === 0) {
        this.config.inputClienteDetalles = true
      }
    },
  },
  computed: {
    ...mapGetters('Turnos', ['getTurneroConfig']),
    pickerOptions() {
      return {
        disabledDate: (date) => {
          const formattedDate = this.formatDate(date)
          const today = this.formatDate(new Date())
          const dayOfWeek = date.getDay() // Obtenemos el día de la semana (0: domingo, 1: lunes, ...)
          return (
            formattedDate < today || this.fechasDeshabilitadas.includes(formattedDate) || dayOfWeek === 0 // Deshabilitar los domingos (0)
          )
        },
      }
    },
  },
}
</script>
