<template>
  <div style="padding: 0px 20px">
    <el-row :gutter="10">
      <el-col :span="24" :xs="24">
        <h2>Guia #: {{ rowData.terminal.toString().padStart(5, '0') }}-{{ rowData.numero.toString().padStart(8, '0') }}</h2>
        <hr />
        <el-form size="small" ref="guiaRef" label-position="left">
          <el-row :gutter="20">
            <el-col :md="{ span: 8 }">
              <el-form-item label="Codigo:" prop="codigo">
                <el-input :value="rowData.codigo" readonly />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 8 }">
              <el-form-item label="CUIT:" prop="cuit">
                <el-input v-mask="'##-########-#'" :value="rowData.cuit" readonly />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 8 }">
              <el-form-item label="Razon Social:" prop="razon_social">
                <el-input prefix-icon="el-icon-user-solid" :value="rowData.razon_social" readonly />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :md="{ span: 6 }">
              <el-form-item label="Dirección:" prop="direccion">
                <el-input :value="rowData.direccion" readonly />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 6 }">
              <el-form-item label="Codigo Postal:" prop="codigo_postal">
                <el-input readonly size="small" :value="rowData.codigo_postal" />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 6 }">
              <el-form-item label="Provincia:" prop="provincia">
                <el-input readonly size="small" :value="rowData.provincia" />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 6 }">
              <el-form-item label="Localidad:" prop="localidad">
                <el-input readonly size="small" :value="rowData.localidad" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20"> </el-row>
          <el-row :gutter="20">
            <el-col :md="{ span: 4 }">
              <el-form-item label="Terminal:" prop="terminal">
                <el-input size="small" readonly :value="rowData.terminal" />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 4 }">
              <el-form-item label="Numero:" prop="numero">
                <el-input size="small" readonly :value="rowData.numero" />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 8 }">
              <el-form-item label="Fecha de entrega:" prop="fecha_entrega">
                <el-date-picker type="date" readonly value-format="yyyy-MM-dd" format="dd-MM-yyyy" :value="rowData.fecha_entrega" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 4 }">
              <el-form-item label="Rango horario">
                <el-input type="text" readonly :value="rowData.rango_inicial" prefix-icon="el-icon-time" />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 4 }">
              <el-form-item label="-" prop="rango_final">
                <el-input type="text" readonly :value="rowData.rango_final" prefix-icon="el-icon-time" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :md="{ span: 24 }">
              <el-form-item label="Datos adicionales:" prop="adicional">
                <el-input readonly type="textarea" :value="rowData.adicional" />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 4 }">
              <el-checkbox readonly label="Cobranza" :true-label="1" :false-label="0" :value="checkCobranza" />
            </el-col>
            <el-col :md="{ span: 4 }">
              <el-input readonly prefix-icon="fas fa-dollar-sign" size="small" :value="rowData.valor_cobranza" />
            </el-col>
            <el-col :md="{ span: 4 }">
              <el-checkbox readonly size="medium" :true-label="1" :false-label="0" label="Acompañante" :value="checkAcompanante" />
            </el-col>
            <el-col :md="{ span: 4 }">
              <el-input prefix-icon="fas fa-users" readonly size="small" :value="rowData.acompanante" />
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 20px">
            <hr />
            <br />
            <el-col :md="{ span: 8 }">
              <el-form-item label="Porte:" prop="porte">
                <el-input size="small" readonly :value="rowData.porte" />
              </el-form-item>
            </el-col>
            <el-col :md="{ span: 4 }">
              <el-checkbox style="margin-top: 30px" readonly size="medium" :true-label="1" :false-label="0" label="Vehículo con Pala" :value="rowData.pala" />
            </el-col>
            <el-col :md="{ span: 5 }">
              <el-checkbox style="margin-top: 30px" readonly size="medium" :true-label="1" :false-label="0" label="Retiro de mercaderia en deposito" :value="rowData.retiro_cliente" />
            </el-col>
            <el-col :md="{ span: 5 }">
              <el-checkbox style="margin-top: 30px" readonly size="medium" :true-label="1" :false-label="0" label="Contra entrega de mercadería" :value="rowData.contraentrega" />
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 30px">
            <!-- <el-col :md="{ span: 12 }">
                  <el-form-item label="GLN:" prop="gln" label-position="right">
                    <el-input type="textarea" readonly :value="rowData.gln" :rows="3"/>
                  </el-form-item>
              </el-col> -->
            <el-col :md="{ span: 24 }">
              <el-form-item label="Otros requerimientos:" prop="otros">
                <el-input type="textarea" readonly :value="rowData.otros" :rows="3" />
              </el-form-item>
            </el-col>
          </el-row>
          <br />
        </el-form>
        <hr />
        <el-row :gutter="10">
          <el-col :span="12" :xs="12">
            <h2 style="text-align: left; float: left; padding-right: 10px">Comprobantes</h2>
            <div style="text-align: left; float: left; margin-top: 10px">
              <el-button :loading="btnLoading" size="mini" @click="imprimirTodo(rowData.id, 'C')" icon="el-icon-printer" type="primary" plain />
            </div>
            <vuetable ref="vComprobantes" :api-mode="false" :data="rowData.comprobantes" :fields="fComprobantes" :css="css.table" noDataTemplate="Sin Datos" :row-class="onRowClass">
              <template slot="sequence" slot-scope="props">
                <div>{{ props.rowIndex + 1 }}</div>
              </template>
              <template slot="actions" slot-scope="props">
                <el-tooltip class="item" effect="dark" content="Imprimir" placement="left">
                  <el-button :loading="btnLoading" size="mini" @click="imprimir(props, 'C', 'text')" icon="el-icon-printer" type="info" plain />
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Descargar" placement="right">
                  <el-button :loading="btnLoading" size="mini" @click="imprimir(props, 'C', 'file')" icon="el-icon-download" type="primary" plain />
                </el-tooltip>
              </template>
            </vuetable>
          </el-col>
          <el-col :span="12" :xs="12">
            <h2 style="text-align: left; float: left; padding-right: 10px">Bultos</h2>
            <div style="text-align: left; float: left; margin-top: 10px">
              <el-button :loading="btnLoading" size="mini" @click="imprimirTodo(rowData.id, 'B')" icon="el-icon-printer" type="primary" plain />
            </div>
            <vuetable ref="vBultos" :api-mode="false" :data="rowData.bultos" :fields="fbultos" :css="css.table" noDataTemplate="Sin Datos" detail-row-component="bultos-details">
              <template slot="sequence" slot-scope="props">
                <div>{{ props.rowIndex + 1 }}</div>
              </template>
              <template slot="actions" slot-scope="props">
                <el-tooltip class="item" effect="dark" content="Imprimir" placement="left">
                  <el-button :loading="btnLoading" size="mini" @click="imprimir(props, 'B', 'text')" icon="el-icon-printer" type="info" plain />
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Descargar" placement="bottom">
                  <el-button :loading="btnLoading" size="mini" @click="imprimir(props, 'B', 'file')" icon="el-icon-download" type="primary" plain />
                </el-tooltip>
              </template>
            </vuetable>
          </el-col>
        </el-row>
        <br /><br />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import accounting from 'accounting'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import { css } from '@/components/cross/_guias'
import { API } from '@/plugins/api'
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper'

export default {
  name: 'guias-comprobantes-details',
  components: { Vuetable },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },
  mounted() {
    if (this.rowData.acompanante == 0) this.rowData.acompanante = null
  },
  data() {
    return {
      btnLoading: false,
      loaderTable: false,
      bultos: [],
      fComprobantes: [
        {
          name: '__slot:sequence',
          title: '#',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '30px',
        },
        {
          name: 'codigo',
          title: 'Comprobante',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'contraentrega',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '200px',
          callback: this.callContraentrega,
        },
        {
          name: '__slot:actions',
          title: 'Acciones',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '120px',
        },
      ],
      fbultos: [
        {
          name: '__slot:sequence',
          title: '#',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '30px',
        },
        {
          name: 'tipo_bulto',
          title: 'Tipo de Bulto',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'peso_declarado',
          title: 'Peso Declarado',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'volumen_declarado',
          title: 'Volumen Declarado',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'valor_declarado',
          title: 'Valor Declarado',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          callback: this.callFormatNumber,
        },
        {
          name: 'frio',
          title: 'Cadena de Frio',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          callback: this.callTipo,
        },
        /*{
          name: 'comprobante',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          callback: this.callComprobante
        },*/
        {
          name: '__slot:actions',
          title: 'Acciones',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '120px',
        },
      ],
      css,
    }
  },
  methods: {
    callFormatNumber(value) {
      return accounting.formatMoney(value, '$', '0', '.')
    },
    callBultosLength(value) {
      return "<span class='el-tag el-tag--success'>" + value.length + '</span>'
    },
    callComprobante(value) {
      if (value == null) return 'Sin vincular'
      else return value.codigo
    },

    callTipo(value) {
      if (value == 1) return 'SI'
      if (value == 0) return 'NO'
    },
    callContraentrega(value) {
      if (value == 1) return 'SI'
      if (value == 0) return 'NO'
    },
    onRowClass(dataItem) {
      //index
      if (this.$refs.vComprobantes.isVisibleDetailRow(dataItem.id)) {
        return 'selected-row'
      }
    },
    imprimir(props, tipo, opcion) {
      this.btnLoading = true
      API.post('/cross/guias/printFile', {
        id: props.rowData.id,
        tipo: tipo,
        opcion: opcion,
      })
        .then((response) => {
          //console.log(response.data.data)
          if (opcion == 'text') {
            const zpl = response.data.data
            return this.connectZebra(zpl)
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            //const contentDisposition = response.headers['content-disposition']

            let fileName
            if (tipo == 'C') fileName = tipo + props.rowData.orden + '-' + props.rowData.codigo + '.txt'
            else fileName = tipo + props.rowData.orden + '-' + this.rowData.terminal.padStart(4, '0') + '-' + this.rowData.numero.padStart(8, '0') + '.txt'
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
            this.btnLoading = false
            return this.$swal('Archivo Descargado', 'El pedido se proceso de forma exitosa', 'success')
          }
        })
        .catch((error) => {
          return this.$message({
            message: error.response.data.message,
            type: 'error',
          })
        })
    },
    imprimirTodo(idGuia, tipo) {
      this.btnLoading = true
      API.post('/cross/guias/printAll', { id: idGuia, tipo: tipo })
        .then((response) => {
          this.btnLoading = false
          const zpl = response.data.data
          return this.connectZebra(zpl)
        })
        .catch((error) => {
          this.btnLoading = false
          return this.$message({
            message: error.response.data.message,
            type: 'error',
          })
        })
    },
    async connectZebra(zpl) {
      console.log('Iniciando Zebra...')
      try {
        //Instancio browserPrint
        const browserPrint = new ZebraBrowserPrintWrapper()
        // Seleccion impresora por defecto
        const defaultPrinter = await browserPrint.getDefaultPrinter()
        // Seteo impresora por defecto
        browserPrint.setPrinter(defaultPrinter)
        // IMPRESION DIRECTA
        //console.log(zpl)
        zpl.forEach(function (tk) {
          setTimeout(() => {
            browserPrint.print(tk)
          }, 1500)
        })

        this.btnLoading = false

        return this.$swal('Listo!', 'Etiquetas enviadas a la impresora por defecto en BrowserPrint.', 'success')
        // FIN IMPRESION DIRECTA
        // CHEQUEO ANTES DE IMPRESION
        /*
            const printerStatus = await browserPrint.checkPrinterStatus()
            if(printerStatus.isReadyToPrint) {
              browserPrint.print(zpl)
              console.log("Imprimiendo...")
              this.btnLoading = false
              return this.$swal('Listo!', 'Impresion Completada', 'success')
            }
            else {
              console.log("Error/s", printerStatus.errors)
              this.btnLoading = false
              return this.$swal('Atencion!', printerStatus.errors, 'warning')
            }
            */
        // FIN DE CHEQUEO ANTES DE IMPRESION
      } catch (error) {
        //throw new Error(error)
        //console.log(error)
        this.btnLoading = false
        return this.$swal('No se pudo conectar a la impresora', 'Debe configurar la impresora por defecto en el BrowserPrint', 'error')
      }
    },
    // printZebraTest() {
    //   this.btnLoading = true
    //   const zpl = `^XA
    //               ^BY2,2,100
    //               ^FO20,20^BC^FD0123456789^FS
    //               ^XZ`
    //   this.connectZebra(zpl)
    // },
  },
  computed: {
    checkCobranza() {
      if (this.rowData.valor_cobranza > 0) return true
      else return false
    },
    checkAcompanante() {
      if (this.rowData.acompanante > 0) return true
      else return false
    },
  },
}
</script>
