<template>
    <div style="max-width:1500px;margin-left: auto;margin-right: auto;">
         <el-card style="margin-top: 10px">
             <el-row>
                 <el-col>
                    <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
                        <el-form-item label="Ingrese nº de Comprobante"  prop="comprobante"
                            :rules="[
                            {
                                required: true,
                                message: 'Debe ingresar el nº de comprobante',
                                trigger: 'change',
                            },
                            {
                                min: 12,
                                message: 'El número de comprobante debe tener al menos 12 caracteres',
                                trigger: 'blur',
                            },
                            ]">
                            <el-input ref="comprobante" v-model="formInline.comprobante" size="small" placeholder="Nº comprobante" clearable :disabled ="desa_input"
                            @keypress.enter.native.prevent="onSubmit('formInline')" >
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit('formInline')" size="small" icon="el-icon-search" :loading="buscando">Buscar</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="warning" @click="limpiarCampos('formInline')" icon="el-icon-close" size="small">Limpiar</el-button>
                        </el-form-item>
                    </el-form>
                 </el-col>
             </el-row>
         </el-card>
         <div v-if="datos_olcomprob.length">
            <el-card style="margin-top: 10px" v-loading="buscando">
                <div v-if="datos_olcomprob.length">
                <h3 style="margin:0px"><center>Datos del Comprobante</center></h3>
                <el-row :gutter="10" style="margin:0px">
                    <el-col :md="8">
                        <label style="font-weight: 400;"> <i class="el-icon-check"></i> <u>Comprobante</u> :</label>
                            <label v-if="datos_olcomprob[0].comprob" style="font-weight: 600;">
                                {{datos_olcomprob[0].comprob}}
                            </label>
                            <label v-else>-</label>                  
                    </el-col>
                    <el-col :md="8">
                        <label style="font-weight: 400;"> <i class="el-icon-check"></i> <u>Guías asociadas</u> :</label>
                        <div v-if="datos_olcomprob.length>1">
                             <ul style="margin: 0px;">
                                <li style="font-size: 13px;" v-for="guia in datos_olcomprob" :key="guia.numero">{{guia.letra}}-{{guia.terminal}}-{{guia.numero}}</li>
                            </ul> 
                        </div>
                        <label style="font-weight: 600;" v-if="datos_olcomprob.length == 1">
                            {{datos_olcomprob[0].letra}}-{{datos_olcomprob[0].terminal}}-{{datos_olcomprob[0].numero}}
                        </label>                                               
                    </el-col>
                        <el-col :md="8" v-if="datos_web_escaneados.length">
                            <label style="font-weight: 400;"> <i class="el-icon-check"></i> <u>Fecha Comprobante</u> :</label>
                            <label  v-if="datos_web_escaneados[0].fecha_comprob" style="font-weight: 600;">
                                {{datos_web_escaneados[0].fecha_comprob.date | moment("DD/MM/YY HH:mm")}}
                            </label>                       
                            <label v-else>-</label>    
                        </el-col>
                        <el-col :md="8" v-else-if="datos_olcomprob.length">
                            <label style="font-weight: 400;"> <i class="el-icon-check"></i> <u>Fecha Comprobante</u> :</label>
                            <label  v-if="datos_olcomprob[0].fchcomp" style="font-weight: 600;">
                                {{datos_olcomprob[0].fchcomp.date | moment("DD/MM/YY HH:mm")}}
                            </label>
                            <label v-else>-</label>    
                        </el-col>
                </el-row>
                <div  v-if="datos_web_escaneados.length">          
                <el-row :gutter="10" style="margin:0px">
                    <el-col :md="8">
                        <label style="font-weight: 400;"> <i class="el-icon-check"></i> <u> Sucursal </u> :</label>
                        <label  v-if="datos_web_escaneados[0].sucursal" style="font-weight: 600;">
                            {{datos_web_escaneados[0].sucursal}}-{{datos_web_escaneados[0].sucursal_nombre}}
                        </label>
                        <label v-else>-</label>   
                    </el-col>
                    
                    <el-col :md="8">
                        <label style="font-weight: 400;"> <i class="el-icon-check"></i> <u> Convenio </u>:</label>
                        <label  v-if="datos_web_escaneados[0].guia_olconv && datos_web_escaneados[0].convenio_nombre" style="font-weight: 600;">
                            {{datos_web_escaneados[0].guia_olconv}}-{{datos_web_escaneados[0].convenio_nombre}}
                        </label>
                        <label v-else>-</label>   
                    </el-col>
                    <el-col :md="8">
                        <label style="font-weight: 400;"> <i class="el-icon-check"></i> <u> Cuit </u> :</label>
                        <label  v-if="datos_web_escaneados[0].cuit" style="font-weight: 600;">
                            {{datos_web_escaneados[0].cuit}}
                        </label>
                        <label v-else>-</label>   
                    </el-col>
                </el-row>  
            
                <el-row :gutter="10" style="margin:0px">
                    <el-col :md="8">
                        <label style="font-weight: 400;"> <i class="el-icon-check"></i> <u>Empresa </u> :</label>
                        <label  v-if="datos_web_escaneados[0].empresa" style="font-weight: 600;">
                            {{datos_web_escaneados[0].empresa}}
                        </label>
                        <label v-else>LOG</label>   
                    </el-col>
                    <el-col :md="8">
                        <label style="font-weight: 400;"> <i class="el-icon-check"></i> <u>Fecha Sincronizacion</u> :</label>
                        <label v-if="datos_web_escaneados[0].fecha_sincronizacion" style="font-weight: 600;">
                            {{datos_web_escaneados[0].fecha_sincronizacion.date | moment("DD/MM/YY HH:mm")}}
                        </label>
                        <label v-else>-</label>                  
                    </el-col>
                    <el-col :md="8" style="margin-bottom: 20px !important;">
                            <label style="font-weight: 400;"> <i class="el-icon-check"></i> <u>Fecha de escaneo</u> :</label>
                            <label  v-if="datos_web_escaneados[0].fecha_escaneo" style="font-weight: 600;">
                                {{datos_web_escaneados[0].fecha_escaneo.date | moment("DD/MM/YY HH:mm")}}
                            </label>
                            <label v-else>-</label>    
                    </el-col>
                    
                </el-row>
                
                <el-divider></el-divider>
                <div v-if="datos_web_escaneados[0]['ruta']!=''">
                    <el-row :gutter="20">
                        <h3 style="margin:0px"><center>Comprobante Escaneado</center></h3>
                    </el-row> 
                    <el-row :gutter="20">                    
                        <!-- <el-col :md="{ span: 6, offset: 2 }"> -->
                        <el-col :md="{ span: 8, offset: 2 }">
                                <!-- <el-card shadow="always"> -->
                                <label style="margin-bottom: 10px;"><center>  Pdfs del Comprobante</center></label>
                                <div v-if="numPages.length" style="margin-top: 5px;">
                                    <div v-for="pdf in numPages" :key="pdf.id">
                                        
                                        <el-tooltip class="item" effect="dark" content="Comprobantes escaneados" placement="bottom">                        
                                            <el-button type="danger" class="block" icon="el-icon-picture" style="margin-bottom: 5px;"
                                            size="mini"  @click="openFoto(pdf.ruta)"> Comprobante {{pdf.id}} {{pdf.texto}}
                                            </el-button>
                                        </el-tooltip>
                                        <!-- <label >{{pdf.texto}}</label> -->
                                    <!-- @click="openPdf(datos_web_escaneados[0])" -->
                                    </div>
                                </div>
                                <div v-else style="margin-top:5px">
                                <center> No hay comprobantes escaneados</center>
                                </div>
                                <!-- </el-card> -->
                            
                        </el-col>
                        <el-col :md="{ span: 12 }" style="">
                            <!-- <el-card shadow="always"> -->
                                <label style="margin-bottom: 10px;"><center>  Subir Comprobantes</center></label>

                            <el-form>
                            <el-form-item style="text-align: center;">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Subir comprobante"
                                placement="bottom"
                            >
                            <el-upload
                            class="upload-demo" ref="upload"
                            action="#" 
                            :http-request="uploadPdf" :on-change="handleChange" :on-remove='eliminarElemento'
                            accept=".pdf" :file-list="fileList">
                            <el-button size="mini" type="primary">Clic para subir archivo</el-button>
                            <div slot="tip" class="el-upload__tip">Solo archivos PDF</div>
                            </el-upload>
                            </el-tooltip>
                            </el-form-item>
                            <el-form-item  v-if="mostrar_barra" style="text-align: center !important;" label="Progreso de carga de archivo actual">
                                <el-progress  :text-inside="true" size="mini" :stroke-width="15" style="text-align: center !important;"
                                :percentage="progressPercent" />
                            </el-form-item>
                            </el-form>
                            <!-- </el-card> -->
                        </el-col>
                    </el-row>
                </div>
                </div>
                
                <div v-else>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <h3><center>Comprobante Escaneado</center></h3>
                    <!-- </el-row> 
                    <el-row :gutter="20"> -->
                            <el-alert :closable="false"
                            title="El comprobante no tiene datos de escaneo" type="error" center show-icon>
                            </el-alert>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <h3><center>Subir comprobante escaneado</center></h3>
                        <el-col :md="12">
                            <el-form>
                            <el-form-item style="text-align: center;">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Subir comprobante"
                                placement="bottom"
                            >
                            <el-upload
                            class="upload-demo" ref="upload"
                            action="#" 
                            :http-request="uploadPdf" :on-change="handleChange" :on-remove='eliminarElemento'
                            accept=".pdf" :file-list="fileList">
                            <el-button size="small" type="primary">Clic para subir archivo</el-button>
                            <div slot="tip" class="el-upload__tip">Solo archivos PDF</div>
                            </el-upload>
                            </el-tooltip>
                            </el-form-item>
                            <el-form-item  v-if="mostrar_barra" style="text-align: center !important;" label="Progreso de carga de archivo actual">
                                <el-progress  :text-inside="true" :stroke-width="24" style="text-align: center !important;"
                                :percentage="progressPercent" />
                            </el-form-item>
                            </el-form>
                        </el-col>
                        <el-col :md="12">
                        <label style="margin-bottom: 10px;"><center>  Pdfs del Comprobante</center></label>
                            <div v-if="numPages.length">
                                <div v-for="pdf in numPages" :key="pdf.id">
                                    
                                    <el-tooltip class="item" effect="dark" content="Comprobantes escaneados" placement="bottom">                        
                                        <el-button type="danger" class="block" icon="el-icon-picture" style="margin-bottom: 5px;"
                                        size="mini"  @click="openFoto(pdf.ruta)"> Comprobante {{pdf.id}} 
                                        </el-button>
                                    </el-tooltip>
                                <!-- @click="openPdf(datos_web_escaneados[0])" -->
                                </div>
                            </div>
                            <div v-else>
                                <!-- <center><small> No hay comprobantes escaneados </small></center> -->
                                <center>
                                    <el-alert title=" No hay comprobantes escaneados" type="warning" :closable="false">
                                    </el-alert>
                                </center>
                            </div>
                        </el-col>
                    </el-row>

                </div>
                </div>
            </el-card>
         </div>
    </div>
</template>
<script>
import { HTTP } from "@/axios";
// import pdf from "vue-pdf";

export default {
    components: {
        // pdf,
    },
    data(){
        return{
            formInline: {
                comprobante: '',
            },
            datos_olcomprob:[],
            datos_web_escaneados:[],
            buscando:false,
            desa_input:false,
            esperando:false,
            modalDetalle:false,
            numPages:[],
            descargando : false,
            empresaUrl: window.localStorage.getItem('empresa'),
            operador:JSON.parse(window.localStorage.getItem("datos")).uid + '-'+JSON.parse(window.localStorage.getItem("datos")).user,
            url: "//caba33.log-in.com.ar/escaner/",
            ruta: false, 
            fileList: [],
            file:'',
            no_corresponde:false,
            progressPercent:0,
            mostrar_barra:false
            }
    },
    methods: {
    formatFecha(fecha) {
        return this.$moment(fecha).format('DD/MM/YYYY');
    },
    onSubmit(formName) {
    this.$refs[formName].validate((valid) => {
        if (valid) {
        this.buscando   = true
        this.desa_input = true
         this.numPages  = []
        HTTP.post("/escanerPrueba/getComprobSubir", {comprobante:this.formInline.comprobante})
        .then((res) => {
            this.buscando= false
            if(res.data != 0 && res.data != 2)
            {
                this.datos_olcomprob        = res.data.olcomprob != null ?  res.data.olcomprob : null               
                this.datos_web_escaneados   = res.data.web_comprob  != null ?  res.data.web_comprob : null  
                if(this.datos_web_escaneados.length)
                {
                   
                    this.datos_web_escaneados.forEach(element => {
                        element.empresa = element.empresa == 'LOG' ? null :element.empresa
                       this.openPdf(element)
                    });
                    // console.log(this.datos_web_escaneados)

                }          
                
            }else
            {
                if(res.data == 2)
                {
                    this.$alert('El formato del comprobante no es correcto', 'Cuidado', {
                    confirmButtonText: 'OK',
                    type: 'warning'
                    });
                    
                }else
                {
                    this.$alert('El comprobante no existe', 'Error', {
                    confirmButtonText: 'OK',
                    type: 'error'
                    });
                }   
                this.desa_input = false
                this.$refs.comprobante.focus();         
            }            
        }).catch(error=>{
        console.log(error)
        // this.$alert('No se pudo realizar la acción. Intente nuevamente');
        this.buscando 	= false
        this.desa_input = false
        });
        }
          });
    },
    limpiarCampos(formName)
    {
        this.desa_input             = false
        this.buscando               = false
        this.formInline.comprobante = ''
        this.datos_olcomprob        = []
        this.datos_web_escaneados   = []
        this.fileList               = []
        this.$refs[formName].resetFields();
    },
    openPdf(c) {
    //    console.log('c',c)
      if (c.ruta) 
      {   
         
        if(c.empresa == "DSA" && this.empresaUrl != 'lo' )
        {
           this.url ="https://seguimiento.suizoargentina.com.ar/seguimiento"
        }else
        {
          this.url = (this.empresaUrl != 'lo' )
            ? "https://seguimiento.suizoargentina.com.ar/seguimiento"
            : "https://seguimiento.log-in.com.ar/seguimiento";
        }
        
        let url = "robotescanerlogin/downloadPdfs"
        let empresa_comprobante = c.empresa == "DSA" ? 'DSA' : 'LOGIN'

        // let separar_ruta = c.ruta.split('/')
        // if(separar_ruta.length && separar_ruta[0]=='comprobantes_subidos')
        // {
        //     HTTP.post('escanerPrueba/downloadPdfs', { ruta: c.ruta, id: c.id, hojas:c.hojas,empresa:empresa_comprobante})
        //   .then((res) => {
        //     this.descargando = false;
        //     console.log(res.data)
            
        //   })
        //   .catch((e) => {
        //     this.descargando = false;

        //     this.$alert("Error al descargar el archivo ", e);
        //   });
        // }else
        // {
        let cantidad = 0
        let is_comprob_subido = c.ruta.split('/')
        HTTP.post(url, { ruta: c.ruta, id: c.id, hojas:c.hojas,empresa:empresa_comprobante})
          .then((res) => {
            this.descargando = false;
            if(c.hojas==1 || c.hojas == null )
            {
              if (res.data.status === 1) 
              {
                this.ruta = this.url + res.data.archivo;
                let url_s = res.data.archivo.split('/')
                this.numPages.push(
                {
                    id: url_s[2],
                    ruta: this.url + res.data.archivo,
                    hoja:cantidad+=1,
                    texto: is_comprob_subido[0] == "comprobantes_subidos" ? '- Pdf cargado en esta instancia' : ''
                })
              }else if (res.data.status === -1) {
                this.$alert("No se pudo recuperar el archivo ", res.data);
              }
            }
            else
            {              
              res.data.forEach((value, key) => {
                
                if (value.status === 1) {
                  this.numPages.push({id:key+1,ruta:this.url + value.archivo})
                }
              });           
            }
            
          })
          .catch((e) => {
            this.descargando = false;

            this.$alert("Error al descargar el archivo ", e);
          });
        // }
      } else {
        // let pdf = c.ruta.split(".")[1]
        // if(pdf && pdf == 'pdf')
        // {
        //   this.descargando = c.id;

        //   this.url = "https://cdn.log-in.com.ar/escaner/";
        //   this.ruta = this.url + c.ruta;
        //   this.numPages.push(
        //   {
        //     id: c.hojas,
        //     ruta: this.ruta
        //   })
          
        // }else{
          this.$alert("El archivo está dañado o no existe ");
        // }
      
      }
    },
    openFoto(url) {
      window.open(url, "_blank");
    },
    changeAct(file)
    {
        // alert('change')
        this.file = file
        this.fileList.push(file)
    },
    uploadPdf(param)
    {
        this.mostrar_barra = true;
        if(!this.no_corresponde)
        {
            this.progressPercent =  0
            // let datos = []
            // if(this.datos.length)
            // {
            //     datos.push({comprobante:this.datos[0].comprob,url:this.datos[0].url})
            // }
            let objetoDatos     =  typeof JSON.stringify(this.datos_web_escaneados[0]) == 'undefined' ? null : JSON.stringify(this.datos_web_escaneados[0]);
            let ol_comprob      =  typeof JSON.stringify(this.datos_olcomprob[0])== 'undefined' ? null :JSON.stringify(this.datos_olcomprob[0])
            let empresa         = this.empresaUrl == 'u' ? 'DSA' : 'LOG'
            let carpeta_guardar = "comprobantes_subidos/"+this.$moment().format('DD-MM-YYYY')+"/"+this.datos_olcomprob[0]['convol']+"/"+param.file['name']
            const dataForm = new FormData()
            dataForm.append('upload', param.file)
            dataForm.append("operador", this.operador);
            dataForm.append("web_escaneados", objetoDatos);
            dataForm.append("ol_comprob", ol_comprob);
            dataForm.append("empresa", empresa);
            dataForm.append("carpeta", carpeta_guardar.toString());
            if(this.datos_web_escaneados.length)
            {
                if(typeof this.datos_web_escaneados[0].ruta !='undefined')
                {
                    dataForm.append("url", this.datos_web_escaneados[0].ruta);
                }
            }
            // console.log(dataForm)
            HTTP.post("/escanerPrueba/uploadPdf", dataForm).then(
            (res) => {
                switch (res.data) {
                    case 1:
                        this.$alert(param.file.name, 'Carga exitosa', {
                        confirmButtonText: 'OK',
                        type: 'success'
                        });
                        this.progressPercent =  100;
                        setTimeout(() => {
                            this.progressPercent =  0
                        }, 3000);
                        this.onSubmit('formInline')
                        break;
                    case 0:
                        this.$alert('No se pudo cargar: ' + param.file.name, 'Error', {
                        confirmButtonText: 'OK',
                        type: 'error'
                        });
                        break;
                    case 2:
                        this.$alert('El archivo ya existe: ' + param.file.name, 'Existe', {
                        confirmButtonText: 'OK',
                        type: 'warning'
                        });
                        break;
                }
                
            });        
        }else{
            // this.fileList.find(elemento => elemento.)
            alert('no envías')
        }
    },       
    errorShow()
    {
        alert('error al subir')
    },
    beforeUpload(file) {
        this.no_corresponde = false
        const testmsg       = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension     = testmsg === 'pdf'
    // const isLt2M = file.size / 1024 / 1024 < 10        
        return extension;
    },
    handleChange(file, fileList) {
        let estado = this.beforeUpload(file)
        if(!estado)
        {
            this.$message({
            message: '¡Los archivos cargados solo pueden estar en formato .pdf!',
            type: 'warning'
            })
            this.no_corresponde = true;
        }else
        {
            this.fileList.push(file)
        }
         console.log(file,fileList)
        // this.fileList = fileList.slice(1,);
      },
	eliminarElemento(file, fileList) {
        console.log(file,fileList)
        this.fileList = fileList
    }
    },
}
</script>
<style scoped>
.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #04AA6D;
  /* padding: 14px 28px; */
  /* font-size: 12px; */
  cursor: pointer;
  text-align: center;
}
</style>