<template>
  <div>
    <el-row style="border-bottom: 1px solid #ddd; text-align: center">
      <el-col :md="24">
        <p style="margin: 0px !important" :style="styleTitleTabla" class="bold">Total de Presentaciones:{{ cantidad_elementos }} {{ msj }}</p>
      </el-col>
    </el-row>
    <el-row style="padding: 0px 5px 0px 5px">
      <el-table
        :data="tablaPres"
        style="width: 100%"
        border
        stripe
        fit
        size="mini"
        height="550"
        ref="tableViewBox"
        v-loading="cargando"
        element-loading-text="cargando resultados..."
        element-loading-spinner="el-icon-loading"
        @row-click="testVueRoute"
        :empty-text="vacio">
        <el-table-column prop="nropres" label="#" width="90"></el-table-column>

        <el-table-column prop="fchpres" label="Fecha" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.fchpres">{{ formatFecha(scope.row.fchpres.date) }} </span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column prop="cant" label="Cant. Guías" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.cant_guia">{{ scope.row.cant_guia }} </span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column prop="sucorigen" label="Sucursal Origen" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.sucorigen">{{ scope.row.nombre }} </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="olconv" label="Convenio">
          <template slot-scope="scope">
            <span v-if="scope.row.olconv">{{ scope.row.olconv }} - {{ scope.row.convenio }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="observac" label="Observaciones">
          <template slot-scope="scope">
            <span v-if="scope.row.observac">{{ scope.row.observac }} </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="anulafec" label="Fecha Anul." width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.anulafec">{{ formatFecha(scope.row.anulafec.date) }} </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="Acción" width="70">
          <img src="@/assets/ico_preview.png" alt="Ver guias" />
        </el-table-column>
      </el-table>
    </el-row>
    <el-pagination
      v-if="cantidad_elementos"
      background
      layout="total,prev, pager, next"
      :total="cantidad_elementos"
      :page-size="50"
      style="margin-top: 20px; float: right"
      @current-change="nextPage(pagina_actual)"
      :current-page.sync="pagina_actual">
    </el-pagination>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'
// import { HTTP } from '@/axios'
import { API } from '@/plugins/api'

const moment = require('moment')
export default {
  props: ['postTitle'],
  data() {
    return {
      datos: JSON.parse(window.localStorage.getItem('datos')),
      cantidad_elementos: 0,
      imgEstado: '',
      cargando: false,
      tablaPres: [],
      pagina_actual: '',
      vacio: 'Sin Datos',
      msj: '',
      styleTitleTabla: {
        textAlign: 'center',
        padding: '3px',
        color: 'white',
        fontSize: '15px',
      },
      empresa: window.localStorage.getItem('empresa'),
      parametrosBusqueda: {},
      fechasShow: [],
    }
  },
  created() {
    this.styleTitleTabla['backgroundColor'] = this.empresa == 'u' ? '#0b406a' : '#0b406a'
  },

  mounted() {
    EventBus.$on('cargando', () => {
      this.cargando = true
    })
    EventBus.$on('cargarPresentaciones', (g) => {
      if (this.$refs.tableViewBox && this.$refs.tableViewBox.bodyWrapper) {
        this.$refs.tableViewBox.bodyWrapper.scrollTop = 0
      }
      this.cargando = false

      /* Si vienen elementos cargo la tabla */
      if (g.tablaP.total > 0) {
        // this.tablaPres = g.tablaP.data
        this.parametrosBusqueda = g.parametros
        this.fechasShow = g.fechasShow
        this.cantidad_elementos = g.tablaP.total
        this.pagina_actual = g.tablaP.current_page
        this.vacio = ''
        this.msj = '- Fecha: ' + g.fechaIni + ' - ' + g.fechaFin

        this.tablaPres = g.tablaP.data.map(function (obj) {
          obj.convenio = g.convenios.find((element) => element.olconv === obj.olconv).descrip
          return obj
        })
      } else {
        this.tablaPres = []
        this.cantidad_elementos = 0
        this.pagina_actual = ''
        if (g.fechaIni != '' && g.fechaFin) {
          this.vacio = 'Sin datos desde ' + g.fechaIni + ' - ' + g.fechaFin
          this.msj = '- Fecha: ' + g.fechaIni + ' - ' + g.fechaFin
        } else {
          this.vacio = 'Sin datos'
          this.msj = ''
        }
        this.cargando = false
      }
    })
    EventBus.$on('cargarPresentaciones', (g) => {
      if (this.$refs.tableViewBox && this.$refs.tableViewBox.bodyWrapper) {
        this.$refs.tableViewBox.bodyWrapper.scrollTop = 0
      }
      this.cargando = false

      /* Si vienen elementos cargo la tabla */
      if (g.tablaP.total > 0) {
        // this.tablaPres = g.tablaP.data
        this.parametrosBusqueda = g.parametros
        this.fechasShow = g.fechasShow
        this.cantidad_elementos = g.tablaP.total
        this.pagina_actual = g.tablaP.current_page
        this.vacio = ''
        this.msj = '- Fecha: ' + g.fechaIni + ' - ' + g.fechaFin

        this.tablaPres = g.tablaP.data.map(function (obj) {
          obj.convenio = g.convenios.find((element) => element.olconv === obj.olconv).descrip
          return obj
        })
      } else {
        this.tablaPres = []
        this.cantidad_elementos = 0
        this.pagina_actual = ''
        if (g.fechaIni != '' && g.fechaFin) {
          this.vacio = 'Sin datos desde ' + g.fechaIni + ' - ' + g.fechaFin
          this.msj = '- Fecha: ' + g.fechaIni + ' - ' + g.fechaFin
        } else {
          this.vacio = 'Sin datos'
          this.msj = ''
        }
        this.cargando = false
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('cargando', 0)
    EventBus.$off('cargarPresentaciones')
  },
  methods: {
    /* Formatos para tabla */
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    /* Renombrar evento */
    nextPage(pagina) {
      this.parametrosBusqueda = {
        ...this.parametrosBusqueda,
        page: pagina,
      }
      this.cargando = true
      API.post('/presentaciones/getPresentaciones', this.parametrosBusqueda)
        .then((response) => {
          if (response.status === 200) {
            this.tablaPres = response.data.data.data
            this.cantidad_elementos = response.data.data.total
            this.pagina_actual = response.data.data.current_page
          }
          this.cargando = false
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
          this.cargando = false
        })
      // EventBus.$emit('newPresentaciones', page)
      // this.pagina_actual = page
      // return false
    },
    testVueRoute(row) {
      window.localStorage.setItem('pres', JSON.stringify(row.nropres))
      // this.$router.push({path: '/guias' });
      if (this.empresa == 'u') {
        window.open('https://seguimiento.suizoargentina.com.ar/#/guias', '_blank')
        //  window.open('http://localhost:8080/#/usuarios?e=lo', "_blank");
      } else {
        window.open('https://seguimiento.log-in.com.ar/#/guias', '_blank')
        //  window.open('http://localhost:8080/#/usuarios?e=u', "_blank");
      }
    },
  },
}
</script>
<style scoped>
.el-table th {
  background: #f0f2f5 !important;
  border: 1px solid #f0f2f5 !important;
  font-weight: normal;
  color: #000;
}
</style>
