<template>
  <div>
    <el-row :gutter="10"
      ><center>
        <span><i class="el-icon-download"></i> OBTENER PDF ESCANEADOS</span>
      </center>
      <hr
    /></el-row>
    <el-row :gutter="20">
      <el-col :md="12">
        <el-card
          shadow="never"
          :body-style="{ padding: '5px 10px', minHeight: '500px' }">
          <div slot="header">
            <h3><center>Cargar excel de comprobantes</center></h3>
          </div>
          <el-row :gutter="10"
            ><el-col>
              <el-alert
                title="Importante!"
                type="info"
                effect="dark"
                description="El limite máximo de descarga es de 200 comprobantes por archivo."
                :closable="false">
              </el-alert>
              <br />
              <el-row :gutter="20">
                <el-col :md="24">
                  <el-form>
                    <el-form-item style="text-align: center">
                      <el-upload
                        ref="upload"
                        class="upload-demo"
                        action="#"
                        :http-request="UploadImage"
                        accept=".xlsx, .xls">
                        <div slot="tip" class="el-upload__tip">
                          Solo archivos xlsx/xls
                        </div>
                        <el-button
                          size="mini"
                          type="success"
                          style="
                            font-family: sans-serif !important;
                            font-size: 13px;
                            padding: 10px 150px;
                            width: 100% !important;
                          "
                          :loading="cargando">
                          CARGAR EXCEL
                          <i class="el-icon-upload el-icon-right"></i
                        ></el-button>
                      </el-upload>
                    </el-form-item>
                    <el-form-item
                      label="Progreso de carga de archivo actual"
                      style="margin-bottom: 0px">
                      <el-progress
                        :text-inside="true"
                        :stroke-width="15"
                        :percentage="progressPercent" />
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row> </el-col
          ></el-row>
        </el-card>
      </el-col>
      <el-col :md="12">
        <el-card
          shadow="never"
          :body-style="{ padding: '5px 10px', minHeight: '500px' }">
          <div slot="header">
            <h3><center>Archivos cargados previamente</center></h3>
          </div>
          <el-row :gutter="20"
            ><el-col>
              <div v-if="zip_guardados.length">
                <el-table
                  :data="zip_guardados"
                  :border="true"
                  style="width: 100%"
                  height="500"
                  size="mini"
                  v-loading="loading_tabla">
                  <el-table-column prop="fecha" label="Fecha" width="150">
                    <template slot-scope="scope">
                      <div v-if="scope.row.fecha">
                        <span v-if="scope.row.fecha.date">{{
                          scope.row.fecha.date | moment('DD-MM-YYYY HH:mm:ss')
                        }}</span>
                        <span v-else-if="scope.row.fecha">{{
                          scope.row.fecha.date
                        }}</span>
                        <span v-else>-</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="nombre" label="Nombre archivo">
                  </el-table-column>
                  <el-table-column label="" width="120">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="danger"
                        @click="downloadZip(scope.row)"
                        icon="el-icon-download"
                        >Descargar</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  v-if="zip_guardados.length"
                  style="
                    margin-bottom: 20px;
                    margin-left: 10px;
                    float: right;
                    margin-top: 10px;
                  "
                  background
                  layout="total,prev, pager, next"
                  :total="zip_guardados[0]['TotalRows']"
                  :page-size="20"
                  @current-change="getArchivos(pagina_actual)"
                  :current-page.sync="pagina_actual">
                </el-pagination>
              </div>
              <div v-else>
                <h5><center>No hay archivos cargados</center></h5>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import readXlsxFile from 'read-excel-file'
import { HTTP } from '@/axios'

export default {
  data() {
    return {
      fileList: [],
      file: '',
      progressPercent: 0,
      zip_guardados: [],
      id_operador: JSON.parse(window.localStorage.getItem('datos')).uid,
      loading_tabla: false,
      descargar: false,
      cargando: false,
      pagina_actual: 1,
    }
  },
  mounted() {
    this.getArchivos(1)
  },
  methods: {
    downloadZip(datos) {
      if (datos != '') {
        /* Antes de descargar controlar que existe el PDF */
        HTTP.post('/uploadExcel/controlZip', datos)
          .then((res) => {
            /* Si no existe pedir que vuelva a cargarlo y cambiar el estado del elemento en la BD */
            /* sino descargar */
            if (res.data == true) {
              window.location.href =
                'https://seguimiento.suizoargentina.com.ar/seguimiento/public/' +
                datos.path
            } else {
              this.$message.error(
                'Oops, el archivo .zip fue eliminado. Por favor vuelva a subir el archivo.'
              )
              this.zip_guardados = this.zip_guardados.filter(
                (item) => item.id !== datos.id
              )
            }
          })
          .catch((error) => {
            this.$alert('Error del servidor ' + error)
            this.loading_tabla = false
          })
      } else {
        this.$message('El archivo no existe')
      }
    },
    getArchivos(pagina) {
      this.loading_tabla = true
      HTTP.post('/uploadExcel/getFileZip', {
        operador: this.id_operador,
        pagina: pagina,
      })
        .then((res) => {
          this.loading_tabla = false
          if (res.data != '') {
            this.zip_guardados = res.data
          }
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
          this.loading_tabla = false
        })
    },
    UploadImage(param) {
      let existe = 0
      // antes de cargar controlar si no existe en BD y en el servidor el zip
      if (this.zip_guardados.length) {
        existe = this.zip_guardados.find((ab) => ab.nombre == param.file.name)
      }

      if (!existe) {
        this.cargando = true
        const formdata = new FormData()
        formdata.append('upload', param.file)
        formdata.append('operador', this.id_operador)
        /* CONTROLAR EL TAMAÑO DEL ARCHIVO */
        let testmsg = param.file.name.substring(
          param.file.name.lastIndexOf('.') + 1
        )
        let extension = testmsg === 'xlsx' || testmsg === 'xls'
        const isLt2M = param.file.size < 400000
        if (!extension || !isLt2M) {
          this.cargando = false
          if (!extension) {
            this.$message({
              message:
                '¡Los archivos cargados solo pueden estar en formato .xlsx o .xls!',
              type: 'warning',
            })
          }
          if (!isLt2M) {
            this.$message({
              message: 'El archivo cargado es muy pesado para ser procesado',
              type: 'error',
            })
          }
        } else {
          HTTP.post('/uploadExcel/uploadExcel', formdata, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then((res) => {
              this.cargando = false
              this.progressPercent = 100
              switch (res.data) {
                case 0:
                  this.progressPercent = 0
                  alert('No se puede descargar')
                  break
                case 99:
                  this.$message.error(
                    'El archivo debe contener la columna "Comprobante" o "Comprobantes".'
                  )
                  this.progressPercent = 0
                  break
                case -1:
                  this.$message.error(
                    'No se guardaron correctamente los datos. Intente nuevamente.'
                  )
                  this.progressPercent = 0
                  break
                default:
                  if (typeof res.data != 'undefined') {
                    if (res.data.descargar) {
                      window.location.href = res.data.url
                      this.getArchivos(1)
                      this.progressPercent = 0
                    } else {
                      this.$notify.error({
                        title: 'Error',
                        message: 'Error al cargar el archivo',
                      })
                      this.progressPercent = 0
                    }
                  } else {
                    this.$notify.error({
                      title: 'Error',
                      message: 'Error al cargar el archivo',
                    })
                    this.progressPercent = 0
                  }

                  break
              }
            })
            .catch((error) => {
              console.log(error)
              this.$notify.error({
                title: 'Error',
                message: 'Error al cargar el archivo',
              })
            })
        }
      } else {
        this.$message({
          message: 'El archivo ya se encuentra disponible para descargar.',
          type: 'warning',
        })
        this.progressPercent = 0
        this.cargando = false
      }
    },
    changeAct(file) {
      let estado = this.beforeUpload(file)
      if (!estado) {
        this.$message({
          message:
            '¡Los archivos cargados solo pueden estar en formato .xlsx o .xls!',
          type: 'warning',
        })
        this.no_corresponde = true
      } else {
        let existe = -1
        if (this.zip_guardados.length) {
          existe = this.zip_guardados.findIndex(
            (ab) => ab.nombre == file.raw.name
          )
          if (existe == -1) {
            this.fileList.push(file)
          }
        } else {
          this.fileList.push(file)
        }
      }
    },
    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xlsx' || testmsg === 'xls'
      // const isLt2M = file.size < 400000
      if (!extension) {
        this.$message({
          message:
            '¡Los archivos cargados solo pueden estar en formato .xlsx o .xls!',
          type: 'warning',
        })
      }
      return extension
    },
  },
}
</script>
<style scoped>
.el-divider--vertical {
  display: inline-block;
  width: 1px;
  height: 40rem;
  margin: 0 8px;
  vertical-align: middle;
  position: relative;
}
.el-upload__tip {
  font-size: 11px;
  margin-top: -5px;
}
</style>
