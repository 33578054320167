<template>
  <div class="vstock_buscar">
    <el-row :gutter="10">
      <el-col :span="24">
        <el-button
          type="success"
          style="float: right; margin-bottom: 5px"
          @click="MovimientoConsultaDSA"
          plain
          size="mini"
          icon="el-icon-download"
          :loading="loadDescargar">
          Descargar Excel Movimientos
        </el-button>
      </el-col>
    </el-row>
    <el-row
      style="border-bottom: 1px solid #ddd; padding-bottom: 8px"
      :gutter="10">
      <el-col :span="24">
        <h4 style="font-size: 24px; margin: -3px">
          Consulta de movimientos:
          <span v-if="titulo != ''">" {{ titulo }} " </span><span v-else></span>
        </h4>
      </el-col>
    </el-row>
    <el-form
      label-position="top"
      :model="formMovimiento"
      ref="formMovimiento"
      class="demo-ruleForm">
      <el-row :gutter="10">
        <el-col :md="6" :xs="12">
          <el-form-item label="Buscar movimiento">
            <el-input
              v-model="formMovimiento.campo_unico"
              size="mini"
              clearable
              placeholder="Buscar por...(mov.,lote,cod. proveedor,descripción,cod suizo..)"
              ref="empieza_con"
              @keypress.native.enter="buscarMov(1)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6" :xs="12">
          <el-form-item label="Sucurales">
            <el-select
              v-model="formMovimiento.sucursal"
              size="mini"
              placeholder="Seleccionar sucursal"
              clearable
              class="vpim-select">
              <el-option
                v-for="item in sucursales"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="6" :xs="12">
          <el-form-item label="Fecha">
            <el-date-picker
              size="mini"
              style="width: 100%"
              clearable
              v-model="formMovimiento.fecha"
              format="dd-MM-yyyy"
              value-format="yyyyMMdd"
              type="daterange"
              start-placeholder="Desde"
              end-placeholder="Hasta"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="6" :xs="6">
          <center>
            <el-form-item style="margin-top: 40px">
              <el-button-group>
                <el-button
                  type="primary"
                  @click="buscarMov(1)"
                  icon="el-icon-search"
                  size="mini"
                  :loading="loadingbtn"
                  >Buscar</el-button
                >
                <el-button
                  @click="resetForm(1)"
                  size="mini"
                  icon="el-icon-close"
                  :disabled="loadingbtn"
                  >Limpiar</el-button
                >
              </el-button-group>
            </el-form-item>
          </center>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
import { EventBus } from '@/event-bus'
const moment = require('moment')

export default {
  data() {
    return {
      formMovimiento: {
        campo_unico: '',
        sucursal: '',
        pagina: 1,
        laborat: JSON.parse(window.localStorage.getItem('datos')).laborat,
        fecha: [],
      },
      loadingbtn: false,
      titulo: '',
      sucursales: [
        { label: 'TUCUMÁN', value: 'TUCUMAN' },
        { label: 'PIEDRABUENA', value: 'PIEDRABUENA' },
        { label: 'CÓRDOBA', value: 'CORDOBA' },
        { label: 'JUNIN', value: 'JUNIN' },
        { label: 'TOM-LOGIN ACONDICIONAMIENTO', value: 'TOM-LOGIN' },
        { label: 'IMPORTADORA PB ACONDICIONAMIENTO', value: 'IMPORTADORA' },
      ],
      loadDescargar: false,
      pagina: 1,
      pickerOptions: {
        shortcuts: [
          {
            text: 'Últ. Semana',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Últ. Mes',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '3 Últ. meses',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  mounted() {
    this.$refs.empieza_con.focus()
    EventBus.$on('cambiarPaginaMov', (g) => {
      this.buscarMov(g)
    })
  },
  beforeDestroy() {
    EventBus.$off('cambiarPaginaMov', false)
  },
  methods: {
    buscarMov(pagina) {
      this.loadingbtn = true
      this.formMovimiento.pagina = pagina
      this.titulo =
        this.formMovimiento.campo_unico + this.formMovimiento.sucursal
      EventBus.$emit('cargandoTablaMovimientos')
      if (this.formMovimiento.fecha.length == 0) {
        this.formMovimiento.fecha = [
          new Date(moment().subtract(30, 'days')),
          new Date(),
        ]
      }
      // console.log(this.formMovimiento.fecha)
      HTTP.post('/vlogisticanew/movDSA_prueba', this.formMovimiento)
        .then((res) => {
          // console.log(res.data)
          var $respuesta = []
          if (res.data != 0) {
            $respuesta = res.data
          }
          // console.log($respuesta)
          EventBus.$emit('cargarTablaMovimiento', $respuesta)
          this.loadingbtn = false
        })
        .catch((error) => {
          this.loadingbtn = false
          EventBus.$emit('cargarTablaMovimiento', [])
          console.log(error)
        })
    },
    MovimientoConsultaDSA() {
      this.loadDescargar = true
      HTTP.post('/vlogisticanew/MovimientoConsultaDSA', this.formMovimiento)
        .then((res) => {
          this.loadDescargar = false
          // console.log(res.data)
          if (Number.isInteger(res.data)) {
            this.numberTitle = res.data
          } else if (res.data != '') {
            window.location.href = res.data
          } else {
            this.$message({
              message: 'No hay datos para descargar.',
              type: 'warning',
            })
          }
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
        })
    },
    resetForm() {
      this.formMovimiento.campo_unico = ''
      this.formMovimiento.sucursal = ''
      this.formMovimiento.fecha = []
      this.titulo = ''
      setTimeout(() => {
        EventBus.$emit('limpiarMov')
      }, 500)
    },
  },
}
</script>
<style scoped>
.el-form-item {
  margin-bottom: 10px !important;
}
</style>
