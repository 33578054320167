<template>
  <el-dialog
    :visible.sync="modal.visible"
    width="80%"
    @closed="cerrarForm()"
    :close-on-click-modal="false"
    :close-on-press-escape="true"
    :destroy-on-close="false"
    :show-close="false"
    top="3vh">
    <span slot="title">
      <el-alert
        center
        :closable="false"
        effect="dark"
        style="background-color: #e5590a">
        <span slot="title">
          <h2>Turno #{{ modal.row.id }} - {{ modal.row.convenio.descrip }}</h2>
        </span>
        <h3 style="text-align: center">
          <i class="far fa-calendar"></i> {{ modal.row.fecha }} |
          <i class="far fa-clock"></i> {{ modal.row.hora_inicial }}
        </h3>
        <h3
          style="text-align: center"
          v-if="modal.row.estado > 2 && modal.row.estado <= 4">
          Recepción en NAVE {{ modal.row.nave }} - Dock Nº
          {{ modal.row.dock.numero }}
        </h3>
      </el-alert>
    </span>
    <el-form size="small" label-width="170px" v-loading="config.loading">
      <!-- Recuadro ESTADO -->
      <el-row>
        <el-alert type="info" center :closable="false">
          <span slot="title">
            <qrcode-vue :value="this.modal.row.qr" size="300" level="H" />
          </span>
        </el-alert>
        <el-alert type="info" center :closable="false">
          <span slot="title">
            <br />
            <h1 style="font-size: 3em; color: black">
              {{
                this.modal.row.qr.slice(0, 3) +
                ' - ' +
                this.modal.row.qr.slice(3)
              }}
            </h1>
            <br />
          </span>
        </el-alert>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="danger"
        size="medium"
        @click="cerrarForm()"
        icon="el-icon-close"
        >Cancelar</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'qr-modal',
  props: {
    modal: { required: true },
  },
  components: {
    QrcodeVue,
  },
  mounted() {
    this.config.loading = false
  },
  data() {
    return {
      qrVisible: true,
      config: {
        loading: true,
        icon: null,
        titulo: null,
        hora_inicial: true,
      },
    }
  },
  methods: {
    cerrarForm() {
      this.$events.fire('close-qr')
    },
  },
}
</script>
