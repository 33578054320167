<template>
  <div>
    <div v-if="rowData.logs.length > 0">
      <el-button
        size="mini"
        @click="detalles(rowData.logs)"
        icon="el-icon-zoom-in"
        type="primary"
        circle />
    </div>
    <div v-else>
      <el-tooltip
        class="item is-disabled"
        effect="dark"
        content="Sin logs"
        placement="top-start">
        <el-button
          size="mini"
          icon="el-icon-zoom-in"
          type="primary"
          circle></el-button>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guiasActions',
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },
  methods: {
    detalles(value) {
      this.$events.fire('mostrar-detalles-modal', value)
    },
  },
}
</script>
