<template>
  <div
    class="body-back bg-s"
    style="overflow-y: scroll"
    v-loading="cargandoPage"
    element-loading-text="Cargando..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <img :src="img_header" class="img-banner" fit="contain" alt="Logo Empresa" />
    <div v-if="!showMensaje">
      <el-row :gutter="10" class="hidden-xs-only">
        <el-col :lg="{ span: 12, offset: 6 }" :md="{ span: 12, offset: 5 }" :sm="{ span: 10, offset: 7 }" :xs="{ span: 22, offset: 1 }">
          <center>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <h2>Cambiar contraseña</h2>
                <span>El usuario debe cambiar de contraseña</span>
              </div>
              <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item label="Contraseña Actual" prop="password_old">
                  <el-input type="password" prop="password_old" v-model="ruleForm.password_old" autocomplete="off" clearable show-password :disabled="btnActivo"
                    ><template slot="prepend"><i class="el-icon-lock"></i></template
                  ></el-input>
                </el-form-item>
                <el-form-item label="Nueva Contraseña" prop="password">
                  <el-input type="password" prop="password" v-model="ruleForm.password" autocomplete="off" clearable show-password :disabled="btnActivo"
                    ><template slot="prepend"><i class="el-icon-lock"></i></template
                  ></el-input>
                </el-form-item>
                <el-form-item label="Repetir Contraseña" prop="password_confirmation">
                  <el-input v-model="ruleForm.password_confirmation" prop="password_confirmation" autocomplete="off" clearable show-password :disabled="btnActivo"
                    ><template slot="prepend"><i class="el-icon-lock"></i></template
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="success" @click="submitForm('ruleForm')" icon="el-icon-check" :loading="btnActivo">CAMBIAR</el-button>
                  <el-button @click="regresar()" icon="el-icon-close">CANCELAR</el-button>
                </el-form-item>
              </el-form>
            </el-card>
          </center>
        </el-col>
      </el-row>
      <el-row class="hidden-sm-and-up">
        <el-col :xs="20" :offset="2"
          ><center>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <h2>Cambiar contraseña</h2>
                <span>El usuario debe cambiar de contraseña</span>
              </div>
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item label="Contraseña Actual" prop="password_old">
                  <el-input type="password" prop="password_old" v-model="ruleForm.password_old" autocomplete="off" clearable show-password
                    ><template slot="prepend"><i class="el-icon-lock"></i></template
                  ></el-input>
                </el-form-item>
                <el-form-item label="Nueva Contraseña" prop="password">
                  <el-input type="password" prop="password" v-model="ruleForm.password" autocomplete="off" clearable show-password
                    ><template slot="prepend"><i class="el-icon-lock"></i></template
                  ></el-input>
                </el-form-item>
                <el-form-item label="Repetir Contraseña" prop="password_confirmation">
                  <el-input v-model="ruleForm.password_confirmation" prop="password_confirmation" autocomplete="off" clearable show-password
                    ><template slot="prepend"><i class="el-icon-lock"></i></template
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="success" @click="submitForm('ruleForm')" icon="el-icon-check">CAMBIAR</el-button>
                  <el-button @click="regresar()" icon="el-icon-close">CANCELAR</el-button>
                </el-form-item>
              </el-form>
            </el-card>
          </center>
        </el-col>
      </el-row>
    </div>
    <div v-if="showMensaje">
      <el-row :gutter="10" style="padding-top: 50px">
        <el-col :lg="{ span: 12, offset: 6 }" :md="{ span: 12, offset: 5 }" :sm="{ span: 10, offset: 7 }" :xs="{ span: 22, offset: 1 }">
          <el-card class="box-card">
            <!-- <img :src="imgResultado" alt="resultado" /> -->
            <h3>Nueva contraseña</h3>
            <hr />
            <p>Su contraseña se actualizó correctamente. Regrese a la vista para ingresar al Sistema.</p>
            <p>Muchas gracias.</p>
            <hr />
            <el-button :type="showbtnC" @click="regresar()"><i class="el-icon-back"></i>Regresar</el-button></el-card
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { API } from '@/plugins/api'
import jwt_decode from 'jwt-decode'

export default {
  name: 'Expirar',
  props: {
    dataInfo: {
      required: true,
    },
  },
  data() {
    var validatePassOld = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('Por favor ingrese una contraseña'))
      } else {
        if (this.ruleForm.password_old.length < 8 || this.ruleForm.password_old.length > 25) {
          callback(new Error('El tamaño de contraseña es incorrecto.'))
        }
        callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('Por favor ingrese una contraseña'))
      } else {
        if (this.ruleForm.password.length < 6 || this.ruleForm.password.length > 25) {
          callback(new Error('El tamaño de contraseña es incorrecto.'))
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('Por favor repita la contraseña'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('Las contraseñas no coinciden'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        password_old: [{ validator: validatePassOld, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        password_confirmation: [{ validator: validatePass2, trigger: 'blur' }],
      },
      img_header: require('@/assets/SuizoBlanco.svg'),
      titulo: 'Suizo Argentina S.A.',
      imgFavicon: 'favicon_suizo.ico',
      ruleForm: {
        uid: null,
        password_old: null,
        password: null,
        password_confirmation: null,
      },
      controlBtn: false,
      showMensaje: false,
      // imgResultado: require('@/assets/002-comprobado-1.png'),
      btnActivo: false,
      cargandoPage: true,
      info_decode: null,
    }
  },
  metaInfo() {
    return {
      title: this.titulo,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        {
          name: 'description',
          content: 'An example Vue application with vue-meta.',
        },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css?family=Roboto:300,300i,500,700,900i&display=swap',
        },
        { rel: 'shortcut icon', type: 'image/png', href: this.imgFavicon },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Varela+Round&display=swap',
        },
      ],
    }
  },
  created() {
    /* Tomar los datos de props */
    if (JSON.stringify(this.$route.params) == '{}') {
      this.$router.push('/login') //o sea anda paya, juera de aqui
    } else {
      this.info_decode = jwt_decode(this.dataInfo)
    }
    /* Imagen segu url y btn */
    let urlpp = window.location.host,
      array_url = urlpp.split('/'),
      empresa_nombre = array_url[0].split('.')
    if (process.env.NODE_ENV !== 'development') {
      if (empresa_nombre[1] == 'suizoargentina') {
        this.img_header = require('@/assets/SuizoBlanco.svg')
        this.titulo = 'Suizo Argentina S.A.'
        this.imgFavicon = 'favicon_suizo.ico'
        this.showbtnC = 'primary'
        this.empresa = 'u'
      } else if (empresa_nombre[1] == 'log-in') {
        this.titulo = 'LOG-IN'
        this.img_header = require('@/assets/loginfarma.png')
        this.imgFavicon = 'favicon_log.png'
        this.showbtnC = 'warning'
        this.empresa = 'lo'
        this.showError = true
      } else {
        this.$router.push({ name: 'PageNotFound' })
      }
      setTimeout(() => {
        this.cargandoPage = false
      }, 500)
    } else if (empresa_nombre[1] == 'log-in') {
      this.titulo = 'LOG-IN'
      this.img_header = require('@/assets/loginfarma.png')
      this.imgFavicon = 'favicon_log.png'
      this.showbtnC = 'warning'
      this.empresa = 'lo'
      this.showError = true
      setTimeout(() => {
        this.cargandoPage = false
      }, 500)
    } else {
      //  this.$router.push({name:'PagError'})
      if (process.env.VUE_APP_EMPRESA === 'suizo') {
        this.titulo = 'Suizo Argentina S.A.'
        this.img_header = require('@/assets/SuizoBlanco.svg')
        this.imgFavicon = 'favicon_suizo.ico'
        this.showbtnC = 'primary'
        this.empresa = 'u'
      } else {
        this.titulo = 'LOG-IN'
        this.img_header = require('@/assets/loginfarma.png')
        this.imgFavicon = 'favicon_log.png'
        this.showbtnC = 'warning'
        this.empresa = 'lo'
      }
      setTimeout(() => {
        this.cargandoPage = false
      }, 500)
    }
  },
  methods: {
    submitForm(formName) {
      this.btnActivo = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.uid = this.info_decode.data.session
          this.ruleForm.empresa = this.info_decode.data.empresa === 'LOG' ? 'lo' : 'u'
          API.post('/auth/expiraPass', this.ruleForm)
            .then((response) => {
              if (response.data.status == '200') {
                this.$message({
                  message: 'Se actualizó su contraseña.',
                  type: 'success',
                })
                this.showMensaje = true
              }
            })
            .catch((error) => {
              this.$message.error('Algunos de los datos ingresados es incorrecto.')
              console.log(error)
            })
          this.btnActivo = false
        } else {
          this.btnActivo = false
          this.$message.error('Controle que los campos sean correctos.')
          return false
        }
      })
    },
    regresar() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({ name: 'login' })
      })
      //FIN VUEX
    },
  },
}
</script>
<style>
.bg-s {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #66b1ff !important;
  background-image: linear-gradient(315deg, #3a638d 0%, #2a2b2d 74%);
  z-index: -1;
  transition: 850ms !important;
  /* margin-top: 20%; */
}
.body-back {
  /* background: linear-gradient(96deg, #FFFAFA 0, #E5EBF6 64%); */
  overflow: hidden;
  height: 100%;
  text-align: center;
  font-family: 'Roboto';
}

.img-banner {
  padding-top: 20px;
  width: 290px;
  padding-bottom: 20px;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
</style>
