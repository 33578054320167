import axios from 'axios'

// En produccion `https://seguimiento.suizoargentina.com.ar/seguimiento`
// En desarrollo `http://localhost/seguimiento`
// Modificar .env para cambiar
export const baseUrl = process.env.VUE_APP_AXIOS_BASE_URL
export const baseUrl_remitos = process.env.VUE_APP_AXIOS_BASE_URL_REMITO
export const baseUrlDesarrollo = process.env.VUE_APP_AXIOS_BASE_URL_DESARROLLO

export const HTTP = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  },
  crossDomain: true,
})
export const HTTP_REMITO = axios.create({
  baseURL: baseUrl_remitos,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  },
  crossDomain: true,
})
export const HTTP_DESARROLLO = axios.create({
  baseURL: baseUrlDesarrollo,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  },
  crossDomain: true,
})
