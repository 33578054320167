<template>
  <div class="container-fluid">
    <!-- Titulo para tabla de Guías en Hoja de rutas -->
    <el-row :gutter="10" v-if="hjruta != ''" :style="styleTitleTabla">
      <center>
        <el-col :md="24">
          <p style="margin: 5px !important" class="font-title">
            Detalle de Guías asociadas a la hoja de ruta
            <strong style="font-style: oblique"> {{ hjruta }}</strong
            >- Total de Guías:{{ cantidad_elementos }} {{ fechaBusc }}

            <el-button-group style="float: right">
              <el-tooltip placement="bottom">
                <div slot="content">Exportar Guía</div>
                <el-button
                  type="primary"
                  style="border-color: #409eff; padding: 3px; margin-bottom: 5px; font-size: 11px"
                  size="mini"
                  @click="exportarGuias"
                  :loading="export_loading"
                  :disabled="disabledExp"
                  ><strong><i class="el-icon-download"></i> Excel Guía</strong>
                </el-button>
              </el-tooltip>
            </el-button-group>
          </p>
        </el-col>
      </center>
    </el-row>
    <!-- Titulo para tabla de Guías en Guías -->
    <el-row :gutter="10" v-else>
      <center>
        <el-col :md="24">
          <p class="font-title" :style="styleTitleTabla">Total de Guías:{{ cantidad_elementos }} {{ fechaBusc }}</p>
        </el-col>
      </center>
    </el-row>
    <el-table
      :data="tablaGuias"
      height="500"
      size="mini"
      border
      fit
      style="width: 100%"
      ref="tableViewBox"
      v-loading="cargando"
      element-loading-text="cargando resultados..."
      element-loading-spinner="el-icon-loading"
      @row-click="testVueRoute"
      :empty-text="vacio"
      :row-style="tableRowClassName">
      <!-- :row-style="tableRowClassName" -->

      <el-table-column prop="numero" label="Número" width="120" :rows="6" :columns="5">
        <template slot-scope="scope">
          <span v-if="scope.row.letra">{{ scope.row.letra }}-{{ scope.row.terminal }}-{{ scope.row.numero }} </span>
          <span v-else>G-{{ scope.row.terminal }}-{{ scope.row.numero }}</span>
        </template>
      </el-table-column>

      <el-table-column v-if="permiso" prop="ocliente" label="CTA" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.ocliente">{{ scope.row.ocliente }} </span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column prop="fecha" label="Fecha" width="130">
        <template slot-scope="scope"> {{ formatFechaH(scope.row.fecha.date) }} hr. </template>
      </el-table-column>

      <el-table-column prop="cporte" label="Carta" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.cporte">{{ scope.row.cporte }} </span>
          <span v-else center>-</span>
        </template>
      </el-table-column>

      <el-table-column prop="estado" label="Estado" width="80" @mouseover="dialogVisible = true">
        <template slot-scope="scope">
          <el-popover placement="right" trigger="hover">
            <div v-if="messageMostrar(scope.row)">
              <p>
                <strong>{{ messageMostrar(scope.row).sucursal_orig }}</strong
                >{{ messageMostrar(scope.row).suc_orig }} <strong>{{ messageMostrar(scope.row).sucursal_dest }}</strong
                >{{ messageMostrar(scope.row).suc_dest }}
              </p>
              <p>
                <img height="15" :src="getImgTabla(scope.row)" alt="" />
                <strong> Estado: </strong>
                <span v-if="scope.row.estado == 'E'" style="color: green"> {{ scope.row.estadoc }}</span>
                <span v-else>{{ scope.row.estadoc }}</span>
              </p>
              <p v-if="scope.row.intentos">
                <!-- <img height='15' :src="getImgTabla(scope.row)" alt="" />  -->
                <strong>
                  Segunda visita el día
                  {{ formatFechaH(scope.row.intentos.fecha_app.date) }}
                </strong>
              </p>
              <div v-if="scope.row.nropres">
                <div v-if="scope.row.fchpres != null">
                  <p>
                    <img height="15" :src="getImgTabla2(scope.row)[1]" alt="" />
                    <strong> Presentado: </strong>{{ formatFechaH(scope.row.fchpres.date) }}
                  </p>
                  <p>
                    <strong> Núm presentación: {{ scope.row.nropres }} </strong>
                  </p>
                  <p v-if="scope.row.presanulada != null" style="color: red">
                    (Presentación Anulada)
                    {{ formatFechaH(scope.row.presanulada.date) }}
                  </p>
                </div>
                <div v-else>
                  <p>
                    <strong> Núm presentación: {{ scope.row.nropres }} </strong>
                  </p>
                  <p v-if="scope.row.presanulada != null" style="color: red">
                    (Presentación Anulada)
                    {{ formatFechaH(scope.row.presanulada.date) }}
                  </p>
                </div>
              </div>
              <div v-else-if="scope.row.presentacion">
                <div v-if="scope.row.fchpres">
                  <p>
                    <img height="15" :src="getImgTabla2(scope.row)" alt="" />
                    <strong> Presentado:</strong>
                    {{ formatFechaH(scope.row.fchpres.date) }}
                  </p>
                  <p>
                    <strong> Núm presentación:</strong>
                    {{ scope.row.presentacion }}
                  </p>
                  <p v-if="scope.row.presanulada != null" style="color: red">
                    (Presentación Anulada)
                    {{ formatFechaH(scope.row.presanulada.date) }}
                  </p>
                </div>
                <div v-else>
                  <p>
                    <strong> Núm presentación: {{ scope.row.presentacion }} </strong>
                  </p>
                  <p v-if="scope.row.presanulada != null" style="color: red">
                    (Presentación Anulada)
                    {{ formatFechaH(scope.row.presanulada.date) }}
                  </p>
                </div>
              </div>
              <div v-else>
                <p>
                  <img height="15" :src="getImgTabla2(scope.row)" alt="" />
                  <strong> No presentado </strong>
                </p>
              </div>
            </div>
            <div v-else>
              <p v-if="scope.row.estadoc != ''">{{ scope.row.estadoc }}</p>
              <p v-else>
                <img height="15" :src="getImgTabla(scope.row)" alt="" />
              </p>
              <p>No recibido en sucursal: Los bultos están pendientes de ser recibidos</p>
              <div v-if="scope.row.nropres">
                <div v-if="scope.row.fchpres">
                  <p>
                    <img height="15" :src="getImgTabla2(scope.row)" alt="" />
                    <strong> Presentado:</strong>
                    {{ formatFechaH(scope.row.fchpres.date) }}
                  </p>
                  <p><strong> Núm presentación:</strong> {{ scope.row.nropres }}</p>
                  <p v-if="scope.row.presanulada != null" style="color: red">
                    (Presentación Anulada)
                    {{ formatFechaH(scope.row.presanulada.date) }}
                  </p>
                </div>
                <div v-else>
                  <p>
                    <strong> Núm presentación: {{ scope.row.nropres }} </strong>
                  </p>
                  <p v-if="scope.row.presanulada != null" style="color: red">
                    (Presentación Anulada)
                    {{ formatFechaH(scope.row.presanulada.date) }}
                  </p>
                </div>
              </div>
              <div v-else-if="scope.row.presentacion">
                <div v-if="scope.row.fchpres">
                  <p>
                    <img height="15" :src="getImgTabla2(scope.row)" alt="" /><strong> Presentado:</strong>
                    {{ formatFechaH(scope.row.fchpres.date) }}
                  </p>
                  <p>
                    <strong> Núm presentación:</strong>
                    {{ scope.row.presentacion }}
                  </p>
                  <p v-if="scope.row.presanulada != null" style="color: red">
                    (Presentación Anulada)
                    {{ formatFechaH(scope.row.presanulada.date) }}
                  </p>
                </div>
                <div v-else>
                  <p>
                    <strong> Núm presentación: {{ scope.row.presentacion }} </strong>
                  </p>
                  <p v-if="scope.row.presanulada != null" style="color: red">
                    (Presentación Anulada)
                    {{ formatFechaH(scope.row.presanulada.date) }}
                  </p>
                </div>
              </div>
              <div v-else>
                <p><img height="15" :src="getImgTabla2(scope.row)" alt="" /><strong> No presentado </strong></p>
              </div>
            </div>
            <p v-if="scope.row.contrareem > 0">
              <img height="15" :src="getImgReembolso(scope.row)" alt="Gestión de Cobranza" />
              <span>
                Gestión cobranza:
                {{ formatoMoneda(scope.row.contrareem) }}</span
              >
            </p>
            <div slot="reference" class="name-wrapper" style="margin-top: 3px">
              <img height="18" :src="getImgFrio(scope.row)" alt="" style="margin-right: 3px" />
              <img height="18" :src="getImgReembolso(scope.row)" alt="" style="margin-right: 3px" />
              <img height="18" :src="getImgTabla(scope.row)" alt="" style="margin-right: 3px" />
              <img height="18" :src="getImgTabla2(scope.row)" alt="" />
            </div>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column prop="fchentrega" label="Entrega" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.fchentrega">
            {{ formatFecha(scope.row.fchentrega.date) }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column prop="razsoc" label="Nombre/Razón social"> </el-table-column>

      <el-table-column prop="direc" label="Dirección" width="180"> </el-table-column>

      <el-table-column prop="localid" label="Localidad" width="150"> </el-table-column>

      <el-table-column prop="codpos" label="CP" width="80"> </el-table-column>

      <el-table-column prop="provincia" label="Provincia" width="120"></el-table-column>

      <el-table-column prop="address" label="" width="30">
        <el-link icon="el-icon-zoom-in" target="_blank"></el-link>
        <!-- <template slot-scope="scope"> -->

        <!-- base_path_ + 'guias/detalle&terminal='+ scope.row.terminal + '&numero='+scope.row.numero -->
        <!-- </template> -->
      </el-table-column>
    </el-table>
    <!-- Paginación para tabla guías en Hoja de ruta -->
    <div v-if="hjruta != ''">
      <el-pagination
        v-if="tablaGuias.length"
        background
        layout="total,prev, pager, next"
        :total="cantidad_elementos"
        :page-size="25"
        style="margin-top: 20px; float: right; padding-bottom: 1rem"
        @current-change="nextHj"
        :current-page.sync="pagina_actual">
      </el-pagination>
    </div>
    <!-- Paginación para tabla guías en Guías -->
    <div v-else>
      <el-pagination
        v-if="tablaGuias.length"
        background
        layout="total,prev, pager, next"
        :total="cantidad_elementos"
        :page-size="25"
        style="margin-top: 20px; float: right"
        @current-change="nextPage"
        :current-page.sync="pagina_actual">
      </el-pagination>
    </div>
    <detalle></detalle>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import { HTTP } from '@/axios'
import accounting from 'accounting'
import DetalleGuia from '@/components/guias/detalleGuia.vue'

const moment = require('moment')
export default {
  components: {
    detalle: DetalleGuia,
  },
  props: ['tguias', 'hruta', 'filtrohr'],
  data() {
    return {
      tablaGuias: [],
      loadingTabla: false,
      permiso: '',
      datos: JSON.parse(window.localStorage.getItem('datos')),
      cantidad_elementos: 0,
      destinos: [],
      imgEstado: '',
      imgEstado2: '',
      cargando: false,
      pagina_actual: '',
      vacio: 'Sin Datos',
      fechaBusc: '',
      datosBusqueda: {
        comprob: '',
        terminal: '',
        nguia: '',
        numpres: '',
        cporte: '',
        fecha: [],
        codpos: '',
        razsoc: '',
        estado: 'x',
        pdestino: '',
        afinidad: '',
        ol_convenio: '',
        destino: '',
        presentado: '',
        guia_convenios: '',
        hrsuc: '',
        hojaruta: '',
        origen: '',
        id: '',
        convenio: '',
        provincia: '',
        pagina: '',
        lista: '',
        multi: '',
        sesion: { guia_convenios: '', ol_convenio: '' },
      },
      styleTitleTabla: {},
      hjruta: '',
      disabledExp: true,
      export_loading: false,
      empresa: window.localStorage.getItem('empresa'),
      back_tabla: { background: '#D7E9F7  !important' },
      datos_select: null,
      datosHR: [],
      exportarHR: [],
      tupla_Select: {},
    }
  },
  created() {
    /* Color del título según la empresa logeada */
    this.styleTitleTabla['backgroundColor'] = this.empresa == 'u' ? '#0b406a' : '#36404e'
    // window.localStorage.removeItem('Bloque')
    // Window.localStorage.removeItem('row_select_guia')
    if (typeof JSON.parse(window.localStorage.getItem('row_select_guia')) != 'undefined') {
      this.datos_select = JSON.parse(window.localStorage.getItem('row_select_guia'))
      window.localStorage.removeItem('row_select_guia')
      // console.log(this.datos_select)

      this.pagina_actual = this.datos_select != null ? this.datos_select.pag_guia : 1
    } else {
      this.datos_select = null
      this.pagina_actual = 1
    }
  },
  mounted() {
    if (this.tguias != undefined) {
      this.cargando = true
      setTimeout(() => {
        this.tablaGuias = this.tguias
        this.pagina_actual = 1
        this.cargando = false
      }, 150)
      this.cantidad_elementos = this.tguias[0].TotalRows
      this.hjruta = this.hruta
      if (this.cantidad_elementos > 0) {
        this.disabledExp = false
      }
    } else if (this.tguias == undefined) {
      EventBus.$on('cargandoGuias', () => {
        this.cargando = true
      })

      EventBus.$on('cargar_datos_select', () => {
        this.datos_select = JSON.parse(window.localStorage.getItem('row_select_guia'))
      })
      setTimeout(() => {
        EventBus.$on('cargarTablaGuias', (g) => {
          if (this.$refs.tablaGuias && this.$refs.tablaGuias.bodyWrapper) {
            this.$refs.tablaGuias.bodyWrapper.scrollTop = 0
          }
          console.log(g)
          this.fechaBusc = ''
          this.cargando = false
          this.datosBusqueda = g.datos
          /* Si vienen elementos cargo la tabla */
          if (g.tabla != 0) {
            this.tablaGuias = g.tabla
            this.cantidad_elementos = g.datos.comprob != '' ? g.tabla.length : g.tabla[0].TotalRows
            this.pagina_actual = g.pagina
            // Si regresa de detalle guia

            this.vacio = ''
            if (g.fechaIni != '' && g.fechaFin != '') {
              if (g.datos.nguia != '' || g.datos.comprob != '') {
                this.vacio = 'Sin datos'
                this.fechaBusc = '- Fecha de búsqueda: - '
              } else {
                this.vacio = 'Sin datos desde ' + g.fechaIni + ' - ' + g.fechaFin
                this.fechaBusc = '- Fecha de búsqueda: ' + g.fechaIni + ' - ' + g.fechaFin
              }
            } else {
              this.vacio = 'Sin datos'
              this.fechaBusc = ''
            }
          } else {
            this.tablaGuias = []
            this.cantidad_elementos = 0
            this.pagina_actual = ''
            if (g.fechaIni != '' && g.fechaFin) {
              this.vacio = 'Sin datos desde ' + g.fechaIni + ' - ' + g.fechaFin
              this.fechaBusc = '- Fecha de búsqueda: ' + g.fechaIni + ' - ' + g.fechaFin
            } else {
              this.vacio = 'Sin datos'
              this.fechaBusc = ''
            }
          }
        })
      }, 500)
    }

    this.permiso = this.datos.permisos.indexOf('MULCTA') != -1 ? true : false
  },
  beforeDestroy() {
    EventBus.$off('nuevaPagina', 0)
    EventBus.$off('cargarTablaGuias', 0)
    EventBus.$off('cargandoGuias')
    EventBus.$off('destinos')
    EventBus.$off('cargar_datos_select', 0)
    EventBus.$off('abrirModalDetalles', false)
  },
  methods: {
    /* Formatos para tabla */
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    formatFechaH(fecha) {
      return moment(fecha).format('DD/MM/YYYY HH:mm')
    },
    getImgTabla(datosTabla) {
      let img_estado = ''
      if (datosTabla['estado_app'] === 400) {
        img_estado = require('@/assets/ico_rechazado.png')
        return img_estado
      }

      if (!datosTabla['sucorig']) {
        img_estado = require('@/assets/ico_historial.png')
        return img_estado
      }
      if (datosTabla['estadoc']) {
        var estado = datosTabla['estadoc'].toUpperCase()
        if (estado.includes('MILLA')) {
          img_estado = require('@/assets/ico-um1.png')
          return img_estado
        }
        if (estado.includes('EN SUC. DESTINO')) {
          img_estado = require('@/assets/ico-pick8.png')
          return img_estado
        }
      }

      if (datosTabla['anulafec'] != null) {
        datosTabla['estado'] = 'A'
      }
      // Imagenes
      switch (datosTabla['estado']) {
        case 'E':
          img_estado = require('@/assets/tick1.png')
          break
        case 'X':
          img_estado = require('@/assets/ico_rechazado.png')
          break
        case 'R':
          img_estado = require('@/assets/ico_reprogamado.png')
          break
        case 'P':
          img_estado = require('@/assets/ico_retiro.png')
          break
        case 'A':
          img_estado = require('@/assets/ico_anulado.png')
          break
        case 'NR':
          img_estado = require('@/assets/ico_nr.png')
          break
        // case 'Z':
        //     img_estado 	= require("@/assets/stkremoto.png")
        //     break;
        // case "":
        //     img_estado 	= require("@/assets/stkremoto.png")
        //     break;
        default:
          img_estado = require('@/assets/stkremoto.png')
          break
      }
      return img_estado
    },
    getImgTabla2(datosTabla) {
      let imgEstado2
      if (datosTabla['nropres'] || datosTabla['presentacion']) {
        imgEstado2 = require('@/assets/ico_rendido.png')
      } else {
        imgEstado2 = require('@/assets/ico_rendido2.png')
      }
      return imgEstado2
    },
    messageMostrar(datosTabla) {
      let suc_o = ''
      let sucursal_text_orig = ''
      let sucursal_text_dest = ''
      let suc_d = ''
      let sucursal_origen = this.destinos.find((m) => m.codigo === datosTabla['sucorig'])
      let sucursal_destino = ''
      if (datosTabla['sucorig'] > 0 && sucursal_origen) {
        suc_o = sucursal_origen.nombre
        sucursal_text_orig = 'Suc Origen: '
        sucursal_destino = this.destinos.find((m) => m.codigo === datosTabla['sucdest'])

        if (datosTabla['sucdest'] > 0 && sucursal_destino) {
          suc_d = sucursal_destino.nombre
          if (datosTabla['sucdest'] != datosTabla['sucorig']) {
            sucursal_text_dest = ' | Suc Destino:  '
          } else {
            sucursal_text_orig = 'Suc Origen | Destino: '
            sucursal_text_dest = ''
            suc_o = ''
          }
        }
      }
      return {
        sucursal_orig: sucursal_text_orig,
        suc_orig: suc_o,
        sucursal_dest: sucursal_text_dest,
        suc_dest: suc_d,
      }
    },
    nextPage(page) {
      EventBus.$emit('nuevaPagina', page)
      this.pagina_actual = page
      return false
    },
    nextHj(page) {
      this.pagina_actual = page
      this.filtrohr.pagina = page
      this.cargando = true
      setTimeout(() => {
        HTTP.post('/guiasnew/getDatosTabla', this.filtrohr)
          .then((res) => {
            this.cargando = false
            if (res.data.length == 0) {
              // Mostrar mensaje de que no se encontraron resultados
              this.tablaGuias = []
            } else {
              this.showTablaGuias = true
              this.tablaGuias = res.data
            }
          })
          .catch((error) => {
            this.loadingTabla = false
            this.$alert('Error del servidor ' + error)
          })
      }, 200)

      return false
    },
    testVueRoute(row) {
      this.tupla_Select = { terminal: row.terminal, numero: row.numero }
      this.cargando = true
      setTimeout(() => {
        this.cargando = false
      }, 1500)
      setTimeout(() => {
        EventBus.$emit('abrirModalDetalles', {
          info: row,
          empresa: this.empresa,
        })
      }, 500)

      //Borro los datos guardados para una proxima busqueda
      // window.localStorage.removeItem('row_select_guia')
      // Guardo los dtos de la fila seleccionada
      // window.localStorage.setItem(
      //   'row_select_guia',
      //   JSON.stringify({
      //     pag_guia: this.pagina_actual,
      //     terminal: row.terminal,
      //     numero: row.numero,
      //   })
      // )
      /* Guardo los datos de Búsqueda para GUIAS*/
      // if (this.tguias == undefined) {
      //   window.localStorage.setItem('Bloque', JSON.stringify(this.datosBusqueda))
      //   this.$router.push({
      //     name: 'detalles',
      //     query: { terminal: row.terminal, numero: row.numero },
      //     params: row,
      //   })
      // } else {
      //   /* Tomo el nombre */
      //   // window.localStorage.setItem('Pag',this.$router.history.current.name);
      //   // this.$router.push({name:'detalles',
      //   // query: { terminal: row.terminal ,numero:row.numero,hr:row.hrsuc,hj:row.hojaruta},
      //   // params: row })
      //   if (this.empresa == 'u') {
      //     window.open('https://seguimiento.suizoargentina.com.ar/#/detalles?terminal=' + row.terminal + '&numero=' + row.numero + '&hr=' + row.hrsuc + '&hj=' + row.hojaruta, '_blank')
      //     // window.open('http://localhost:8080/#/detalles?terminal='+row.terminal+"&numero="+row.numero+"&hr="+row.hrsuc+"&hj="+row.hojaruta, "_blank");
      //   } else {
      //     window.open('https://seguimiento.log-in.com.ar/#/detalles?terminal=' + row.terminal + '&numero=' + row.numero + '&hr=' + row.hrsuc + '&hj=' + row.hojaruta, '_blank')
      //     // window.open('http://localhost:8080/#/detalles?terminal='+row.terminal+"&numero="+row.numero+"&hr="+row.hrsuc+"&hj="+row.hojaruta, "_blank");
      //   }
      //   window.localStorage.setItem(
      //     'guiasHR',
      //     JSON.stringify({
      //       terminal: row.terminal,
      //       numero: row.numero,
      //       hr: row.hrsuc,
      //       hj: row.hojaruta,
      //     })
      //   )
      // }
    },
    tableRowClassName({ row }) {
      if (row.terminal === this.tupla_Select.terminal && row.numero === this.tupla_Select.numero) {
        return this.back_tabla
      } else {
        return ''
      }

      // if (this.datos_select != null) {
      //   // Traigo los datos guardados de la hoja de ruta seleccionada
      //   let numero = this.datos_select.numero
      //   let terminal = this.datos_select.terminal
      //   if (row.terminal === terminal && row.numero === numero) {
      //     return this.back_tabla
      //   } else {
      //     return ''
      //   }
      // } else {
      //   return ''
      // }
    },
    /* FUNCIÓN PARA LA PARTE DE HOJA DE RUTA */
    exportarGuias() {
      if (this.tablaGuias.length) {
        this.filtrohr['tipo_busqueda'] = 'exportar'
        this.export_loading = true
        HTTP.post('/guiasnew/getDatosTabla', this.filtrohr)
          .then((rest) => {
            HTTP.post('/guiasNew/excelGuiasHr', rest.data).then((res) => {
              this.export_loading = false
              window.location.href = res.data
            })
          })
          .catch((error) => {
            this.loadingTabla = false
            this.export_loading = false
            this.$alert('Error del servidor ' + error)
          })
      } else {
        alert('No hay datos')
      }
    },
    getImgFrio(datosTabla) {
      let filtrarAfi = datosTabla.bultosCaract.filter((elemento) => elemento.tipobul === 4)
      if (filtrarAfi.length) {
        return require('@/assets/heladera.jpg')
      }
    },
    getImgReembolso(datosTabla) {
      // alert(datosTabla.contrareem)
      if (datosTabla.contrareem > 0) {
        return require('@/assets/reembolso_1.png')
      }
    },
    formatoMoneda(value) {
      return accounting.formatMoney(value, '$', 2)
    },
  },
}
</script>
<style scoped>
.el-table th {
  background: #f0f2f5 !important;
  border: 1px solid #f0f2f5 !important;
  font-weight: normal;
  color: #000;
}
</style>
