<template>
  <div class="vstock_buscar">
    <el-row
      style="border-bottom: 1px solid #ddd; padding-bottom: 8px"
      :gutter="10">
      <el-col :span="12">
        <h5 style="font-size: 16px; margin: -3px">
          Consulta de stock:
          <span v-if="titulo != ''" style="font-size: 13px"
            >" {{ titulo }} "
          </span>
          <span v-else></span>
        </h5>
      </el-col>
      <el-col :span="12" style="float: right">
        <span style="font-size: 14px; float: right; color: #606266"
          >{{ usuario.toUpperCase() }} | SECTOR :
          <span>{{ sectores }}</span></span
        >
      </el-col>
    </el-row>
    <el-form
      :label-position="labelPosition"
      :model="formBuscar"
      ref="formBuscar"
      class="demo-ruleForm"
      :disabled="bloquear">
      <el-row :gutter="10">
        <el-col :md="5" :sm="12" :xs="12" v-if="!no_genericos">
          <el-form-item label="Genérico">
            <el-select
              v-model="formBuscar.generico"
              filterable
              remote
              style="width: 100% !important"
              reserve-keyword
              placeholder="Ingrese búsqueda"
              size="mini"
              clearable
              @change="buscarArticulo(formBuscar.generico)"
              :loading="loading">
              <el-option
                v-for="item in genericos"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="5" :sm="12" :xs="12">
          <el-form-item label="Artículo">
            <el-select
              v-model="formBuscar.contiene"
              filterable
              remote
              style="width: 100% !important"
              reserve-keyword
              placeholder="Ingrese búsqueda"
              size="mini"
              clearable
              @change="buscarArticulo()"
              :loading="loading">
              <el-option
                v-for="item in descripciones"
                :key="item.descrip + item.codigo + item.stock"
                :label="item.descrip"
                :value="item.descrip">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="3" :sm="12" :xs="12">
          <el-form-item label="N° Lote">
            <el-input
              v-model="formBuscar.lote"
              clearable
              placeholder=" N° de lote"
              size="mini"
              @keypress.native.enter="buscarArticulo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="3" :sm="12" :xs="12">
          <el-form-item label="Código">
            <el-input
              v-model="formBuscar.codigo"
              clearable
              placeholder="Ingrese Código"
              size="mini"
              @keypress.native.enter="buscarArticulo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="4" :sm="6" :xs="12">
          <el-form-item
            label="Incluir artículos sin stock"
            style="float: right; text-align: center !important">
            <el-switch
              style="margin-top: -10px; width: 50%"
              v-model="formBuscar.sw_stock"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Si"
              inactive-text="No"
              size="mini">
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :md="4" :sm="6" :xs="12">
          <el-form-item style="margin-top: 40px">
            <el-button-group style="float: right">
              <el-button type="primary" @click="buscarArticulo()" size="mini"
                >Buscar</el-button
              >
              <el-button @click="resetForm(1)" size="mini">Limpiar</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
// import {  HTTP_DESARROLLO } from '@/axios'
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      formBuscar: {
        lote: '',
        contiene: '',
        codigo: '',
        sw_stock: false,
        cliente: JSON.parse(window.localStorage.getItem('datos'))['cliente'],
        session: JSON.parse(window.localStorage.getItem('datos')),
        estado: 0,
        idu: JSON.parse(window.localStorage.getItem('datos'))['uid'],
        generico: '',
        tipo_busqueda: 'palabra',
      },
      labelPosition: 'left',
      titulo: '',
      bloquear: false,
      sectores: '',
      session: JSON.parse(window.localStorage.getItem('datos')),
      descripciones: [],
      genericos: [],
      options: [],
      loading: false,
      usuario: '',
      descripciones_general: [], //lo uso como axuliar para guardar los articulos completos
      no_genericos: false,
    }
  },
  mounted() {
    /* NUEVO PERMISO NO GENERICOS. NO MOSTRAR GENERICOS PARA QUIENES TIENEN ESTE PERMISO */
    this.no_genericos = this.session.permisos.includes('NO_GENERICOS')
      ? true
      : false
    // ------------------------------------------------------------------------------------
    /* ASIGNACIÓN DE NOMBRE */
    this.usuario =
      this.session.user != null && this.session.user != ''
        ? this.session.user
        : '-'
    /* Cuando se envie el pedido recargo la tabla nuevamente */
    EventBus.$on('recargar_tabla', (f) => {
      if (f) {
        HTTP.post('/vlogisticanew/consultaStockPedidos', this.formBuscar)
          .then((res) => {
            var $respuesta = []
            if (res.data != 0) {
              $respuesta = res.data.resultados.map((row) => {
                return {
                  ...row,
                  cantidad: 0,
                  estado_2: '',
                  row_consultada: [],
                  cantlote: 0,
                }
              })
            }
            EventBus.$emit('recargarTablaCompleta', $respuesta)
          })
          .catch((error) => {
            console.log(error)
            this.$alert('Error del servidor ' + error)
          })
      }
    })

    /* ESCUCHA LA CONSULTA DE STOCK CARGADA EB CARRITO */
    EventBus.$on('consulta_primera_stock', (f) => {
      this.bloquear = false
      let $respuesta = []
      if (f.length > 0) {
        $respuesta = f.map((row) => {
          return {
            ...row,
            estado_2: '',
            row_consultada: [],
          }
        })
        this.descripciones = $respuesta
        this.descripciones_general = $respuesta //VARIABLE AUXILIAR
        let datos = $respuesta
        /* -----TOMO LOS GENÉRTICOS----- */
        let data = datos.map((item) => {
          return item.descgenerico
        })
        this.genericos = data.filter((item, index) => {
          return data.indexOf(item) === index
        })
        /* ------------------------------ */
      }
      EventBus.$emit('cargarTablaStock', $respuesta)
    })
    // SECTORES DONDE PUEDE CONSULTAR EL USUARIO
    this.getSectorUsuario()
  },
  beforeDestroy() {
    EventBus.$off('recargar_tabla', false)
    EventBus.$off('consulta_primera_stock', false)
  },
  methods: {
    buscarArticulo() {
      this.bloquear = true
      this.titulo =
        this.formBuscar.generico +
        ' ' +
        this.formBuscar.contiene +
        ' ' +
        this.formBuscar.lote +
        ' ' +
        this.formBuscar.codigo
      /* SI SE BUSCA POR GENERICO EN EL SELECT DE ARTICULO LISTO LOS ARTICULOS QUE TIENEN ESE GENERICO */
      this.descripciones = this.descripciones_general
      if (this.formBuscar.generico != '') {
        this.descripciones = this.descripciones.filter(
          (arti) => arti.descgenerico == this.formBuscar.generico
        )
      }
      /* ----------------------------------- */
      EventBus.$emit('cargandoStock')
      HTTP.post('/vlogisticaLogin/consultaStockPedidos', this.formBuscar)
        // HTTP_DESARROLLO.post(
        //   '/vlogisticaLogin/consultaStockPedidos',
        //   this.formBuscar
        // )
        .then((res) => {
          console.log(res.data)
          this.bloquear = false
          let $respuesta = []
          if (res.data != 0) {
            $respuesta = res.data.resultados.map((row) => {
              return {
                ...row,
                cantidad: 0,
                estado_2: '',
                row_consultada: [],
              }
            })
          }
          EventBus.$emit('cargarTablaStock', $respuesta)
        })
        .catch((error) => {
          this.bloquear = false
          this.$alert('Error del servidor ' + error)
        })
    },
    resetForm() {
      this.formBuscar.contiene = ''
      this.formBuscar.codigo = ''
      this.formBuscar.lote = ''
      this.formBuscar.generico = ''
      this.formBuscar.sw_stock = false
      this.titulo = ''
      this.descripciones = this.descripciones_general
      setTimeout(() => {
        EventBus.$emit('limpiarStockLog')
      }, 500)
    },
    getSectorUsuario() {
      HTTP.post('/vlogisticaLogin/getSectorUsuario', { session: this.session })
        .then((res) => {
          this.sectores = res.data == '' ? 'TODOS' : res.data
        })
        .catch((error) => {
          console.log(error)
          this.$alert('Error del servidor ' + error)
        })
    },
  },
}
</script>
<style scoped>
.el-form-item {
  margin-bottom: 10px !important;
}
</style>
