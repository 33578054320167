export const selectBultoTipo = [
  {
    nombre: 'FAC',
    letra: [{ nombre: 'A' }, { nombre: 'B' }, { nombre: 'C' }, { nombre: 'E' }],
  },
  {
    nombre: 'REM',
    letra: [{ nombre: 'R' }],
  },
  {
    nombre: 'OTR',
    letra: [{ nombre: 'X' }, { nombre: 'O' }],
  },
]
export const selectBultoLetraFac = [
  {
    nombre: 'A',
  },
  {
    nombre: 'B',
  },
  {
    nombre: 'C',
  },
  {
    nombre: 'E',
  },
]
export const selectBultoLetraRem = [
  {
    nombre: 'R',
  },
]
export const selectBultoLetraOtr = [
  {
    nombre: 'X',
  },
  {
    nombre: 'O',
  },
]
