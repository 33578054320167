<template>
    <div style="padding:10px;">
        
        <el-tabs type="border-card" @tab-click="handleClick" v-model="activeName"> 
          <!-- ---------------------------Administrar usuarios------------------------------------ -->
            <el-tab-pane label="STOCK" name="first">
                <stock-dsa></stock-dsa>
                <tabla-stock-dsa></tabla-stock-dsa>
            </el-tab-pane>        
            
            <el-tab-pane label="MOVIMIENTOS" name="movimientos">
                <movimiento-dsa></movimiento-dsa>
                <tabla-movimientos></tabla-movimientos>
            </el-tab-pane>                
        </el-tabs>        
    </div>
</template>
<script>
import StockDsa from '@/components/stocks/dsa/stockDsa.vue'
import TablaStockDsa from '@/components/stocks/dsa/TablaStockDsa.vue'
import MovimientoDsa from '@/components/stocks/dsa/movimientoDsa.vue';
import TablaMovimientos from '@/components/stocks/dsa/TablaMovimientos.vue'
import { EventBus } from "@/event-bus";
// import CarritoDSA from '../components/stocks/carritoDSA.vue';

export default {
    components:
    {
        StockDsa,
        TablaStockDsa,
        MovimientoDsa,
        TablaMovimientos
    },
    data() {
        return {
            activeName      : 'first',
            datos           : JSON.parse(window.localStorage.getItem("datos")),
            convenio        : '',
            aceptar_load    : false,
            blockCancelar   : false,            
        }
    },
    
    methods: {
          handleClick() {
            EventBus.$emit("limpiarStock");
        }, 
    },
}
</script>