<template>
  <el-dialog
    :visible.sync="modal.comprobantes"
    width="80%"
    @closed="limpiarForm()"
    :close-on-click-modal="false"
    style="padding-top: 0px !important"
    :destroy-on-close="true"
    :show-close="false"
    :close-on-press-escape="true"
    top="5vh">
    <el-row style="margin-top: -37px">
      <h2 style="text-align: center; padding: 0px">Carga de Comprobantes</h2>
      <hr />
    </el-row>
    <el-form label-position="right" label-width="130px">
      <el-row :gutter="10">
        <el-col :md="{ span: 4 }">
          <el-button type="primary" icon="el-icon-plus" round plain @click="addComprobante()"> Agregar Comprobante</el-button>
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-col>
          <el-table size="mini" border stripe :data="comprobantes" empty-text="Realice una busqueda para ver la información" style="width: 100%">
            <el-table-column label="#" type="index" width="50"></el-table-column>
            <el-table-column label="Codigo" min-width="20">
              <editable-cell :show-input="row.editMode" slot-scope="{ row }" v-model="row.bulto_tipo" editable-component="el-select" close-event="change" @change="tipoLetra(row)">
                <span slot="content">{{ row.bulto_tipo }}</span>
                <template slot="edit-component-slot">
                  <el-option v-for="(item, index) in row.selectBultoTipo" :key="index" :label="item.nombre" :value="item.nombre" />
                </template>
              </editable-cell>
            </el-table-column>
            <el-table-column label="" min-width="20">
              <editable-cell :show-input="row.editMode" slot-scope="{ row }" v-model="row.bulto_l" editable-component="el-select" close-event="change">
                <span slot="content">{{ row.bulto_l }}</span>
                <template slot="edit-component-slot">
                  <el-option v-for="(item, index) in row.selectBultoLetra" :key="index" :label="item.nombre" :value="item.nombre" />
                </template>
              </editable-cell>
            </el-table-column>
            <el-table-column label="" min-width="20">
              <editable-cell :show-input="row.editMode" slot-scope="{ row }" v-model="row.terminal" clearable editable-component="el-input" :maxlength="5" placeholder="Terminal" type="text">
                <span slot="content">{{ row.terminal }}</span>
              </editable-cell>
            </el-table-column>
            <el-table-column label="" min-width="50">
              <editable-cell :show-input="row.editMode" slot-scope="{ row }" v-model="row.numero" clearable editable-component="el-input" :maxlength="8" placeholder="Numero" type="text">
                <span slot="content">{{ row.numero }}</span>
              </editable-cell>
            </el-table-column>
            <el-table-column min-width="60" label="Contra entrega de mercadería">
              <editable-cell :show-input="row.editMode" slot-scope="{ row }" editable-component="el-select" close-event="change" v-model="row.contraentrega">
                <el-tag size="medium" :type="row.contraentrega === '0' ? 'danger' : 'success'" slot="content">
                  {{ row.contraentrega === '0' ? 'NO' : 'SI' }}
                </el-tag>
                <template slot="edit-component-slot">
                  <el-option :value="'0'" label="NO"></el-option>
                  <el-option :value="'1'" label="SI"></el-option>
                </template>
              </editable-cell>
            </el-table-column>
            <el-table-column label="Acciones">
              <template slot-scope="scope">
                <el-button v-if="scope.row.editMode == false" plain size="mini" type="primary" icon="el-icon-edit" @click="setEditMode(scope.row, scope.$index)" />
                <el-button
                  v-if="scope.row.editMode == true"
                  plain
                  size="mini"
                  type="success"
                  icon="el-icon-check"
                  @click="saveRow(scope.row, scope.$index)"
                  v-on:keyup.enter="saveRow(scope.row, scope.$index)" />
                <el-button plain size="mini" icon="el-icon-delete" type="danger" @click="deleteRow(scope.$index)" />
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-row type="flex" justify="end">
        <el-button type="success" size="medium" @click="agregarComprobantes()" icon="el-icon-check">Guardar</el-button>
        <el-button type="danger" size="medium" @click="limpiarForm()" icon="el-icon-close">Cerrar</el-button>
      </el-row>
    </span>
  </el-dialog>
</template>
<script>
import EditableCell from '@/components/cross/EditableCell'
import { selectBultoTipo } from '@/components/cross/_aux'

export default {
  name: 'GuiasCreateComprobantesForm',
  components: { EditableCell },
  props: { modal: { required: true } },
  mounted() {
    this.selectBultoLetra = []
    this.comprobantes = this.modal.rowGuia.comprobantes
    this.bultos = this.modal.rowGuia.bultos
    this.comprobantes = this.comprobantes.map((row) => {
      return {
        ...row,
        editMode: false,
      }
    })
  },
  data() {
    return {
      selectBultoLetra: [],
      comprobantes: [],
      selectBultoTipo,
    }
  },
  methods: {
    limpiarForm() {
      this.$events.fire('close-modal')
    },
    addComprobante() {
      let comprobante = {}
      comprobante['id'] = Date.now()
      comprobante['bulto_tipo'] = 'FAC'
      comprobante['bulto_l'] = 'A'
      comprobante['terminal'] = null
      comprobante['numero'] = null
      comprobante['codigo'] = null
      comprobante['contraentrega'] = '0'
      comprobante['selectBultoTipo'] = this.selectBultoTipo
      comprobante['selectBultoLetra'] = this.selectBultoTipo[0].letra
      comprobante['editMode'] = true
      this.comprobantes.push(comprobante)
    },
    setEditMode(row) {
      //index
      //Comprobar que no tenga bultos vinculados
      /*let contadorComprobantesVinculados = 0
			this.bultos.forEach(bulto =>{
				if(bulto.comprobante == this.comprobantes[index].codigo)
						contadorComprobantesVinculados++
			})
			if(contadorComprobantesVinculados > 0)
				return this.$message({
					showClose: true,
					message: 'No puede editar un comprobante que este vinculado a un bulto.',
					type: 'warning'
				})
			*/
      row.selectBultoTipo = this.selectBultoTipo
      row.bulto_l = null
      switch (row.bulto_tipo) {
        case 'FAC':
          row.selectBultoLetra = row.selectBultoTipo[0].letra
          break
        case 'REM':
          row.selectBultoLetra = row.selectBultoTipo[1].letra
          break
        case 'OTR':
          row.selectBultoLetra = row.selectBultoTipo[2].letra
          break
        default:
          row.selectBultoLetra = []
          break
      }
      row.bulto_l = row.selectBultoLetra[0].nombre
      row.editMode = true
    },
    saveRow(row) {
      row.terminal = row.terminal.trim()
      row.numero = row.numero.trim()
      //Control requerido
      if (row.bulto_tipo == null || row.bulto_l == null || row.terminal == null || row.numero == null)
        return this.$message({
          showClose: true,
          message: 'Debe completar los datos del comprobante antes de validar.',
          type: 'warning',
        })
      //Control numerico
      if (isNaN(row.numero) || isNaN(row.terminal))
        return this.$message({
          showClose: true,
          message: 'Los valores terminal y numero deben ser numericos.',
          type: 'warning',
        })

      //Comprobar que no repita codigos de comprobantes
      var contadorComprobantes = 0
      this.comprobantes.forEach((comprobante) => {
        if (
          comprobante.bulto_tipo + comprobante.bulto_l + comprobante.terminal.toString().padStart(5, '0') + comprobante.numero.toString().padStart(8, '0') ===
          row.bulto_tipo + row.bulto_l + row.terminal.toString().padStart(5, '0') + row.numero.toString().padStart(8, '0')
        )
          contadorComprobantes++
      })
      if (contadorComprobantes > 1)
        return this.$message({
          showClose: true,
          message: 'El comprobante esta repetido.',
          type: 'warning',
        })
      //Completo con ceros
      row.terminal = row.terminal.toString().padStart(5, '0')
      row.numero = row.numero.toString().padStart(8, '0')
      row.codigo = row.bulto_tipo + row.bulto_l + row.terminal + row.numero
      row.editMode = false
    },
    deleteRow(index) {
      //Comprobar que no tenga bultos vinculados
      /*let contadorComprobantes = 0
			this.bultos.forEach(bulto =>{
				if(bulto.comprobante == this.comprobantes[index].codigo)
						contadorComprobantes++
			})
			if(contadorComprobantes > 0)
				return this.$message({
					showClose: true,
					message: 'No puede quitar un comprobante que este vinculado a un bulto.',
					type: 'warning'
				})
			else
				this.comprobantes.splice(index,1)*/
      this.comprobantes.splice(index, 1)
    },
    agregarComprobantes() {
      //Validar que esten todos validados
      if (
        this.comprobantes.filter(function (e) {
          return e.editMode === true
        }).length > 0
      ) {
        return this.$message({
          showClose: true,
          message: 'Debe confirmar los datos de todos los bultos cargados.',
          type: 'warning',
        })
      }
      /*
			//Busqueda de Comprobantes duplicados
			const busqueda = this.comprobantes.reduce((acc, comprobante) => {
				const clave = JSON.stringify(comprobante.codigo)
				acc[clave] = ++acc[clave] || 0
				return acc
			}, {})
			const duplicados = this.comprobantes.filter( (comprobante) => {
				return busqueda[JSON.stringify(comprobante.codigo)]
			})
			//Si encuentro duplicados salgo y no permito guardar
			if(duplicados.length > 0){
				if(duplicados.length/2 == 1) {
					return this.$message({
						showClose: true,
						message: 'Existe 1 comprobante duplicado.',
						type: 'warning'
					})
				}
				else {
					return this.$message({
						showClose: true,
						message: 'Existe '+duplicados.length/2+' comprobantes duplicados.',
						type: 'warning'
					})
				}
			}*/
      this.$events.fire('add-comprobante', this.modal.rowGuia, this.comprobantes)
    },
    tipoLetra(row) {
      //console.log(row)
      row.bulto_l = null
      switch (row.bulto_tipo) {
        case 'FAC':
          row.selectBultoLetra = row.selectBultoTipo[0].letra
          break
        case 'REM':
          row.selectBultoLetra = row.selectBultoTipo[1].letra
          break
        case 'OTR':
          row.selectBultoLetra = row.selectBultoTipo[2].letra
          break
        default:
          row.selectBultoLetra = []
          break
      }
      row.bulto_l = row.selectBultoLetra[0].nombre
    },
  },
}
</script>
