export const fields = [
  {
    name: 'id',
    title: 'Referencia',
    sortField: 'id',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '110px',
    visible: false,
  },
  {
    name: '__slot:guia',
    title: 'Guia',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
    width: '150px',
  },
  {
    name: 'convenio',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
  },
  {
    name: 'razon_social',
    title: 'Razon social',
    sortField: 'razon_social',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
    width: '200px',
  },
  {
    title: 'Codigo Postal',
    name: 'codigo_postal',
    sortField: 'codigo_postal',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: 'provincia',
    sortField: 'provincia',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: 'localidad',
    sortField: 'localidad',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: 'direccion',
    sortField: 'direccion',
    title: 'Dirección',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: '__slot:cobranza',
    title: 'Cobranza',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
  },
  {
    title: 'Valor',
    name: '__slot:valor_cobranza',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
  },
  {
    title: 'Comprobantes',
    name: '__slot:cantidad_comprobantes',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
  },
  {
    title: 'Bultos',
    name: '__slot:cantidad_bultos',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
  },
  {
    title: 'Total Peso',
    name: 'total_peso',
    sortField: 'total_peso',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    title: 'Total Volumen',
    name: 'total_volumen',
    sortField: 'total_volumen',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    title: 'Total Valor Declarado',
    name: '__slot:total_valor_declarado',
    sortField: 'total_valor_declarado',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: '__slot:created_at',
    title: 'Fecha de carga',
    sortField: 'created_at',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
    width: '150px',
  },
  {
    name: '__slot:estado',
    title: 'Estado',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
  },
  {
    name: '__component:guias-consultas-actions',
    title: '',
    titleClass: 'text-center-titulo',
    dataClass: 'text-left-data',
    width: '120px',
  },
]
export const sortOrder = [{ field: 'id', sortField: 'id', direction: 'desc' }]
export const moreParams = {
  convenio_select: null,
  //checkSuper: 'super',
}
