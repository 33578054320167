<template>
  <div style="padding: 0px 20px">
    <el-row :gutter="10">
      <el-col :span="24" :xs="24">
        <h2 v-if="rowData.origen === 'ARCHIVO'">Guia #: {{ rowData.terminal }}-{{ rowData.numero }}</h2>
        <h2 v-else>Guia carga manual: #{{ rowData.id }}</h2>
        <hr />
        <el-row :gutter="10">
          <el-col :span="12" :xs="12">
            <h2 style="text-align: left; float: left; padding-right: 10px">Comprobantes</h2>
            <div style="text-align: left; float: left; margin-top: 10px">
              <el-button type="primary" :icon="checkIcon(rowData.comprobantes)" circle size="mini" @click="formComprobantesBultos(rowData, 'C')"></el-button>
            </div>
            <vuetable ref="vComprobantes" :api-mode="false" :data="rowData.comprobantes" :fields="fComprobantes" :css="css.table" noDataTemplate="Sin Datos" :row-class="onRowClass">
              <template slot="sequence" slot-scope="props">
                <div>{{ props.rowIndex + 1 }}</div>
              </template>
            </vuetable>
          </el-col>
          <el-col :span="12" :xs="12">
            <h2 style="text-align: left; float: left; padding-right: 10px">Bultos</h2>
            <div style="text-align: left; float: left; margin-top: 10px">
              <el-button type="primary" :icon="checkIcon(rowData.bultos)" circle size="mini" @click="formComprobantesBultos(rowData, 'B')"></el-button>
            </div>
            <vuetable ref="vBultos" :api-mode="false" :data="rowData.bultos" :fields="fbultos" :css="css.table" noDataTemplate="Sin Datos" detail-row-component="bultos-details">
              <template slot="sequence" slot-scope="props">
                <div>{{ props.rowIndex + 1 }}</div>
              </template>
            </vuetable>
          </el-col>
        </el-row>
        <br />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import accounting from 'accounting'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import { css } from '@/components/cross/_guias'

export default {
  name: 'ComprobantesDetails',
  components: { Vuetable },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },
  data() {
    return {
      loaderTable: false,
      bultos: [],
      fComprobantes: [
        {
          name: '__slot:sequence',
          title: '#',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '30px',
        },
        {
          name: 'codigo',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '200px',
        },
        {
          name: 'contraentrega',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '200px',
          callback: this.callContraentrega,
        },
      ],
      fbultos: [
        {
          name: '__slot:sequence',
          title: '#',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '30px',
        },
        {
          name: 'tipo_bulto',
          title: 'Tipo de Bulto',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'peso_declarado',
          title: 'Peso Declarado',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'volumen_declarado',
          title: 'Volumen Declarado',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'valor_declarado',
          title: 'Valor Declarado',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          callback: this.callFormatNumber,
        },
        {
          name: 'frio',
          title: 'Cadena de Frio',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          callback: this.callTipo,
        },
      ],
      css,
    }
  },
  methods: {
    checkIcon(data) {
      if (data.length > 0) return 'el-icon-edit'
      else return 'el-icon-plus'
    },
    callFormatNumber(value) {
      return accounting.formatMoney(value, '$ ', '0', '.')
    },
    callBultosLength(value) {
      return "<span class='el-tag el-tag--success'>" + value.length + '</span>'
    },
    callTipo(value) {
      if (value == 1) return 'SI'
      if (value == 0) return 'NO'
    },
    callContraentrega(value) {
      if (value == 1) return 'SI'
      if (value == 0) return 'NO'
    },
    onRowClass(dataItem) {
      if (this.$refs.vComprobantes.isVisibleDetailRow(dataItem.id)) {
        return 'selected-row'
      }
    },
    formComprobantesBultos(rowGuia, tipo) {
      this.$events.fire('open-form-anexo', rowGuia, tipo)
    },
  },
  computed: {
    checkCobranza() {
      if (this.rowData.valor_cobranza > 0) return true
      else return false
    },
    checkAcompanante() {
      if (this.rowData.acompanante > 0) return true
      else return false
    },
    checkOtros() {
      if (this.rowData.otros != null) return true
      else return false
    },
  },
}
</script>
