<template>
  <div style="background-color: #e5ebf7; padding: 5px">
    <h2 style="text-align: center">Modificar contraseña</h2>
    <el-row v-if="permiso_password" :gutter="20">
      <el-col :lg="{ span: 12, offset: 6 }" :md="{ span: 13, offset: 4 }" :sm="{ span: 12, offset: 6 }" :xs="{ span: 20, offset: 2 }">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm" :label-position="'left'">
          <el-form-item label="Contraseña actual" prop="pass_actual">
            <el-input :type="passwordType" v-model="ruleForm.pass_actual" ref="actual" autocomplete="off" clearable placeholder="Ingrese contraseña actual" :disabled="loadingGuardar">
              <template slot="prepend"><i class="el-icon-unlock"></i></template>
              <el-button slot="append" type="password" icon="el-icon-view" @click="mostrarPass('passwordType')"></el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="Nueva contraseña" prop="pass_nueva">
            <el-input :type="passwordType2" v-model="ruleForm.pass_nueva" ref="primera" autocomplete="off" clearable placeholder="Ingrese  su nueva contraseña" :disabled="loadingGuardar">
              <template slot="prepend"><i class="el-icon-lock"></i></template>
              <el-button slot="append" type="password" icon="el-icon-view" @click="mostrarPass('passwordType2')"></el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="Repita la nueva contraseña" prop="pass_nueva1">
            <el-input type="password" v-model="ruleForm.pass_nueva1" autocomplete="off" clearable placeholder="Repita la nueva contraseña" :disabled="loadingGuardar">
              <template slot="prepend"><i class="el-icon-lock"></i></template
            ></el-input>
          </el-form-item>
          <center>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')" :loading="loadingGuardar">Guardar</el-button>
              <el-button @click="resetForm2('ruleForm')">Cancelar</el-button>
            </el-form-item>
          </center>
        </el-form>
      </el-col>

      <el-col :lg="{ span: 12, offset: 6 }" :md="{ span: 13, offset: 4 }" :sm="{ span: 12, offset: 6 }" :xs="{ span: 20, offset: 2 }" v-if="showErrores">
        <el-alert title="Controle el siguiente error" :description="errorContra" type="warning" show-icon> </el-alert>
      </el-col>
      <el-col :lg="{ span: 12, offset: 6 }" :md="{ span: 13, offset: 4 }" :sm="{ span: 12, offset: 6 }" :xs="{ span: 20, offset: 2 }" v-if="showCerrar">
        <el-alert title="Actualización exitosa" :description="errorContra" type="success" show-icon> </el-alert>
      </el-col>
    </el-row>

    <el-row v-if="permiso_password == 'false'">
      <el-alert title="Usuario:" :closable="false" type="error" description="No tiene permisos para cambiar su contraseña" show-icon></el-alert>
    </el-row>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'
import { EventBus } from '@/event-bus'

export default {
  data() {
    var validatePass = (rules, value, callback) => {
      if (value === '') {
        callback(new Error('Ingrese la contraseña'))
      } else {
        if (value.toString().length < 8) {
          callback(new Error('La contraseña debe tener al menos 8 caracteres'))
        } else {
          if (this.ruleForm.pass_nueva1 !== '') {
            this.$refs.ruleForm.validateField('pass_nueva1')
          }
          callback()
        }
      }
    }
    var validatePass2 = (rules, value, callback) => {
      if (value === '') {
        callback(new Error('Ingrese la contraseña'))
      } else if (value !== this.ruleForm.pass_nueva) {
        callback(new Error('Las contraseñas no coinciden'))
      } else {
        callback()
      }
    }

    var validatepass_actual = (rules, value, callback) => {
      if (value === '') {
        callback(new Error('Ingrese la contraseña'))
      } else if (value.toString().length < 6) {
        callback(new Error('La contraseña debe tener al menos 6 caracteres'))
      } else {
        callback()
      }
    }
    return {
      permiso_password: true, //window.localStorage.getItem('permiso_pass'),
      showErrores: false,
      errorContra: '',
      alertContra: '',
      empresa: window.localStorage.getItem('empresa'),
      clave_ex: window.localStorage.getItem('clave_ex'),
      datos: JSON.parse(window.localStorage.getItem('datos')),
      passwordType: 'password',
      passwordType2: 'password',
      loadingGuardar: false,
      showCerrar: false,
      ruleForm: {
        pass_actual: '',
        pass_nueva: '',
        pass_nueva1: '',
      },
      rules: {
        pass_actual: [{ validator: validatepass_actual, trigger: 'blur' }],
        pass_nueva: [{ validator: validatePass, trigger: 'blur' }],
        pass_nueva1: [{ validator: validatePass2, trigger: ['blur', 'change'] }],
      },
      posicion: 'right',
    }
  },
  mounted() {
    if (screen.width < 450) {
      this.posicion = 'top'
    } else {
      this.posicion = 'right'
    }
    EventBus.$on('limpiarCampos', (p) => {
      if (p) {
        this.resetForm2('ruleForm')
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('limpiarCampos', false)
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.showErrores = false
          this.loadingGuardar = true
          console.log(HTTP)
          HTTP.post('/guiasNew/editarClave050624', {
            // HTTP_DESARROLLO.post('/guiasNew/editarClave', {
            pass: this.ruleForm,
            tipo: this.empresa,
            uid: this.datos['uid'],
            clave_ex: true,
            empresa: this.empresa,
          })
            .then((res) => {
              console.log(res.data)
              /* Comentario Errores
                        error = 1 contraseña actual incorrecta,error = 2 contraseñas diferentes, error = 3 tamaño < 6
                        error = 4 contiene palabrasNoAceptadas, error = 5 campos imcompletos*/
              this.errorContra = ''
              this.loadingGuardar = false

              switch (res.data) {
                case 1:
                  this.errorContra = 'La contraseña actual es incorrecta'
                  this.$refs.actual.focus()
                  this.showErrores = true
                  break
                case 2:
                  this.errorContra = 'La contraseñas no coinciden'
                  this.$refs.primera.focus()
                  this.showErrores = true
                  break
                case 3:
                  this.errorContra = 'La contraseña nueva debe tener al menos 8 caracteres'
                  this.showErrores = true
                  break
                case 4:
                  if (this.empresa == 'u') {
                    this.errorContra = 'La contraseña no puede ser alguna de estas palabras: SUIZO , BARRACAS, SUIZOBARRACAS, internet,123456, FARMACIA o DROGUERIA'
                  } else {
                    this.errorContra = 'La contraseña no puede ser alguna de estas palabras: Internet,123456, Farmacia,'
                  }
                  this.$refs.primera.focus()
                  this.showErrores = true
                  break
                case 5:
                  this.errorContra = 'Campos incompletos'
                  this.showErrores = true
                  break
                case '':
                  this.$message({
                    showClose: true,
                    message: 'Oops, lo siento no se pudo realizar la acción.',
                    type: 'error',
                  })
                  break
                case false:
                  this.$message({
                    showClose: true,
                    message: 'Oops, lo siento no se pudo realizar la acción.',
                    type: 'error',
                  })
                  break
                case true:
                  this.errorContra = 'Su contraseña se actualizó correctamente.'
                  this.showCerrar = true
                  setTimeout(() => {
                    this.showCerrar = false
                  }, 1700)
                  this.resetForm2('ruleForm')
                  break
              }
            })
            .catch((error) => {
              this.$alert('Error del servidor ' + error)
            })
        } else {
          return false
        }
      })
    },

    mostrarPass(nombre) {
      if (nombre == 'passwordType') {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
      } else {
        this.passwordType2 = this.passwordType2 === 'password' ? 'text' : 'password'
      }
    },
    resetForm2(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>
