<template>
  <div class="body-back bg-s" style="overflow-y: scroll">
    <img :src="img_header" class="img-banner" fit="contain" alt="Logo Empresa" />

    <el-row :gutter="10" class="hidden-xs-only">
      <el-col :lg="{ span: 10, offset: 7 }" :md="{ span: 12, offset: 5 }" :sm="{ span: 12, offset: 5 }" :xs="{ span: 22, offset: 1 }">
        <el-card class="box-card" v-loading="loadingMsj" element-loading-text="Cargando..." element-loading-spinner="el-icon-loading">
          <div slot="header" class="clearfix">
            <span style="font-size: 20px"><i class="el-icon-user-solid"></i>Recuperar contraseña</span>
            <p v-if="!noShow && !loadingMsj && noShow != ''" style="margin-bottom: -5px !important">A continuación ingrese una nueva contraseña para su cuenta.</p>
          </div>
          <div v-if="!noShow && !loadingMsj">
            <el-form :model="ruleForm" status-icon label-position="left" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
              <el-form-item label="Nueva Contraseña" prop="pass">
                <el-input type="password" v-model="ruleForm.pass" autocomplete="off" clearable
                  ><template slot="prepend"><i class="el-icon-lock"></i></template
                ></el-input>
              </el-form-item>
              <el-form-item label="Repetir Contraseña" prop="checkPass">
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" clearable @keypress.native.enter="recuperar('ruleForm')"
                  ><template slot="prepend"><i class="el-icon-unlock"></i></template
                ></el-input>
              </el-form-item>
              <el-form-item label-width="20px">
                <el-button :type="showbtnC" @click="recuperar('ruleForm')" :loading="loadin_enviar"><i class="el-icon-lock"></i> Guardar</el-button>
                <el-button @click="regresarLink()"><i class="el-icon-back"></i> Regresar</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="noShow && !loadingMsj && noShow != ''">
            <img :src="imgResultado" alt="" />
            <p>{{ msj_show }}</p>
            <el-button :type="showbtnC" @click="regresarLink(btnRegresar)"><i class="el-icon-back"></i>Regresar</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- Recup pass Movil -->
    <el-row class="hidden-sm-and-up">
      <el-col :xs="20" :offset="2">
        <el-card class="box-card" v-loading="loadingMsj" element-loading-text="Cargando..." element-loading-spinner="el-icon-loading">
          <div slot="header" class="clearfix">
            <span style="font-size: 20px"><i class="el-icon-user-solid"></i>Recuperar contraseña</span>
            <p v-if="!noShow && !loadingMsj && noShow != ''" style="margin-bottom: -5px !important">A continuación ingrese una nueva contraseña para su cuenta.</p>
          </div>
          <div v-if="!noShow && !loadingMsj">
            <el-form :model="ruleForm" status-icon label-position="left" :rules="rules" ref="ruleForm" class="demo-ruleForm" v-if="!noShow">
              <el-form-item label="Nueva Contraseña" prop="pass">
                <el-input type="password" v-model="ruleForm.pass" autocomplete="off" clearable
                  ><template slot="prepend"><i class="el-icon-lock"></i></template
                ></el-input>
              </el-form-item>
              <el-form-item label="Repetir Contraseña" prop="checkPass">
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" @keypress.native.enter="recuperar('ruleForm')" clearable
                  ><template slot="prepend"><i class="el-icon-unlock"></i></template
                ></el-input>
              </el-form-item>
              <el-form-item label-width="0px">
                <el-button :type="showbtnC" @click="recuperar('ruleForm')" :loading="loadin_enviar"><i class="el-icon-lock"></i>Guardar</el-button>
                <el-button @click="regresarLink()"><i class="el-icon-back"></i>Regresar</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="noShow && !loadingMsj && noShow != ''">
            <img :src="imgResultado" alt="" />
            <p>{{ msj_show }}</p>
            <el-button :type="showbtnC" @click="regresarLink(btnRegresar)"><i class="el-icon-back"></i>Regresar</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import { HTTP } from '@/axios'

export default {
  props: ['productId'],
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Ingrese una contraseña'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Ingrese una contraseña'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('Las contraseñas no coinciden!'))
      } else {
        callback()
      }
    }
    return {
      showForm: false,
      loadin_enviar: false,
      img_header: '',
      titulo: '',
      imgFavicon: '',
      showbtnC: '',
      empresa: '',
      ruleForm: {
        pass: '',
        checkPass: '',
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: ['change', 'blur'] }],
      },
      codigo: false,
      noShow: '',
      msj_show: '',
      imgResultado: '',
      btnRegresar: '',
      loadingMsj: true,
    }
  },
  created() {
    /* Imagen segu url y btn */
    // Mostrar los datos según la url
    let urlpp = window.location.host,
      array_url = urlpp.split('/'),
      empresa_nombre = array_url[0].split('.')

    if (empresa_nombre[1] == 'suizoargentina') {
      this.img_header = require('@/assets/SuizoBlanco.svg')
      this.titulo = 'Suizo Argentina S.A.'
      this.imgFavicon = 'favicon_suizo.ico'
      this.showbtnC = 'primary'
      this.empresa = 'u'
    } else if (empresa_nombre[1] == 'log-in') {
      this.titulo = 'LOG-IN'
      this.img_header = require('@/assets/loginfarma.png')
      this.imgFavicon = 'favicon_log.png'
      this.showbtnC = 'warning'
      this.empresa = 'lo'
    } else {
      this.img_header = require('@/assets/SuizoBlanco.svg')
      this.titulo = 'Suizo Argentina S.A.'
      this.imgFavicon = 'favicon_suizo.ico'
      this.showbtnC = 'primary'
      this.empresa = 'u'
    }

    /* ----- */
    if (this.$route.query.s != '') {
      this.codigo = this.$route.query.s
      this.loadingMsj = true
      HTTP.post('/loginguias/verificarSaltP', {
        codigo: this.codigo,
        empresa: this.empresa,
      })
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            this.msj_show = ' Ocurrió un error. Le pedimos vuelva a solicitar recuperar contraseña.'
            setTimeout(() => {
              this.loadingMsj = false
            }, 700)
            this.noShow = true
            this.btnRegresar = 'pass'
            this.imgResultado = require('@/assets/003-cancelar.png')
          } else {
            setTimeout(() => {
              this.loadingMsj = false
            }, 700)
            this.noShow = false
          }
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
        })
    }
  },
  metaInfo() {
    return {
      title: this.titulo,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        {
          name: 'description',
          content: 'An example Vue application with vue-meta.',
        },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css?family=Roboto:300,300i,500,700,900i&display=swap',
        },
        { rel: 'shortcut icon', type: 'image/png', href: this.imgFavicon },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Varela+Round&display=swap',
        },
      ],
    }
  },
  mounted() {
    EventBus.$on('mostrarForm', () => {
      this.showForm = true
    })
  },
  methods: {
    recuperar(formName) {
      setTimeout(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            /* Antes de enviar el cambio primero controlar que no se haya modificado el salt */
            HTTP.post('/loginguias/verificarSaltP', {
              codigo: this.codigo,
              empresa: this.empresa,
            })
              .then((res) => {
                if (res.data) {
                  this.msj_show = ' Ocurrió un error. Le pedimos vuelva a solicitar el cambio de contraseña.'
                  this.btnRegresar = 'pass'
                  this.imgResultado = require('@/assets/003-cancelar.png')
                  setTimeout(() => {
                    this.noShow = false
                  }, 800)
                } else {
                  setTimeout(() => {
                    this.noShow = false
                  }, 100)
                  /* Realizó el cambio */
                  this.loadin_enviar = true
                  HTTP.post('/loginguias/resetPassword', {
                    accion: 'newPass',
                    pass: this.ruleForm.pass,
                    new_pass: this.ruleForm.checkPass,
                    codigo: this.codigo,
                    empresa: this.empresa,
                  })
                    .then((res) => {
                      // console.log('pasas',res.data)
                      this.loadin_enviar = false
                      switch (res.data) {
                        case 0:
                          this.msj_error = 'Ocurrió un error interno al modificar la contraseña de su cuenta, por favor reintente más tarde.'
                          // this.noShow = true;
                          setTimeout(() => {
                            this.noShow = true
                          }, 400)
                          this.imgResultado = require('@/assets/003-cancelar.png')
                          this.btnRegresar = 'pass'
                          break
                        case 1:
                          this.msj_show = 'El cambio de contraseña fue exitoso. Por favor ingrese al Sistema.'
                          setTimeout(() => {
                            this.noShow = true
                          }, 400)
                          this.imgResultado = require('@/assets/004-email.png')
                          break
                        case 2:
                          this.msj_error = 'No se pudo procesar su petición, por favor intente más tarde.'
                          setTimeout(() => {
                            this.noShow = true
                          }, 400)
                          this.imgResultado = require('@/assets/003-cancelar.png')
                          this.btnRegresar = 'pass'
                          break
                        // default:
                        // 	this.msj_show = 'El cambio de contraseña fue exitoso. Por favor ingrese al Sistema.';
                        // 	break;
                      }
                    })
                    .catch((error) => {
                      this.loadin_enviar = false
                      this.$alert('Error del servidor ' + error)
                    })
                }
              })
              .catch((error) => {
                this.$alert('Error del servidor ' + error)
              })
            // Enviar la consulta de busqueda de email
            // Si existe enviar el email con los datos solicitados
          } else {
            this.$message({
              message: 'Cuidado hay campos incompletos.',
              type: 'warning',
            })
          }
        })
      }, 150)
    },
    regresarLink(accion) {
      if (accion == 'pass') {
        this.$router.push({ name: 'recuperar' })
      } else {
        this.$router.push({ name: 'login' })
      }
    },
  },
}
</script>
<style scoped>
.bg-s {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #66b1ff !important;
  background-image: linear-gradient(315deg, #3a638d 0%, #2a2b2d 74%);
  z-index: -1;
  transition: 850ms !important;
}
.body-back {
  /* background: linear-gradient(96deg, #FFFAFA 0, #E5EBF6 64%); */
  overflow: hidden;
  height: 100%;
  text-align: center;
  font-family: 'Roboto';
}
.img-banner {
  padding-top: 20px;
  width: 290px;
  padding-bottom: 20px;
}
</style>
