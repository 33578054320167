<template>
    <div >
    <el-row :gutter="20" v-if="empresa==='DSA'" style="margin-bottom:15px;">
        <el-col :md="24">
            <el-tooltip class="item" effect="dark"  content="Descargar indices con errores" placement="top-start">
                <el-button type="info" style="float:right;" plain icon="el-icon-download" @click="downloadIndices"
                size="small"  :disabled="block_descargar" :loading="loading_download">Descargar Índices con error</el-button>
            </el-tooltip>
        </el-col>
    </el-row>
     <el-row :gutter="20">
          <el-col :md="8" :offset="0" v-if="empresa=='DSA'">
              <listado-indices :indice="1"  />
          </el-col>
             <!-- <el-col :md="6" :offset="0">
              <listado-indices :indice="1" />
          </el-col>
             <el-col :md="6" :offset="0">
              <listado-indices :indice="1" />
          </el-col> -->

         <el-col :md="6" :offset="0">
             <el-card shadow="never" class="forzador" :body-style="{ padding: '10px', height: '500px' }">
                 <div slot="header">
                     <span>Sincronizar comprobantes  con la web de seguimiento</span>
                 </div>
                <p class="aviso">
                 Este proceso recoge la información desde el servidor de archivos, procesa los indices generados por el escaner y guarda la información en la base de datos
                </p>
                 <p class="aviso2">
                  Para realizar la sincronización es necesario subir los comprobantes escaneados desde la unidad donde se encuentra conectado el escaner al servidor de archivo.
                </p>
                  <p class="aviso1">
                      Actualmente se ejecuta automáticamente todos los días a las 10am y a las 16pm. 
                </p>
                <!-- <el-button v-if="empresa=='DSA'" type="danger" style="width:100%" :loading="sincronizando" size="default" @click="forzarSincro">FORZAR SINCRONIZACIÓN</el-button> -->
                
             </el-card>
             
         </el-col>
         <el-col :md="10" :offset="0" v-if="empresa=='DSA'">
              <buscador-indices :indice="1"  />
          </el-col>
     </el-row>

     
     
    </div>
</template>

<script>
  import {HTTP} from "@/axios"
  import {EventBus} from "@/event-bus"
  import ListadoIndices from "@/components/escaner/listadoIndices.vue"
  import BuscadorIndices from "@/components/escaner/BuscadorIndices.vue"
    export default {
        components:{ListadoIndices,BuscadorIndices},

        data() {
            return {
                logs: [],
                pagina:1,
                sincronizando:false,
                empresa: window.localStorage.getItem("empresa") == "u" ? "DSA" : "LOG",
                block_descargar:false,
                loading_download: false
            }
        },
        created()
        {
            this.getLogs()
          
        },
        methods: {
            getLogs() {
                HTTP.post('/escaner/getLogs', {pagina: this.pagina, empresa: this.empresa})
                    .then( res => {
                        // console.log(res.data);
                        this.logs = res.data
                    })
            },
            forzarSincro(){
                 EventBus.$emit('disableBtnSubir', true)
                this.sincronizando = true
                HTTP.post('/robotescaner/forzarSincro', {empresa: this.empresa})
                    .then(res => {
                this.sincronizando = false
                        EventBus.$emit('disableBtnSubir', false)
                        // console.log(res.data);
                        if(res.data)
                        {
                            this.$alert('Sincronización exitosa, se procesaron 10 indices', { type: 'success'})
                        }
                        else{
                            this.$alert('No se pudo realizar la sincronización ' , { type: 'error'})
                        }
                    })
                    .catch( e => {
                this.sincronizando = false

                        this.$alert('No se pudo realizar la sincronización ', e)
                    })
            },              
            downloadIndices() {
                this.loading_download = true
                this.block_descargar  = true
                HTTP.get('/escaner/downloadIndices')
                .then( res=>{
                    this.loading_download = false	
                    this.block_descargar  = false
                    if(res.data == 0)
                    {
                        this.$message({
                        message: 'No hay indices para descargar',
                        type: 'warning'
                        });
                    }else
                    {
                        // this.dialogExcel        = true
                        // console.log(res.data)
                        window.location.href 		= res.data
                    }				
                })
                .catch(error=>{
                    console.log(error)
                    this.$alert('No se pudo realizar la acción. Intente nuevamente');
                    this.loading_download 		= false;
                    this.block_descargar  = false
                });	
            },
        },
    }
</script>
<style>
.forzador .el-card__header{
    height: 85px;
    text-align: center;
    padding: 26px;
}
</style>
<style lang="scss" scoped>
.aviso{
    background: #fff9c470;
    padding: 20px;
    border-left: 3px solid #cddc39;
    word-break: break-word;
    border-radius: 4px;
}
.aviso1{
    background: #fff3e0;
    padding: 20px;
    border-left: 3px solid #ff9800;
    word-break: break-word;
    border-radius: 4px;
}
.aviso2{
    background: #ffebee;
    padding: 20px;
    border-left: 3px solid #f44336;
    word-break: break-word;
    border-radius: 4px;
}
</style>