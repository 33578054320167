<template>
  <el-row>
    <el-form>
      <el-col :md="4" :sm="8" :xs="24">
        <el-button
          @click="formDestinatario()"
          size="mini"
          :class="sistema.boton">
          <i class="el-icon-circle-plus-outline"> </i> Nuevo Destinatario
        </el-button>
      </el-col>
      <el-col :md="10" :sm="8" :xs="24">
        <el-input
          @keypress.native.enter="filtrar()"
          id="filter"
          name="filter"
          placeholder="Puede buscar por Razon social, dirección o calles"
          v-model="filter"
          size="mini" />
        <el-input type="text" v-show="false" /><!-- Previene reload -->
      </el-col>
      <el-col :md="10" :sm="8" :xs="24">
        <el-button-group>
          <el-button
            @click="filtrar()"
            icon="el-icon-search"
            size="mini"
            rounded
            style="margin-left: 10px"
            >Buscar</el-button
          >
          <el-button
            @click="limpiarFiltro()"
            icon="el-icon-refresh"
            size="mini"
            rounded
            style="margin-left: 10px"
            >Limpiar</el-button
          >
          <el-button
            @click="importDestinatario()"
            size="mini"
            :class="sistema.boton"
            style="margin-left: 10px">
            <i class="fas fa-file-import"></i> Importar Destinatario
          </el-button>
        </el-button-group>
      </el-col>
    </el-form>
  </el-row>
</template>
<script>
export default {
  name: 'destinatarios-filter',
  props: ['sistema'],
  data() {
    return {
      importar: false,
      filter: null,
    }
  },
  methods: {
    filtrar() {
      this.$events.fire('filter-set', this.filter)
    },
    limpiarFiltro() {
      this.filter = null
      this.$events.fire('filter-reset')
    },
    formDestinatario() {
      this.$events.fire('open-form-destinatario', 'ALTA')
    },
    importDestinatario() {
      this.$events.fire('open-form-importar')
    },
  },
}
</script>
