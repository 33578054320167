<template>
  <div v-loading="loading">
    <consulta></consulta>
    <tabla></tabla>
    <!-- <detalle></detalle> -->
  </div>
</template>
<script>
import Consulta from '@/components/guias/consulta.vue'
import TablaGuias from '@/components/guias/tablaGuias.vue'
export default {
  components: {
    consulta: Consulta,
    tabla: TablaGuias,
    // detalle: DetalleGuia,
  },
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
}
</script>
