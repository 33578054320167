<template>
  <el-form label-position="top" size="small">
    <el-row>
      <el-col :md="24" :sm="24" :xs="24">
        <el-button @click="formAgenda()" size="mini" class="btnCross" style="float: right !important"> <i class="el-icon-circle-plus-outline"> </i> Nuevo Contacto </el-button>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row :gutter="10">
      <el-col :md="18" :sm="16" :xs="24" style="padding-bottom: 5px">
        <div class="el-select el-select--small" style="width: 100%; line-height: 0px !important">
          <v-select
            id="selectConvenio"
            name="selectConvenio"
            ref="convenioRef"
            v-model="selectConvenio"
            :options="this.convenios"
            :filter="fuseSearch"
            :getOptionLabel="(option) => option.nombre"
            placeholder="Seleccione un convenio"
            @input="filtrar"
            multiple
            :reduce="(obj) => obj.convenio">
            <span slot="no-options">Sin Coincidencia</span>
            <template #option="{ nombre }">
              <cite>{{ nombre }}</cite>
            </template>
          </v-select>
        </div>
      </el-col>
      <el-col :md="6" :sm="8" :xs="24">
        <el-button-group>
          <el-button @click="limpiarFiltro()" icon="el-icon-refresh" size="mini" rounded style="margin-left: 10px">Limpiar</el-button>
        </el-button-group>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import { mapGetters } from 'vuex'

export default {
  name: 'agenda-filter',
  components: { vSelect },
  props: ['sistema'],
  mounted() {
    this.convenios = this.getTurneroConfig.convenios_all
  },
  data() {
    return {
      selectConvenio: [],
      convenios: [],
      importar: false,
    }
  },
  methods: {
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['nombre'],
        shouldSort: true,
        minMatchCharLength: 1,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    async filtrar() {
      // await this.delay(segundos * 1000)
      var filtros = {}
      //Check convenios
      if (this.selectConvenio != null) {
        filtros.filter_convenio = this.selectConvenio
      }
      this.$events.fire('filter-set', filtros)
    },
    limpiarFiltro() {
      this.selectConvenio = []
      this.$events.fire('filter-reset')
    },
    formAgenda() {
      this.$events.fire('open-form-agenda', 'ALTA')
    },
  },
  computed: {
    ...mapGetters('Turnos', ['getTurneroConfig']),
  },
}
</script>
