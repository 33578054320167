<template>
  <el-dialog
    :visible.sync="modal.visible"
    width="80%"
    @closed="cerrarForm()"
    :close-on-click-modal="false"
    :close-on-press-escape="true"
    :destroy-on-close="false"
    :show-close="false"
    top="3vh"
    class="modal-test">
    <span slot="title">
      <el-alert center :closable="false" effect="dark" style="background-color: #e5590a">
        <span slot="title">
          <h2 v-if="modal.row.convenio != null">Turno #{{ modal.row.id }} - {{ modal.row.convenio.descrip }}</h2>
          <h2 v-else>Turno #{{ modal.row.id }} - {{ this.getImportadora.descrip }}</h2>
        </span>
        <h3 style="text-align: center"><i class="far fa-calendar"></i> {{ modal.row.fecha }} | <i class="far fa-clock"></i> {{ modal.row.hora_inicial }}</h3>
        <h3 style="text-align: center" v-if="modal.row.estado > 1 && modal.row.estado <= 4">
          Recepción en NAVE {{ modal.row.nave }}
          <span v-if="modal.row.estado > 2 && modal.row.estado <= 4"> - Dock Nº {{ modal.row.dock.numero }}</span>
        </h3>
      </el-alert>
    </span>
    <el-form size="mini" label-width="170px" v-loading="config.loading">
      <!-- Datos basicos -->
      <el-row>
        <el-col :md="{ span: 24 }">
          <el-form-item label="Proveedor de Convenio">
            <el-input name="proveedor_convenio" type="text" readonly :value="modal.row.proveedor_convenio" />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 8 }">
          <el-form-item label="¿Necesita Frio?">
            <el-input name="check_frio" type="text" readonly :value="modal.row.check_frio === 1 ? 'SI' : 'NO'" />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 8 }">
          <el-form-item label="Tipo Unidad">
            <el-input name="tipo_unidad" type="text" readonly :value="modal.row.tipo_unidad" />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 8 }">
          <el-form-item label="Tipo Descarga">
            <el-input name="tipo_descarga" type="text" readonly :value="modal.row.tipo_descarga" />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="¿Proveedor de descarga?">
            <el-input name="check_cliente" type="text" readonly :value="modal.row.check_cliente === 1 ? 'SI' : 'NO'" />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }" v-if="modal.row.check_cliente === 1">
          <el-form-item label="Detalles:" prop="check_cliente_detalles">
            <el-input
              name="check_cliente_detalles"
              type="textarea"
              :rows="3"
              :maxlength="100"
              placeholder="Detalles del proveedor de descarga que ingresara al predio."
              :value="modal.row.check_cliente_detalles"
              readonly>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="modal.row.estado > 1 && modal.row.estado <= 5">
        <el-col :md="{ span: 12 }">
          <el-form-item label="Chofer:">
            <el-input name="chofer_nombre" :value="modal.row.chofer_nombre" readonly />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Dni:">
            <el-input name="chofer_dni" :value="modal.row.chofer_dni" readonly />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Empresa:">
            <el-input name="chofer_empresa" prefix-icon="el-icon-s-cooperation" :value="modal.row.chofer_empresa" readonly />
          </el-form-item>
        </el-col>
        <el-col :md="{ span: 12 }">
          <el-form-item label="Patente:">
            <el-input name="chofer_patente" prefix-icon="el-icon-truck" :value="modal.row.chofer_patente" readonly />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- Recuadro ESTADO -->
      <el-row style="box-shadow: 0 5px 10px rgba(9, 0, 0, 0.3)" class="alert-test">
        <el-alert type="info" center :closable="false" v-if="modal.row.estado <= 5">
          <br />
          <el-col :md="{ span: 12 }">
            <el-form-item label="Ingreso a Playa:">
              <el-date-picker
                name="datetime_playa"
                type="datetime"
                readonly
                :value="modal.row.datetime_playa"
                value-format="yyyy-MM-dd hh:mm:ss"
                format="HH:mm"
                style="width: 100%"
                placeholder="Sin registrar" />
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 12 }">
            <el-form-item label="Ingreso a Dock:">
              <el-date-picker
                name="datetime_dock"
                type="datetime"
                readonly
                :value="modal.row.datetime_dock"
                value-format="yyyy-MM-dd hh:mm:ss"
                format="HH:mm"
                style="width: 100%"
                placeholder="Sin registrar" />
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 12 }">
            <el-form-item label="Salida:">
              <el-date-picker
                name="datetime_atendido"
                type="datetime"
                readonly
                :value="modal.row.datetime_atendido"
                value-format="yyyy-MM-dd hh:mm:ss"
                format="HH:mm"
                style="width: 100%"
                placeholder="Sin registrar" />
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 12 }">
            <el-form-item label="Mostrar QR:">
              <el-button type="primary" size="mini" @click="$events.fire('open-qr', modal.row)"><i class="fas fa-qrcode"> QR Turno</i></el-button>
            </el-form-item>
          </el-col>
        </el-alert>
        <el-alert type="info" center :closable="false" v-if="modal.row.estado == 99">
          <el-form-item label="Horario de cancelacion:" style="width: 100%">
            <el-date-picker
              name="datetime_cancelado"
              type="datetime"
              readonly
              :value="modal.row.datetime_cancelado"
              value-format="yyyy-MM-dd hh:mm:ss"
              format="HH:mm"
              style="width: 100%"
              placeholder="Sin registrar" />
          </el-form-item>
        </el-alert>
        <el-alert type="info" center :closable="false" v-if="modal.row.estado < 4">
          <span slot="title">
            <h3>
              Cambio de estado a:
              <el-tag :type="formatEstado(modal.row.estado, 1).color">{{ formatEstado(modal.row.estado, 1).texto }}</el-tag>
            </h3>
          </span>
        </el-alert>
        <el-alert type="info" center :closable="false" v-if="modal.row.estado == 99">
          <span slot="title">
            <h3><el-tag type="danger">Turno Cancelado</el-tag></h3>
          </span>
        </el-alert>
      </el-row>
      <br />
      <!-- Cambio a TURNADO -->
      <el-row v-if="modal.row.estado == 0" justify="center">
        <el-form size="small" :model="turnado" :rules="rulesTurnado" ref="turnadoFormRef" label-width="300px" label-position="left">
          <el-col :md="{ span: 12 }">
            <el-form-item label="Cantidad aproximado de Pallets:" prop="aprox_pallets">
              <el-input-number id="aprox_pallets" name="aprox_pallets" v-model="turnado.aprox_pallets" :min="0" :max="9999" clearable />
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 12 }">
            <el-form-item label="Cantidad aprox de Granel o Bultos" prop="aprox_bultos">
              <el-input-number id="aprox_bultos" name="aprox_bultos" v-model="turnado.aprox_bultos" :min="0" :max="9999" clearable />
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 24 }">
            <el-form-item label="Seleccione una Nave" prop="nave">
              <el-select id="nave" name="nave" style="width: 100%" v-model="turnado.nave">
                <el-option v-for="item in naves" :key="item.value" :label="'Nave ' + item.value" :value="item.value">
                  <span style="float: left; font-size: 16px; font-style: italic; font-weight: bold; color: #27ae60">Nave {{ item.value }} </span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <!-- Cambio a PLAYA -->
      <el-row v-if="modal.row.estado == 1">
        <el-form size="small" :model="playa" :rules="rulesPlaya" ref="playaFormRef" label-width="170px">
          <el-col :md="{ span: 14 }">
            <el-form-item label="Chofer:" prop="chofer_nombre">
              <el-input name="chofer_nombre" ref="choferRef" id="chofer_nombre" v-model="playa.chofer_nombre" :maxlength="60" placeholder="Apellido y nombre del chofer" clearable />
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 10 }">
            <el-form-item label="Dni:" prop="chofer_dni">
              <el-input name="chofer_dni" id="chofer_dni" :maxlength="8" prefix-icon="el-icon-key" v-model="playa.chofer_dni" placeholder="DNI del chofer" clearable />
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 14 }">
            <el-form-item label="Empresa:" prop="chofer_empresa">
              <el-input name="chofer_empresa" id="chofer_empresa" :maxlength="60" v-model="playa.chofer_empresa" placeholder="Empresa" clearable />
            </el-form-item>
          </el-col>
          <el-col :md="{ span: 10 }">
            <el-form-item label="Patente:" prop="chofer_patente">
              <el-input
                name="chofer_patente"
                id="chofer_patente"
                prefix-icon="el-icon-truck"
                :maxlength="10"
                v-model="playa.chofer_patente"
                placeholder="Patente"
                @input="upperCase()"
                clearable />
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <!-- Cambio a DOCK -->
      <el-row v-if="modal.row.estado == 2">
        <br />
        <el-form size="small" :model="dock" :rules="rulesDock" ref="dockFormRef" label-width="170px">
          <el-col :md="{ span: 24 }">
            <el-form-item label="Seleccione un Dock" prop="dock_id">
              <el-select id="dock_id" name="dock_id" style="width: 100%" v-model="dock.dock_id" placeholder="Seleccione un dock disponible">
                <el-option v-for="item in docks" :key="item.horario" :label="'Dock Nº: ' + item.numero" :value="item.id" :disabled="item.estado == 0 ? true : false">
                  <span v-if="item.estado == 1" style="float: left; font-size: 16px; font-style: italic; font-weight: bold; color: #27ae60">Dock Nº: {{ item.numero }} </span>
                  <span v-else style="float: left; font-size: 16px; font-style: italic; font-weight: bold; color: #e74c3c">Dock Nº:{{ item.numero }} - No Disponible</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <!-- Cambio a ATENDIDO -->
      <el-row v-if="modal.row.estado == 3">
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label"> <i class="el-icon-s-order"></i> Datos de finalización </span>
            <el-form label-position="right" size="mini" :model="atendido" :rules="rulesAtendido" ref="atendidoFormRef" label-width="170px">
              <el-col :md="{ span: 8 }">
                <el-form-item label="Estado del camion" prop="carga_camion">
                  <el-select id="carga_camion" name="carga_camion" style="width: 100%" v-model="atendido.carga_camion" placeholder="Seleccione una opcion">
                    <el-option v-for="item in camion" :key="item.value" :label="item.value" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="Estado de la carga" prop="carga_estado">
                  <el-select id="carga_estado" name="carga_estado" style="width: 100%" v-model="atendido.carga_estado" placeholder="Seleccione una opcion">
                    <el-option v-for="item in camion" :key="item.value" :label="item.value" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="Nro Precinto:" prop="carga_precinto">
                  <el-input id="carga_precinto" name="carga_precinto" prefix-icon="el-icon-truck" :maxlength="15" v-model="atendido.carga_precinto" clearable />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 8 }">
                <el-form-item label="Cantidad de Pallets:" prop="carga_pallets">
                  <el-input-number id="carga_pallets" name="carga_pallets" prefix-icon="fas fa-pallet" v-model="atendido.carga_pallets" :min="0" :max="999" clearable />
                </el-form-item>
                <el-form-item label="Temperatura:" prop="carga_temperatura">
                  <el-input-number
                    :disabled="temperaturaEstado"
                    id="carga_temperatura"
                    name="carga_temperatura"
                    prefix-icon="fas fa-thermometer-half"
                    :precision="1"
                    :step="0.1"
                    v-model="atendido.carga_temperatura"
                    :min="-50"
                    :max="99"
                    clearable />
                </el-form-item>
                <el-form-item label="Tarimas devueltas:" prop="carga_tarimas">
                  <el-input-number id="carga_tarimas" name="carga_tarimas" prefix-icon="fas fa-thermometer-half" v-model="atendido.carga_tarimas" :min="0" :max="999" clearable />
                </el-form-item>
                <el-form-item label="Cantidad a Granel:" prop="carga_granel">
                  <el-input-number id="carga_granel" name="carga_granel" v-model="atendido.carga_granel" :min="0" :max="9999" clearable />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 8 }">
                <el-form label-position="top">
                  <el-form-item label="Observaciones:" prop="observaciones">
                    <el-input id="observaciones" name="observaciones" type="textarea" :rows="6" :maxlength="200" placeholder="Observaciones extras" v-model="atendido.observaciones">
                    </el-input>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-row>
      <!-- Cambio a FINALIZADO -->
      <el-row v-if="modal.row.estado == 4">
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label"> <i class="el-icon-circle-plus"></i> Carga de Comprobantes </span>
            <el-form label-position="right" label-width="130px">
              <el-row :gutter="10">
                <el-col :md="{ span: 4 }">
                  <el-button type="primary" icon="el-icon-plus" round plain size="mini" @click="addComprobante()"> Agregar Comprobante</el-button>
                </el-col>
              </el-row>
              <br />
              <el-row>
                <el-col>
                  <el-table size="mini" border stripe :data="finalizado.comprobantes" empty-text="Realice una busqueda para ver la información" style="width: 100%">
                    <el-table-column label="#" type="index" width="50"></el-table-column>
                    <el-table-column label="Comprobantes" min-width="25">
                      <editable-cell
                        size="mini"
                        :show-input="row.editMode"
                        slot-scope="{ row }"
                        v-model="row.tipo"
                        editable-component="el-select"
                        close-event="change"
                        @change="tipoLetra(row)">
                        <span slot="content">{{ row.tipo }}</span>
                        <template slot="edit-component-slot">
                          <el-option v-for="(item, index) in selectTipo" :key="index" :label="item.nombre" :value="item.nombre" />
                        </template>
                      </editable-cell>
                    </el-table-column>
                    <el-table-column label="" min-width="20">
                      <editable-cell size="mini" :show-input="row.editMode" slot-scope="{ row }" v-model="row.letra" editable-component="el-select" close-event="change">
                        <span slot="content">{{ row.letra }}</span>
                        <template slot="edit-component-slot">
                          <el-option v-for="(item, index) in selectLetra" :key="index" :label="item.nombre" :value="item.nombre" />
                        </template>
                      </editable-cell>
                    </el-table-column>
                    <el-table-column label="" min-width="30">
                      <editable-cell
                        size="mini"
                        :show-input="row.editMode"
                        slot-scope="{ row }"
                        v-model="row.punto"
                        clearable
                        editable-component="el-input"
                        :maxlength="4"
                        placeholder="XXXX"
                        type="text">
                        <span slot="content">{{ row.punto }}</span>
                      </editable-cell>
                    </el-table-column>
                    <el-table-column label="">
                      <editable-cell
                        size="mini"
                        :show-input="row.editMode"
                        slot-scope="{ row }"
                        v-model="row.numero"
                        clearable
                        editable-component="el-input"
                        :maxlength="8"
                        placeholder="YYYYYYYY"
                        type="text">
                        <span slot="content">{{ row.numero }}</span>
                      </editable-cell>
                    </el-table-column>
                    <el-table-column label="Acciones" min-width="22">
                      <template slot-scope="scope">
                        <el-button-group>
                          <el-button v-if="scope.row.editMode == false" size="mini" type="primary" icon="el-icon-edit" @click="setEditMode(scope.row, scope.$index)" />
                          <el-button
                            v-if="scope.row.editMode == true"
                            size="mini"
                            type="success"
                            icon="el-icon-check"
                            @click="saveRow(scope.row, scope.$index)"
                            v-on:keyup.enter="saveRow(scope.row, scope.$index)" />
                          <el-button size="mini" icon="el-icon-delete" type="danger" @click="deleteRow(scope.$index)" />
                        </el-button-group>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label"> <i class="el-icon-s-order"></i> Datos de finalización </span>
            <el-form label-position="right" size="mini" label-width="170px">
              <el-col :md="{ span: 7 }">
                <el-form-item label="Estado del camion">
                  <el-input name="carga_camion4" type="text" readonly :value="modal.row.carga_camion" prefix-icon="fas fa-truck" />
                </el-form-item>
                <el-form-item label="Estado de la carga">
                  <el-input name="carga_estado" type="text" readonly :value="modal.row.carga_estado" prefix-icon="fas fa-box" />
                </el-form-item>
                <el-form-item label="Nro Precinto:">
                  <el-input name="carga_precinto4" type="text" readonly :value="modal.row.carga_precinto" prefix-icon="fas fa-info" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 7 }">
                <el-form-item label="Cantidad de Pallets:">
                  <el-input name="carga_pallets4" type="text" readonly :value="modal.row.carga_pallets" prefix-icon="fas fa-pallet" />
                </el-form-item>
                <el-form-item label="Temperatura:">
                  <el-input name="carga_temperatura4" type="text" readonly :value="modal.row.carga_temperatura" prefix-icon="fas fa-thermometer-quarter" />
                </el-form-item>
                <el-form-item label="Tarimas devueltas:">
                  <el-input name="carga_tarimas" type="text" readonly :value="modal.row.carga_tarimas" prefix-icon="fas fa-undo" />
                </el-form-item>
                <el-form-item label="Cantidad a Granel:">
                  <el-input name="carga_granel" type="text" readonly :value="modal.row.carga_granel" prefix-icon="fas fa-undo" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 10 }">
                <el-form-item label="Observaciones:">
                  <el-input name="observaciones" type="textarea" :value="modal.row.observaciones"> </el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-row>
      <!-- Visor final -->
      <el-row v-if="modal.row.estado == 5">
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label"> <i class="el-icon-s-order"></i> Datos de finalización </span>
            <el-form label-position="right" size="mini" label-width="170px">
              <el-col :md="{ span: 7 }">
                <el-form-item label="Estado del camion">
                  <el-input name="carga_camion4" type="text" readonly :value="modal.row.carga_camion" prefix-icon="fas fa-truck" />
                </el-form-item>
                <el-form-item label="Estado de la carga">
                  <el-input name="carga_estado" type="text" readonly :value="modal.row.carga_estado" prefix-icon="fas fa-box" />
                </el-form-item>
                <el-form-item label="Nro Precinto:">
                  <el-input name="carga_precinto4" type="text" readonly :value="modal.row.carga_precinto" prefix-icon="fas fa-info" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 7 }">
                <el-form-item label="Cantidad de Pallets:">
                  <el-input name="carga_pallets4" type="text" readonly :value="modal.row.carga_pallets" prefix-icon="fas fa-pallet" />
                </el-form-item>
                <el-form-item label="Temperatura:">
                  <el-input name="carga_temperatura4" type="text" readonly :value="modal.row.carga_temperatura" prefix-icon="fas fa-thermometer-quarter" />
                </el-form-item>
                <el-form-item label="Tarimas devueltas:">
                  <el-input name="carga_tarimas" type="text" readonly :value="modal.row.carga_tarimas" prefix-icon="fas fa-undo" />
                </el-form-item>
                <el-form-item label="Cantidad a Granel:">
                  <el-input name="carga_granel" type="text" readonly :value="modal.row.carga_granel" prefix-icon="fas fa-undo" />
                </el-form-item>
              </el-col>
              <el-col :md="{ span: 10 }">
                <el-form-item label="Observaciones:">
                  <el-input name="observaciones" type="textarea" :value="modal.row.observaciones"> </el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label"> <i class="el-icon-s-management"></i> Comprobantes </span>
            <el-form label-position="right" label-width="130px">
              <br />
              <el-row>
                <el-col>
                  <el-table border stripe :data="modal.row.comprobantes" style="width: 100%">
                    <el-table-column label="#" type="index" width="50"></el-table-column>
                    <el-table-column label="Comprobantes" min-width="25" prop="valor"> </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button v-if="permisos == true" type="success" size="medium" @click="changeTurno()" icon="el-icon-refresh" :loading="btnDisabled">Informar cambio</el-button>
      <el-button type="danger" size="medium" @click="cerrarForm()" icon="el-icon-close">Cancelar</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { API } from '@/plugins/api'
import { estados, camion, naves, booleano } from '@/components/turnos/_auxiliar'
import { supabase } from '@/plugins/supabase'
import moment from 'moment'
import EditableCell from '@/components/cross/EditableCell'
import { mapGetters } from 'vuex'

export default {
  name: 'form-turno-modal',
  components: { EditableCell },
  props: {
    modal: { required: true },
  },
  mounted() {
    switch (this.modal.row.estado) {
      case 0:
        this.config.loading = false
        //this.checkDocks()

        break
      case 1:
        this.config.loading = false
        this.$nextTick(() => this.$refs.choferRef.focus())
        break
      case 2:
        this.checkDocks()
        break
      case 3:
      case 4:
      case 5:
        this.config.loading = false
        break
      case 99:
        this.config.loading = false
        break
    }
  },
  data() {
    return {
      btnDisabled: false,
      naves,
      config: {
        loading: true,
        icon: null,
        titulo: null,
        hora_inicial: true,
      },
      camion,
      turnado: {
        aprox_pallets: 0,
        aprox_bultos: 0,
        nave: null,
      },
      playa: {
        chofer_nombre: null,
        chofer_dni: null,
        chofer_empresa: null,
        chofer_patente: null,
      },
      atendido: {
        carga_camion: null,
        carga_estado: null,
        carga_pallets: 0,
        carga_temperatura: this.modal.row.check_frio === 1 ? 0 : 99,
        carga_precinto: null,
        carga_tarimas: 0,
        carga_granel: 0,
        observaciones: null,
      },
      finalizado: {
        comprobantes: [],
      },
      options: [],
      turno: {
        id: null,
        convenio_id: null,
        fecha: null,
        hora_inicial: null,
        nave: null,
        tipo_unidad: null,
        tipo_descarga: null,
        check_cliente: 0,
      },
      dock: {
        dock_id: null,
      },
      rulesTurnado: {
        aprox_pallets: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        aprox_bultos: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        nave: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
      },
      rulesPlaya: {
        chofer_nombre: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        chofer_dni: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        chofer_empresa: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        chofer_patente: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
      },
      rulesAtendido: {
        carga_camion: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        carga_estado: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        carga_pallets: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        carga_temperatura: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        carga_precinto: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        carga_tarimas: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
        carga_granel: [{ required: true, message: 'Campo obligatorio', trigger: 'change' }],
      },
      rulesDock: {
        dock_id: [{ required: true, message: 'Campo obligatorio', trigger: 'blur' }],
      },
      turnos_disponibles: [],
      docks: [],
      formValidado: false,
      booleano,
      selectTipo: [
        {
          nombre: 'REM',
        },
      ],
      selectLetra: [{ nombre: 'R' }, { nombre: 'X' }, { nombre: 'E' }],
    }
  },
  methods: {
    cerrarForm() {
      this.$events.fire('close-form')
    },
    async changeTurno() {
      this.btnDisabled = true
      switch (this.modal.row.estado) {
        case 0: {
          try {
            await this.validarFormulario('turnadoFormRef')
            if (this.turnado.aprox_pallets <= 0 && this.turnado.aprox_bultos <= 0) {
              this.formValidado = false
              this.btnDisabled = false
              return this.$message({
                showClose: true,
                message: 'Debe cargar al cantidad aproximada de pallets, granel o bultos',
                type: 'warning',
              })
            }

            if (this.formValidado) {
              console.log('inicio consulta')
              const response = await API.post('turnero/turnos/change', {
                turno_id: this.modal.row.id,
                estado: this.modal.row.estado + 1,
                estado_viejo: this.modal.row.estado,
                uid: this.getUid,
                form: this.turnado,
              })
              const supabase = await this.supabase(response.data.data)
              this.cerrarForm()
              this.$events.fire('refrescar-tabla')
              if (supabase.status == 201)
                this.$message({
                  message: response.data.message,
                  type: 'success',
                })
              else
                this.$message({
                  message: 'Turno actualizado - Error en tracking realtime',
                  type: 'success',
                })
              console.log('fin consulta')
            }
          } catch (error) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
            })
            this.btnDisabled = false
            this.cerrarForm()
            this.$events.fire('refrescar-tabla')
          }
          break
        }
        case 1: {
          try {
            await this.validarFormulario('playaFormRef')
            if (this.formValidado) {
              console.log('inicio consulta')
              const response = await API.post('turnero/turnos/change', {
                turno_id: this.modal.row.id,
                estado: this.modal.row.estado + 1,
                estado_viejo: this.modal.row.estado,
                uid: this.getUid,
                form: this.playa,
              })
              const supabase = await this.supabase(response.data.data)
              this.cerrarForm()
              this.$events.fire('refrescar-tabla')

              if (supabase.status == 200) {
                this.$message({
                  message: response.data.message,
                  type: 'success',
                })
                console.log('todo ok')
              } else {
                this.$message({
                  message: 'Turno actualizado - pero sin trancking',
                  type: 'success',
                })
                console.log('error raro')
              }

              console.log('fin consulta')
            }
          } catch (error) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
            })
            this.btnDisabled = false
            this.cerrarForm()
            this.$events.fire('refrescar-tabla')
          }
          break
        }
        case 2: {
          try {
            await this.validarFormulario('dockFormRef')
            if (this.formValidado) {
              console.log('inicio consulta')
              const response = await API.post('turnero/turnos/change', {
                turno_id: this.modal.row.id,
                estado: this.modal.row.estado + 1,
                estado_viejo: this.modal.row.estado,
                uid: this.getUid,
                form: this.dock,
              })
              const supabase = await this.supabase(response.data.data)
              this.cerrarForm()
              this.$events.fire('refrescar-tabla')
              if (supabase.status == 200)
                this.$message({
                  message: response.data.message,
                  type: 'success',
                })
              else
                this.$message({
                  message: 'Turno actualizado - pero sin trancking',
                  type: 'success',
                })
              console.log('fin consulta')
            }
          } catch (error) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
            })
            this.btnDisabled = false
            this.cerrarForm()
            this.$events.fire('refrescar-tabla')
          }
          break
        }
        case 3: {
          try {
            await this.validarFormulario('atendidoFormRef')
            if (this.formValidado) {
              console.log('inicio consulta')

              const response = await API.post('turnero/turnos/change', {
                turno_id: this.modal.row.id,
                estado: this.modal.row.estado + 1,
                estado_viejo: this.modal.row.estado,
                uid: this.getUid,
                form: this.atendido,
              })

              const supabase = await this.supabase(response.data.data)
              this.cerrarForm()
              this.$events.fire('refrescar-tabla')
              if (supabase.status == 200)
                this.$message({
                  message: response.data.message,
                  type: 'success',
                })
              else
                this.$message({
                  message: 'Turno actualizado - pero sin trancking',
                  type: 'success',
                })
              console.log('fin consulta')
            }
          } catch (error) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
            })
            this.btnDisabled = false
            this.cerrarForm()
            this.$events.fire('refrescar-tabla')
          }
          break
        }
        case 4: {
          try {
            //Validacion de comprobantes
            if (
              this.finalizado.comprobantes.filter(function (e) {
                return e.editMode === true
              }).length > 0
            ) {
              this.btnDisabled = false
              return this.$message({
                showClose: true,
                message: 'Debe confirmar los datos de todos los remitos cargados.',
                type: 'warning',
              })
            }
            //Valido que tenga al menos un comprobante.
            if (this.finalizado.comprobantes.length <= 0) {
              this.btnDisabled = false
              return this.$message({
                showClose: true,
                message: 'Debe al menos cargar un remito antes de cambiar el estado del turno.',
                type: 'warning',
              })
            }
            //Todo validado ... continuo
            console.log('inicio consulta')

            const response = await API.post('turnero/turnos/change', {
              turno_id: this.modal.row.id,
              estado: this.modal.row.estado + 1,
              estado_viejo: this.modal.row.estado,
              uid: this.getUid,
              form: this.finalizado,
            })

            const supabase = await this.supabase(response.data.data)
            this.cerrarForm()
            this.$events.fire('refrescar-tabla')
            if (supabase.status == 200)
              this.$message({
                message: response.data.message,
                type: 'success',
              })
            else
              this.$message({
                message: 'Turno actualizado - pero sin trancking',
                type: 'success',
              })
            console.log('fin consulta')
          } catch (error) {
            this.$message({
              message: error.response.data.message,
              type: 'error',
            })
            this.btnDisabled = false
            this.cerrarForm()
            this.$events.fire('refrescar-tabla')
          }
          break
        }
        default:
          break
      }
    },
    async supabase(turnito) {
      let supa
      switch (turnito.estado) {
        case 1: {
          console.log('insert supa turnado')
          supa = await supabase
            .from(process.env.VUE_APP_SUPABASE_TABLE)
            .insert([
              {
                turno_id: turnito.id,
                fecha_turno: turnito.fecha,
                hora_inicial: turnito.hora_inicial,
                nave: turnito.nave,
                estado: turnito.estado,
              },
            ])
            .select()
          //console.log(supa)
          console.log('fin supabase')
          break
        }
        case 2: {
          console.log('update supabase - playa')
          supa = await supabase
            .from(process.env.VUE_APP_SUPABASE_TABLE)
            .update([
              {
                chofer_nombre: turnito.chofer_nombre,
                chofer_dni: turnito.chofer_dni,
                chofer_empresa: turnito.chofer_empresa,
                chofer_patente: turnito.chofer_patente,
                datetime_playa: turnito.datetime_playa,
                estado: turnito.estado,
              },
            ])
            .eq('turno_id', turnito.id)
            .select()
          //console.log(supa)
          console.log('fin supabase')
          break
        }
        case 3: {
          console.log('update supabase - dock')
          supa = await supabase
            .from(process.env.VUE_APP_SUPABASE_TABLE)
            .update([
              {
                estado: turnito.estado,
                datetime_dock: turnito.datetime_dock,
                dock: turnito.dock_numero,
              },
            ])
            .eq('turno_id', turnito.id)
            .select()
          //console.log(supa)
          console.log('fin supabase')
          break
        }
        case 4: {
          console.log('update supabase - atendido')
          supa = await supabase
            .from(process.env.VUE_APP_SUPABASE_TABLE)
            .update([
              {
                estado: turnito.estado,
                datetime_atendido: turnito.datetime_atendido,
              },
            ])
            .eq('turno_id', turnito.id)
            .select()
          //console.log(supa)
          console.log('fin supabase')
          break
        }
        case 5: {
          console.log('update supabase - finalizado')
          supa = await supabase
            .from(process.env.VUE_APP_SUPABASE_TABLE)
            .update([
              {
                estado: turnito.estado,
                datetime_finalizado: turnito.datetime_finalizado,
              },
            ])
            .eq('turno_id', turnito.id)
            .select()
          //console.log(supa)
          console.log('fin supabase')
          break
        }
        default:
          break
      }
      return supa
    },
    formatEstado(value, tipo = 0) {
      let estadoProps
      if (tipo == 1) estadoProps = value + 1
      else estadoProps = value
      let objeto = estados.find((x) => x.estado == estadoProps)
      if (objeto === undefined) return null
      else return objeto
    },
    checkDocks() {
      this.docks = []
      this.dock.dock_id = null
      API.get('turnero/docks', { params: { nave: this.modal.row.nave } })
        .then((response) => {
          this.docks = response.data.data
          this.config.loading = false
        })
        .catch((error) => {
          this.$message({ message: error.response.data.message, type: 'error' })
        })
    },
    upperCase() {
      this.playa.chofer_patente = this.playa.chofer_patente.replace(/[^a-zA-Z0-9]/g, '')
      this.playa.chofer_patente = this.playa.chofer_patente.toUpperCase()
    },
    async validarFormulario(formulario) {
      await this.$refs[formulario].validate((valid) => {
        //Control de Form
        if (!valid) {
          this.btnDisabled = false
          return this.$message({
            showClose: true,
            message: 'Debe completar los el formulario para continuar.',
            type: 'warning',
          })
        }
        this.formValidado = true
        //Control numerico
        // if (isNaN(this.playa.chofer_dni)) {
        // 	this.btnDisabled = false
        // 	return this.$message({
        // 		showClose: true,
        // 		message: 'Debe ingresar un DNI valido, solo numeros',
        // 		type: 'warning'
        // 	})
        // }
      })
    },
    formatDate(value, fmt = 'D MMM YYYY') {
      return value == null ? 'Sin registro' : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt)
    },
    addComprobante() {
      let comprobante = {}
      comprobante['id'] = Date.now()
      comprobante['tipo'] = 'REM'
      comprobante['letra'] = 'R'
      comprobante['punto'] = null
      comprobante['numero'] = null
      comprobante['codigo'] = null
      comprobante['editMode'] = true
      this.finalizado.comprobantes.push(comprobante)
    },
    setEditMode(row) {
      row.editMode = true
    },
    saveRow(row) {
      //Control requerido
      if (row.tipo == null || row.letra == null || row.punto == null || row.numero == null)
        return this.$message({
          showClose: true,
          message: 'Debe completar los datos del remito antes de validar.',
          type: 'warning',
        })
      //Control numerico
      if (isNaN(row.numero) || isNaN(row.numero))
        return this.$message({
          showClose: true,
          message: 'Los valores punto de venta y numero deben ser numericos.',
          type: 'warning',
        })

      //Comprobar que no repita codigos de comprobantes
      var contadorComprobantes = 0
      this.finalizado.comprobantes.forEach((comprobante) => {
        if (
          comprobante.tipo + '-' + comprobante.letra + '-' + comprobante.punto.toString().padStart(4, '0') + '-' + comprobante.numero.toString().padStart(8, '0') ===
          row.tipo + '-' + row.letra + '-' + row.punto.toString().padStart(4, '0') + '-' + row.numero.toString().padStart(8, '0')
        )
          contadorComprobantes++
      })

      if (contadorComprobantes > 1)
        return this.$message({
          showClose: true,
          message: 'El comprobante esta repetido.',
          type: 'warning',
        })
      //Completo con ceros
      row.punto = row.punto.toString().padStart(4, '0')
      row.numero = row.numero.toString().padStart(8, '0')
      row.codigo = row.tipo + '-' + row.letra + '-' + row.punto + '-' + row.numero
      row.editMode = false
    },
    deleteRow(index) {
      this.finalizado.comprobantes.splice(index, 1)
    },
  },
  computed: {
    ...mapGetters(['getDatos', 'getUid']),
    ...mapGetters('Turnos', ['getImportadora']),
    permisos() {
      let turnos_admin = this.getDatos.permisos.includes('TURNOS_ADMIN') ? true : false
      let turnos_operario = this.getDatos.permisos.includes('TURNOS_OPERARIO') ? true : false
      let turnos_vigilancia = this.getDatos.permisos.includes('TURNOS_VIGILANCIA') ? true : false
      switch (this.modal.row.estado) {
        case 0: //Cambio a turnado
          //Agrego operario de forma temporal
          if (turnos_admin || turnos_operario) return true
          else return false
        case 1: //Cambio a Playa
          //Agrego operario de forma temporal
          if (turnos_admin || turnos_operario || turnos_vigilancia) return true
          else return false
        case 2: //Cambio a dock
          if (turnos_admin || turnos_operario) return true
          else return false
        case 3: //Cambio a atendido
          if (turnos_admin) return true //|| turnos_operario
          else return false
        case 4: //Cambio a finalizado
          if (turnos_admin) return true //|| turnos_operario
          else return false
        default:
          return false
      }
    },
    temperaturaEstado() {
      return this.modal.row.check_frio === 0 ? true : false
    },
  },
}
</script>
<style>
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-test .el-dialog__header {
  background-color: #e5590a;
  padding: 0px;
}
.modal-test .el-dialog__body {
  background-color: #fcf5e9;
}
.modal-test .el-dialog__footer {
  background-color: #fcf5e9;
}
.modal-test .el-collapse-item__header {
  background-color: #fcf5e9;
  /*box-shadow: 0 5px 10px rgba(9, 0, 0, 0.3);*/
}
.modal-test .el-collapse-item__wrap {
  background-color: #fcf5e9;
}
.modal-test .el-collapse-item__content {
  background-color: #fcf5e9;
}
.alert-test .el-alert {
  padding: 0px;
}
</style>
