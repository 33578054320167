<template>
  <div>
    <el-row :gutter="20" style="padding-bottom: 5px" v-if="tipo == 'SG'">
      <el-col :md="12" :xs="12" :sm="12" :offset="12"
        ><el-button round style="float: right" :style="styleTitleTabla" @click="mostrarTabla" size="mini" icon="el-icon-back"> Regresar</el-button></el-col
      >
    </el-row>
    <el-row :gutter="20" style="padding-bottom: 5px" v-if="tipo == 'SUC'">
      <el-col :md="12" :xs="12" :sm="12" :offset="12"
        ><el-button round style="float: right" :style="styleTitleTabla" @click="bloquearVistas" size="mini" icon="el-icon-back"> Vista Principal de Resumen</el-button></el-col
      >
    </el-row>
    <el-row>
      <el-col :md="24">
        <el-card shadow="always" class="instalaciones" style="padding-bottom: 0px; padding-top: 10px; padding: 0px !important">
          <el-row :gutter="20">
            <el-col :md="8" :sm="12" :xs="24">
              <label for="" class="vpim-label">Buscar Comprobante completo</label><label v-if="mes != 'TODOS'"> mes {{ mes }}:</label>
              <el-input size="small" v-model="filterComprob" placeholder="Buscar comprobante" @keyup.native.prevent.enter="filtrarComprob(filterComprob)"></el-input>
            </el-col>
            <el-col :md="12" :sm="12" :xs="24">
              <el-button-group style="margin-top: 18px">
                <el-button type="primary" size="small" icon="el-icon-search" @click="filtrarComprob(filterComprob)">
                  <!-- @click="buscarDatos(formInline)"> -->
                  Buscar
                </el-button>
                <el-button type="default" size="small" icon="el-icon-close" @click="filtrarComprob(false)">
                  <!-- @click="limpiarFiltro()"> -->
                  Limpiar
                </el-button>
              </el-button-group>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <p v-if="tipo == 'SG'" class="font-title" :style="styleTitleTabla">Total de comprobantes sin Guías: {{ totalRow }} - {{ getNombreConv(datosTabla.convol) }} - MES : {{ mes }}</p>
      <p v-if="tipo == 'SUC'" class="font-title" :style="styleTitleTabla">
        Total de comprobantes: {{ totalRow }} - ({{ datosTabla.sucursal.codigo }}) {{ datosTabla.sucursal.nombre }} - MES : {{ mes }}
      </p>
    </el-row>

    <el-table
      :data="tablaD"
      border
      fit
      stripe
      style="width: 100%"
      size="mini"
      v-loading="loadTabla"
      element-loading-text="Cargando comprobantes..."
      element-loading-spinner="el-icon-loading">
      <el-table-column prop="comprob" label="Comprobante" width="120"></el-table-column>
      <el-table-column prop="fecha_comprobante" label="Fecha Comprob." width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.fecha_comprobante">{{ scope.row.fecha_comprobante.date | fechaE }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="convol" label="Convenio">
        getNombreConv
        <template slot-scope="scope"> {{ scope.row.convol }} - {{ getNombreConv(scope.row.convol) }} </template>
      </el-table-column>
      <el-table-column prop="cuit" label="CUIT" width="100"></el-table-column>
      <el-table-column prop="tipo" label="Tipo" width="80"></el-table-column>
      <el-table-column prop="comprob" label="Fecha escaneo" width="140">
        <template slot-scope="scope">
          <span v-if="scope.row.fecha_escaneo">{{ scope.row.fecha_escaneo.date | fechaE }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="" width="370">
        <template slot-scope="scope">
          <center>
            <el-button-group>
              <el-tooltip class="item" effect="dark" content="Ver pdf" placement="top">
                <el-button :type="'warning'" :icon="'el-icon-document-add'" :loading="descargando == scope.row.id" size="mini" plain @click="pdf2(scope.row)">Ver</el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                <el-button :type="'success'" :icon="'el-icon-edit'" size="mini" plain @click="editarModal(scope.row)"> Editar</el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Guía" placement="top">
                <el-button type="link" size="mini" @click="getDetalle(scope.row)" :loading="loadBtn === scope.row.id"><i class="el-icon-document-checked"></i> Guía</el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Eliminar" placement="top-end">
                <el-button :type="'danger'" plain size="mini" @click="showDelete(scope.row)" :loading="loadBtn == scope.row.id" v-if="permiso_escaner"
                  ><i class="el-icon-delete"></i> Eliminar</el-button
                >
              </el-tooltip>
            </el-button-group>
          </center>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalRow"
      style="margin-bottom: 20px; margin-left: 10px; float: right"
      background
      layout="total,prev, pager, next"
      :total="totalRow"
      :page-size="20"
      @current-change="nextPage"
      :current-page.sync="pagina_actual">
    </el-pagination>

    <!-- ----------------------Modal editar comprobantes--------------------- -->
    <el-dialog :visible.sync="dialogEditar" width="50%" @closed="limpiarTodo(true)" :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="false" :top="'0vh'">
      <el-row style="border-bottom: 1px dashed #ddd; margin-top: -25px; margin-bottom: 10px" :gutter="10">
        <el-col :md="24">
          <span style="float: left; font-size: 20px; text-aling: center"
            ><strong> <i class="el-icon-edit"></i> {{ titleModal }}</strong></span
          >
        </el-col>
      </el-row>
      <el-form :inline="true" :model="formInline" :rules="regla1" ref="formInline" class="demo-form-inline" v-if="tipo == 'SG'" @submit.native.prevent="buscarEscaneo('formInline')">
        <!-- Formulario para los otros -->
        <el-form-item label="Comprobante" prop="comproba">
          <el-input v-model="formInline.comproba" ref="inputComprob" size="mini" minlength="12" placeholder="Ingrese comprobante" clearable :disabled="form1" />
          <div class="el-form-item__error" style="white-space: pre" v-if="errorBuscar">
            {{ errorBuscar }}
          </div>
        </el-form-item>
        <!-- ------------------------ -->
        <el-form-item>
          <el-button-group>
            <el-button type="primary" size="mini" @click="buscarEscaneo('formInline')" title="Buscar comprobante" :loading="buscando" :disabled="form1">Buscar</el-button>
            <el-button @click="limpiarTodo(false)" size="mini">Limpiar</el-button>
          </el-button-group>
        </el-form-item>
        <el-form-item>
          <el-button :type="'danger'" v-if="datosComprob != ''" :icon="'el-icon-document-add'" size="mini" plain @click="pdf2(datosComprob)">Pdf</el-button>
        </el-form-item>
      </el-form>

      <el-form :model="formCrear" :rules="rules" ref="formCrear" label-position="top" label-width="100px" class="demo-ruleForm">
        <div v-if="showForm">
          <h4 v-if="showMje" style="text-decoration: underline; margin: 0px; text-decoration-style: dotted">
            <center>Número de Comprobante: {{ formCrear.comprob }}</center>
          </h4>
          <el-row :gutter="10" class="vguia-row">
            <el-col :md="12" :sm="12" :xs="24">
              <el-form-item label="Terminal de guía:" prop="terminal" style="padding-bottom: 5px; margin-bottom: 5px !important">
                <el-input v-model="formCrear.terminal" size="mini" placeholder="Terminal" ref="user" disabled prefix-icon="el-icon-check"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" :sm="12" :xs="24">
              <el-form-item label="Número de guía:" prop="numero" style="padding-bottom: 5px; margin-bottom: 5px !important">
                <el-input v-model="formCrear.numero" size="mini" placeholder="Número" disabled prefix-icon="el-icon-check"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="vguia-row">
            <el-col :md="12" :sm="12" :xs="24">
              <el-form-item label="Sucursal de escaneo:" prop="sucursal">
                <el-select v-model="formCrear.sucursal" placeholder="Seleccione sucursal" class="vpim-select" size="mini" clearable filterable>
                  <el-option v-for="item in sucursales" :key="item.codigo" :label="item.codigo + ' - ' + item.nombre" :value="item.codigo"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12" :sm="12" :xs="24">
              <el-form-item label="Comprobante:" prop="comprob">
                <el-input v-model.number="formCrear.comprob" size="mini" placeholder="Comprobante" clearable disabled prefix-icon="el-icon-check"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="showMje" class="vguia-row">
            <el-col :span="24">
              <el-alert :title="mensaje" type="info" show-icon :closable="false"> </el-alert>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="vguia-row">
            <center>
              <el-col :span="24">
                <el-form-item label="" style="margin-bottom: 0px !important">
                  <el-button type="primary" @click="enviarForm('formCrear')" icon="el-icon-check" :loading="loadingGuardar" size="mini">Guardar</el-button>
                  <el-button @click="limpiarTodo(true)" icon="el-icon-close" size="mini">Cancelar</el-button>
                </el-form-item>
              </el-col>
            </center>
          </el-row>
        </div>
      </el-form>
    </el-dialog>
    <!-- --------------------------------------------------------------- -->
    <!-- Modal pdf -->
    <el-dialog :visible.sync="modalPdf" width="600px" top="1vh" class="soporteModal">
      <el-carousel :autoplay="false" arrow="always" height="80vh" :indicator-position="'none'">
        <el-carousel-item v-for="c in numPages" :key="c.id" class="comprob" @click.native="openFoto(c.ruta)">
          <pdf :src="c.ruta"></pdf>
        </el-carousel-item>
      </el-carousel>
      <el-button-group>
        <el-button size="mini" type="danger" @click="openUrl(rutaPdf)">DESCARGAR COMPROBANTE </el-button>
      </el-button-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="modalPdf = false">Cerrar</el-button>
      </span>
    </el-dialog>
    <!-- MODAL ELIMINAR COMPROBANTE -->
    <el-dialog :title="titleEliminar" :visible.sync="dialogDelete" width="45%" center :close-on-click-modal="false" :before-close="beforeDelete()">
      <span style="word-break: break-word"
        >¿Está seguro de eliminar el comprobante: <strong>{{ datosEliminar.comprob }}</strong
        >?
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="deleteComprobante()" size="small" icon="el-icon-delete" :loading="loadBtn">Eliminar</el-button>
        <el-button @click="cancelarEliminar()" size="small" icon="el-icon-close">Cancelar</el-button>
      </span>
    </el-dialog>
    <detalle></detalle>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
const moment = require('moment')
import { EventBus } from '@/event-bus'
import pdf from 'vue-pdf'
import DetalleGuia from '@/components/guias/detalleGuia.vue'

export default {
  components: {
    pdf,
    detalle: DetalleGuia,
  },
  props: ['datosTabla', 'styleTitleTabla', 'tipo', 'conv', 'mes'],
  data() {
    let checkTamanio = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Ingrese número de comprobante'))
      }
      if (value.length < 8) {
        return callback(new Error('Debe tener al menos 8 caracteres'))
      } else {
        return callback()
      }
    }
    let checkTerminal = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Ingrese Terminal de comprobante'))
      }
      if (value.length > 4) {
        return callback(new Error('Debe tener máximo 4 caracteres'))
      } else if (value.length < 4) {
        return callback(new Error('Debe tener al menos 4 caracteres'))
      } else {
        return callback()
      }
    }
    let checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Ingrese número de comprobante'))
      }
      if (value.length < 1) {
        return callback(new Error('Debe completar el dato'))
      } else {
        return callback()
      }
    }
    return {
      tablaD: [],
      convenios: [],
      totalRow: 0,
      pagina_actual: 0,
      loadTabla: false,
      formCrear: {
        terminal: '',
        numero: '',
        sucursal: '',
        comprob: '',
      },
      dialogEditar: false,
      sucursales: [],
      titleModal: '',
      rules: {
        sucursal: [
          {
            required: true,
            message: 'Por favor elija una sucursal',
            trigger: 'change',
          },
        ],
        comprob: [
          {
            required: true,
            message: 'No se cargó el comprobante',
            trigger: 'change',
          },
        ],
      },
      loadingGuardar: false,
      showMje: false,
      // mensaje: 'Los datos previamente cargados se encuentran asociados al comprobante, pero no están como datos del comprobante escaneado. Por lo tanto si los datos son correctos debe guardar los mismos.',
      mensaje: 'Los datos previamente cargados se encuentran asociados al comprobante, pero no así al comprobante escaneado. Si los datos son correctos guarde los mismos.',
      loadBtn: false,
      datosComprob: '',
      modalPdf: false,
      rutaPdf: '',
      empresaUrl: window.localStorage.getItem('empresa'),
      operador: JSON.parse(window.localStorage.getItem('datos')).uid + '-' + JSON.parse(window.localStorage.getItem('datos')).user,
      showForm: false,
      disabledComprob: true,
      buscando: false,
      form1: false,
      formInline: {
        comproba: null,
      },
      btnBus: false,
      regla1: {
        comproba: [{ validator: checkTamanio, trigger: 'blur' }],
      },
      reglaComprob: {
        terminal: [{ validator: checkTerminal, trigger: 'blur' }],
        numero: [{ validator: checkNum, trigger: 'blur' }],
      },
      comprob_buscar: '',
      errorBuscar: '',
      filterComprob: '',
      conv_especial: false,
      inputComprob: false,
      formComprob: {
        terminal: '',
        numero: '',
      },
      permiso_escaner: JSON.parse(window.localStorage.getItem('datos')).permisos.includes('ESCANER'),
      dialogDelete: false,
      datosEliminar: '',
      titleEliminar: '',
      numPages: [],
      descargando: false,
    }
  },
  mounted() {
    this.getConvenios()
    setTimeout(() => {
      this.getSucursales()
    }, 150)
    if (this.tipo == 'SG') {
      this.getDatosTabla(1, this.datosTabla, false)
      this.showForm = false
      this.disabledComprob = false
      let diferente_conv = [389, 412, 13]
      this.datosComprob = ''
      this.conv_especial = diferente_conv.find((element) => element == this.conv)
      if (typeof this.conv_especial != 'undefined') {
        this.inputComprob = true
      }
    } else if (this.tipo == 'SUC') {
      this.showForm = true
      this.disabledComprob = true
      this.loadTabla = true
      setTimeout(() => {
        this.getComprobSuc(1, this.datosTabla, false)
      }, 150)
    }
  },
  beforeDestroy() {
    EventBus.$off('abrirModalDetalles', false)
    EventBus.$off('pagPrincipal', false)
    EventBus.$off('pagConvenios', false)
  },
  methods: {
    resetForm() {
      this.dialogEditar = false
    },

    nextPage(page) {
      if (this.tipo == 'SG') {
        this.getDatosTabla(page, this.datosTabla, false)
      } else if (this.tipo == 'SUC') {
        this.getComprobSuc(page, this.datosTabla, false)
        // console.log(this.tipo)
      }
    },
    getDatosTabla(pagina, datos, buscar) {
      this.pagina_actual = pagina
      this.loadTabla = true
      HTTP.post('/escaner/getSinsuc', {
        pag: pagina,
        datosBuscar: datos,
        buscarC: buscar,
      })
        .then((res) => {
          if (res.data) {
            this.totalRow = res.data.length > 0 ? res.data[0].TotalRows : 0
            this.tablaD = res.data
          } else {
            this.$message({
              message: 'Ocurrió un problema,refresque la página e intente nuevamente.',
              type: 'warning',
            })
          }
          this.loadTabla = false
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
          this.loadTabla = false
        })
    },
    getConvenios() {
      HTTP.post('/escaner/getConvenios').then((res) => {
        this.convenios = res.data
        this.convenios.push({ codigo: 999, descrip: 'COMPROBANTES LOGIN' }, { codigo: 0, descrip: 'COMPROBANTES SUIZO' })
        // this.convenios['logistica'].push({codigo:999,descrip:'COMPROBANTES LOGIN'})
        // this.convenios['receta'].push({codigo:999,descrip:'COMPROBANTES LOGIN'})
        // this.convenios['receta'].push({codigo:0,descrip:'COMPROBANTES SUIZO'})
        // this.convenios['logistica'].push({codigo:0,descrip:'COMPROBANTES SUIZO'})
        this.convenios.sort(function (a, b) {
          if (a.descrip > b.descrip) {
            return 1
          } else if (a.descrip < b.descrip) {
            return -1
          }
          return 0
        })
        // this.convenios['logistica'].sort(function (a, b) {
        //     if (a.descrip > b.descrip) {
        //       return 1;
        //     } else if (a.descrip < b.descrip) {
        //       return -1;
        //     }
        //     return 0;
        //   });
        //   this.convenios['receta'].sort(function (a, b) {
        //     if (a.descrip > b.descrip) {
        //       return 1;
        //     } else if (a.descrip < b.descrip) {
        //       return -1;
        //     }
        //     return 0;
        //   });
      })
    },
    getNombreConv(convol) {
      // let tipo = empresa == "DSA" ? "receta" : "logistica";
      if (convol > 0) {
        // if (
        //   (this.convenios["receta"] && this.convenios["receta"].length) ||
        //   (this.convenios["logistica"] && this.convenios["logistica"].length)
        // )
        // {
        // let filtrado = this.convenios[tipo].filter((f) => {
        //   return f.codigo == convol;
        // });
        let filtrado = this.convenios.filter((f) => {
          return f.codigo == convol
        })
        let descript = ''
        if (typeof filtrado[0] !== 'undefined') {
          descript = filtrado[0].descrip
        }
        // if(filtrado[0].descrip)
        // {
        //   descript = filtrado[0].descrip
        // }
        return descript
        // }
      } else if (convol == 0 || convol == 999) {
        let convenioCero = convol == 0 ? 'COMPROBANTES SUIZO' : 'COMPROBANTES LOGIN'
        return convenioCero
      }
    },
    pdf2(c) {
      this.numPages = []
      if (c.comprob) {
        this.descargando = c.id
        this.url = c.empresa == 'DSA' && this.empresaUrl != 'lo' ? 'https://seguimiento.suizoargentina.com.ar/seguimiento' : 'https://seguimiento.log-in.com.ar/seguimiento'

        HTTP.post('/escanerPrueba/downloadSinGuia', {
          ruta: c.ruta,
          id: c.id,
          empresa: c.empresa,
        })
          .then((res) => {
            this.descargando = false
            if (res.data[0].status === 1) {
              this.rutaPdf = this.url + res.data[0].archivo
              this.numPages.push({
                id: c.hojas,
                ruta: this.url + res.data[0].archivo,
              })
              setTimeout(() => {
                this.modalPdf = true
              }, 100)
            } else if (res.data[0].status === 0) {
              this.$alert('No se pudo recuperar el archivo ', res.data)
            }
          })
          .catch(() => {
            this.descargando = false
            this.modalPdf = false
            this.$alert('Error al descargar el archivo ')
          })
      } else {
        let tipo = c.ruta.split('.')[1]
        if (tipo && tipo == 'pdf') {
          this.descargando = c.id

          this.url = 'https://cdn.log-in.com.ar/escaner/'
          this.rutaPdf = this.url + c.ruta
          this.numPages.push({
            id: c.hojas,
            ruta: this.ruta,
          })
          setTimeout(() => {
            this.descargando = false
            this.modalPdf = true
          }, 100)
        } else {
          this.$alert('El archivo está dañado o no existe ')
        }
      }
    },
    getSucursales() {
      HTTP.post('/guiasNew/getSucursales', { codigo: this.formCrear.sucursal })
        .then((res) => {
          this.sucursales = res.data
        })
        .catch((error) => console.log(error))
    },
    getDatosOlcomprob(datos) {
      // console.log(datos)
      this.showMje = false
      if (datos != null && datos != false) {
        this.showMje = true
        this.showForm = true
        this.formCrear.terminal = datos.guia_terminal != null || datos.guia_terminal != '' ? datos.guia_terminal : ''
        this.formCrear.numero = datos.guia_numero != null || datos.guia_numero != '' ? datos.guia_numero : ''
        if (this.formInline.comproba == datos.comprobante_guardar) {
          this.formCrear.comprob =
            datos.comprobante_guardar != null && datos.comprobante_guardar != '' ? (this.formInline.comproba == datos.comprobante_guardar ? datos.comprobante_guardar : '') : ''
          this.formCrear.comprob1 = datos.comprob != null && datos.comprob != '' ? datos.comprob : null
        }

        /*  Asigno sucursal */
        if (datos.presentacion_suc != null && datos.presentacion_suc != '') {
          this.formCrear.sucursal = datos.presentacion_suc
        } else if (datos.guia_suc != null && datos.guia_suc != '') {
          this.formCrear.sucursal = datos.guia_suc
        } else if (datos.canalizador_suc != null && datos.canalizador_suc != '') {
          this.formCrear.sucursal = datos.canalizador_suc
        } else {
          this.formCrear.sucursal = ''
        }

        if (datos.fecha_comprob != null || datos.fecha_comprob != '') {
          this.formCrear.fecha_comprob = moment(datos.fecha_comprob.date).format('YYYYMMDD H:mm:ss')
        }

        this.buscando = false
      } else {
        this.showMje = false
        this.showForm = false
        this.buscando = false
        this.form1 = false
        this.$alert('No se encontró el comprobante')
      }
    },
    editarModal(datos) {
      this.datosComprob = ''
      if (this.tipo == 'SG') {
        // console.log(this.empresaUrl,datos)
        window.localStorage.setItem('comprob', datos.comprob)
        if (this.empresaUrl === 'u') {
          window.open('https://seguimiento.suizoargentina.com.ar/#/comprobantes', '_blank')
        } else {
          window.open('https://seguimiento.log-in.com.ar/#/comprobantes', '_blank')
        }

        // setTimeout(() => {
        //   this.dialogEditar = true;
        //   this.titleModal = 'Editar comprobante (sin guía asociada): '+ datos.comprob;
        // }, 200);
        // this.comprob_buscar = datos.comprob
        // this.datosComprob = datos
      } else {
        this.datosComprob = datos
        setTimeout(() => {
          this.dialogEditar = true
          this.titleModal = 'Editar comprobante: ' + datos.comprob
        }, 200)
        this.formCrear.terminal = datos.guia_terminal
        this.formCrear.numero = datos.guia_numero
        this.formCrear.sucursal = datos.sucursal
        this.formCrear.comprob = datos.comprob
      }
    },
    enviarForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          HTTP.post('/escaner/actualizarEscaneo', {
            comprobante: this.datosComprob,
            formulario: this.formCrear,
            operador: this.operador,
          })
            .then((res) => {
              if (res.data) {
                if (this.tipo == 'SG') {
                  this.getDatosTabla(1, this.datosTabla, false)
                } else if (this.tipo == 'SUC') {
                  this.getComprobSuc(1, this.datosTabla, false)
                }

                this.dialogEditar = false
                this.$message({
                  message: 'Comprobante editado.',
                  type: 'success',
                  duration: 2000,
                })
              } else {
                this.$message.error('No se pudo editar el comprobante.Intente nuevamente')
              }
            })
            .catch((error) => console.log(error))
        } else {
          this.$message({
            message: 'Por favor. Controle los campos obligatorios.',
            type: 'warning',
          })
          return false
        }
      })
    },
    getDetalle(datos) {
      this.loadBtn = datos.id
      // let terminal_d = ''
      // let numero_d =''
      const numeros = { terminal_d: '', numero_d: '' }
      let noshow = false
      if (this.tipo == 'SG') {
        setTimeout(() => {
          HTTP.post('/escaner/getComprob', { comprob: datos.comprob })
            .then((res) => {
              this.loadBtn = false
              if (res.data != null && res.data != false) {
                numeros.terminal_d = res.data.terminal
                numeros.numero_d = res.data.numero
              }
            })
            .catch((error) => console.log(error))
          this.loadBtn = false
        }, 200)
      } else {
        this.loadBtn = false
        numeros.terminal_d = datos.guia_terminal
        numeros.numero_d = datos.guia_numero
      }

      /* --------Redireccionar---------- */
      if (numeros.terminal_d != '' && numeros.numero_d != '') {
        EventBus.$emit('abrirModalDetalles', {
          info: datos,
          empresa: this.empresa,
        })
        // if (this.empresaUrl == 'u') {
        //   window.open('https://seguimiento.suizoargentina.com.ar/#/detalles?terminal=' + numeros.terminal_d + '&numero=' + numeros.numero_d, '_blank')
        //   // window.open('http://localhost:8080/#/detalles?terminal='+numeros.terminal_d+"&numero="+numeros.numero_d, "_blank");
        // } else {
        //   window.open('https://seguimiento.log-in.com.ar/#/detalles?terminal=' + numeros.terminal_d + '&numero=' + numeros.numero_d, '_blank')
        //   // window.open('http://localhost:8080/#/detalles?terminal='+numeros.terminal_d+"&numero="+numeros.numero_d, "_blank");
        // }
      } else if (!noshow) {
        this.$message({
          showClose: true,
          message: 'No hay hay guía asociada.',
          type: 'error',
        })
      }
    },
    mostrarTabla() {
      EventBus.$emit('pagConvenios', true)
    },
    getComprobSuc(pagina, datos, buscar) {
      HTTP.post('/escaner/getComprobxSuc', {
        pagina: pagina,
        fecha: datos.fecha,
        sucursal: datos.sucursal.codigo,
        buscarC: buscar,
      })
        .then((res) => {
          // console.log('respuesta', res.data)
          if (res.data) {
            this.totalRow = res.data.length > 0 ? res.data[0].TotalRows : 0
            this.tablaD = res.data
          } else {
            this.$message({
              message: 'Ocurrió un problema,refresque la página e intente nuevamente.',
              type: 'warning',
            })
          }

          this.loadTabla = false
        })
        .catch((error) => {
          console.log(error)
          this.loadTabla = false
        })
    },
    bloquearVistas() {
      EventBus.$emit('pagPrincipal', true)
    },
    openUrl(url) {
      window.open(url, '_blank')
    },
    buscarEscaneo(formName) {
      this.errorBuscar = ''
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form1 = true
          this.buscando = true
          let comprob_existe = this.tablaD.length > 1 ? this.tablaD.find((elememto) => elememto.comprob == this.formInline.comproba) : false
          if (typeof comprob_existe != 'undefined' && comprob_existe && this.formInline.comproba != this.comprob_buscar) {
            this.errorBuscar = 'El comprobante ingresado pertenece a otro elemento de la tabla.'
            this.buscando = false
            this.form1 = false
          } else {
            /* Primero tomos los casos especiales */

            HTTP.post('/escaner/getEscaneado', {
              comprob: this.formInline.comproba,
            })
              .then((res) => {
                // console.log(res.data)
                if (!res.data) {
                  this.$message.error('No se encontró comprobante')
                  this.form1 = false
                  this.buscando = false
                } else {
                  this.getDatosOlcomprob(res.data)
                }
              })
              .catch((error) => console.log(error))
          }
        } else {
          this.form1 = false
          return false
        }
      })
    },
    resetForm1(formName) {
      this.$refs[formName].resetFields()
    },
    limpiarTodo(cerrar) {
      if (this.tipo == 'SG') {
        this.resetForm1('formCrear')
        this.resetForm1('formInline')
        setTimeout(() => {
          this.form1 = false
          this.showForm = false
        }, 200)
        this.buscando = false
        this.errorBuscar = ''
        this.dialogEditar = cerrar ? false : true
      } else if (cerrar) {
        this.dialogEditar = false
      }
    },
    filtrarComprob(numero) {
      this.filterComprob = !numero ? '' : this.filterComprob
      if (this.tipo == 'SG') {
        this.getDatosTabla(1, this.datosTabla, numero)
      } else if (this.tipo == 'SUC') {
        setTimeout(() => {
          this.getComprobSuc(1, this.datosTabla, numero)
        }, 150)
      }
    },
    showDelete(datos) {
      this.loadBtn = datos.id
      this.dialogDelete = true
      this.datosEliminar = datos
      this.titleEliminar = ''
      if (this.tipo == 'SG') {
        this.titleEliminar = 'Eliminar Comprobante - Convenio ' + this.getNombreConv(this.datosTabla.convol)
      } else {
        this.titleEliminar = 'Eliminar Comprobante - Sucursal ' + this.datosTabla.sucursal.nombre
      }
    },
    cancelarEliminar() {
      this.datosEliminar = ''
      this.dialogDelete = false
      this.loadBtn = false
    },
    beforeDelete() {
      this.loadBtn = false
    },
    deleteComprobante() {
      this.loadBtn = true
      if (this.datosEliminar != '') {
        HTTP.post('/escaner/deleteWebComprob', {
          id_comprob: this.datosEliminar.id,
          operador: this.operador,
        })
          .then((res) => {
            switch (res.data) {
              case 0:
                this.$message.error('No se encontró comprobante')
                break
              case 1:
                this.$message({
                  message: 'Se eliminó correctamente el comprobante.',
                  type: 'success',
                })
                this.dialogDelete = false
                this.actualizarTabla()
                break
              case 2:
                this.$message({
                  message: 'El comprobante no se eliminó, intente nuevamente.',
                  type: 'warning',
                })
                break
            }

            this.loadBtn = false
          })
          .catch((error) => {
            console.log(error)
            this.$message({
              message: 'El comprobante no se eliminó, intente nuevamente.',
              type: 'warning',
            })
            this.loadBtn = false
          })
      } else {
        this.loadBtn = false
        this.dialogDelete = false
        this.$message({
          message: 'El comprobante no se eliminó, intente nuevamente.',
          type: 'warning',
        })
      }
    },
    actualizarTabla() {
      if (this.tipo == 'SG') {
        this.getDatosTabla(1, this.datosTabla, false)
        this.showForm = false
        this.disabledComprob = false
        let diferente_conv = [389, 412, 13]
        this.datosComprob = ''
        this.conv_especial = diferente_conv.find((element) => element == this.conv)
        if (typeof this.conv_especial != 'undefined') {
          this.inputComprob = true
        }
      } else if (this.tipo == 'SUC') {
        this.showForm = true
        this.disabledComprob = true
        this.loadTabla = true
        setTimeout(() => {
          this.getComprobSuc(1, this.datosTabla, false)
        }, 150)
      }
    },
    openFoto(url) {
      window.open(url, '_blank')
    },
  },
  filters: {
    fechaE: function (date) {
      return moment(date).format('DD-MM-YYYY H:mm:ss')
    },
  },
}
</script>
