<template>
  <el-form label-position="top" size="small">
    <el-row v-if="isAdmin">
      <el-col :md="24" :sm="24" :xs="24">
        <el-button @click="formTurno()" size="mini" class="btnCross" style="float: right !important"> <i class="el-icon-circle-plus-outline"> </i> Nuevo Turno </el-button>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row :gutter="10">
      <el-col :md="10" :sm="16" :xs="24" style="padding-bottom: 5px">
        <div class="el-select el-select--small" style="width: 100%; line-height: 0px !important">
          <v-select
            id="selectConvenio"
            name="selectConvenio"
            ref="convenioRef"
            v-model="selectConvenio"
            :options="this.convenios"
            :filter="fuseSearch"
            :getOptionLabel="(option) => option.nombre"
            placeholder="Seleccione un convenio"
            @input="filtrar"
            multiple
            :reduce="(obj) => obj.convenio">
            <span slot="no-options">Sin Coincidencia</span>
            <template #option="{ nombre }">
              <cite>{{ nombre }}</cite>
            </template>
          </v-select>
        </div>
      </el-col>
      <el-col :md="6" :sm="8" :xs="24" style="padding-bottom: 5px">
        <el-date-picker
          id="fecha"
          name="fecha"
          @change="filtrar()"
          size="mini"
          style="width: 100%"
          clearable
          v-model="fecha"
          format="dd-MM-yyyy"
          value-format="yyyy-MM-dd"
          type="daterange"
          start-placeholder="Desde"
          end-placeholder="Hasta">
        </el-date-picker>
      </el-col>
      <el-col :md="8" :sm="16" :xs="24" style="padding-bottom: 5px">
        <el-time-select
          id="hora_ini"
          name="hora_ini"
          size="mini"
          placeholder="Hora inicial"
          v-model="hora_ini"
          :picker-options="configTime"
          style="width: 151px !important"
          @change="clearTime()" />
        <span style="margin-left: 5px; margin-right: 5px">-</span>
        <el-time-select
          id="hora_fin"
          name="hora_fin"
          size="mini"
          placeholder="Hora final"
          style="width: 151px !important"
          v-model="hora_fin"
          :picker-options="{
            start: '06:00',
            step: '00:20',
            end: '19:00',
            minTime: hora_ini,
          }"></el-time-select>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="vguia-row">
      <el-col :md="10" :sm="14" :xs="24" style="padding-bottom: 5px">
        <el-select
          id="estado_select"
          name="estado_select"
          @remove-tag="filtrar()"
          size="mini"
          v-model="estado_select"
          placeholder="Filtro por estado"
          multiple
          clereable
          filterable
          style="width: 100%"
          clearable>
          <el-option v-for="(item, index) in estados" :key="index" :label="item.texto" :value="item.estado">
            <el-tag :type="formatEstado(item.estado).color">{{ formatEstado(item.estado).texto }}</el-tag>
          </el-option>
        </el-select>
      </el-col>
      <el-col :md="6" :sm="12" :xs="24" style="padding-bottom: 5px">
        <el-select
          id="nave_select"
          name="nave_select"
          @change="filtrar()"
          size="mini"
          v-model="nave_select"
          placeholder="Filtro por nave"
          multiple
          clereable
          filterable
          style="width: 100%"
          clearable>
          <el-option v-for="(item, index) in naves" :key="index" :label="item.texto" :value="item.value">
            <span style="float: left">{{ item.texto }}</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :md="6" :sm="10" :xs="24" style="padding-bottom: 5px">
        <el-button-group>
          <el-button @click="filtrar()" icon="el-icon-search" size="mini">Buscar</el-button>
          <el-button @click="limpiarFiltro()" icon="el-icon-refresh" size="mini">Limpiar</el-button>
          <el-button @click="exportExcel()" class="btnCross" :loading="btnLoading" size="mini"><i class="fas fa-file-excel"></i> Exportar</el-button>
        </el-button-group>
      </el-col> </el-row
    ><br />
  </el-form>
</template>
<script>
import XLSX from 'xlsx'
import { API } from '@/plugins/api'
import { estados, naves } from '@/components/turnos/_auxiliar'
import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Turnofilter',
  components: { vSelect },

  mounted() {
    this.convenios = this.getTurneroConfig.convenios_all
  },
  data() {
    return {
      convenios: [],
      btnLoading: false,
      configTime: {
        start: '06:00',
        step: '00:20',
        end: '19:00',
      },
      estados,
      naves,
      estado_select: [0, 1, 2, 3, 4],
      nave_select: null,
      fecha: null,
      hora_ini: null,
      hora_fin: null,
      selectConvenio: [],
    }
  },
  methods: {
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    async filtrar(segundos = 0) {
      await this.delay(segundos * 1000)
      var filtros = {}
      //Check convenios
      if (this.selectConvenio != null) {
        filtros.filter_convenio = this.selectConvenio
      }
      //Check fechas
      if (this.fecha != null) {
        filtros.filter_date = this.fecha[0] + '|' + this.fecha[1]
      }
      //Check Hora
      if (this.hora_ini != null && this.hora_fin != null) {
        filtros.filter_time = this.hora_ini + '|' + this.hora_fin
      }
      //Check estados
      if (this.estado_select.length > 0) {
        filtros.estado_select = this.estado_select
      }
      //Check naves
      if (this.nave_select.length > 0) {
        filtros.nave_select = this.nave_select
      }
      this.$events.fire('filter-set', filtros)
    },
    limpiarFiltro() {
      this.selectConvenio = []
      this.estado_select = [0, 1, 2, 3]
      this.nave_select = [1, 2]
      ;(this.fecha = null), (this.hora_ini = null)
      this.hora_fin = null
      this.$events.fire('filter-reset')
    },
    exportExcel() {
      var filtros = {}
      //Check convenios
      if (this.selectConvenio.length > 0) {
        filtros.filter_convenio = this.selectConvenio
      }
      //Check fechas
      if (this.fecha != null) {
        filtros.filter_date = this.fecha[0] + '|' + this.fecha[1]
      }
      //Check estados
      if (this.estado_select.length > 0) {
        filtros.estado_select = this.estado_select
      }
      //Check naves
      if (this.nave_select.length > 0) {
        filtros.nave_select = this.nave_select
      }
      this.btnLoading = true
      API.post('/turnero/turnos/export', filtros)
        .then((response) => {
          this.btnLoading = false
          let turnos = response.data.data
          let data = XLSX.utils.json_to_sheet(turnos)
          const workbook = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(workbook, data, 'turnos')
          XLSX.writeFile(workbook, `turnos.xlsx`)
        })
        .catch((error) => {
          this.btnLoading = false
          this.$message({
            message: error.response.data.message,
            type: 'error',
          })
          //console.log(error)
        })
    },
    formTurno() {
      this.$events.fire('open-form-turno', 'ALTA')
    },
    formatEstado(value, tipo = 0) {
      let estadoProps
      switch (tipo) {
        case 1:
          estadoProps = value + 1
          break
        default:
          estadoProps = value
          break
      }

      let objeto = estados.find((x) => x.estado == estadoProps)
      if (objeto === undefined) return null
      else return objeto
    },
    clearTime() {
      this.hora_fin = null
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['nombre'],
        shouldSort: true,
        minMatchCharLength: 1,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
  },
  computed: {
    ...mapGetters('Turnos', ['getTurneroConfig']),
    ...mapGetters(['getDatos']),
    isAdmin() {
      return this.getDatos.permisos.includes('TURNOS_ADMIN') || this.getDatos.permisos.includes('TURNOS_OPERARIO') ? true : false
    },
  },
}
</script>
