<template>
    <div>
  <el-row :gutter="20">
      <el-col :md="12" :offset="0">
              <code-highlight language="javascript" style="width:100%">
                  <pre>
//indice
[1][UBICACION]   "R:\comprobantes_escaneados\Meta 046 26-05-21\", 
[2][CUIT]        "30661838775",
[3][TIPO]        "REM",
[4][CONVENIO]    "020",
[5][COMPROBANTE] "03007133314",
[6][COMPROBANTE] "0003007133314",
[7][FECHA]       "11052021", //Ahora fecha de escaneo
[8][CONFORME]    "Conforme",
[9][?]           "No",
[10][EXTENSION]  ".pdf", 
<!-- [11][EMPRESA]    "S", //S=Suizo - L=Log-In -->

//Carpeta que contiene el indice
[EJEMPLO] Meta 046 26-05-21    
[CARPETA] ['NOMBRE CONVENIO']['# DE LOTE']['FECHA PROCESADO']    

//Nombre del archivo
[EJEMPLO] "30661838775-03007153690-260521.pdf"
[NOMBRE]  ['CUIT']-['COMPROBANTE']-['FECHA ESCANEO'] 
 </pre>
  
   

    </code-highlight>
      </el-col>
      <el-col :md="12" :offset="0">
          <img src="@/assets/proceso_escaner.png" class="img-proceso" alt="">
      </el-col>
  </el-row>
  
    </div>
</template>

<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
// import "vue-code-highlight/themes/window.css";

    export default {
        components:{
  CodeHighlight,
  
}
    }
</script>

<style lang="scss" scoped>
.img-proceso{
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 550px;
}
</style>