<template>
  <div v-loading="sistema.loading" style="padding: 0px 20px">
    <el-row style="border-bottom: 1px solid #ddd; padding-bottom: 10px">
      <el-tabs v-model="sistema.activeTab" v-if="!sistema.loading">
        <!-----------------------------    diario   ---------------------------->
        <el-tab-pane name="diario" v-if="this.permisos.turnos_admin || this.permisos.turnos_operario || this.permisos.turnos_vigilancia">
          <span slot="label"><i class="fas fa-table"></i> Turnos del dia</span>
          <Diario v-if="this.sistema.activeTab == 'diario'" />
        </el-tab-pane>
        <!-----------------------------    gestor   ---------------------------->
        <el-tab-pane name="gestor" v-if="this.permisos.turnos_admin || this.permisos.turnos_operario">
          <span slot="label"><i class="fas fa-truck-loading"></i> Administrador de Turnos</span>
          <Gestor v-if="this.sistema.activeTab == 'gestor'" />
        </el-tab-pane>
        <!-----------------------------    consulta   ---------------------------->
        <el-tab-pane name="consulta" v-if="this.permisos.turnos_consulta && !this.permisos.turnos_admin">
          <span slot="label"><i class="fas fa-search"></i> Visor de Turnos</span>
          <Gestor v-if="this.sistema.activeTab == 'consulta'" />
        </el-tab-pane>
        <!-----------------------------    docks   ---------------------------->
        <el-tab-pane name="docks" v-if="this.permisos.turnos_admin">
          <span slot="label"><i class="fas fa-cogs"></i> Configs</span>
          <Docks v-if="this.sistema.activeTab == 'docks'" />
        </el-tab-pane>
        <!-----------------------------    agenda   ---------------------------->
        <el-tab-pane name="agenda" v-if="this.permisos.turnos_admin || this.permisos.turnos_operario">
          <span slot="label"><i class="el-icon-notebook-1"></i> Agenda de Contactos</span>
          <Agenda v-if="this.sistema.activeTab == 'agenda'" />
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>
<script>
import Gestor from '@/components/turnos/Gestor/Index.vue'
import Docks from '@/components/turnos/Configs/Docks.vue'
import Diario from '@/components/turnos/Visor/Diario.vue'
import Agenda from '@/components/turnos/Agenda/Index.vue'
import { API } from '@/plugins/api'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Gestor,
    Diario,
    Docks,
    Agenda,
  },
  data() {
    return {
      sistema: {
        activeTab: 'diario',
        loading: true,
      },
      permisos: {
        turnos_admin: false,
        turnos_consulta: false,
        turnos_operario: false,
        turnos_vigilancia: false,
      },
    }
  },
  async created() {
    this.permisos.turnos_admin = this.getDatos.permisos.includes('TURNOS_ADMIN') ? true : false
    this.permisos.turnos_consulta = this.getDatos.permisos.includes('TURNOS_CONSULTA') ? true : false
    this.permisos.turnos_operario = this.getDatos.permisos.includes('TURNOS_OPERARIO') ? true : false
    this.permisos.turnos_vigilancia = this.getDatos.permisos.includes('TURNOS_VIGILANCIA') ? true : false

    if (this.permisos.turnos_consulta && !this.permisos.turnos_admin && !this.permisos.turnos && !this.permisos.turnos_operario && !this.permisos.turnos_vigilancia)
      this.sistema.activeTab = 'consulta'
    await this.getConvenios()
  },
  methods: {
    ...mapActions('Turnos', ['setTurneroConfig']),
    async getConvenios() {
      const response = await API.get('turnero/getConvenios')
      //quito convenio objeto hasta habilitar en back y front
      let convenios_all = response.data.data.convenios_all
      let agenda = response.data.data.agenda
      // Crear el nuevo objeto

      //console.log(this.getImportadora)
      let convenioNull = {
        convol: this.getImportadora.convol,
        convenio: this.getImportadora.convenio,
        descrip: this.getImportadora.descrip,
        nombre: this.getImportadora.nombre,
      }
      // Agregar el nuevo objeto al array de convenios_all
      convenios_all.push(convenioNull)

      this.setTurneroConfig({
        convenios_all,
        agenda,
      })
      this.sistema.loading = false
    },
  },
  computed: {
    ...mapGetters(['getDatos']),
    ...mapGetters('Turnos', ['getImportadora']),
  },
}
</script>
