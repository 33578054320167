<template>
  <div>
    <div :style="styleHeader" class="header">
      <!-- LOGO DINÁMICO  -->

      <el-row :gutter="20">
        <el-col :span="12">
          <a>
            <el-tooltip
              class="item"
              effect="light"
              :content="altEmpresa"
              placement="right">
              <img :src="imgHeader" :style="styleImg" />
            </el-tooltip>
          </a>
        </el-col>
        <!-- Se muestra si el usuario tiene permiso (HACER) -->
        <el-col :span="12" style="text-align: end">
          <el-tooltip placement="top" effect="light" v-if="sac">
            <div slot="content">{{ nameSac }}</div>

            <el-link
              class="boton"
              target="_blank"
              style="background-color: #ffffff !important">
              <div v-if="empresa == 'u'">
                <img
                  src="@/assets/ico_sgr_mini.png"
                  alt="Sac"
                  :style="styleImgSac"
                  @click="sacUrl" />
              </div>
              <div v-else>
                <img
                  src="@/assets/sac_login.png"
                  alt="Sac"
                  :style="styleImgSac"
                  @click="sacUrl" />
              </div>
            </el-link>
          </el-tooltip>
          <el-dropdown>
            <el-button
              type="warning"
              circle
              size="small"
              style="font-size: 20px"
              :style="stylebtnUser">
              <i class="el-icon-user"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><strong>Usuario:</strong>
                {{ permisos_usuario.usuario }}</el-dropdown-item
              >
              <el-dropdown-item>
                <router-link
                  to="cuenta"
                  exact
                  class="link"
                  :class="getClaseSubLink('/cuenta', empresa)">
                  <i class="el-icon-s-custom"></i> Mi cuenta</router-link
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-link :underline="false" @click="salir">
                  <i class="el-icon-switch-button"></i> Cerrar sesión</el-link
                ></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </div>
    <div
      class="container-fluid"
      style="margin-right: auto; margin-left: auto"
      id="menu">
      <nav>
        <!-- <ul :style="menu" class="menu hidden-md-and-up" >
        <el-dropdown style="padding:10px 0px 10px 10px;">
          <span class="el-dropdown-link">
         <i class="el-icon-s-grid el-icon--left"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in menuDinamic" :key="item.id">
              <a
              v-if="!item.admin"
              :class="getClaseLink(item.path, empresa)"
              @click="$router.push(item.path)"
              ><i :class="item.icono"></i> {{ item.name }}
            </a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </ul> -->
        <ul :style="menu" class="menu" v-loading="LoadingLoginGuias">
          <li
            v-for="(item, index) in menuDinamic"
            style="margin: -5px"
            :key="index">
            <a
              v-if="!item.admin"
              :class="getClaseLink(item.path, empresa)"
              @click="$router.push(item.path)"
              ><i :class="item.icono"></i>
              <span v-if="item.cantidad && cantidad_badge > 0">
                <el-badge
                  :value="cantidad_badge"
                  class="item"
                  type="warning"
                  style="margin-right: 20px; margin-left: 3px; font-size: 11px">
                  <span style="margin-right: 10px; margin-left: 3px">
                    {{ item.name }}
                  </span>
                </el-badge>
              </span>
              <span v-else>
                {{ item.name }}
              </span>
            </a>
            <!-- <el-badge v-if="item.name == 'Carro'" type="primary" class="mark" size="small" :value="valorCarro" /> -->
            <el-dropdown
              trigger="click"
              v-if="item.admin && item.tipo == 'admin'"
              :class="{ activar: active === true }"
              class="dropdown-admin"
              :style="styleDrop">
              <span class="el-dropdown-link">
                <i :class="item.icono"></i> {{ item.name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="subitem in menuAdmin"
                  :icon="subitem.icono"
                  :href="subitem.path"
                  :key="subitem.id"
                  :class="getClaseSubLink(subitem.path, empresa)"
                  @click.native="$router.push(subitem.path)">
                  <a v-if="!subitem.admin" style="text-decoration: none">{{
                    subitem.name
                  }}</a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown
              @command="loginFromGuias"
              trigger="click"
              v-if="item.admin && item.tipo == 'empresa'"
              :class="{ activar: active === true }"
              class="dropdown-admin"
              :style="styleDrop">
              <span class="el-dropdown-link">
                <i :class="item.icono"></i> {{ item.name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="subitem in menuEmpresa"
                  :icon="subitem.icono"
                  :key="subitem.id"
                  :command="subitem.empresa">
                  {{ subitem.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <el-link @click="salir" :underline="false" style="color: white"
              ><i class="el-icon-switch-button"></i> Salir
            </el-link>
          </li>
        </ul>

        <!-- <span class="line"></span> -->
      </nav>
    </div>
  </div>
</template>

<script>
// import { baseUrl } from "@/axios";
import { HTTP } from '@/axios'
import { EventBus } from '@/event-bus'

export default {
  name: 'GuiasHeader',
  data() {
    return {
      styleTitle: {
        fontWeight: '700',
        fontSize: '20px',
        margin: '4px 0px 4px',
        lineHeight: '1',
      },
      permisos_usuario: JSON.parse(window.localStorage.getItem('permisos')),
      styleHeader: {
        color: '#006dcc',
        fontSize: '14px',
        paddingTop: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '10px',
      },
      imgHeader: '',
      altEmpresa: '',
      activeIndex: '1',
      activeIndex2: '1',
      menu: {
        // display: 'flex',
        margin: '0 auto',
        listStyle: 'none',
        paddingLeft: ' 0px',
        fontSize: '14px',
      },
      active: null,
      loading: false,
      menuDinamic: [{ path: '', activac: '', icono: '', name: '' }],
      menuAdmin: [],
      menuEmpresa: [],
      styleImg: {},
      sac: false,
      styleImgSac: {},
      activacion: false,
      empresa: window.localStorage.getItem('empresa'),
      // baseUrl: baseUrl,
      styleDrop: {},
      stylebtnUser: {},
      valorCarro: 0,
      permisoEmpresa: false,
      claseEmpresa: {},
      LoadingLoginGuias: false,
      nameSac: '',
    }
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) this.getClaseLink(to.path, this.empresa)
    },
  },
  created() {
    this.permisos()
    this.urlMenu()
    // this.loading = this.$loading({
    //   lock: true,
    //   text: "Cargando",
    //   spinner: "el-icon-loading",
    //   background: "rgba(0, 0, 0, 0.7)",
    // });
    // setTimeout(() => {
    //   this.loading.close();
    // }, 1000);
  },
  mounted() {
    EventBus.$on('cargandoCarro', (valor) => {
      this.valorCarro = valor
    })
  },
  beforeDestroy() {
    EventBus.$off('cantidad_total_stock', false)
  },
  methods: {
    getClaseLink(path, empresa) {
      let clase = ''
      clase = empresa == 'lo' ? 'menu-link-lo' : 'menu-link-u'
      if (empresa == 'lo' && path == this.$route.path) {
        clase = clase + ' menu-active-lo'
      } else {
        clase = clase + ' '
      }
      if (empresa == 'u' && path == this.$route.path) {
        clase = clase + ' menu-active-u'
      } else {
        clase = clase + ' '
      }
      return clase
    },
    getClaseSubLink(path, empresa) {
      let clase = ''
      if (empresa == 'lo' && path == this.$route.path) {
        clase = 'submenu-link-lo'
      } else {
        if (empresa == 'u' && path == this.$route.path) {
          clase = 'submenu-link-u'
        } else {
          clase = ' '
        }
      }

      return clase
    },
    getClaseSubBtn(action, empresa) {
      let clase = ''
      if (empresa == 'lo' && action == 'BD' && action == 'RICHMOND') {
        clase = 'submenu-link-lo'
      } else {
        clase = ' '
      }

      return clase
    },
    loginFromGuias(command) {
      this.LoadingLoginGuias = true
      HTTP.post('/guiasconsultas/loginFromGuias', { empresa: command })
        .then((res) => {
          this.LoadingLoginGuias = false
          window.open(
            'https://seguimiento.log-in.com.ar/seguimiento/empresa/#/' +
              command +
              '/guias?token=' +
              res.data,
            '_blank'
          )
        })
        .catch((e) => {
          this.LoadingLoginGuias = false
          this.$alert('No se pudo generar el token ', e)
        })
    },
    permisos() {
      if (this.empresa == 'lo') {
        this.styleHeader['backgroundColor'] = '#36404e'
        this.imgHeader = 'logo-log-in.png'
        this.altEmpresa = 'Log-In | Logística Integral'
        this.log = ''
        this.menu['backgroundColor'] = '#36404e'
        this.menu['borderTop'] = '1px solid #6b727c'
        this.styleImgSac['width'] = '100%'
        this.styleImgSac['marginLeft'] = '-3px'
        this.styleImgSac['marginTop'] = '5px'
        this.imgHeader = require('@/assets/logo.png')
        this.styleDrop['paddingLeft'] = '30px'

        this.sac = this.permisos_usuario.includes('SACL')
        this.nameSac = 'Ir a SAC'
      } else if (this.empresa == 'u') {
        this.styleHeader['backgroundColor'] = '#0B406A'
        this.imgHeader = 'logo_suizo_blanco.webp'
        this.altEmpresa = 'Suizo Argentina S.A.'
        this.menu['backgroundColor'] = '#0B406A'
        this.menu['borderTop'] = '1px solid #f0f2f5'
        this.styleImg['padding'] = '5px'
        this.styleImgSac['width'] = '100%'
        this.styleImgSac['marginLeft'] = '-3px'
        this.styleImgSac['marginTop'] = '5px'
        this.imgHeader = require('@/assets/logo_suizo_blanco.webp')
        this.styleDrop['paddingLeft'] = '30px'
        this.stylebtnUser['backgroundColor'] = '#6592c7 !important'
        this.stylebtnUser['borderColor'] = '#0b406a !important'
        this.sac = this.permisos_usuario.includes('SAC')
        this.nameSac = 'Ir a SAC'
      }

      // /* Menú dinámico de acuerdo al permiso se muestra el menú*/
      var $OL = this.permisos_usuario.includes('OL')
      var $OL_LISTA = this.permisos_usuario.includes('OL_LISTACONV')
      var $APP = this.permisos_usuario.includes('APP_USUARIOS')
      var $MULTI = this.permisos_usuario.includes('OL_MULTI')
      var $ESC = this.permisos_usuario.includes('ESCANER')

      var $ADMIN = this.permisos_usuario.includes('ADMIN')

      var $STOCK =
        this.permisos_usuario.includes('STOCK') && this.empresa == 'lo'
          ? true
          : false
      var $EMPRESA = this.permisos_usuario.includes('MENU_EMPRESA')
      var $AMB_USER = this.permisos_usuario.includes('OL_ABM_USUARIOS')
      var $STOCK_DSA =
        this.permisos_usuario.includes('STOCK') && this.empresa == 'u'
          ? true
          : false
      var $CROSSDOCK = this.permisos_usuario.includes('CROSSDOCK')
      var $INTEGRACIONES = this.permisos_usuario.includes('INTEGRACIONES')
      var $TURNOS =
        this.permisos_usuario.includes('TURNOS') ||
        this.permisos_usuario.includes('TURNOS_ADMIN') ||
        this.permisos_usuario.includes('TURNOS_CONSULTA') ||
        this.permisos_usuario.includes('TURNOS_OPERARIO') ||
        this.permisos_usuario.includes('TURNOS_VIGILANCIA')
          ? true
          : false || this.permisos_usuario.includes('TURNOS_CLIENTE')
          ? true
          : false
      var $FM = this.permisos_usuario.includes('FM')
      /* Nuevo permiso para que cuando venga estilo OL_ABM_USUARIOS pueda editar usuarios */

      if ($OL || $MULTI || $ADMIN) {
        this.menuDinamic = [
          {
            id: '1',
            path: '/inicio',
            activac: 'inicio',
            icono: 'el-icon-s-home',
            name: 'Inicio',
          },
        ]
      }

      if ($OL || $ADMIN) {
        this.menuDinamic.push(
          {
            id: '2',
            path: '/guias',
            activac: 'guias',
            icono: 'el-icon-s-order',
            name: 'Guias',
          },
          {
            id: '3',
            path: '/presentaciones',
            activac: 'presentaciones',
            icono: 'el-icon-document-checked',
            name: 'Presentaciones',
          },
          {
            id: '4',
            path: '/resumen',
            activac: 'resumen',
            icono: 'el-icon-s-data',
            name: 'Resumen',
          }
        )
      }

      if ($MULTI || $ADMIN) {
        this.menuDinamic.push({
          id: '5',
          path: '/resumenhojaruta',
          activac: 'resumenhojaruta',
          icono: 'el-icon-truck',
          name: 'Hoja de Ruta',
        })
      }
      if ($STOCK) {
        this.menuDinamic.push({
          id: '6',
          path: '/stock',
          activac: 'stock',
          icono: 'el-icon-takeaway-box',
          name: 'Stock',
        })
        // this.menuDinamic.push({id:'8',path:'carros',activac:'carros',icono:'el-icon-shopping-cart-2', name:'Carro'});
        this.activacion = true
      }
      if ($STOCK_DSA) {
        this.menuDinamic.push({
          id: '10',
          path: '/stockdsa',
          activac: 'stockdsa',
          icono: 'el-icon-takeaway-box',
          name: 'Stock',
        })
        this.activacion = true
      }
      if (($APP && $ESC) || $ADMIN) {
        /* Caso diferente remitosadmin */
        this.menuDinamic.push({
          id: '6',
          path: '/admin',
          activac: 'admin',
          icono: 'el-icon-setting',
          name: 'Admin',
          tipo: 'admin',
          admin: [
            {
              id: '1',
              path: '/remitos',
              activac: 'remitos',
              icono: 'el-icon-paperclip',
              name: 'Remitos App',
            },
            {
              id: '22',
              path: '/comprobantes/visor',
              activac: 'comprobantesvisor',
              icono: 'el-icon-document-copy',
              name: 'Escaner',
            },
          ],
        })

        this.menuAdmin.push(
          {
            id: '1',
            path: '/remitos',
            activac: 'remitos',
            icono: 'el-icon-paperclip',
            name: 'Remitos App',
          },
          {
            id: '22',
            path: '/comprobantes',
            activac: 'comprobantes',
            icono: 'el-icon-document-copy',
            name: 'Escaner',
          }
        )

        this.activacion = true
      } else if ($APP) {
        this.menuDinamic.push({
          id: '8',
          path: '/admin',
          activac: 'admin',
          icono: 'el-icon-setting',
          name: 'Admin',
          tipo: 'admin',
          admin: [],
        })
        this.menuAdmin.push({
          id: '1',
          path: '/remitos',
          activac: 'remitos',
          icono: 'el-icon-paperclip',
          name: 'Remitos App',
        })
        this.activacion = true
      } else if ($ESC) {
        this.menuDinamic.push({
          id: '7',
          path: '/admin',
          activac: 'admin',
          icono: 'el-icon-setting',
          name: 'Admin',
          tipo: 'admin',
          admin: [],
        })
        this.menuAdmin.push({
          id: '22',
          path: '/comprobantes',
          activac: 'comprobantes',
          icono: 'el-icon-document-copy',
          name: 'Escaner',
        })
        this.activacion = true
      }
      /* Si tiene permiso OL_ABM_USUARIOS Puede agregar y editar Usuarios */
      if ($AMB_USER) {
        var existAdmin = this.menuDinamic.find(
          (element) => element.name == 'Admin'
        )
        if (typeof existAdmin === 'undefined') {
          this.menuDinamic.push({
            id: '7',
            path: '/admin',
            activac: 'admin',
            icono: 'el-icon-setting',
            name: 'Admin',
            tipo: 'admin',
            admin: [],
          })
        }
        this.menuAdmin.push({
          id: '23',
          path: '/usuarios',
          activac: 'usuarios',
          icono: 'el-icon-user-solid',
          name: 'Usuarios',
        })
        this.activacion = true
      }
      /* Si tiene permiso empresa */
      if ($EMPRESA) {
        /* Caso diferente remitosadmin */
        this.permisoEmpresa = true
        this.menuDinamic.push({
          id: '100',
          path: '',
          activac: '',
          icono: 'el-icon-house',
          name: 'Guías Empresas',
          tipo: 'empresa',
          admin: [],
        })

        this.menuEmpresa.push(
          {
            id: '101',
            empresa: 'bd',
            icono: 'el-icon-s-flag',
            name: 'BD',
            action: 'BD',
          },
          {
            id: '102',
            empresa: 'richmond',
            icono: 'el-icon-s-flag',
            name: 'Richmond',
            action: 'Richmond',
          }
        )

        this.activacion = true
      }
      /* NUevo: SI ES MULTI Y NO ES ADMIN, NI ESC, MOSTRAR COMPROBANTES */
      let $ESC_COMPROB = false
      $ESC_COMPROB = $MULTI || $OL || $OL_LISTA ? true : false
      if ($ESC_COMPROB && !$ADMIN && !$ESC) {
        this.menuDinamic.push({
          id: '22',
          path: '/comprobantes',
          activac: 'comprobantes',
          icono: 'el-icon-document-copy',
          name: 'Comprobantes',
        })
      } else {
        if ($ESC_COMPROB && !$ADMIN && $ESC) {
          this.menuDinamic.push({
            id: '22',
            path: '/comprobantes',
            activac: 'comprobantes',
            icono: 'el-icon-document-copy',
            name: 'Comprobantes',
          })
        }
      }
      //CROSSDOCKING
      if ($CROSSDOCK) {
        this.menuDinamic.push({
          id: '9',
          path: '/cross',
          activac: 'cross',
          icono: 'el-icon-upload',
          name: 'Crossdocking',
        })
      }
      //INTEGRACIONES
      if ($INTEGRACIONES) {
        this.menuDinamic.push({
          id: '10',
          path: '/integraciones',
          activac: 'integraciones',
          icono: 'el-icon-s-tools',
          name: 'Integraciones',
        })
      }
      //TURNOS
      if ($TURNOS) {
        this.menuDinamic.push({
          id: '11',
          path: '/turnos',
          activac: 'turnos',
          icono: 'fas fa-ticket-alt',
          name: 'Turnos',
        })
      }
      if ($FM) {
        this.menuDinamic.push({
          id: '10',
          path: '/fm',
          activac: 'fm',
          icono: 'el-icon-download',
          name: 'Archivos',
        })
      }
    },
    urlMenu() {
      var array_url = this.$router.history.current.name
      this.active = ''
      for (let index = 4; index < array_url.length; index++) {
        this.active += array_url[index]
      }
    },
    salir() {
      //VUEX
      this.$store.dispatch('logout').then(() => {
        console.log('Gracias vuelva pronto...')
      })
      //FIN VUEX
      if (window.localStorage.getItem('externo') === 'true') {
        window.localStorage.clear()
        window.sessionStorage.clear()
        window.location.href = '//web1.suizoargentina.com/login/salir'
      } else {
        window.localStorage.clear()
        window.sessionStorage.clear()
        this.$router.push({ name: 'login' })
      }
      // if(window.localStorage.getItem('externo')=== 'true')
      // {
      //   window.localStorage.clear();
      //   window.location.href = "//web1.suizoargentina.com/login/salir"
      //   // this.$store.dispatch('logout')
      //   //         .then(() => {
      //   //             this.$router.push({ name: "login" });
      //   //             // console.log("deslogueando")
      //   //         })
      //   //         .catch((error) => {
      //   //             this.$router.push({ name: "login" });
      //   //             console.log(error)
      //   //         })
      // }else
      // {
      //   window.localStorage.clear();
      //   this.$router.push({ name: "login" });
      //   // this.$store.dispatch('logout')
      //   // .then(() => {
      //   //     this.$router.push({ name: "login" });
      //   //     // console.log("deslogueando")
      //   // })
      //   // .catch((error) => {
      //   //     this.$router.push({ name: "login" });
      //   //     console.log(error)
      //   // })
      // }
    },
    sacUrl() {
      let token = window.localStorage.getItem('token')
      // window.open(
      //   'https://sac.log-in.com.ar/guiasconsultas/getToSac?token=' + token,
      //   '_blank'
      // )
      window.open(
        'https://sacweb.log-in.com.ar/guiasconsultas/getToSac?token=' + token,
        '_blank'
      )
    },
  },
  watched: {},
}
</script>

<style lang="css" scoped>
.iconos {
  font-size: 20px;
}

/* Boton ir a ayuda */
.boton {
  width: 22px;
  height: 22px;
  background-color: #6e9de4;
  margin: 5px;
  margin-top: 0px;
  padding: 10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size: 30px;
  line-height: 32px;
  text-transform: uppercase;
  color: white !important;
}
.boton:hover {
  opacity: 0.5;
  -moz-opacity: 0.5;
  filter: alpha (opacity=50);
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f0f2f5;
  color: #606266;
}
.submenu-link-u {
  background-color: #6592c7 !important;
  color: #ffffff !important;
}
.submenu-link-lo {
  background-color: #36404e !important;
  color: #ffffff !important;
}
.menu-link-lo,
.menu-link-u {
  cursor: pointer;
}
.menu-link-lo:hover,
.menu-link-u:hover {
  color: #ffffff;
}
.menu-active-lo {
  background-color: #505d6feb;
  color: #ffde5a;
  font-size: 14px;
}
.menu-active-u {
  background-color: #6592c7;
  color: #ffffff;
  font-size: 14px;
}
.router-link-active {
  /* color: #fff!important; */
  transform: scale(1.1) matrix(1, 0, 0, 1, 0, 0);
  /* font-weight: 700!important; */
  /* border-bottom: 5px solid #3a8ee6; */
  background: #1798ff;
  padding: 8px 12px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.dropdown-admin {
  font-size: 13px;
  text-decoration: none;
  transition: all 0.45s;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  color: #ffffff;
  font-weight: 500;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  line-height: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.link {
  color: #303133;
  text-decoration: none !important;
}
/* Menu mobile */
.dropdown {
  position: relative;
}
.dropdown a {
  text-decoration: none;
}
.dropdown [data-toggle='dropdown'] {
  display: block;
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.dropdown [data-toggle='dropdown']:before {
  position: absolute;
  display: block;
  content: '\2630';
  font-size: 1.5em;
  color: #fff;
  top: 5px;
  right: 10px;
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -moz-transition: -moz-transform 0.6s;
  -o-transition: -o-transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
}
.dropdown > .dropdown-menu {
  max-height: 0;
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -moz-transition: max-height 0.6s ease-out;
  -o-transition: max-height 0.6s ease-out;
  -webkit-transition: max-height 0.6s ease-out;
  transition: max-height 0.6s ease-out;
  animation: hideAnimation 0.4s ease-out;
  -moz-animation: hideAnimation 0.4s ease-out;
  -webkit-animation: hideAnimation 0.4s ease-out;
}
.dropdown > .dropdown-menu li {
  padding: 0;
}
.dropdown > .dropdown-menu li a[data-v-29e8c3c6] {
  display: block;
  color: #fff;
  /* background: #EEE; */
  /* box-shadow: 0 1px 0 white inset, 0 -1px 0 #d5d5d5 inset; */
  /* text-shadow: 0 -1px 0 rgb(255 255 255 / 30%); */
  padding: 10px 10px;
}
.dropdown > .dropdown-menu li a:hover {
  background: #f6f6f6;
}
.dropdown > {
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.dropdown > .dropdown-menu {
  max-height: 9999px;
  display: block;
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  animation: showAnimation 0.5s ease-in-out;
  -moz-animation: showAnimation 0.5s ease-in-out;
  -webkit-animation: showAnimation 0.5s ease-in-out;
  -moz-transition: max-height 2s ease-in-out;
  -o-transition: max-height 2s ease-in-out;
  -webkit-transition: max-height 2s ease-in-out;
  transition: max-height 2s ease-in-out;
}
.dropdown > a[data-toggle='dropdown']:before {
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -moz-transition: -moz-transform 0.6s;
  -o-transition: -o-transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
}

@keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }
  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }
  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }
  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }
  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }
  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }
  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-moz-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }
  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }
  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }
  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }
  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }
  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }
  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-webkit-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }
  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }
  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }
  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }
  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }
  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }
  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }
  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }
  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@-moz-keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }
  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }
  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@-webkit-keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }
  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }
  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
</style>
