<template>
  <div>
    <el-row :gutter="20">
      <el-col :md="24">
        <el-table border fixed size="mini" :data="comprobantes" v-loading="loadingTabla" :row-class-name="tableRowClassName" height="400" style="width: 100%">
          <el-table-column width="100" prop="comprobante" label="Comprobante">
            <template slot-scope="scope">
              <span v-if="scope.row.comprobante">{{ scope.row.comprobante }}</span>
              <span v-else>{{ scope.row.terminal }}{{ scope.row.numero }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="tipo2" label="Tipo" width="50">
            <template slot-scope="scope">
              <span v-if="scope.row.tipo2">{{ scope.row.tipo2 }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column sortable prop="fecha_comprobante" label="Fecha comprobante" width="160">
            <template slot-scope="scope">
              <span v-if="scope.row.fecha_comprob">{{ scope.row.fecha_comprob.date | fecha }}</span>
              <span v-else-if="scope.row.fecha_comprobante">{{ scope.row.fecha_comprobante.date | fecha }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="guia_olconv" label="Convenio">
            <template slot-scope="scope">
              <span v-if="scope.row.guia_olconv != null">
                {{ getNombreConv(scope.row.guia_olconv) }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="cuit" label="Cuit" width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.cuit">{{ scope.row.cuit }}</span>
              <span v-else> - </span>
            </template>
          </el-table-column>
          <el-table-column sortable prop="fecha_escaneo" label="Fecha escaneo" width="140">
            <template slot-scope="scope">
              <span v-if="scope.row.fecha_escaneo">{{ scope.row.fecha_escaneo.date | fechaE }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="conforme" label="Conforme" width="100"> </el-table-column>
          <el-table-column label="Sucursal" v-if="showT" align="center">
            <el-table-column align="center" prop="presentacion_suc" label="Presentación" width="95">
              <template slot-scope="scope">
                <span v-if="scope.row.presentacion_suc && scope.row.presentacion_suc > 0">{{ scope.row.presentacion_suc }}</span>
                <span v-if="scope.row.presentacion_suc == null || scope.row.presentacion_suc == 0">-</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="guia_suc" label="Guia" width="90" order>
              <template slot-scope="scope">
                <span v-if="scope.row.guia_suc != null && scope.row.guia_suc > 0">{{ scope.row.guia_suc }}</span>
                <span v-if="scope.row.guia_suc == null || scope.row.guia_suc == 0">-</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="canalizador_suc" label="Canalizador" width="90">
              <template slot-scope="scope">
                <span v-if="scope.row.canalizador_suc != null && scope.row.canalizador_suc > 0">{{ scope.row.canalizador_suc }}</span>
                <span v-if="scope.row.canalizador_suc == null || scope.row.canalizador_suc == 0">-</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="Sucursal" v-else>
            <template slot-scope="scope">
              <span v-if="scope.row.sucursal && scope.row.sucursal > 0 && scope.row.nombre != null"> {{ scope.row.sucursal }}-{{ scope.row.nombre }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column v-if="showT" prop="empresa" label="Empresa" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.empresa"> {{ scope.row.empresa }}</span>
              <span v-else>LOG</span>
            </template>
          </el-table-column>
          <el-table-column prop="afinidad" label="Afinidad" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.afinidad"> {{ scope.row.afinidad }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="guia_completa" label="Guía" width="100" sortable>
            <template slot-scope="scope">
              <span v-if="scope.row.guia_terminal != -1 && scope.row.guia_terminal != null"> {{ scope.row.guia_terminal }}-{{ scope.row.guia_numero }} </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="Acciones" width="200" align="center">
            <template slot-scope="scope">
              <el-button-group>
                <el-button
                  :type="'danger'"
                  :icon="'el-icon-document-add'"
                  size="mini"
                  :loading="descargando == scope.row.id"
                  plain
                  @click="openPdf(scope.row)"
                  :disabled="scope.row.hojas == null"
                  >Ver
                  <span v-if="scope.row.hojas != null">({{ scope.row.hojas }})</span>
                  <span v-else>(0)</span>
                </el-button>
                <el-button type="link" :loading="buscando == scope.row.id" :disabled="scope.row.guia_terminal == -1" size="mini" @click="buscarGuia(scope.row)"
                  ><i class="el-icon-document-checked"></i> Guía</el-button
                >
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="hidden-xs-only"
          v-if="comprobantes.length && comprobantes[0].TotalRows"
          style="margin-bottom: 20px; margin-right: 6px; margin-top: 5px; float: right"
          background
          layout="total,prev, pager, next"
          :total="cant_comprobantes"
          :page-size="12"
          @current-change="actualizarComprobantes"
          :current-page="pagina_actual"></el-pagination>
        <el-pagination
          class="hidden-sm-and-up"
          v-if="comprobantes.length && comprobantes[0].TotalRows"
          style="margin-bottom: 20px; margin-right: 6px; margin-top: 5px"
          background
          layout="total,prev, pager, next"
          :total="cant_comprobantes"
          :page-size="12"
          @current-change="actualizarComprobantes"
          :current-page="pagina_actual">
        </el-pagination>
        <el-dialog :visible.sync="modalDetalle" width="600px" top="1vh" class="soporteModal">
          <div class="hidden-xs-only">
            <el-carousel :autoplay="false" arrow="always" height="80vh" :indicator-position="'none'">
              <el-carousel-item v-for="c in numPages" :key="c.id" class="comprob" @click.native="openFoto(c.ruta)">
                <pdf :src="c.ruta"></pdf>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="hidden-sm-and-up">
            <el-carousel :autoplay="false" arrow="always" :indicator-position="'none'">
              <el-carousel-item v-for="c in numPages" :key="c.id" class="comprob" @click.native="openFoto(c.ruta)">
                <pdf :src="c.ruta"></pdf>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div v-for="i in numPages" :key="i.id + 'B'">
            <el-col :lg="{ span: 6 }" :md="{ span: 6 }" :sm="{ span: 6 }" :xs="{ span: 6 }">
              <el-button size="mini" type="danger" style="margin-bottom: 5px" @click="openFoto(i.ruta)">Descargar hoja {{ i.id }} </el-button>
            </el-col>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="mini" @click="modalDetalle = false">Cerrar</el-button>
          </span>
        </el-dialog>
      </el-col>
    </el-row>
    <!-- <el-row :gutter="20">
      <el-col :md="24">
        <div style="overflow-x:auto;">
        <table
          class="table table-sm table-bordered table-hover table-striped tabla-comprobante table-responsive"

        >
          <thead>
            <tr>
              <th style="width: 90px">Comprobante</th>
              <th style="width: 30px">Tipo</th>
              <th style="width: 80px">Fecha comprobante</th>
              <th>Convenio</th>
              <th style="width: 90px">CUIT</th>

              <th style="width: 90px">Fecha escaneo</th>
              <th style="width: 90px">Conforme</th>
              <th style="width: 60px" colspan="3"  v-if="showT">Sucursal </th>
              <th style="width: 60px" v-else>Sucursal </th>
              <th style="width: 60px">Empresa</th>
              <th style="width: 40px">Afinidad</th>
              <th style="width: 80px">Guía</th>
              <th>Acciones</th>
            </tr>
            <tr v-if="showT">
            <th colspan="7"></th>

            <th style="width: 60px">Presentacion</th>
            <th style="width: 40px">Guía</th>
            <th style="width: 60px">Canalizador</th>
            <th colspan="4"></th>
          </tr>

          </thead>
          <tbody v-loading="loadingTabla"
          element-loading-text="Cargando comprobantes..."
          element-loading-spinner="el-icon-loading"
          style="height: 100px;"
          >
            <tr v-for="c in comprobantes" :key="c.id">
              <td>
                <span v-if="c.comprobante">{{ c.comprobante }}</span>
                <span v-else>{{ c.terminal }}{{ c.numero }}</span>
              </td>
              <td v-if="c.tipo2">{{ c.tipo2 }}</td>
              <td v-else>-</td>
              <td>

                <span v-if="c.fecha_comprob">{{ c.fecha_comprob.date | fecha }}</span>
                <span v-else-if="c.fecha_comprobante"> {{ c.fecha_comprobante.date | fecha }}</span>
                <span v-else>-</span>

                </td>
              <td>
                <span v-if="c.guia_olconv!= null">
                  {{ getNombreConv(c.guia_olconv) }}
                </span>
                <span v-else>-</span>
              </td>
              <td>
                <span v-if="c.cuit">{{ c.cuit }}</span>
                <span v-else> - </span>
              </td>

              <td style="width:120px;">
                <span v-if="c.fecha_escaneo">
                  {{ c.fecha_escaneo.date | fechaE }}
                </span>
                <span v-else> sin registrar </span>
              </td>
              <td>{{ c.conforme }}</td>

              <td  v-if="c.presentacion_suc!=null && c.presentacion_suc>0 && showT">{{c.presentacion_suc}}</td>
              <td v-if="(c.presentacion_suc==null || c.presentacion_suc == 0) && showT">-</td>

              <td v-if="c.guia_suc!=null && c.guia_suc>0 && showT">{{c.guia_suc}}</td>
              <td v-if="(c.guia_suc==null || c.guia_suc == 0) && showT">-</td>

              <td v-if="c.canalizador_suc!=null && c.canalizador_suc>0 && showT">{{c.canalizador_suc}}</td>
              <td v-if="(c.canalizador_suc==null || c.canalizador_suc == 0) && showT">-</td>

              <td v-if=" !showT && c.sucursal!=null && c.sucursal>0 && c.nombre!=null" style="width:120px;">{{c.sucursal}}-{{c.nombre}}
              </td>

              <td v-if=" !showT && (c.sucursal==null || c.sucursal == 0 || c.nombre == null) ">-</td>

              <td>{{ c.empresa || "LOG" }}</td>
              <td>
                <span v-if="c.afinidad">
                  {{ c.afinidad}}
                </span>
                <span v-else> - </span>
              </td>
              <td>
                <span v-if="c.guia_terminal!=-1">
                  {{ c.guia_terminal}}-{{c.guia_numero}}
                </span>
                <span v-else> - </span>
              </td>
              <td style="width:180px;text-align:center">
                <el-button-group>
                  <el-button
                    :type="'danger'"
                    :icon="'el-icon-document-add'"
                    size="mini"
                    :loading="descargando == c.id"
                    plain
                    @click="openPdf(c)" :disabled="c.hojas==null"
                    >Ver
                    <span v-if="c.hojas!=null">({{c.hojas}})</span>
                    <span v-else>(0)</span>
                    </el-button>
                  <el-button
                    type="link"
                    :loading="buscando == c.id" :disabled="c.guia_terminal==-1"
                    size="mini"
                    @click="buscarGuia(c)"
                    ><i class="el-icon-document-checked"></i> Guía</el-button
                  >
                </el-button-group>
              </td>
            </tr>
            <tr>
              <td colspan="13" v-if="!showT">
                <div
                  v-if="!comprobantes.length && !loadingTabla"
                  class="sin-datos"
                >
                  <h2
                    style="
                      text-align: center;
                      padding: 20px;
                      font-size: 14px;
                    "
                  >
                    No hay datos para mostrar.
                  </h2>
                </div>
              </td>
              <td colspan="16" v-if="showT">
                <div
                  v-if="!comprobantes.length && !loadingTabla"
                  class="sin-datos"
                >
                  <h2
                    style="
                      text-align: center;
                      padding: 20px;
                      font-size: 14px;
                    "
                  >
                    No hay datos para mostrar.
                  </h2>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <el-pagination  class="hidden-xs-only"
          v-if="comprobantes.length && comprobantes[0].TotalRows"
          style="margin-bottom: 20px; margin-left: 10px; float: right"
          background
          layout="total,prev, pager, next"
          :total="cant_comprobantes"
          :page-size="12"
          @current-change="actualizarComprobantes"
          :current-page='pagina_actual'
        ></el-pagination>
        <el-pagination  class="hidden-sm-and-up"
          v-if="comprobantes.length && comprobantes[0].TotalRows"
          style="margin-bottom: 20px; margin-left: 10px;"
          background
          layout="total,prev, pager, next"
          :total="cant_comprobantes"
          :page-size="12"
          @current-change="actualizarComprobantes"
          :current-page='pagina_actual'
        >
        </el-pagination>
        </div>
          <el-dialog
            :visible.sync="modalDetalle"
            width="600px"
            top="1vh"
            class="soporteModal"           >
            <div class="hidden-xs-only">
              <el-carousel :autoplay="false" arrow="always" height="80vh" :indicator-position="'none'">

                <el-carousel-item
                  v-for="c in numPages"
                  :key="c.id"
                  class="comprob"
                    @click.native="openFoto(c.ruta)">
                  <pdf :src="c.ruta"></pdf>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="hidden-sm-and-up">
              <el-carousel :autoplay="false" arrow="always" :indicator-position="'none'">

                <el-carousel-item
                  v-for="c in numPages"
                  :key="c.id"
                  class="comprob"
                    @click.native="openFoto(c.ruta)">
                  <pdf :src="c.ruta"></pdf>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div  v-for="i in numPages" :key="i.id+'B'" >
                <el-col :lg="{ span: 6 }"
              :md="{ span: 6 }"
              :sm="{ span: 6 }"
              :xs="{ span: 6 }">
                  <el-button size="mini" type="danger" style="margin-bottom: 5px;" @click="openFoto(i.ruta)"
                    >Descargar hoja {{i.id}}
                  </el-button>
                </el-col>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" size="mini" @click="modalDetalle = false"
                >Cerrar</el-button>
            </span>

          </el-dialog>
      </el-col>
    </el-row> -->
    <!-- <el-dialog :visible.sync="modalGuia" :title="tituloDialog" width="50%">
      <h3>Información de la guía asociada</h3>

      <el-table
        :data="selectGuia"
        style="width: 100%"
        fit
        stripe
        border
        size="mini"
        highlight-current-row
      >
        <el-table-column prop="terminal" label="Terminal"> </el-table-column>
        <el-table-column prop="numero" label="Número"> </el-table-column>
        <el-table-column prop="comprob" label="Comprobante"> </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="modalGuia = false">Aceptar</el-button>
      </span>
    </el-dialog> -->
    <!-- Levantar detalle de guía -->
    <detalle></detalle>
  </div>
</template>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #dff6d3;
}
.el-table .uno {
  background-color: rgb(165, 126, 42);
}

.el-table .dos {
  color: white;
  background-color: rgb(10, 73, 108);
}
.el-table .tres {
  color: white;
  background-color: rgb(42, 165, 58);
}
.el-table .cuatro {
  color: white;
  background-color: rgb(219, 29, 206);
}

.el-table .cinco {
  background-color: brown;
}
</style>
<script>
const moment = require('moment')
import { EventBus } from '@/event-bus'
import { HTTP } from '@/axios'
import pdf from 'vue-pdf'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
// import InnerImageZoom from "vue-inner-image-zoom";
import DetalleGuia from '@/components/guias/detalleGuia.vue'

export default {
  components: {
    // InnerImageZoom,
    pdf,
    detalle: DetalleGuia,
  },
  // props:['lista_sucursales'],
  data() {
    return {
      tableData: [
        {
          date: '2016-05-03',
          name: 'Tom',
          state: 'California',
          city: 'Los Angeles',
          address: 'No. 189, Grove St, Los Angeles',
          zip: 'CA 90036',
        },
        {
          date: '2016-05-02',
          name: 'Tom',
          state: 'California',
          city: 'Los Angeles',
          address: 'No. 189, Grove St, Los Angeles',
          zip: 'CA 90036',
        },
        {
          date: '2016-05-04',
          name: 'Tom',
          state: 'California',
          city: 'Los Angeles',
          address: 'No. 189, Grove St, Los Angeles',
          zip: 'CA 90036',
        },
        {
          date: '2016-05-01',
          name: 'Tom',
          state: 'California',
          city: 'Los Angeles',
          address: 'No. 189, Grove St, Los Angeles',
          zip: 'CA 90036',
        },
        {
          date: '2016-05-08',
          name: 'Tom',
          state: 'California',
          city: 'Los Angeles',
          address: 'No. 189, Grove St, Los Angeles',
          zip: 'CA 90036',
        },
        {
          date: '2016-05-06',
          name: 'Tom',
          state: 'California',
          city: 'Los Angeles',
          address: 'No. 189, Grove St, Los Angeles',
          zip: 'CA 90036',
        },
        {
          date: '2016-05-07',
          name: 'Tom',
          state: 'California',
          city: 'Los Angeles',
          address: 'No. 189, Grove St, Los Angeles',
          zip: 'CA 90036',
        },
      ],
      loaderBuscador: true,
      buscando: false,
      descargando: false,
      modalDetalle: false,
      loadingTabla: true,
      comprobantes: [],
      modalGuia: false,
      tituloDialog: '',
      selectGuia: {},
      bg: false,
      url: '//caba33.log-in.com.ar/escaner/',
      ruta: false,
      pagina_actual: 1,
      cant_comprobantes: 0,
      convenioCero: '',
      empresaUrl: window.localStorage.getItem('empresa'),
      showT: false,
      user_permisos: JSON.parse(window.localStorage.getItem('datos')).permisos,
      numPages: [],
      sucursales: [],
    }
  },
  created() {
    let ol_multi = this.user_permisos.includes('OL_MULTI')
    let admin = this.user_permisos.includes('ADMIN')
    let escaner = this.user_permisos.includes('ESCANER')
    if (admin || escaner) {
      this.showT = true
    } else if (ol_multi) {
      this.showT = false
    }
  },
  mounted() {
    EventBus.$on('cargarComprob', (c) => {
      if (c) {
        this.loadingTabla = true
      }
    })
    EventBus.$on('sucursales', (sucursales) => {
      this.sucursales = sucursales
    })
    setTimeout(() => {
      EventBus.$on('getComprobantes', (c) => {
        window.scrollTo(0, 0)
        setTimeout(() => {
          this.convenios = c.convenios

          //  var colores =['uno','dos','tres','cuatro','cinco']
          c.datos.forEach((element) => {
            if (element.guia_terminal != -1 && element.guia_terminal != null) {
              let primero = element.guia_terminal + element.guia_numero
              let id_primero = element.id
              // let index = 0;
              let color = 'success-row'
              c.datos.find((otro) => {
                if (primero == otro.guia_terminal + otro.guia_numero && otro.id != id_primero) {
                  // console.log(primero+'-'+element.color)
                  element.color = color
                  otro.color = color

                  return 1
                } else {
                  if (color == 'warning-row') {
                    color = 'success-row'
                  } else {
                    color = 'warning-row'
                  }

                  return 0
                }
              })
            }
          })

          //  console.log(c)
          if (c.datos.length > 0) {
            this.comprobantes = c.datos
            this.cant_comprobantes = c.datos[0].TotalRows
          } else {
            this.comprobantes = []
            this.cant_comprobantes = 0
          }
          this.loadingTabla = false
          this.pagina_actual = c.pagina == true ? 1 : c.pagina
        }, 500)
      })
    }, 1000)
  },
  beforeDestroy() {
    EventBus.$off('getComprobantes')
    EventBus.$off('cargarComprob')
    EventBus.$off('Mostrar')
    EventBus.$off('sucursales')
    EventBus.$off('abrirModalDetalles', false)
  },
  methods: {
    tableRowClassName({ row }) {
      // console.log(rowIndex,row.comprobante)
      if (row.color !== '' && row.guia_terminal != -1 && row.guia_terminal != null) {
        return row.color
      }
      return ''
    },
    openPdf(c) {
      this.numPages = []
      //  console.log(c)
      if (c.comprob) {
        this.descargando = c.id
        if (c.empresa == 'DSA' && this.empresaUrl != 'lo') {
          this.url = 'https://seguimiento.suizoargentina.com.ar/seguimiento'
        } else {
          this.url = this.empresaUrl != 'lo' ? 'https://seguimiento.suizoargentina.com.ar/seguimiento' : 'https://seguimiento.log-in.com.ar/seguimiento'
        }
        // let url = this.empresaUrl == 'lo' ? "robotescanerlogin/downloadPdfLogin" : "/escaner/rob"
        let url = 'robotescanerlogin/downloadPdfs'
        let empresa_comprobante = c.empresa == 'DSA' ? 'DSA' : 'LOGIN'
        HTTP.post(url, {
          ruta: c.ruta,
          id: c.id,
          hojas: c.hojas,
          empresa: empresa_comprobante,
        })
          .then((res) => {
            this.descargando = false
            //  console.log(res.data)
            if (c.hojas == 1 || c.hojas == null) {
              if (res.data.status === 1) {
                this.ruta = this.url + res.data.archivo
                this.numPages.push({
                  id: c.hojas,
                  ruta: this.url + res.data.archivo,
                })
                setTimeout(() => {
                  this.modalDetalle = true
                }, 100)
              } else if (res.data.status === 2) {
                // console.log(res.data.status)
                this.$alert('El archivo no existe')
              } else {
                this.$alert('No se pudo recuperar el archivo ')
              }
            } else {
              res.data.forEach((value, key) => {
                if (value.status === 1) {
                  this.numPages.push({
                    id: key + 1,
                    ruta: this.url + value.archivo,
                  })
                }
              })
              if (this.numPages.length) {
                setTimeout(() => {
                  this.modalDetalle = true
                }, 250)
              } else {
                this.modalDetalle = false
              }
            }
          })
          .catch((e) => {
            this.descargando = false

            this.$alert('Error al descargar el archivo ', e)
          })
      } else {
        let pdf = c.ruta.split('.')[1]
        if (pdf && pdf == 'pdf') {
          this.descargando = c.id

          this.url = 'https://cdn.log-in.com.ar/escaner/'
          this.ruta = this.url + c.ruta
          this.numPages.push({
            id: c.hojas,
            ruta: this.ruta,
          })

          setTimeout(() => {
            this.descargando = false
            this.modalDetalle = true
          }, 100)
        } else {
          this.$alert('El archivo está dañado o no existe ')
        }
      }
    },
    openFoto(url) {
      window.open(url, '_blank')
    },
    zeroPad(num, places) {
      var zero = places - num.toString().length + 1
      return Array(+(zero > 0 && zero)).join('0') + num
    },
    actualizarComprobantes(p) {
      setTimeout(() => {
        EventBus.$emit('actualizarComprobantes', p)
      }, 300)
    },
    buscarGuia(c) {
      // console.log(c);
      this.buscando = c.id
      // this.cargando = true
      setTimeout(() => {
        this.buscando = false
      }, 2000)
      setTimeout(() => {
        EventBus.$emit('abrirModalDetalles', {
          info: c,
          empresa: this.empresa,
        })
      }, 500)
      // HTTP.post('/escaner/buscarGuia', {
      //   comprobante: c.comprob,
      //   empresa: c.empresa,
      //   convenio: c.guia_olconv,
      //   terminal: c.terminal,
      //   numero: c.numero,
      // })
      //   .then((res) => {
      //     this.buscando = false
      //     if (res.data != null) {
      //       if (res.data.terminal && res.data.numero) {
      //         // let routeData = this.$router.resolve({
      //         //   name: "detalles",
      //         //   query: { terminal: res.data.terminal, numero: res.data.numero },
      //         // });
      //         if (this.empresaUrl == 'u') {
      //           window.open('https://seguimiento.suizoargentina.com.ar/#/detalles?terminal=' + res.data.terminal + '&numero=' + res.data.numero, '_blank')
      //         } else {
      //           window.open('https://seguimiento.log-in.com.ar/#/detalles?terminal=' + res.data.terminal + '&numero=' + res.data.numero, '_blank')
      //         }
      //       }
      //     } else {
      //       this.$alert('No se encontró guia asociada al comprobante')
      //     }
      //   })
      //   .catch((e) => {
      //     this.$alert('error al obtener la guía ', e)
      //   })
    },
    getNombreConv(guia_olconv) {
      // let tipo = empresa == "DSA" ? "receta" : "logistica";
      //  console.log("this.convenios['receta']", this.convenios['receta']);
      //  console.log("this.convenios['logistica']", this.convenios['logistica']);
      if (guia_olconv > 0) {
        if (this.convenios && this.convenios.length) {
          let nameConv = this.convenios.filter((f) => {
            return f.codigo == guia_olconv
          })
          if (nameConv.length > 0) {
            return nameConv[0].codigo + '- ' + nameConv[0].descrip
          } else {
            return ' - '
          }
        }
      } else if (guia_olconv == 0 || guia_olconv == 999) {
        let convenioCero = guia_olconv == 0 ? '0 -COMPROBANTES SUIZO' : '99-COMPROBANTES LOGIN'
        return convenioCero
      }
    },
    getSucursal(datos) {
      if (datos.sucursal == null) {
        return datos.presentacion_suc != null && datos.presentacion_suc != 0
          ? datos.presentacion_suc
          : datos.guia_suc != null && datos.guia_suc != 0
          ? datos.guia_suc
          : datos.canalizador_suc != null && datos.canalizador_suc != 0
          ? datos.canalizador_suc
          : '-'
      } else {
        return '-'
      }
    },
    OperLogin(c) {
      this.numPages = []
      // this.descargando = c.id;
      // this.url =
      //     (c.empresa == "DSA" && this.empresaUrl != 'lo' )
      //       ? "https://seguimiento.suizoargentina.com.ar/seguimiento"
      //       : "https://seguimiento.log-in.com.ar/seguimiento";
      HTTP.post('/robotescanerlogin/traerTxt', { ruta: c.ruta, id: c.id })
        .then((res) => {
          // this.descargando = false;
          console.log('res2', res.data)
          // if(c.hojas==1)
          // {
          //   if (res.data.status === 1) {
          //     // console.log(this.url + res.data.archivo);
          //     this.ruta = this.url + res.data.archivo;
          //     this.numPages.push(
          //           {
          //             id: c.hojas,
          //             ruta: this.url + res.data.archivo
          //           })
          //     setTimeout(() => {
          //       this.modalDetalle = true;
          //     }, 100);
          //   }else if (res.data.status === -1) {
          //     this.$alert("No se pudo recuperar el archivo ", res.data);
          //   }
          // }
          // else
          // {
          //   // console.log(res.data)

          //   res.data.forEach((value, key) => {
          //     // console.log(value);
          //     if (value.status === 1) {
          //       this.numPages.push({id:key+1,ruta:this.url + value.archivo})
          //     }
          //   });
          //   if(this.numPages.length)
          //   {
          //      setTimeout(() => {
          //       this.modalDetalle = true;
          //     }, 250);
          //   }else
          //   {
          //      this.modalDetalle = false
          //   }

          // }
        })
        .catch((e) => {
          this.$alert('Error al descargar el archivo ', e)
        })
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD-MM-YYYY H:mm:ss')
    },
    fecha: function (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    fechaE: function (date) {
      return moment(date).format('DD-MM-YYYY H:mm:ss')
    },
    desde: function (date) {
      return moment(date).fromNow()
    },
  },
}
</script>
<style lang="css" scoped>
.tabla-comprobante > thead > tr > th {
  vertical-align: middle;
  background: #ff5722 !important;
  color: white;
  border-color: red !important;
  font-size: 12px !important;
}
.el-table__header-wrapper,
.el-table__footer-wrapper {
  border: 1px black solid;
}
.el-table .warning-row {
  background: oldlace;
}
.el-table .success-row {
  background: #f0f9eb;
}
.el-table .uno {
  background-color: rgb(165, 126, 42);
}

.el-table .dos {
  color: white;
  background-color: rgb(10, 73, 108);
}
.el-table .tres {
  color: white;
  background-color: rgb(42, 165, 58);
}
.el-table .cuatro {
  color: white;
  background-color: rgb(219, 29, 206);
}

.el-table .cinco {
  background-color: brown;
}
.greenClass {
  background: green;
}
.redClass {
  background: red;
}
</style>
