<template>
  <div v-loading="sistema.loading">
    <south-filter />
    <el-row :gutter="10">
      <el-col :span="24" v-show="this.sistema.datos">
        <div class="table-responsive-sm">
          <vuetable
            ref="vuetable"
            api-url="/south/guias"
            :http-fetch="myFetch"
            :fields="fields"
            pagination-path=""
            :css="css.table"
            :sort-order="sortOrder"
            :multi-sort="true"
            :append-params="moreParams"
            :per-page="20"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:loading="showLoader"
            @vuetable:loaded="hideLoader">
            <template slot="fecha_alta" slot-scope="props">
              <div @click="mostrarDetalles(props.rowData.id)">
                {{ formatDate(props.rowData.fecha_alta, 'DD-MM-YYYY HH:mm:ss') }}
              </div>
            </template>
            <template slot="fecha_info_api" slot-scope="props">
              <div @click="mostrarDetalles(props.rowData.id)">
                {{ formatDate(props.rowData.fecha_info_api, 'DD-MM-YYYY HH:mm:ss') }}
              </div>
            </template>
          </vuetable>
        </div>
        <div class="el-pagination is-background" style="margin-top: 20px; float: right; padding-bottom: 1rem">
          <vuetable-pagination-info ref="paginationInfo" info-class="pagination-info" info-template="Mostrando de {from}-{to} registros de un total de {total}" />
          <vuetable-pagination ref="pagination" :css="css.pagination" @vuetable-pagination:change-page="onChangePage" />
        </div>
      </el-col>
      <el-col :span="24" v-show="!this.sistema.datos">
        <el-alert type="info" show-icon :closable="false" center>
          <span slot="title">
            <p>No se encontraron guias asociadas a la busqueda.</p>
          </span>
        </el-alert>
      </el-col>
    </el-row>
    <south-details v-if="this.sistema.modal.visible" :modal="this.sistema.modal" />
  </div>
</template>
<script>
import { API } from '@/plugins/api'
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import config from '@/components/integraciones/_config'
import estadosJson from './_estados.json'
import SouthFilter from './Filter'
import SouthActions from './Actions'
import SouthDetails from './Details'
import moment from 'moment'

Vue.component('south-actions', SouthActions)

export default {
  name: 'SouthTable',
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    SouthFilter,
    SouthDetails,
  },
  data() {
    return {
      sistema: {
        loading: true,
        boton: 'btnCross',
        datos: false,
        modal: {
          visible: false,
          datos: null,
        },
      },
      fields: [
        {
          name: 'id',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          width: '110px',
          visible: false,
        },
        {
          name: 'terminal',
          sortField: 'terminal',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'numero',
          title: 'Número',
          sortField: 'numero',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'nroped',
          sortField: 'nroped',
          title: 'Nro Pedido',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          callback: this.callNroPedido,
        },
        {
          title: 'Comprobante',
          name: 'comprob',
          sortField: 'comprob',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
        },
        {
          name: 'razsoc',
          title: 'Razón social',
          sortField: 'razsoc',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '200px',
        },
        {
          name: 'olconv',
          sortField: 'olconv',
          title: 'Convenio',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
        },
        {
          name: 'direccion',
          title: 'Dirección',
          sortField: 'direccion',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
        },
        {
          name: '__slot:fecha_alta',
          title: 'Fecha Alta',
          sortField: 'fecha_alta',
          titleClass: 'text-left-titulo',
          dataClass: 'text-left-data',
          width: '150px',
        },
        {
          name: '__slot:fecha_info_api',
          title: 'Fecha Api',
          sortField: 'fecha_info_api',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          width: '150px',
        },
        {
          title: 'Estado Informado',
          name: 'logs',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          callback: this.callLogs,
        },
        {
          title: 'Respuesta API',
          name: 'logs',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          callback: this.callLogsAPI,
        },
        {
          name: '__component:south-actions',
          title: 'Acciones',
          titleClass: 'text-center-titulo',
          dataClass: 'text-center-data',
          width: '100px',
        },
      ],
      css: config.css,
      sortOrder: [{ field: 'id', sortField: 'id', direction: 'desc' }],
      moreParams: {
        //convenio: this.$store.getters.getDatos.convenio,
        convenio_select: ['608'],
      },
    }
  },
  methods: {
    async myFetch(apiUrl, httpOptions) {
      const datos = await API.get(apiUrl, httpOptions)
      if (datos.data.data.data.length > 0) this.sistema.datos = true
      else this.sistema.datos = false
      return datos.data
    },

    showLoader() {
      this.sistema.loading = true
    },
    hideLoader() {
      this.sistema.loading = false
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    mostrarDetalles(value) {
      this.$events.fire('mostrar-detalles', value)
    },
    formatDate(value, fmt = 'D MMM YYYY') {
      return value == null ? 'Sin registro' : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt)
    },
    callLogs(logs) {
      if (logs.length <= 0) return "<span class='el-tag el-tag--info'>SIN LOG</span>"
      let objeto = estadosJson.find((x) => x.codigo == logs[0]['estado_informado'])
      if (objeto === undefined) return "<span class='el-tag el-tag--info'>DESCONOCIDO</span>"
      else return "<span class='el-tag el-tag--" + objeto.color + "'>" + objeto.texto + '</span>'
    },
    callLogsAPI(logs) {
      if (logs.length <= 0) return "<span class='el-tag el-tag--info'>SIN LOG</span>"
      else {
        if (logs[0].estado === 'ok') return "<span class='el-tag el-tag--success'>OK</span>"
        else return "<span class='el-tag el-tag--danger'>ERROR</span>"
      }
    },
    callNroPedido(value) {
      if (value == '') return '-'
      else return value
    },
  },
  events: {
    'filter-set'(filtros) {
      this.moreParams = filtros
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'filter-reset'() {
      this.moreParams = {
        //convenio: this.$store.getters.getDatos.convenio
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    refrescarTabla() {
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    'mostrar-detalles-modal'(datos) {
      this.sistema.modal.datos = datos
      this.sistema.modal.visible = true
    },
    'cerrar-detalles-modal'() {
      this.sistema.modal.datos = null
      this.sistema.modal.visible = false
    },
  },
}
</script>
