<template>
  <div class="vstock_buscar">
    <el-row style="border-bottom: 1px solid #ddd; padding-bottom: 10px" :gutter="10">
      <el-col :span="24">
        <h4 style="font-size: 24px; margin: -3px">Consulta:</h4>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-top: 10px">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="Código Lote">
          <el-input v-model="formBuscar.lote" clearable placeholder="Ingrese código de lote" size="mini" ref="lote" @keypress.native.enter="buscarCod()"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="buscarCod()" :disabled="btnBuscar" size="mini">Buscar</el-button>
          <el-button @click="resetForm()" size="small">Limpiar</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      labelPosition: 'left',
      formBuscar: {
        lote: '',
        tipo_busqueda: 'lote',
        cliente: JSON.parse(window.localStorage.getItem('datos'))['cliente'],
        session: JSON.parse(window.localStorage.getItem('datos')),
      },
      btnBuscar: true,
    }
  },
  mounted() {
    this.$refs.lote.focus()
  },
  methods: {
    buscarCod() {
      if (this.formBuscar.lote.length >= 3) {
        HTTP.post('/vlogisticaLogin/StockGralLogin', this.formBuscar).then(
          // HTTP_DESARROLLO.post(
          //   '/vlogisticaLogin/StockGralLogin',
          //   this.formBuscar
          // ).then(
          (res) => {
            var $respuesta = []
            if (res.data) {
              $respuesta = res.data.resultados
            }
            EventBus.$emit('cargarTablaStockCOD', $respuesta)
          }
        )
      } else {
        this.$message({
          message: 'Por favor ingrese al menos tres caracteres para iniciar la búsqueda.',
          type: 'warning',
        })
      }
    },
    resetForm() {
      EventBus.$emit('limpiarStock')
      this.formBuscar.lote = ''
      this.btnBuscar = true
    },
  },
  watch: {
    formBuscar: {
      handler(val) {
        // do stuff
        this.btnBuscar = val.lote == '' ? true : false
      },
      deep: true,
    },
  },
}
</script>
