<template>
    <div>
        <resumen></resumen>
    </div>
</template>
<script>
import Resumen from '@/components/resumen/resumen.vue'
export default {
    components:{
        Resumen
    }
}
</script>