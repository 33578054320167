<template>
  <div>
    <el-row
      style="
        border-bottom: 1px solid #ddd;
        padding: 10px;
        margin-left: 0px !important;
      "
      :gutter="20">
      <el-col :span="24" style="display: flex">
        <img
          class="fir-author-image fir-clickcircle"
          src="@/assets/img-usuario.svg"
          alt="Información Usuario" />
        <h4 style="font-size: 20px; margin: 2px; padding-top: 5px">
          Información de la cuenta
        </h4>
      </el-col>
    </el-row>
    <el-row
      style="border-bottom: 1px solid #ddd; padding-bottom: 8px"
      :gutter="10">
      <figure class="fir-image-figure">
        <div class="fir-imageover-color"></div>
        <!-- <figcaption> -->
        <el-row :gutter="20">
          <el-col :md="10" :xs="24">
            <div class="fig-author-figure-title">
              <strong> <i class="el-icon-success"></i> Cuenta: </strong>
              <span v-if="fantasia != ''"> {{ fantasia }}</span>
              <span v-else> - </span>
            </div>
          </el-col>
          <el-col :md="6" :xs="24">
            <div class="fig-author-figure-title">
              <strong> <i class="el-icon-success"></i> Usuario: </strong>
              <span v-if="datos['user']"> {{ datos['user'] | Uppercase }}</span>
              <span v-else> - </span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :md="10" :xs="24" v-if="permiso_ol">
            <div class="fig-author-figure-title">
              <strong> <i class="el-icon-success"></i> Convenio: </strong>
              {{ convenio }}
            </div>
          </el-col>
          <el-col :md="12" :xs="24" v-if="permiso_stock">
            <div class="fig-author-figure-title">
              <strong>
                <i class="el-icon-success"></i> Convenio Logística:
              </strong>
              {{ convol }}
            </div>
          </el-col>
        </el-row>
      </figure>
    </el-row>
    <br />
    <div class="container" style="padding: 17px">
      <el-row :gutter="20">
        <el-col :md="8" :sm="12" :xs="22" v-if="permiso_ol">
          <div class="our-team" :style="borderEmpresa">
            <router-link to="/guias" style="text-decoration: none">
              <el-link :underline="false" class="color: #606266;">
                <div class="picture">
                  <!-- <img v-if="empresa=='u'" class="img-fluid" src="@/assets/001-caja-abierta-azul.png">
                                <img v-if="empresa=='lo'" class="img-fluid" src="@/assets/001-caja-abierta.png"> -->
                  <img
                    class="img-fluid"
                    src="@/assets/004-comercio-electronico-1.png" />
                </div>
                <div class="team-content">
                  <h1 class="name" style="font-size: 25px">
                    Consulta de Guías
                  </h1>
                  <p>
                    Permite ver el estado de las guías y los movimientos de los
                    mismos
                  </p>
                </div>
                <div class="social"></div>
              </el-link>
            </router-link>
          </div>
        </el-col>
        <el-col :md="8" :sm="12" :xs="22" v-if="permiso_ol">
          <div class="our-team" :style="borderEmpresa">
            <router-link to="/resumen" style="text-decoration: none">
              <el-link :underline="false" class="link-style">
                <div class="picture">
                  <div class="picture">
                    <!-- <img class="img-fluid" src="@/assets/ico-resumen-guias.png"> -->
                    <img
                      v-if="empresa == 'u'"
                      class="img-fluid"
                      src="@/assets/009-lista-de-verificacion-3.png" />
                    <img
                      v-if="empresa == 'lo'"
                      class="img-fluid"
                      src="@/assets/008-lista-de-verificacion-2.png" />
                  </div>
                </div>
                <div class="team-content">
                  <h1 class="name" style="font-size: 25px">Resumen de Guías</h1>

                  <p>
                    Muestra un resumen globlal de las guías procesadas y
                    pendiente agrupadas por su convenio
                  </p>
                </div>
                <div class="social"></div>
              </el-link>
            </router-link>
          </div>
        </el-col>
        <div v-if="permiso_olMulti">
          <el-col :md="8" :sm="12" :xs="22">
            <div class="our-team" :style="borderEmpresa">
              <router-link to="/resumenhojaruta" style="text-decoration: none">
                <el-link :underline="false" class="link-style">
                  <div class="picture">
                    <!-- <img v-if="empresa=='u'" class="img-fluid" src="@/assets/ico-resumen-hr.png">
                                <img v-if="empresa=='lo'" class="img-fluid" src="@/assets/ico-resumen-log.png"> -->
                    <img
                      v-if="empresa == 'u'"
                      class="img-fluid"
                      src="@/assets/002-transporte-1.png" />
                    <img
                      v-if="empresa == 'lo'"
                      class="img-fluid"
                      src="@/assets/001-transporte.png" />
                  </div>
                  <div class="team-content">
                    <h1 class="name" style="font-size: 25px">
                      Resumen de Hojas de Ruta
                    </h1>

                    <p>
                      Permite ver un resumen estado de los repartos y Hojas de
                      ruta por sucursal
                    </p>
                  </div>
                  <div class="social"></div>
                </el-link>
              </router-link>
            </div>
          </el-col>
        </div>
        <el-col
          :md="8"
          :sm="12"
          :xs="22"
          v-if="
            permiso_escaner || permiso_olMulti || permiso_ollista || permiso_ol
          ">
          <div class="our-team" :style="borderEmpresa">
            <router-link to="/comprobantes" style="text-decoration: none">
              <el-link :underline="false" class="color: #606266;">
                <div class="picture">
                  <img
                    v-if="empresa == 'u'"
                    class="img-fluid"
                    src="@/assets/001-descargar.png" />
                  <img
                    v-if="empresa == 'lo'"
                    class="img-fluid"
                    src="@/assets/002-descargar-1.png" />
                </div>
                <div class="team-content">
                  <h1 class="name" style="font-size: 25px">Comprobantes</h1>
                  <p>Permite ver y buscar los comprobantes escaneados</p>
                </div>
                <div class="social"></div>
              </el-link>
            </router-link>
          </div>
        </el-col>
        <el-col :md="8" :sm="12" :xs="22" v-if="permiso_remitos">
          <div class="our-team" :style="borderEmpresa">
            <router-link to="/remitos" style="text-decoration: none">
              <el-link :underline="false" class="color: #606266;">
                <div class="picture">
                  <!-- <img  class="img-fluid" src="@/assets/001-smartphone.png"> -->
                  <img
                    class="img-fluid"
                    src="@/assets/009-aplicacion-de-seguimiento-1.png" />
                </div>
                <div class="team-content">
                  <h1 class="name" style="font-size: 25px">Remitos</h1>
                  <p>
                    Permite ver información de Choferes, Configuración de R.F.O
                  </p>
                </div>
                <div class="social"></div>
              </el-link>
            </router-link>
          </div>
        </el-col>
        <el-col
          :md="8"
          :sm="12"
          :xs="22"
          v-if="permiso_stock || permiso_stock_DSA">
          <div class="our-team" :style="borderEmpresa">
            <router-link :to="stock_empresa" style="text-decoration: none">
              <el-link :underline="false" class="color: #606266;">
                <div class="picture">
                  <img
                    v-if="empresa == 'u'"
                    class="img-fluid"
                    src="@/assets/006-lista-de-verificacion-1.png" />
                  <img
                    v-if="empresa == 'lo'"
                    class="img-fluid"
                    src="@/assets/003-lista-de-verificacion.png" />
                </div>
                <div class="team-content">
                  <h1 class="name" style="font-size: 25px">Stock</h1>
                  <p>
                    Permite conocer los productos en stock e información de los
                    mismos.
                  </p>
                </div>
                <div class="social"></div>
              </el-link>
            </router-link>
          </div>
        </el-col>

        <el-col :md="8" :sm="12" :xs="22" v-if="permiso_user">
          <div class="our-team" :style="borderEmpresa">
            <router-link to="/usuarios" style="text-decoration: none">
              <el-link :underline="false" class="color: #606266;">
                <div class="picture">
                  <img
                    v-if="empresa == 'u'"
                    class="img-fluid"
                    src="@/assets/001-usuario.png" />
                  <img
                    v-if="empresa == 'lo'"
                    class="img-fluid"
                    src="@/assets/002-usuario-1.png" />
                </div>
                <div class="team-content">
                  <h1 class="name" style="font-size: 25px">Usuarios</h1>
                  <p>Permite crear, editar y eliminar usuarios.</p>
                </div>
                <div class="social"></div>
              </el-link>
            </router-link>
          </div>
        </el-col>
        <el-col :md="8" :sm="12" :xs="22" v-if="permiso_crossdock">
          <div class="our-team" :style="borderEmpresa">
            <router-link to="/cross" style="text-decoration: none">
              <el-link :underline="false" class="color: #606266;">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="@/assets/003-comercio-electronico.png" />
                </div>
                <div class="team-content">
                  <h1 class="name" style="font-size: 25px">Crossdocking</h1>
                  <p>
                    Permite cargar, importar y validar la informacion para guías
                    y bultos.
                  </p>
                </div>
                <div class="social"></div>
              </el-link>
            </router-link>
          </div>
        </el-col>
        <el-col :md="8" :sm="12" :xs="22" v-if="permiso_integraciones">
          <div class="our-team" :style="borderEmpresa">
            <router-link to="/integraciones" style="text-decoration: none">
              <el-link :underline="false" class="color: #606266;">
                <div class="picture">
                  <img class="img-fluid" src="@/assets/001-subir-archivo.png" />
                </div>
                <div class="team-content">
                  <h1 class="name" style="font-size: 25px">
                    Integraciones externas
                  </h1>
                  <p>Acceso a integraciones de servicios externos.</p>
                </div>
                <div class="social"></div>
              </el-link>
            </router-link>
          </div>
        </el-col>
        <el-col :md="8" :sm="12" :xs="22" v-if="permiso_turnos">
          <div class="our-team" :style="borderEmpresa">
            <router-link to="/turnos" style="text-decoration: none">
              <el-link :underline="false" class="color: #606266;">
                <div class="picture">
                  <!-- <img v-if="empresa=='u'" class="img-fluid" src="@/assets/007-turnos.png">
                                <img v-if="empresa=='lo'" class="img-fluid" src="@/assets/007-turnos.png"> -->
                  <img
                    class="img-fluid"
                    src="@/assets/001-gestion-de-proyectos.png" />
                </div>
                <div class="team-content">
                  <h1 class="name" style="font-size: 25px">Turnos Web</h1>
                  <p>
                    Administracion de turnos para el Ingreso de Camiones a
                    Planta
                  </p>
                </div>
                <div class="social"></div>
              </el-link>
            </router-link>
          </div>
        </el-col>
        <el-col :md="8" :sm="12" :xs="22" v-if="permiso_fm">
          <div class="our-team" :style="borderEmpresa">
            <router-link to="/fm" style="text-decoration: none">
              <el-link :underline="false" class="color: #606266;">
                <div class="picture">
                  <!-- <img v-if="empresa=='u'" class="img-fluid" src="@/assets/007-turnos.png">
                                <img v-if="empresa=='lo'" class="img-fluid" src="@/assets/007-turnos.png"> -->
                  <img
                    class="img-fluid"
                    src="@/assets/001-libreria-digital.png" />
                </div>
                <div class="team-content">
                  <h1 class="name" style="font-size: 25px">Archivos</h1>
                  <p>Permite ver los archivos cargados</p>
                </div>
                <div class="social"></div>
              </el-link>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { baseUrl } from '@/axios'

export default {
  data() {
    return {
      permisos_usuario: '',
      empresa: '',
      baseUrl: baseUrl,
      fantasia: '',
      convenio: '',
      style_estado: {},
      permiso_ol: false,
      permiso_ollista: false,
      permiso_olMulti: false,
      permiso_remitos: false,
      permiso_escaner: false,
      permiso_stock: false,
      permiso_stock_DSA: false,
      stock_empresa: '',
      permiso_user: false,
      permiso_crossdock: false,
      permiso_integraciones: false,
      permiso_turnos: false,
      permiso_fm: false,
      imgGuia: '',
      datos: [],
      principal: baseUrl + '/guias',
      convol: '',
      borderEmpresa: {},
    }
  },
  mounted() {
    // this.permisos_usuario = JSON.parse(window.localStorage.getItem("permisos"))
    this.fantasia =
      window.localStorage.getItem('fantasia') == 'null'
        ? ' - '
        : window.localStorage.getItem('fantasia')
    this.empresa = window.localStorage.getItem('empresa')
    this.datos = JSON.parse(window.localStorage.getItem('datos'))
    this.convenio =
      window.localStorage.getItem('convenio') == ''
        ? '-'
        : '(' +
          JSON.parse(window.localStorage.getItem('datos')).ol_convenio +
          ') ' +
          window.localStorage.getItem('convenio')
    this.permisos_usuario = JSON.parse(window.localStorage.getItem('permisos'))

    if (this.datos['user'] == 'pedidos_test') {
      this.convol = 'LOG-IN'
    } else {
      this.convol =
        JSON.parse(window.localStorage.getItem('convol')) == null ||
        JSON.parse(window.localStorage.getItem('convol')) == false
          ? '-'
          : '(' +
            JSON.parse(window.localStorage.getItem('convol')).convol +
            ') ' +
            JSON.parse(window.localStorage.getItem('convol')).descrip
    }
    setTimeout(() => {
      this.permisos()
    }, 200)
  },

  methods: {
    permisos() {
      if (this.empresa == 'lo') {
        this.imgGuia = '@/assets/ico-guias-login.png'
        this.borderEmpresa['borderTop'] = '5px solid #7e889d'
        this.borderEmpresa['borderBottom'] = '5px solid #7e889d'
        this.borderEmpresa['borderLeft'] = '1px solid #7e889d'
        this.borderEmpresa['borderRight'] = '1px solid #7e889d'
      } else {
        this.imgGuia = '@/assets/ico-guias.png'
        this.borderEmpresa['borderTop'] = '3px solid #0b406a78'
        this.borderEmpresa['borderBottom'] = '3px solid #0b406a78'
        this.borderEmpresa['borderLeft'] = '1px solid #0b406a78'
        this.borderEmpresa['borderRight'] = '1px solid #0b406a78'
      }
      this.style_estado['color'] =
        this.datos['estado_text'] == 'NORMAL' ? '#67c23a' : '#f56c6c'
      this.permiso_user = this.permisos_usuario.includes('OL_ABM_USUARIOS')
        ? true
        : false
      if (this.permisos_usuario.includes('ADMIN')) {
        this.permiso_olMulti = true
        this.permiso_ollista = true
        this.permiso_ol = true
        this.permiso_remitos = true
        this.permiso_escaner = true
        // this.permiso_stock      = true;
        if (this.empresa == 'lo') {
          this.permiso_stock = true
          this.stock_empresa = '/stock'
        } else {
          this.permiso_stock_DSA = true
          this.stock_empresa = '/stockdsa'
        }
      } else {
        this.permiso_olMulti = this.permisos_usuario.includes('OL_MULTI')
          ? true
          : false
        this.permiso_ollista = this.permisos_usuario.includes('OL_LISTACONV')
          ? true
          : false
        this.permiso_ol = this.permisos_usuario.includes('OL') ? true : false
        this.permiso_remitos = this.permisos_usuario.includes('APP_USUARIOS')
          ? true
          : false
        this.permiso_escaner = this.permisos_usuario.includes('ESCANER')
          ? true
          : false
        if (this.empresa == 'lo') {
          this.permiso_stock = this.permisos_usuario.includes('STOCK')
            ? true
            : false
          this.stock_empresa = '/stock'
        } else {
          this.permiso_stock_DSA = this.permisos_usuario.includes('STOCK')
            ? true
            : false
          // this.permiso_stock_DSA  = this.permisos_usuario.includes("OL") ? true : false;
          this.stock_empresa = '/stockdsa'
        }
        // this.permiso_stock      = this.permisos_usuario.includes("STOCK") ? true : false;
        this.permiso_crossdock = this.permisos_usuario.includes('CROSSDOCK')
          ? true
          : false
        this.permiso_integraciones = this.permisos_usuario.includes(
          'INTEGRACIONES'
        )
          ? true
          : false
        this.permiso_turnos =
          this.permisos_usuario.includes('TURNOS') ||
          this.permisos_usuario.includes('TURNOS_ADMIN') ||
          this.permisos_usuario.includes('TURNOS_CONSULTA') ||
          this.permisos_usuario.includes('TURNOS_OPERARIO') ||
          this.permisos_usuario.includes('TURNOS_VIGILANCIA')
            ? true
            : false || this.permisos_usuario.includes('TURNOS_CLIENTE')
            ? true
            : false
        this.permiso_fm = this.permisos_usuario.includes('FM')
      }
      /* Permisos */
    },
  },
  filters: {
    Uppercase: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.toUpperCase()
    },
  },
}
</script>

<style lang="scss" scoped>
.el-link.el-link--default:hover {
  color: #36404e;
}
.fir-clickcircle {
  height: 35px;
  width: 35px;
  border-radius: 100px;
  cursor: pointer;
}

.fir-image-figure .caption,
.fir-image-figure figcaption {
  padding-left: 15px;
}

html.wf-active .fir-image-figure .fig-author-figure-title {
  font-family: var(--fir-font-header);
  font-size: 16px;
}

.fir-image-figure .fig-author-figure-title {
  color: var(--fir-color-grey);
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
    Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
}

.container {
  width: 95% !important;
}
.our-team {
  padding: 5px 0 5px;
  margin-bottom: 30px;
  /* background-color: #ebeef5; */
  background: linear-gradient(96deg, #fffafa 0, #e5ebf6 64%);
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 230px;
  box-shadow: 13px 4px 7px 4px #bbc1ce;
  transition: 0.5s all;
}
.our-team:hover {
  transform: scale(1.05);
}
.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  z-index: 1;
  position: relative;
}
.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  /* width: 100%; */
  height: 90px;
  border-radius: 0%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
  padding-top: 18px;
}

.our-team:hover .picture::after {
  background-color: #c8d2e2 !important;
  border-radius: 50%;
}

p {
  font-size: 13px;
  padding: 0px 2px 0px 2px;
}
.el-link {
  position: initial;
}
.link-style {
  font-weight: 500;
  font-size: 11px;
}
</style>
<style lang="css">
/* .richmond {
  max-width: 145px !important;
}
.menu-tt.el-tooltip__popper .popper__arrow::after {
  content: " ";
  border-width: 5px;
  border-bottom-color: #ffeb3b !important;
}
.menu-tt.el-tooltip__popper .popper__arrow {
  border-bottom-color: #ffeb3b !important;
}

.menu-tt.el-tooltip__popper.is-light {
  background: #ffeb3b !important;
  border: 1px solid #ffeb3b !important;
} */
.menu li {
  display: inline-block;
}
.menu li a {
  font-size: 13px;
  text-decoration: none;
  transition: all 0.45s;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  color: #ffffff;
  font-weight: 500;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  line-height: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
body {
  background: radial-gradient(#fefefe, #ffffff);
}
.iconos {
  font-size: 20px;
}

.menu li a.home:hover {
  color: #95a5a6;
  transform: scale(1.1) matrix(1, 0, 0, 1, 0, 0);
}

.menu li a:active:before {
  color: #95a5a6;
  transform: scale(1.1) matrix(1, 0, 0, 1, 0, 0);
}
a:link,
a:visited {
  color: white;
}

.menu li a:hover {
  color: #95a5a6;
  transform: scale(1.1) matrix(1, 0, 0, 1, 0, 0);
}
.activar {
  color: #ffffff !important;
  transform: scale(1.1) matrix(1, 0, 0, 1, 0, 0);
  font-weight: bold !important;
  border-bottom: 5px solid #3a8ee6;
}
/* Boton ir a ayuda */
.boton {
  width: 22px;
  height: 22px;
  background-color: #6e9de4;
  margin: 5px;
  margin-top: 0px;
  padding: 10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size: 30px;
  line-height: 32px;
  text-transform: uppercase;
  color: white !important;
}
.boton:hover {
  opacity: 0.5;
  -moz-opacity: 0.5;
  filter: alpha (opacity=50);
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #104ca5;
  color: #606266;
}
</style>
