<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-table
          :data="turnos"
          size="mini"
          border
          fit
          element-loading-text="Cargando..."
          ref="tablaTurnos"
          element-loading-spinner="el-icon-loading"
          v-loading="sistema.loading"
          :cell-class-name="classChecker"
          :row-class-name="tableRowClassName"
          style="width: 100%"
          height="650">
          <el-table-column label="HORA" prop="hora_inicial" width="80" align="center" fixed>
            <template slot-scope="scope">
              <i class="el-icon-time"></i><span style="margin-left: 10px">{{ scope.row.hora_inicial }}</span>
            </template>
          </el-table-column>
          <el-table-column label="TURNADOS" prop="turno" width="90" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.turno.length > 0">
                <el-badge :value="scope.row.turno.length" class="item" type="primary">
                  <img style="height: 30px; margin-left: 20px" src="@/assets/truck.svg" @click="$events.fire('open-tabla-visor', scope.row.turno)" />
                </el-badge>
              </div>
              <div v-else>
                <p><br /></p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="PLAYA" prop="playa" align="center">
            <template slot-scope="scope">
              <div class="image-row" v-if="scope.row.playa.length > 0">
                <el-tooltip v-for="item in scope.row.playa" :key="item.id" placement="right" effect="light" popper-class="draw_share_atooltip">
                  <div slot="content">
                    <el-descriptions direction="horizontal" size="mini" :title="'TURNO #' + item.id" :column="1" style="width: 300px">
                      <el-descriptions-item style="width: 50px">
                        <template slot="label"> <i class="fas fa-clipboard-list"></i><span style="padding-left: 5px">PROVEEDOR CONVENIO</span> </template>
                        {{ item.proveedor_convenio }}
                      </el-descriptions-item>
                      <el-descriptions-item style="width: 50px">
                        <template slot="label"><i class="fas fa-truck-moving"></i><span style="padding-left: 5px">CHOFER</span></template>
                        {{ item.chofer_nombre }}
                      </el-descriptions-item>
                      <el-descriptions-item style="width: 50px">
                        <template slot="label"><i class="fas fa-truck-moving"></i><span style="padding-left: 5px">PATENTE</span></template>
                        {{ item.chofer_patente }}
                      </el-descriptions-item>
                      <el-descriptions-item>
                        <template slot="label"><i class="fas fa-info-circle"></i><span style="padding-left: 5px">EMPRESA</span></template>
                        {{ item.chofer_empresa }}
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                  <img style="height: 30px" src="@/assets/truck.svg" @click="$events.fire('cambiar-estado', item)" />
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="NAVE 1" align="center">
            <el-table-column v-for="dock of docks1" :key="dock.id" :label="`DOCK ${dock.numero}`" prop="dock" width="70" align="center">
              <template slot-scope="scope">
                <div v-for="item in scope.row.nave1" :key="item.id">
                  <el-tooltip v-if="item.dock_id == dock.id" placement="right" effect="light" popper-class="draw_share_atooltip">
                    <div slot="content">
                      <el-descriptions direction="horizontal" size="mini" :title="'TURNO #' + item.id" :column="1" style="width: 300px">
                        <el-descriptions-item style="width: 50px">
                          <template slot="label"> <i class="fas fa-clipboard-list"></i><span style="padding-left: 5px">PROVEEDOR CONVENIO</span> </template>
                          {{ item.proveedor_convenio }}
                        </el-descriptions-item>
                        <el-descriptions-item style="width: 50px">
                          <template slot="label"><i class="fas fa-truck-moving"></i><span style="padding-left: 5px">CHOFER</span></template>
                          {{ item.chofer_nombre }}
                        </el-descriptions-item>
                        <el-descriptions-item style="width: 50px">
                          <template slot="label"><i class="fas fa-truck-moving"></i><span style="padding-left: 5px">PATENTE</span></template>
                          {{ item.chofer_patente }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                          <template slot="label"><i class="fas fa-info-circle"></i><span style="padding-left: 5px">EMPRESA</span></template>
                          {{ item.chofer_empresa }}
                        </el-descriptions-item>
                      </el-descriptions>
                    </div>
                    <img style="height: 30px" src="@/assets/truck.svg" @click="$events.fire('cambiar-estado', item)" />
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="NAVE 2" align="center">
            <el-table-column v-for="dock of docks2" :key="dock.id" :label="`DOCK ${dock.numero}`" prop="dock" width="70" align="center">
              <template slot-scope="scope">
                <div v-for="item in scope.row.nave2" :key="item.id">
                  <el-tooltip v-if="item.dock_id == dock.id" placement="right" effect="light" popper-class="draw_share_atooltip">
                    <div slot="content">
                      <el-descriptions direction="horizontal" size="mini" :title="'TURNO #' + item.id" :column="1" style="width: 300px">
                        <el-descriptions-item style="width: 50px">
                          <template slot="label"> <i class="fas fa-clipboard-list"></i><span style="padding-left: 5px">PROVEEDOR CONVENIO</span> </template>
                          {{ item.proveedor_convenio }}
                        </el-descriptions-item>
                        <el-descriptions-item style="width: 50px">
                          <template slot="label"><i class="fas fa-truck-moving"></i><span style="padding-left: 5px">CHOFER</span></template>
                          {{ item.chofer_nombre }}
                        </el-descriptions-item>
                        <el-descriptions-item style="width: 50px">
                          <template slot="label"><i class="fas fa-truck-moving"></i><span style="padding-left: 5px">PATENTE</span></template>
                          {{ item.chofer_patente }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                          <template slot="label"><i class="fas fa-info-circle"></i><span style="padding-left: 5px">EMPRESA</span></template>
                          {{ item.chofer_empresa }}
                        </el-descriptions-item>
                      </el-descriptions>
                    </div>
                    <img style="height: 30px" src="@/assets/truck.svg" @click="$events.fire('cambiar-estado', item)" />
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="ATENDIDOS" prop="atendido" width="90" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.atendido.length > 0">
                <el-badge :value="scope.row.atendido.length" class="item" type="primary">
                  <img style="height: 30px; margin-left: 20px" src="@/assets/truck.svg" @click="$events.fire('open-tabla-visor', scope.row.atendido, 'Atendidos')" />
                </el-badge>
              </div>
              <div v-else>
                <p><br /></p>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="FINALIZADOS" prop="finalizados" width="100" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.finalizado.length > 0">
                <el-badge :value="scope.row.finalizado.length" class="item" type="primary">
                  <img style="height: 30px; margin-left: 20px" src="@/assets/truck.svg" @click="$events.fire('open-tabla-visor', scope.row.finalizado, 'Finalizados')" />
                </el-badge>
              </div>
              <div v-else>
                <p><br /></p>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
      </el-col>
    </el-row>
    <tabla-turno-visor v-if="this.sistema.modalTabla.visible" :modal="this.sistema.modalTabla" />
    <form-cambio-estado v-if="this.sistema.modalEstado.visible" :modal="this.sistema.modalEstado" />
    <qr-modal v-if="this.sistema.modalQr.visible" :modal="this.sistema.modalQr" />
  </div>
</template>

<script>
import { API } from '@/plugins/api'
import { supabase } from '@/plugins/supabase'
import moment from 'moment'
import TablaTurnoVisor from './TablaTurnoVisor'
import FormCambioEstado from '@/components/turnos/Gestor/FormCambioEstado'
import QrModal from '@/components/turnos/Gestor/QrModal'

export default {
  name: 'VisorDiario',
  components: {
    TablaTurnoVisor,
    FormCambioEstado,
    QrModal,
  },
  async created() {
    //this.iniciarActualizacionAutomatica()
    this.getTurnosHoy()
    //const channel =
    await supabase
      .channel('turnos_chagnes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: process.env.VUE_APP_SUPABASE_TABLE,
        },
        () => {
          //console.log('cambio', payload)
          this.getTurnosHoy()
        }
      )
      .subscribe()
    //console.log(channel)
  },
  data() {
    return {
      sistema: {
        loading: true,
        styleBtn: {
          borderColor: '#e5590a',
          backgroundColor: '#e5590a',
          color: 'white',
        },
        modalTabla: {
          visible: false,
          row: null,
        },
        modalEstado: {
          visible: false,
          row: null,
        },
        modalQr: {
          visible: false,
          row: null,
        },
        first: true,
      },
      docks1: [],
      docks2: [],
      turnos: [],
      name_docks1: [],
      name_docks2: [],
      docks1_estados: [],
      docks2_estados: [],
    }
  },
  methods: {
    getTurnosHoy() {
      API.get('/turnero/turnos/hoy')
        .then((response) => {
          this.turnos = response.data.data.turnos
          this.docks1 = response.data.data.config.docks1
          this.docks2 = response.data.data.config.docks2
          this.name_docks1 = this.docks1.map(function (x) {
            return 'DOCK ' + x.numero
          })
          this.name_docks2 = this.docks2.map(function (x) {
            return 'DOCK ' + x.numero
          })
          this.docks1_estados = this.docks1.filter((e) => e.estado == 99).map((m) => 'DOCK ' + m.numero)
          this.docks2_estados = this.docks2.filter((e) => e.estado == 99).map((m) => 'DOCK ' + m.numero)
          // this.docks1_estados = this.docks1.filter(function(x) {
          //  x.estado ==99
          // });
          // this.docks2_estados = this.docks2.filter(function(x) {
          //  x.estado ==99
          // });
          this.sistema.loading = false
        })
        .catch((error) => {
          return this.$message({
            message: error.response.data.message,
            type: 'error',
          })
        })
    },
    classChecker({ column, columnIndex }) {
      let uno = 3 + this.docks1.length
      let dos = uno + this.docks2.length
      if ((this.docks1_estados.includes(column.label) && columnIndex > 2 && columnIndex < uno) || (this.docks2_estados.includes(column.label) && columnIndex >= uno && columnIndex < dos)) {
        return 'docks99'
      } else {
        if (this.name_docks1.includes(column.label) && columnIndex > 2 && columnIndex < uno) {
          return 'docks1'
        }
        if (this.name_docks2.includes(column.label) && columnIndex >= uno && columnIndex < dos) {
          return 'docks2'
        }
        return ''
      }
    },
    tableRowClassName({ row, rowIndex }) {
      let ahora = moment()
      let hora_inicial = moment(row.hora_inicial, 'HH:mm')
      let hora_final = moment(row.hora_final, 'HH:mm')
      let evaluar = moment(ahora).isBetween(hora_inicial, hora_final)
      //console.log('HI:' + hora_inicial + '-HF:' + hora_final + '-Now:' + ahora + '-estado:' + evaluar)
      if (evaluar) {
        if (rowIndex > 5 && this.sistema.first) {
          const tablaTurnos = this.$refs.tablaTurnos
          const filaDeseada = rowIndex - 2 // Índice de la fila deseada (puedes ajustarlo según tus necesidades)

          if (tablaTurnos && tablaTurnos.$el) {
            const contenedorScroll = tablaTurnos.$el.querySelector('.el-table__body-wrapper')
            const fila = tablaTurnos.$el.querySelector(`.el-table__body-wrapper table tbody tr:nth-child(${filaDeseada})`)

            if (contenedorScroll && fila) {
              const contenedorRect = contenedorScroll.getBoundingClientRect()
              const filaRect = fila.getBoundingClientRect()
              const scrollY = filaRect.top - contenedorRect.top + contenedorScroll.scrollTop

              contenedorScroll.scrollTo({
                top: scrollY,
                behavior: 'smooth',
              })
              this.sistema.first = false
            }
          }
        }
        return 'success-row'
      }
    },
    /*iniciarActualizacionAutomatica() {
      this.getTurnosHoy(); // Realiza la primera carga inmediatamente
      //console.log('bucle abierto')
      this.actualizacionAutomaticaInterval = setInterval(() => {
        //console.log('fetch')
        this.getTurnosHoy()
      }, 60000) // 60 segundos en milisegundos
    },    */
  },
  events: {
    'open-tabla-visor'(row, tipo) {
      this.sistema.modalTabla.row = row
      this.sistema.modalTabla.visible = true
      this.sistema.modalTabla.tipo = tipo
    },
    'cambiar-estado'(row) {
      this.sistema.modalEstado.row = row
      this.sistema.modalEstado.visible = true
    },
    'open-qr'(row) {
      this.sistema.modalQr.row = row
      this.sistema.modalQr.visible = true
    },
    'close-form'() {
      this.sistema.modalTabla.visible = false
      this.sistema.modalEstado.visible = false
      this.sistema.modalQr.visible = false
    },
    'close-qr'() {
      this.sistema.modalQr.visible = false
    },
    'refrescar-tabla'() {
      this.getTurnosHoy()
    },
  },
  async beforeDestroy() {
    //console.log('bucle cerrado')
    //clearInterval(this.actualizacionAutomaticaInterval);
    //const channel = await supabase.removeAllChannels()
    await supabase.removeAllChannels()
    //console.log(channel)
  },
}
</script>
<style scoped>
.image-row {
  white-space: nowrap;
}
.image-row img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.item {
  margin-top: 10px;
  margin-right: 40px;
}
</style>
