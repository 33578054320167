<template>
  <div>
    <el-row>
      <el-col :md="12" style="float: left" v-if="title_cantidad != ''">
        <strong>Cantidad de productos :</strong>
        <span v-loading="cargandoCarrito"> {{ title_cantidad }} </span>
      </el-col>
      <el-col :md="12" v-if="title_cantidad != ''">
        <el-button
          style="float: right; margin-right: 15px; margin-bottom: 10px"
          size="mini"
          plain
          type="danger"
          @click="eliminarTodo"
          :loading="cargandoCarrito"
          icon="el-icon-error"
          >ELIMINAR TODO
        </el-button>
      </el-col>
    </el-row>
    <el-table
      :data="tablaCarrito"
      style="width: 100%"
      v-loading="cargandoCarrito"
      :height="altTabla"
      :row-class-name="tableRowClassName">
      <el-table-column
        prop="cantidad"
        label="Cantidad agregada"
        width="170"
        sortable>
        <template slot-scope="scope">
          <div v-if="scope.row.lote == null">
            <el-input-number
              v-model="scope.row.cantidad"
              @change="cambiando(scope.row)"
              style="width: 75% !important"
              :min="0"
              :max="scope.row.disponible"
              size="mini"
              :disabled="scope.row.disponible <= 0">
            </el-input-number>
          </div>
          <div v-else-if="scope.row.lote != null">
            <el-input-number
              v-model="scope.row.cantidad"
              @change="cambiando(scope.row)"
              style="width: 75% !important"
              :min="0"
              :max="scope.row.disponible_lote"
              size="mini"
              :disabled="scope.row.disponible_lote <= 0">
            </el-input-number>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="descrip" label="Descripción" sortable>
      </el-table-column>
      <el-table-column
        prop="lote"
        width="150"
        label="Lote"
        sortable
        align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.lote != null">{{ scope.row.lote }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!no_genericos"
        prop="cod_generico"
        width="150"
        label="Genérico"
        align="center">
      </el-table-column>
      <el-table-column
        prop="articulo"
        width="100"
        label="Cod. Login"
        align="center">
      </el-table-column>
      <el-table-column
        prop="disponible"
        align="center"
        width="80"
        label="Disponible">
        <template slot-scope="scope">
          <span v-if="scope.row.lote != null">{{
            scope.row.disponible_lote
          }}</span>
          <span v-else>{{ scope.row.disponible }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100" align="center" label="Acción">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            :loadign="btn_eliminar"
            icon="el-icon-error"
            >Eliminar
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="width: 100 !important" v-if="btn_pedido">
      <el-tooltip
        class="item"
        effect="dark"
        content="Permite calcular la cantidad correcta por productos agregados al pedido"
        placement="right-start">
        <el-button
          type="warning"
          class="block-btn"
          icon="el-icon-circle-check"
          :disabled="tablaCarrito.length == 0"
          @click="enviarPedido('preenvio')"
          :loading="loading_proceso">
          VALIDAR PEDIDO
        </el-button>
      </el-tooltip>
    </div>
    <div style="width: 100 !important" v-else>
      <el-button
        type="primary"
        class="block-btn"
        icon="el-icon-sell"
        :disabled="tablaCarrito.length == 0"
        @click="enviarPedido('envio')"
        :loading="loading_proceso">
        ENVIAR PEDIDO
      </el-button>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import { HTTP } from '@/axios'
// import { HTTP_DESARROLLO } from '@/axios'

export default {
  data() {
    return {
      tablaCarrito: [],
      cantidad_total: 0,
      datos: JSON.parse(window.localStorage.getItem('datos')),
      cargandoCarrito: false,
      formBuscar: {
        empieza_con: '',
        contiene: '',
        codigo: '',
        sw_stock: false,
        cliente: JSON.parse(window.localStorage.getItem('datos'))['cliente'],
        session: JSON.parse(window.localStorage.getItem('datos')),
        tipo_busqueda: 'palabra',
        estado: 0,
        idu: JSON.parse(window.localStorage.getItem('datos'))['uid'],
      },
      loading_proceso: false,
      altTabla: '40vh',
      btn_eliminar: false,
      eliminar_tabla: 0,
      session: JSON.parse(window.localStorage.getItem('datos')),
      btn_pedido: true,
      cliente: JSON.parse(window.localStorage.getItem('datos'))['cliente'],
      title_cantidad: '',
      show_eliminar_todo: false,
      no_genericos: false,
    }
  },
  created() {
    this.cargarCarritoCompleto()
  },
  mounted() {
    /* NUEVO PERMISO NO GENERICOS. NO MOSTRAR GENERICOS PARA QUIENES TIENEN ESTE PERMISO */
    this.no_genericos = this.session.permisos.includes('NO_GENERICOS')
      ? true
      : false
    // ------------------------------------------------------------------------------------
    EventBus.$on('cargarCarrito', (f) => {
      this.cargandoCarrito = true
      f['idu'] = this.datos['uid']
      let control = ''
      // Voy agregar al carrito de acuerdo a ciertas condiciones
      // Si apreta el botón agregar, controlo si el elemento no existe en la tabla de carrito
      if (this.tablaCarrito.length > 0) {
        control = this.tablaCarrito.find(
          (element) =>
            element.articulo == f.codigo &&
            element.articulo_cli == f.codprov &&
            element.descrip == f.descrip &&
            element.lote == null
        )

        if (typeof control === 'undefined') {
          if (f.estado_2 == 'actualizar' || f.estado_2 == 'actualizando') {
            control = this.tablaCarrito.find(
              (element) =>
                element.articulo == f.codigo &&
                element.articulo_cli == f.codprov &&
                element.lote == null
            )
          }
          // let guardar     = false
          this.btn_pedido = true
          switch (f.estado_2) {
            case 'btn_agregar':
              f.articulo = f.codigo
              f.articulo_cli = f.codprov
              /* Guardar en la BD */
              /* CONTROLO QUE EL ELEMENTO NO EXISTE EN EL CARRITO */
              this.guardar(f, 'guardar')
              // if(guardar != false)
              // {
              setTimeout(() => {
                this.cantidad_total = this.tablaCarrito
                  .map((element) => element.cantidad)
                  .reduce((a, b) => a + b, 0)
                this.altTabla = this.tablaCarrito.length > 10 ? '70vh' : ''
              }, 500)
              // }
              break
            case 'actualizar':
              if (typeof control !== 'undefined') {
                f.articulo = f.codigo
                f.articulo_cli = f.codprov
                /* Guardar en la BD */
                this.guardar(f, 'actualizar1actualizar')
                // if(guardar!=false)
                // {
                control.cantidad = f.cantidad
                this.cantidad_total = this.tablaCarrito
                  .map((element) => element.cantidad)
                  .reduce((a, b) => a + b, 0)
                this.altTabla = this.tablaCarrito.length > 10 ? '70vh' : ''
                // }
              }

              break
            case 'actualizando':
              if (typeof control !== 'undefined') {
                f.articulo = f.codigo
                f.articulo_cli = f.codprov
                /* Guardar en la BD */
                this.guardar(f, 'actualizar')
                // if(guardar!=false)
                // {
                setTimeout(() => {
                  control.cantidad = f.cantidad
                  this.cantidad_total = this.tablaCarrito
                    .map((element) => element.cantidad)
                    .reduce((a, b) => a + b, 0)
                  this.altTabla = this.tablaCarrito.length > 10 ? '70vh' : ''
                }, 500)

                // }
              }
              break
          }
        } else {
          this.btn_pedido = true
          if (f.estado_2 === 'actualizar') {
            /* actualizar en la BD */
            this.guardar(f, 'actualizar')
            // if(actualizar!=false)
            // {
            setTimeout(() => {
              //    control.cantidad = f.cantidad
              this.cantidad_total = this.tablaCarrito
                .map((element) => element.cantidad)
                .reduce((a, b) => a + b, 0)
              this.altTabla = this.tablaCarrito.length > 10 ? '70vh' : ''
            }, 500)

            // }
          } else {
            if (f.estado_2 === 'actualizando') {
              /* actualizar en la BD */
              this.guardar(f, 'actualizar')
              // if(actualizar!=false)
              // {
              setTimeout(() => {
                // control.cantidad = f.cantidad
                this.cantidad_total = this.tablaCarrito
                  .map((element) => element.cantidad)
                  .reduce((a, b) => a + b, 0)
                this.altTabla = this.tablaCarrito.length > 10 ? '70vh' : ''
              }, 500)

              // }
            }
          }
        }
      } else {
        this.btn_pedido = true
        if (f.estado_2 === 'btn_agregar') {
          this.guardar(f, 'guardar')

          // if(guardar != false)
          // {
          f.articulo = f.codigo
          f.articulo_cli = f.codprov
          this.cantidad_total = this.tablaCarrito
            .map((element) => element.cantidad)
            .reduce((a, b) => a + b, 0)
          this.altTabla = this.tablaCarrito.length > 8 ? '70vh' : ''
          // }
        }
      }
      EventBus.$emit('cantidad_total_carrito', this.cantidad_total)
      EventBus.$emit('loading_elemento', false)
    })

    /*----------------------------------- LOTE --------------------------------------------------*/
    /* EventBus para esccuchar agregar Productos por LOTE */
    EventBus.$on('cargarLote', (f) => {
      f['idu'] = this.datos['uid']
      let control = ''
      // Voy agregar al carrito de acuerdo a ciertas condiciones
      // Si apreta el botón agregar, controlo si el elemento no existe en la tabla de carrito
      if (this.tablaCarrito.length > 0) {
        control = this.tablaCarrito.find(
          (element) =>
            element.articulo == f.codigo &&
            element.articulo_cli == f.codprov &&
            element.descrip == f.descrip &&
            element.lote == f.lote
        )
        if (typeof control === 'undefined') {
          if (f.estado_2 == 'actualizar' || f.estado_2 == 'actualizando') {
            control = this.tablaCarrito.find(
              (element) =>
                element.articulo == f.codigo &&
                element.articulo_cli == f.codprov &&
                element.descrip == f.descrip &&
                element.lote == f.lote
            )
          }
          let guardar = false
          this.btn_pedido = true
          switch (f.estado_2) {
            case 'btn_agregar':
              f.articulo = f.codigo
              f.articulo_cli = f.codprov
              /* Guardar en la BD */
              guardar = this.guardar(f, 'guardar')
              if (guardar != false) {
                // this.cargarCarritoCompleto()
                this.cantidad_total = this.tablaCarrito
                  .map((element) => element.cantidad)
                  .reduce((a, b) => a + b, 0)
                this.altTabla = this.tablaCarrito.length > 10 ? '70vh' : ''
              }
              EventBus.$emit('showEstado', guardar)

              break
            case 'actualizar':
              if (typeof control !== 'undefined') {
                f.articulo = f.codigo
                f.articulo_cli = f.codprov
                /* Guardar en la BD */
                guardar = this.guardar(f, 'actualizar1carritoactualizar')
                if (guardar != false) {
                  // control.cantidad = f.cantidad
                  this.cantidad_total = this.tablaCarrito
                    .map((element) => element.cantidad)
                    .reduce((a, b) => a + b, 0)
                  this.altTabla = this.tablaCarrito.length > 10 ? '70vh' : ''
                }
                EventBus.$emit('showEstado', guardar)
              }

              break
            case 'actualizando':
              f.estado_2 = f.estado_2 == 'btn_agregar' ? 'actualizar' : ''
              if (typeof control !== 'undefined') {
                f.articulo = f.codigo
                f.articulo_cli = f.codprov
                /* Guardar en la BD */
                guardar = this.guardar(f, 'actualizar')
                if (guardar != false) {
                  // control.cantidad = f.cantidad
                  this.cantidad_total = this.tablaCarrito
                    .map((element) => element.cantidad)
                    .reduce((a, b) => a + b, 0)
                  this.altTabla = this.tablaCarrito.length > 10 ? '70vh' : ''
                }
                EventBus.$emit('showEstado', guardar)
              }
              break
          }
        } else {
          this.btn_pedido = true
          /* este es el caso de que ya fue agregado al carro y no se borro sólo se actualiza */
          f.estado_2 = f.estado_2 == 'btn_agregar' ? 'actualizar' : ''
          if (f.estado_2 === 'actualizar') {
            /* actualizar en la BD */
            let actualizar = this.guardar(f, 'actualizar')
            if (actualizar != false) {
              // control.cantidad = f.cantidad
              this.cantidad_total = this.tablaCarrito
                .map((element) => element.cantidad)
                .reduce((a, b) => a + b, 0)
              this.altTabla = this.tablaCarrito.length > 10 ? '70vh' : ''
            }
            EventBus.$emit('showEstado', actualizar)
          } else {
            if (f.estado_2 === 'actualizando') {
              /* actualizar en la BD */
              let actualizar = this.guardar(f, 'actualizar')
              if (actualizar != false) {
                // control.cantidad = f.cantidad
                this.cantidad_total = this.tablaCarrito
                  .map((element) => element.cantidad)
                  .reduce((a, b) => a + b, 0)
                this.altTabla = this.tablaCarrito.length > 10 ? '70vh' : ''
              }
              EventBus.$emit('showEstado', actualizar)
            }
          }
        }
      } else {
        if (f.estado_2 === 'btn_agregar') {
          let guardar = this.guardar(f, 'guardar')
          this.btn_pedido = true
          if (guardar != false) {
            f.articulo = f.codigo
            f.articulo_cli = f.codprov
            this.cantidad_total = this.tablaCarrito
              .map((element) => element.cantidad)
              .reduce((a, b) => a + b, 0)
            this.altTabla = this.tablaCarrito.length > 8 ? '70vh' : ''
          }
        }
      }
      EventBus.$emit('cantidad_total_carrito', this.cantidad_total)
    })
    /* --- FIN LOTE ---- */
  },
  beforeDestroy() {
    EventBus.$off('cargarCarrito', false)
    EventBus.$off('cargarLote', false)
  },
  methods: {
    cambiando(row) {
      this.btn_pedido = true
      let cantidad_total_restada = this.tablaCarrito
        .map((element) => element.cantidad)
        .reduce((a, b) => a + b, 0)
      this.guardar(row, 'actualizar1')
      /* funcion para tomar correctamente los valores de cantlote */
      let carro_stock = this.tablaCarrito.filter(
        (element) => element.lote == null
      )
      let carro_stock_lote = this.tablaCarrito.filter(
        (element) => element.lote != null
      )
      EventBus.$emit('cantidad_total_carrito', cantidad_total_restada)
      EventBus.$emit('carrito_previo', carro_stock) // ENVIO LOS CANTIDAD POR ARTÍCULO
      EventBus.$emit('carrito_previo_lote', carro_stock_lote) // ENVIO LA CANTIDAD POR LOTE

      let result = []
      carro_stock_lote.reduce(function (res, value) {
        if (!res[value.articulo]) {
          res[value.articulo] = {
            articulo: value.articulo,
            cantidad: 0,
            descrip: value.descrip,
          }
          result.push(res[value.articulo])
        }

        res[value.articulo].cantidad += value.cantidad
        return res
      }, {})
      EventBus.$emit('solo_stock', result) // ENVIO LOS PROD QUE TIENEN SELECCIONADO LOTES
    },
    enviarPedido(tipo_btn) {
      this.loading_proceso = true
      if (tipo_btn === 'preenvio') {
        /* CONTROLO LA CANTIDAD DE ELEMENTOS SI COMPRO ELEMENTOS POR LOTES */
        this.tablaCarrito.forEach((element) => {
          /* PRIMERO ELIMINO LOS ELEMENTOS QUE TENGAN CANTIDAD = 0 */
          element.cambiada = false
          if (element.lote == null) {
            let arrya = this.tablaCarrito.filter(
              (elemento) =>
                elemento.lote != null &&
                elemento.articulo == element.articulo &&
                element.cantidad > 0
            )
            let cantidad_total = 0
            if (arrya.length) {
              /* UNA FORMA DE CONTROLAR LA CANTIDAD DE ELEMENTOS SI PIDE EXACTAMENTE DE DISTINTOS LOTES */
              arrya.forEach((extra) => {
                cantidad_total += extra.cantidad
              })
              let disponible_total = element.disponible - cantidad_total
              /* Si la cantidad de producto por lotes es igual a la cantidad de productos
                            solicitado sin especificar lote o la cantidad por lotes es mejor a la cantidad solicitada
                            sin especificar lote se hace la diferencia en sin lote  */
              if (
                cantidad_total < element.cantidad &&
                disponible_total < element.cantidad
              ) {
                element.cantidad = disponible_total
                element.cambiada = true
              } else if (
                cantidad_total > element.cantidad &&
                element.disponible == cantidad_total
              ) {
                /* Si la cantidad por lote es mayor a la cantidad del producto sin lote y la cantidad por lote
                                es igual a la cant. prod. disponible */
                element.cantidad = 0
              } else if (
                cantidad_total > element.cantidad &&
                element.disponible > cantidad_total
              ) {
                /* Si la cantidad total por lote es mayor a ña cant. de prod sin lote y quedan elementos disponibles
                                calculo cuanto queda */

                /* Si quedan elementos  pero es menor a la cantidad solicitada */
                if (
                  disponible_total > 0 &&
                  disponible_total < element.cantidad
                ) {
                  element.cantidad = disponible_total
                  element.cambiada = true
                } else if (disponible_total < 0) {
                  element.cantidad = 0
                }
              } else if (element.cantidad == cantidad_total) {
                if (
                  disponible_total > 0 &&
                  disponible_total < element.cantidad
                ) {
                  element.cantidad = disponible_total
                } else if (disponible_total == 0) {
                  element.cantidad = 0
                }
              }
            }
          }
        })
        /* ----- SI HAY ELEMENTOS CON CANTIDAD = 0. SE LOS ELIMINA DE LA TABLA Y BD ----- */
        let elementos_eliminar = this.tablaCarrito.filter(
          (elementos) => elementos.cantidad == 0
        )
        if (elementos_eliminar.length) {
          this.eliminarElementosTabla(this.tablaCarrito, elementos_eliminar)
        }
        /* ----- ENVIO NUEVAMENTE LOS CAMBIOS REALIZADOS CON LAS ELIMINACIONS ----- */
        setTimeout(() => {
          let cantidad_total_restada = this.tablaCarrito
            .map((element) => element.cantidad)
            .reduce((a, b) => a + b, 0)
          EventBus.$emit('cantidad_total_carrito', cantidad_total_restada)
          EventBus.$emit('carrito_previo', this.tablaCarrito)
          this.loading_proceso = false
          this.cargandoCarrito = false
          this.btn_pedido = false
          this.title_cantidad = this.tablaCarrito.length
        }, 1000)
      } else {
        this.$swal
          .fire({
            title: 'Está seguro que desea enviar el pedido?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#409EFF',
            cancelButtonColor: '#f56c6c',
            confirmButtonText: 'Sí, enviar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.value) {
              let hoy = new Date()
              HTTP.post('/vlogisticaLogin/enviarPedido_22_08_23', {
                // HTTP_DESARROLLO.post(
                //   '/vlogisticaLogin/enviarPedido_22_08_23Desarrollo',
                //   {
                idu: this.session.uid,
                cliente: this.session.cliente,
                pedido: this.tablaCarrito,
                fecha_hoy: hoy,
                session: this.session,
              })
                .then((res) => {
                  this.loading_proceso = false
                  if (
                    res.data != null &&
                    res.data != -99 &&
                    res.data != -1 &&
                    res.data != 0
                  ) {
                    this.$swal.fire(
                      'Algunos productos no pudieron procesarse correctamente',
                      '',
                      'warning'
                    )
                  } else if (res.data === -99) {
                    this.$swal.fire(
                      'Algunos de los datos no se cargaron correctamente',
                      '',
                      'warning'
                    )
                  } else if (res.data === -1) {
                    this.$swal.fire(
                      'Hubo un error en el proceso',
                      '',
                      'warning'
                    )
                  } else {
                    this.$swal.fire(
                      'Pedido enviado correctamente',
                      '',
                      'success'
                    )
                    this.title_cantidad = ''
                    this.show_eliminar_todo = false
                    setTimeout(() => {
                      EventBus.$emit('cantidad_total_carrito', 0)
                    }, 1000)
                  }
                  /* CARGO TODO */
                  setTimeout(() => {
                    this.cargarCarritoCompleto()
                    EventBus.$emit('actualizar_pedidos', true)
                    this.altTabla = ''
                  }, 1500)
                })
                .catch((error) => {
                  this.loading_proceso = false
                  console.log(error)
                })
            } else {
              this.loading_proceso = false
            }
          })
      }
    },
    guardar(tupla, accion) {
      tupla['idu'] = this.datos['uid']
      this.eliminar_tabla = 0
      HTTP.post('/vlogisticaLogin/guardarPedidoLogin', {
        // HTTP_DESARROLLO.post('/vlogisticaLogin/guardarPedidoLoginDesarrollo', {
        session: this.session,
        tipo_accion: accion,
        datos: tupla,
      })
        .then((res) => {
          console.log(res.data)
          switch (accion) {
            case 'guardar':
              if (!res.data) {
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'No se puedo guardar el pedido.',
                })
              }
              this.cargarCarritoCompleto()
              //comentado porque hace funcionar mal el actualizar
              this.cargandoCarrito = false
              break
            case 'actualizar':
              if (res.data == 0) {
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'No se puedo actualizar LA CANTIDAD.',
                })
              }
              this.cargarCarritoCompleto()
              this.cargandoCarrito = false
              break
            /* ACTUALIZAR1 FUNCIONA CUANDO CAMBIO LOTE DEL DESDE CARRITO */
            case 'actualizar1':
              if (res.data == 0) {
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'No se puedo actualizar la POP.',
                })
              }
              // setTimeout(() => {
              //     this.cargarCarritoCompleto()
              // }, 2000);
              this.cargandoCarrito = false
              break
            case 'eliminar':
              if (!res.data) {
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'No se puedo eliminar.Intente nuevamente.',
                })
                this.eliminar_tabla = 0
              } else {
                this.$swal.fire(
                  'El producto se elimino correctamente',
                  '',
                  'success'
                )
                this.eliminar_tabla = 1
              }
              // setTimeout(() => {
              //     this.cargarCarritoCompleto()
              // }, 2000);
              this.cargandoCarrito = false
              break
          }
        })
        .catch((error) => {
          this.cargandoCarrito = false
          console.log(error)
          this.$alert('Error del servidor ')
        })
    },
    eliminarElementosTabla(tablaC, elementos_eliminar) {
      if (elementos_eliminar.length) {
        let elementos_id = elementos_eliminar.map((elem) => elem.id)

        HTTP.post('/vlogisticaLogin/eliminarProdCantidad', {
          // HTTP.post('/vlogisticaLogin/eliminarProdCantidadDesarrollo', {
          cliente: this.cliente,
          idu: this.session.uid,
          productos: elementos_id,
          session: this.session,
        })
          .then((res) => {
            if (res.data) {
              this.cargandoCarrito = false
              this.tablaCarrito = tablaC.filter((elem) => elem.cantidad > 0)
              EventBus.$emit('carrito_previo', this.tablaCarrito) // ENVIO LOS CANTIDAD POR ARTÍCULO
            } else {
              alert('error en el calculo')
            }
          })
          .catch((error) => {
            this.cargandoCarrito = false
            console.log(error)
          })
      }
    },
    eliminarProd(tupla) {
      this.cargandoCarrito = true
      HTTP.post('/vlogisticaLogin/guardarPedidoLogin', {
        // HTTP.post('/vlogisticaLogin/guardarPedidoLoginDesarrollo', {
        session: this.session,
        tipo_accion: 'eliminar',
        datos: tupla,
      })
        .then((res) => {
          this.cargandoCarrito = false
          if (res.data) {
            this.tablaCarrito = this.tablaCarrito.filter(
              (item) => item.cantidad > 0
            )
            this.title_cantidad = this.tablaCarrito.length
          }
        })
        .catch((error) => {
          this.cargandoCarrito = false
          console.log(error)
          this.$alert('Error del servidor ')
        })
    },
    cargarPrimerConsulta() {
      this.tablaCarrito = []
      this.cargandoCarrito = true
      HTTP.post('/vlogisticaLogin/getCarritoUser', {
        // HTTP_DESARROLLO.post('/vlogisticaLogin/getCarritoUserDesarrollo', {
        idu: this.datos['uid'],
        estado: 0,
        session: this.session,
        cliente: this.cliente,
      })
        .then((res) => {
          this.cargandoCarrito = false
          let tabla = res.data.tabla //tabla de la pagina principal
          let carrito = res.data.carrito //carrito de compras

          if (carrito.length) {
            this.title_cantidad = carrito.length
            this.show_eliminar_todo = true
            if (tabla.length) {
              carrito.forEach((elemento) => {
                let control = tabla.find(
                  (element) =>
                    element.codigo == elemento.articulo &&
                    element.codprov == elemento.articulo_cli &&
                    element.descrip == elemento.descrip
                )

                if (typeof control !== 'undefined') {
                  elemento.disponible = control.disponible
                } else {
                  /* CONSULTAR A SEBAS */
                  control = tabla.find(
                    (element) =>
                      element.codigo == elemento.articulo &&
                      element.codprov == elemento.articulo_cli
                  )
                  if (typeof control !== 'undefined') {
                    elemento.disponible = control.disponible
                  }
                }
              })
            }

            /* Guardo los resultados */
            this.tablaCarrito = carrito
            this.cantidad_total = this.tablaCarrito
              .map((element) => element.cantidad)
              .reduce((a, b) => a + b, 0)
            /* DIVIDO LOS ARTICULOS POR LOTE O NO */
            let carro_stock = this.tablaCarrito.filter(
              (element) => element.lote == null
            )
            // let carro_stock_lote    = this.tablaCarrito.filter(element=> element.lote != null)
            EventBus.$emit('carrito_previo', carro_stock) // ENVIO LOS CANTIDAD POR ARTÍCULO
            // EventBus.$emit("carrito_previo_lote",carro_stock_lote);   // ENVIO LA CANTIDAD POR LOTE
            EventBus.$emit('cantidad_total_carrito', this.cantidad_total) // TOMO LA CANTIDAD TOTAL

            this.altTabla = this.tablaCarrito.length > 8 ? '70vh' : ''
            EventBus.$emit('consulta_primera_stock', tabla)
          } else {
            this.cantidad_total = 0
            this.tablaCarrito = []
            EventBus.$emit('carrito_previo', [])
            EventBus.$emit('consulta_primera_stock', tabla)
            EventBus.$emit('cantidad_total_carrito', 0)
          }

          /* TABLA PRINCIPAL DE STOCK SE REFRESCA */
        })
        .catch((error) => {
          this.cargandoCarrito = false
          console.log(error)
          this.$alert('Error del servidor ' + error)
        })
    },
    /* FUNCION QUE TRAE LOS DATOS QUE SE VAN CARGANDO EN EL CARRO */
    /* Uso está función para controlar que lo que se carga todavía exita como producto y este disponible */
    cargarCarritoCompleto() {
      this.cargandoCarrito = true
      HTTP.post('/vlogisticaLogin/getCarritoUser', {
        // HTTP_DESARROLLO.post('/vlogisticaLogin/getCarritoUserDesarrollo', {
        idu: this.datos['uid'],
        estado: 0,
        session: this.session,
        cliente: this.cliente,
      })
        .then((res) => {
          this.cargandoCarrito = false
          let tabla = res.data.tabla
          let carrito = res.data.carrito

          if (carrito.length) {
            this.title_cantidad = carrito.length
            this.show_eliminar_todo = true
            if (tabla.length) {
              carrito.forEach((elemento) => {
                let control = tabla.find(
                  (element) =>
                    element.codigo == elemento.articulo &&
                    element.codprov == elemento.articulo_cli &&
                    element.descrip == elemento.descrip
                )

                if (typeof control !== 'undefined') {
                  elemento.disponible = control.disponible
                } else {
                  /* CONSULTAR A SEBAS */
                  control = tabla.find(
                    (element) =>
                      element.codigo == elemento.articulo &&
                      element.codprov == elemento.articulo_cli
                  )
                  if (typeof control !== 'undefined') {
                    elemento.disponible = control.disponible
                  }
                }
              })
            }

            /* Guardo los resultados */
            this.tablaCarrito = carrito
            this.tablaCarrito = this.tablaCarrito.map((row) => {
              return {
                ...row,
                cambiada: false,
              }
            })
            this.cantidad_total = this.tablaCarrito
              .map((element) => element.cantidad)
              .reduce((a, b) => a + b, 0)
            /* DIVIDO LOS ARTICULOS POR LOTE O NO */
            let carro_stock = this.tablaCarrito.filter(
              (element) => element.lote == null
            )
            // let carro_stock_lote    = this.tablaCarrito.filter(element=> element.lote != null)
            EventBus.$emit('carrito_previo', carro_stock) // ENVIO LOS CANTIDAD POR ARTÍCULO
            // EventBus.$emit("carrito_previo_lote",carro_stock_lote);   // ENVIO LA CANTIDAD POR LOTE
            EventBus.$emit('cantidad_total_carrito', this.cantidad_total) // TOMO LA CANTIDAD TOTAL
            EventBus.$emit('consulta_primera_stock', res.data.tabla)

            this.altTabla = this.tablaCarrito.length > 8 ? '70vh' : ''
          } else {
            this.cantidad_total = 0
            this.tablaCarrito = []
            EventBus.$emit('carrito_previo', [])
            EventBus.$emit('consulta_primera_stock', res.data.tabla)

            EventBus.$emit('cantidad_total_carrito', 0)
          }
        })
        .catch((error) => {
          this.cargandoCarrito = false
          console.log(error)
          this.$alert('Error del servidor ' + error)
        })
    },
    handleDelete(index, row) {
      this.guardar(row, 'eliminar') //primero llamo a la accion general con leyenda eliminar
      this.cargandoCarrito = true
      this.btn_pedido = true
      setTimeout(() => {
        if (this.eliminar_tabla) {
          /* quito el elemento de la tabla sin hacer consulta */
          this.tablaCarrito.splice(index, 1)
          this.cantidad_total -= row.cantidad
          this.cantidad_total = this.tablaCarrito
            .map((element) => element.cantidad)
            .reduce((a, b) => a + b, 0)
          /* calculo nuevamente la cantidad de elementos y emito el comunicado */
          EventBus.$emit('cantidad_total_carrito', this.cantidad_total)
          this.title_cantidad = this.tablaCarrito.length
          /* si el elemento es de un lote especifico comunico al modal lotes sino a la tabla principal */
          if (row.lote == null) {
            let carro_stock = this.tablaCarrito.filter(
              (element) => element.lote == null
            )
            EventBus.$emit('cargarCarrito2_art', {
              elemento: row,
              carrito_nuevo: carro_stock,
            })
          } else {
            let carro_stock = this.tablaCarrito.filter(
              (element) => element.lote != null
            )
            EventBus.$emit('cargarCarrito2_art', {
              elemento: row,
              carrito_nuevo: carro_stock,
            })
          }
        }
      }, 1000)
    },
    eliminarTodo() {
      this.$swal
        .fire({
          title: 'Está seguro que desea eliminar todo el pedido?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#409EFF',
          cancelButtonColor: '#f56c6c',
          confirmButtonText: 'Sí, eliminar',
          cancelButtonText: 'Cancelar',
        })
        .then((result) => {
          if (result.value) {
            this.cargandoCarrito = true
            HTTP.post('/vlogisticaLogin/eliminarPedidoCompleto', {
              // HTTP.post('/vlogisticaLogin/eliminarPedidoCompletoDesarrollo', {
              cliente: this.cliente,
              idu: this.session.uid,
              pedido: this.tablaCarrito[0].pedido,
              session: this.session,
            })
              .then((res) => {
                this.cargandoCarrito = false
                // console.log(res.data)
                if (res.data) {
                  this.tablaCarrito = []
                  this.title_cantidad = ''
                  this.altTabla = '40vh'
                  setTimeout(() => {
                    EventBus.$emit('cantidad_total_carrito', 0)
                    EventBus.$emit('cantidad_total_stock', 0)
                    this.$swal.fire(
                      'Se eliminaron todos los productos correctamente.',
                      '',
                      'success'
                    )
                    EventBus.$emit('recargar_tabla', true)
                  }, 500)
                } else {
                  this.$swal.fire(
                    'Ocurrió un error, intente nuevamente',
                    '',
                    'warning'
                  )
                }
              })
              .catch((error) => {
                this.cargandoCarrito = false
                console.log(error)
              })
          } else {
            this.cargandoCarrito = false
          }
        })
    },
    tableRowClassName({ row }) {
      if (row.cambiada) {
        return 'cambiando-row'
      }
      return ''
    },
  },
}
</script>
<style scoped>
.block-btn {
  display: block;
  width: 100%;
  border: none;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}
</style>
