export default {
  namespaced: true,
  state: {
    cross: {
      guias: [],
      convenios: [],
      convenios_full: [],
      bultos: [],
      porte: [],
    },
  },
  mutations: {
    SET_CROSS_GUIAS(state, guias) {
      state.cross.guias = guias
    },
    SET_CROSS_CONFIG(state, { convenios, convenios_full, bultos, porte }) {
      state.cross.convenios = convenios
      state.cross.convenios_full = convenios_full
      state.cross.bultos = bultos
      state.cross.porte = porte
    },
    CLEAR_CROSS(state) {
      state.cross = {
        guias: [],
        convenios: [],
        convenios_full: [],
        bultos: [],
        porte: [],
      }
    },
  },
  actions: {
    setCrossFetch({ commit }, guias) {
      commit('SET_CROSS_GUIAS', guias)
    },
    setCrossConfig({ commit }, config) {
      commit('SET_CROSS_CONFIG', config)
    },
  },
  getters: {
    getGuiasCross: (state) => state.cross.guias,
    getGuiasConfig: (state) => state.cross,
  },
}
