<template>
  <div>
    <div class="vguias_buscar">
      <!-- Botones de sincronización  -->
      <el-row style="border-bottom: 1px solid #ddd; padding-bottom: 8px" :gutter="10">
        <el-col :md="5" :sm="24" :xs="24">
          <h2 style="margin: -3px">Presentaciones</h2>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-form :labe-position="'top'" size="small" style="margin: 0px 5px">
          <el-col :md="3" :sm="24" :xs="24">
            <el-form-item label="Presentación">
              <el-input placeholder="Nro. Presentación" v-model="presentacion.numpres" size="mini" :disabled="deshabilitarCampos"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="24" :xs="24">
            <el-form-item label="Sucursal origen">
              <el-select size="mini" v-model="presentacion.sucursal" filterable placeholder="Buscar por Convenio" style="width: 100%" clearable :disabled="deshabilitarCampos">
                <el-option value="" label="TODAS" key=""> </el-option>
                <el-option v-for="suc in sucursalesOrig" :key="suc.codigo" :label="suc.codigo + ' - ' + suc.nombre" :value="suc.codigo"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="6" v-if="showConvenio" :xs="24">
            <el-form-item label="Convenios">
              <el-select
                style="width: 100%"
                v-model="presentacion.ol_convenio"
                placeholder="Seleccione Convenios"
                size="mini"
                filterable
                clearable
                :disabled="deshabilitarCampos"
                :loading="loadRecursos">
                <el-option value="" label="TODOS" key=""> </el-option>
                <el-option v-for="conv in convenios" :key="conv.olconv" :label="conv.nombre" :value="conv.olconv"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8" :xs="24">
            <el-form-item label="Fecha">
              <el-date-picker
                style="width: 100%"
                v-model="presentacion.fecha"
                type="daterange"
                start-placeholder="Desde"
                end-placeholder="Hasta"
                size="mini"
                format="dd/MM/yyyy"
                :picker-options="pickerOptions"
                :disabled="deshabilitarCampos">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="6" :xs="24">
            <el-button-group style="margin-top: 33px">
              <el-button type="default" size="mini" @click="buscarPresentacion()" :style="styleBtnBuscar" :loading="loading_btn" :disabled="deshabilitarCampos"> Buscar </el-button>
              <el-button type="default" size="mini" :disabled="deshabilitarCampos" @click="limpiarCampos"> Limpiar </el-button>
            </el-button-group>
          </el-col>
        </el-form>
      </el-row>
    </div>
  </div>
</template>
<script>
// import { HTTP } from '@/axios'
import { EventBus } from '@/event-bus'
import { API } from '@/plugins/api'

const moment = require('moment')
export default {
  data() {
    return {
      presentacion: {
        nropres: null,
        fecha: [],
        sucursal: null,
        ol_convenio: null,
      },
      styleBtnBuscar: { borderColor: '#02172e00', color: 'white' }, // por defecto
      pickerOptions: {
        shortcuts: [
          {
            text: 'Últ. Semana',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Últ. Mes',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '3 Últ. meses',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      sucursalesOrig: [],
      convenios: [],
      msg: '',
      empresa: window.localStorage.getItem('empresa'),
      deshabilitarCampos: false,
      loading_btn: false,
      loadRecursos: false,
      datos: JSON.parse(window.localStorage.getItem('datos')),
      showConvenio: false,
    }
  },
  mounted() {
    this.showConvenio = this.datos.permisos.includes('OL_LISTACONV') || this.datos.permisos.includes('OL_MULTI') || this.datos.permisos.includes('ADMIN') ? true : false
    this.coloresFront()
    this.getRecursos()
  },
  beforeDestroy() {
    EventBus.$off('newPresentaciones', false)
  },
  methods: {
    coloresFront() {
      if (this.empresa == 'lo') {
        this.styleBtnBuscar['backgroundColor'] = '#e5590a !important'
      } else if (this.empresa == 'u') {
        this.styleBtnBuscar['backgroundColor'] = '#0b406a !important'
      }
    },
    getRecursos() {
      this.loadRecursos = true
      API.post('/presentaciones/getRecursos')
        .then((res) => {
          this.convenios = res.data.data.convenios
          this.sucursalesOrig = res.data.data.sucursales
          this.loadRecursos = false
        })
        .catch((error) => {
          this.$alert('Error del servidor ' + error)
          this.loadRecursos = false
        })
    },
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    armarObjeto() {
      const filtros = {}
      if (this.presentacion.nropres != null && this.presentacion.nropres != '') {
        filtros.nropres = this.presentacion.nropres
      } else {
        if (Array.isArray(this.presentacion.fecha) && this.presentacion.fecha.length > 0) {
          filtros.fecha = this.presentacion.fecha
        } else {
          filtros.fecha = [new Date(), new Date()]
          this.presentacion.fecha = filtros.fecha
        }

        if (this.presentacion.ol_convenio != null && this.presentacion.ol_convenio != '') {
          filtros.ol_convenio = this.presentacion.ol_convenio
        }

        if (this.presentacion.sucursal != null && this.presentacion.sucursal != '') {
          filtros.sucursal = this.presentacion.sucursal
        }
      }
      return filtros
    },
    buscarPresentacion() {
      this.showMsg = false
      this.msg = 'Presentaciones correspondientes al: ' + this.formatFecha(this.presentacion.fecha[0]) - this.formatFecha(this.presentacion.fecha[1])

      this.deshabilitarCampos = true
      this.loading_btn = true
      EventBus.$emit('cargando', true)
      let parametros = this.armarObjeto()
      // HTTP.post('/guiasNew/getPresentaciones', this.presentacion)
      API.post('/presentaciones/getPresentaciones', parametros)
        .then((response) => {
          EventBus.$emit('cargarPresentaciones', {
            tablaP: response.data.data,
            fechaIni: this.formatFecha(this.presentacion.fecha[0]),
            fechaFin: this.formatFecha(this.presentacion.fecha[1]),
            fechasShow: [response.data.fechaIni, response.data.fechaFin],
            parametros: parametros,
            convenios: this.convenios,
          })
          this.deshabilitarCampos = false
          this.loading_btn = false
        })
        .catch((error) => {
          this.deshabilitarCampos = false
          this.loading_btn = false
          this.$alert('Error del servidor ' + error)
        })
      // HTTP.post('/guiasNew/getPresentaciones26_03_2024', this.presentacion)
      //   .then((res) => {
      //     // console.log(res.data)
      //     if (res.data[1] == 0) {
      //       EventBus.$emit('cargarPresentaciones', {
      //         tablaP: 0,
      //         fechaIni: this.formatFecha(this.presentacion.fecha[0]),
      //         fechaFin: this.formatFecha(this.presentacion.fecha[1]),
      //       })
      //     } else {
      //       EventBus.$emit('cargarPresentaciones', {
      //         tablaP: res.data,
      //         pagina: this.presentacion.pagina,
      //         fechaIni: this.formatFecha(this.presentacion.fecha[0]),
      //         fechaFin: this.formatFecha(this.presentacion.fecha[1]),
      //       })
      //     }
      //     this.deshabilitarCampos = false
      //     this.loading_btn = false
      //   })
      //   .catch((error) => {
      //     this.deshabilitarCampos = false
      //     this.$alert('Error del servidor ' + error)
      //   })
    },
    limpiarCampos() {
      this.presentacion.nropres = null
      this.presentacion.fecha = []
      this.presentacion.sucursal = null
      this.presentacion.ol_convenio = null
      this.showMsg = false
      this.msg = ''
      EventBus.$emit('cargarPresentaciones', {
        tablaP: [],
      })
    },
  },
}
</script>
<style>
@media (max-width: 768px) {
  .el-range-editor--mini.el-input__inner {
    height: 28px;
    width: 100% !important;
  }
}
</style>
