export const css = {
  table: {
    tableClass: 'el-table el-table--fit el-table--striped el-table--mini el-table--border el-table--enable-row-hover el-table--enable-row-transition tabla-comprobante',
    //tableClass: 'table table-striped table-hover table-sm  table-border',
    loadingClass: 'loading',
    ascendingIcon: 'fas fa-chevron-up',
    descendingIcon: 'fas fa-chevron-down',
    detailRowClass: 'vuetable-detail-row',
    handleIcon: 'fas fa-shower',
  },
  pagination: {
    wrapperClass: 'el-pagination is-background',
    activeClass: 'el-button--primary',
    disabledClass: 'is-disabled',
    pageClass: 'el-button el-button--mini is-plain',
    linkClass: 'el-button el-button--mini is-plain',
    icons: {
      first: 'fas fa-angle-double-left grey',
      prev: 'fas fa-angle-left',
      next: 'fas fa-angle-right',
      last: 'fas fa-angle-double-right',
    },
  },
}

export const fieldsDestinatarios = [
  {
    name: 'id',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '110px',
    visible: false,
  },
  {
    name: 'codigo',
    title: 'Cod. Destinatario',
    sortField: 'codigo',
    titleClass: 'text-center-titulo',
    dataClass: 'text-center-data',
    width: '110px',
    visible: true,
  },
  {
    name: 'razon_social',
    title: 'Razon Social',
    sortField: 'razon_social',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
    width: '200px',
  },
  {
    name: 'direccion',
    title: 'Dirección',
    sortField: 'direccion',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  // {
  //   name: 'numero',
  //   sortField: 'numero',
  //   titleClass: 'text-left-titulo',
  //   dataClass: 'text-left-data',
  // },
  // {
  //   title: 'Calle 1',
  //   name: 'entrecalle1',
  //   sortField: 'entrecalle1',
  //   titleClass: 'text-left-titulo',
  //   dataClass: 'text-left-data',
  // },
  // {
  //   title: 'Calle 2',
  //   name: 'entrecalle2',
  //   sortField: 'entrecalle2',
  //   titleClass: 'text-left-titulo',
  //   dataClass: 'text-left-data',
  // },
  {
    name: 'provincia',
    sortField: 'provincia',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    name: 'localidad',
    sortField: 'localidad',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  {
    title: 'Codigo Postal',
    name: 'codigo_postal',
    sortField: 'codigo_postal',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
  },
  /* {
                  title: 'Turnado',
                  name: 'turnado',
                  sortField: 'turnado',
                  titleClass: 'text-left-titulo',
                  dataClass: 'text-left-data',
                  callback: this.callTurnado
                },                 */
  {
    name: '__component:destinatarios-actions',
    title: 'Acciones',
    titleClass: 'text-left-titulo',
    dataClass: 'text-left-data',
    width: '200px',
  },
]
