<template>
  <div v-loading="sistema.loading" style="padding: 0px 20px">
    <el-row style="border-bottom: 1px solid #ddd; padding-bottom: 10px">
      <el-tabs v-model="sistema.activeTab" v-if="!sistema.loading">
        <!-----------------------------Integracion SouthPost---------------------------->
        <el-tab-pane name="southpost">
          <span slot="label"><i class="fas fa-clipboard-list"></i> GUIAS SOUTHPOST</span>
          <Southpost v-if="this.sistema.activeTab == 'southpost'" />
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>
<script>
import Southpost from '@/components/integraciones/south/Index.vue'
import { API } from '@/plugins/api'
import { mapActions } from 'vuex'
export default {
  components: {
    Southpost,
  },
  data() {
    return {
      sistema: {
        activeTab: 'southpost',
        loading: true,
      },
      datos: {
        convenios: [],
      },
    }
  },
  async created() {
    await this.getConvenios()
  },
  methods: {
    ...mapActions('Integraciones', ['setIntegraciones']),
    async getConvenios() {
      const response = await API.get('south/getConvenios')
      let convenios = response.data.data

      this.setIntegraciones({
        convenios,
      })
      this.sistema.loading = false
    },
  },
}
</script>
