<template>
  <div>
    <div class="vguias_buscar">
      <el-row style="border-bottom: 1px solid #ddd; padding-bottom: 8px" :gutter="10">
        <el-col :span="3" :xs="24">
          <h4 style="font-size: 24px; margin: -3px">
            <!-- <img v-if="empresa=='u'" class="img-fluid" src="@/assets/001-caja-abierta-azul.png" style="height: 31px;margin-bottom:-4px;">
            <img v-if="empresa=='lo'" class="img-fluid" src="@/assets/001-caja-abierta.png" style="height: 31px;margin-bottom:-4px;"> -->
            <img class="img-fluid" src="@/assets/004-comercio-electronico-1.png" style="height: 31px; margin-bottom: -4px" />
            Guías
          </h4>
        </el-col>
        <el-col :span="21" :xs="24" class="hidden-xs-only">
          <el-button-group style="float: right">
            <el-tooltip placement="bottom">
              <div slot="content">Exportar Guía</div>
              <el-button type="default" size="mini" :style="styleExcel" @click="exportGuia" :loading="guia_loading" :disabled="disabled_btns">
                <strong><i class="el-icon-download"></i> Excel Guía </strong>
              </el-button>
            </el-tooltip>
            <el-tooltip placement="bottom">
              <div slot="content">Excel Seguimiento</div>
              <el-button type="default" size="mini" :style="styleExcel" @click="exportSeguimiento" :loading="seg_loading" :disabled="disabled_btns">
                <strong><i class="el-icon-download"></i> Excel Seguimiento </strong>
              </el-button>
            </el-tooltip>

            <el-tooltip placement="bottom">
              <div slot="content">Excel</div>
              <el-button type="default" size="mini" :style="styleExcel" @click="exportarGuiaCompro" :loading="guia_seg_loading" :disabled="disabled_btns">
                <strong><i class="el-icon-download"></i> Excel Guía y Comprobantes</strong>
              </el-button>
            </el-tooltip>
          </el-button-group>
        </el-col>
        <el-col :sm="24" :xs="24" class="hidden-sm-and-up">
          <br />
          <el-row :gutter="10">
            <el-tooltip placement="bottom">
              <div slot="content">Exportar Guía</div>
              <el-button type="default" size="mini" @click="exportGuia" :loading="guia_loading" :disabled="disabled_btns">
                <strong><i class="el-icon-download"></i> Excel Guía</strong>
              </el-button>
            </el-tooltip>
          </el-row>
          <el-row :gutter="10">
            <el-tooltip placement="bottom">
              <div slot="content">Excel Seguimiento</div>
              <el-button type="default" size="mini" @click="exportSeguimiento" :loading="seg_loading" :disabled="disabled_btns">
                <strong><i class="el-icon-download"></i> Excel Seguimiento</strong>
              </el-button>
            </el-tooltip>
          </el-row>
          <el-row :gutter="10">
            <el-tooltip placement="bottom">
              <div slot="content">Excel</div>
              <el-button type="default" size="mini" @click="exportarGuiaCompro" :loading="guia_seg_loading" :disabled="disabled_btns">
                <strong><i class="el-icon-download"></i> Excel Guía y Comprobantes</strong>
              </el-button>
            </el-tooltip>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-form :labe-position="'top'" size="mini" style="margin: 0px 5px">
          <el-col :md="2" :sm="8" :xs="12">
            <el-form-item label="Terminal">
              <el-input placeholder="Terminal" size="mini" v-model="buscar.terminal" @keypress.native.enter="buscarDatos(1)" :disabled="disabled_btns" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="3" :sm="8" :xs="12">
            <el-form-item label="Número">
              <el-input placeholder="Ingrese Número" size="mini" v-model="buscar.nguia" @keypress.native.enter="buscarDatos(1)" :disabled="disabled_btns" clearable></el-input
            ></el-form-item>
          </el-col>
          <el-col :md="4" :sm="8" :xs="12">
            <el-form-item label="Estado">
              <el-select
                style="width: 100%"
                v-model="buscar.estado"
                placeholder="Estado"
                size="mini"
                filterable
                clearable
                multiple
                collapse-tags
                @change="quitarTodos()"
                :disabled="disabled_btns"
                default-first-option>
                <!-- Por defecto el valor de Todos es un x -->
                <!-- <el-option value="x" label = "TODOS" key="x">
						</el-option> -->
                <el-option v-for="item in estados" :key="item.codigo" :label="item.nombre" :value="item.codigo"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="3" :sm="8" :xs="12">
            <el-form-item label="Comprobante">
              <el-input placeholder="Ingrese comprob." size="mini" v-model="buscar.comprob" @keypress.native.enter="buscarDatos(1)" :disabled="disabled_btns" clearable></el-input>
            </el-form-item>
            <!-- <span v-if="error_comprob" style="font-size: 12px;color: #d00909;"><mini>{{msj_error}}</mini></span> -->
          </el-col>
          <el-col :md="3" :sm="8" :xs="12">
            <el-form-item label="N° Presentación">
              <el-input placeholder="Presentación" size="mini" v-model="buscar.numpres" @keypress.native.enter="buscarDatos(1)" :disabled="disabled_btns" clearable></el-input
            ></el-form-item>
          </el-col>
          <el-col :md="3" :sm="4" :xs="12">
            <el-form-item label="Carta de reporte">
              <el-input placeholder="Carta" size="mini" v-model="buscar.cporte" @keypress.native.enter="buscarDatos(1)" :disabled="disabled_btns" clearable></el-input
            ></el-form-item>
          </el-col>
          <el-col :md="4" :sm="12" :xs="24">
            <el-form-item label="Nombre/Razón Social">
              <el-input placeholder="Ingrese nombre" size="mini" v-model="buscar.razsoc" @keypress.native.enter="buscarDatos(1)" :disabled="disabled_btns" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="2" :sm="4" :xs="12">
            <el-form-item label="CP">
              <el-input placeholder="Cp" size="mini" v-model="buscar.codpos" @keypress.native.enter="buscarDatos(1)" :disabled="disabled_btns" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="3" :sm="12" :xs="24">
            <el-form-item label="Presentación">
              <el-select style="width: 100%" v-model="buscar.presentado" placeholder="Presentación" size="mini" filterable :disabled="disabled_btns" clearable>
                <el-option value="" label="TODO" key=""> </el-option>
                <el-option v-for="item in presentanciones" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="12" :xs="24">
            <el-form-item label="Fecha">
              <el-date-picker
                v-model="buscar.fecha"
                type="daterange"
                start-placeholder="Desde"
                end-placeholder="Hasta"
                size="mini"
                format="dd/MM/yyyy"
                :disabled="disabled_btns"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <div v-if="showConv">
            <el-col :md="3" :sm="12" :xs="12">
              <el-form-item label="Afinidad">
                <el-select
                  style="width: 100%"
                  v-model="buscar.afinidad"
                  placeholder="Afinidad"
                  size="mini"
                  filterable
                  clearable
                  @change="mostrarConvenios"
                  :disabled="disabledAfinidad || disabled_btns">
                  <el-option v-for="item in afinidad" :key="item.codigo" :label="item.descrip" :value="item.codigo"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="12" :xs="12">
              <el-form-item label="Gestión cobranza">
                <el-select style="width: 100%" v-model="buscar.gestion" placeholder="Gestión cobranza" size="mini" filterable :disabled="disabled_btns" clearable>
                  <el-option v-for="item in gestiones" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="9" :sm="10" :xs="24">
              <el-form-item label="Convenios">
                <el-select
                  style="width: 100%"
                  v-model="buscar.ol_convenio"
                  placeholder="Seleccione Convenios"
                  size="mini"
                  filterable
                  clearable
                  :disabled="!showConv || disabled_btns"
                  :loading="cargandoConvenios"
                  multiple>
                  <el-option value="" label="TODOS" key=""> </el-option>
                  <el-option v-for="item in convenios" :key="item.olconv" :label="item.nombre" :value="item.olconv"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <el-col :md="4" :sm="4" :xs="24">
            <el-form-item label="Prov. Destino">
              <el-select style="width: 100%" v-model="buscar.pdestino" placeholder="Prov. Destino" size="mini" filterable clearable :disabled="disabled_btns" multiple>
                <el-option value="" label="TODAS" key=""></el-option>
                <el-option v-for="item in provincias" :key="item.codigo" :label="item.nombre" :value="item.codigo"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="5" :xs="24">
            <el-form-item label="Origen">
              <el-select style="width: 100%" v-model="buscar.origen" placeholder="Origen" size="mini" filterable clearable :disabled="disabled_btns" multiple>
                <el-option value="" label="TODAS" key=""> </el-option>
                <el-option v-for="item in destinos" :key="item.codigo" :label="item.nombre" :value="item.codigo"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="4" :xs="24">
            <el-form-item label="Destino">
              <el-select style="width: 100%" v-model="buscar.destino" placeholder="Destino" size="mini" filterable clearable :disabled="disabled_btns" multiple>
                <el-option value="" label="TODAS" key=""> </el-option>
                <el-option v-for="item in destinos" :key="item.codigo" :label="item.nombre" :value="item.codigo"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="10" :xs="24">
            <el-button-group style="margin-top: 28px">
              <el-button type="primary" size="mini" :style="styleBtnBuscar" @click="buscarDatos(1)" icon="el-icon-search" :disabled="disabled_btns" :loading="load_buscar">
                BUSCAR
              </el-button>
              <el-button type="default" size="mini" @click="limpiarCampos()" icon="el-icon-close" :disabled="disabled_btns"> LIMPIAR </el-button>
            </el-button-group>
          </el-col>
        </el-form>
      </el-row>
      <!-- <el-row :gutter="10">
        <el-col :md="20" :sm="21" :xs="18">
          <el-row :gutter="10" class="vguia-row">
            <el-col :md="3" :sm="8" :xs="12">
              <label for="" class="vpim-label">Terminal:</label>
              <el-input
                placeholder="Ingrese Nº"
                size="mini"
                v-model="buscar.terminal"
                @keypress.native.enter="buscarDatos(1)"
                clearable></el-input>
            </el-col>

            <el-col :md="4" :sm="8" :xs="12">
              <label for="" class="vpim-label">Número:</label>
              <el-input
                placeholder="Ingrese Nº"
                size="mini"
                v-model="buscar.nguia"
                @keypress.native.enter="buscarDatos(1)"
                clearable></el-input>
            </el-col>

            <el-col :md="4" :sm="8" :xs="12">
              <label for="" class="vpim-label">Estado:</label>
              <el-select
                v-model="buscar.estado"
                placeholder="Estado"
                size="mini"
                class="vpim-select"
                filterable
                clearable
                multiple
                @change="quitarTodos()"
                default-first-option>

                <el-option
                  v-for="item in estados"
                  :key="item.codigo"
                  :label="item.nombre"
                  :value="item.codigo">
                </el-option>
              </el-select>
            </el-col>
            <el-col :md="4" :sm="8" :xs="12">
              <label for="" class="vpim-label">Comprobante:</label>
              <el-input
                placeholder="Ingrese comprob."
                size="mini"
                v-model="buscar.comprob"
                @keypress.native.enter="buscarDatos(1)"
                clearable></el-input>
            </el-col>

            <el-col :md="4" :sm="8" :xs="12">
              <label for="" class="vpim-label">Presentación:</label>
              <el-input
                placeholder="Presentación"
                size="mini"
                v-model="buscar.numpres"
                @keypress.native.enter="buscarDatos(1)"
                clearable></el-input>
            </el-col>

            <el-col :md="3" :sm="4" :xs="12">
              <label for="" class="vpim-label">Carta de porte:</label>
              <el-input
                placeholder="Carta"
                size="mini"
                v-model="buscar.cporte"
                @keypress.native.enter="buscarDatos(1)"
                clearable></el-input>
            </el-col>
            <el-col :md="2" :sm="4" :xs="12">
              <label for="" class="vpim-label">Cp:</label>
              <el-input
                placeholder="Cp"
                size="mini"
                v-model="buscar.codpos"
                @keypress.native.enter="buscarDatos(1)"
                clearable></el-input>
            </el-col>
          </el-row>
          <el-row class="vguia-row" :gutter="10">
            <el-col :md="7" :sm="12" :xs="24">
              <label for="" class="vpim-label">Nombre/Razón Social:</label>
              <el-input
                placeholder="Ingrese nombre"
                size="mini"
                v-model="buscar.razsoc"
                @keypress.native.enter="buscarDatos(1)"
                clearable></el-input>
            </el-col>
            <el-col :md="3" :sm="12" :xs="24">
              <label for="" class="vpim-label">Presentación:</label>
              <el-select
                v-model="buscar.presentado"
                placeholder="Presentación"
                size="mini"
                class="vpim-select"
                filterable
                clearable>
                <el-option value="" label="TODO" key=""> </el-option>
                <el-option
                  v-for="item in presentanciones"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :md="6" :sm="12" :xs="24">
              <label for="" class="vpim-label">Fecha:</label>
              <div class="block">
                <el-date-picker
                  width="100%"
                  v-model="buscar.fecha"
                  type="daterange"
                  start-placeholder="Desde"
                  end-placeholder="Hasta"
                  size="mini"
                  format="dd/MM/yyyy"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </el-col>
            <el-col :md="4" :sm="12" :xs="12" v-if="showConv">
              <label for="" class="vpim-label">Afinidad:</label>
              <el-select
                v-model="buscar.afinidad"
                placeholder="Afinidad"
                size="mini"
                class="vpim-select"
                filterable
                clearable
                @change="mostrarConvenios"
                :disabled="disabledAfinidad">
                <el-option
                  v-for="item in afinidad"
                  :key="item.codigo"
                  :label="item.descrip"
                  :value="item.codigo">
                </el-option>
              </el-select>
            </el-col>
            <el-col :md="4" :sm="12" :xs="12" v-if="showConv">
              <label for="" class="vpim-label">Gestión cobranza:</label>
              <el-select
                v-model="buscar.gestion"
                placeholder="Gestión cobranza"
                size="mini"
                class="vpim-select"
                filterable
                clearable>
                <el-option
                  v-for="item in gestiones"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="vguia-row" :gutter="10">
            <el-col :md="4" :sm="4" :xs="24">
              <label for="" class="vpim-label">Prov. Destino:</label>
              <el-select
                v-model="buscar.pdestino"
                placeholder="Prov. Destino"
                size="mini"
                class="vpim-select"
                filterable
                clearable
                multiple>
                <el-option value="" label="TODAS" key=""></el-option>
                <el-option
                  v-for="item in provincias"
                  :key="item.codigo"
                  :label="item.nombre"
                  :value="item.codigo">
                </el-option>
              </el-select>
            </el-col>
            <el-col :md="4" :sm="5" :xs="24">
              <label for="" class="svpim-label">Origen:</label>
              <el-select
                v-model="buscar.origen"
                placeholder="Origen"
                size="mini"
                class="vpim-select"
                filterable
                clearable
                multiple>
                <el-option value="" label="TODAS" key=""> </el-option>
                <el-option
                  v-for="item in destinos"
                  :key="item.codigo"
                  :label="item.nombre"
                  :value="item.codigo">
                </el-option>
              </el-select>
            </el-col>
            <el-col :md="4" :sm="5" :xs="24">
              <label for="" class="vpim-label">Destino:</label>
              <el-select
                v-model="buscar.destino"
                placeholder="Destino"
                size="mini"
                class="vpim-select"
                filterable
                clearable
                multiple>
                <el-option value="" label="TODAS" key=""> </el-option>
                <el-option
                  v-for="item in destinos"
                  :key="item.codigo"
                  :label="item.nombre"
                  :value="item.codigo">
                </el-option>
              </el-select>
            </el-col>
            <el-col :md="12" :sm="10" :xs="24" v-if="showConv">
              <label for="" class="vpim-label">Convenios:</label>
              <el-select
                v-model="buscar.ol_convenio"
                placeholder="Seleccione Convenios"
                size="mini"
                class="vpim-select"
                filterable
                clearable
                :disabled="!showConv"
                :loading="cargandoConvenios"
                multiple>
                <el-option value="" label="TODOS" key=""> </el-option>
                <el-option
                  v-for="item in convenios"
                  :key="item.olconv"
                  :label="item.nombre"
                  :value="item.olconv">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :sm="3" :md="{ span: 3, offset: 1 }" class="hidden-xs-only">
          <div
            style="
              height: 100px;
              position: absolute;
              top: 50%;
              margin-top: -50px;
              text-align: center !important;
              display: table-cell !important;
              vertical-align: middle !important;
            ">
            <el-row>
              <el-button
                type="primary"
                size="medium"
                :style="styleBtnBuscar"
                @click="buscarDatos(1)"
                icon="el-icon-search"
                :disabled="disabled_btns"
                :loading="load_buscar">
                Buscar
              </el-button>
            </el-row>
            <el-row>
              <el-button
                type="default"
                size="medium"
                @click="limpiarCampos()"
                icon="el-icon-close"
                style="margin-top: 5px"
                :disabled="disabled_btns">
                Limpiar
              </el-button>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="6" class="hidden-sm-and-up">
          <div
            style="
              height: 100px;
              position: absolute;
              top: 50%;
              text-align: center !important;
              margin-left: -5px;
            ">
            <el-row>
              <el-button
                type="primary"
                size="mini"
                :style="styleBtnBuscar"
                @click="buscarDatos(1)"
                icon="el-icon-search"
                :disabled="disabled_btns"
                :loading="load_buscar">
                Buscar
              </el-button>
            </el-row>
            <el-row>
              <el-button
                type="default"
                size="mini"
                @click="limpiarCampos()"
                icon="el-icon-close"
                style="margin-top: 5px"
                :disabled="disabled_btns">
                Limpiar
              </el-button>
            </el-row>
          </div>
        </el-col>
      </el-row> -->
      <!-- <el-row class="vguia-row" :gutter="10">
		<el-col :md="24">

		</el-col>
	</el-row> -->
      <el-row v-if="showMsj">
        <el-col :span="5" :offset="10">
          <el-alert title="La búsqueda se realiza por datos de hoja de ruta" center show-icon type="warning" :closable="false" style="margin-top: 5px"> </el-alert>
        </el-col>
      </el-row>
    </div>

    <!-- Modal para Excel -->
    <el-dialog :visible.sync="dialogExcel" width="50%" :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="false" :show-close="false" :top="'2vh'">
      <el-row style="border-bottom: 1px dashed #ddd; margin-top: -25px; margin-bottom: 10px" :gutter="10">
        <el-col :md="14">
          <span style="float: left; font-size: 20px; text-aling: center"
            ><strong> <i class="el-icon-download"></i> Descargar Guías </strong></span
          >
        </el-col>
      </el-row>
      <p style="word-break: break-word">
        La descarga de resultados está limitada a 10.000 registros, su actual consulta arrojó un total de
        <strong style="color: #f76868">{{ numberTitle }}</strong> resultados.
      </p>
      <p style="word-break: break-word">Puede ajustar los resultados agregando nuevos filtros o reduciendo el rango de fechas seleccionadas.</p>
      <el-row style="padding: 5px !important">
        <center>
          <el-button type="success" icon="el-icon-download" @click="downloadSame" size="small" :loading="aceptar_load"> Descargar 10.000 registros </el-button>
          <el-button @click="cancelarDownload" icon="el-icon-close" size="small" :disabled="blockCancelar">Cancelar</el-button>
        </center>
      </el-row>
    </el-dialog>
  </div>
</template>

<script type="text/javascript" src="https://raw.github.com/Stuk/jszip/master/jszip.js"></script>

<script>
import { HTTP } from '@/axios'
import { EventBus } from '@/event-bus'
const moment = require('moment')

export default {
  data() {
    return {
      empresa: window.localStorage.getItem('empresa'),
      buscar: {
        comprob: '',
        terminal: '',
        nguia: '',
        numpres: '',
        cporte: '',
        fecha: [],
        codpos: '',
        razsoc: '',
        estado: [],
        pdestino: '',
        afinidad: 'x',
        gestion: '',
        ol_convenio: '',
        origen: '',
        destino: '',
        presentado: '',
        guia_convenios: '',
        hrsuc: '',
        hojaruta: '',
        id: '',
        convenio: '',
        provincia: '',
        pagina: '',
        lista: '',
        multi: '',
        sesion: { guia_convenios: '', ol_convenio: '' },
        askDescargar: 0,
        permiso_motivo: false,
      },
      fecha: '',
      afinidad: [],
      gestiones: [
        { label: 'TODAS', value: '' },
        { label: 'Si', value: 'cg' },
        { label: 'No', value: 'sg' },
      ],
      provincias: [],
      destinos: [],
      estados: [
        { codigo: 'x', nombre: 'Todos', estado: 'x' },
        { codigo: '', nombre: 'En tránsito', estado: 'ET' },
        { codigo: 'UM', nombre: 'Última Milla', estado: 'UM' },
        { codigo: 'E', nombre: 'Entregado', estado: 'ET' },
        { codigo: 'X', nombre: 'Rechazado', estado: 'ET' },
        { codigo: 'R', nombre: 'Reprogramada', estado: 'ET' },
        { codigo: 'NE', nombre: 'NO Entregado', estado: 'ET' },
        { codigo: 'NR', nombre: 'No recibido', estado: 'ET' },
        { codigo: 'P', nombre: 'Retiro', estado: 'ET' },
        { codigo: 'A', nombre: 'Anulado', estado: 'ET' },
        { codigo: 'PD', nombre: 'Próxima a despachar', estado: 'PD' },
      ],
      presentanciones: [
        { value: 1, label: 'Presentado' },
        { value: 2, label: 'No presentado' },
      ],
      codigo: '',
      loading: false,
      permisos_usuario: JSON.parse(window.localStorage.getItem('datos')).permisos,
      guia_conv: window.localStorage.getItem('conv'),
      convenios: [],
      styleBtnBuscar: { borderColor: '#02172e00' },
      pickerOptions: {
        shortcuts: [
          {
            text: 'Últ. Semana',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Últ. Mes',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '3 Últ. meses',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        disabledDate(date) {
          return date > new Date()
        },
      },
      datos: JSON.parse(window.localStorage.getItem('datos')),
      guia_loading: false,
      seg_loading: false,
      guia_seg_loading: false,
      showConv: true,
      disabledAfinidad: false,
      conveniosInicio: [],
      afinidadInicio: [],
      cargandoConvenios: false,
      showMsj: false,
      msjNroPres: false,
      dialogExcel: false,
      aceptar_load: false,
      blockCancelar: false,
      numberTitle: '',
      disabled_btns: false,
      load_buscar: false,
      styleExcel: {},
    }
  },
  mounted() {
    EventBus.$on('nuevaPagina', (p) => {
      this.buscar.pagina = p
      setTimeout(() => {
        this.buscarDatos()
        return false
      }, 100)
    })
    /* OK */
    if (JSON.parse(window.localStorage.getItem('Bloque')) == null && JSON.parse(window.localStorage.getItem('pres')) == null) {
      this.cargarPermiso()
      setTimeout(() => {
        this.limpiarCampos()
      }, 500)
    } else {
      EventBus.$emit('cargandoGuias', true)
      if (JSON.parse(window.localStorage.getItem('Bloque'))) {
        this.buscar = JSON.parse(window.localStorage.getItem('Bloque'))
        localStorage.removeItem('Bloque')

        if (JSON.parse(window.localStorage.getItem('row_select_guia'))) {
          EventBus.$emit('cargar_datos_select', true)
          let datos = JSON.parse(window.localStorage.getItem('row_select_guia'))
          this.buscar.pagina = datos.pag_guia
        } else {
          setTimeout(() => {
            this.cargarPermiso()
            this.buscarDatos()
            return false
          }, 300)
        }
      } else {
        /* OK */
        if (JSON.parse(window.localStorage.getItem('pres'))) {
          this.msjNroPres = true
          this.$message('Buscando por Nº de Presentación.')
          this.buscar.numpres = JSON.parse(window.localStorage.getItem('pres'))
          localStorage.removeItem('pres')
          this.buscar.afinidad = this.buscar.estado
          setTimeout(() => {
            this.cargarPermiso()
            this.buscarDatos(1)
            return false
          }, 300)
        } else {
          this.limpiarCampos()
        }
      }
    }
  },

  created() {
    /* Primer carga por defecto */
    this.permisos()
    setTimeout(() => {
      this.getProvincia()
      this.getDestino()
      let showAfinidad = this.permisos_usuario.indexOf('ADMIN') != -1 || this.permisos_usuario.indexOf('OL_LISTACONV') != -1 || this.permisos_usuario.indexOf('OL_MULTI') != -1 ? true : false
      if (showAfinidad === true) {
        this.getAfinidad()
      }

      // this.cargarPermiso();
    }, 1000)
  },
  methods: {
    cargarPermiso() {
      if (this.permisos_usuario.indexOf('ADMIN') != -1) {
        this.buscar.lista = true
        this.buscar.multi = true
      } else {
        this.buscar.lista = this.permisos_usuario.indexOf('OL_LISTACONV') != -1 ? true : false
        this.buscar.multi = this.permisos_usuario.indexOf('OL_MULTI') != -1 ? true : false
      }
      this.buscar.permiso_motivo = this.permisos_usuario.indexOf('EXCEL_MOTIVOS') != -1 ? true : false
      this.buscar.sesion.guia_convenios = this.datos['guia_convenios']
      this.buscar.sesion.ol_convenio = this.datos['ol_convenio']
      /* Cargamos los convenios de acuerdo al permiso */
      if (this.buscar.lista || this.buscar.multi) {
        this.getConvenios()
      } else {
        this.showConv = false
      }
    },
    permisos() {
      if (this.empresa == 'lo') {
        this.styleBtnBuscar['backgroundColor'] = '#e5590a !important'
        this.styleExcel['backgroundColor'] = 'antiquewhite !important'
      } else if (this.empresa == 'u') {
        this.styleBtnBuscar['backgroundColor'] = '#0b406a !important'
        this.styleExcel['backgroundColor'] = 'aliceblue !important'
      }
    },
    getProvincia() {
      HTTP.get('/guiasnew/getProv')
        .then((res) => {
          this.provincias = res.data
        })
        .catch((error) => {
          this.provincias = []
          console.log(error)
          this.$alert('Hubo un problema al cargar  las provincias. Refresca la vista')
        })
    },
    getDestino() {
      HTTP.get('/guiasnew/getDestino')
        .then((res) => {
          this.destinos = res.data
        })
        .catch((error) => {
          this.destinos = []
          console.log(error)
          this.$alert('Hubo un problema al cargar los destinos. Refresca la vista')
        })
    },
    getAfinidad() {
      HTTP.get('/guiasnew/getAfinidad')
        .then((res) => {
          this.afinidad = res.data
          this.afinidadInicio = res.data
        })
        .catch((error) => {
          this.afinidad = []
          this.afinidadInicio = []
          console.log(error)
          this.$alert('Hubo un problema al cargar las afinidades. Refresca la vista')
        })
    },
    /* Cuando seleccione una afinidad cargar los convenios que tengan esa afinidad */
    mostrarConvenios(afinidad) {
      this.convenios = []
      this.buscar.ol_convenio = ''
      if (afinidad != 'x' && afinidad != '') {
        var filtrados = this.conveniosInicio.filter((elemento) => elemento.afinidad === afinidad)
        this.convenios = filtrados
      } else {
        this.convenios = this.conveniosInicio
        this.afinidad = this.afinidadInicio
        this.buscar.afinidad = 'x'
      }
    },
    /* Cuando seleccione un convenio mostrar afinidad del convenio seleccionado de los contrario disabled.  */
    mostrarAfinidad(convenio) {
      this.afinidad = []
      this.buscar.afinidad = ''
      let buscarConv = []

      if (convenio.length > 1) {
        convenio.forEach((element) => {
          buscarConv.push(this.conveniosInicio.filter((elemento) => elemento.olconv === element))
        })
        buscarConv.forEach((element) => {
          if (element['afinidad'] != '' && element['afinidad'] != 0) {
            this.disabledAfinidad = false
            let filtrarAfi = this.afinidadInicio.filter((elemento) => elemento.codigo === element['afinidad'])
            if (filtrarAfi.length) {
              this.afinidad.push(filtrarAfi)
            }
          }
        })
      }
    },
    getConvenios() {
      this.convenios = []
      this.conveniosInicio = []
      this.cargandoConvenios = true
      let lista = this.buscar.lista && !this.buscar.multi ? true : false

      HTTP.post('/guiasnew/getConvenios', {
        ol_lista: lista,
        ol_multi: this.buscar.multi,
        guia_convenios: this.datos['guia_convenios'],
      })
        .then((res) => {
          this.convenios = res.data
          this.conveniosInicio = res.data
          this.cargandoConvenios = false
        })
        .catch((error) => {
          this.convenios = []
          this.conveniosInicio = []
          this.cargandoConvenios = false
          console.log(error)
          this.$alert('Hubo un problema al cargar los convenios. Refresca la vista')
        })
    },

    buscarDatos(pagina_now) {
      this.showErrorPres = false
      this.disabled_btns = true
      this.load_buscar = true
      this.filtrosDOP(true)
      this.loadingTabla = true
      // Control de comprobantes
      window.scrollTo(0, 0)
      if (pagina_now == 1) {
        this.buscar.pagina = pagina_now
      }

      this.fechaTabla = ''
      // /*  Envió permisos OL_MULTI Y LISTA  y guias_convenio y ol_convenio*/
      /* En el caso de que el usuario tenga permiso OL_LISTA nada más y elija Convenios->TODOS, enviar los convenios */
      EventBus.$emit('cargandoGuias', true)
      EventBus.$emit('destinos', this.destinos)
      setTimeout(() => {
        HTTP.post('/guiasnew/getDatosTabla', this.buscar)
          .then((res) => {
            if (res.data.length == 0) {
              if (this.buscar.fecha.length > 0) {
                EventBus.$emit('cargarTablaGuias', {
                  tabla: 0,
                  fechaIni: this.formatFecha(this.buscar.fecha[0]),
                  fechaFin: this.formatFecha(this.buscar.fecha[1]),
                  datos: this.buscar,
                })
              } else {
                /* Si busca por nro de presentacion y no devuelve resultado */
                if (this.msjNroPres) {
                  this.$alert('Todavía no se cargo la guía asociada al Nº de Presentación: ' + this.buscar.numpres, 'Guía asociada', {
                    confirmButtonText: 'OK',
                  })
                }
                EventBus.$emit('cargarTablaGuias', {
                  tabla: 0,

                  datos: this.buscar,
                })
              }
            } else {
              EventBus.$emit('cargarTablaGuias', {
                tabla: res.data,
                fechaIni: this.formatFecha(this.buscar.fecha[0]),
                fechaFin: this.formatFecha(this.buscar.fecha[1]),
                pagina: this.buscar.pagina,
                datos: this.buscar,
              })
            }
            this.disabled_btns = false
            this.load_buscar = false
          })
          .catch((error) => {
            this.loadingTabla = false
            this.disabled_btns = false
            this.load_buscar = false
            // this.disabledCampos(false);
            console.log(error)
            this.$alert('No se pudo realizar la acción. Refresque la página')
          })
      }, 200)
    },
    /* Formatos para tabla */
    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    },
    limpiarCampos() {
      let showAfinidad = this.permisos_usuario.indexOf('ADMIN') != -1 || this.permisos_usuario.indexOf('OL_LISTACONV') != -1 || this.permisos_usuario.indexOf('OL_MULTI') != -1 ? true : false
      if (showAfinidad === true) {
        this.getAfinidad()
      }
      this.buscar.comprob = ''
      this.buscar.terminal = ''
      this.buscar.nguia = ''
      this.buscar.numpres = ''
      this.buscar.cporte = ''
      this.buscar.fecha = []
      this.buscar.codpos = ''
      this.buscar.razsoc = ''
      this.buscar.estado = []
      this.buscar.afinidad = 'x'
      this.buscar.provincia = ''
      this.buscar.convenio = ''
      this.buscar.pdestino = ''
      this.buscar.ol_convenio = ''
      this.buscar.presentado = ''
      this.buscar.guia_convenios = ''
      this.buscar.hrsuc = ''
      this.buscar.hojaruta = ''
      this.buscar.origen = ''
      this.buscar.id = ''
      ;(this.buscar.destino = ''), (this.pagina = 1), (this.convenios = this.conveniosInicio)
      this.disabledAfinidad = false
      this.showMsj = false
      this.error_comprob = false
      // this.buscarDatos(1)
    },
    exportGuia() {
      this.guia_loading = true
      this.filtrosDOP()
      this.fechaTabla = ''
      this.buscar.askDescargar = 0
      HTTP.post('/guiasnew/exportarGuia', this.buscar)
        .then((res) => {
          this.guia_loading = false
          if (res.data == 0) {
            this.$message({
              message: 'No hay registros para descargar',
              type: 'warning',
            })
          } else if (Number.isInteger(res.data)) {
            this.dialogExcel = true
            this.numberTitle = res.data
          } else {
            window.location.href = res.data
          }
        })
        .catch((error) => {
          console.log(error)
          this.$alert('No se pudo realizar la acción. Intente nuevamente.')
          this.guia_loading = false
          ;(this.aceptar_load = false), (this.blockCancelar = false)
        })
    },
    downloadSame() {
      this.loadingTabla = true
      this.filtrosDOP()
      window.scrollTo(0, 0)
      this.buscar.askDescargar = 1
      this.aceptar_load = true
      this.blockCancelar = true
      HTTP.post('/guiasnew/exportarGuia', this.buscar)
        .then((res) => {
          window.location.href = res.data
          setTimeout(() => {
            this.aceptar_load = false
            this.blockCancelar = false
            this.dialogExcel = false
          }, 200)
        })
        .catch((error) => {
          console.log(error)
          this.$alert('No se pudo realizar la acción. Refresque la página y vuelva a intentar.')
          this.aceptar_load = false
          this.blockCancelar = false
          this.dialogExcel = true
        })
    },
    cancelarDownload() {
      this.dialogExcel = false
      this.buscar.askDescargar = 0
    },
    exportSeguimiento() {
      this.seg_loading = true
      this.filtrosDOP()
      this.$swal
        .fire({
          title: '¿Desea exportar los seguimientos?',
          text: 'Tenga en cuenta que se descargarán las primeras 1000 guias.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, exportar!',
          cancelButtonText: 'No',
        })
        .then((result) => {
          if (result.value) {
            // HTTP.post('/guiasnew/exportSeguimiento',this.buscar
            HTTP.post('/guiasnew/exportSeguimientoPRUEBA', this.buscar)
              .then((res) => {
                this.seg_loading = false
                if (res.data == 0) {
                  this.$message({
                    showClose: true,
                    message: 'No hay registros para descargar.',
                    type: 'warning',
                  })
                } else {
                  if (Number.isInteger(res.data)) {
                    this.$swal.fire('Descarga limitada 10.000 registros', 'Puede ajustar los resultados agregando nuevos filtros o reduciendo el rango de fechas seleccionadas.', 'warning')
                  } else {
                    window.location.href = res.data
                  }
                }
              })
              .catch((error) => {
                console.log(error)
                this.$alert('No se pudo realizar la acción. Refresque la página')
              })
          } else {
            this.seg_loading = false
          }
        })
    },
    exportarGuiaCompro() {
      this.guia_seg_loading = true
      this.filtrosDOP()

      HTTP.post('/guiasnew/exportGuiaComprob', this.buscar)
        .then((res) => {
          this.guia_seg_loading = false
          if (res.data == 0) {
            this.$message({
              showClose: true,
              message: 'No hay registros para descargar.',
              type: 'warning',
            })
          } else {
            window.location.href = res.data
          }
        })
        .catch((error) => {
          console.log(error)
          this.$alert('No se pudo realizar la acción. Refresque la página')
        })
    },
    changeConvenio(convenio) {
      let filtro_vacio = convenio.filter((elemento) => elemento == '')
      if (filtro_vacio.length) {
        this.buscar.ol_convenio = []
        this.buscar.ol_convenio.push('')
      }
    },
    filtrosDOP(tipo = null) {
      if (this.buscar.ol_convenio != '') {
        let traeTodos = this.buscar.ol_convenio.find(function filtrarElemento(elemento) {
          return elemento == ''
        })
        if (traeTodos == '') {
          this.buscar.ol_convenio = ['']
        }
      }
      /* Buscar todas las provincias */
      if (this.buscar.pdestino != '') {
        let traeTodas = this.buscar.pdestino.find(function filtrarElemento(elemento) {
          return elemento == ''
        })
        if (traeTodas == '') {
          this.buscar.pdestino = ['']
        }
      }
      if (this.buscar.destino != '') {
        let traeTodas = this.buscar.destino.find(function filtrarElemento(elemento) {
          return elemento == ''
        })
        if (traeTodas == '') {
          this.buscar.destino = ['']
        }
      }
      if (this.buscar.origen != '') {
        let origen = this.buscar.origen.find(function filtrarElemento(elemento) {
          return elemento == ''
        })
        if (origen == '') {
          this.buscar.origen = ['']
        }
      }
      var termi = this.buscar.terminal
      if (this.buscar.fecha == null) {
        this.buscar.fecha = [new Date(moment().subtract(7, 'days')), new Date()]
      } else {
        if (this.buscar.fecha.length === 0) {
          this.buscar.fecha = [new Date(moment().subtract(7, 'days')), new Date()]
        }
      }
      if (this.buscar.nguia != '') {
        this.buscar.nguia = parseInt(this.buscar.nguia)
        this.buscar.fecha = []
      }

      if (this.buscar.numpres != null && this.buscar.numpres != '') {
        var present = this.buscar.numpres
        this.buscar.fecha = []
        this.buscar.numpres = present
        this.msjNroPres = this.msjNroPres && tipo ? true : false
      }

      if (this.buscar.hojaruta != '' && this.buscar.hrsuc != '') {
        let hrsuc = this.buscar.hrsuc
        let hoja = this.buscar.hojaruta
        this.buscar.fecha = []
        this.buscar.hojaruta = hoja
        this.buscar.hrsuc = hrsuc
        this.showMsj = tipo ? true : false
      }
      this.buscar.terminal = termi
      if (this.buscar.estado.length > 0) {
        let traeEstados = this.buscar.estado.find(function filtrarElemento(elemento) {
          return elemento == 'x'
        })
        if (traeEstados == 'x') {
          this.buscar.estado = ['x']
        }
      } else {
        this.buscar.estado = ['x']
      }
    },
    quitarTodos() {
      if (this.buscar.estado.length > 1) {
        let traeTodos = this.buscar.estado.find(function filtrarElemento(elemento) {
          return elemento == 'x'
        })
        if (traeTodos) {
          this.buscar.estado = this.buscar.estado.filter((word) => word != 'x')
        }
      }
    },
  },
}
</script>
<style scoped>
/* @media (min-width: 1300px) and (max-width: 1300px)
{
	.el-date-editor--daterange.el-input__inner {
    width: 250px !important;
	}
} */
.el-date-editor--daterange.el-input__inner {
  padding: 2px;
  width: 100%;
}

.el-date-table td.start-date span,
.el-date-table td.end-date span {
  background-color: #0b406a !important;
}
.el-select-dropdown__item.selected {
  color: #577bce;
}
</style>
