<template>
    <div style="background: #FFF; padding-bottom: 90px;margin-top: 5px;">
        <div style="padding:15px">
            <el-row style="border-bottom:1px solid #ddd;padding-bottom:8px;" :gutter="10">
                    <el-col :span="24">
                        <h4 style="font-size:24px;margin:-3px;"> <img src="@/assets/ico_micuenta.png" alt="">
                            Modificar mi cuenta
                        </h4>
                    </el-col>
            </el-row>
        <el-tabs v-model="activeName"  @tab-click="tabClick">
            <el-tab-pane label="Email" name="email" >
                <span slot="label"><i class="el-icon-message"></i> Email</span><mi-cuenta></mi-cuenta>
            </el-tab-pane>
            <el-tab-pane label="Contraseña" name="password">
                <span slot="label"><i class="el-icon-unlock"></i> Contraseña</span><password></password>
            </el-tab-pane>
        </el-tabs>
        </div>
    </div>
</template>
<script>
import MiCuenta from '@/components/cuentausuario/miCuenta.vue'
import Password from '@/components/cuentausuario/Password.vue'
import {EventBus} from "@/event-bus"

export default {
    components:{
        MiCuenta,
        Password
    },
    data() {
        return {
            activeName: 'email',
        }
    },
    methods: {
        tabClick(tab)
        {
            if(tab.name == 'password')
            {
                EventBus.$emit("limpiarCampos",true);
            }
        }
    },
}
</script>